import {Locale} from 'domain/Locale'
import i18next from 'i18next'
import en from '../../i18n/en.json'
import ja from '../../i18n/ja.json'


const i18n = i18next

i18n.init({
  lng: Locale.JA,
  initImmediate: true,
  resources: {en: {translation: en}, ja: {translation: ja}}
})

export default i18n