import {ReactComponent as MoveToBookmarkBottom} from '../../../assets/icons/vertical_align_bottom.svg'
import React, {useCallback, useContext, useEffect, useMemo, useRef, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {updateExcludedWordsFromWordCount} from '../../../api-clients/projectClient'
import EventBus, {EventType} from '../../../EventBus'
import {ReportContext} from '../../../providers/ReportProvider'

interface BlocksContextMenuProps {
  container: HTMLElement
}

interface MenuPosition {
  x: number
  y: number
}

const BlocksContextMenu: React.FC<BlocksContextMenuProps> = ({container}) => {
  const {t} = useTranslation()
  const {project, setWordCountExcludedBlockIds} = useContext(ReportContext)
  const [opened, setOpened] = useState(false)
  const [menuPosition, setMenuPosition] = useState<MenuPosition | null>(null)
  const menuContainer = useRef<HTMLDivElement>(null)
  const wordCountExcludedBlockIds = useMemo(() => project!.wordCountExcludedBlockIds || [], [project])
  const [selectedBlocks, setSelectedBlocks] = useState<HTMLElement[]>([])
  const selectedBlockIds = useMemo(() => selectedBlocks.map(block => block.id), [selectedBlocks])

  useEffect(() => {
    container.addEventListener('contextmenu', onRightClick)

    return () => {
      container.removeEventListener('contextmenu', onRightClick)
      document.removeEventListener('click', onCloseOutsideMenu, false)
    }
  }, [])

  useEffect(() => {
    if (opened) {
      document.addEventListener('click', onCloseOutsideMenu, false)
    } else {
      document.removeEventListener('click', onCloseOutsideMenu, false)
    }
  }, [opened])

  function onRightClick(e: MouseEvent) {
    const selectedBlocks = Array.from(container.querySelectorAll('.ce-block--selected')) as HTMLElement[]
    const allSelectedBlocks = Array.from(
      container.querySelectorAll('.ce-block--focused, .ce-block--selected')
    ) as HTMLElement[]

    const selection = document.getSelection()

    if (selectedBlocks.length > 0 || (selection && selection.toString().length > 0)) {
      e.preventDefault()
      setSelectedBlocks(allSelectedBlocks)
      setMenuPosition({x: e.clientX + window.scrollX, y: e.clientY + window.scrollY})
      setOpened(true)
    }
  }

  const onCloseOutsideMenu = useCallback((e: MouseEvent) => {
    if (!menuContainer.current!.contains(e.target as HTMLElement)) {
      setOpened(false)
    }
  }, [])

  function anyBlockSelectedForExclusion() {
    return selectedBlockIds.some(id => wordCountExcludedBlockIds.includes(id))
  }

  const updateExcludedWords = (newWordCountExcluded: string[]) => {
    updateExcludedWordsFromWordCount(project!._id, newWordCountExcluded)
      .then(() => {
        setWordCountExcludedBlockIds(newWordCountExcluded)
      })
      .finally(() => {
        setOpened(false)
        setSelectedBlocks([])
      })
  }

  const excludeFromWordCount = (e: React.MouseEvent) => {
    e.preventDefault()
    const newWordCountExcluded = Array.from(new Set(wordCountExcludedBlockIds.concat(...selectedBlockIds)))
    updateExcludedWords(newWordCountExcluded)
  }

  const includeToWordCount = (e: React.MouseEvent) => {
    e.preventDefault()
    const newWordCountExcluded = wordCountExcludedBlockIds.filter(id => !selectedBlockIds.includes(id))
    updateExcludedWords(newWordCountExcluded)
  }

  const moveToBookmark = (e: React.MouseEvent) => {
    e.preventDefault()
    EventBus.emit(EventType.MOVE_TO_BOOKMARK, {blockIds: selectedBlockIds})
  }

  return opened && menuPosition ? (
    <div className="context-menu" ref={menuContainer} style={{top: menuPosition.y, left: menuPosition.x}}>
      {anyBlockSelectedForExclusion() ? (
        <a onClick={includeToWordCount} className="context-menu-item">
          <span className="context-menu-item-text">{t('components.BlocksContextMenu.includeToWordCount')}</span>
        </a>
      ) : (
        <a onClick={excludeFromWordCount} className="context-menu-item">
          <span className="context-menu-item-text">{t('components.BlocksContextMenu.excludeFromWordCount')}</span>
        </a>
      )}
      <a className="context-menu-item" onClick={moveToBookmark}>
        <MoveToBookmarkBottom className="icon icon-sm me-1" />
        <span className="context-menu-item-text">{t('components.BlocksContextMenu.moveToBookmark')}</span>
      </a>
    </div>
  ) : (
    <></>
  )
}

export default BlocksContextMenu
