import {Locale} from 'domain/Locale'
import {Block, BlockType} from 'domain/Report'
import {tokenizeJapaneseTextByDelimiters} from 'domain/utils/textUtils'


class BlockTermsCollector {
  private textTerms = new Set<string>()
  private tableTerms = new Set<string>()

  constructor(private block: Block, private locale: Locale) {
    this.collectTerms()
  }

  terms() {
    return {
      tableTerms: Array.from(this.tableTerms),
      textTerms: Array.from(this.textTerms)
    }
  }

  private collectTerms(block = this.block) {
    const {locale} = this
    switch (block.type) {
      case BlockType.PARAGRAPH:
      case BlockType.HEADING:
        this.collectTextTerm(block.text[locale]?.value)
        return
      case BlockType.ALERT:
        this.collectTextTerm(block.message[locale]?.value)
        return
      case BlockType.LIST:
        ((block.items[locale]?.value || []).flat() as string[]).forEach(term => this.collectTextTerm(term))
        return
      case BlockType.IMAGE:
        this.collectTextTerm(block.title[locale]?.value)
        this.collectTextTerm(block.description[locale]?.value)
        return
      case BlockType.EXCEL_TABLE:
        this.collectTextTerm(block.title[locale]?.value)
        this.collectTextTerm(block.description[locale]?.value)
        const terms = (block.content[locale]?.value || []).flatMap(row => row.flatMap(cell => {
          const sanitizedCell = cell.removeHTMLTags().replace(/\n/g, '').replace(/ +/g, ' ')
          return Array.from(new Set([sanitizedCell || '', ...tokenizeJapaneseTextByDelimiters(sanitizedCell || '')]))
        }))
        terms.forEach(term => this.collectTableTerm(term))
        return
      case BlockType.COMMON_TABLE:
        this.collectTextTerm(block.title[locale])
        this.collectTextTerm(block.description[locale])
        const tableTerms = (block.content[locale] || []).flatMap(row => {
          return row.map(cell => {
            return (cell.value || '').removeHTMLTags().replace(/\n/g, '').replace(/ +/g, ' ')
          })
        })
        tableTerms.forEach(term => this.collectTableTerm(term))
        return
      case BlockType.GRID:
        return (block.grid[locale] || []).flatMap(row => row.flatMap(cell => cell.blocks.flatMap(block => {
          this.collectTerms(block)
        })))
      default:
        return
    }
  }

  private collectTextTerm(term: string | undefined | null) {
    if (term) this.textTerms.add(term.removeHTMLTags())
  }

  private collectTableTerm(term: string | undefined | null) {
    if (term) this.tableTerms.add(term.removeHTMLTags())
  }
}

export default BlockTermsCollector