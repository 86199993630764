interface Array<T> {
  first(): T

  last(): T | null

  equals<R>(array: any[], by?: (item: T) => R): boolean

  max<R>(by?: (item: T) => R): R

  groupBy(by: (item: T) => string): Record<string, T[]>

  groupByFirst(by: (item: T) => string): Record<string, T>

  sortBy(by: (item: T) => any): T[]

  subtract<R>(items: T[], by?: (item: T) => R): T[]

  uniqueBy(by: (item: T) => any): T[]

  prependIfPresent(item?: T): T[]
}

Array.prototype.first = function () {
  return this[0]
}

Array.prototype.last = function () {
  return this[this.length - 1]
}

Array.prototype.equals = function (array, by = v => v) {
  return array.length === this.length && this.every((item, i) => by(item) === by(array[i]))
}

Array.prototype.max = function (by = v => v) {
  return this.reduce(
    (r, e) => {
      const v = by(e)
      return v > r ? v : r
    },
    this.length ? by(this.first()) : undefined
  )
}

Array.prototype.groupBy = function (by) {
  return this.reduce((acc, e) => {
    const v = by(e)
    if (!acc[v]) acc[v] = []
    acc[v].push(e)
    return acc
  }, {})
}

Array.prototype.groupByFirst = function (by) {
  return this.reduce((acc, e) => {
    const v = by(e)
    if (!acc[v]) acc[v] = e
    return acc
  }, {})
}

Array.prototype.sortBy = function (by) {
  return this.sort((item1, item2) => {
    const byItem1 = by(item1)
    const byItem2 = by(item2)
    if (byItem1 === undefined || byItem1 === null) return 1
    if (byItem2 === undefined || byItem2 === null) return -1
    if (byItem1 === byItem2) return 0
    return byItem1 > byItem2 ? 1 : -1
  })
}

Array.prototype.subtract = function (items, by = v => v) {
  const mappedItems = items.map(by)
  return this.filter(item => !mappedItems.includes(by(item)))
}

Array.prototype.uniqueBy = function (by) {
  const seenItems = {} as Record<any, any>

  return this.filter(item => {
    if (seenItems[by(item)]) return false
    seenItems[by(item)] = item
    return true
  })
}

Array.prototype.prependIfPresent = function (item) {
  if (!item) {
    return this
  }

  this.unshift(item)

  return this
}
