import classNames from 'classnames'
import {ReferencedBlock} from 'domain/MicroTranslation'
import React from 'react'
import {useTranslation} from 'react-i18next'
import ReferenceBlockCard from './ReferenceBlockCard'


interface Props {
  classes: any
  content: string
  reference?: ReferencedBlock
}

const MicroTranslationOriginalContent: React.FC<Props> = props => {
  const {classes, content, reference} = props
  const {t} = useTranslation()

  return <div className={classNames({...classes, 'bg-body': true})}>
    <div className="card-body p-3">
      <div className="mb-2 mt-n1">
        <div className="text-muted-more small">{t('components.MicroTranslationColumn.original')}</div>
      </div>
      <div dangerouslySetInnerHTML={{__html: content}}/>
    </div>
    {reference && <ReferenceBlockCard reference={reference}/>}
  </div>
}

export default MicroTranslationOriginalContent