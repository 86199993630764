import {Company, CompanyStatus} from 'domain/Company'
import {Errors} from 'domain/Errors'
import {Locale} from 'domain/Locale'
import {User} from 'domain/User'
import {trimStartSpacesForText} from 'domain/utils/textUtils'
import assignDeep from 'object-assign-deep'
import React, {ReactElement, useContext, useEffect, useState} from 'react'
import SelectSearch, {SelectItemRenderer} from 'react-dropdown-select'
import {useTranslation} from 'react-i18next'
import {getCompanyClassificationGICS} from '../../api-clients/companyClient'
import {getAnalysts} from '../../api-clients/userClient'
import {ReactComponent as DoneIcon} from '../../assets/icons/done.svg'
import useModal from '../../hooks/useModal'
import {LocaleContext} from '../../providers/LocaleProvider'
import {formattedMonth} from '../../utils/momentUtils'
import ConfirmationModal from '../utils/ConfirmationModal'
import Input from '../utils/form/Input'
import MultipleSelect from '../utils/form/MultipleSelect'
import {onHandleKeyDownFn, SelectOption} from '../utils/form/SearchSelectHelper'
import Select from '../utils/form/Select'
import SelectWithLabel from '../utils/form/SelectWithLabel'
import Textarea from '../utils/form/Textarea'
import ModalTemplate from '../utils/ModalTemplate'

interface Props {
  company?: Company
  onConfirm: (company: Company) => Promise<any>
}

const CompanyForm: React.FC<Props> = (props): ReactElement => {
  const isNewCompany = !props.company
  const {closeModal} = useModal()
  const {t} = useTranslation()
  const [errors, setErrors] = useState<Errors<Company>>({})
  const [analysts, setAnalysts] = useState<User[]>([])
  const [loading, setLoading] = useState(true)
  const [gicsClassification, setGicsClassification] = useState<SelectOption[]>([])
  const {locale} = useContext(LocaleContext)
  const {showModal} = useModal()

  const [company, setCompany] = useState<Company>(props.company || {
    name: {
      [Locale.EN]: '',
      [Locale.JA]: ''
    },
    shortName: {
      [Locale.EN]: '',
      [Locale.JA]: ''
    },
    status: CompanyStatus.DRAFT,
    isZeroYen: false
  } as Company)

  useEffect(() => {
    Promise.all([getAnalysts(), getCompanyClassificationGICS()]).then(([analysts, gics]) => {
      setAnalysts(analysts)
      setGicsClassification(gics.map(gics => ({value: gics, label: t(`gics.${gics}`)})))
      setLoading(false)
    })
  }, [])

  const onChange = (event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>) => {
    event.preventDefault()

    const {name, value} = event.target
    setCompany(company => ({...company, [name]: value ? trimStartSpacesForText(value) : null}))
  }

  const onLocalizedFieldChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, locale: Locale) => {
    event.preventDefault()

    const {name, value} = event.target
    setCompany(company => assignDeep({}, company, {[name]: {[locale]: trimStartSpacesForText(value)}}))
  }

  const onConfirm = (event: React.MouseEvent<HTMLButtonElement> | React.FormEvent) => {
    event.preventDefault()
    const submit = () => {
      return props.onConfirm(company).then(_ => {
        closeModal()
      }).catch(({errors}) => {
        if (errors.duplicatedTick)
          setErrors({...errors, tick: 'duplicatedTick'})
        else
          setErrors(errors)
      })
    }

    if (!!props.company && props.company.isZeroYen !== company.isZeroYen) {
      confirmZeroYenChangeSubmit(submit)
    } else {
      submit().then(_ => {
      })
    }
  }


  const onAssistantAnalystsChange = (assistantAnalystIds: string[]) => {
    setCompany(company => ({...company, assistantAnalystIds}))
  }

  const onStatusChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setCompany(company => ({...company, status: e.target.value as CompanyStatus}))
  }

  const onChangeGics = (options: SelectOption[]) => {
    const classification = {...company.classification, gics: options.map(option => option.value)}
    const newCompany = {...company, classification}
    setCompany(newCompany)
  }

  const selectSearchItemRenderer = ({item, methods}: SelectItemRenderer<SelectOption>) => {
    return (
      <div className="btn-outline-secondary p-2" onClick={() => methods.addItem(item)}>
        {item.label}
      </div>
    )
  }

  const selectedGicsValues = () => {
    return (company.classification?.gics || []).map(gics => ({value: gics, label: t(`gics.${gics}`)}))
  }

  const title = t('components.Companies.CompanyForm.' + (isNewCompany ? 'createCompany' : 'editCompany'))

  const reportLocales = [Locale.EN, Locale.JA]

  const onZeroYenChange = async () => {

    return setCompany({...company, isZeroYen: !company.isZeroYen})
  }

  const confirmZeroYenChangeSubmit = (onConfirm: () => Promise<void>) => {
    const body = company.isZeroYen ? 'toZeroYen' : 'toNotZeroYen'
    showModal(<ConfirmationModal
      title={t('components.Companies.CompanyForm.confirmZeroYenChange.title')}
      ConfirmationIcon={DoneIcon}
      closeOnlyLast={true}
      body={t(`components.Companies.CompanyForm.confirmZeroYenChange.${body}`)}
      onConfirm={onConfirm}/>)
  }

  return (
    <ModalTemplate size="xl" title={title}>
      <form onSubmit={onConfirm}>
        <div className="modal-body">
          <div className="row g-5 row-divided-columns">
            <div className="col-lg-6">
              <div className="row">
                <div className="mb-3 col-lg-6">
                  <label id="company-name" className="form-label small">
                    {t('models.Company.name')}
                  </label>
                  <div className="input-groups-stacked">
                    {reportLocales.map(locale => (
                      <div key={`name-${locale}`} className="input-group">
                        <Input className="form-control" name="name" aria-describedby="company-name"
                               value={company.name![locale] || ''} onChange={e => onLocalizedFieldChange(e, locale)}
                               error={errors[`name.${locale}`]}
                               placeholder={t(`models.Company.languagePlaceholder.${locale}`)} required/>
                      </div>
                    ))}
                    <div className="input-group">
                      <Input className="form-control" id="japaneseSpellingName" name="japaneseSpellingName"
                             aria-describedby="japaneseSpellingName"
                             value={company.japaneseSpellingName || ''} onChange={onChange}
                             placeholder={t('models.Company.japaneseSpellingName')}
                             error={errors.japaneseSpellingName} required/>
                    </div>
                  </div>
                </div>
                <div className="mb-3 col-lg-6">
                  <label id="company-short-name" className="form-label small">
                    {t('models.Company.shortName')}
                  </label>
                  <div className="input-groups-stacked">
                    {reportLocales.map(locale => (
                      <div key={`name-${locale}`} className="input-group">
                        <Input className="form-control" name="shortName" aria-describedby="company-short-name"
                               value={company.shortName![locale] || ''}
                               onChange={e => onLocalizedFieldChange(e, locale)}
                               error={errors[`shortName.${locale}`]}
                               placeholder={t(`models.Company.languagePlaceholder.${locale}`)} required/>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="mb-3 col-lg-12">
                  <label id="headOfficeAddress" className="form-label small">
                    {t('models.Company.headOfficeAddress')}
                  </label>
                  <div className="input-groups-stacked">
                    {reportLocales.map(locale => (
                      <div className="input-group" key={`headOfficeAddress-${locale}`}>
                        <Textarea className="form-control"
                                  value={company.headOfficeAddress?.[locale] || ''} name="headOfficeAddress"
                                  aria-describedby="headOfficeAddress"
                                  onChange={e => onLocalizedFieldChange(e, locale)}
                                  placeholder={t(`models.Company.languagePlaceholder.${locale}`)}
                                  error={errors[`headOfficeAddress.${locale}`]}/>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="mb-3 col-lg-4">
                  <label htmlFor="company-tick" className="form-label small">
                    {t('models.Company.tick')}
                  </label>
                  <Input className="form-control" id="company-tick" aria-describedby="company-tick" required
                         name="tick" value={company.tick || ''} onChange={onChange} error={errors.tick}/>
                </div>

                {['phone', 'website'].map(field => (
                  <div className="mb-3 col-lg-4" key={field}>
                    <label htmlFor={field} className="form-label small">
                      {t('models.Company.' + field)}
                    </label>
                    <Input className="form-control" id={field} name={field} aria-describedby={field}
                           value={company[field] || ''} onChange={onChange}
                           error={errors[field]}/>
                  </div>
                ))}
                <div className="mb-3 col-lg-12">
                  <label className="form-label small">
                    {t('models.Company.industry')}
                  </label>
                  {gicsClassification && <SelectSearch options={gicsClassification}
                                                       onChange={onChangeGics}
                                                       handleKeyDownFn={onHandleKeyDownFn}
                                                       multi={true}
                                                       itemRenderer={selectSearchItemRenderer}
                                                       values={selectedGicsValues()}/>}
                </div>
                <div className="mb-3 col-lg-12">
                  <label id="summary" className="form-label small">
                    {t('models.Company.summary')}
                  </label>
                  <div className="input-groups-stacked">
                    {reportLocales.map(locale => (
                      <div className="input-group" key={`summary-${locale}`}>
                        <Textarea className="form-control"
                                  value={company.summary?.[locale] || ''} name="summary"
                                  aria-describedby="summary"
                                  onChange={e => onLocalizedFieldChange(e, locale)}
                                  placeholder={t(`models.Company.languagePlaceholder.${locale}`)}
                                  error={errors[`summary.${locale}`]}/>
                      </div>
                    ))}
                  </div>
                </div>

              </div>
            </div>
            <div className="col-lg-6">

              <h5 className="card-title">
                {t('components.Companies.CompanyForm.investorRelations')}
              </h5>
              <div className="row">
                {['irContact', 'irPhone', 'irEmail', 'irWebsite'].map(field => {
                  return (
                    <div className="mb-3 col-lg-6" key={field}>
                      <label id={field} className="form-label small">
                        {t('models.Company.' + field)}
                      </label>
                      <div className="input-groups-stacked">
                        {reportLocales.map(locale => (
                          <div className="input-group" key={`${field}-${locale}`}>
                            <Input className="form-control" name={field} aria-describedby={field}
                                   value={company[field]?.[locale] || ''}
                                   onChange={e => onLocalizedFieldChange(e, locale)}
                                   placeholder={t(`models.Company.languagePlaceholder.${locale}`)}
                                   error={errors[field]?.[locale]}/>
                          </div>
                        ))}
                      </div>
                    </div>)
                })
                }
              </div>

              <h5 className="card-title">
                {t('components.Companies.CompanyForm.additionalCompanyInfo')}
              </h5>
              <div className="row">
                <div className="col-lg-4">
                  {['establishedAt', 'listedAt'].map(field =>
                    (<div className="mb-3" key={field}>
                      <label htmlFor={field} className="form-label small">
                        {t('models.Company.' + field)}
                      </label>
                      <Input className="form-control" id={field} name={field} type="date"
                             aria-describedby={field}
                             value={company[field] as string || ''} onChange={onChange}
                             error={errors[field]}/>
                    </div>))}
                </div>

                <div className="col-lg-4">
                  <div className="mb-3">
                    <label htmlFor="coverageInitiatedAt" className="form-label small">
                      {t('models.Company.coverageInitiatedAt')}
                    </label>
                    <Input className="form-control" id="coverageInitiatedAt" name="coverageInitiatedAt" type="date"
                           aria-describedby="coverageInitiatedAt"
                           value={company.coverageInitiatedAt as string || ''} onChange={onChange}
                           error={errors.coverageInitiatedAt}>
                    </Input>
                  </div>
                  <div className="mb-3">
                    <label htmlFor="financialYearEnd" className="form-label small">
                      {t('models.Company.financialYearEnd')}
                    </label>
                    <Select id="financialYearEnd" className="form-select" name="financialYearEnd"
                            aria-describedby="financialYearEnd"
                            value={company.financialYearEnd} onChange={onChange}>
                      <option/>
                      {Array.from({length: 12}).map((_, m) => (
                        <option key={m} value={m}>
                          {formattedMonth(m, locale)}
                        </option>
                      ))}
                    </Select>
                  </div>
                </div>
                <div className="mb-3 col-lg-4" key="exchangeName">
                  <label id="exchangeName" className="form-label small">
                    {t('models.Company.exchangeName')}
                  </label>
                  <div className="input-groups-stacked">
                    {reportLocales.map(locale => (
                      <div className="input-group" key={`exchangeName-${locale}`}>
                        <Input className="form-control" name="exchangeName"
                               value={company.exchangeName?.[locale] || ''}
                               aria-describedby="exchangeName"
                               onChange={e => onLocalizedFieldChange(e, locale)}
                               placeholder={t(`models.Company.languagePlaceholder.${locale}`)}
                               error={errors[`exchangeName.${locale}`]}/>
                      </div>
                    ))}
                  </div>
                </div>

                <h5 className="card-title">
                  {t('components.Companies.CompanyForm.analysts')}
                </h5>
                <div className="col-6 mb-3">
                  <label htmlFor="senior-analyst" className="form-label small">
                    {t('models.Company.seniorAnalyst')}
                  </label>
                  <Select id="senior-analyst" className="form-select" value={company.seniorAnalystId as string || ''}
                          onChange={onChange} name="seniorAnalystId" error={errors.seniorAnalystId}
                          disabled={loading}>
                    <option value="">{t('components.Companies.CompanyForm.selectSeniorAnalyst')}</option>
                    {analysts.map(analyst =>
                      <option key={analyst._id as string} value={analyst._id as string}>{analyst.name}</option>
                    )}
                  </Select>
                </div>
                <div className="col-6 mb-3">
                  <label className="form-label small">
                    {t('models.Company.assistantAnalysts')}
                  </label>
                  <MultipleSelect htmlId="assistantAnalysts" name="assistantAnalysts"
                                  placeholder={t('components.Companies.CompanyForm.selectAssistantAnalysts')}
                                  label={(user: User) => user.name!} collection={analysts}
                                  selectedValues={company.assistantAnalystIds as string[]}
                                  onChange={onAssistantAnalystsChange}
                  />
                </div>
              </div>

              <h5 className="card-title">{t('components.Companies.CompanyForm.contract')}</h5>
              <div className="row mb-3">
                {isNewCompany ? (
                  <SelectWithLabel
                    options={Object.values(CompanyStatus).filter(status => status !== CompanyStatus.INACTIVE)}
                    labelKey={'models.Company.status'}
                    baseOptionsKey={'models.CompanyStatus'}
                    name={'companyStatus'}
                    value={company.status}
                    onChange={onStatusChange}
                    className={'col-6 mb-3'}
                  />
                ) : null}

                <div className="col-6">
                  <label htmlFor="contractEndsAt" className="form-label small">
                    {t('models.Company.contractEndsAt')}
                  </label>
                  <Input
                    className="form-control"
                    id="contractEndsAt"
                    name="contractEndsAt"
                    type="date"
                    value={company.contractEndsAt || ''}
                    onChange={onChange}
                    error={errors.contractEndsAt}
                  ></Input>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <input type="checkbox" className="form-check-input" onChange={onZeroYenChange} id="is-zero-yen" checked={company.isZeroYen} />
                  <label className="form-check-label ms-2" htmlFor="is-zero-yen">
                    {t('models.Company.isZeroYen')}
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className=" modal-footer">
          <button className="btn btn-success btn-with-icon" id={'submitCompanyForm'}>
            <span>{t('components.Companies.CompanyForm.confirm')}</span>
            <DoneIcon className="icon" />
          </button>
        </div>
      </form>
    </ModalTemplate>
  )
}

export default CompanyForm