const japaneseToUnicodeMap = {
  '１': 1,
  '２': 2,
  '３': 3,
  '４': 4,
  '５': 5,
  '６': 6,
  '７': 7,
  '８': 8,
  '９': 9,
  '０': 0
}

export const normalizeSearch = (rawSearch: string) => {
  return rawSearch
    .toLowerCase()
    .trim()
    .replace(/[０-９]/g, r => japaneseToUnicodeMap[r])
}
