import {Locale} from 'domain/Locale'
import {ProjectWithReport} from 'domain/Project'
import {BlockType} from 'domain/Report'
import React from 'react'
import FormattedDate from '../../utils/FormattedDate'


type Props = {
  newsReport: ProjectWithReport
  locale: Locale
}

const NewsReleaseTitle: React.FC<Props> = ({newsReport, locale}) => {
  const id = newsReport._id as string

  return <div className="report-content-block" id={id} data-block={BlockType.HEADING}>
    <h4 id={`title-${id}`}>{newsReport.reportTitle?.[locale] || ''}</h4>
    <div className="text-muted mt-n2 mb-2 fw-bold small">
      <FormattedDate date={newsReport.publishedAt?.[locale]!}/>
    </div>
  </div>
}

export default NewsReleaseTitle