import {Locale} from 'domain/Locale'
import {
  AlertBlock,
  Block,
  BlockType,
  CommonTableBlock,
  ExcelTableBlock,
  GridBlock,
  HeadingBlock,
  ImageBlock,
  ListBlock,
  ParagraphBlock
} from 'domain/Report'
import deepCopy from '../../ui/src/utils/deepCopy'


export const hasTranslation = (block: Block) => {
  return translationDetector[block.type](block)
}

export const anyBlockContainsTranslation = (blocks: Block[]) => {
  return blocks.some(block => hasTranslation(block))
}

export const copyBlockContent = (source: Block, dest: Block, locale: Locale) => translationCopy[source.type]({
  source, dest, locale
})

const translationDetector: { [key in BlockType]: (block: Block) => boolean } = {
  [BlockType.HEADING]: (block: Block) => {
    return !!(block as HeadingBlock).text.en?.value
  },
  [BlockType.PARAGRAPH]: (block: Block) => {
    return !!(block as ParagraphBlock).text.en?.value
  },
  [BlockType.LIST]: (block: Block) => {
    const value = (block as ListBlock).items.en?.value
    return value && value.length > 0 || false
  },
  [BlockType.EXCEL_TABLE]: (block: Block) => {
    const excel = (block as ExcelTableBlock)
    const content = excel.content.en?.value
    return !!excel.title.en?.value || !!excel.description.en?.value || content && content.length > 0 || false
  },
  [BlockType.COMMON_TABLE]: (block: Block) => {
    const table = (block as CommonTableBlock)
    const content = table.content.en
    return !!table.title.en || !!table.description.en || content && content.length > 0 || false
  },
  [BlockType.IMAGE]: (block: Block) => {
    const image = (block as ImageBlock)
    return !!image.title.en?.value || !!image.description.en?.value || !!image.file.en?.url
  },
  [BlockType.ALERT]: (block: Block) => {
    return !!(block as AlertBlock).message.en?.value
  },
  [BlockType.BOOKMARK]: (block: Block) => {
    return false
  },
  [BlockType.GRID]: (block: Block) => {
    const grid = (block as GridBlock)
    const content = grid.grid.en
    return !!grid.title.en?.value || !!grid.description.en?.value || content && content.length > 0 || false
  },
  [BlockType.CLONE]: (block: Block) => {
    return false
  }
}

type translationCopyParams = {
  source: Block,
  dest: Block,
  locale: Locale
}

const translationCopy: { [key in BlockType]: (params: translationCopyParams) => Block } = {
  [BlockType.HEADING]: (params: translationCopyParams) => {
    const {source, dest} = deepCopyBlocks<HeadingBlock>(params.source, params.dest)

    if (source.text[params.locale]?.value) {
      dest.text[params.locale] = source.text[params.locale]
    }

    return dest
  },
  [BlockType.PARAGRAPH]: (params: translationCopyParams) => {
    const {source, dest} = deepCopyBlocks<ParagraphBlock>(params.source, params.dest)

    if (source.text[params.locale]?.value) {
      dest.text[params.locale] = deepCopy(source.text[params.locale])
    }

    return dest
  },
  [BlockType.LIST]: (params: translationCopyParams) => {
    const {source, dest} = deepCopyBlocks<ListBlock>(params.source, params.dest)

    if (source.items[params.locale]?.value) {
      dest.items[params.locale] = source.items[params.locale]
    }

    return dest
  },
  [BlockType.EXCEL_TABLE]: (params: translationCopyParams) => {
    const {source, dest} = deepCopyBlocks<ExcelTableBlock>(params.source, params.dest)

    if (source.layout[params.locale]) {
      dest.layout[params.locale] = source.layout[params.locale]
    }

    if (source.title[params.locale]?.value) {
      dest.title[params.locale] = source.title[params.locale]
    }

    if (source.description[params.locale]?.value) {
      dest.description[params.locale] = source.description[params.locale]
    }

    if (source.content[params.locale]?.value) {
      dest.content[params.locale] = source.content[params.locale]
    }

    return dest
  },
  [BlockType.COMMON_TABLE]: (params: translationCopyParams) => {
    const {source, dest} = deepCopyBlocks<CommonTableBlock>(params.source, params.dest)

    if (source.title[params.locale]) {
      dest.title[params.locale] = source.title[params.locale]
    }

    if (source.description[params.locale]) {
      dest.description[params.locale] = source.description[params.locale]
    }

    if (source.content[params.locale]) {
      dest.content[params.locale] = source.content[params.locale]
    }

    return dest
  },
  [BlockType.IMAGE]: (params: translationCopyParams) => {
    const {source, dest} = deepCopyBlocks<ImageBlock>(params.source, params.dest)

    if (source.file[params.locale]?.url) {
      dest.file[params.locale] = source.file[params.locale]
    }

    if (source.title[params.locale]?.value) {
      dest.title[params.locale] = source.title[params.locale]
    }

    if (source.description[params.locale]?.value) {
      dest.description[params.locale] = source.description[params.locale]
    }

    return dest
  },
  [BlockType.ALERT]: (params: translationCopyParams) => {
    const {source, dest} = deepCopyBlocks<AlertBlock>(params.source, params.dest)

    if (source.message[params.locale]?.value) {
      dest.message[params.locale] = source.message[params.locale]
    }

    return dest
  },
  [BlockType.BOOKMARK]: (params: translationCopyParams) => {
    return deepCopy(params.source)
  },
  [BlockType.GRID]: (params: translationCopyParams) => {
    const {source, dest} = deepCopyBlocks<GridBlock>(params.source, params.dest)

    if (source.title[params.locale]?.value) {
      dest.title[params.locale] = source.title[params.locale]
    }

    if (source.description[params.locale]?.value) {
      dest.description[params.locale] = source.description[params.locale]
    }

    if (source.grid[params.locale]) {
      dest.grid[params.locale] = source.grid[params.locale]
    }

    return dest
  },
  [BlockType.CLONE]: (params: translationCopyParams) => {
    return deepCopy(params.source)
  }
}

const deepCopyBlocks = <T extends Block>(source: Block, dest: Block): { source: T, dest: T } => {
  return {
    source: deepCopy(source) as T,
    dest: deepCopy(dest) as T
  }
}