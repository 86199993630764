import {Entity} from 'domain/Entity'
import {ProjectType} from 'domain/Project'
import {Section} from 'domain/Report'


export enum ReportType {
  FULL_REPORT = 'FULL_REPORT',
  SECTION_REPORT = 'SECTION_REPORT',
  SELECTED_PARTS = 'SELECTED_PARTS',
  NEWS_REPORT = 'NEWS_REPORT',
  TEASER = 'TEASER'
}

export interface ReportTemplate extends Omit<Entity, 'createdAt' | 'updatedAt'> {
  sections: Section[]
}

export const reportTypeByProjectType = (projectType: ProjectType) => {
  switch (projectType) {
    case ProjectType.FLASH:
      return ReportType.SELECTED_PARTS
    case ProjectType.NEWS_UPDATE:
      return ReportType.NEWS_REPORT
    default:
      return ReportType.FULL_REPORT
  }
}