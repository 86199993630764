import React, {FC, ReactElement, useEffect, useState} from 'react'
import ProjectCard from '../ProjectCard'
import {ProjectAggregate} from 'domain/ProjectAggregate'

interface ProjectsColumnProps {
  loadProjects: () => Promise<ProjectAggregate[]>
}

const ProjectsColumn: FC<ProjectsColumnProps> = ({loadProjects}): ReactElement => {
  const [projects, setProjects] = useState<ProjectAggregate[]>([])

  useEffect(() => {
    loadProjects().then(setProjects)
  }, [])

  return (
    <>
      {projects.map(project => {
        return <ProjectCard project={project} key={project._id as string}/>
      })}
    </>
  )
}

export default ProjectsColumn