import {ProjectLog} from 'domain/ProjectLog'
import React from 'react'
import {useTranslation} from 'react-i18next'
import RawHtml from '../../../utils/RawHtml'


interface LogProps {
  log: ProjectLog
}

const ReportRegenerationLogLabel: React.FC<LogProps> = ({log}) => {
  const {t} = useTranslation()
  return <RawHtml>{t(`components.ProjectLogs.${log.type}.${log.params.locale}`)}</RawHtml>
}

export default ReportRegenerationLogLabel