import {Id} from 'domain/Entity'
import {Project} from 'domain/Project'
import React, {ReactElement, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'
import {getProjects} from '../../api-clients/projectClient'
import useModal from '../../hooks/useModal'
import Select from '../utils/form/Select'
import ModalTemplate from '../utils/ModalTemplate'


const SelectProjectToMerge: React.FC<{ project: Project }> = ({project}): ReactElement => {
  const {t} = useTranslation()
  const history = useHistory()
  const {closeModal} = useModal()
  const [loading, setLoading] = useState(true)
  const [projects, setProjects] = useState<Project[]>([])
  const [baseProject, setBaseProject] = useState<Project | null>(null)
  const [source, setSource] = useState<Id | null>(null)

  useEffect(() => {
    if (!!project.sourceProjectId) {
      getProjects().then(projects => {
        setProjects(projects.filter(p => project.sourceProjectId === p.sourceProjectId && project._id !== p._id))
        setBaseProject(projects.find(p => project.sourceProjectId === p._id)!)
        setLoading(false)
      })
    }
  }, [])

  const onProjectSourceChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSource(event.target.value)
  }

  const navigateToMergeView = (event: React.MouseEvent) => {
    event.preventDefault()
    history.push(`/projects/${project._id}/merge?importedProjectId=${source}&baseProjectId=${baseProject?._id}`)
    closeModal()
  }

  return (<ModalTemplate title={t('components.SelectProjectToMerge.title')}>
    <div className="modal-body">

      {project.sourceProjectId && projects.length === 0 && (
        <div className="alert alert-info mb-0">{t('components.SelectProjectToMerge.noProjects')}</div>
      )}

      {!project.sourceProjectId &&
        <div className="alert alert-info mb-0">
          {t('components.SelectProjectToMerge.notImport')}
        </div>
      }

      {project.sourceProjectId && projects.length > 0 && (
        <div className="row g-2">
          <div className="col-8">
            <Select className="form-select" id="project-source" name="projectSource"
                    onChange={onProjectSourceChange} disabled={loading}>
              <option>{t('components.SelectProjectToMerge.selectProject')}</option>
              {projects.map(project =>
                <option value={project._id as string} key={project._id as string}>
                  {project.title} - {t('models.ProjectType.' + project.type)}
                </option>
              )}
            </Select>

          </div>
          <div className="col-4">
            <button className="btn btn-primary" disabled={!source} onClick={navigateToMergeView}>
              <span>{t('components.SelectProjectToMerge.import')}</span>
            </button>
          </div>
        </div>
      )}
    </div>
  </ModalTemplate>)
}

export default SelectProjectToMerge
