import {Locale} from 'domain/Locale'
import {PageType} from 'domain/PageType'
import {containsNewsUpdates, GroupedNews} from 'domain/Project'
import {ReportState} from 'domain/Report'
import React, {ReactElement, useContext, useEffect, useState} from 'react'
import {getGroupedNewsProjectsWithReports, getReport} from '../../api-clients/reportClient'
import useCurrentLocationVisibilityInSidebar from '../../hooks/useCurrentLocationVisibilityInSidebar'
import useForceRender from '../../hooks/useForceRender'
import {useProjectDocumentTitle} from '../../hooks/useProjectDocumentTitle'
import useReportPage from '../../hooks/useReportPage'
import {ReportContext} from '../../providers/ReportProvider'
import Spinner from '../utils/Spinner'
import LanguageSwitcher from './LanguageSwitcher'
import SidebarWithReportChangeListener from './navigation/SidebarWithReportChangeListener'
import ReportContent from './preview/ReportContent'
import ReportToc from './preview/ReportToc'
import ReportNavigation from './report-navigation/ReportNavigation'
import ShowIf, {notNewsUpdateType} from './ShowIf'

const PreviewReportPage: React.FC = (): ReactElement => {
  const [reportLocale, setReportLocale] = useState<Locale>(Locale.JA)
  const [groupedNews, setGroupedNews] = useState<GroupedNews>({recentUpdates: [], newsAndTopics: []})
  const {company, project, reportStore} = useContext(ReportContext)
  useReportPage()
  const {rerender} = useForceRender()
  const [report, setReport] = useState<ReportState | null>(null)
  useProjectDocumentTitle(project, PageType.PREVIEW)

  useEffect(() => {
    if (!project) return
    if (!containsNewsUpdates(project.type)) return
    getGroupedNewsProjectsWithReports(project._id, reportLocale).then(setGroupedNews)
  }, [project, reportLocale])

  useEffect(() => {
    reportStore.onUpdateStructure(() => {
      rerender()
    })
  }, [])

  useEffect(() => {
    if (project) getReport(project._id).then(report => setReport(report))
  }, [project])

  const onChangeReportLocale = (locale: Locale) => {
    setReportLocale(locale)
  }

  useCurrentLocationVisibilityInSidebar('[data-section]', [reportStore.getReport()])

  return report ? (
    <div>
      <div className="navbar navbar-secondary">
        {project && company && (
          <ReportNavigation
            titleKey={'components.Report.PreviewReport.title'}
            project={project}
            company={company}
            locale={reportLocale}
          >
            <div className="btn-group ms-2" role="group">
              <LanguageSwitcher activeLocale={reportLocale} setLocale={onChangeReportLocale} />
            </div>
          </ReportNavigation>
        )}
      </div>
      {report && company && (
        <div className="report-content-wrapper">
          <ShowIf rule={notNewsUpdateType}>
            <SidebarWithReportChangeListener
              reportStore={reportStore}
              locale={reportLocale}
              displayMenu={false}
              groupedNews={groupedNews}
              displayNewsIfEmpty={false}
            />
          </ShowIf>
          <div className="report-content report-output flex-grow-1 container container-editor">
            <ReportToc report={report} locale={reportLocale} groupedNews={groupedNews} />
            <ReportContent report={report} locale={reportLocale} lazy={true} groupedNews={groupedNews} />
          </div>
        </div>
      )}
    </div>
  ) : (
    <Spinner withDimmer />
  )
}

export default PreviewReportPage
