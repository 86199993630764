import {ObjectId} from 'bson'
import diffPatch from 'domain/diffPatch'
import {Locale} from 'domain/Locale'
import {Project} from 'domain/Project'
import {Block, ReportState} from 'domain/Report'
import {ReactComponent as DoneIcon} from '../../assets/icons/done.svg'
import React, {useEffect, useMemo, useState} from 'react'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'
import {useTranslation} from 'react-i18next'
import {useHistory, useParams} from 'react-router-dom'
import {getProject} from '../../api-clients/projectClient'
import {getReport, updateReport, updateRevisionName} from '../../api-clients/reportClient'
import EventBus, {EventType} from '../../EventBus'
import useToast from '../../hooks/useToast'
import scrollToElement from '../../utils/scrollToElement'
import LazyGradually from '../utils/LazyGradually'
import Spinner from '../utils/Spinner'
import Editor, {EditorMode} from './edit/Editor'
import {BlockMergeResult} from './merge/BlockMergeResult'
import {ToolbarSide} from './merge/BlockMergeToolbar'
import BlockSource from './merge/BlockSource'
import MergeNavigation from './merge/MergeNavigation'
import ReadonlyBlock from './merge/ReadonlyBlock'
import {BlockMergeStatus, ReportSectionsMerger, SectionMergeResult} from './merge/ReportSectionsMerger'
import MergeConflictsCount from './MergeConflictsCount'


interface RouteParams {
  projectId: string
}

const ReportMergingPage = () => {
  const {projectId} = useParams<RouteParams>()
  const {t, i18n} = useTranslation()

  const urlSearchParams = new URLSearchParams(window.location.search)
  const [importedProjectId] = useState<string | null>(urlSearchParams.get('importedProjectId'))
  const [commonBaseProjectId] = useState<string | null>(urlSearchParams.get('baseProjectId'))
  const {renderErrorToast, renderSuccessToast} = useToast()
  const history = useHistory()
  const [currentProject, setCurrentProject] = useState<Project | null>(null)
  const [importedProject, setImportedProject] = useState<Project | null>(null)
  const [currentReport, setCurrentReport] = useState<ReportState | null>(null)
  const [mergeResult, setMergeResult] = useState<SectionMergeResult[]>([])
  const [locale, setLocale] = useState(Locale.JA)
  const [loading, setLoading] = useState(true)
  const allConflictsResolved = useMemo(() => {
    return mergeResult.every(section => {
      return section.mergedBlocks.every(blockMergeResult => !blockMergeResult.isResolutionRequired(locale))
    })
  }, [locale, mergeResult])

  useEffect(() => {
    if (!importedProjectId || !commonBaseProjectId) {
      renderErrorToast(t('components.ReportMergingPage.noImport'))
      history.push(`/projects/${projectId}`)
    }

    Promise.all([getProject(projectId), getProject(importedProjectId!)]).then(([current, imported]) => {
      setCurrentProject(current)
      setImportedProject(imported)

      return Promise.all([getReport(current._id), getReport(imported._id), getReport(commonBaseProjectId!)])
    }).then(([currentReport, importedReport, baseReport]) => {
      setCurrentReport(currentReport)
      const reportMerge = new ReportSectionsMerger(baseReport.sections, currentReport.sections, importedReport.sections)
      const mergeResults = reportMerge.merge()
      setMergeResult(mergeResults)
      setLoading(false)
    })

  }, [])

  const confirmChanges = (event: React.MouseEvent) => {
    event.preventDefault()
    const successMessage = t('components.ReportMergingPage.resolved.' + locale)

    if (locale === Locale.JA) {
      setLocale(Locale.EN)
      setTimeout(() => {
        const header = document.querySelector('.report-merge-view-header')
        if (header) scrollToElement(header)
      })
    } else {
      const revisionId = new ObjectId()
      const updatedReportSections = mergeResult.map(section => section.toSection())

      const diff = diffPatch.diff({sections: currentReport!.sections}, {sections: updatedReportSections})
      updateReport(revisionId, currentProject!._id, currentReport!.updateHash, undefined, diff).then(() => {
        renderSuccessToast(t('components.ReportMergingPage.importSuccess'))
        history.push(`projects/${projectId}`)
        updateRevisionName(revisionId, '<- ' + importedProject!.title)
      })
    }
    renderSuccessToast(successMessage)
  }

  const resolveMerge = (event: React.MouseEvent, blockMergeResult: BlockMergeResult) => {
    event.preventDefault()
    blockMergeResult.resolve(locale)
    setMergeResult([...mergeResult])
    setTimeout(() => EventBus.emit(EventType.MERGE_CONFLICT_RESOLVED))
  }

  const resolveTooltip =
    <Tooltip id="resolve-tooltip" className={`${loading || !allConflictsResolved ? '' : 'd-none'}`}>
      {loading || !allConflictsResolved ? t('components.ReportMergingPage.resolveFirst') : ''}
    </Tooltip>

  return (
    <>
      <div className="navbar navbar-secondary">
        {!loading && currentProject && mergeResult.length > 0 && (
          <MergeNavigation project={currentProject}>
            <div className="navbar-nav mx-2">
              <MergeConflictsCount mergeResult={mergeResult} locale={locale}/>
            </div>

            <div className="navbar-nav">
              <OverlayTrigger overlay={resolveTooltip} placement="bottom">
                <button className="btn btn-primary" onClick={confirmChanges}
                        disabled={loading || !allConflictsResolved}>
                  {t('components.ReportMergingPage.confirm.' + locale)}
                </button>
              </OverlayTrigger>
            </div>
          </MergeNavigation>
        )}
      </div>

      <div className="row text-center report-merge-view-header border-bottom">
        <div className="col-4 py-2 border-right">
          <h5>{currentProject?.title}</h5>
        </div>
        <div className="col-4 py-2">
          <h5>{t('components.ReportMergingPage.result')}</h5>
        </div>
        <div className="col-4 py-2 border-left">
          <h5>{importedProject?.title}</h5>
        </div>
      </div>

      {
        loading ? <Spinner withDimmer/> :
          mergeResult.map((section, sectionIdx) => (
            <div className="section" key={`section-${sectionIdx}`}>
              {section.mergedBlocks.map(blockMergeResult => {
                const id = blockMergeResult.left?.id ?? blockMergeResult.right?.id as string

                return (
                  <LazyGradually key={id}>
                    <div
                      className={`row block-merge d-flex border-bottom
                        ${blockMergeResult.isResolutionRequired(locale) && 'merge-requires-resolution border-bottom'}
                        ${blockMergeResult.isConflict(locale) && 'merge-conflict merge-conflict-navigate'}`}>
                      <div
                        className={`block-merge-col col-4 border-right ${blockMergeResult.isClone() ? 'report-block-clone' : ''}`}>
                        <BlockSource locale={locale} side={ToolbarSide.LEFT} blockMergeResult={blockMergeResult}
                                     reportMergeResult={mergeResult} setReportMergeResult={setMergeResult}/>
                      </div>
                      <div className="block-merge-col col-4 bg-container">
                        <div className="block-merge-result">
                          {blockMergeResult.isClone() || blockMergeResult.status(locale) === BlockMergeStatus.EQUAL ?
                            <ReadonlyBlock mergeResults={mergeResult}
                                           block={blockMergeResult.resolved!}
                                           locale={locale}/>
                            :
                            (<Editor locale={locale} canComment={false}
                                     blocks={[blockMergeResult.resolved as Block].filter(Boolean)}
                                     id={new ObjectId().toHexString()} mode={EditorMode.TRANSLATE}
                                     t={t} onEdit={blocks => blockMergeResult.updateResolved(blocks[0])}
                                     i18n={i18n}/>)}
                        </div>
                        {blockMergeResult.isConflict(locale) && (
                          <div className="block-merge-result-toolbar">
                            <button className="btn btn-xs btn-success btn-with-icon border-bottom px-2"
                                    onClick={e => resolveMerge(e, blockMergeResult)}>
                              <DoneIcon className="icon icon-xs"/>
                              <span>{t('components.ReportMergingPage.done')}</span>
                            </button>
                          </div>
                        )}
                      </div>
                      <div
                        className={`block-merge-col col-4 border-left ${blockMergeResult.isClone() ? 'report-block-clone' : ''}`}>
                        <BlockSource locale={locale} side={ToolbarSide.RIGHT} blockMergeResult={blockMergeResult}
                                     reportMergeResult={mergeResult} setReportMergeResult={setMergeResult}/>
                      </div>
                    </div>
                  </LazyGradually>
                )
              })}
            </div>
          ))
      }
    </>
  )
}

export default ReportMergingPage