import React, {useEffect, useRef, useState} from 'react'
import placeCaretAtTheEndOfText from '../../../utils/placeCaretAtTheEndOfText'
import Error from './Error'


type ContentEditableProps = React.DetailedHTMLProps<React.BaseHTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
  error?: string | null
  value: string
  onContentChange: (value: string) => void
  autofocus?: boolean
}

const ContentEditable: React.FC<ContentEditableProps> = props => {
  const {error, value: initialValue, autofocus, onContentChange, ...divProps} = props
  const [value, setValue] = useState(initialValue)
  const divRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (autofocus) {
      placeCaretAtTheEndOfText(divRef.current!)
    }
  }, [])

  useEffect(() => {
    if (!divRef.current) return

    if (initialValue !== divRef.current.innerHTML) {
      setValue(initialValue)
      divRef.current.innerHTML = initialValue
    }
  }, [initialValue])

  const onInput = (event: React.ChangeEvent<HTMLDivElement>) => {
    onContentChange(event.target.innerHTML)
  }

  return (
    <>
      <div contentEditable={true} {...divProps} ref={divRef}
           className={`content-editable ${props.className} ${error ? 'is-invalid' : ''}`}
           dangerouslySetInnerHTML={{__html: value}} onInput={onInput}>
      </div>

      {error && <Error error={error}/>}
    </>
  )
}

export default ContentEditable
