import {Id} from 'domain/Entity'
import {LargePasteAggregate} from 'domain/report-statistics/LargePaste'
import apiClient from '../ApiClient'


const createLargePaste = async (projectId: Id, textLength: number, linesCount: number): Promise<void> => {
  return apiClient.post('/api/system/large-paste', {projectId, textLength, linesCount})
}

const getLargePastes = async (startDate: string, endDate: string): Promise<LargePasteAggregate[]> => {
  return await apiClient.get(`/api/system/large-paste?startDate=${startDate}&endDate=${endDate}`)
}

const downloadCsv = async (startDate: string, endDate: string): Promise<Blob> => {
  return await apiClient.getBlob(`/api/system/large-paste/csv?startDate=${startDate}&endDate=${endDate}`)
}

export {
  createLargePaste,
  getLargePastes,
  downloadCsv
}