export const findNearestElementIndex = (elementsTopWithIndex: { index: number, top: number }[],
                                        clientY: number): number => {
  return elementsTopWithIndex
    .map(elementTop => {
      return {
        index: elementTop.index, top: Math.abs(elementTop.top - clientY)
      }
    })
    .sort((t1: { index: number, top: number }, t2: { index: number, top: number }) => {
        const diff = t1.top - t2.top
        return diff === 0 ? t2.index - t1.index : diff
      }
    )
    .first()?.index
}