import {EditorHeadingBlock} from 'domain/Editor'
import {Locale} from 'domain/Locale'
import {HeadingBlock} from 'domain/Report'
import Converter from './Converter'

class HeadingConverter extends Converter<HeadingBlock, EditorHeadingBlock> {
  toEditor(block: HeadingBlock, locale: Locale): EditorHeadingBlock {
    const {id, type, text, ...other} = block
    return {id, type, data: {...other, text: text[locale]?.value || ''}}
  }

  fromEditor(editorBlock: EditorHeadingBlock, locale: Locale) {
    const {id, type, data} = editorBlock
    const {text, ...others} = data

    return {
      id,
      type,
      text: {
        [locale]: {
          value: text ? text.replace(/&nbsp;/g, ' ').trim() : ''
        }
      },
      ...others
    }
  }
}

export default new HeadingConverter()
