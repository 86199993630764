import {BlockType, HeadingBlock, ListBlock, AlertBlock, AlertType, ListStyle, ParagraphBlock, ParagraphStyle} from 'domain/Report'


const paragraphToHeading = (paragraph: ParagraphBlock, options?: ConverterOptions): HeadingBlock => {
  return {
    id: paragraph.id, type: BlockType.HEADING, level: options?.level || 2, text: {
      ...paragraph.text
    }
  }
}

const paragraphToList = (paragraph: ParagraphBlock): ListBlock => {
  const items = {}
  for (const locale of Object.keys(paragraph.text)) {
    items[locale] = {
      value: [paragraph.text[locale]!.value]
    }
  }

  return {id: paragraph.id, type: BlockType.LIST, style: ListStyle.UNORDERED, items}
}

const paragraphToAlert = (paragraph: ParagraphBlock): AlertBlock => {
  return {
    id: paragraph.id, type: BlockType.ALERT, message: {
      ...paragraph.text
    },
    alertType: AlertType.INFO
  }
}

const alertToParagraph = (alert: AlertBlock): ParagraphBlock => {
  return {
    id: alert.id, type: BlockType.PARAGRAPH, style: ParagraphStyle.NORMAL, text: {
      ...alert.message
    }
  }
}

const headingToParagraph = (heading: HeadingBlock): ParagraphBlock => {
  return {
    id: heading.id, type: BlockType.PARAGRAPH, style: ParagraphStyle.NORMAL, text: {
      ...heading.text
    }
  }
}

export interface ConverterOptions {
  level?: number
}

export type ParagraphConverter = Record<BlockType.HEADING | BlockType.LIST,
  (source: ParagraphBlock, options?: ConverterOptions) => HeadingBlock | ListBlock>

export type HeadingConverter = Record<BlockType.PARAGRAPH,
  (source: HeadingBlock, options?: ConverterOptions) => ParagraphBlock>

export type BlockTypeForConversion = BlockType.PARAGRAPH | BlockType.HEADING
export type BlockForConversion = ParagraphBlock | HeadingBlock | AlertBlock

export const blockTypeConverters = {
  paragraph:  {
    heading: paragraphToHeading,
    list: paragraphToList,
    alert: paragraphToAlert,
  },
  heading : {
    paragraph: headingToParagraph
  },
  alert: {
    paragraph: alertToParagraph
  }
}