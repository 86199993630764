import {Paginated} from 'domain/Entity'
import {AggregatedProjectNotification} from 'domain/ProjectNotification'
import apiClient from '../ApiClient'


const getProjectNotifications = async (page = 1): Promise<Paginated<AggregatedProjectNotification>> => {
  return apiClient.get(`/api/project-notifications?page=${page}`)
}

const readAllProjectNotifications = async (): Promise<void> => {
  return apiClient.patch('/api/project-notifications/read-all')
}

export {
  getProjectNotifications,
  readAllProjectNotifications
}
