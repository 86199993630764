import {EditorCommonTableBlockCellData, EditorCommonTableBlockRowData} from 'domain/Editor'


export const getTableContentFromHTML = (html: string): EditorCommonTableBlockRowData[] => {
  const dom = new DOMParser().parseFromString(html, 'text/html')
  const table = dom.querySelector('table') as HTMLTableElement
  if (!table) return []

  return Array.from(table.rows).map(row => {
    const result = {cells: [] as EditorCommonTableBlockCellData[]}

    for (const cell of Array.from(row.cells)) {
      result.cells.push({value: cell.innerText, colSpan: cell.colSpan, rowSpan: cell.rowSpan})
    }

    return result
  })
}