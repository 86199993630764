import {TaskWithUser} from 'domain/Task'
import React from 'react'
import UserButton from '../user/UserButton'
import {getTaskAssignee} from './getTaskAssignee'

interface Props {
  task: TaskWithUser
  buttonClass?: string
}

const TaskAssignee: React.FC<Props> = ({task, buttonClass}) => {
  const assignee = getTaskAssignee(task)

  if (typeof assignee === 'string') {
    return (
      <div
        className={`badge-outline text-muted ${buttonClass ? buttonClass : ''}`}
        key={`assignee-${task._id}-${assignee}`}
      >
        {assignee}
      </div>
    )
  } else {
    return <UserButton user={assignee} buttonClass={buttonClass} key={`assignee-${task._id}-${assignee._id}`} />
  }
}

export default TaskAssignee
