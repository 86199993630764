import {Id, Paginated} from 'domain/Entity'
import {Locale} from 'domain/Locale'
import {Project, ProjectType} from 'domain/Project'
import {AggregatedProjectLog} from 'domain/ProjectLog'
import {ProjectProblem} from 'domain/ProjectProblem'
import {ProjectCreateRequest} from '../../../api/src/services/ProjectCreationService'
import apiClient from '../ApiClient'
import {ProjectAggregate} from 'domain/ProjectAggregate'


const getCurrentUserProjects = async (): Promise<ProjectAggregate[]> => {
  return await apiClient.get(`/api/projects/current-user`)
}

const getActiveProjectsByType = async (type: ProjectType): Promise<ProjectAggregate[]> => {
  return await apiClient.get(`/api/projects?type=${type}`)
}

const getActiveProjects = async (sortBy = 'en'): Promise<ProjectAggregate[]> => {
  return await apiClient.get(`/api/projects?sort=${sortBy}`)
}

const getPublishedProjects = async (page = 1): Promise<Paginated<ProjectAggregate>> => {
  return await apiClient.get(`/api/projects/published?page=${page}`)
}

const getCloneableProjects = async (companyId: Id): Promise<ProjectAggregate[]> => {
  return await apiClient.get(`/api/projects/cloneable?companyId=${companyId}`)
}

const getProjects = async (): Promise<ProjectAggregate[]> => {
  return await apiClient.get('/api/projects')
}

const getLastPublishedProject = async (companyId: Id, locale: Locale): Promise<Project> => {
  return await apiClient.get(`/api/projects/last-published?companyId=${companyId}&locale=${locale}`)
}

const getPendingTranslatorConfirmationProjects = async (): Promise<ProjectAggregate[]> => {
  return await apiClient.get('/api/projects/pending-translator-confirmation')
}

const getCompanyActiveProjects = async (companyId: string): Promise<ProjectAggregate[]> => {
  return await apiClient.get(`/api/projects?companyId=${companyId}`)
}

const getUserActiveProjects = async (userId: Id): Promise<ProjectAggregate[]> => {
  return await apiClient.get(`/api/projects?userId=${userId}`)
}

const getCompanyPublishedProjects = async (companyId: string, page: number): Promise<Paginated<ProjectAggregate>> => {
  return await apiClient.get(`/api/projects/published?companyId=${companyId}&page=${page}`)
}

const searchProjects = async (query: string, page: number): Promise<Paginated<ProjectAggregate>> => {
  return await apiClient.get(`/api/projects/search?query=${query}&page=${page}`)
}

const getProject = async (id: Id, suppressError?: boolean): Promise<ProjectAggregate> => {
  return await apiClient.get(`/api/projects/${id}`, suppressError)
}

const createProject = async (request: ProjectCreateRequest): Promise<Project> => {
  return await apiClient.post(`/api/projects`, request)
}

const deleteProject = async (id: string) => {
  return await apiClient.delete(`/api/projects/${id}`)
}

const updateProject = async (project: Project): Promise<ProjectAggregate> => {
  return await apiClient.patch(`/api/projects/${project._id}`, project)
}

const updateProjectReportTitle = async (projectId: Id, reportTitleEN: string): Promise<void> => {
  return await apiClient.patch(`/api/projects/${projectId}/title`, {reportTitleEN})
}

const publishProjectReport = async (projectId: Id, locale: Locale): Promise<void> => {
  return await apiClient.patch(`/api/projects/${projectId}/publish`, {locale})
}

const unpublishProjectReport = async (projectId: Id, locale: Locale): Promise<void> => {
  return await apiClient.patch(`/api/projects/${projectId}/unpublish`, {locale})
}

const regenerateProjectReport = async (projectId: Id, locale: Locale): Promise<void> => {
  return await apiClient.patch(`/api/projects/${projectId}/regenerate`, {locale})
}

const updateExcludedWordsFromWordCount = async (projectId: Id, blockIds: string[]): Promise<Project> => {
  return await apiClient.patch(`/api/projects/${projectId}/word-count-exclude`, {blockIds})
}

const assignTranslationRevision = async (projectId: Id, revisionId: Id): Promise<Project> => {
  return await apiClient.patch(`/api/projects/${projectId}/assign-translation-revision`, {revisionId})
}

const getProjectLogs = async (page = 1, projectId?: Id): Promise<Paginated<AggregatedProjectLog>> => {
  return await apiClient.get(`/api/project-logs?page=${page}${projectId ? `&projectId=${projectId}` : ''}`)
}

const createProjectProblem = async (projectId: Id, problem: ProjectProblem): Promise<void> => {
  return await  apiClient.post(`/api/projects/${projectId}/problem`, problem)
}

const getProjectLastRevisionDate = async (id: Id, locale: Locale): Promise<{date: Date | null}> => {
  return await apiClient.get(`/api/projects/${id}/last-revision-date?locale=${locale}`)
}

export {
  getCurrentUserProjects,
  getCompanyActiveProjects,
  getCompanyPublishedProjects,
  getUserActiveProjects,
  getActiveProjectsByType,
  getActiveProjects,
  getPendingTranslatorConfirmationProjects,
  getPublishedProjects,
  createProject,
  deleteProject,
  getProject,
  searchProjects,
  updateProject,
  publishProjectReport,
  unpublishProjectReport,
  assignTranslationRevision,
  updateExcludedWordsFromWordCount,
  getProjects,
  getProjectLogs,
  updateProjectReportTitle,
  regenerateProjectReport,
  getLastPublishedProject,
  createProjectProblem,
  getCloneableProjects,
  getProjectLastRevisionDate
}