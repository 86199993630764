import {Company} from 'domain/Company'
import {Id} from 'domain/Entity'
import {GlossaryType} from 'domain/Glossary'
import {Permission} from 'domain/Permission'
import {ReactComponent as AddIcon} from '../../assets/icons/add.svg'
import {ReactComponent as UploadIcon} from '../../assets/icons/cloud_upload.svg'
import {ReactComponent as TableIcon} from '../../assets/icons/table-normal.svg'
import {ReactComponent as TextIcon} from '../../assets/icons/text_fields.svg'
import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory, useParams} from 'react-router-dom'
import {getActiveCompanies} from '../../api-clients/companyClient'
import {canUpdateGlossary, fetchGlossariesPaginated} from '../../api-clients/glossaryTermClient'
import useModal from '../../hooks/useModal'
import {usePaginatedCollection} from '../../hooks/usePaginatedCollection'
import CompanySelect from '../companies/CompanySelect'
import Protected from '../utils/Protected'
import AddGlossaryTerm from './AddGlossaryTerm'
import GlossaryTerms from './GlossaryTerms'
import UploadGlossary from './UploadGlossary'

const GLOBAL = 'GLOBAL'

interface RouteParams {
  companyId?: string
}

const GlossaryPage: React.FC = () => {
  const [companies, setCompanies] = useState<Company[]>([])
  const [companyTicks, setCompanyTicks] = useState<object>({})
  const {companyId} = useParams<RouteParams>()
  const {t} = useTranslation()
  const [isEditable, setEditable] = useState(false)
  const [search, setSearch] = useState('')
  const [currentGlossaryType, setCurrentGlossaryType] = useState(GlossaryType.TABLE)
  const {showModal} = useModal()
  const history = useHistory()
  const glossaryPagination = usePaginatedCollection({
    fetchCollection: page => fetchGlossariesPaginated(currentGlossaryType, page, companyId, search)
  })

  useEffect(() => {
    setSearch('')
    canUpdateGlossary(companyId).then(setEditable)
    glossaryPagination.reload()
  }, [companyId])

  useEffect(() => {
    getActiveCompanies().then(companies => {
      setCompanyTicks(
        companies.reduce((acc, company) => {
          const id = company._id.toString()
          if (!acc[id]) {
            acc[id] = company.tick
          }
          return acc
        }, {})
      )
      setCompanies(companies)
    })
  }, [])

  useEffect(() => {
    glossaryPagination.reload()
  }, [currentGlossaryType])

  useEffect(() => {
    glossaryPagination.reload()
  }, [search])

  const onSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault()
    setSearch(event.target.value)
  }

  const onCompanyChange = (selectedCompanyId: Id) => {
    history.push(`/glossary/${selectedCompanyId === GLOBAL ? '' : selectedCompanyId}`)
  }

  const uploadCSVFile = (event: React.MouseEvent) => {
    event.preventDefault()
    showModal(
      <UploadGlossary
        glossaryType={currentGlossaryType}
        companyId={companyId}
        onUploaded={() => {
          glossaryPagination.reload()
        }}
      />
    )
  }

  const addNewTerm = (event: React.MouseEvent) => {
    event.preventDefault()
    showModal(
      <AddGlossaryTerm type={currentGlossaryType} companyId={companyId} onAdd={() => glossaryPagination.reload()} />
    )
  }

  return (
    <>
      <div className="navbar navbar-secondary">
        <div className="container-fluid">
          <div className="row flex-grow-1">
            <div className="col-4 d-flex align-items-center justify-content-start">
              <h5 className="mb-0 fw-bolder me-3">{t('glossary.title')}</h5>

              <CompanySelect
                id="glossary-owner"
                companies={companies}
                selectedValue={companyId || GLOBAL}
                clearable={false}
                onChange={onCompanyChange}
                defaultValue={{label: t('glossary.global.title'), value: GLOBAL}}
              />
            </div>
            <div className="col-4 d-flex align-items-center justify-content-center">
              <div className="navbar navbar-expand">
                <div className="navbar-nav">
                  <div className="nav-item">
                    <a
                      onClick={() => setCurrentGlossaryType(GlossaryType.TABLE)}
                      className={`nav-link nav-link-large-icon ${
                        currentGlossaryType === GlossaryType.TABLE ? 'active' : ''
                      }`}
                    >
                      <TableIcon className="icon-sm" />
                      <span>{t('glossary.types.TABLE')}</span>
                    </a>
                  </div>
                  <div className="nav-item">
                    <a
                      onClick={() => setCurrentGlossaryType(GlossaryType.BLOCK)}
                      className={`nav-link nav-link-large-icon ${
                        currentGlossaryType === GlossaryType.BLOCK ? 'active' : ''
                      }`}
                    >
                      <TextIcon className="icon-sm" />
                      <span>{t('glossary.types.BLOCK')}</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container py-3">
        <div className="card elevate">
          <div className="d-flex gap-3 justify-content-between align-items-center card-body">
            <input
              className="form-control form-control-sm"
              type="search"
              autoFocus
              placeholder={t('components.Companies.Companies.search_placeholder')}
              aria-label="Search"
              value={search}
              onChange={onSearch}
            />
            <Protected permission={Permission.Glossary.UPDATE}>
              <button
                type="button"
                className="btn btn-sm btn-with-icon btn-primary"
                disabled={!isEditable}
                onClick={addNewTerm}
              >
                <AddIcon className="icon icon-sm" />
                <span className="text-nowrap">{t('glossary.term.newTerm')}</span>
              </button>
            </Protected>
            <Protected
              permission={
                companyId ? Permission.Glossary.UPLOAD_FROM_FILE : Permission.Glossary.UPLOAD_GLOBAL_FROM_FILE
              }
            >
              <button className="btn btn-outline-primary btn-sm btn-with-icon" onClick={uploadCSVFile}>
                <UploadIcon className="icon icon-sm" />
                <span className="text-nowrap">{t('glossary.importFromFile')}</span>
              </button>
            </Protected>
          </div>
          <GlossaryTerms
            companyTicks={companyTicks}
            pagination={glossaryPagination}
            isEditable={isEditable}
            glossaryType={currentGlossaryType}
            companyId={companyId}
          />
        </div>
      </div>
    </>
  )
}

export default GlossaryPage
