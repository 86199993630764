import {EditorBlock} from 'domain/Editor'
import {Locale} from 'domain/Locale'
import {Block, BlockType} from 'domain/Report'
import assignDeep from 'object-assign-deep'
import alertConverter from './AlertConverter'
import bookmarkConverter from './BookmarkConverter'
import cloneConverter from './CloneConverter'
import commonTableConverter from './CommonTableConverter'
import Converter from './Converter'
import gridConverter from './GridConverter'
import headingConverter from './HeadingConverter'
import imageConverter from './ImageConverter'
import listConverter from './ListConverter'
import paragraphConverter from './ParagraphConverter'
import tableConverter from './TableConverter'


const converters: {
  [key in BlockType]: Converter<Block, EditorBlock>
} = {
  [BlockType.HEADING]: headingConverter,
  [BlockType.PARAGRAPH]: paragraphConverter,
  [BlockType.LIST]: listConverter,
  [BlockType.IMAGE]: imageConverter,
  [BlockType.EXCEL_TABLE]: tableConverter,
  [BlockType.ALERT]: alertConverter,
  [BlockType.BOOKMARK]: bookmarkConverter,
  [BlockType.GRID]: gridConverter,
  [BlockType.COMMON_TABLE]: commonTableConverter,
  [BlockType.CLONE]: cloneConverter
}

class BlocksEditor {
  blocks: Block[]

  constructor(blocks: Block[]) {
    this.blocks = blocks
  }

  static convertFromEditor(blocks: EditorBlock[], locale: Locale) {
    return blocks.map(block => converters[block.type].fromEditor(block, locale))
  }

  static convertToEditor(blocks: Block[], locale: Locale, withMetadata = true) {
    return blocks.map(block => {
      const editorBlock = converters[block.type].toEditor(block, locale)
      return withMetadata ? {...editorBlock, metadata: {block}} : editorBlock
    })
  }

  convertToEditor(locale: Locale): EditorBlock[] {
    return BlocksEditor.convertToEditor(this.blocks, locale)
  }

  reset(editorBlocks: EditorBlock[], locale: Locale) {
    this.blocks = editorBlocks.map(block => {
      const updatedBlock = converters[block.type].fromEditor(block, locale)
      const {block: originalBlock} = block.metadata ?? {}

      if (originalBlock) {
        this.removeRedundantFieldsFromOriginalBlock(originalBlock, updatedBlock)
      }

      return originalBlock ? assignDeep({}, originalBlock, updatedBlock) : updatedBlock
    })

    return this.blocks
  }

  merge(editorBlocks: EditorBlock[], locale: Locale) {
    editorBlocks.forEach(editorBlock => {
      const updatedBlock = converters[editorBlock.type].fromEditor(editorBlock, locale)
      const originalBlock = this.blocks.find(b => b.id === editorBlock.id)

      if (originalBlock) {
        this.removeRedundantFieldsFromOriginalBlock(originalBlock, updatedBlock)
        assignDeep(originalBlock, updatedBlock)
      }
    })

    return this.blocks
  }

  private removeRedundantFieldsFromOriginalBlock(originalBlock: Block, updatedBlock: Block) {
    for (const key of Object.keys(originalBlock)) {
      if (!updatedBlock.hasOwnProperty(key) && !Object.values(Locale).includes(key as Locale)) {
        delete originalBlock[key]
      }

      if (originalBlock[key] instanceof Object && updatedBlock[key]) {
        this.removeRedundantFieldsFromOriginalBlock(originalBlock[key], updatedBlock[key])
      }
    }
  }
}

export default BlocksEditor
