import {stages} from 'domain/project-stages/stages'
import {ProjectStageRule} from 'domain/ProjectStageFlow'
import {ProjectMilestone} from 'domain/Report'


const initialReportStages: ProjectStageRule[] = [
  stages.INTERVIEW_1({hours: 8 * 5}) /* need a week interval */,
  stages.INTERVIEW_2({hours: 8}),
  stages.ORIGINAL_WRITING({
    hours: 70,
    milestone: ProjectMilestone.ORIGINAL_REPORT_WRITING_FINISHED
  }) /* need 10 days interval */,
  stages.ORIGINAL_REVIEW1({hours: 8}),
  stages.ORIGINAL_POST_REVIEW_WRITING1({hours: 8 * 4}),
  stages.ORIGINAL_REVIEW2({hours: 8}),
  stages.ORIGINAL_POST_REVIEW_WRITING2({hours: 8 * 5}) /* need 3 days interval  changed from *2 to *5  */,
  stages.ORIGINAL_EDITING_FOR_TRANSLATION({hours: 8}),
  stages.ORIGINAL_HEAD_OF_RESEARCH_AUTH({hours: 8}) /* 16 > 8  */,
  stages.ORIGINAL_PROOFREADING({hours: 40}),
  stages.ORIGINAL_COMPANY_CHECK({hours: 8 * 5 * 2}),
  stages.ORIGINAL_POST_COMPANY_REVIEW_WRITING({hours: 4, milestone: ProjectMilestone.ORIGINAL_REPORT_FINISHED}),
  stages.TRANSLATION_PREPARATION({hours: 1}),
  stages.TRANSLATION_WRITING({hours: 12 * 8, milestone: ProjectMilestone.TRANSLATED_REPORT_WRITING_FINISHED}),
  stages.TRANSLATION_OZ_CHECK({hours: 1}),
  stages.TRANSLATION_REVIEW({hours: 13 * 8}) /* 3 > 13  */,
  stages.TRANSLATION_COMPANY_CHECK({hours: 8 * 5, milestone: ProjectMilestone.TRANSLATED_REPORT_FINISHED}),
  stages.PUBLISH({hours: 2, milestone: ProjectMilestone.PROJECT_FINISHED})
]

export default initialReportStages
