import {getWordCountFromPreparedHTML} from 'domain/wordCount'
import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import EventBus, {EventType} from '../../../EventBus'

const WordCountBadge: React.FC = () => {
  const {t} = useTranslation()
  const [wordCount, setWordCount] = useState<number>(0)

  const onDiffUpdated = () => {
    const wordCount = getWordCountFromPreparedHTML(document.body.innerHTML)
    setWordCount(wordCount)
  }

  useEffect(() => {
    onDiffUpdated()
    EventBus.on(EventType.HTML_DIFF_UPDATED, onDiffUpdated)
    return () => EventBus.unsubscribe(EventType.HTML_DIFF_UPDATED, onDiffUpdated)
  }, [])

  return wordCount ? (
    <div className="d-flex align-items-center bg-soft-warning rounded py-2 px-3" data-testid="word-count">
      <div className="h5 mb-0 me-2">{wordCount}</div>
      <div className="small text-muted text-pre-wrap" style={{lineHeight: 1}}>
        {t('components.Report.revisions.WordCountBadge.wordCount')}
      </div>
    </div>
  ) : (
    <></>
  )
}

export default WordCountBadge
