import {Id} from 'domain/Entity'
import {Permission} from 'domain/Permission'
import {AggregatedProjectLog} from 'domain/ProjectLog'
import {ReactComponent as HistoryIcon} from '../../assets/icons/history.svg'
import {ReactComponent as AuditIcon} from '../../assets/icons/receipt_long_black.svg'
import {ReactComponent as RefreshIcon} from '../../assets/icons/refresh_black.svg'
import React, {FC, ReactElement, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {Link} from 'react-router-dom'
import {getProjectLogs} from '../../api-clients/projectClient'
import EventBus, {EventType} from '../../EventBus'
import Protected from '../utils/Protected'
import ProjectLogRow from './logs/ProjectLogRow'

interface ProjectAuditProps {
  projectId: Id
}

const ProjectLogs: FC<ProjectAuditProps> = ({projectId}): ReactElement => {
  const {t} = useTranslation()
  const [projectLogs, setProjectLogs] = useState<AggregatedProjectLog[]>([])

  const fetchLogs = () => {
    getProjectLogs(1, projectId).then(result => setProjectLogs(result.collection))
  }

  useEffect(() => {
    fetchLogs()

    EventBus.on(EventType.REFRESH_PROJECT_LOGS, fetchLogs)

    return () => {
      EventBus.unsubscribe(EventType.REFRESH_PROJECT_LOGS, fetchLogs)
    }
  }, [projectId])

  return (
    <div className="card">
      <div className="card-header">

        <div className="d-flex align-items-center">
          <AuditIcon className="icon-sm text-muted me-2"/>
          <h6 className="mb-0">{t('components.ProjectLogs.title')}</h6>
        </div>

        <div className="card-header-actions">
          <Protected permission={Permission.Revision.READ_ALL}>
            <Link to={`/projects/${projectId}/report/revisions`}
                  title={t('components.Project.Project.view')}
                  className="btn btn-outline-secondary btn-sm btn-with-icon ms-2">
              <HistoryIcon className="icon-sm"/>
              <span>{t('components.ProjectLogs.revisionHistory')}</span>
            </Link>
          </Protected>
          <button className="btn btn-outline-secondary btn-sm btn-with-icon px-1 ms-2" onClick={() => fetchLogs()}>
            <RefreshIcon className="icon icon-sm"/>
          </button>
        </div>
      </div>
      <table className="table table-sm small">
        <tbody>
        {projectLogs.map(log => <ProjectLogRow log={log} key={log._id.toString()}/>)}
        </tbody>
      </table>
    </div>
  )
}

export default ProjectLogs