import {Company} from 'domain/Company'
import {Locale} from 'domain/Locale'
import {Permission} from 'domain/Permission'
import {ReactComponent as EditIcon} from '../../assets/icons/edit.svg'
import React, {ReactElement, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'
import useModal from '../../hooks/useModal'
import {normalizeSearch} from '../../utils/searchUtils'
import FormattedDate from '../utils/FormattedDate'
import Protected from '../utils/Protected'
import CompanyForm from './CompanyForm'

export interface Props {
  companies: Company[]
  onCompanyUpdate: (company: Company) => Promise<any>
}

const CompaniesList: React.FC<Props> = ({companies, onCompanyUpdate}): ReactElement => {
  const history = useHistory()
  const {t} = useTranslation()
  const {showModal} = useModal()
  const [search, setSearch] = useState('')
  const [filteredCompanies, setFilteredCompanies] = useState<Company[]>([])

  useEffect(() => filterCompanies(), [search, companies])

  const onSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault()
    setSearch(event.target.value)
  }

  const filterCompanies = () => {
    const normalizedSearch = normalizeSearch(search)
    const result =
      normalizedSearch.length > 1
        ? companies.filter(company => {
            const searchableFields = [company.name[Locale.EN], company.name[Locale.JA], company.tick]
            return searchableFields.some(field => field.toLowerCase().indexOf(normalizedSearch) > -1)
          })
        : []
    setFilteredCompanies(result)
  }

  const displayCompanyPage = (event: React.MouseEvent, company: Company) => {
    event.preventDefault()
    history.push(`/companies/${company._id}`)
  }

  const editCompany = (event: React.MouseEvent, company: Company) => {
    event.preventDefault()
    event.stopPropagation()

    showModal(<CompanyForm company={company} onConfirm={onCompanyUpdate} />)
  }

  return (
    <>
      <div className="card elevate mb-3">
        <div className="card-body">
          <input
            autoFocus
            className="form-control form-control-lg my-2 my-lg-0"
            type="search"
            placeholder={t('components.Companies.Companies.search_placeholder')}
            aria-label="Search"
            value={search}
            onChange={onSearch}
          />
        </div>
      </div>
      {filteredCompanies.length > 0 && (
        <div className="card elevate">
          <div className="card-body">
            <table className="table table-sm small table-hover table-clickable-rows">
              <thead>
                <tr>
                  <th>{t('models.Company.tick')}</th>
                  <th>{t('models.Company.name')}</th>
                  <th>{t('models.Company.name')}</th>
                  <th>{t('models.Company.updatedAt')}</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {filteredCompanies.map(company => {
                  return (
                    <tr key={company._id.toString()} onClick={e => displayCompanyPage(e, company)}>
                      <td>
                        <strong>{company.tick}</strong>
                      </td>
                      <td>{company.name[Locale.JA]}</td>
                      <td>{company.name[Locale.EN]}</td>
                      <td className="text-muted-more">
                        <FormattedDate date={company.updatedAt} />
                      </td>
                      <td className="text-end">
                        <Protected permission={Permission.Company.UPDATE}>
                          <button
                            title={t('components.Companies.Companies.edit')}
                            onClick={e => editCompany(e, company)}
                            className="btn btn-outline-secondary btn-sm btn-icon-only"
                          >
                            <EditIcon className="icon icon-sm" />
                          </button>
                        </Protected>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </>
  )
}

export default CompaniesList
