import {API, BaseTool} from '@editorjs/editorjs'
import BookmarkIcon from '../../../assets/inline-svg/bookmark_border.svg'


interface BookmarkData {
  title: string
}

export default class Bookmark implements BaseTool {
  private readonly api: API
  private readonly container: HTMLDivElement
  private readonly data: BookmarkData
  private readonly readonly: boolean

  static get toolbox() {
    return {
      icon: BookmarkIcon,
      title: 'Bookmark'
    }
  }

  static get isReadOnlySupported() {
    return true
  }

  get CSS() {
    return {
      wrapper: 'bookmark'
    }
  }

  constructor({data, api, readOnly}: { data?: BookmarkData, config?: {}, api: API, readOnly?: boolean }) {
    this.api = api
    this.readonly = readOnly || false
    this.data = data?.title ? data : {title: ''}
    this.container = document.createElement('div')

    this.container.className = this.CSS.wrapper

    const dash = document.createElement('div')
    dash.className = 'dash'
    this.container.appendChild(dash)

    const editableArea = document.createElement('span')
    editableArea.contentEditable = this.readonly ? 'false' : 'true'
    editableArea.innerHTML = this.data.title
    this.container.appendChild(editableArea)
  }

  render() {
    return this.container
  }

  save(container: HTMLDivElement): BookmarkData {
    return {
      title: container.innerHTML
    }
  }
}
