import React from 'react'
import Error from './Error'


type SelectProps = React.DetailedHTMLProps<React.SelectHTMLAttributes<HTMLSelectElement>, HTMLSelectElement> & {
  error?: string | null
}

const Select: React.FunctionComponent<SelectProps> = ({error, children, ...props}) => {
  return (
    <>
      <select {...props} className={`${props.className} ${error ? 'is-invalid' : ''}`}>
        {children}
      </select>

      {error && <Error error={error} />}
    </>
  )
}

export default Select
