import {Locale} from 'domain/Locale'
import {Project} from 'domain/Project'
import {ProjectAggregate} from 'domain/ProjectAggregate'

type GroupedProjectsByTranslatorIdAndDeadline = {
  [key in string]: {
    [key in string]: {type: string, size: number}[]
  }
}

type ProjectsByTranslatorIdAndDeadline = {
  [key in string]: {
    [key in string]: ProjectAggregate[]
  }
}

export const groupedProjectsByTranslatorIdAndDeadline = (
  projects: ProjectAggregate[]
): GroupedProjectsByTranslatorIdAndDeadline => {
  const projectsByTranslatorId = projectsByTranslatorIdAndDeadline(projects)
  const res = {}

  for (const translatorId of Object.keys(projectsByTranslatorId)) {
    res[translatorId] = {}

    for (const date of Object.keys(projectsByTranslatorId[translatorId])) {
      const projects = projectsByTranslatorId[translatorId][date]
      const projectsByType = projects.groupBy((project: Project) => project.type)

      res[translatorId][date] = Object.keys(projectsByType).map(type => {
        return {type, size: projectsByType[type].length}
      })
    }
  }

  return res
}

const projectsByTranslatorIdAndDeadline = (projects: ProjectAggregate[]): ProjectsByTranslatorIdAndDeadline => {
  return projects.reduce((res, project) => {
    if (project.translatorId) {
      res[project.translatorId as string] ||= {}
      res[project.translatorId as string][project.expectedDeadline[Locale.EN]] ||= []
      res[project.translatorId as string][project.expectedDeadline[Locale.EN]].push(project)
    }
    return res
  }, {})
}