import {Permission} from 'domain/Permission'
import {hasRoles, isOnlyTranslator, Role} from 'domain/User'
import React, {useContext} from 'react'
import {Switch, useParams} from 'react-router-dom'
import AuthRoute from '../../AuthRoute'
import CommentProvider from '../../providers/CommentProvider'
import ReportProvider from '../../providers/ReportProvider'
import ReportUpdateProvider from '../../providers/ReportUpdateProvider'
import {UserContext} from '../../providers/UserProvider'
import EditReportPage from '../report/EditReportPage'
import PreviewReport from '../report/PreviewReportPage'
import ProofreadingPage from '../report/ProofreadingPage'
import ReportMergingPage from '../report/ReportMergingPage'
import RevisionsPreviewPage from '../report/RevisionsPreviewPage'
import TranslateReport from '../report/TranslateReportPage'
import ProjectPage from './ProjectPage'

interface RouteParams {
  projectId: string
}

const ProjectWorkspace: React.FC = () => {
  const {user} = useContext(UserContext)
  const {projectId} = useParams<RouteParams>()
  const isCompanyUser = hasRoles(user?.roles, Role.COMPANY_REPRESENTATIVE)

  return (
    <ReportProvider projectId={projectId}>
      <ReportUpdateProvider>
        <CommentProvider>
          <Switch>
            <AuthRoute
              path="/projects/:projectId/report/revisions"
              component={RevisionsPreviewPage}
              permission={Permission.Revision.READ_ALL}
            />

            <AuthRoute
              path="/projects/:projectId/report/edit"
              component={isOnlyTranslator(user?.roles) ? ProofreadingPage : EditReportPage}
              permission={Permission.Report.UPDATE}
            />

            <AuthRoute
              path="/projects/:projectId/report/translate"
              component={TranslateReport}
              permission={Permission.Report.UPDATE}
            />

            <AuthRoute
              path="/projects/:projectId/report"
              component={isCompanyUser ? ProofreadingPage : PreviewReport}
              permission={Permission.Report.READ}
            />

            <AuthRoute
              path="/projects/:projectId/merge"
              component={ReportMergingPage}
              permission={Permission.Report.UPDATE}
            />
            <AuthRoute path="/projects/:projectId" component={ProjectPage} permission={Permission.Project.READ} />
          </Switch>
        </CommentProvider>
      </ReportUpdateProvider>
    </ReportProvider>
  )
}

export default ProjectWorkspace
