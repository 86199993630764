import {Errors} from 'domain/Errors'
import {ReactComponent as DoneIcon} from '../../assets/icons/done.svg'
import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'
import {updateCompanyImages} from '../../api-clients/companyClient'
import useModal from '../../hooks/useModal'
import Input from '../utils/form/Input'
import ModalTemplate from '../utils/ModalTemplate'

import {AggregatedCompany} from 'domain/Company'

interface UploadImagesFormProps {
  company: AggregatedCompany
  onCompanyUpdated: (company: AggregatedCompany) => void
}

interface Images {
  logoImage: File | null
  bgImage: File | null
}

const UploadImagesForm: React.FC<UploadImagesFormProps> = ({company, onCompanyUpdated}) => {
  const {t} = useTranslation()
  const [images, setImages] = useState<Images>({logoImage: null, bgImage: null})
  const [errors, setErrors] = useState<Errors<Images>>({})
  const {closeModal} = useModal()
  const [uploading, setUploading] = useState(false)

  const onImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    const {name, files} = e.target
    setImages(images => ({...images, [name]: files ? files[0] : null}))
  }

  const onConfirm = (e: React.FormEvent) => {
    e.preventDefault()
    setUploading(true)
    setErrors({})

    updateCompanyImages(company._id, images)
      .then(company => {
        onCompanyUpdated(company)
        setUploading(false)
        closeModal()
      })
      .catch(({errors}) => {
        setErrors(errors)
        setUploading(false)
      })
  }

  return (
    <ModalTemplate size="md" title={t('components.UploadImagesForm.title')}>
      <form onSubmit={onConfirm}>
        <div className="modal-body">
          <div className="row">
            <div className="mb-3 col-lg-12">
              <div className="custom-file">
                <label className="form-label small">{t('models.Company.logoImage')}</label>
                <Input
                  type="file"
                  className="form-control"
                  id="uploadFile"
                  name="logoImage"
                  onChange={onImageChange}
                  error={errors.logoImage}
                />
              </div>
              <div className="form-text">{t('components.UploadImagesForm.logoImageDimensions')}</div>
            </div>

            <div className="mb-3 col-lg-12">
              <div className="custom-file">
                <label className="form-label small">{t('models.Company.bgImage')}</label>
                <Input
                  type="file"
                  className="form-control"
                  id="uploadFile"
                  name="bgImage"
                  onChange={onImageChange}
                  error={errors.bgImage}
                />
              </div>
              <div className="form-text">{t('components.UploadImagesForm.bgImageDimensions')}</div>
            </div>
          </div>
        </div>

        <div className="modal-footer">
          <button className="btn btn-success btn-with-icon" disabled={uploading}>
            <span>{t('components.UploadImagesForm.upload')}</span>
            {uploading ? (
              <span className="spinner-border spinner-border-sm ms-2" role="status" aria-hidden="true" />
            ) : (
              <DoneIcon className="icon" />
            )}
          </button>
        </div>
      </form>
    </ModalTemplate>
  )
}

export default UploadImagesForm
