import {Block, BlockType} from 'domain/Report'


export enum BlockDiffStatus {
  EQUAL = 'EQUAL',
  INSERTION = 'INSERTION',
  DELETION = 'DELETION',
  MOVED_AWAY = 'MOVED_AWAY',
  MOVED_TO = 'MOVED_TO',
}

type BlockDiffResultInit = {
  left?: Block
  right?: Block
  status: BlockDiffStatus
  sectionId: string
}

export class BlockDiffResult {
  public readonly left?: Block
  public readonly right?: Block
  public readonly status: BlockDiffStatus
  public readonly sectionId: string

  constructor(init: BlockDiffResultInit) {
    this.left = init.left
    this.right = init.right
    this.status = init.status
    this.sectionId = init.sectionId
  }

  isClone() {
    return this.left?.type === BlockType.CLONE || this.right?.type === BlockType.CLONE
  }

  isDeleted() {
    return this.status === BlockDiffStatus.DELETION
  }

  isAdded() {
    return this.status === BlockDiffStatus.INSERTION
  }

  isMovedAway() {
    return this.status === BlockDiffStatus.MOVED_AWAY
  }

  isMovedTo() {
    return this.status === BlockDiffStatus.MOVED_TO
  }
}