import {Locale} from 'domain/Locale'
import {Project} from 'domain/Project'
import {PublishingProjectTask} from 'domain/Task'
import {ReactComponent as RefreshIcon} from '../../../assets/icons/refresh_black.svg'
import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {getLastPublishedProject, getProject, regenerateProjectReport} from '../../../api-clients/projectClient'
import websocket from '../../../websocket'


type Props = {
  project: Project
  task: PublishingProjectTask
  locale: Locale
}

const RegenerateLastReportButton: React.FC<Props> = ({project, task, locale}) => {
  const {t} = useTranslation()
  const [lastPublishedProject, setLastPublishedProject] = useState<Project | null>(null)

  useEffect(() => {
    getLastPublishedProject(project.companyId, locale).then(project => {
      setLastPublishedProject(project)
    }).catch(() => {
      setLastPublishedProject(null)
    })
  }, [project._id])

  useEffect(() => {
    if (!lastPublishedProject) return

    websocket.onProjectUpdated(lastPublishedProject._id, () => {
      getProject(lastPublishedProject._id).then(setLastPublishedProject)
    })
    return () => {
      websocket.stopListeningProjectUpdated(lastPublishedProject._id)
    }
  }, [lastPublishedProject?._id])

  const onRegenerate = (e: React.MouseEvent) => {
    e.preventDefault()
    if (!lastPublishedProject) return
    regenerateProjectReport(lastPublishedProject._id, locale)
  }

  const projectPublishedAt = project.publishedAt?.[locale] ? new Date(project.publishedAt[locale]!) : new Date()
  const isRegenerating = task.isGeneratingFiles?.[locale]
  const isLastProjectPublishedLater = projectPublishedAt <= new Date(lastPublishedProject?.publishedAt![locale]!) &&
    project._id !== lastPublishedProject?._id

  if (!isLastProjectPublishedLater) {
    return <></>
  }

  if (!lastPublishedProject) {
    return <></>
  }

  return <button className="btn btn-xs btn-with-icon btn-outline-secondary mt-1" onClick={onRegenerate}
                 disabled={isRegenerating}>
    <span className="me-1">{t('components.RegenerateLastReportButton.regenerate')}</span>

    {isRegenerating ?
      <span className="spinner-border spinner-border-xs" role="status" aria-hidden="true"/> :
      <RefreshIcon className="icon-xs m-0"/>
    }
  </button>
}

export default RegenerateLastReportButton