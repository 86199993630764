import {useContext, useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import EventBus, {EventType} from '../../../EventBus'
import {ReportContext} from '../../../providers/ReportProvider'


export const EXCLUDED_WORDS_DATA_TAG = 'data-word-count-excluded'

const useExcludedFromWordCountBlocks = () => {
  const {project} = useContext(ReportContext)
  const {t} = useTranslation()
  const label = t('components.BlocksContextMenu.excludedFromWordCount')

  useEffect(() => {
    EventBus.on(EventType.EDITOR_READY, highlightBlocksExcludedFromWordCount)

    return () => {
      EventBus.unsubscribe(EventType.EDITOR_READY, highlightBlocksExcludedFromWordCount)
    }
  }, [project])

  useEffect(() => {
    return () => {
      EventBus.unsubscribe(EventType.EDITOR_READY, highlightBlocksExcludedFromWordCount)
    }
  }, [])

  useEffect(() => {
    onExcludedFromWordCountWordsUpdated(project?.wordCountExcludedBlockIds || [])
  }, [[project?.wordCountExcludedBlockIds]])

  const highlightBlocksExcludedFromWordCount = (event: Event) => {
    if (!(event as CustomEvent).detail) return
    const sectionId = (event as CustomEvent).detail.id
    const blocksSelector = (project?.wordCountExcludedBlockIds || []).map(id => `[id="${id}"]`).join(',')

    if (blocksSelector) {
      document.getElementById(sectionId)!.querySelectorAll(blocksSelector).forEach(block => {
        block.setAttribute(EXCLUDED_WORDS_DATA_TAG, label)
      })
    }
  }

  const onExcludedFromWordCountWordsUpdated = (blockIds: string[]) => {
    document.querySelectorAll(`[${EXCLUDED_WORDS_DATA_TAG}]`).forEach(block => {
      block.removeAttribute(EXCLUDED_WORDS_DATA_TAG)
    })

    for (const blockId of blockIds) {
      document.querySelectorAll(`[id="${blockId}"]`).forEach(block => {
        block.setAttribute(EXCLUDED_WORDS_DATA_TAG, label)
      })
    }
  }
}

export default useExcludedFromWordCountBlocks