import { useEffect } from 'react'

const useScrollKey = (): void => {
  const downHandler = (e: KeyboardEvent ): void => {

    // Instead of PgDn key
    if (e.key === '{' && e.shiftKey && e.ctrlKey) {
      window.scroll({
        top: window.scrollY - window.innerHeight,
        behavior: 'smooth'
      })
    }

    // Instead of PgUp key
    if (e.key === '}' && e.shiftKey && e.ctrlKey) {
      window.scroll({
        top: window.scrollY + window.innerHeight,
        behavior: 'smooth'
      })
    }
  }

  useEffect(() => {
    window.addEventListener('keydown', downHandler)
    return () => {
      window.removeEventListener('keydown', downHandler)
    }
  }, [])
}

export default useScrollKey