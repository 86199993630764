import {AggregatedTask, ProjectTask} from 'domain/Task'
import React, {useContext, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import { Link } from 'react-router-dom'
import EventBus, {EventType} from '../../EventBus'
import useModal from '../../hooks/useModal'
import useToast from '../../hooks/useToast'
import {LocaleContext} from '../../providers/LocaleProvider'
import ProjectTaskRescheduleModal from '../project/tasks/ProjectTaskRescheduleModal'
import FormattedDate from '../utils/FormattedDate'
import ModalTemplate from '../utils/ModalTemplate'


type Props = {
  tasks: AggregatedTask[]
}

const OverdueTasksReminderModal: React.FC<Props> = props => {
  const {locale} = useContext(LocaleContext)
  const [tasks, setTasks] = useState(props.tasks)
  const {showModal, closeLastModal} = useModal()
  const {renderSuccessToast} = useToast()
  const {t} = useTranslation()

  useEffect(() => {
    const overdueTasks = tasks.filter(t => new Date(t.endDate) < new Date())
    if (overdueTasks.length === 0) {
      renderSuccessToast(t('components.OverdueTasksReminderModal.rescheduledSuccessfully'))
      closeLastModal()
    }
  }, [tasks])

  const onReschedule = (task: AggregatedTask) => {
    showModal(<ProjectTaskRescheduleModal task={task} onRescheduled={onTaskUpdate}/>)
  }

  const onTaskUpdate = (updatedTask: ProjectTask) => {
    setTasks(tasks => tasks.map(task => task._id === updatedTask._id ? {...task, ...updatedTask} : task))
    EventBus.emit(EventType.TASK_RESCHEDULED)
  }

  return <ModalTemplate title={t('components.OverdueTasksReminderModal.title')} size="lg">
    <div className="modal-body">
      <p>{t('components.OverdueTasksReminderModal.description')}</p>

      <table className="table table-striped">
        <thead>
        <tr>
          <th>{t('models.Project.company')}</th>
          <th>{t('models.Project.title')}</th>
          <th>{t('models.Project.title')}</th>
          <th>{t('components.OverdueTasksReminderModal.currentEndDate')}</th>
          <th/>
        </tr>
        </thead>
        <tbody>
        {tasks.map(task => {
          const isOverdue = new Date(task.endDate) < new Date()

          return <tr key={task._id as string}>
            <td><strong>{task.company.shortName[locale]}</strong></td>
            <td>
              <strong><Link to={`/projects/${task.project._id}`} target="_blank">{task.project.title}</Link></strong>
            </td>
            <td>{t(`models.ProjectStage.${task.stage}.title`)}</td>
            <td className={isOverdue ? 'text-danger' : 'text-success'}>
              <FormattedDate date={task.endDate} format={t('dateTimeFormat')}/>
            </td>
            <td>
              {isOverdue && <button className="btn btn-primary btn-sm" onClick={() => onReschedule(task)}>
                {t('components.OverdueTasksReminderModal.reschedule')}
              </button>}
            </td>
          </tr>
        })}
        </tbody>
      </table>
    </div>
  </ModalTemplate>
}

export default OverdueTasksReminderModal