import {Id} from 'domain/Entity'
import apiClient from '../ApiClient'
import {AggregatedTask} from 'domain/Task'

const getCurrentUserTasks = async (startDate: Date, endDate: Date): Promise<AggregatedTask[]> => {
  return await apiClient.get(`/api/tasks/current-user?startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}`)
}

const getCurrentUserOverdueTasks = async (endDate: Date): Promise<AggregatedTask[]> => {
  return await apiClient.get(`/api/tasks/current-user/overdue?endDate=${endDate.toISOString()}`)
}

const getUserTasks = async (userId: Id, startDate: Date, endDate: Date): Promise<AggregatedTask[]> => {
  return await apiClient.get(`/api/tasks?startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}&userId=${userId}`)
}

const getUserOverdueTasks = async (userId: Id, endDate: Date): Promise<AggregatedTask[]> => {
  return await apiClient.get(`/api/tasks/current-user/overdue?endDate=${endDate.toISOString()}&userId=${userId}`)
}

export {
  getCurrentUserOverdueTasks,
  getCurrentUserTasks,
  getUserTasks,
  getUserOverdueTasks
}