import {Locale} from 'domain/Locale'
import {GroupedNews} from 'domain/Project'
import {BlockType, HeadingBlock, ReportState} from 'domain/Report'
import React, {ReactElement, useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import {buildReportWithNewsReleases} from '../../../utils/projectUtils'


interface ReportTocProps {
  report: ReportState | null
  locale: Locale
  groupedNews: GroupedNews
}

const ReportToc: React.FC<ReportTocProps> = ({report, groupedNews, locale}): ReactElement => {

  const reportWithNewsReleases = useMemo(() => {
    return report ? buildReportWithNewsReleases(report, groupedNews, false) : null
  }, [report, groupedNews])

  const displayHeading = (block: HeadingBlock) => {
    return ['1', '2', '3', '4'].indexOf(block.level.toString()) !== -1
  }

  const {t} = useTranslation()

  return (
    <div className="report-section report-section-output report-section-toc">
      <div className="container container-editor">
        <div className="p-2">
          <h1 className="report-toc-heading">{t('components.Report.preview.ReportToc.heading', {lng: locale})}</h1>
          <div className="report-toc-items">
            {reportWithNewsReleases && reportWithNewsReleases.sections.map(section => {
              const headingBlocks = section.blocks.filter(block => block.type === BlockType.HEADING) as HeadingBlock[]

              return (
                <div className="report-toc-section" key={section._id.toString()}>
                  {headingBlocks.map(headingBlock => {
                    return displayHeading(headingBlock) &&
                      <a className={`report-toc-item report-toc-item-h${headingBlock.level}`}
                         href={`#${headingBlock.id}`}
                         key={headingBlock.id}>{headingBlock.text[locale]?.value?.removeHTMLTags()}</a>
                  })}
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ReportToc