import classNames from 'classnames'
import {Locale} from 'domain/Locale'
import {ImageBlock} from 'domain/Report'
import React, {ReactElement} from 'react'
import RawHtml from '../../utils/RawHtml'


interface ListProps {
  block: ImageBlock,
  locale: Locale
}

const Image: React.FC<ListProps> = ({block, locale}): ReactElement => {
  const title = block.title[locale]?.value
  const description = block.description[locale]?.value

  const classes = {
    'with-border': block.withBorder,
    'stretched': block.stretched,
    'with-background': block.withBackground
  }

  return (
    <div className="report-image mb-3 print-break-inside-avoid">
      {title && <div className="image-tool__title"><RawHtml>{title}</RawHtml></div>}

      {block.file[locale]?.url && <div className={classNames(classes)}>
        <img className="image-tool__image-picture" src={block.file[locale]?.url} alt=""/>
      </div>}

      {!!description && (<div className="image-tool__description"><RawHtml>{description}</RawHtml></div>)}
    </div>
  )
}

export default Image
