import {Locale} from 'domain/Locale'
import {ReactComponent as ArrowDownIcon} from '../../../assets/icons/arrow_downward.svg'
import {ReactComponent as ArrowUpIcon} from '../../../assets/icons/arrow_upward.svg'
import React, {useEffect} from 'react'
import EventBus, {EventType} from '../../../EventBus'
import useTagNavigator from '../../../hooks/useTagNavigator'
import getNavigableRevisionDiffs from './getNavigableRevisionDiffs'

interface Props {
  locale: Locale
}

const RevisionDiffNavigation: React.FC<Props> = ({locale}) => {
  const navigator = useTagNavigator()
  const lastEvent: boolean[] = []
  const timerRun: boolean[] = []

  useEffect(() => {
    navigator.resetIndex()
  }, [locale])

  const onHtmlDiffComputed = (event: Event) => {
    lastEvent.length = 0
    lastEvent.push((event as any)?.detail?.isDeletionVisible ?? true)
    if (timerRun.length === 0) {
      timerRun.push(true)
      setTimeout(executeLastEvent, 1000)
    }
  }

  const onReportViewClick = (e: MouseEvent) => {
    navigator.recalculatePosition(e.pageY)
  }

  const executeLastEvent = () => {
    if (lastEvent.length > 0) {
      navigator.updateTags(getNavigableRevisionDiffs(lastEvent.pop()!))
      timerRun.length = 0
    }
  }

  useEffect(() => {
    EventBus.on(EventType.HTML_DIFF_UPDATED, onHtmlDiffComputed)
    const reportArea = document.querySelector('.report-content-wrapper') as HTMLElement
    if (reportArea) reportArea.addEventListener('click', onReportViewClick)

    return () => {
      EventBus.unsubscribe(EventType.HTML_DIFF_UPDATED, onHtmlDiffComputed)
      if (reportArea) reportArea.removeEventListener('click', onReportViewClick)
    }
  }, [])

  const navigate = (event: React.MouseEvent, step: number) => {
    event.preventDefault()
    if (step > 0) navigator.navigateForward()
    else navigator.navigateBack()
  }

  return navigator.hasTags() ? (
    <div className="navbar-nav">
      <div className="nav-item">
        <a
          className={`nav-link ${navigator.canNavigateBack() ? 'text-muted' : 'text-muted-more'}`}
          href="#"
          onClick={e => navigate(e, -1)}
        >
          <ArrowUpIcon className="icon" />
        </a>
      </div>

      <div className="nav-item">
        <a
          className={`nav-link ${navigator.canNavigateForward() ? 'text-muted' : 'text-muted-more'}`}
          href="#"
          onClick={e => navigate(e, 1)}
        >
          <ArrowDownIcon className="icon" />
        </a>
      </div>
    </div>
  ) : (
    <></>
  )
}

export default RevisionDiffNavigation
