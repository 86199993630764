import {BackgroundStyle, FontColorStyle} from 'domain/Report'
import TableParser from './TableParser'


const EMPTY_BACKGROUND_COLOR = [
  'rgb(255, 255, 255)',
  'rgba(0, 0, 0, 0)'
]

const UNSET_FONT_COLOR = 'rgb(0, 0, 0)'

const HEADER_BACKGROUND_STYLES = [
  BackgroundStyle.HEADER,
  BackgroundStyle.SECONDARY_HEADER
]

class TableCellStyleConverter {
  _tableParser: TableParser
  _tableHasBgColors: boolean | undefined

  constructor(tableParser: TableParser) {
    this._tableParser = tableParser
  }

  cellBackgroundStyle(cell: HTMLTableDataCellElement): BackgroundStyle {
    const row = cell.parentNode as HTMLTableRowElement
    const headerRow = (row.parentNode as HTMLTableElement).rows[0]
    const table = row.parentNode as HTMLTableElement

    if (!this.tableHasBgColors(table)) {
      return BackgroundStyle.NORMAL
    }

    if (this.hasBackgroundColor(cell) && this.containsHeaderRowColor(row)) {
      if (headerRow === row) {
        return BackgroundStyle.HEADER
      } else {
        return BackgroundStyle.SECONDARY_HEADER
      }
    } else if (this.hasBackgroundColor(cell)) {
      return BackgroundStyle.HIGHLIGHTED
    } else {
      return BackgroundStyle.NORMAL
    }
  }

  cellFontStyle(cell: HTMLTableDataCellElement): FontColorStyle {
    const textColor = this._tableParser.styles(cell).color
    const isHeader = HEADER_BACKGROUND_STYLES.indexOf(this.cellBackgroundStyle(cell)) > -1

    if (isHeader || textColor === UNSET_FONT_COLOR) {
      return FontColorStyle.NORMAL
    } else {
      return FontColorStyle.WARNING
    }
  }

  private containsHeaderRowColor(targetRow: HTMLTableRowElement): boolean {
    const headerRow = (targetRow.parentNode as HTMLTableElement).rows[0]
    return this.getRowColor(headerRow) === this.getRowColor(targetRow)
  }

  private hasBackgroundColor(cell: HTMLTableDataCellElement): boolean {
    const bgColor = this.getCellBackgroundColor(cell)
    return bgColor !== '' && EMPTY_BACKGROUND_COLOR.indexOf(bgColor) === -1
  }

  private tableHasBgColors(table: HTMLTableElement): boolean {
    if (this._tableHasBgColors !== undefined) return this._tableHasBgColors

    const allBgColors = Array.from(table.rows).flatMap(row => Array.from(row.cells).map(cell => {
      return this.getCellBackgroundColor(cell)
    }))

    this._tableHasBgColors = new Set(allBgColors).size > 1
    return this._tableHasBgColors
  }

  private getRowColor(row: HTMLTableRowElement): string {
    return this.getCellBackgroundColor(row.cells[0])
  }

  private getCellBackgroundColor(cell: HTMLTableDataCellElement): string {
    return this._tableParser.styles(cell).backgroundColor
  }
}

export default TableCellStyleConverter