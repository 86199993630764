import {EditorParagraphBlock} from 'domain/Editor'
import {Locale} from 'domain/Locale'
import {ParagraphBlock} from 'domain/Report'
import Converter from './Converter'


class ParagraphConverter extends Converter<ParagraphBlock, EditorParagraphBlock> {
  toEditor(block: ParagraphBlock, locale: Locale): EditorParagraphBlock {
    const {id, type, text, ...other} = block
    return {id, type, data: {...other, text: text[locale]?.value || ''}}
  }

  fromEditor(editorBlock: EditorParagraphBlock, locale: Locale) {
    const {id, type, data} = editorBlock
    const {text, ...others} = data

    return {
      id, type,
      text: {
        [locale]: {
          value: text
        }
      },
      ...others
    }
  }
}

export default new ParagraphConverter()