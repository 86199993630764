import {PDFFileJob} from 'domain/Job'
import {Locale} from 'domain/Locale'
import {reportTitle} from 'domain/pdfTitle'
import {Project} from 'domain/Project'
import {ReportType} from 'domain/ReportTemplate'
import {ReactComponent as DownloadIcon} from '../../assets/icons/cloud_download.svg'
import React, {FC, ReactElement, useEffect, useRef, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {getFile} from '../../api-clients/fileClient'
import {getReportPDF} from '../../api-clients/reportClient'
import useToast from '../../hooks/useToast'
import waitJobCompletion from '../../utils/waitJobCompletion'


interface PdfButtonProps {
  locale: Locale
  reportType: ReportType
  sectionId: string | null
  project: Project
  tick: string
  password?: string
  onStartDownload: () => void
  onEndDownload: () => void
}

const PdfButton: FC<PdfButtonProps> = (props): ReactElement => {
  const {tick, reportType, project, locale, sectionId, password, onStartDownload, onEndDownload} = props
  const {t} = useTranslation()
  const linkRef = useRef<HTMLAnchorElement>(null)
  const [loading, setLoading] = useState(false)
  const [blobUrl, setBlobUrl] = useState<string | null>(null)
  const {renderErrorToast} = useToast()

  const onClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    if (blobUrl) {
      return
    }

    event.preventDefault()
    setLoading(true)
    onStartDownload()

    getReportPDF(reportType, project._id, locale, sectionId, password ?? null).then(job => {
      return waitJobCompletion(job).then(async jobData => {
        const blob = await getFile((jobData as PDFFileJob).data.filepath)
        const url = URL.createObjectURL(blob)
        setBlobUrl(url)
        setLoading(false)
      })
    }).catch(() => {
      displayError()
    }).finally(() => {
      onEndDownload()
    })
  }

  const displayError = () => {
    renderErrorToast(t('components.Project.PdfButton.fileGenerationFailed'))
    setLoading(false)
  }

  useEffect(() => {
    if (blobUrl) {
      linkRef.current!.click()
    }
  }, [blobUrl])

  function clearBlobUrl() {
    if (blobUrl) {
      URL.revokeObjectURL(blobUrl)
      setBlobUrl(null)
    }
  }

  useEffect(() => clearBlobUrl, [])
  useEffect(clearBlobUrl, [locale, sectionId, password, reportType])

  const filename = () => reportTitle(reportType, project.type, locale, tick) + '.pdf'

  return (
    <a href={blobUrl || ''} onClick={onClick} download={filename()}
       className={`btn btn-outline-success btn-sm btn-with-icon ms-2 ${loading ? 'disabled' : ''}`}
       ref={linkRef}>
      {loading && <span className="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"/>}
      <DownloadIcon className="icon icon-sm"/>
      <span>{t('components.Project.DownloadPdf.title')}</span>
    </a>
  )
}

export default PdfButton