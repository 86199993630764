import {ProjectTask} from 'domain/Task'
import moment from 'moment-timezone'
import React, {ReactElement, useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {range} from '../../../../../api/src/utils/range'
import {rescheduleProjectTask} from '../../../api-clients/projectTaskClient'
import useModal from '../../../hooks/useModal'
import {formatDate} from '../../../utils/momentUtils'
import ButtonWithLoader from '../../utils/form/ButtonWithLoader'
import Textarea from '../../utils/form/Textarea'
import FormattedDate from '../../utils/FormattedDate'
import ModalTemplate from '../../utils/ModalTemplate'


interface Props {
  task: ProjectTask,
  onRescheduled: (task: ProjectTask) => void,
}

const ProjectTaskRescheduleModal: React.FC<Props> = ({task, onRescheduled}): ReactElement => {
  const {closeLastModal} = useModal()
  const {t} = useTranslation()
  const [newDate, setNewDate] = useState(formatDate(task.endDate, 'YYYY-MM-DD'))
  const [newTime, setNewTime] = useState(`${formatDate(task.endDate, 'HH')}:00`)
  const [reason, setReason] = useState('')
  const [loading, setLoading] = useState(false)
  const newEndDate = useMemo(() => {
    return new Date(`${newDate} ${newTime}`)
  }, [newDate, newTime])

  const onReschedule = (event: React.FormEvent) => {
    event.preventDefault()

    setLoading(true)
    rescheduleProjectTask(task._id, newEndDate, reason).then(task => {
      closeLastModal()
      onRescheduled(task)
    }).finally(() => {
      setLoading(false)
    })
  }

  const onReasonChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    e.preventDefault()
    setReason(e.target.value)
  }

  const onDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    setNewDate(e.target.value)
  }

  const onTimeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    e.preventDefault()
    setNewTime(e.target.value)
  }

  const minEndDate = moment.max(moment(), moment(task.startDate)).format('YYYY-MM-DD')
  const stageTitle = t(`models.ProjectStage.${task.stage}.title`)
  const isReschedulingForFuture = moment(task.endDate).isBefore(newEndDate)

  return (
    <ModalTemplate title={t('components.ProjectTaskRescheduleModal.title', {stageTitle})}>
      <form onSubmit={onReschedule}>
        <div className="modal-body">
          <div className="row mb-2">
            <div className="col">
              <label className="mb-1">
                {t(`components.ProjectTaskRescheduleModal.currentEndDate`)}
              </label>
              <div className="text-muted">
                <FormattedDate date={task.endDate} format={t('dateTimeFormat')} />
              </div>
            </div>
          </div>
          <div className="row mb-2">
            <div className="col">
              <label className="form-label">{t(`components.ProjectTaskRescheduleModal.newEndDate`)}</label>

              <div className="d-flex">
                <input type="date" className="form-control" value={newDate} onChange={onDateChange} min={minEndDate}/>

                <select value={newTime} onChange={onTimeChange} className="form-control ms-2">
                  {range(0, 23).map(hour => {
                    const formattedHour = `${hour.toString().padStart(2, '0')}:00`
                    return <option key={formattedHour} value={formattedHour}>{formattedHour}</option>
                  })}
                </select>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <label htmlFor="reason" className="form-label">
                {t(`components.ProjectTaskRescheduleModal.reason`)}
              </label>
              <Textarea className="form-control" value={reason} name="reason"
                        aria-describedby="reason"
                        onChange={onReasonChange}
                        placeholder={t(`components.ProjectTaskRescheduleModal.reasonPlaceholder`)}/>
            </div>
          </div>

          {isReschedulingForFuture && <div className="row mt-2">
            <div className="col">
              <div className="alert alert-danger">{t('components.ProjectTaskRescheduleModal.alert')}</div>
            </div>
          </div>}
        </div>
        <div className="modal-footer justify-content-end">
          <ButtonWithLoader type="submit" className="btn btn-primary" loading={loading}
                            disabled={new Date(task.endDate).getTime() === newEndDate.getTime()}>
            {t('components.ProjectTaskRescheduleModal.submit')}
          </ButtonWithLoader>
        </div>
      </form>
    </ModalTemplate>
  )
}

export default ProjectTaskRescheduleModal
