import React from 'react'

type ButtonWithLoaderProps = React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
> & {
  loading: boolean
}

const ButtonWithLoader: React.FC<ButtonWithLoaderProps> = props => {
  const {loading, children, className, ...rest} = props
  return (
    <button className={`${className} ${loading ? 'disabled' : ''}`} {...rest}>
      <>
        {loading && <span className="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true" />}
        {children}
      </>
    </button>
  )
}

export default ButtonWithLoader
