import React, {useContext} from 'react'
import {useTranslation} from 'react-i18next'
import {getProjectLogs} from '../../../api-clients/projectClient'
import {LocaleContext} from '../../../providers/LocaleProvider'
import {relativeDateFromNow} from '../../../utils/momentUtils'
import {logLabel} from '../../../utils/projectLogUtils'
import PaginatedView from '../../utils/PaginatedView'
import Pane from '../../utils/Pane'

type Props = {
  onClose: (e: React.MouseEvent) => void
}

const ProjectHistoryPane: React.FC<Props> = ({onClose}) => {
  const {t} = useTranslation()
  const {locale} = useContext(LocaleContext)

  return (
    <Pane title={'History'} onClose={onClose}>
      <PaginatedView
        fetchCollection={getProjectLogs}
        render={log => {
          const LogComponent = logLabel(log)
          return (
            <div className="card mb-2">
              <div className="card-header p-2 py-1">
                <div className="d-flex justify-content-between align-items-center w-100">
                  <strong>
                    <small>{`${log.companyTick} - ${log.projectTitle}`}</small>
                  </strong>
                  <small className="text-muted-more">
                    {relativeDateFromNow(log.createdAt, locale, t('dateTimeFormat'))}
                  </small>
                </div>
              </div>
              <div className="card-body p-2">
                <small>
                  <LogComponent log={log} key={log._id} />
                </small>
              </div>
            </div>
          )
        }}
      />
    </Pane>
  )
}

export default ProjectHistoryPane
