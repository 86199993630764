import {Company, CompanyStatus} from 'domain/Company'
import {ReactComponent as Activate} from '../../assets/icons/power.svg'
import {ReactComponent as Reactivate} from '../../assets/icons/visibility.svg'
import {ReactComponent as Deactivate} from '../../assets/icons/visibility_off.svg'
import React from 'react'
import {useTranslation} from 'react-i18next'
import useModal from '../../hooks/useModal'
import ToggleCompanyStatus from '../companies/ToggleCompanyStatus'


interface Props {
  company: Company,
  onCompanyStatusChange: () => void,
}

const ToggleCompanyStatusButton: React.FC<Props> = ({company, onCompanyStatusChange}) => {
  const {t} = useTranslation()
  const {showModal} = useModal()

  const statusIcon = {
    [CompanyStatus.DRAFT]: <Activate className="icon icon-sm"/>,
    [CompanyStatus.ACTIVE]: <Deactivate className="icon icon-sm"/>,
    [CompanyStatus.INACTIVE]: <Reactivate className="icon icon-sm"/>
  }
  const isCompanyActive = company.status === CompanyStatus.ACTIVE

  const toggleCompanyStatus = () => {
    showModal(<ToggleCompanyStatus company={company} onChange={onCompanyStatusChange}/>)
  }

  return <button
    title={t(isCompanyActive ? 'components.Companies.Companies.deactivate' : 'components.Companies.Companies.activate')}
    onClick={toggleCompanyStatus}
    className="btn btn-outline-secondary btn-sm btn-icon-only ms-2">
    {statusIcon[company.status]}
  </button>
}

export default ToggleCompanyStatusButton