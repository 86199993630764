import {getCommentedElements, getElementTopPosition} from './commentUtils'

class CommentPositionModifier {
  observer: MutationObserver

  constructor(private container: HTMLElement) {
    this.observer = new MutationObserver(this.adjustPositions.bind(this))
  }

  observe() {
    const config = {
      subtree: true,
      childList: true
    }

    this.observer.observe(this.container, config)
  }

  close() {
    this.observer.disconnect()
  }

  adjustPositions() {
    const elements = Array.from(this.container.children) as HTMLElement[]

    const sortedElements = elements
      .map(element => {
        const commentId = element.getAttribute('data-comment-id')
        if (!commentId) {
          return {element, span: null}
        }
        const blockId = element.getAttribute('data-commented-block-id')
        const firstCommentedElement = getCommentedElements({_id: commentId, blockId}).first()

        if (firstCommentedElement) {
          element.style.top = getElementTopPosition(firstCommentedElement) + 'px'
        }

        return {element, span: firstCommentedElement}
      })
      .filter(({span}) => span)
      .sort((el1, el2) => {
        return getElementTopPosition(el1.span!) - getElementTopPosition(el2.span!)
      })

    let y: number
    sortedElements.forEach((elementWithSpan: any, i) => {
      const {element} = elementWithSpan

      if (y && y > parseInt(element.style.top, 10)) {
        element.style.top = `${y}px`
      }

      y = parseInt(element.style.top, 10) + element.offsetHeight
    })
  }
}

export default CommentPositionModifier