import {Permission} from 'domain/Permission'
import {ExternalTranslator, hasRoles, Role, Translator} from 'domain/User'
import React from 'react'
import {useTranslation} from 'react-i18next'
import Protected from '../utils/Protected'


interface TranslatorProfileProps {
  user: Translator
}

const TranslatorProfile: React.FC<TranslatorProfileProps> = ({user}) => {
  const {t} = useTranslation()

  const externalTranslator = hasRoles(user, Role.EXTERNAL_TRANSLATOR) ? (user as ExternalTranslator) : undefined

  return <Protected permission={Permission.User.READ_PROFILE}>
    <>
      <dl>
        <dt>{t('models.User.tier')}</dt>
        <dd className="text-pre-wrap">{user.tier || '-'}</dd>
      </dl>
      {externalTranslator && <>
        <dl>
          <dt>{t('models.User.ratePerCharacter')}</dt>
          <dd>{externalTranslator.ratePerCharacter ? externalTranslator.ratePerCharacter + '¥/ch' : '-'}</dd>
        </dl>
        <dl>
          <dt>{t('models.User.responseSpeed')}</dt>
          <dd>
            {externalTranslator.responseSpeed ? t(`models.ResponseSpeed.${externalTranslator.responseSpeed}`) : '-'}
          </dd>
        </dl>
      </>}
    </>
  </Protected>
}

export default TranslatorProfile