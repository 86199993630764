import {Company} from 'domain/Company'
import {Locale} from 'domain/Locale'
import React, {ReactElement} from 'react'
import {OverlayTrigger, Popover} from 'react-bootstrap'
import {Link} from 'react-router-dom'
import {ReactComponent as BusinessIcon} from '../../assets/icons/business.svg'
import Description from './Description'

interface CompanyButtonProps {
  company: Pick<Company, '_id' | 'name' | 'tick' | 'phone' | 'website' | 'financialYearEnd'>
  buttonClass?: string
}

const CompanyButton: React.FC<CompanyButtonProps> = ({company, buttonClass}): ReactElement => {
  const companyPopover = (
    <Popover id="popover-basic">
      <Popover.Header as="div">
        <div className="d-flex justify-content-between">
          <div>{company.name[Locale.EN]}</div>
          <div className="fw-bolder">{company.tick}</div>
        </div>
      </Popover.Header>

      <Popover.Body>
        <Description company={company} />
      </Popover.Body>
    </Popover>
  )

  return (
    <OverlayTrigger overlay={companyPopover} placement="auto">
      <Link
        to={`/companies/${company._id}`}
        className={`btn btn-xs btn-outline-secondary btn-with-icon  ${buttonClass ? buttonClass : ''}`}
      >
        <BusinessIcon className="icon-xs" />
        <span className="max-w-96 ellipsis">{company.name[Locale.EN]}</span>
        <span className="ms-1 fw-bold">{company.tick}</span>
      </Link>
    </OverlayTrigger>
  )
}

export default CompanyButton
