import StatusCodes from 'http-status-codes'
import apiVersion from './apiVersion'
import EventBus, {EventType} from './EventBus'
import tokenStorage from './TokenStorage'

const {NO_CONTENT, CONFLICT, FORBIDDEN, UNPROCESSABLE_ENTITY, UNAUTHORIZED, NOT_FOUND} = StatusCodes

const defaultHeaders = () => ({
  Authorization: `Bearer ${tokenStorage.getToken()}`,
  'X-Api-Version': apiVersion
})

const jsonHeaders = () => ({
  Accept: 'application/json',
  'Content-Type': 'application/json',
  ...defaultHeaders()
})

class ApiClient {
  async get(url: string, suppressError?: boolean) {
    return await this.request(url, {}, 'GET', suppressError)
  }

  async getBlob(url: string) {
    return fetch(url, {headers: jsonHeaders()}).then(this.parseBlob)
  }

  async post(url: string, data: object) {
    return await this.request(url, data, 'POST')
  }

  async put(url: string, data: object) {
    return await this.request(url, data, 'PUT')
  }

  async patch(url: string, data?: object) {
    return await this.request(url, data || {}, 'PATCH')
  }

  async delete(url: string, data: object = {}) {
    return await this.request(url, data, 'DELETE')
  }

  async patchFile(url: string, data: object, files: {[key: string]: File}) {
    const formData = new FormData()

    for (const [key, file] of Object.entries(files)) {
      formData.append(key, file)
    }

    for (const [key, value] of Object.entries(data)) {
      formData.append(key, value)
    }

    return await fetch(url, {
      method: 'PATCH',
      body: formData,
      headers: defaultHeaders()
    })
      .then(this.parseResponse)
      .catch(this.handleFetchFailure)
  }

  private async request(
    url: string,
    data: object,
    methodName: 'POST' | 'PUT' | 'PATCH' | 'DELETE' | 'GET',
    suppressError?: boolean
  ) {
    const requestInit: RequestInit = {
      method: methodName,
      headers: jsonHeaders()
    }

    if (methodName !== 'GET') {
      requestInit.body = JSON.stringify(data)
    }

    const abortController = new AbortController()
    const requestTimeoutSeconds = methodName === 'GET' ? 25 : 10
    setTimeout(() => abortController.abort(), requestTimeoutSeconds * 1000)
    requestInit.signal = abortController.signal

    try {
      const response = await fetch(url, requestInit)
      return this.parseResponse(response, suppressError)
    } catch (error: any) {
      error.url = url
      error.method = methodName
      this.handleFetchFailure(error)
    }
  }

  private handleFetchFailure(error: Error) {
    if (error.message === 'Failed to fetch') {
      throw {...error, message: 'errors.networkUnavailable'}
    }
    else if (error.name === 'AbortError') {
      throw {...error, message: 'errors.requestTimedOut'}
    }
    else {
      throw error
    }
  }

  private async parseBlob(response: Response): Promise<Blob> {
    if (response.ok) {
      return response.blob()
    } else {
      return Promise.reject(response)
    }
  }

  private async parseResponse(response: Response, suppressError?: boolean): Promise<any> {
    if (!response.ok) {
      const error = {status: response.status}
      switch (response.status) {
        case NOT_FOUND:
          return Promise.reject(error)
        case UNAUTHORIZED:
          EventBus.emit(EventType.EXPIRE_SESSION)
          return Promise.reject(error)
        case FORBIDDEN:
          if (!suppressError) {
            EventBus.emit(EventType.NOTIFY_FORBIDDEN)
          }
          return Promise.reject(error)
        case CONFLICT:
          const {error: message} = await response.json().catch(() => ({}))
          return Promise.reject(message ? {message, status: CONFLICT} : error)
        case UNPROCESSABLE_ENTITY:
          return Promise.reject(error)
        default:
          const json = response.status === NO_CONTENT ? {} : await response.json()
          return Promise.reject({...error, ...json})
      }
    }

    return response.status === NO_CONTENT ? {} : await response.json()
  }
}

export default new ApiClient()
