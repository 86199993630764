import {Project} from 'domain/Project'
import {ReactComponent as CopyIcon} from '../../assets/icons/content_copy.svg'
import {ReactComponent as TranslateIcon} from '../../assets/icons/translate.svg'
import React from 'react'
import {useTranslation} from 'react-i18next'
import useModal from '../../hooks/useModal'
import ConfirmApplyingGlossary from '../project/ConfirmApplyingGlossary'
import ConfirmCopyJaToEn from '../project/ConfirmCopyJaToEn'

type Props = {
  project: Project
}

const ReportTranslationInitialization: React.FC<Props> = ({project}) => {
  const {t} = useTranslation()
  const {showModal} = useModal()

  const onCopyJaToEn = (e: React.MouseEvent) => {
    e.preventDefault()
    showModal(<ConfirmCopyJaToEn projectId={project._id} />)
  }

  const onApplyGlossary = (e: React.MouseEvent) => {
    e.preventDefault()
    showModal(<ConfirmApplyingGlossary projectId={project._id} />)
  }

  return (
    <>
      <button className="btn btn-xs btn-with-icon btn-outline-secondary me-2" onClick={onCopyJaToEn}>
        <CopyIcon className="icon icon-xs" />
        <span>{t('components.ReportTranslationInitialization.copyJaToEn')}</span>
      </button>
      <button className="btn btn-xs btn-with-icon btn-outline-secondary" onClick={onApplyGlossary}>
        <TranslateIcon className="icon-xs" />
        <span>{t('components.ReportTranslationInitialization.applyGlossary')}</span>
      </button>
    </>
  )
}

export default ReportTranslationInitialization
