import {Locale} from 'domain/Locale'
import React, {useEffect} from 'react'
import EventBus, {EventType} from '../../../EventBus'
import BlockDiff from './BlockDiff'
import {BlockMergeResult} from './BlockMergeResult'
import BlockMergeToolbar, {ToolbarSide} from './BlockMergeToolbar'
import ReadonlyBlock from './ReadonlyBlock'
import {BlockMergeStatus, SectionMergeResult} from './ReportSectionsMerger'


interface BlockMergeProps {
  blockMergeResult: BlockMergeResult
  side: ToolbarSide
  locale: Locale
  reportMergeResult: SectionMergeResult[]
  setReportMergeResult: (mergeResult: SectionMergeResult[]) => void
}

const BlockSource: React.FC<BlockMergeProps> = props => {
  const {blockMergeResult, side, locale, reportMergeResult, setReportMergeResult} = props

  useEffect(() => {
    if (blockMergeResult.isResolutionRequired(locale)) {
      setTimeout(() => EventBus.emit(EventType.MERGE_CONFLICT_RENDERED))
    }
  }, [locale])

  const source = side === ToolbarSide.LEFT ? blockMergeResult.left : blockMergeResult.right

  const copyBlock = (blockMergeResult: BlockMergeResult) => {
    side === ToolbarSide.LEFT ? blockMergeResult.copyLeft() : blockMergeResult.copyRight()
    setReportMergeResult([...reportMergeResult])
  }

  const rejectBlock = (blockMergeResult: BlockMergeResult) => {
    side === ToolbarSide.LEFT ? blockMergeResult.rejectLeft() : blockMergeResult.rejectRight()
    setReportMergeResult([...reportMergeResult])
  }

  const isInsertion = () => {
    return blockMergeResult.status(locale) === BlockMergeStatus.INSERTION && !!source
  }

  const isRejection = () => {
    return blockMergeResult.status(locale) === BlockMergeStatus.REJECTED && !!source
  }

  const displayMoveButtons = (blockMergeResult: BlockMergeResult) => {
    return (blockMergeResult.isConflict(locale) || isInsertion()) && (
      !blockMergeResult.isClone() || blockMergeResult.isOriginalBlockInResult())
  }

  const classNames = [
    'block-source-' + side,
    isInsertion() && 'merge-source-insert',
    isRejection() && 'merge-source-rejected'
  ].filter(Boolean).join(' ')


  return source ?
    <div className={classNames}>
      {side === ToolbarSide.RIGHT && blockMergeResult.isConflict(locale) && blockMergeResult.right ?
        <BlockDiff currentBlock={blockMergeResult.right} previousBlock={blockMergeResult.resolved} locale={locale}/> :
        <ReadonlyBlock mergeResults={reportMergeResult} block={source} locale={locale} side={side}/>}
      {displayMoveButtons(blockMergeResult) &&
      <BlockMergeToolbar copyBlock={() => copyBlock(blockMergeResult)}
                         rejectBlock={() => rejectBlock(blockMergeResult)}
                         canReject={blockMergeResult.status(locale) === BlockMergeStatus.INSERTION}
                         side={side}/>}
    </div> : blockMergeResult.wasBlockDeleted ?
      <div className={`${'block-source-' + side} h-100 merge-conflict`}/> :
      <></>
}

export default React.memo<BlockMergeProps>(BlockSource)