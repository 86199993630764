import {Permission} from 'domain/Permission'
import React from 'react'
import {useTranslation} from 'react-i18next'
import InlineEditable from '../utils/form/InlineEditable'
import Protected from '../utils/Protected'


interface GlossaryCommentProps {
  isEditable: boolean
  comment: string
  onChange: (value: string) => void
}

const GlossaryComment: React.FC<GlossaryCommentProps> = ({isEditable, comment, onChange}) => {
  const {t} = useTranslation()

  return (<Protected permission={Permission.Glossary.UPDATE} else={comment}>
    {
      isEditable ?
        <InlineEditable value={comment}
                        placeholder={t(`glossary.term.comment.placeholder`)}
                        onChange={onChange}
        /> : <span>{comment}</span>
    }
  </Protected>)
}

export default GlossaryComment