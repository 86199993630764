import {Errors} from 'domain/Errors'
import {UserWithFormPasswords} from 'domain/User'
import {ReactComponent as DoneIcon} from '../../assets/icons/done.svg'
import React, {ReactElement, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory, useParams} from 'react-router-dom'
import {resetPassword} from '../../api-clients/userClient'
import useToast from '../../hooks/useToast'
import PublicPageTemplate from '../public/PublicPageTemplate'
import Input from '../utils/form/Input'


const ResetPasswordPage: React.FC = (): ReactElement => {
  const history = useHistory()
  const {t} = useTranslation()
  const {renderSuccessToast} = useToast()

  const {resetPasswordToken} = useParams<{ resetPasswordToken: string }>()

  const [credentials, setCredentials] = useState({
    password: '',
    passwordConfirmation: ''
  })

  const [errors, setErrors] = useState<Errors<UserWithFormPasswords> & { baseError: string }>()

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault()

    const {name, value} = event.target
    setCredentials(c => ({...c, [name]: value}))
  }

  const onSubmit = (event: React.FormEvent) => {
    event.preventDefault()

    resetPassword({...credentials, resetPasswordToken}).then(() => {
      renderSuccessToast(t('components.ResetPasswordPage.successMessages'))
      history.push('/login')
    }).catch(({errors}) => setErrors(errors))
  }

  return (
    <PublicPageTemplate>
      <div className="container-narrow">

        <h4 className="mb-3">
          {t('components.ResetPasswordPage.title')}
        </h4>

        {errors?.baseError && (
          <div className="alert alert-danger">{t('components.ResetPasswordPage.' + errors.baseError)}</div>
        )}

        <form className="my-2 my-lg-0" onSubmit={onSubmit}>
          <div className="form-floating mb-3 required">
            <Input className="form-control" name="password" type="password" value={credentials.password}
                   onChange={onChange} error={errors?.password}/>
            <label htmlFor="password" className="form-label">
              {t('models.User.password')}
            </label>
          </div>
          <div className="form-floating mb-3 required">
            <Input className="form-control" name="passwordConfirmation" type="password"
                   value={credentials.passwordConfirmation} onChange={onChange}
                   error={errors?.passwordConfirmation}/>
            <label htmlFor="passwordConfirmation" className="form-label">
              {t('models.User.passwordConfirmation')}
            </label>
            <div className="form-text small text-muted">
              {t('errors.password_pattern')}
            </div>
          </div>
          <div>
            <button className="btn btn-lg btn-success btn-with-icon w-100">
              <span>{t('components.ResetPasswordPage.reset')}</span>
              <DoneIcon className="icon" />
            </button>
          </div>
        </form>
      </div>
    </PublicPageTemplate>
  )
}

export default ResetPasswordPage