import React from 'react'
import {useTranslation} from 'react-i18next'
import Select from '../../utils/form/Select'


interface Props {
  options: string[],
  baseOptionsKey: string,
  labelKey: string,
  placeholderKey?: string,
  name: string
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void
  value?: string,
  error?: string
  required?: boolean
  className?: string
}

const SelectWithLabel: React.FC<Props> = props => {
  const {
    options, labelKey, baseOptionsKey, placeholderKey, name, value = '', error, onChange, required = false, className
  } = props
  const {t} = useTranslation()

  return <>
    <div className={`${className}`}>
      <label htmlFor={name} className="form-label small required">
        {t(labelKey)}
      </label>
      <Select className="form-select" name={name} id={name} value={value} required={required} aria-label={t(labelKey)}
              onChange={onChange} error={error}>
        {(placeholderKey && !value) && <option value={''} disabled>{t(placeholderKey)}</option>}
        {options.map(option => {
          return <option value={option} key={option}>{t(baseOptionsKey + '.' + option)}</option>
        })}
      </Select>
    </div>
  </>
}

export default SelectWithLabel