import {Errors} from 'domain/Errors'
import {InternalTranslator} from 'domain/User'
import React from 'react'
import {useTranslation} from 'react-i18next'
import Select from '../../utils/form/Select'


interface EditInternalTranslatorFormProps {
  user: InternalTranslator
  errors: Errors<InternalTranslator>
  onChange: (e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>) => void
}

const EditInternalTranslatorForm: React.FC<EditInternalTranslatorFormProps> = ({user, errors, onChange}) => {
  const {t} = useTranslation()

  return <div className="row">
    <div className="mb-3 col-lg-12">
      <label className="form-label small">
        {t('models.User.tier')}
      </label>
      <Select className="form-select" id="tier" name="tier" onChange={onChange}
              error={errors.tier} value={user.tier || ''}>
        <option value="">{t('components.EditTranslatorForm.selectTier')}</option>
        {[1,2,3,4,5].map(tier => {
          return <option key={tier} value={tier}>{tier}</option>
        })}
      </Select>
    </div>
  </div>
}

export default EditInternalTranslatorForm