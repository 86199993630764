import {ReportState} from 'domain/Report'
import React, {PropsWithChildren, useContext} from 'react'
import {getReport} from '../api-clients/reportClient'
import reportLockManager from '../components/report/ReportLockManager'
import {ReportContext} from './ReportProvider'

export interface ReportUpdateProviderState {
  loadReport: () => Promise<void>
  saveReport: () => Promise<void>
  setMigratedReport: (report: ReportState) => void
}

export const ReportUpdateContext = React.createContext<ReportUpdateProviderState>({} as ReportUpdateProviderState)

const ReportUpdateProvider: React.FC<PropsWithChildren> = ({children}) => {
  const {reportStore, project} = useContext(ReportContext)

  const saveReport = async () => {
    const report = reportStore.getReport()
    if (!report) {
      return
    }

    const updates = reportStore.getChangedLocales()
    reportStore.reportSender.recordReportUpdate(report, updates)
  }

  const loadReport = async () => {
    const report = await getReport(project._id)
    reportLockManager.resetLockedItems()
    reportStore.setReport(report)
    reportStore.reportSender.startNewRevision(report)
  }

  const setMigratedReport = (report: ReportState) => {
    reportStore.reportSender.startNewRevision(report)
    reportStore.setReport(report)
  }

  const providerState: ReportUpdateProviderState = {
    loadReport,
    saveReport,
    setMigratedReport
  }

  return <ReportUpdateContext.Provider value={providerState}>{children}</ReportUpdateContext.Provider>
}

export default ReportUpdateProvider
