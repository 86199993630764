import {Comment} from 'domain/Comment'
import {Id} from 'domain/Entity'
import {
  COMMENT_HIGHLIGHT_CLASS,
  COMMENT_ID_PREFIX_CLASS,
  COMMENT_STICKY_HIGHLIGHT_CLASS
} from '../../../editorjs/plugins/comment/CommentTool'
import scrollToElement from '../../../utils/scrollToElement'
import {MENTION_REGEXP} from './CommentContentForm'

export const removeCommentHighlighting = (element: Element) => {
  if (element.classList.contains(COMMENT_HIGHLIGHT_CLASS)) {
    const parent = element.parentElement!
    element.replaceWith(element.innerHTML)
    parent.normalize()
  }
}

export const unhighlightCommentById = (commentId: Id) => {
  document.querySelectorAll(`.${COMMENT_ID_PREFIX_CLASS}${commentId}`).forEach(element => {
    element.classList.remove(COMMENT_HIGHLIGHT_CLASS)
  })
}

export const highlightCommentById = (commentId: Id) => {
  document.querySelectorAll(`.${COMMENT_ID_PREFIX_CLASS}${commentId}`).forEach(element => {
    element.classList.add(COMMENT_HIGHLIGHT_CLASS)
  })
}

export const removeCommentHighlightingById = (commentId: Id) => {
  document.querySelectorAll(`.${COMMENT_ID_PREFIX_CLASS}${commentId}`).forEach(element => {
    const parent = element.parentElement!
    element.replaceWith(...Array.from(element.childNodes))
    parent.normalize()
  })
}

export const highlightAsStickyComment = (elements: HTMLElement[]) => {
  for (const element of elements) {
    const replace = document.createElement('sticky-comment') as HTMLElement
    replace.className = element.className
    replace.classList.add(COMMENT_STICKY_HIGHLIGHT_CLASS)
    replace.innerHTML = element.innerHTML
    element.parentNode!.replaceChild(replace, element)
  }
}

export const highlightAsUnstickyComment = (elements: HTMLElement[]) => {
  for (const element of elements) {
    const replace = document.createElement('comment') as HTMLElement
    replace.className = element.className
    replace.classList.remove(COMMENT_STICKY_HIGHLIGHT_CLASS)
    replace.innerHTML = element.innerHTML
    element.parentNode!.replaceChild(replace, element)
  }
}

export const getCommentedElements = (comment: Pick<Comment, '_id' | 'blockId'>) => {
  const commentedElements = Array.from(
    document.querySelectorAll(`.${COMMENT_ID_PREFIX_CLASS}${comment._id}`)
  ) as HTMLElement[]

  if (commentedElements.length > 0) {
    return commentedElements
  }

  if (!comment.blockId) {
    return []
  }

  const block = document.getElementById(comment.blockId)
  return block ? [block] : []
}

export const detectCommentsAttachment = (comments: Comment[] | null) => {
  if (!comments) return

  const commentsById = comments.reduce((res, c) => {
    res[c._id as string] = c
    return res
  }, {})

  const unattachedCommentedElements = Array.from(
    document.querySelectorAll(`.${COMMENT_HIGHLIGHT_CLASS}:not([data-attached])`)
  ) as HTMLElement[]

  const query = new URLSearchParams(location.search)
  const queryCommentId = query.get('commentId')
  for (const element of unattachedCommentedElements) {
    const commentId = getCommentId(element)
    const commentExists = !!commentId && !!commentsById[commentId]

    element.dataset.attached = commentExists ? 'true' : 'false'

    if (queryCommentId && commentExists && queryCommentId === commentId) {
      scrollToElement(element)
    }
  }
}

export const getElementTopPosition = (element: HTMLElement): number => {
  const container = document.querySelector('.report-with-comments')
  const containerRect = container?.getBoundingClientRect() || {top: 0, left: 0}
  const elementRect = element.getBoundingClientRect()

  return elementRect.top + element.offsetHeight / 2 - containerRect.top
}

export const getRelativeCoordinates = (e: MouseEvent) => {
  const container = document.querySelector('.report-with-comments')
  const containerRect = container?.getBoundingClientRect() || {top: 0, left: 0}

  const commentWidth = 300
  const x = Math.min(e.clientX - containerRect.left, window.innerWidth - commentWidth)
  const y = e.clientY - containerRect.top

  return {x, y}
}

export const findMentionedEntry = (text: string) => {
  const match = text.match(MENTION_REGEXP)
  if (match) {
    return match[2]
  }

  return null
}

export const getCommentId = (element: HTMLElement) => {
  return element.className.match(/comment-id-([^\s]+)/)?.[1]
}
