import React, {PropsWithChildren} from 'react'
import useModal from '../../hooks/useModal'


export enum ModalType {
  DIALOG = 'DIALOG',
  SIDEBAR = 'SIDEBAR'
}

interface ModalTemplateProps {
  size?: 'sm' | 'md' | 'lg' | 'xl' | 'xxl' | 'fullscreen'
  title?: string
  type?: ModalType
  onBeforeClose?: () => void
}

const ModalTemplate: React.FC<PropsWithChildren<ModalTemplateProps>> = (
  {size = '', title, type = ModalType.DIALOG, onBeforeClose, children}) => {

  const {closeLastModal} = useModal()
  const modalClasses = {
    [ModalType.DIALOG]: 'modal-dialog modal-dialog-centered',
    [ModalType.SIDEBAR]: 'modal-dialog modal-side-panel transition-slide-in-left'
  }[type]

  const onCloseModal = () => {
    if (onBeforeClose) onBeforeClose()
    closeLastModal()
  }

  return (
    <div className={`${modalClasses} ${size ? `modal-${size}` : ''}`}>
      <div className="modal-content">
        {title && <div className="modal-header align-items-center">
          <h5 className="modal-title">
            {title}
          </h5>
          <button type="button" className="btn-close d-flex align-items-center" data-dismiss="modal"
                  aria-label="Close" onClick={onCloseModal}/>
        </div>}
        {children}
      </div>
    </div>
  )

}

export default ModalTemplate