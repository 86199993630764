import {Id} from 'domain/Entity'
import {GlossaryAlternativeTerm} from 'domain/Glossary'
import {Locale} from 'domain/Locale'
import React from 'react'
import {GlossaryAlternativeTermWithTick} from './BlockTranslator'
import {icons} from './translationUtils'


interface Props {
  candidate: GlossaryAlternativeTermWithTick
  translation: string
  companyId?: Id
  onTranslationChosen: (e: React.MouseEvent, translationCandidate: GlossaryAlternativeTerm) => void
  hasVerifiedCompanyTranslation: boolean
}

const InlineTranslationCandidate: React.FC<Props> = props => {
  const {candidate, translation, companyId, onTranslationChosen, hasVerifiedCompanyTranslation} = props
  const selectedClass = (someTranslation: string) => someTranslation === translation ? 'text-primary' : ''

  const translationIcon = (candidate: GlossaryAlternativeTerm) => {
    const Icon = icons[candidate.type]
    return <Icon className="icon" />
  }

  const disabled = hasVerifiedCompanyTranslation && candidate.type === 1

  return (
    <a className={`popover-menu-item ${selectedClass(candidate[Locale.EN])} ${disabled ? 'disabled' : ''}`}
       onClick={e => {
         if (!disabled) onTranslationChosen(e, candidate)
       }}>
      <span className="icon icon-sm ms-n1 me-1">{translationIcon(candidate)}</span>
      <span
        className={`popover-menu-item-text ${selectedClass(candidate[Locale.EN])}`}>
                {candidate[Locale.EN]}
              </span>
      {
        candidate.tick && candidate.companyId !== companyId &&
        <div className="ms-2 badge bg-secondary text-primary">{candidate.tick}</div>
      }
    </a>
  )
}

export default InlineTranslationCandidate