import {ObjectId} from 'bson'
import {Id} from 'domain/Entity'
import {Locale} from 'domain/Locale'
import {ReportState} from 'domain/Report'
import {SendingStatus} from 'domain/SendingStatus'
import {StatusCodes} from 'http-status-codes'
import i18next from 'i18next'
import {Delta} from 'jsondiffpatch'
import {postClientError} from '../api-clients/clientErrors'
import {updateReport} from '../api-clients/reportClient'
import EventBus, {EventType} from '../EventBus'
import deepCopy from '../utils/deepCopy'
import {calculateDiffInWorker} from '../workers/workerApi'

type ReportUpdate = {
  report: ReportState
  locale: Locale
}

class ReportSender {
  private report!: ReportState
  private revisionId!: ObjectId
  private reportUpdateHash!: string
  private upcomingUpdatedReport: ReportUpdate | null = null
  private requestDispatcher = new RequestDispatcher()
  private revisionCreated = false
  private dispatcherCreatedAt = new Date()

  constructor(private projectId: Id) {}

  startNewRevision(report: ReportState) {
    this.revisionId = new ObjectId()
    this.revisionCreated = false
    this.reportUpdateHash = report.updateHash
    this.upcomingUpdatedReport = null
    this.setCurrentReport(report)
  }

  recordReportUpdate(updatedReport: ReportState, changedLocales: Locale[]) {
    const changedLocale = (changedLocales.length === 2 ? null : changedLocales[0]) as Locale
    this.upcomingUpdatedReport = {report: updatedReport, locale: changedLocale}
    setTimeout(() => this.requestDispatcher.push(() => this.sendReportUpdate()), 0)
  }

  private async sendReportUpdate(): Promise<void> {
    if (!this.upcomingUpdatedReport) return

    const {report: updatedReport, locale} = this.upcomingUpdatedReport
    const diff = await calculateDiffInWorker(this.report, updatedReport)

    if (!diff && !this.revisionCreated) {
      return
    }

    EventBus.emit(EventType.REPORT_SENDING_STATUS_CHANGE, {status: SendingStatus.SENDING})
    try {
      await this.reportSuspiciousUpdate(diff)

      const revision = await updateReport(this.revisionId, this.projectId, this.reportUpdateHash, locale, diff)

      this.revisionCreated = true
      this.reportUpdateHash = revision.updateHash
      EventBus.emit(EventType.REPORT_SENDING_STATUS_CHANGE, {
        status: SendingStatus.SUCCEEDED,
        timestamp: revision.updatedAt
      })
    } catch (error: any) {
      EventBus.emit(EventType.REPORT_SENDING_STATUS_CHANGE, {status: SendingStatus.FAILED})
      if (error.status === StatusCodes.CONFLICT) {
        alert(i18next.t('errors.revision_conflict'))
        location.reload()
      } else {
        throw error
      }
    }
  }

  // TODO: remove this after the issue with involuntary report updates has been resolved
  private async reportSuspiciousUpdate(diff?: Delta) {
    const thresholdInMilliseconds = 3000
    const millisecondsSinceReportSenderInit = new Date().getTime() - this.dispatcherCreatedAt.getTime()
    if (millisecondsSinceReportSenderInit > thresholdInMilliseconds) {
      return
    }

    await postClientError({
      message: `Suspicious report update within ${thresholdInMilliseconds / 1000} seconds since rendering`,
      diff
    })
  }

  private setCurrentReport(report: ReportState) {
    this.report = deepCopy(report)
  }
}

class RequestDispatcher {
  private sendingRequest = false
  private isRequestInQueue = false

  push(fn: (...args: any[]) => Promise<any>) {
    if (this.sendingRequest) {
      this.isRequestInQueue = true
      return
    }

    this.sendingRequest = true
    fn().finally(() => {
      this.sendingRequest = false
      if (this.isRequestInQueue) {
        this.isRequestInQueue = false
        this.push(fn)
      }
    })
  }
}

export default ReportSender
