import {Company} from 'domain/Company'
import {Locale} from 'domain/Locale'
import {MicroTranslationProject, MicroTranslationStatus} from 'domain/MicroTranslation'
import {User} from 'domain/User'
import {ReactComponent as BusinessIcon} from '../../../assets/icons/business.svg'
import React, {useContext, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'
import {getCompany} from '../../../api-clients/companyClient'
import {assignMicroTranslationToMe, unassignMeFromMicroTranslation} from '../../../api-clients/microTranslationClient'
import {getUser} from '../../../api-clients/userClient'
import useToast from '../../../hooks/useToast'
import {UserContext} from '../../../providers/UserProvider'
import UserButton from '../../user/UserButton'


type Props = {
  project: MicroTranslationProject
  onProjectUpdated: (project: MicroTranslationProject) => void
}

const MicroTranslationDetails: React.FC<Props> = ({project, onProjectUpdated}) => {
  const {t} = useTranslation()
  const [company, setCompany] = useState<Company>()
  const history = useHistory()
  const [translator, setTranslator] = useState<User | null>(null)
  const {renderSuccessToast, renderErrorToast} = useToast()
  const {user} = useContext(UserContext)

  useEffect(() => {
    getCompany(project.companyId).then(company => setCompany(company))
  }, [])

  useEffect(() => {
    if (project?.translatorId) {
      getUser(project?.translatorId).then(setTranslator)
    } else {
      setTranslator(null)
    }
  }, [project])

  const onAssignMe = (e: React.MouseEvent) => {
    e.preventDefault()
    if (!project) return

    assignMicroTranslationToMe(project._id).then(project => {
      onProjectUpdated(project)
      renderSuccessToast(t('components.MicroTranslationDetails.assignmentSuccessful'))
    }).catch(({errors}) => {
      if (errors.error) {
        renderErrorToast(t(`components.MicroTranslationDetails.${errors.error}`))
      } else {
        renderErrorToast(t('components.MicroTranslationDetails.assignFailed'))
      }
    })
  }

  const onUnassignMe = (e: React.MouseEvent) => {
    e.preventDefault()
    if (!project) return

    unassignMeFromMicroTranslation(project._id).then(project => {
      onProjectUpdated(project)
      renderSuccessToast(t('components.MicroTranslationDetails.unassignmentSuccessful'))
    }).catch(({errors}) => {
      if (errors.error) {
        renderErrorToast(t(`components.MicroTranslationDetails.${errors.error}`))
      } else {
        renderErrorToast(t('components.MicroTranslationDetails.unassignFailed'))
      }
    })
  }

  const navigateToCompany = (e: React.MouseEvent) => {
    e.preventDefault()
    history.push(`/companies/${company!._id}`)
  }

  return <div className="text-body">
    <div className="pane-header">
      <div className="pane-title">
        <div className="d-flex">
          <div className="d-flex align-items-start">
            <BusinessIcon className="mt-1 me-2 icon-sm text-muted"/>
            <div className="flex-grow-1">
              {company && (
                <div className="text-body small">{company.name[Locale.EN]} <span
                  className="text-muted">{company.tick}</span>
                </div>
              )}
              <div className="text-muted small">{company?.name[Locale.JA] || project.companyName}</div>

              {company && <a href="#" onClick={navigateToCompany} className="small">
                {t('components.MicroTranslationDetails.details')}
              </a>}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="pane-body">
      <div className="data-list data-list-row">
        <dl>
          <dt>{t('models.MicroTranslation.translator')}</dt>
          <dd>
            {translator ? <UserButton user={translator}/> : '—'}
          </dd>
          <dt className="mt-2">{t('models.MicroTranslation.status')}</dt>
          <dd>
            <small className="text-body">{t(`models.MicroTranslationStatus.${project.status}`)}</small>
          </dd>
        </dl>
      </div>

      {project.status === MicroTranslationStatus.PENDING && <>
        {project.translatorId !== user._id && (
          <a className="btn btn-sm btn-success mt-2 w-100" onClick={onAssignMe}>
            <span>{t('components.MicroTranslationDetails.takeForTranslation')}</span>
          </a>
        )}

        {project.translatorId === user._id && (
          <a className="btn btn-sm btn-outline-primary mt-2 w-100" onClick={onUnassignMe}>
            <span>{t('components.MicroTranslationDetails.unassignMe')}</span>
          </a>
        )}
      </>}
    </div>
  </div>
}
export default MicroTranslationDetails