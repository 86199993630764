import {TermTranslation} from 'domain/Glossary'
import {ReactComponent as DeleteIcon} from '../../assets/icons/delete.svg'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {deleteGlossaryTerm} from '../../api-clients/glossaryTermClient'
import useToast from '../../hooks/useToast'
import ConfirmationModal from '../utils/ConfirmationModal'


interface DeleteGlossaryTermProps {
  term: TermTranslation
  onDeleted: () => void
}

const DeleteGlossaryTerm: React.FC<DeleteGlossaryTermProps> = ({term, onDeleted}) => {
  const {renderSuccessToast} = useToast()
  const {t} = useTranslation()

  const onDelete = () => {
    return deleteGlossaryTerm(term._id!).then(() => {
      renderSuccessToast(t('glossary.term.deleteSuccess'))
      onDeleted()
    })
  }

  return (
    <ConfirmationModal title={t('glossary.term.delete.confirmationMessage')}
                       ConfirmationIcon={DeleteIcon} onConfirm={() => onDelete()}/>
  )
}

export default DeleteGlossaryTerm