import {ReactComponent as BoldIcon} from '../../../assets/icons/format_bold.svg'
import {ReactComponent as ItalicIcon} from '../../../assets/icons/format_italic.svg'
import {ReactComponent as BulletedListIcon} from '../../../assets/icons/format_list_bulleted.svg'
import {ReactComponent as NumberedListIcon} from '../../../assets/icons/format_list_numbered.svg'
import {ReactComponent as UnderlinedIcon} from '../../../assets/icons/format_underlined.svg'
import {ReactComponent as RedoIcon} from '../../../assets/icons/redo.svg'
import {ReactComponent as TextFieldsIcon} from '../../../assets/icons/text_fields.svg'
import {ReactComponent as UndoIcon} from '../../../assets/icons/undo.svg'
import React, {useEffect, useRef, useState} from 'react'

const TextEditToolbar: React.FC = () => {
  const [toolbarVisible, setToolbarVisible] = useState(false)
  const containerDiv = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const onOutsideClickListener = (e: MouseEvent) => {
      const clickedInside = containerDiv.current && containerDiv.current.contains(e.target as Node)
      if (!clickedInside) setToolbarVisible(false)
    }

    setTimeout(() => {
      document.addEventListener('click', onOutsideClickListener, false)
    }, 0)

    return () => {
      document.removeEventListener('click', onOutsideClickListener)
    }
  }, [])

  const exec = (e: React.MouseEvent<HTMLButtonElement>, command: string) => {
    e.preventDefault()
    document.execCommand(command)
  }

  const toggleToolbar = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    setToolbarVisible(visible => !visible)
  }

  return (
    <div className="dropup" ref={containerDiv}>
      <button className="btn btn-outline-secondary btn-sm" title="Format text" type="button" onClick={toggleToolbar}>
        <TextFieldsIcon className="icon" />
      </button>

      <div
        className={`dropdown-menu dropdown-menu-start fade-in-top position-absolute elevate ${
          toolbarVisible ? 'show' : ''
        }`}
        data-bs-popper="static"
      >
        <div className="d-flex flex-row align-items-center">
          <button className="btn btn-icon-only btn-sm" title="Undo" onClick={e => exec(e, 'undo')}>
            <UndoIcon className="icon" />
          </button>
          <button className="btn btn-icon-only btn-sm" title="Redo" onClick={e => exec(e, 'redo')}>
            <RedoIcon className="icon" />
          </button>

          <div className="border-end mx-2" style={{height: '1.5rem'}} />

          <button className="btn btn-icon-only btn-sm" title="Bold" onClick={e => exec(e, 'bold')}>
            <BoldIcon className="icon" />
          </button>
          <button className="btn btn-icon-only btn-sm" title="Italic" onClick={e => exec(e, 'italic')}>
            <ItalicIcon className="icon" />
          </button>
          <button className="btn btn-icon-only btn-sm" title="Underline" onClick={e => exec(e, 'underline')}>
            <UnderlinedIcon className="icon" />
          </button>

          <div className="border-end mx-2" style={{height: '1.5rem'}} />

          <button
            className="btn btn-icon-only btn-sm"
            title="Bulleted list"
            onClick={e => exec(e, 'insertUnorderedList')}
          >
            <BulletedListIcon className="icon" />
          </button>
          <button
            className="btn btn-icon-only btn-sm"
            title="Numbered list"
            onClick={e => exec(e, 'insertOrderedList')}
          >
            <NumberedListIcon className="icon" />
          </button>
        </div>
      </div>
    </div>
  )
}
export default TextEditToolbar
