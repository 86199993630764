import {useContext, useEffect} from 'react'
import {detectCommentsAttachment} from '../components/report/comments/commentUtils'
import EventBus, {EventType} from '../EventBus'
import {CommentContext} from '../providers/CommentProvider'


export const useComments = () => {
  const {comments} = useContext(CommentContext)

  useEffect(() => {
    detectCommentsAttachment(comments)

    const onDetectCommentsAttachment = () => {
      setTimeout(() => detectCommentsAttachment(comments), 0)
    }

    EventBus.on(EventType.EDITOR_READY, onDetectCommentsAttachment)
    EventBus.on(EventType.TRANSLATION_PAGE_READY, onDetectCommentsAttachment)
    EventBus.on(EventType.ON_UNDO_REDO, onDetectCommentsAttachment)

    return () => {
      EventBus.unsubscribe(EventType.EDITOR_READY, onDetectCommentsAttachment)
      EventBus.unsubscribe(EventType.TRANSLATION_PAGE_READY, onDetectCommentsAttachment)
      EventBus.unsubscribe(EventType.ON_UNDO_REDO, onDetectCommentsAttachment)
    }
  }, [comments])
}