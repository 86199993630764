import {Errors} from 'domain/Errors'
import {ExternalTranslator, ResponseSpeed} from 'domain/User'
import React from 'react'
import {useTranslation} from 'react-i18next'
import Input from '../../utils/form/Input'
import Select from '../../utils/form/Select'


interface EditExternalTranslatorFormProps {
  user: ExternalTranslator
  errors: Errors<ExternalTranslator>
  onChange: (e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>) => void
}

const EditExternalTranslatorForm: React.FC<EditExternalTranslatorFormProps> = ({user, errors, onChange}) => {
  const {t} = useTranslation()

  return <div className="row">
    <div className="mb-3 col-lg-6">
      <label className="form-label small">
        {t('models.User.tier')}
      </label>
      <Select className="form-select" id="tier" name="tier" onChange={onChange}
              error={errors.tier} value={user.tier || ''}>
        <option value="">{t('components.EditTranslatorForm.selectTier')}</option>
        {[1,2,3,4,5].map(tier => {
          return <option key={tier} value={tier}>{tier}</option>
        })}
      </Select>
    </div>
    <div className="mb-3 col-lg-6">
      <label className="form-label small">
        {t('models.User.ratePerCharacter')}
      </label>
      <Input className="form-control" type="number" id="ratePerCharacter" name="ratePerCharacter" onChange={onChange}
             error={errors.ratePerCharacter} value={user.ratePerCharacter || ''}>
      </Input>
    </div>
    <div className="mb-3 col-lg-12">
      <label className="form-label small">
        {t('models.User.responseSpeed')}
      </label>
      <Select className="form-select" id="responseSpeed" name="responseSpeed" onChange={onChange}
              error={errors.responseSpeed} value={user.responseSpeed || ''}>
        <option value="">{t('components.EditTranslatorForm.selectResponseSpeed')}</option>
        {Object.values(ResponseSpeed).map(speed => {
          return <option key={speed} value={speed}>{t(`models.ResponseSpeed.${speed}`)}</option>
        })}
      </Select>
    </div>
  </div>
}

export default EditExternalTranslatorForm