import classNames from 'classnames'
import {hasTaskAccess} from 'domain/access'
import {Permission} from 'domain/Permission'
import {getStageRules} from 'domain/project-stages/projectStageHelpers'
import {AggregatedTask, TaskStatus} from 'domain/Task'
import {formattedBusinessDuration} from 'domain/tasks/taskScheduleUtils'
import {ReactComponent as ScheduleIcon} from '../../assets/icons/schedule.svg'
import React, {useContext, useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import {Link} from 'react-router-dom'
import {finishProjectTask} from '../../api-clients/projectTaskClient'
import {LocaleContext} from '../../providers/LocaleProvider'
import {UserContext} from '../../providers/UserProvider'
import memoizeComponent from '../../utils/memoizeComponent'
import CompanyButton from '../companies/CompanyButton'
import TaskRescheduleButton from '../project/tasks/TaskRescheduleButton'
import FormattedDate from '../utils/FormattedDate'
import Protected from '../utils/Protected'


type Props = {
  task: AggregatedTask
  onUpdated: () => void
  isContinuing: boolean
}

const ProjectTaskCard: React.FC<Props> = ({task, onUpdated, isContinuing}) => {
  const {user} = useContext(UserContext)
  const {locale} = useContext(LocaleContext)
  const {t} = useTranslation()
  const isOverdue = new Date(task.endDate) < new Date() && task.status !== TaskStatus.FINISHED

  const stageRule = useMemo(() => {
    return getStageRules(task.project.type).find(rule => rule.stage === task.stage)
  }, [task])

  const onDone = (e: React.MouseEvent) => {
    e.preventDefault()
    finishProjectTask(task._id).then(onUpdated)
  }

  const taskClasses = {
    'card card-clickable mb-2': true,
    'card-danger': isOverdue,
    'card-success': task.status === TaskStatus.FINISHED
  }

  const dateButtonClasses = `btn btn-xs btn-with-icon ${isOverdue ? 'btn-outline-danger' : 'btn-outline-secondary'}`
  const dateButtonContent = <>
    <ScheduleIcon className="icon icon-xs me-1 ms-0"/>
    <FormattedDate date={task.endDate}/>
  </>

  return <Link className={classNames(taskClasses)} to={`/projects/${task.projectId}`}>
    <div className="card-body p-2">
      <div className="d-flex justify-content-between">
        <div className="mb-2">
          <div className="small fw-semibold">
            {isContinuing && <div className="small text-muted-more">
              {t('components.ProjectTaskCard.continuing')}
            </div>}
            {t(`models.ProjectStage.${task.stage}.title`)}
          </div>

          <div className="small text-muted">
            {task.project.title}
          </div>
        </div>

        <div>
          <Protected permission={Permission.ProjectTask.RESCHEDULE} else={<button className={dateButtonClasses}>
            {dateButtonContent}
          </button>}>
            <TaskRescheduleButton task={task} project={task.project} onRescheduled={onUpdated}
                                  classNames={dateButtonClasses}>
              {dateButtonContent}
            </TaskRescheduleButton>
          </Protected>
        </div>
      </div>

      <div className="d-flex align-items-center flex-wrap mt-n1 justify-content-between">
        <CompanyButton company={task.company} buttonClass="btn-outline-secondary me-1 mt-1"/>
        {stageRule && <span className="small text-muted">
          {formattedBusinessDuration(stageRule.hours, locale)}
        </span>}
      </div>

      {task.status === TaskStatus.IN_PROGRESS && hasTaskAccess(user, task.project, task) && (
        <div className="d-flex mt-2">
          <button className="btn btn-sm btn-outline-success me-1 flex-grow-1" onClick={onDone}>
            <span>{t('components.InTranslationCard.done')}</span>
          </button>
        </div>
      )}

      {task.status === TaskStatus.SCHEDULED && <div className="small text-muted">
        <span className="small">{t('components.ProjectTaskCard.waiting')}</span>
      </div>}
    </div>
  </Link>
}

export default memoizeComponent(ProjectTaskCard)