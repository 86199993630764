import {Locale} from 'domain/Locale'
import React, {PropsWithChildren, ReactElement} from 'react'
import {useTranslation} from 'react-i18next'
import {Link} from 'react-router-dom'
import CompanyButton from '../../companies/CompanyButton'
import TaskAssignee from '../../project/TaskAssignee'
import DeadlineOverdueBadge from '../../projects/DeadlineOverdueBadge'
import FormattedDate from '../../utils/FormattedDate'
import {ProjectAggregate} from 'domain/ProjectAggregate'

interface ProjectCardProps {
  project: ProjectAggregate
  badges?: React.ReactNode | React.ReactNode[]
  showAssignees?: boolean
}

const ProjectCard: React.FC<PropsWithChildren<ProjectCardProps>> = ({
  project,
  children,
  badges,
  showAssignees = true
}): ReactElement => {
  const {t} = useTranslation()

  return (
    <Link className="card card-clickable mb-2" to={`/projects/${project._id}`}>
      <div className="card-body p-2">
        <div className="d-flex justify-content-between">
          <div className="small fw-semibold mb-1">{project.title}</div>
          {project.publishedAt?.ja ? (
            <div className="small text-right small text-nowrap">
              <span className="badge rounded-pill me-1 bg-success">
                <FormattedDate date={project.publishedAt.ja} />
              </span>
            </div>
          ) : (
            <div className="small text-right small text-nowrap">
              <DeadlineOverdueBadge locale={Locale.EN} project={project} />
            </div>
          )}
        </div>

        <div className="d-flex align-items-center flex-wrap mt-n1">
          <CompanyButton company={project.company} buttonClass="btn-outline-secondary me-1 mt-1" />
          <div className="btn btn-xs btn-outline-secondary btn-with-icon me-1 mt-1">
            <span>{t(`models.ProjectType.${project.type}`)}</span>
          </div>
          {badges}
        </div>
        {showAssignees && project.activeTask && (
          <TaskAssignee task={project.activeTask} buttonClass="mt-1 me-1" />
        )}
        {children}
      </div>
    </Link>
  )
}

export default ProjectCard