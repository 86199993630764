import {Locale} from 'domain/Locale'
import React, {useState} from 'react'
import ReactDOM from 'react-dom/server'
import {I18nextProvider} from 'react-i18next'
import i18n from '../../../../api/src/i18n'
import BatchUnreadProjectNotification from './BatchUnreadProjectNotification'
import {
  aggregatedProject,
  projectNotifications,
  translator,
  translationRequest,
  assigner,
  coordinator,
} from './email-fixtures'
import EmailLayout from './EmailLayout'
import TranslationAssignmentNotification from './TranslationAssignmentNotification'
import TranslationRequestAcceptedNotification from './TranslationRequestAcceptedNotification'
import TranslationRequestCancelledNotification from './TranslationRequestCancelledNotification'
import TranslationRequestRejectedNotification from './TranslationRequestRejectedNotification'
import TranslatorFinishedNotification from './TranslatorFinishedNotification'


const EMAILS = {
  batchUnreadProjectNotification: <BatchUnreadProjectNotification notifications={projectNotifications}/>,
  translationAssignmentNotification:
    <TranslationAssignmentNotification
      translator={translator}
      project={aggregatedProject}
      message={translationRequest.message}
      translationWritingDeadline={new Date()}
    />,
  translationRequestAcceptedNotification:
    <TranslationRequestAcceptedNotification
      assigner={assigner}
      translator={translator}
      project={aggregatedProject}
    />,
  translationRequestCancelledNotification:
    <TranslationRequestCancelledNotification
      coordinator={coordinator}
      project={aggregatedProject}
      translator={translator}
      translationRequest={translationRequest}
    />,
  translationRequestRejectedNotification:
    <TranslationRequestRejectedNotification
      assigner={assigner}
      project={aggregatedProject}
      translator={translator}
      translationRequest={translationRequest}
    />,
  translatorFinishedNotification:
    <TranslatorFinishedNotification project={aggregatedProject} translator={translator}/>
}

const EmailsPreview: React.FC = () => {
  const [selectedEmail, setSelectedEmail] = useState(Object.keys(EMAILS)[0])
  const onSelectEmail = (e: React.MouseEvent, email: string) => {
    e.preventDefault()
    setSelectedEmail(email)
  }

  const emailContent = ReactDOM.renderToString(
    <I18nextProvider i18n={i18n}>
      <EmailLayout host={`${location.protocol}//${location.host}`}>{EMAILS[selectedEmail]}</EmailLayout>
    </I18nextProvider>
  )

  return <div className="d-flex">
    <ul className="mt-3 d-flex flex-column">
      {Object.keys(EMAILS).map(email => (
        <li onClick={e => onSelectEmail(e, email)} key={email} className="mb-2 btn-link" style={{cursor: 'pointer'}}>
          {email}
        </li>
      ))}
    </ul>

    {selectedEmail && <div className="mt-5 m-auto">
      <iframe srcDoc={emailContent} style={{width: '600px', minHeight: '800px'}}/>
    </div>}
  </div>
}

export default EmailsPreview