import {BookmarkBlock} from 'domain/Report'
import {ReactComponent as BookmarkIcon} from '../../../assets/icons/bookmark_border.svg'
import React, {ReactElement, useContext, useEffect, useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'
import useTrackSelectedBlocks from '../../../hooks/useTrackSelectedBlocks'
import {ReportContext} from '../../../providers/ReportProvider'
import debounce from '../../../utils/debounce'
import {getBookmarks} from '../../../utils/reportHelpers'
import {scrollToElementWithRetry} from '../../../utils/scrollToElement'
import {SelectedBlocksContext} from './SelectedBlocksProvider'

const BookmarkSelectionMenu: React.FC = (): ReactElement => {
  const {reportStore} = useContext(ReportContext)
  const {selectedBlocks} = useContext(SelectedBlocksContext)
  const {t} = useTranslation()

  const report = reportStore.getReport()
  const [sectionBookmarks, setSectionBookmarks] = useState<Record<string, BookmarkBlock[]>>(getBookmarks(report))

  const showMenu = useMemo(() => {
    return selectedBlocks.length > 0 && Object.keys(sectionBookmarks).length > 0
  }, [selectedBlocks, sectionBookmarks])

  useTrackSelectedBlocks(showMenu)

  const debouncedUpdateBookmark = debounce(() => {
    setSectionBookmarks(getBookmarks(reportStore.getReport()))
  }, 1000)

  useEffect(() => {
    reportStore.onUpdate(() => {
      debouncedUpdateBookmark()
    })
  }, [])

  const moveToBookmark = (e: React.MouseEvent, sectionId: string, bookmark: BookmarkBlock) => {
    e.preventDefault()
    if (!report) return

    report.sections.forEach(section => {
      section.blocks = section.blocks.filter(block => !selectedBlocks.includes(block))
    })

    const targetSection = report.sections.find(section => section._id === sectionId)!
    const bookmarkIndex = targetSection.blocks.indexOf(bookmark)
    targetSection.blocks.splice(bookmarkIndex + 1, 0, ...selectedBlocks)

    reportStore.setReport(report)

    scrollToElementWithRetry(() => document.getElementById(bookmark.id))
  }

  return (
    <>
      {showMenu ? (
        <div className="context-menu context-menu-floating">
          <div className="context-menu-section-title">{t('components.BookmarkSelectionMenu.title')}</div>
          {Object.entries(sectionBookmarks).map(([sectionId, bookmarks]) => {
            return bookmarks.map(bookmark => (
              <a key={bookmark.id} onClick={e => moveToBookmark(e, sectionId, bookmark)} className="context-menu-item">
                <BookmarkIcon className="icon icon-sm ms-n1 me-1 text-info" />
                <span className="context-menu-item-text">{bookmark.title}</span>
              </a>
            ))
          })}
        </div>
      ) : (
        <></>
      )}
    </>
  )
}

export default BookmarkSelectionMenu
