import React, {ReactElement, ReactNode} from 'react'
import IntrinsicAttributes = React.JSX.IntrinsicAttributes


type ProviderType = ({children}: { children: ReactNode | ReactNode[] }) => ReactElement

export function withProviders<T extends IntrinsicAttributes>(providers: ProviderType[], Component: React.FC<T>) {
  return (props: T) => {
    return providers.reduce((Result, Provider) => {
      return <Provider>{Result}</Provider>
    }, <Component {...props} />)
  }
}