import {Paginated} from 'domain/Entity'
import {AggregatedMicroTranslationProject} from 'domain/MicroTranslation'
import React, {FC, ReactElement} from 'react'
import PaginatedView from '../utils/PaginatedView'
import MicroTranslationCard from './MicroTranslationCard'


interface Props {
  loadProjects: () => Promise<Paginated<AggregatedMicroTranslationProject>>
}

const PaginatedColumn: FC<Props> = ({loadProjects}): ReactElement => {
  return (
    <PaginatedView fetchCollection={loadProjects} render={microTranslation => {
      return <MicroTranslationCard microTranslation={microTranslation}/>
    }}/>
  )
}

export default PaginatedColumn