import {CommentStatus, CommentWithUserName} from 'domain/Comment'
import React, {ReactElement, useEffect, useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'
import EventBus, {EventType} from '../../../EventBus'
import useForceRender from '../../../hooks/useForceRender'
import scrollToElement from '../../../utils/scrollToElement'
import ModalTemplate, {ModalType} from '../../utils/ModalTemplate'
import Comment from './Comment'
import CommentFilters from './CommentFilters'
import {getCommentedElements} from './commentUtils'
import {filterComments} from './filterComments'

interface CommentsSidebarProps {
  localeComments: CommentWithUserName[]
  onClose: () => void
}

const CommentsSidebar: React.FC<CommentsSidebarProps> = ({localeComments, onClose}): ReactElement => {
  const {t} = useTranslation()
  const {rerender} = useForceRender()
  const users = useMemo(
    () =>
      localeComments.map(({userId, username}) => ({_id: userId as string, name: username})).uniqueBy(({_id}) => _id),
    []
  )
  const userIds = useMemo(() => users.map(user => user._id), [])
  const defaultFilters = useMemo(
    () => ({
      userIds,
      status: CommentStatus.ACTIVE,
      isSticky: false,
      isVisible: false
    }),
    []
  )
  const [commentFilters, setCommentFilters] = useState(defaultFilters)
  const onEditorReady = () => {
    rerender()
  }

  useEffect(() => {
    EventBus.on(EventType.EDITOR_READY, onEditorReady)
    EventBus.on(EventType.TRANSLATION_PAGE_READY, onEditorReady)

    return () => {
      EventBus.unsubscribe(EventType.EDITOR_READY, onEditorReady)
      EventBus.unsubscribe(EventType.TRANSLATION_PAGE_READY, onEditorReady)
    }
  }, [])

  const onClick = (comment: CommentWithUserName) => {
    const commentedElements = getCommentedElements(comment)
    if (commentedElements.length === 0) {
      return
    }

    enableBlockEditing(commentedElements)
    scrollToElement(commentedElements.first())
  }

  const enableBlockEditing = (commentedElements: HTMLElement[]) => {
    const blockId = commentedElements[0]?.closest('[data-block]')?.id
    EventBus.emit(EventType.ACTIVATE_TRANSLATED_BLOCK, {blockId})
  }

  return (
    <ModalTemplate
      type={ModalType.SIDEBAR}
      title={t('components.Report.comments.CommentsSidebar.title')}
      onBeforeClose={onClose}
    >
      <div className="modal-body">
        <CommentFilters users={users} userIds={userIds} onChange={setCommentFilters} />
        {filterComments(localeComments, commentFilters).map(comment => (
          <div
            className="d-flex justify-content-between align-items-center mb-2 comments-sidebar"
            onClick={() => onClick(comment)}
            key={comment._id as string}
          >
            <Comment comment={comment} commentedElements={getCommentedElements(comment)} />
          </div>
        ))}
      </div>
    </ModalTemplate>
  )
}

export default CommentsSidebar
