export enum EventType {
  HOVER = 'HOVER',
  EXPIRE_SESSION = 'EXPIRE_SESSION',
  NOTIFY_FORBIDDEN = 'NOTIFY_FORBIDDEN',
  EDITOR_READY = 'EDITOR_READY',
  HTML_DIFF_UPDATED = 'HTML_DIFF_UPDATED',
  TRANSLATION_PAGE_READY = 'TRANSLATION_PAGE_READY',
  REPORT_CONTENT_PASTED = 'REPORT_CONTENT_PASTED',
  REPORT_SENDING_STATUS_CHANGE = 'REPORT_SENDING_STATUS_CHANGE',
  MERGE_CONFLICT_RENDERED = 'MERGE_CONFLICT_RENDERED',
  MERGE_CONFLICT_RESOLVED = 'MERGE_CONFLICT_RESOLVED',
  MOVE_TO_BOOKMARK = 'MOVE_TO_BOOKMARK',
  BLOCK_DELETED = 'BLOCK_DELETED',
  BLOCK_CONVERSION = 'BLOCK_CONVERSION',
  SET_BLOCK_SELECTION = 'SET_BLOCK_SELECTION',
  IF_DELETE_CONFIRMATION_REQUIRED = 'IF_DELETE_CONFIRMATION_REQUIRED',
  IF_DELETE_CONFIRMATION_REQUIRED_RESPONSE = 'IF_DELETE_CONFIRMATION_REQUIRED_RESPONSE',
  TOGGLE_SHOW_JA_EN_DIFF = 'TOGGLE_SHOW_JA_EN_DIFF',
  ACTIVATE_TRANSLATED_BLOCK = 'ACTIVATE_TRANSLATED_BLOCK',
  ON_UNDO_REDO = 'ON_UNDO_REDO',
  REFRESH_PAGINATED_COLLECTION = 'REFRESH_PAGINATED_COLLECTION',
  REFRESH_PROJECT_LOGS = 'REFRESH_PROJECT_LOGS',
  TEXT_PASTED = 'TEXT_PASTED',
  TEXT_COPIED = 'TEXT_COPIED',
  TASK_RESCHEDULED = 'TASK_RESCHEDULED'
}

class EventBus {
  static on(event: EventType, listener: EventListener) {
    document.addEventListener(event, listener)
  }

  static emit(event: EventType, data?: object) {
    document.dispatchEvent(new CustomEvent(event, {detail: data}))
  }

  static unsubscribe(event: EventType, listener: EventListener) {
    document.removeEventListener(event, listener)
  }
}

export default EventBus