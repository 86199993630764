export const make = (tagName: string, classNames: any[] | string | null = null, attributes: object = {}) => {
  const el = document.createElement(tagName)

  if (Array.isArray(classNames)) {
    el.classList.add(...classNames)
  } else if (classNames) {
    el.classList.add(classNames)
  }

  for (const attrName in attributes) {
    if (Object.prototype.hasOwnProperty.call(attributes, attrName)) {
      el[attrName] = attributes[attrName]
    }
  }

  return el
}

export const onPasteInContentEditable = (event: ClipboardEvent) => {
  event.stopPropagation()
  event.preventDefault()

  const plain = event.clipboardData!.getData('text/plain')
  const html = plain.replaceAll('\n', '<br>')
  document.execCommand('insertHTML', false, html)
}

export const containsTable = (html: string): boolean => {
  return html.match(/<table[\s\S]*>/) !== null
}

export const containsImage = (html: string): boolean => {
  return html.includes('<img')
}