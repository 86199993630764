import {AggregatedTask, TaskStatus} from 'domain/Task'
import {User} from 'domain/User'
import moment from 'moment-timezone'
import React, {useContext, useEffect, useMemo, useState} from 'react'
import {Calendar, momentLocalizer, stringOrDate} from 'react-big-calendar'
import {useTranslation} from 'react-i18next'
import {getUserTasks} from '../../api-clients/taskClient'
import {LocaleContext} from '../../providers/LocaleProvider'
import ModalTemplate from '../utils/ModalTemplate'


type Props = {
  user: User
}
const UserTaskCalendarModal: React.FC<Props> = ({user}) => {
  const {t} = useTranslation()
  const {locale} = useContext(LocaleContext)
  const [tasks, setTasks] = useState<AggregatedTask[]>([])
  const [dates, setDates] = useState<{ from: Date, to: Date }>({
    from: moment().startOf('month').toDate(),
    to: moment().endOf('month').toDate()
  })

  useEffect(() => {
    getUserTasks(user._id, dates.from, dates.to).then(userTasks => {
      setTasks(userTasks.filter(t => t.status !== TaskStatus.FINISHED))
    })
  }, [dates])

  const timeslots = useMemo(() => {
    return tasks.map(task => {
      const localizedStage = t(`models.ProjectStage.${task.stage}.title`)

      return {
        start: new Date(task.startDate),
        end: new Date(task.endDate),
        title: `${task.company.tick} ${localizedStage} | ${task.project.title}`
      }
    })
  }, [tasks])

  const onRangeChange = (range: Date[] | { start: stringOrDate; end: stringOrDate }) => {
    if (Array.isArray(range)) {
      setDates({from: range[0], to: range[range.length - 1]})
    } else {
      setDates({from: new Date(range.start), to: new Date(range.end)})
    }
  }

  return <ModalTemplate title={t('components.UserTaskCalendarModal.title')} size="xl">
    <div className="modal-body">
      <Calendar localizer={momentLocalizer(moment)}
                events={timeslots}
                showMultiDayTimes={true}
                culture={locale}
                startAccessor="start"
                endAccessor="end"
                defaultView="month"
                onRangeChange={onRangeChange}
                views={['agenda', 'day', 'week', 'month']}
                style={{height: 600}}/>
    </div>
  </ModalTemplate>
}

export default UserTaskCalendarModal