import {Errors} from 'domain/Errors'
import {TranslationRequest} from 'domain/TranslationRequest'
import {User} from 'domain/User'
import {ReactComponent as CloseIcon} from '../../assets/icons/close.svg'
import {ReactComponent as DoneIcon} from '../../assets/icons/done.svg'
import React, {ReactElement, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {requestTranslation} from '../../api-clients/translationRequestClient'
import useModal from '../../hooks/useModal'
import useToast from '../../hooks/useToast'
import Textarea from '../utils/form/Textarea'
import ModalTemplate from '../utils/ModalTemplate'
import {ProjectAggregate} from 'domain/ProjectAggregate'

interface Props {
  project: ProjectAggregate
  translator: User
  onAssigned: (request: TranslationRequest) => void
}

const TranslationRequestMessageModal: React.FC<Props> = ({project, translator, onAssigned}): ReactElement => {
  const {closeModal, closeLastModal} = useModal()
  const {t} = useTranslation()
  const {renderSuccessToast} = useToast()
  const [errors, setErrors] = useState<Errors<TranslationRequest>>({})
  const [message, setMessage] = useState<string>('')

  const onChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    event.preventDefault()
    const value = event.target.value
    setMessage(value)
  }

  const onAssign = (event: React.MouseEvent) => {
    event.preventDefault()

    requestTranslation(project._id, translator._id, message)
      .then(request => {
        closeModal()
        onAssigned(request)
        renderSuccessToast(t('components.TranslationRequestMessageModal.requestSent'))
      })
      .catch(({errors}) => setErrors(errors))
  }

  return (
    <ModalTemplate title={t('components.TranslationRequestMessageModal.title')}>
      <div className="modal-body">
        <Textarea
          value={message}
          onChange={onChange}
          error={errors.message}
          className="form-control mt-2"
          placeholder={t('components.TranslationRequestMessageModal.messagePlaceholder')}
        />
      </div>
      <div className="modal-footer justify-content-between">
        <button type="button" className="btn btn-danger btn-with-icon" onClick={closeLastModal}>
          <CloseIcon className="icon" />
          <span>{t('components.TranslationRequestMessageModal.cancel')}</span>
        </button>
        <button type="submit" className="btn btn-success btn-with-icon" onClick={onAssign}>
          <DoneIcon className="icon" />
          <span>{t('components.TranslationRequestMessageModal.send')}</span>
        </button>
      </div>
    </ModalTemplate>
  )
}

export default TranslationRequestMessageModal
