import {Locale} from 'domain/Locale'
import React from 'react'

type LanguageSwitcherProps = {
  activeLocale: Locale
  setLocale: (locale: Locale) => void
}

const LanguageSwitcher: React.FC<LanguageSwitcherProps> = ({activeLocale, setLocale}) => {
  const onClick = (event: React.MouseEvent, locale: Locale) => {
    event.preventDefault()
    setLocale(locale)
  }

  return (<div className="btn-group" data-testid="language-switcher">
    {Object.values(Locale).map(locale => {
      return (<button type="button" key={locale}
                      className={`btn btn-sm ${activeLocale === locale ? 'btn-primary' : 'btn-outline-primary'} text-uppercase`}
                      onClick={e => onClick(e, locale)}>{locale}
      </button>)
    })}
  </div>)
}

export default LanguageSwitcher