import {Errors} from 'domain/Errors'
import {TranslationRequest} from 'domain/TranslationRequest'
import {ReactComponent as CloseIcon} from '../../assets/icons/close.svg'
import {ReactComponent as DoneIcon} from '../../assets/icons/done.svg'
import React, {ReactElement, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {rejectTranslationRequest} from '../../api-clients/translationRequestClient'
import useModal from '../../hooks/useModal'
import useToast from '../../hooks/useToast'
import Textarea from '../utils/form/Textarea'
import ModalTemplate from '../utils/ModalTemplate'
import {ProjectAggregate} from 'domain/ProjectAggregate'


interface Props {
  translationRequest: TranslationRequest,
  onRejected: (project: ProjectAggregate) => void
}

const TranslationRequestRejectModal: React.FC<Props> = ({translationRequest, onRejected}): ReactElement => {
  const {closeModal} = useModal()
  const {t} = useTranslation()
  const {renderSuccessToast} = useToast()
  const [errors, setErrors] = useState<Errors<TranslationRequest>>({})
  const [reply, setReply] = useState<string>('')

  const onChangeReply = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    event.preventDefault()
    const value = event.target.value
    setReply(value)
  }

  const onReject = (event: React.MouseEvent) => {
    event.preventDefault()
    rejectTranslationRequest(translationRequest._id, reply).then(project => {
      closeModal()
      onRejected(project)
      renderSuccessToast(t('components.TranslationRequestRejectModal.rejected'))
    }).catch(({errors}) => setErrors(errors))
  }

  return (
    <ModalTemplate title={t('components.TranslationRequestRejectModal.title')}>
      <div className="modal-body">
        {t('components.TranslationRequestRejectModal.body')}
        <Textarea value={reply} onChange={onChangeReply} error={errors.reply} className="form-control mt-2"
                  placeholder={t('components.TranslationRequestRejectModal.placeholder')}/>
      </div>
      <div className="modal-footer justify-content-between">
        <button type="button" className="btn btn-danger btn-with-icon" onClick={closeModal}>
          <span>{t('components.TranslationRequestRejectModal.cancel')}</span>
          <CloseIcon className="icon" />
        </button>
        <button type="submit" className="btn btn-success btn-with-icon" onClick={onReject} disabled={!reply}>
          <span>{t('components.TranslationRequestRejectModal.reject')}</span>
          <DoneIcon className="icon" />
        </button>
      </div>
    </ModalTemplate>
  )
}

export default TranslationRequestRejectModal
