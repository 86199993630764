import React, {ReactElement} from 'react'
import {useTranslation} from 'react-i18next'
import {DetectedEnvironment, detectEnvironment} from '../../utils/detectEnvironment'


const EnvironmentIndicationBadge: React.FC = (): ReactElement => {
  const {t} = useTranslation()
  const environment = detectEnvironment()
  if (environment === DetectedEnvironment.PRODUCTION) return <></>
  return <span className="badge rounded-pill bg-warning me-2 ms-n2">
    {t(`components.EnvironmentIndicationBadge.${environment}`)}
  </span>
}

export default EnvironmentIndicationBadge