import {Permission} from 'domain/Permission'
import {ReactComponent as EditIcon} from '../../assets/icons/edit.svg'
import noImagePlaceholder from '../../assets/images/no-image-placeholder.png'
import React from 'react'
import {useTranslation} from 'react-i18next'
import useModal from '../../hooks/useModal'
import UploadImagesForm from '../companies/UploadImagesForm'
import Protected from '../utils/Protected'

import {AggregatedCompany} from 'domain/Company'


interface CompanyImagesProps {
  company: AggregatedCompany
  onCompanyUpdates: (company: AggregatedCompany) => void
}

const CompanyImages: React.FC<CompanyImagesProps> = ({company, onCompanyUpdates}) => {
  const {showModal} = useModal()
  const {t} = useTranslation()

  const editCompanyImages = (event: React.MouseEvent) => {
    event.preventDefault()
    showModal(<UploadImagesForm company={company!} onCompanyUpdated={onCompanyUpdates}/>)
  }

  const bgImageUrl = company.bgImage?.externalUrl ? encodeURI(company.bgImage.externalUrl) : 'none'

  return <div className="card mb-3">
    <div className="card-header">
      <h5 className="card-title d-flex justify-content-between mb-0">
        {t('components.CompanyImages.title')}
      </h5>
      <Protected permission={Permission.Company.UPDATE}>
        <div className="card-header-actions">
          <button title={t('components.CompanyImages.edit')} onClick={editCompanyImages}
                  className="btn btn-outline-secondary btn-sm btn-icon-only">
            <EditIcon className="icon icon-sm"/>
          </button>
        </div>
      </Protected>
    </div>

    <div className="card-body p-0">
      <div className="bg-img-cover ps-4"
           style={
             {
               backgroundImage: `url("${bgImageUrl}")`,
               height: 150
             }
           }>
        <img src={company.logoImage?.externalUrl || noImagePlaceholder} className="img-thumbnail" width="100"/>
      </div>
    </div>
  </div>
}

export default CompanyImages