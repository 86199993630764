import {Locale} from 'domain/Locale'
import {ListBlock, ListItem, ListStyle} from 'domain/Report'
import React, {ReactElement} from 'react'
import RawHtml from '../../utils/RawHtml'


interface ListProps {
  block: ListBlock,
  locale: Locale
}

const List: React.FC<ListProps> = ({block, locale}): ReactElement => {
  const ListComponent = block.style === ListStyle.ORDERED ? 'ol' : 'ul'

  const renderList = (items: ListItem[], listIndex = `${block.id}-${locale}`) => {
    return items.map((item, index) => {
      const listItemIndex = `${listIndex}-${index}`

      if (item instanceof Array) {
        return (<ul key={listItemIndex}>
          {renderList(item, listItemIndex)}
        </ul>)
      } else {
        return <li key={listItemIndex}><RawHtml>{item}</RawHtml></li>
      }
    })
  }

  return (
    <ListComponent>
      {renderList(block.items[locale]?.value || [])}
    </ListComponent>
  )
}

export default List
