import {ReactComponent as DotsIcon} from '../../assets/icons/dots.svg'
import React from 'react'
import {OverlayTrigger, Popover} from 'react-bootstrap'

const DropdownMenu: React.FC<{id: string; children: React.ReactNode[]}> = ({children, id}) => {
  const menu = (
    <Popover id={id} className="nav-settings-tooltip dropdown-menu" onClick={() => document.body.click()}>
      <Popover.Body className="p-0">
        <div className="nav-settings-dropdown">{children}</div>
      </Popover.Body>
    </Popover>
  )

  return (
    <OverlayTrigger overlay={menu} rootClose placement="bottom-end" trigger="click" delay={0}>
      <a className="report-toc-section-options">
        <DotsIcon className="icon" />
      </a>
    </OverlayTrigger>
  )
}

export default DropdownMenu
