import React from 'react'
import {useTranslation} from 'react-i18next'


type ErrorProps = React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> & {
  error: string
}

const Error: React.FunctionComponent<ErrorProps> = ({error, ...props}) => {
  const {t} = useTranslation()

  return (
    <div className="invalid-feedback">
      {t(`errors.${error}`, {defaultValue: error})}
    </div>
  )
}

export default Error
