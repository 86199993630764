import {User} from 'domain/User'
import {ReactComponent as PersonIcon} from '../../assets/icons/person.svg'
import React, {ReactElement} from 'react'
import {OverlayTrigger, Popover} from 'react-bootstrap'
import {Link} from 'react-router-dom'
import UserDetails from './UserDetails'

interface UserButtonProps {
  user: User
  buttonClass?: string
}

const UserButton: React.FC<UserButtonProps> = ({user, buttonClass}): ReactElement => {
  const userPopover = (
    <Popover id="popover-basic">
      <Popover.Header as="div">
        <div className="d-flex justify-content-between">
          <div>{user.name}</div>
        </div>
      </Popover.Header>

      <Popover.Body>
        <UserDetails user={user} />
      </Popover.Body>
    </Popover>
  )

  return (
    <OverlayTrigger overlay={userPopover} placement="auto">
      <Link
        to={`/users/${user._id}`}
        className={`btn btn-xs btn-with-icon ${buttonClass ? buttonClass : 'btn-outline-secondary'}`}
      >
        <PersonIcon className="icon-xs" />
        <span>{user.name}</span>
      </Link>
    </OverlayTrigger>
  )
}

export default UserButton
