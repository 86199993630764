import {Id} from 'domain/Entity'
import {Errors} from 'domain/Errors'
import {ProjectUpdateType, ReportBlockUpdate, ReportSectionDelete, WebsocketChannel} from 'domain/Websockets'
import * as socketIOClient from 'socket.io-client'


const io = socketIOClient.io(location.origin, {withCredentials: true, transports: ['websocket']})
io.on('connect_error', console.error)

class Websocket {
  private callbacks = new Map<string, (...args: any[]) => void>()

  onReportUpdated(projectId: Id, cb: (data: ReportBlockUpdate | ReportSectionDelete) => void) {
    const channelKey = this.reportUpdatedChannelKey(projectId)
    this.callbacks.set(channelKey, cb)
    io.on(channelKey, cb)
  }

  onReportMigrated(projectId: Id, cb: () => void) {
    const channelKey = this.reportMigratedChannelKey(projectId)
    this.callbacks.set(channelKey, cb)
    io.on(channelKey, cb)
  }

  onProjectUpdated(projectId: Id, cb: ({type, errors}: { type: ProjectUpdateType, errors?: Errors<any> }) => void) {
    const channelKey = this.projectUpdatedChannelKey(projectId)
    this.callbacks.set(channelKey, cb)
    io.on(channelKey, cb)
  }

  onProjectWordCountUpdated(projectId: Id, cb: (data: { wordCount: number }) => void) {
    const channelKey = this.projectWordCountUpdatedChannelKey(projectId)
    this.callbacks.set(channelKey, cb)
    io.on(channelKey, cb)
  }

  onPendingMicroTranslationCountChanged(cb: () => void) {
    const channelKey = WebsocketChannel.PENDING_MICRO_TRANSLATION_COUNT_CHANGED
    this.callbacks.set(channelKey, cb)
    io.on(channelKey, cb)
  }

  onProjectNotificationCreated(userId: Id, cb: () => void) {
    const channelKey = this.projectNotificationCreatedChannelKey(userId)
    this.callbacks.set(channelKey, cb)
    io.on(channelKey, cb)
  }

  notifyBlockUpdated(data: ReportBlockUpdate) {
    io.emit(WebsocketChannel.REPORT_UPDATE, data)
  }

  notifySectionDeleted(data: ReportSectionDelete) {
    io.emit(WebsocketChannel.REPORT_UPDATE, data)
  }

  notifyReportMigrated(projectId: Id) {
    io.emit(WebsocketChannel.REPORT_MIGRATED, projectId)
  }

  stopListeningProjectUpdated(projectId: Id) {
    this.stopListeningChannel(this.projectUpdatedChannelKey(projectId))
  }

  stopListeningWordCountUpdated(projectId: Id) {
    this.stopListeningChannel(this.projectWordCountUpdatedChannelKey(projectId))
  }

  stopListeningReportUpdated(projectId: Id) {
    this.stopListeningChannel(this.reportUpdatedChannelKey(projectId))
  }

  stopListeningReportMigrated(projectId: Id) {
    this.stopListeningChannel(this.reportMigratedChannelKey(projectId))
  }

  stopListeningPendingMicroTranslationCountChanged() {
    this.stopListeningChannel(WebsocketChannel.PENDING_MICRO_TRANSLATION_COUNT_CHANGED)
  }

  stopListeningProjectNotificationCreated(userId: Id) {
    this.stopListeningChannel(this.projectNotificationCreatedChannelKey(userId))
  }

  private reportUpdatedChannelKey(projectId: Id) {
    return `${WebsocketChannel.REPORT_UPDATE}-${projectId}`
  }

  private reportMigratedChannelKey(projectId: Id) {
    return `${WebsocketChannel.REPORT_MIGRATED}-${projectId}`
  }

  private projectUpdatedChannelKey(projectId: Id) {
    return `${WebsocketChannel.PROJECT_UPDATED}-${projectId}`
  }

  private projectWordCountUpdatedChannelKey(projectId: Id) {
    return `${WebsocketChannel.PROJECT_WORD_COUNT_UPDATED}-${projectId}`
  }

  private projectNotificationCreatedChannelKey(userId: Id) {
    return `${WebsocketChannel.PROJECT_NOTIFICATION_CREATED}-${userId}`
  }

  private stopListeningChannel(channelKey: string) {
    io.off(channelKey, this.callbacks.get(channelKey))
    this.callbacks.delete(channelKey)
  }
}

const websocket = new Websocket()
export default websocket