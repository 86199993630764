import {TranslationRequest} from 'domain/TranslationRequest'
import {Translator} from 'domain/User'
import {ReactComponent as DoneIcon} from '../../assets/icons/done.svg'
import React, {ReactElement, useState} from 'react'
import {useTranslation} from 'react-i18next'
import useModal from '../../hooks/useModal'
import ModalTemplate, {ModalType} from '../utils/ModalTemplate'
import TranslationRequestMessageModal from './TranslationRequestMessageModal'
import TranslatorAssignmentCalendar from './translator-assignment-form/TranslatorAssignmentCalendar'
import TranslatorAssignmentProjectPane from './translator-assignment-form/TranslatorAssignmentProjectPane'
import {ProjectAggregate} from 'domain/ProjectAggregate'

interface TranslatorAssignmentFormProps {
  project: ProjectAggregate
  onAssigned: (request: TranslationRequest) => void
}

const TranslatorAssignmentForm: React.FC<TranslatorAssignmentFormProps> = ({project, onAssigned}): ReactElement => {
  const {t} = useTranslation()
  const [selectedTranslator, setSelectedTranslator] = useState<Translator | null>(null)
  const {showModal} = useModal()

  const onSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    if (!selectedTranslator) return

    showModal(
      <TranslationRequestMessageModal translator={selectedTranslator} project={project} onAssigned={onAssigned} />
    )
  }

  return (
    <ModalTemplate size={'xxl'} type={ModalType.SIDEBAR} title={t('components.TranslatorAssignmentForm.title')}>
      <form onSubmit={onSubmit}>
        <div className="modal-body p-0">
          <div className="panes panes-fill-height border-top">
            <TranslatorAssignmentProjectPane project={project} selectedTranslator={selectedTranslator} />
            <TranslatorAssignmentCalendar project={project} setSelectedTranslator={setSelectedTranslator} />
          </div>
        </div>
        <div className="modal-footer">
          <button type="submit" className="btn btn-success btn-with-icon" disabled={!selectedTranslator}>
            <DoneIcon className="me-2 icon" />
            <span>{t('components.TranslatorAssignmentForm.request')}</span>
          </button>
        </div>
      </form>
    </ModalTemplate>
  )
}

export default TranslatorAssignmentForm
