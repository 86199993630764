import {ReactComponent as CompareIcon} from '../../../assets/icons/compare_arrows.svg'
import React from 'react'
import {useTranslation} from 'react-i18next'


interface Props {
  loading: boolean
  active: boolean
  onClick: (e: React.MouseEvent) => void
}

const SectionDiffToggle: React.FC<Props> = props => {
  const {loading, active, onClick} = props
  const {t} = useTranslation()
  let buttonClasses = 'btn btn-sm btn-icon-only section-diff-button'
  buttonClasses += (active ? ' btn-primary' : ' btn-outline-secondary')
  if (loading) buttonClasses += ' disabled'

  return (<button className={buttonClasses} onClick={onClick} title={t('components.SectionDiffToggle.buttonTitle')}>
    {loading ? (
      <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"/>
    ) : <CompareIcon className="icon icon-sm"/>}
  </button>)
}

export default SectionDiffToggle