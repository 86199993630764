import {Id} from 'domain/Entity'
import {Job} from 'domain/Job'
import {Locale} from 'domain/Locale'
import {GroupedNews} from 'domain/Project'
import {ReportRevision, ReportState, SavedRevisionResponse} from 'domain/Report'
import {ReportType} from 'domain/ReportTemplate'
import apiClient from '../ApiClient'


const getReport = async (projectId: Id): Promise<ReportState> => {
  return await apiClient.get(`/api/projects/${projectId}/report`) as ReportState
}

const getGroupedNewsProjectsWithReports = async (projectId: Id, locale: Locale = Locale.JA): Promise<GroupedNews> => {
  return await apiClient.get(`/api/projects/${projectId}/report/news-updates?locale=${locale}`)
}

const getReportAtRevision = async (projectId: Id, revisionId: Id): Promise<ReportState> => {
  return await apiClient.get(`/api/projects/${projectId}/report/revisions/${revisionId}`) as ReportState
}

const updateReport = async (id: Id, projectId: Id, updateHash: string,
                            locale?: Locale, diff?: ReportRevision['diff']): Promise<SavedRevisionResponse> => {
  const url = `/api/projects/${projectId}/report/revisions/${id}`
  return await apiClient.put(url, {locale, diff, updateHash}) as SavedRevisionResponse
}

const updateRevisionName = async (id: Id, name: string) => {
  const url = `/api/reports/revisions/${id}/name`
  return await apiClient.patch(url, {name})
}

const getReportPDF = async (reportType: ReportType, projectId: Id, locale: Locale,
                            sectionId: string | null, password: string | null): Promise<Job> => {
  const queryParams: string[] = []
  queryParams.push(`type=${reportType}`)
  if (reportType === ReportType.SECTION_REPORT) {
    queryParams.push(`sectionId=${sectionId}`)
  }
  if (password) queryParams.push(`password=${password}`)

  const query = queryParams.length ? '?' + queryParams.join('&') : ''
  return await apiClient.get(`/api/projects/${projectId}/report/pdf/${locale.toLowerCase()}${query}`)
}

const uploadReportFile = async (projectId: Id, file: File, locale: Locale, addNewsUpdatesSections: boolean)
  : Promise<ReportState> => {
  return await apiClient.patchFile(`/api/projects/${projectId}/report/file`,
    {locale, addNewsUpdatesSections}, {file}) as ReportState
}

const getReportRevisions = async (projectId: Id): Promise<ReportRevision[]> => {
  return await apiClient.get(`/api/projects/${projectId}/report/revisions`) as ReportRevision[]
}

const deleteRevision = async (projectId: Id, revisionId: Id) => {
  return await apiClient.delete(`/api/projects/${projectId}/report/revisions/${revisionId}`)
}

const importTranslationsFromSource = async (projectId: Id) => {
  return await apiClient.patch(`/api/projects/${projectId}/report/import-translations`)
}

const copyJaToEn = async (projectId: Id): Promise<ReportState> => {
  return await apiClient.patch(`/api/projects/${projectId}/report/copy-ja-to-en`) as ReportState
}

const applyGlossary = async (projectId: Id): Promise<ReportState> => {
  return await apiClient.patch(`/api/projects/${projectId}/report/apply-glossary`) as ReportState
}

export {
  getReport,
  updateReport,
  updateRevisionName,
  getGroupedNewsProjectsWithReports,
  getReportPDF,
  uploadReportFile,
  getReportRevisions,
  getReportAtRevision,
  deleteRevision,
  importTranslationsFromSource,
  copyJaToEn,
  applyGlossary
}