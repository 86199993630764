import {Id, Paginated} from 'domain/Entity'
import {GlossaryTermsForTranslation, GlossaryTermWithAlternatives, GlossaryType, TermTranslation} from 'domain/Glossary'
import apiClient from '../ApiClient'


const fetchGlossary = async (words: string[],
                             companyId: Id,
                             glossaryType: GlossaryType): Promise<GlossaryTermsForTranslation[]> => {
  return await apiClient.patch(`/api/glossaries/translations?type=${glossaryType}`, {words, companyId})
}

const fetchGlossariesPaginated = async (
  type: GlossaryType, page: number, companyId?: Id, search?: string
): Promise<Paginated<GlossaryTermWithAlternatives>> => {
  const parameters = [`type=${type}`, `page=${page}`]
  if (companyId) {
    parameters.push(`companyId=${companyId}`)
  }
  if (search) {
    parameters.push(`search=${search}`)
  }
  return await apiClient.get(`/api/glossaries/paginated?${parameters.join('&')}`)
}

const addOrUpdateGlossaryTerm = async (
  type: GlossaryType, termTranslation: TermTranslation, companyId?: Id
): Promise<void> => {
  const url = `/api/glossaries?type=${type}${companyId ? `&companyId=${companyId}` : ''}`
  await apiClient.patch(url, termTranslation)
}

const deleteGlossaryTerm = async (id: Id) => {
  const response = await apiClient.delete(`/api/glossaries/${id}`)
  return new Date(response.updatedAt)
}

const canUpdateGlossary = async (companyId?: Id): Promise<boolean> => {
  const {canUpdateGlossary} = await apiClient.get(`/api/glossaries/can-update${companyId ? '/' + companyId : ''}`)
  return canUpdateGlossary
}

const uploadGlossaryFile = async (
  type: GlossaryType, companyId: Id | undefined, file: File, clearOnUploading?: boolean
): Promise<boolean> => {
  const url = `/api/glossaries/file?type=${type}&clearOnUploading=${clearOnUploading}${companyId ? `&companyId=${companyId}` : ''}`
  return await apiClient.patchFile(url, {}, {file})
}

export {
  fetchGlossary,
  fetchGlossariesPaginated,
  addOrUpdateGlossaryTerm,
  deleteGlossaryTerm,
  canUpdateGlossary,
  uploadGlossaryFile
}