import {Locale} from 'domain/Locale'


const PROJECTS_DASHBOARD_COLUMNS_KEY = 'projectColumns'
const MICRO_TRANSLATION_DASHBOARD_COLUMNS_KEY = 'microTranslationColumns'
const TRANSLATIONS_DASHBOARD_COLUMNS_KEY = 'translationsDashboardColumns'
const OVERDUE_MODAL_LAST_DISPLAYED_AT_KEY = 'overdueModalLastDisplayedAt'

class LocalStorage {
  static setProjectsDashboardColumns(columns: string[]) {
    localStorage.setItem(PROJECTS_DASHBOARD_COLUMNS_KEY, JSON.stringify(columns))
  }

  static getProjectsDashboardColumns() {
    const columns = localStorage.getItem(PROJECTS_DASHBOARD_COLUMNS_KEY)
    return columns ? JSON.parse(columns) : null
  }

  static setMicroTranslationsDashboardColumns(columns: string[]) {
    localStorage.setItem(MICRO_TRANSLATION_DASHBOARD_COLUMNS_KEY, JSON.stringify(columns))
  }

  static getMicroTranslationsDashboardColumns() {
    const columns = localStorage.getItem(MICRO_TRANSLATION_DASHBOARD_COLUMNS_KEY)
    return columns ? JSON.parse(columns) : null
  }

  static setTranslationDashboardColumns(columns: string[]) {
    localStorage.setItem(TRANSLATIONS_DASHBOARD_COLUMNS_KEY, JSON.stringify(columns))
  }

  static getTranslationDashboardColumns() {
    const columns = localStorage.getItem(TRANSLATIONS_DASHBOARD_COLUMNS_KEY)
    return columns ? JSON.parse(columns) : null
  }

  static setLocale(locale: Locale) {
    localStorage.setItem('locale', locale)
  }

  static getLocale(): Locale | null {
    const locale = localStorage.getItem('locale')
    return locale ? locale as Locale : null
  }

  static setRedirectTo(redirectTo: string) {
    localStorage.setItem('redirectTo', redirectTo)
  }

  static getRedirectTo() {
    return localStorage.getItem('redirectTo')
  }

  static removeRedirectTo() {
    return localStorage.removeItem('redirectTo')
  }

  static setCopyTextHash(hash: string) {
    localStorage.setItem('copyTextHash', hash)
  }

  static getCopyTextHash() {
    return localStorage.getItem('copyTextHash')
  }

  static getOverdueModalLastDisplayedAt(): Date | null {
    const value = localStorage.getItem(OVERDUE_MODAL_LAST_DISPLAYED_AT_KEY)
    return value ? new Date(value) : null
  }

  static setOverdueModalLastDisplayedAt(date: Date | null) {
    localStorage.setItem(OVERDUE_MODAL_LAST_DISPLAYED_AT_KEY, date ? date.toISOString() : '')
  }
}

export default LocalStorage