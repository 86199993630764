import {ProjectLog, ProjectLogType} from 'domain/ProjectLog'
import React from 'react'
import AssignmentLogLabel from '../components/project/logs/labels/AssignmentLogLabel'
import DefaultLogLabel from '../components/project/logs/labels/DefaultLogLabel'
import ReportEditLogLabel from '../components/project/logs/labels/ReportEditLogLabel'
import ReportGenerationLogLabel from '../components/project/logs/labels/ReportRegenerationLogLabel'
import RescheduleLogLabel from '../components/project/logs/labels/RescheduleLogLabel'
import StageLogLabel from '../components/project/logs/labels/StageLogLabel'


export const logLabel = (log: ProjectLog): React.FC<any> => {
  const logTypeComponentMap = {
    [ProjectLogType.STAGE_REVERT]: StageLogLabel,
    [ProjectLogType.STAGE_COMPLETE]: StageLogLabel,
    [ProjectLogType.REPORT_GENERATED]: ReportGenerationLogLabel,
    [ProjectLogType.REPORT_FILE_MIGRATION]: ReportEditLogLabel,
    [ProjectLogType.REPORT_EDIT]: ReportEditLogLabel,
    [ProjectLogType.STAGE_RESCHEDULE]: RescheduleLogLabel,
    [ProjectLogType.TASK_ASSIGNED]: AssignmentLogLabel,
    [ProjectLogType.ANALYSTS_ASSIGNED]: AssignmentLogLabel
  }

  return logTypeComponentMap[log.type] || DefaultLogLabel
}