import {EditorTableRowTemplate} from 'domain/Editor'
import {CellType, MainTableStyle} from 'domain/Report'
import TableCellStyleConverter from './TableCellStyleConverter'
import TableParser from './TableParser'

const plusCharacters = '\u002B\u1429\u02D6\u207A\u208A\u2795\uFE62\uFF0B'
const minusCharacters = '\\-\u02D7\u207B\u208B\u2212\u2796\uFE63\uFF0D'
const percentCharacters = '\u0025\u066A\uFE6A\uFF05'

export const defaultTableStyle = (content: EditorTableRowTemplate[]): MainTableStyle => {
  const maxColumns: number = content.max(row => row.cells.length) || 0

  switch (true) {
    case maxColumns < 4:
      return MainTableStyle.NORMAL
    case maxColumns < 10:
      return MainTableStyle.COMPACT
    default:
      return MainTableStyle.EXTRA_COMPACT
  }
}

export const getTableCellType = (input: string): CellType => {
  const trimmedInput = input.replace(/&nbsp;/g, '').trim()
  if (minusCharacters.includes(trimmedInput)) {
    return CellType.NUMBER
  }
  const regexp = new RegExp(`^[${plusCharacters}${minusCharacters}]?\\s*(\\d+[\\s,.]?)+\\s*([${percentCharacters}])?$`)
  const isNumber = trimmedInput.match(regexp) !== null
  return isNumber ? CellType.NUMBER : CellType.TEXT
}

export const getTableTemplateFromHTML = (html: string): EditorTableRowTemplate[] => {
  const tableParser = new TableParser(html)
  const tableColorConverter = new TableCellStyleConverter(tableParser)

  return tableParser.withParsedTable((table: HTMLTableElement | null | undefined) => {
    if (!table) {
      return []
    }

    const tableWidth = parseInt(table.getAttribute('width') || '', 10)

    return Array.from(table.rows).map((row: HTMLTableRowElement) => {
      const cells = Array.from(row.cells).map((cell: HTMLTableDataCellElement | HTMLTableHeaderCellElement) => {
        const cellHTML: string = cell.innerHTML
        const cellStyles = tableParser.styles(cell) || {}
        const normalizedHtml = normalize(cellHTML)
        const percentWidth = ((parseInt(cell.getAttribute('width') || '', 10) * 100) / tableWidth).toFixed(2) + '%'

        return {
          backgroundStyle: tableColorConverter.cellBackgroundStyle(cell),
          fontColorStyle: tableColorConverter.cellFontStyle(cell),
          textAlign: cellStyles.textAlign,
          fontWeight: cellStyles.fontWeight,
          fontStyle: cellStyles.fontStyle,
          textDecoration: cellStyles.textDecoration,
          textDecorationStyle: cellStyles.textDecorationStyle,
          verticalAlign: cellStyles.verticalAlign,
          hasBorderLeft: hasBorder(cellStyles.borderLeftWidth),
          hasBorderRight: hasBorder(cellStyles.borderRightWidth),
          colSpan: cell.colSpan,
          rowSpan: cell.rowSpan,
          type: getTableCellType(normalizedHtml),
          html: normalizedHtml,
          width: percentWidth
        }
      })

      return {
        cells
      }
    })
  })
}

const hasBorder = (border: string): boolean => {
  const NO_BORDER = '0px'
  return !!border && border !== NO_BORDER
}

export const normalize = (input: string): string => {
  return input
    .split('<br>')
    .map(substring => {
      return substring.removeHTMLTags().replaceHtmlSpaces().trim()
    })
    .join('<br>')
}
