import classNames from 'classnames'
import {hasCommentWriteAccess} from 'domain/access'
import {CommentStatus, CommentWithUserName} from 'domain/Comment'
import {Permission} from 'domain/Permission'
import {hasRoles, isOnlyCompanyRepresentative, Role} from 'domain/User'
import {ReactComponent as PinIcon} from '../../../assets/icons/push_pin.svg'
import {ReactComponent as VisibilityIcon} from '../../../assets/icons/visibility.svg'
import {ReactComponent as VisibilityOffIcon} from '../../../assets/icons/visibility_off.svg'
import React, {useContext, useEffect, useMemo, useRef, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {
  activateComment,
  deleteComment,
  resolveComment,
  stickComment,
  unstickComment,
  updateComment,
  updateCommentVisibility
} from '../../../api-clients/commentClient'
import {CommentContext} from '../../../providers/CommentProvider'
import {LocaleContext} from '../../../providers/LocaleProvider'
import {ReportContext} from '../../../providers/ReportProvider'
import {UserContext} from '../../../providers/UserProvider'
import {relativeDateTime} from '../../../utils/momentUtils'
import Protected from '../../utils/Protected'
import {
  highlightAsStickyComment,
  highlightAsUnstickyComment,
  highlightCommentById,
  removeCommentHighlightingById,
  unhighlightCommentById
} from './commentUtils'
import Content from './Content'
import EditComment from './EditComment'
import NewReply from './NewReply'
import Reply from './Reply'

interface CommentProps {
  comment: CommentWithUserName
  commentedElements: HTMLElement[]
  onRecalculateHighlightedElements?: () => void
  initiallyActive?: boolean
}

const Comment: React.FC<CommentProps> = props => {
  const {comment, commentedElements, onRecalculateHighlightedElements, initiallyActive = false} = props
  const [activated, setActivated] = useState(initiallyActive)
  const [editing, setEditing] = useState(false)
  const [canModifyReport, setCanModifyReport] = useState(true)
  const [isCommentedTextOpened, setIsCommentedTextOpened] = useState(false)
  const {setComments} = useContext(CommentContext)
  const {user, hasPermission} = useContext(UserContext)
  const {project} = useContext(ReportContext)
  const {locale} = useContext(LocaleContext)
  const {t} = useTranslation()
  const commentRef = useRef<HTMLDivElement>(null)
  const isContentDeleted = !commentedElements.length
  const canManage = useMemo(() => hasCommentWriteAccess(user, project!), [])
  const canEdit = canManage && user._id === comment.userId
  const canDelete = canEdit && (canModifyReport || isContentDeleted) && comment.replies.length === 0
  const canStick =
    !comment.sticky && canModifyReport && user._id === comment.userId && hasPermission(Permission.Comment.STICK)
  const canUnstick =
    !!comment.sticky && canModifyReport && user._id === comment.userId && hasPermission(Permission.Comment.STICK)
  const canManageCommentVisibility =
    (user._id === comment.userId || hasRoles(user, Role.ADMIN)) && hasPermission(Permission.Comment.MANAGE_VISIBILITY)
  const canSeeCommentVisibility = hasPermission(Permission.Comment.MANAGE_VISIBILITY)
  const canResolve =
    canManage &&
    (canModifyReport || isContentDeleted) &&
    !comment.sticky &&
    CommentStatus.ACTIVE === comment.status &&
    (!isOnlyCompanyRepresentative(user.roles) || comment.userId === user._id)
  const canUnResolve =
    canManage &&
    canModifyReport &&
    CommentStatus.RESOLVED === comment.status &&
    (!isOnlyCompanyRepresentative(user.roles) || comment.userId === user._id)
  const canReply = canManage

  useEffect(() => {
    if (onRecalculateHighlightedElements) {
      onRecalculateHighlightedElements()
    }
    setActivated(initiallyActive)
    document.addEventListener('click', onClickListener)

    return () => {
      document.removeEventListener('click', onClickListener)
    }
  }, [comment])

  useEffect(() => {
    const isBlockComment = !!comment.blockId
    const canEditHighlightedBlocks = commentedElements.some(element => element.closest('[contenteditable]'))
    setCanModifyReport(isBlockComment || canEditHighlightedBlocks)

    return () => {
      commentedElements.forEach(span => delete span.dataset.highlighted)
    }
  }, [commentedElements])

  useEffect(() => {
    if (activated) {
      commentedElements.forEach(span => (span.dataset.highlighted = 'true'))
    } else {
      commentedElements.forEach(span => delete span.dataset.highlighted)
    }
  }, [activated, commentedElements])

  const onClickListener = (event: MouseEvent) => {
    const commentDiv = commentRef.current!
    if (commentDiv) {
      const target = event.target as Node
      const clickedInside = commentDiv.contains(target) || commentedElements.some(span => span.contains(target))
      setActivated(clickedInside)
    }
  }

  const closeNewReply = (event: React.MouseEvent) => {
    event.stopPropagation()
    setActivated(false)
  }

  const onEditComment = (content: string) => {
    updateComment(comment._id, content, project!._id).then(updatedComment => {
      setEditing(false)
      updateComments(updatedComment)
    })
  }

  const onStick = (event: React.MouseEvent) => {
    event.preventDefault()
    event.stopPropagation()

    stickComment(comment._id).then(updatedComment => {
      updateComments(updatedComment)
      highlightAsStickyComment(commentedElements)
      if (onRecalculateHighlightedElements) onRecalculateHighlightedElements()
    })
  }

  const onUnstick = (event: React.MouseEvent) => {
    event.preventDefault()
    event.stopPropagation()

    unstickComment(comment._id).then(updatedComment => {
      updateComments(updatedComment)
      highlightAsUnstickyComment(commentedElements)
      if (onRecalculateHighlightedElements) onRecalculateHighlightedElements()
    })
  }

  const setCommentResolved = (event: React.MouseEvent) => {
    event.stopPropagation()
    resolveComment(comment._id, project!._id).then(updatedComment => {
      updateComments(updatedComment)
      unhighlightCommentById(comment._id)
      if (onRecalculateHighlightedElements) onRecalculateHighlightedElements()
    })
  }

  const setCommentUnResolved = (event: React.MouseEvent) => {
    event.stopPropagation()
    activateComment(comment._id, project!._id).then(updatedComment => {
      updateComments(updatedComment)
      highlightCommentById(comment._id)
      if (onRecalculateHighlightedElements) onRecalculateHighlightedElements()
    })
  }

  const onUpdateVisibility = (event: React.MouseEvent) => {
    event.preventDefault()
    event.stopPropagation()

    updateCommentVisibility(comment._id, !comment.isVisibleForCompany).then(updatedComment => {
      updateComments(updatedComment)
    })
  }

  const updateComments = (updatedComment: CommentWithUserName) => {
    setComments(comments =>
      (comments || []).map(comment => (comment._id === updatedComment._id ? updatedComment : comment))
    )
  }

  const enableEditing = (event: React.MouseEvent) => {
    event.preventDefault()
    event.stopPropagation()
    setEditing(true)
  }

  const disableEditing = (event: React.MouseEvent) => {
    event.stopPropagation()
    setEditing(false)
  }

  const onDelete = async (event: React.MouseEvent) => {
    event.preventDefault()
    event.stopPropagation()

    await deleteComment(comment._id, project!._id)
    setComments(comments => comments.filter(currentComment => currentComment._id !== comment._id))
    removeCommentHighlightingById(comment._id)
  }

  const toggleCommentedText = (event: React.MouseEvent) => {
    event.preventDefault()
    setIsCommentedTextOpened(isCommentedTextOpened => !isCommentedTextOpened)
  }

  const classnames = {
    'card elevate comment': true,
    activated,
    'comment-sticky': comment.sticky,
    'comment-company-visible': canSeeCommentVisibility && comment.isVisibleForCompany
  }

  return (
    <div className={classNames(classnames)} ref={commentRef}>
      <div className="card-body px-3 py-2">
        <div className="d-flex justify-content-between align-items-start mx-n1">
          <div className="mb-1">
            {isContentDeleted && <div className="xxsmall text-danger fw-bold">{t('components.Comment.deleted')}</div>}
            <div className="xsmall text-muted" data-username="">
              {comment.username}
            </div>
            <div className="xxsmall text-muted-more">{relativeDateTime(comment.createdAt, locale)}</div>
          </div>
          <div className="ps-1 d-flex flex-nowrap mt-1">
            {canResolve && (
              <button className="btn btn-outline-success btn-xs ms-1 text-nowrap" onClick={setCommentResolved}>
                {t('components.Comment.resolve')}
              </button>
            )}
            {canUnResolve && (
              <button className="btn btn-outline-danger btn-xs ms-1 text-nowrap" onClick={setCommentUnResolved}>
                {t('components.Comment.unresolve')}
              </button>
            )}

            {canStick && (
              <button
                className="btn btn-outline-purple btn-xs btn-with-icon ms-1"
                onClick={onStick}
                title={t('components.Comment.stickyTooltip.unstick')}
              >
                <PinIcon className="icon icon-xs m-0" />
              </button>
            )}

            {canUnstick && (
              <button
                className="btn btn-purple btn-xs btn-with-icon ms-1"
                onClick={onUnstick}
                title={t('components.Comment.stickyTooltip.stick')}
              >
                <PinIcon className="icon icon-xs m-0" />
              </button>
            )}
            {canManageCommentVisibility &&
              (comment.isVisibleForCompany ? (
                <button
                  className="btn btn-info btn-xs btn-with-icon ms-1"
                  onClick={onUpdateVisibility}
                  title={t('components.Comment.visibilityTooltip.on')}
                >
                  <VisibilityIcon className="icon icon-xs m-0" />
                </button>
              ) : (
                <button
                  className="btn btn-outline-info btn-xs btn-with-icon ms-1"
                  onClick={onUpdateVisibility}
                  title={t('components.Comment.visibilityTooltip.off')}
                >
                  <VisibilityOffIcon className="icon icon-xs m-0" />
                </button>
              ))}
          </div>
        </div>

        <div className="mx-n1">
          {editing ? (
            <EditComment originalContent={comment.content} onSubmit={onEditComment} onCancel={disableEditing} />
          ) : (
            <>
              <Content content={comment.content} />

              {canEdit && (
                <a href="#" className="small" onClick={enableEditing}>
                  <span>{t('components.Comment.edit')}</span>
                </a>
              )}

              {canDelete && (
                <>
                  <span className="text-muted-more">&nbsp;•&nbsp;</span>
                  <a href="#" className="small" type="button" onClick={onDelete}>
                    <span>{t('components.Comment.delete')}</span>
                  </a>
                </>
              )}
            </>
          )}

          {comment.commentedText && (
            <>
              {(canEdit || canDelete) && <span className="text-muted-more">&nbsp;•&nbsp;</span>}
              <a href="#" className="small" onClick={toggleCommentedText}>
                {isCommentedTextOpened
                  ? t('components.Comment.hideCommentedText')
                  : t('components.Comment.showCommentedText')}
              </a>
            </>
          )}
        </div>

        {isCommentedTextOpened && (
          <div className="border-start border-2 mt-2 mx-n1">
            <div className="ps-2 text-pre-wrap xsmall">{comment.commentedText}</div>
          </div>
        )}
      </div>

      {comment.replies.map(reply => (
        <div className="card-body px-3 py-2" key={reply._id as string}>
          <Reply reply={reply} key={reply._id as string} commentId={comment._id} />
        </div>
      ))}

      {activated && canReply && (
        <Protected permission={Permission.Comment.MANAGE}>
          <div className="card-body px-3 py-2">
            <NewReply commentId={comment._id} onClose={closeNewReply} />
          </div>
        </Protected>
      )}
    </div>
  )
}

export default Comment
