import {ReactComponent as CloseIcon} from '../../../assets/icons/close.svg'
import {ReactComponent as DoneIcon} from '../../../assets/icons/done.svg'
import React from 'react'
import {useTranslation} from 'react-i18next'
import useModal from '../../../hooks/useModal'
import ModalTemplate from '../../utils/ModalTemplate'


interface DeleteSectionModalProps {
  onDelete: () => void
}

const DeleteSectionModal: React.FC<DeleteSectionModalProps> = ({onDelete}) => {
  const {closeModal} = useModal()
  const {t} = useTranslation()

  const onConfirm = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    onDelete()
    closeModal()
  }

  return (
    <ModalTemplate>
      <div className="modal-body">
        {t('components.Report.edit.DeleteSectionModal.body')}
      </div>
      <div className="modal-footer justify-content-around">
        <button type="button" className="btn btn-danger btn-with-icon" onClick={closeModal}>
          <span>{t('components.Report.edit.DeleteSectionModal.cancel')}</span>
          <CloseIcon className="icon" />
        </button>
        <button type="submit" className="btn btn-success btn-with-icon"
                onClick={event => onConfirm(event)}>
          <span>{t('components.Report.edit.DeleteSectionModal.confirm')}</span>
          <DoneIcon className="icon" />
        </button>
      </div>
    </ModalTemplate>
  )
}

export default DeleteSectionModal

