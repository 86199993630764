import moment from 'moment'


const isUpdatedWithinTimeWindow = (date: string | Date, timeWindow: number = 10 * 60 * 1000) => {
  return new Date(date).getTime() > (new Date().getTime() - timeWindow)
}

const datesForWeek = (date: Date) => {
  const startOfWeek = moment.utc(date).startOf('isoWeek')
  return [0, 1, 2, 3, 4, 5, 6].map(day => startOfWeek.clone().add(day, 'days').toDate())
}

const weekNumber = (date: Date) => {
  return moment(date).isoWeek()
}

export {
  isUpdatedWithinTimeWindow, weekNumber, datesForWeek
}

export const subtractDays = (date: Date, days: number): Date => {
  const newDate = new Date(date)
  newDate.setDate(newDate.getDate() - days)
  return newDate
}