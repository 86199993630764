import {CommentWithUserName} from 'domain/Comment'
import React, {useEffect, useMemo, useState} from 'react'
import EventBus, {EventType} from '../../../EventBus'
import Comment from './Comment'
import {getCommentedElements, getElementTopPosition} from './commentUtils'

interface TextAlignedCommentProps {
  comment: CommentWithUserName
  coordinates?: {x: number; y: number}
  classNames?: string
  initiallyActive?: boolean
}

const TextAlignedComment = React.forwardRef<HTMLDivElement, TextAlignedCommentProps>((props, ref) => {
  const {comment, coordinates, classNames, initiallyActive} = props
  const [commentedElements, setCommentedElements] = useState<HTMLElement[]>([])
  const firstCommentedElement = useMemo(() => commentedElements[0], [commentedElements])

  const commentedElementY = useMemo(() => {
    if (coordinates) return
    return firstCommentedElement ? getElementTopPosition(firstCommentedElement) : null
  }, [firstCommentedElement])

  useEffect(() => {
    EventBus.on(EventType.EDITOR_READY, calculateCommentedElement)
    EventBus.on(EventType.TRANSLATION_PAGE_READY, calculateCommentedElement)
    EventBus.on(EventType.REPORT_CONTENT_PASTED, recalculateCommentedElement)

    return () => {
      EventBus.unsubscribe(EventType.EDITOR_READY, calculateCommentedElement)
      EventBus.unsubscribe(EventType.TRANSLATION_PAGE_READY, calculateCommentedElement)
      EventBus.on(EventType.REPORT_CONTENT_PASTED, recalculateCommentedElement)
    }
  }, [comment])

  useEffect(() => {
    setTimeout(recalculateCommentedElement)
  }, [comment])

  const calculateCommentedElement = () => {
    if (firstCommentedElement && firstCommentedElement.parentElement) return
    recalculateCommentedElement()
  }

  const recalculateCommentedElement = () => {
    setCommentedElements(getCommentedElements(comment))
  }

  if (!(commentedElementY || coordinates)) return <></>

  const style = {
    top: (coordinates?.y || commentedElementY) + 'px',
    left: (coordinates?.x || 0) + 'px'
  }

  return (
    <div className={`comment-container ${classNames ? classNames : ''}`} style={style}
         data-comment-id={comment._id} data-commented-block-id={comment.blockId} ref={ref}>
      <Comment comment={comment} commentedElements={commentedElements} initiallyActive={initiallyActive}
               onRecalculateHighlightedElements={recalculateCommentedElement}/>
    </div>
  )
})

export default TextAlignedComment