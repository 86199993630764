import {useEffect, useState} from 'react'
import {findNearestElementIndex} from '../utils/findNearestElementIndex'
import scrollToElement from '../utils/scrollToElement'


const useTagNavigator = () => {
  const [index, setIndex] = useState(-1)
  const [tags, setTags] = useState<HTMLElement[]>([])
  const [clientY, setClientY] = useState<number>(0)

  const recalculatePosition = (clientY: number) => {
    setClientY(clientY)
  }

  const hasTags = () => tags.length > 0

  const canNavigateBack = () => index > 0

  const canNavigateForward = () => index < tags.length - 1

  const elementsTopWithIndex = () => {
    return tags.map((element, index) => {
      return {index, top: element.getBoundingClientRect().top + window.pageYOffset}
    })
  }

  const navigateForward = () => {
    if (!canNavigateForward()) return
    const newIndex = index + 1
    setIndex(newIndex)
    scrollToElement(tags[newIndex])
  }

  const navigateBack = () => {
    if (!canNavigateBack()) return
    const newIndex = index - 1
    setIndex(newIndex)
    scrollToElement(tags[newIndex])
  }

  const navigateToCurrentPosition = () => {
    if (!tags.length) return
    setIndex(findNearestElementIndex(elementsTopWithIndex(), clientY))
  }

  useEffect(() => {
    navigateToCurrentPosition()
  }, [clientY])

  const updateTags = (tags: HTMLElement[]) => {
    setTags(tags)
  }

  const resetIndex = () => {
    setIndex(-1)
  }

  return {
    hasTags,
    canNavigateBack,
    canNavigateForward,
    navigateBack,
    navigateForward,
    updateTags,
    resetIndex,
    recalculatePosition
  }
}

export default useTagNavigator