import {Entity, Id} from 'domain/Entity'
import {Locale} from 'domain/Locale'
import {Project, ProjectStage} from 'domain/Project'
import {User} from 'domain/User'


export enum ProjectLogType {
  REPORT_EDIT = 'REPORT_EDIT',
  REPORT_FILE_MIGRATION = 'REPORT_FILE_MIGRATION',
  CHANGE_SETTINGS = 'CHANGE_SETTINGS',
  STAGE_COMPLETE = 'STAGE_COMPLETE',
  STAGE_REVERT = 'STAGE_REVERT',
  STAGE_RESCHEDULE = 'STAGE_RESCHEDULE',
  ASSIGN_TRANSLATION = 'ASSIGN_TRANSLATION',
  SEND_TRANSLATION_REQUEST = 'SEND_TRANSLATION_REQUEST',
  ACCEPT_TRANSLATION_REQUEST = 'ACCEPT_TRANSLATION_REQUEST',
  REJECT_TRANSLATION_REQUEST = 'REJECT_TRANSLATION_REQUEST',
  REJECT_ACCEPTED_TRANSLATION_REQUEST = 'REJECT_ACCEPTED_TRANSLATION_REQUEST',
  CANCEL_TRANSLATION_REQUEST = 'CANCEL_TRANSLATION_REQUEST',
  DELETE_LAST_REVISION = 'DELETE_LAST_REVISION',
  IMPORT_TRANSLATION_FROM_SOURCE = 'IMPORT_TRANSLATION_FROM_SOURCE',
  COPY_JA_TO_EN = 'COPY_JA_TO_EN',
  APPLY_GLOSSARY = 'APPLY_GLOSSARY',
  PROJECT_DELETE = 'PROJECT_DELETE',
  REPORT_MANUAL_GENERATION = 'REPORT_MANUAL_GENERATION',
  REPORT_PUBLISHED = 'REPORT_PUBLISHED',
  REPORT_UNPUBLISHED = 'REPORT_UNPUBLISHED',
  REPORT_GENERATED = 'REPORT_GENERATED',
  TASK_ASSIGNED = 'TASK_ASSIGNED',
  ANALYSTS_ASSIGNED = 'ANALYSTS_ASSIGNED',
  SCHEDULE_ADJUSTED = 'SCHEDULE_ADJUSTED'
}

export interface DefaultProjectLog extends Entity {
  projectId: Id
  userId?: Id
  type: ProjectLogType
  params?: any
}

export interface EditReportProjectLog extends DefaultProjectLog {
  type: ProjectLogType.REPORT_EDIT
  params: {
    locale?: Locale
  }
}

export interface DeleteProjectLog extends DefaultProjectLog {
  type: ProjectLogType.PROJECT_DELETE
  params: {
    title: string
  }
}

export interface ReportFileMigrationProjectLog extends DefaultProjectLog {
  type: ProjectLogType.REPORT_FILE_MIGRATION
  params: {
    locale: Locale
  }
}

export interface StageProjectLog extends DefaultProjectLog {
  type: ProjectLogType.STAGE_COMPLETE | ProjectLogType.STAGE_REVERT
  params: {
    stage: ProjectStage
  }
}

export interface SendTranslationRequestLog extends DefaultProjectLog {
  type: ProjectLogType.SEND_TRANSLATION_REQUEST
  params: {
    translatorId: Id
    translatorName: string
  }
}

export type ProjectLog =
  DefaultProjectLog
  | EditReportProjectLog
  | StageProjectLog
  | ReportFileMigrationProjectLog
  | DeleteProjectLog
  | SendTranslationRequestLog

export type AggregatedProjectLog = ProjectLog & {
  user?: User
  projectTitle: Project['title']
  companyTick: string
}