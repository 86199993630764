import {Locale} from 'domain/Locale'
import {GroupedNews} from 'domain/Project'
import {Block, ReportState, Section} from 'domain/Report'
import React, {useEffect, useState} from 'react'
import SectionContent from './SectionContent'


interface ReportContentProps {
  report: ReportState
  locale: Locale
  lazy: boolean
  groupedNews?: GroupedNews
}

const ReportContent: React.FC<ReportContentProps> = props => {
  const {report, locale, lazy = true, groupedNews} = props
  const [blocksById, setBlocksById] = useState<Record<string, Block>>(calculateBlocksById(report.sections))

  useEffect(() => {
    setBlocksById(calculateBlocksById(report.sections))
  }, [report])

  function calculateBlocksById(sections: Section[]) {
    return sections.flatMap(section => section.blocks).groupByFirst(block => block.id)
  }

  return (<div className="sections">
      {report.sections.map((section: Section) => {
        return <SectionContent key={section._id} section={section} locale={locale} lazy={lazy}
                               groupedNews={groupedNews} displayIfNoNews={false} blocksById={blocksById}/>
      })}
    </div>
  )
}

export default ReportContent