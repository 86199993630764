import {Entity, Paginated} from 'domain/Entity'
import {useEffect, useState} from 'react'
import debounce from '../utils/debounce'


const defaultPagination = {collection: [], page: -1, perPage: 0, hasNext: true}

type UsePaginatedViewParams<T extends Entity> = {
  fetchCollection: (page: number) => Promise<Paginated<T>>
}

export type Pagination<T> = {
  loading: boolean
  pagination: Paginated<T>
  reload: () => void
  onUpdate: (item: Partial<T>) => void
  loadNextPage: () => void
}

export const usePaginatedCollection = <T extends Entity>({fetchCollection}:
                                                           UsePaginatedViewParams<T>): Pagination<T> => {
  const [pagination, setPagination] = useState<Paginated<T>>(defaultPagination)
  const [page, setPage] = useState(1)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    loadCollection()
  }, [page])

  const loadCollection = debounce((reload = false) => {
    setLoading(true)
    fetchCollection(page).then(pagination => {
      setPagination(p => {
        const newCollection = reload || page === 1 ? pagination.collection : [...p.collection, ...pagination.collection]
        return ({
          ...pagination,
          collection: newCollection
        })
      })
    }).finally(() => {
      setLoading(false)
    })
  }, 50)

  const reload = () => {
    if (page === 1) loadCollection(true)
    else setPage(1)
  }

  const loadNextPage = () => {
    if (pagination.hasNext) setPage(page => page + 1)
  }

  const onUpdate = (updatedItem: Partial<T>) => {
    setPagination(p => ({
      ...p,
      collection: p.collection.map(item => item._id === updatedItem._id ? {...item, ...updatedItem} : item)
    }))
  }

  return {
    loading,
    pagination,
    reload,
    onUpdate,
    loadNextPage
  }
}