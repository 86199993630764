import {AggregatedProjectLog} from 'domain/ProjectLog'
import React from 'react'
import {useTranslation} from 'react-i18next'
import RawHtml from '../../../utils/RawHtml'

interface Props {
  log: AggregatedProjectLog
}

const AssignmentLogLabel: React.FC<Props> = ({log}) => {
  const {t} = useTranslation()
  const username = log.user ? log.user.name : log.userId
  const stageTitle = t(`models.ProjectStage.${log.params.stage}.title`)

  const assignmentText = () => {
    if (!log.params.assignedUsers || log.params.assignedUsers.length === 0) return null

    return t(`components.ProjectLogs.${log.type}.assigned`, {
      username,
      stage: stageTitle,
      users: log.params.assignedUsers.map((user: any) => user.name).join(', ')
    })
  }

  const unassignmentText = () => {
    if (!log.params.unassignedUsers || log.params.unassignedUsers.length === 0) return null

    return t(`components.ProjectLogs.${log.type}.unassigned`, {
      username,
      stage: stageTitle,
      users: log.params.unassignedUsers.map((user: any) => user.name).join(', ')
    })
  }

  const texts = [unassignmentText(), assignmentText()].filter(text => !!text) as string[]

  return (
    <>
      {texts.map((text, index) => (
        <div key={text + index}>
          <RawHtml>{text}</RawHtml>
        </div>
      ))}
    </>
  )
}

export default AssignmentLogLabel
