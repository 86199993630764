import {hasTaskAccess} from 'domain/access'
import {AggregatedTask, ProjectTask, TaskStatus} from 'domain/Task'
import React, {useContext} from 'react'
import useModal from '../../../hooks/useModal'
import {UserContext} from '../../../providers/UserProvider'
import ProjectTaskRescheduleModal from './ProjectTaskRescheduleModal'


type Props = {
  task: ProjectTask
  project: AggregatedTask['project']
  onRescheduled: () => void
  children: React.ReactNode
  classNames?: string
}

const TaskRescheduleButton: React.FC<Props> = ({task, project, classNames, onRescheduled, children}) => {
  const {user} = useContext(UserContext)
  const {showModal} = useModal()

  const openRescheduleModal = (e: React.MouseEvent) => {
    e.preventDefault()
    showModal(<ProjectTaskRescheduleModal task={task} onRescheduled={onRescheduled}/>)
  }

  return (hasTaskAccess(user, project, task) && task.status !== TaskStatus.FINISHED) ?
    <button onClick={openRescheduleModal} className={classNames}>
      {children}
    </button> :
    <></>
}

export default TaskRescheduleButton