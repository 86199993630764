import React from 'react'
import useToast from '../../hooks/useToast'
import Toast from './Toast'


const Toasts = () => {
  const {toastContents} = useToast()

  return (
    <div className="toast-container">
      {toastContents.map(toast => {
        return <Toast toast={toast} key={toast.id}/>
      })}
    </div>
  )
}

export default Toasts