import {Errors} from 'domain/Errors'
import {Locale} from 'domain/Locale'
import {Project, ProjectType} from 'domain/Project'
import {User} from 'domain/User'
import React, {FC, PropsWithChildren, useContext, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {range} from '../../../../api/src/utils/range'
import {getAnalysts} from '../../api-clients/userClient'
import {UserContext} from '../../providers/UserProvider'
import {formatDate, formattedTimezone} from '../../utils/momentUtils'
import Input from '../utils/form/Input'
import MultipleSelect from '../utils/form/MultipleSelect'
import Select from '../utils/form/Select'
import Textarea from '../utils/form/Textarea'

interface ProjectFormProps {
  project: Partial<Project>
  errors: Errors<Project>
  onChange: (field: string, value: any) => void
  onFormReady?: () => void
  startDate?: Date
  setStartDate?: (newDate: Date) => void
}

const ProjectForm: FC<PropsWithChildren<ProjectFormProps>> = props => {
  const {project, errors, onChange, onFormReady, startDate, setStartDate, children} = props
  const {t} = useTranslation()
  const {user} = useContext(UserContext)
  const [analysts, setAnalysts] = useState<User[]>([])
  const [loading, setLoading] = useState(true)

  const defaultStartTime = () => [ProjectType.FLASH, ProjectType.NEWS_UPDATE].includes(project.type!) ?
    '15:00' :
    '09:00'

  const [newStartDate, setNewStartDate] = useState(formatDate(startDate || new Date(), 'YYYY-MM-DD'))
  const [newStartTime, setNewStartTime] = useState(startDate ? `${formatDate(startDate, 'HH')}:00` : defaultStartTime())

  useEffect(() => {
    if (!setStartDate || !newStartDate) return
    setStartDate(new Date(`${newStartDate || formatDate(new Date(), 'YYYY-MM-DD')}T${newStartTime || '09:00'}${formattedTimezone()}`))
  }, [newStartDate, newStartTime])

  useEffect(() => {
    setNewStartTime(defaultStartTime())
  }, [project.type])

  useEffect(() => {
    getAnalysts().then(analysts => {
      onAnalystsLoaded(analysts)
      setLoading(false)
      onFormReady?.()
    })
  }, [])

  const onAnalystsLoaded = (analysts: User[]) => {
    setAnalysts(analysts)
    if (project._id) return
    if (analysts.find(analyst => analyst._id === user._id)) {
      onChange('analystId', user._id)
    }
  }

  const onInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const {name, value} = event.target
    onChange(name, value)
  }

  const onOptionalInputChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const {name, value} = event.target
    onChange(name, value || null)
  }

  const onLocalizedInputChange = (event: React.ChangeEvent<HTMLInputElement>, locale: Locale) => {
    event.preventDefault()

    const {value, name} = event.target
    onChange(name, {...project[name], [locale]: value})
  }

  const onOptionalLocalizedInputChange = (event: React.ChangeEvent<HTMLInputElement>, locale: Locale) => {
    event.preventDefault()

    const {value, name} = event.target
    onChange(name, {...project[name], [locale]: value || null})
  }

  const onAssistantAnalystsChange = (assistantAnalystIds: string[]) => {
    onChange('assistantAnalystIds', assistantAnalystIds)
  }

  const onNewStartDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault()
    if (setStartDate) setNewStartDate(event.target.value)
  }

  const onNewStartTimeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    event.preventDefault()
    if (setStartDate) setNewStartTime(event.target.value)
  }

  useEffect(() => {
    if (!project.publishedAt || project.publishedAt[Locale.EN]) return

    onChange('publishedAt', {...project.publishedAt, [Locale.EN]: project.publishedAt[Locale.JA]})
  }, [project.publishedAt?.[Locale.JA]])

  const canSetAnalysts = !project._id
  const canSpecifyStartDate = !project._id

  return (
    <>
      <div className="row g-5 row-divided-columns">
        <div className="col-lg-6">
          {children}

          <div className="mb-3">
            <label htmlFor="project-title" className="form-label small">{t('models.Project.title')}</label>
            <Input name="title" type="text" className="form-control" id="project-title"
                   value={project.title || ''} onChange={onInputChange} error={errors.title}/>
          </div>

          <div className="mb-3">
            <label htmlFor="project-report-title-ja"
                   className="form-label small">{t('models.Project.reportTitle.ja')}</label>
            <Input className="form-control" id="project-report-title-ja" name="reportTitle"
                   value={project.reportTitle?.ja || ''}
                   onChange={e => onLocalizedInputChange(e, Locale.JA)}
                   error={errors.reportTitle || errors['reportTitle.ja']}/>
          </div>

          <div className="mb-3">
            <label htmlFor="project-report-title-en"
                   className="form-label small">{t('models.Project.reportTitle.en')}</label>
            <Input className="form-control" id="project-report-title-en" name="reportTitle"
                   value={project.reportTitle?.en || ''}
                   onChange={e => onLocalizedInputChange(e, Locale.EN)}
                   error={errors['reportTitle.en']}/>
          </div>

          <div className="mb-0">
            <label htmlFor="project-notes" className="form-label small">{t('models.Project.notes')}</label>
            <Textarea name="notes" className="form-control" id="project-notes" rows={3}
                      value={project.notes || ''} onChange={onInputChange} error={errors.notes}/>
          </div>

        </div>
        <div className="col-lg-6">
          {canSpecifyStartDate && <>
            <div className="card-title">
              {t('components.ProjectForm.tasksScheduling')}
            </div>
            <div className="row">
              <div className="col-12">
                <div className="mb-3">
                  <label htmlFor="project-start-date" className="form-label small">
                    {t('components.ProjectForm.startDate')}
                  </label>

                  <div className="d-flex">
                    <Input type="date" className="form-control" id="project-start-date" value={newStartDate}
                           onChange={onNewStartDateChange}/>

                    <select value={newStartTime} onChange={onNewStartTimeChange} className="form-control ms-2">
                      {range(0, 23).map(hour => {
                        const formattedHour = `${hour.toString().padStart(2, '0')}:00`
                        return <option key={formattedHour} value={formattedHour}>{formattedHour}</option>
                      })}
                    </select>
                  </div>

                  <div className="small text-muted mt-2">{t('components.ProjectForm.startDateDescription')}</div>
                </div>
              </div>
            </div>
          </>}

          <div className="card-title">
            {t('models.Project.subtitle.deadlines')}
          </div>

          <div className="row">
            <div className="col-lg-6">
              <div className="mb-3">
                <label htmlFor="project-deadline-ja"
                       className="form-label small">{t('models.Project.deadline.ja')}</label>
                <Input type="date" className="form-control" id="project-deadline-ja" name="deadline"
                       value={project.deadline?.ja ? formatDate(project.deadline?.ja, 'yyyy-MM-DD') : ''}
                       onChange={e => onOptionalLocalizedInputChange(e, Locale.JA)} error={errors['deadline.ja']}/>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="mb-3">
                <label htmlFor="project-deadline-en"
                       className="form-label small">{t('models.Project.deadline.en')}</label>
                <Input type="date" className="form-control" id="project-deadline-en" name="deadline"
                       value={project.deadline?.en ? formatDate(project.deadline?.en, 'yyyy-MM-DD') : ''}
                       onChange={e => onOptionalLocalizedInputChange(e, Locale.EN)} error={errors['deadline.en']}/>
              </div>
            </div>
          </div>

          {canSetAnalysts && <>
            <div className="card-title">
              {t('models.Project.subtitle.stakeholders')}
            </div>

            <div className="row">
              <div className="col-12">
                <div className="mb-3">
                  <label htmlFor="analyst" className="form-label small">
                    {t('models.Project.analyst')}
                  </label>
                  <Select id="analyst" className="form-select" value={project.analystId as string || ''}
                          onChange={onOptionalInputChange} name="analystId" error={errors.analystId}
                          disabled={loading}>
                    <option value="">{t('components.ProjectForm.selectAnalyst')}</option>
                    {analysts.map(analyst =>
                      <option key={analyst._id as string} value={analyst._id as string}>{analyst.name}</option>
                    )}
                  </Select>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="mb-3 col-lg-12">
                <label className="form-label small">
                  {t('models.Project.assistantAnalysts')}
                </label>
                <MultipleSelect htmlId="assistantAnalysts" name="assistantAnalysts"
                                placeholder={t('components.ProjectForm.selectAssistantAnalysts')}
                                label={(user: User) => user.name!} collection={analysts}
                                selectedValues={project.assistantAnalystIds as string[]}
                                onChange={onAssistantAnalystsChange}
                />
              </div>
            </div>
          </>}

          <div className="card-title">{t('components.ProjectForm.migrationDetails')}</div>
          <div className="row">
            <div className="col-lg-6">
              <div className="mb-3">
                <label htmlFor="project-published-at-ja"
                       className="form-label small">{t('models.Project.publishedAt.ja')}</label>
                <Input type="date" className="form-control" id="project-published-at-ja" name="publishedAt"
                       value={project.publishedAt?.ja ? formatDate(project.publishedAt.ja, 'yyyy-MM-DD') : undefined}
                       onChange={e => onLocalizedInputChange(e, Locale.JA)} error={errors.publishedAt}/>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="mb-3">
                <label htmlFor="project-published-at-en"
                       className="form-label small">{t('models.Project.publishedAt.en')}</label>
                <Input type="date" className="form-control" id="project-published-at-en" name="publishedAt"
                       value={project.publishedAt?.en ? formatDate(project.publishedAt.en, 'yyyy-MM-DD') : undefined}
                       onChange={e => onLocalizedInputChange(e, Locale.EN)} error={errors.publishedAt}/>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <div className="form-check pt-2">
                <input type="checkbox" id="notifySubscribers" name="type" className="form-check-input"
                       checked={project.notifySubscribers}
                       onChange={e => onChange('notifySubscribers', e.target.checked)}/>
                <label className="form-check-label" htmlFor="notifySubscribers">
                  {t('components.NewProject.notifySubscribers')}
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ProjectForm