import EditorJS, {API, BaseTool, EditorConfig} from '@editorjs/editorjs'
import {ObjectId} from 'bson'
import {EditorBlock, EditorGridBlockData, EditorGridCellData, EditorGridRowData, EditorTableConfig} from 'domain/Editor'
import {BlockType} from 'domain/Report'
import gridIcon from '../../../assets/inline-svg/view_column.svg'
import {InlineTools} from '../../../components/report/edit/Editor'
import EventBus, {EventType} from '../../../EventBus'
import GridBuilder from './GridBuilder'
import Tunes from './Tunes'


interface GridConstructorParam {
  data?: EditorGridBlockData
  config?: object & { titlePlaceholder: string, descriptionPlaceholder: string }
  api: API
  readOnly?: boolean
}

class Grid implements BaseTool {
  api: API
  config: object
  settingsButtons: HTMLElement[]
  data: EditorGridBlockData
  editors: EditorJS[][] = []
  editorConfig: EditorConfig
  builder: GridBuilder
  tunes: Tunes
  readonly: boolean

  constructor({data, config, api, readOnly}: GridConstructorParam) {
    this.api = api
    this.config = config || {} as EditorTableConfig
    this.settingsButtons = []
    this.readonly = readOnly || false

    if (data && data.grid) {
      this.data = data
    } else {
      this.data = {
        border: false,
        equalColumnWidth: true,
        title: '',
        description: '',
        grid: [
          [Grid.defaultCellData(), Grid.defaultCellData()]
        ]
      }
    }

    this.data = {
      ...this.data,
      titlePlaceholder: config?.titlePlaceholder,
      descriptionPlaceholder: config?.descriptionPlaceholder
    }

    const parentConfig = api.editorConfig()
    this.editorConfig = this.getEditorConfig(parentConfig)
    this.builder = new GridBuilder(
      this.api,
      this.data,
      this.readonly,
      Grid.defaultCellData,
      this.initEditorsForRow.bind(this),
      this.initEditorsForColumn.bind(this),
      this.removeEditorsForRow.bind(this),
      this.removeEditorsForColumn.bind(this)
    )

    this.tunes = new Tunes(
      this.api,
      this.setBorder.bind(this),
      this.setEqualColumnWidth.bind(this)
    )
  }

  static get isReadOnlySupported() {
    return true
  }

  static defaultCellData(): EditorGridCellData {
    return {
      id: new ObjectId().toString(),
      blocks: []
    }
  }

  static get toolbox() {
    return {
      title: 'Grid',
      icon: gridIcon
    }
  }

  static get allowedTools() {
    return [
      BlockType.HEADING,
      BlockType.PARAGRAPH,
      BlockType.LIST,
      BlockType.IMAGE,
      BlockType.EXCEL_TABLE,
      BlockType.ALERT,
      InlineTools.COMMENT,
      InlineTools.MARKER,
      InlineTools.SUPERSCRIPT
    ]
  }

  render(): HTMLElement {
    setTimeout(() => {
      document.addEventListener('click', this.onClickInDocument, false)
    }, 0)

    return this.builder.ui()
  }

  rendered(): void {
    this.initEditors()
  }

  renderSettings(): HTMLElement {
    return this.tunes.render(this.data)
  }

  async save(): Promise<EditorGridBlockData> {
    const grid = await Promise.all(this.editors.map(async (rowEditors, rowIdx) => {
      return await Promise.all(rowEditors.map(async (editor, cellIdx) => {
        let blocks

        try {
          blocks = await this.getBlocks(editor)
        } catch {
          blocks = this.data.grid[rowIdx][cellIdx].blocks
        }

        return {id: this.data.grid[rowIdx][cellIdx].id, blocks}
      }))
    }))

    const title = this.builder.nodes.title.innerHTML
    const description = this.builder.nodes.description.innerHTML

    return {
      title,
      description,
      grid,
      border: this.data.border,
      equalColumnWidth: this.data.equalColumnWidth
    }
  }

  async clone(): Promise<EditorGridBlockData> {
    const blockData = await this.save()
    for (const row of blockData.grid) {
      for (const cell of row) {
        cell.id = new ObjectId().toHexString()
        for (const block of cell.blocks) {
          block.id = new ObjectId().toHexString()
        }
      }
    }
    return blockData
  }

  destroy() {
    document.removeEventListener('click', this.onClickInDocument, false)

    for (const editorsRow of this.editors) {
      for (const editor of editorsRow) {
        if (editor.ready) editor.destroy()
      }
    }
  }

  onClickInDocument = (event: MouseEvent) => {
    const target = event.target as HTMLElement
    const inGrid = this.builder.nodes.wrapper.contains(target)

    const clickedInHandler =
      target.closest(`.${this.builder.CSS.columnHandle}`) !== null ||
      target.closest(`.${this.builder.CSS.rowHandle}`) !== null

    if (!clickedInHandler || !inGrid) {
      this.builder.unselect()
    }
  }

  private async getBlocks(editor: EditorJS): Promise<EditorBlock[]> {
    if (!editor.ready) {
      throw Error('Editor is not ready')
    }
    return (await editor.save()).blocks as EditorBlock[]
  }

  setBorder(border: boolean) {
    this.data.border = border
    this.builder.nodes.tableWrapper.classList.toggle(GridBuilder.CSS.tableBordered, this.data.border)
  }

  setEqualColumnWidth(value: boolean) {
    this.data.equalColumnWidth = value
    this.builder.nodes.table.classList.toggle(GridBuilder.CSS.tableFixed, this.data.equalColumnWidth)
  }

  private getEditorConfig(parentConfig: EditorConfig): EditorConfig {
    const {tools: parentTools, onReady, onChange, ...other} = parentConfig
    const tools = Object.fromEntries(
      Object.entries(parentTools || {}).filter(([k, _]) => Grid.allowedTools.includes(k as BlockType))
    )

    return {
      ...other,
      tools
    }
  }

  private initEditors(): void {
    this.editors = this.data.grid.map(row => {
      return row.map(cell => {
        return this.initEditor(cell)
      })
    })
  }

  private initEditor(data: EditorGridCellData): EditorJS {
    return new EditorJS({
      ...this.editorConfig,
      autofocus: false,
      holder: data.id,
      minHeight: 50,
      allowInsert: true,
      allowDelete: true,
      allowClone: false,
      allowCheck: false,
      allowMovingToBookmark: false,
      allowBlockConversion: false,
      allowAutoTranslate: false,
      onReady: () => {
        EventBus.emit(EventType.EDITOR_READY)
      },
      data: {blocks: data.blocks}
    })
  }

  private initEditorsForRow(rowIdx: number, data: EditorGridRowData): void {
    this.editors.splice(rowIdx, 0, data.map(cell => this.initEditor(cell)))
  }

  private initEditorsForColumn(colIdx: number, data: EditorGridCellData[]): void {
    if (this.editors.length === 0) {
      this.editors.push([])
    }

    this.editors.forEach((row, i) => {
      row.splice(colIdx, 0, this.initEditor(data[i]))
    })
  }

  private removeEditorsForRow(rowIdx: number): void {
    for (const editor of this.editors[rowIdx]) {
      editor.destroy()
    }

    this.editors.splice(rowIdx, 1)
  }

  private removeEditorsForColumn(colIdx: number): void {
    this.editors.forEach(rowEditors => {
      rowEditors[colIdx].destroy()
      rowEditors.splice(colIdx, 1)
    })

    for (let i = this.editors.length - 1; i >= 0; i--) {
      if (this.editors[i].length === 0) {
        this.editors.splice(i, 1)
      }
    }
  }
}

export default Grid
