import {Locale} from 'domain/Locale'
import React, {PropsWithChildren, useContext} from 'react'
import {LocaleContext} from '../../providers/LocaleProvider'
import {ReactComponent as LogoIcon} from '../../assets/images/sr-logo.svg'

const PublicPageTemplate: React.FC<PropsWithChildren> = ({children}) => {
  const {locale} = useContext(LocaleContext)
  const {setLocale} = useContext(LocaleContext)

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-6 min-vh-50 d-flex align-items-center justify-content-center bg-container order-2 order-md-1">
          <LogoIcon className="logo" />
        </div>
        <div className="col-md-6 min-vh-50 min-vh-md-100 d-flex align-items-center justify-content-center order-1 order-md-2">
          <div className="position-absolute top-0 end-0 p-3">
            <button
              className={`btn btn-sm btn-link ${locale === 'en' ? 'd-none' : ''}`}
              onClick={() => setLocale(Locale.EN)}
            >
              In English
            </button>
            <button
              className={`btn btn-sm btn-link ${locale === 'ja' ? 'd-none' : ''}`}
              onClick={() => setLocale(Locale.JA)}
            >
              日本語
            </button>
          </div>
          {children}
        </div>
      </div>
    </div>
  )
}

export default PublicPageTemplate
