import {COMMENT_HIGHLIGHT_CLASS, COMMENT_TAG} from './CommentTool'


export const surroundOneTextNode = (range: Range) => {
  const selectedText = range.extractContents()
  const wrapper = highlightText(selectedText)
  range.insertNode(wrapper)
  return wrapper
}

export const surroundEachTextNodeInRange = (range: Range): HTMLElement[] => {
  const {startContainer, endContainer, startOffset, endOffset} = range

  const wrappedElements: HTMLElement[] = []
  const parent = range.commonAncestorContainer
  const allTextNodes = findAllTextNodesBetween(parent, startContainer, endContainer)

  allTextNodes.forEach(node => {
    const wrapper = document.createElement(COMMENT_TAG)
    wrapper.classList.add(COMMENT_HIGHLIGHT_CLASS)
    wrapper.dataset.highlighted = 'true'

    const newNodes = []
    const nodeText = node.wholeText

    if (node.contains(startContainer)) {
      const notHighlightedText = nodeText.slice(0, startOffset)
      const highlightedText = nodeText.slice(startOffset)

      wrapper.append(highlightedText)
      newNodes.push(notHighlightedText, wrapper)
    } else if (node.contains(endContainer)) {
      const highlightedText = nodeText.slice(0, endOffset)
      const notHighlightedText = nodeText.slice(endOffset)

      wrapper.append(highlightedText)
      newNodes.push(wrapper, notHighlightedText)
    } else {
      wrapper.append(nodeText)
      newNodes.push(wrapper)
    }

    node.replaceWith(...newNodes)
    wrappedElements.push(wrapper)
  })

  return wrappedElements
}

const findAllTextNodesBetween = (container: Node, from: Node, to: Node): Text[] => {
  const nodes = Array.from(container.childNodes) as ChildNode[]

  if (container === from && container === to) {
    return findAllTextChildren(container)
  }

  const textNodes: Text[] = []

  let collect = false
  for (const node of nodes) {
    if (node.contains(from) || node.contains(to)) {
      collect = !collect
      if (node.contains(from)) {
        textNodes.push(...findAllTextChildren(from))
      } else {
        textNodes.push(...findAllTextChildren(to))
      }
    } else if (collect) {
      textNodes.push(...findAllTextChildren(node))
    }
  }

  return textNodes
}

export const highlightText = (node: Node) => {
  const wrapper = document.createElement(COMMENT_TAG)
  wrapper.classList.add(COMMENT_HIGHLIGHT_CLASS)
  wrapper.dataset.highlighted = 'true'
  wrapper.append(node)
  return wrapper
}

const findAllTextChildren = (node: Node): Text[] => {
  if (node.nodeName === '#text') {
    return [node] as Text[]
  }

  return Array.from(node.childNodes).flatMap(child => findAllTextChildren(child))
}