import {Holiday} from 'domain/Holiday'
import {ReactComponent as AddIcon} from '../../assets/icons/add.svg'
import {ReactComponent as DeleteIcon} from '../../assets/icons/delete.svg'
import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {deleteHoliday, getHolidays} from '../../api-clients/holidayClient'
import useModal from '../../hooks/useModal'
import ConfirmationModal from '../utils/ConfirmationModal'
import FormattedDate from '../utils/FormattedDate'
import AddHoliday from './AddHoliday'


const HolidaysPage: React.FC = () => {
  const {t} = useTranslation()
  const [holidays, setHolidays] = useState<Holiday[]>([])
  const {showModal} = useModal()
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    getHolidays().then(setHolidays).finally(() => setLoading(false))
  }, [])

  const onDelete = (e: React.MouseEvent, holiday: Holiday) => {
    e.preventDefault()
    showModal(<ConfirmationModal title={t('components.HolidaysPage.confirmDeleteTitle', {date: holiday.date})}
                                 body={t('components.HolidaysPage.confirmDeleteDescription')}
                                 ConfirmationIcon={DeleteIcon}
                                 onConfirm={() => onDeleteConfirmed(holiday)}/>)
  }

  const onDeleteConfirmed = (holiday: Holiday) => {
    return deleteHoliday(holiday._id).then(() => {
      setHolidays(holidays => holidays.filter(h => h._id !== holiday._id))
    })
  }

  const onAddHoliday = (e: React.MouseEvent) => {
    e.preventDefault()
    showModal(<AddHoliday onAdded={onHolidayAdded}/>)
  }

  const onHolidayAdded = (holiday: Holiday) => {
    setHolidays(holidays => [...holidays, holiday].sortBy(h => h.date))
  }

  return (
    <div className="container py-5">
      <div className="d-flex justify-content-between align-items-center my-5">
        <h1 className="display-3 fw-bolder">
          {t('components.HolidaysPage.title')}
        </h1>

        <button type="button" onClick={onAddHoliday}
                className="btn btn-outline-primary btn-sm btn-with-icon">
          <span>{t('components.HolidaysPage.add')}</span>
          <AddIcon className="icon icon-sm"/>
        </button>
      </div>

      <div className="card elevate">
        <div className="card-body">
          {!loading && holidays.length > 0 && (
            <table className="table table-sm small">
              <thead>
              <tr>
                <th>{t('models.Holiday.date')}</th>
              </tr>
              </thead>
              <tbody>
              {holidays.map(holiday => (
                <tr key={holiday._id as string}>
                  <td><FormattedDate date={holiday.date}/></td>
                  <td className="text-end">
                    <button title={t('components.HolidaysPage.delete')} onClick={e => onDelete(e, holiday)}
                            className="btn btn-sm btn-icon-only ms-2 btn-outline-danger">
                      <DeleteIcon className="icon icon-sm"/>
                    </button>
                  </td>
                </tr>))}
              </tbody>
            </table>)}

          {!loading && holidays.length === 0 && <div className="d-flex justify-content-center">
            <p className="lead m-0">{t('components.HolidaysPage.noDates')}</p>
          </div>}
        </div>
      </div>
    </div>
  )
}

export default HolidaysPage