import {CompanyStatus} from 'domain/Company'
import {Locale} from 'domain/Locale'
import {ProjectStage, ProjectType} from 'domain/Project'
import {AggregatedProjectNotification, ProjectNotificationType} from 'domain/ProjectNotification'
import {TranslationRequest, TranslationRequestStatus} from 'domain/TranslationRequest'
import {Role, User} from 'domain/User'
import {ProjectAggregate} from 'domain/ProjectAggregate'


export const projectNotifications: AggregatedProjectNotification[] = [
  {
    _id: 'p-1',
    userId: 'analyst-id',
    projectId: 'project-id',
    type: ProjectNotificationType.PROJECT_ASSIGN,
    companyTick: '1234',
    projectTitle: 'Flash Q4 2022 Update',
    params: {
      actorName: 'Bob Smith'
    },
    createdAt: new Date(),
    updatedAt: new Date()
  },
  {
    _id: 'p-2',
    userId: 'analyst-id',
    projectId: 'project-id',
    type: ProjectNotificationType.COMMENT_CREATE,
    companyTick: '1234',
    projectTitle: 'Flash Q4 2022 Update',
    params: {
      commentId: 'comment-id',
      comment: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.',
      locale: Locale.JA,
      actorName: 'Bob Smith'
    },
    createdAt: new Date(),
    updatedAt: new Date()
  },
  {
    _id: 'p-3',
    userId: 'analyst-id',
    projectId: 'project-id',
    type: ProjectNotificationType.PROJECT_TASK_RESCHEDULE,
    companyTick: '1234',
    projectTitle: 'Flash Q4 2022 Update',
    params: {
      taskStage: ProjectStage.TRANSLATION_PREPARATION,
      oldDate: new Date('2022-03-05'),
      newDate: new Date('2022-04-11')
    },
    createdAt: new Date(),
    updatedAt: new Date()
  }
]

export const translator: User = {
  _id: 'user-id',
  email: 'translator@shared-rs.co.jp',
  name: 'Ben Maruyama',
  roles: [Role.INTERNAL_TRANSLATOR],
  createdAt: new Date(),
  updatedAt: new Date()
}

export const assigner: User = {
  _id: 'user-id',
  email: 'assigner@shared-rs.co.jp',
  name: 'Takumi Hojo',
  roles: [Role.EXTERNAL_TRANSLATOR],
  createdAt: new Date(),
  updatedAt: new Date()
}

export const coordinator: User = {
  _id: 'user-id',
  email: 'coordinator@shared-rs.co.jp',
  name: 'Kyoko Sato',
  roles: [Role.TRANSLATOR_COORDINATOR],
  createdAt: new Date(),
  updatedAt: new Date()
}

export const aggregatedProject: ProjectAggregate = {
  _id: 'project-id',
  reportId: 'report-id',
  type: ProjectType.FULL,
  title: 'Morinaga Milk Industry Full Report',
  companyId: 'morinaga-id',
  notes: 'notes',
  notifySubscribers: true,
  deadline: {
    ja: new Date(),
    en: new Date()
  },
  reportTitle: {
    ja: 'Title JA',
    en: 'Title EN'
  },
  company: {
    _id: 'morinaga-id',
    tick: '2264',
    name: {
      ja: '森永乳業',
      en: 'MORINAGA MILK INDUSTRY'
    },
    shortName: {
      ja: 'shortNameJA',
      en: 'shortNameEn'
    },
    japaneseSpellingName: 'Kanji',
    status: CompanyStatus.ACTIVE,
    createdAt: new Date(),
    updatedAt: new Date(),
    isZeroYen: false
  },
  translationRequests: [],
  assistantAnalysts: [],
  currentTaskIndex: 0,
  tasksCount: 1,
  expectedDeadline: {
    ja: new Date(),
    en: new Date()
  },
  createdAt: new Date(),
  updatedAt: new Date()
}

export const translationRequest: TranslationRequest = {
  _id: 'translation-request-id',
  projectId: 'project-id',
  assignerId: 'assigner-id',
  translatorId: 'translator-id',
  status: TranslationRequestStatus.PENDING,
  reply: 'reply',
  cancelReply: 'cancelReply',
  message: 'message',
  createdAt: new Date(),
  updatedAt: new Date()
}