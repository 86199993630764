import {PageType} from 'domain/PageType'
import {useContext, useEffect} from 'react'
import {LocaleContext} from '../providers/LocaleProvider'
import {ProjectAggregate} from 'domain/ProjectAggregate'

export const useProjectDocumentTitle = (project: ProjectAggregate | null, pageType: PageType) => {
  const {locale} = useContext(LocaleContext)

  useEffect(() => {
    const defaultTitle = document.title
    if (project) {
      const companyName = project?.company.name[locale] || ''
      const projectTitle = project?.title || ''
      document.title = `${defaultTitle}-${companyName}-${projectTitle}-${pageType}-${locale.toUpperCase()}`
    }

    return () => {
      document.title = defaultTitle
    }
  }, [project])

  return
}
