import React, {useContext, useEffect, useRef} from 'react'
import {CommentContext} from '../../../providers/CommentProvider'
import {InlineCommentContext} from '../../../providers/InlineCommentProvider'
import {getCommentId, getRelativeCoordinates} from '../comments/commentUtils'
import TextAlignedComment from '../comments/TextAlignedComment'


const COMMENT_SELECTOR = '.comment-highlight[data-attached=true]'

const InlineComment: React.FC = () => {
  const {openInlineComment, closeInlineComment, inlineComment, coordinates} = useContext(InlineCommentContext)
  const {comments} = useContext(CommentContext)
  const commentRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const onCommentClick = (e: MouseEvent) => {
      const target = e.target as HTMLElement
      const commentNode = target.closest(COMMENT_SELECTOR) as HTMLElement
      if (!commentNode) return

      const commentId = getCommentId(commentNode)
      if (commentId) openInlineComment(commentId, getRelativeCoordinates(e))
    }

    document.addEventListener('click', onCommentClick)

    return () => {
      document.removeEventListener('click', onCommentClick)
    }
  }, [comments])

  useEffect(() => {
    const onKeyDown = (e: KeyboardEvent) => {
      if (!inlineComment) return
      if (e.key === 'Escape') {
        closeInlineComment()
      }
    }

    const onOutsideClickListener = (e: MouseEvent) => {
      const node = commentRef.current
      if (!node) return

      const clickedInside = node.contains(e.target as Node)
      if (!clickedInside) {
        closeInlineComment()
        e.preventDefault()
        e.stopPropagation()
      }
    }

    document.addEventListener('keydown', onKeyDown)
    document.addEventListener('click', onOutsideClickListener)

    return () => {
      document.removeEventListener('keydown', onKeyDown)
      document.removeEventListener('click', onOutsideClickListener)
    }
  }, [inlineComment])

  return inlineComment && coordinates ? <TextAlignedComment comment={inlineComment} ref={commentRef}
                                                            coordinates={coordinates} initiallyActive={true}
                                                            classNames="inline-comment"/> : <></>
}

export default InlineComment