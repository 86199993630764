import {Permission} from 'domain/Permission'
import {hasRoles, Role} from 'domain/User'
import {StatusCodes} from 'http-status-codes'
import React, {ReactElement, useContext, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {matchPath, Redirect, Route, Switch, useHistory} from 'react-router-dom'
import {renewAccessToken} from './api-clients/authClient'

import {getMSClarityConfiguration} from './api-clients/msClarityClient'
import './assets/scss/main.scss'
import AuthRoute from './AuthRoute'
import HolidaysPage from './components/admin/HolidaysPage'
import LargePastesPage from './components/admin/LargePastesPage'
import CompaniesPage from './components/companies/CompaniesPage'
import CompanyPage from './components/company/CompanyPage'
import EmailsPreview from './components/emails/EmailsPreview'
import GlossaryPage from './components/glossary/GlossaryPage'
import LoginPage from './components/login/LoginPage'
import MicroTranslationPage from './components/micro-translations/MicroTranslationPage'
import MicroTranslationsDashboardPage from './components/micro-translations/MicroTranslationsDashboardPage'
import ProjectWorkspace from './components/project/ProjectWorkspace'
import CompanyProjectsPage from './components/projects/CompanyProjectsPage'
import ExternalTranslatorProjectsPage from './components/projects/ExternalTranslatorProjectsPage'
import ProjectsPage from './components/projects/ProjectsPage'
import RequestResetPasswordPage from './components/reset-password/RequestResetPasswordPage'
import ResetPasswordPage from './components/reset-password/ResetPasswordPage'
import SchedulePage from './components/schedule/SchedulePage'
import TopNav from './components/topnav/TopNav'
import TranslationsDashboardPage from './components/translations-dashboard/TranslationsDashboardPage'
import UserPage from './components/user/UserPage'
import UserActivationPage from './components/users/UserActivation'
import UsersPage from './components/users/UsersPage'
import Modal from './components/utils/Modal'
import Toasts from './components/utils/Toasts'
import ErrorHandler from './ErrorHandler'
import EventBus, {EventType} from './EventBus'
import useModal from './hooks/useModal'
import useOverdueTasksReminder from './hooks/useOverdueTasksReminder'
import useToast from './hooks/useToast'
import LocalStorage from './LocalStorage'
import {msClaritySnippet} from './msClaritySnippet'
import LocaleProvider from './providers/LocaleProvider'
import ModalProvider from './providers/ModalProvider'
import ToastProvider from './providers/ToastProvider'
import UserProvider, {UserContext} from './providers/UserProvider'
import {withProviders} from './providers/utils'
import {DetectedEnvironment, detectEnvironment} from './utils/detectEnvironment'
import {ignore} from './utils/promiseUtils'

const App: React.FC = (): ReactElement => {
  const [loading, setLoading] = useState(true)
  const {user, setAccessToken, logoutUser} = useContext(UserContext)
  const {replace, location} = useHistory()
  const {renderErrorToast} = useToast()
  const {components: modalComponents, closeModal} = useModal()
  const {t} = useTranslation()
  useOverdueTasksReminder()

  const environment = detectEnvironment()

  useEffect(() => {
    ignore(refreshAccessToken())

    EventBus.on(EventType.EXPIRE_SESSION, logoutUser)
    EventBus.on(EventType.NOTIFY_FORBIDDEN, notifyForbidden)

    return () => {
      EventBus.unsubscribe(EventType.EXPIRE_SESSION, logoutUser)
      EventBus.unsubscribe(EventType.NOTIFY_FORBIDDEN, notifyForbidden)
    }
  }, [])

  const refreshAccessToken = async () => {
    setLoading(true)
    try {
      const {accessToken} = await renewAccessToken()
      setAccessToken(accessToken)
    } catch (error: any) {
      if (error.status === StatusCodes.UNAUTHORIZED) {
        if (isUnauthorizedRoute(location.pathname)) {
          return
        }
        replace('/login')
      } else {
        throw error
      }
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getMSClarityConfiguration().then(response => {
      if (response.id) {
        msClaritySnippet(response.id)
      } else {
        console.info('MSClarity has not been configured for this environment.')
      }
    })
  }, [])

  const notifyForbidden = () => {
    renderErrorToast(t('errors.forbidden_request'))
  }

  const isCompanyUser = hasRoles(user?.roles, Role.COMPANY_REPRESENTATIVE)
  const isExternalTranslator = hasRoles(user?.roles, Role.EXTERNAL_TRANSLATOR)

  const projectsComponent = (path: string) => {
    if (isCompanyUser) return <Route path={path} exact={true} component={CompanyProjectsPage} />
    if (isExternalTranslator) return <Route path={path} exact={true} component={ExternalTranslatorProjectsPage} />
    return <AuthRoute exact={true} path={path} component={ProjectsPage} permission={Permission.Project.READ_ALL} />
  }

  const authorizedApp = (
    <>
      <div>
        <Route>
          <TopNav />

          <Switch>
            <AuthRoute path="/schedule" component={SchedulePage} />

            {projectsComponent('/projects')}

            <Route
              exact
              path="/"
              render={() => (isCompanyUser ? <Redirect to="/projects" /> : <Redirect to="/schedule" />)}
            />

            <AuthRoute exact path="/companies" component={CompaniesPage} permission={Permission.Company.READ_ALL} />

            <AuthRoute path="/companies/:id" component={CompanyPage} permission={Permission.Company.READ} />

            <AuthRoute
              exact
              path="/translations"
              component={TranslationsDashboardPage}
              permission={Permission.Project.READ_ALL}
            />

            <AuthRoute path="/projects/:projectId" component={ProjectWorkspace} permission={Permission.Project.READ} />

            <AuthRoute exact path="/users" component={UsersPage} permission={Permission.User.READ_ALL} />

            <AuthRoute exact path="/users/:id" component={UserPage} permission={Permission.User.READ} />

            <AuthRoute path="/glossary/:companyId?" component={GlossaryPage} permission={Permission.Glossary.READ} />

            <AuthRoute
              path="/micro-translations"
              component={MicroTranslationsDashboardPage}
              permission={Permission.MicroTranslation.READ}
              exact={true}
            />
            <AuthRoute
              path="/micro-translations/:id"
              component={MicroTranslationPage}
              permission={Permission.MicroTranslation.READ}
            />
            <Route path="/user/activate/:activationToken" component={UserActivationPage} />

            <Route path="/login" render={() => <Redirect to="/" />} />

            <AuthRoute
              path="/admin/large-pastes"
              permission={Permission.System.LARGE_PASTE_MANAGE}
              component={LargePastesPage}
            />

            <AuthRoute path="/admin/holidays" permission={Permission.System.HOLIDAYS} component={HolidaysPage} />

            {environment === DetectedEnvironment.DEVELOPMENT && (
              <Route path="/emails-preview" component={EmailsPreview} />
            )}
          </Switch>
        </Route>
      </div>

      {modalComponents.length > 0 && <Modal components={modalComponents} onClose={closeModal} />}

      <Toasts />
    </>
  )

  const unauthorizedApp = (
    <>
      <Route>
        <Switch>
          {unauthorizedRoutes.map(route => (
            <Route key={route.path} path={route.path} component={route.component} />
          ))}
          <Route path="/" render={() => <Redirect to={'/login'} />} />
        </Switch>
      </Route>
      <Toasts />
    </>
  )

  if (loading) return <></>
  if (!user) LocalStorage.setRedirectTo(location.pathname + location.search)
  return <ErrorHandler>{user ? authorizedApp : unauthorizedApp}</ErrorHandler>
}

const unauthorizedRoutes = [
  {path: '/login', component: LoginPage},
  {path: '/user/activate/:activationToken', component: UserActivationPage},
  {path: '/request-reset-password', component: RequestResetPasswordPage},
  {path: '/reset-password/:resetPasswordToken', component: ResetPasswordPage}
]

const isUnauthorizedRoute = (path: string) => unauthorizedRoutes.some(route => matchPath(path, {path: route.path}))

export default withProviders([ToastProvider, LocaleProvider, ModalProvider, UserProvider], App)
