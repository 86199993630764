import {Id} from 'domain/Entity'
import {ReactComponent as ArrowForward} from '../../assets/icons/arrow_forward.svg'
import {ReactComponent as DoneIcon} from '../../assets/icons/done.svg'
import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'
import {applyGlossary} from '../../api-clients/reportClient'
import useModal from '../../hooks/useModal'
import useToast from '../../hooks/useToast'
import ButtonWithLoaderAndIcon from '../utils/form/ButtonWithLoaderAndIcon'
import ModalTemplate from '../utils/ModalTemplate'


type Props = {
  projectId: Id
}

const ConfirmApplyingGlossary: React.FC<Props> = props => {
  const {projectId} = props
  const {t} = useTranslation()
  const history = useHistory()
  const {renderErrorToast} = useToast()
  const {closeModal} = useModal()
  const [loading, setLoading] = useState(false)
  const [completed, setCompleted] = useState(false)

  const onInitiate = (event: React.MouseEvent) => {
    event.preventDefault()
    setLoading(true)
    applyGlossary(projectId)
      .then(() => {
        setCompleted(true)
      })
      .catch(() => {
        renderErrorToast(t('components.ConfirmApplyingGlossary.error'))
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const onOpenTranslation = (event: React.MouseEvent) => {
    event.preventDefault()
    history.push(`/projects/${projectId}/report/translate`)
    closeModal()
  }

  return (<ModalTemplate title={t('components.ConfirmApplyingGlossary.title')}>
    <div className="modal-body">
      <div>
        {t('components.ConfirmApplyingGlossary.description')}
      </div>
    </div>

    <div className="modal-footer">
      {!completed &&
        <ButtonWithLoaderAndIcon loading={loading} className="btn btn-success" onClick={onInitiate}
                                 icon={<DoneIcon className="icon" />}>
          <span>{t('components.ConfirmApplyingGlossary.apply')}</span>
        </ButtonWithLoaderAndIcon>
      }

      {completed &&
        <ButtonWithLoaderAndIcon loading={loading} className="btn btn-success" onClick={onOpenTranslation}
                                 icon={<ArrowForward className="icon" />}>
          <span>{t('components.ConfirmApplyingGlossary.openTranslation')}</span>
        </ButtonWithLoaderAndIcon>
      }
    </div>
  </ModalTemplate>)
}

export default ConfirmApplyingGlossary