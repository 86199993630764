import {CommentWithUserName} from 'domain/Comment'
import {Locale} from 'domain/Locale'
import {PageType} from 'domain/PageType'
import {emptyGroupedNews, GroupedNews} from 'domain/Project'
import {Block} from 'domain/Report'
import React, {ReactElement, useContext, useEffect, useMemo, useState} from 'react'
import {useHistory, useLocation} from 'react-router-dom'
import {getActiveCompanies} from '../../api-clients/companyClient'
import {getReportAtRevision} from '../../api-clients/reportClient'
import EventBus, {EventType} from '../../EventBus'
import useConfirmationBeforeDeletion from '../../hooks/useConfirmationBeforeDeletion'
import useCurrentLocationVisibilityInSidebar from '../../hooks/useCurrentLocationVisibilityInSidebar'
import useForceRender from '../../hooks/useForceRender'
import useModal from '../../hooks/useModal'
import {useResizeDataSections} from '../../hooks/useResizeDataSections'
import {usePageReloadAfterSleep} from '../../hooks/usePageReloadAfterSleep'
import {useProjectDocumentTitle} from '../../hooks/useProjectDocumentTitle'
import useReportPage from '../../hooks/useReportPage'
import useScrollKey from '../../hooks/useScrollKey'
import {CommentContext} from '../../providers/CommentProvider'
import DiffDeletionsProvider from '../../providers/DiffDeletionsProvider'
import InlineCommentProvider from '../../providers/InlineCommentProvider'
import {ReportContext} from '../../providers/ReportProvider'
import {ReportUpdateContext} from '../../providers/ReportUpdateProvider'
import TranslatePageProvider, {TranslatePageContext} from '../../providers/TranslatePageProvider'
import {UserContext} from '../../providers/UserProvider'
import {withProviders} from '../../providers/utils'
import ImportTranslations from '../project/ImportTranslations'
import UploadFile from '../project/UploadFile'
import Modal from '../utils/Modal'
import Spinner from '../utils/Spinner'
import CommentsSidebar from './comments/CommentsSidebar'
import useExcludedFromWordCountBlocks from './edit/useExcludedFromWordCountBlocks'
import InlineComments from './inline-comments/InlineComments'
import SidebarWithReportChangeListener from './navigation/SidebarWithReportChangeListener'
import CompanyGlossaryLink from './report-navigation/CompanyGlossaryLink'
import ReportNavigation from './report-navigation/ReportNavigation'
import ReportReload from './report-navigation/ReportReload'
import SearchAndReplace from './report-navigation/SearchAndReplace'
import {useReportLock} from './ReportLockManager'
import DeletionToggles from './revisions/DeletionToggles'
import ShowIf, {notNewsUpdateType} from './ShowIf'
import Toolbar from './Toolbar'
import NewsUpdatesHeader from './translation/NewsUpdatesHeader'
import TranslatedReportBlocks from './translation/TranslatedReportBlocks'
import TranslationSidebar from './translation/TranslationSidebar'

const TranslateReportPage: React.FC = (): ReactElement => {
  const targetLocale = Locale.EN
  useScrollKey()
  const {showModal, closeLastModal} = useModal()
  const {setCompanies, showTranslationSidebar} = useContext(TranslatePageContext)
  const {loadReport, setMigratedReport} = useContext(ReportUpdateContext)
  const {company, project, reportStore} = useContext(ReportContext)
  const {getLocaleComments, comments} = useContext(CommentContext)
  const {state} = useLocation()
  const history = useHistory()
  const {undoRedoActions, getGroupedNews} = useReportPage()
  const [untranslatedTerms, setUntranslatedTerms] = useState<string[]>([])
  const [previousBlocks, setPreviousBlocks] = useState<Record<string, Block> | null>(null)
  const {rerender} = useForceRender()
  const {user} = useContext(UserContext)
  const [showCommentsSidebar, setShowCommentsSidebar] = useState(false)
  const [localeComments, setLocaleComments] = useState<CommentWithUserName[]>([])
  const [groupedNews, setGroupedNews] = useState<GroupedNews>(emptyGroupedNews)
  const report = reportStore.getReport()

  const readyForRender = useMemo(() => {
    return report && company && (!project?.translateFromRevisionId || previousBlocks)
  }, [report, company, project, previousBlocks])

  useResizeDataSections([report, previousBlocks])
  useCurrentLocationVisibilityInSidebar('[data-section]', [readyForRender])
  useConfirmationBeforeDeletion(reportStore)
  useExcludedFromWordCountBlocks()
  usePageReloadAfterSleep()
  useProjectDocumentTitle(project, PageType.TRANSLATE)

  useEffect(() => {
    reportStore.onUpdateStructure(() => {
      rerender()
    })
  }, [])

  useEffect(() => {
    EventBus.emit(EventType.TRANSLATION_PAGE_READY)
  }, [readyForRender])

  useEffect(() => {
    getActiveCompanies().then(setCompanies)
    getGroupedNews(targetLocale).then(setGroupedNews)
    return () => {
      closeLastModal()
    }
  }, [])

  useReportLock(project._id, targetLocale, user)

  const closeSidebar = () => {
    if (untranslatedTerms && untranslatedTerms.length > 0) setUntranslatedTerms([])
  }

  useEffect(() => {
    if (!project || !project.translateFromRevisionId) return

    getReportAtRevision(project._id, project.translateFromRevisionId).then(previousReport => {
      const blocksById = previousReport.sections
        .flatMap(s => s.blocks)
        .reduce((result, block) => {
          result[block.id] = block
          return result
        }, {})
      setPreviousBlocks(blocksById)
    })
  }, [project?._id])

  useEffect(() => {
    const localeComments = getLocaleComments(targetLocale)
    if (localeComments && localeComments.length && state && (state as any).showCommentsSidebar) {
      showComments()
      delete (state as any).showCommentsSidebar
      history.replace({...history, state})
    }
    setLocaleComments(localeComments)
  }, [comments])

  const onFileUpload = () => {
    showModal(<UploadFile locale={targetLocale} projectId={project!._id} onUploaded={setMigratedReport} />)
  }

  const onImportTranslation = () => {
    showModal(<ImportTranslations project={project!} loadReport={loadReport} />)
  }

  const showComments = () => {
    setShowCommentsSidebar(true)
  }

  const closeComments = () => {
    setShowCommentsSidebar(false)
  }

  if (!readyForRender) {
    return <Spinner withDimmer />
  } else {
    return (
      <>
        <div className="navbar navbar-secondary">
          {project && company && (
            <ReportNavigation
              titleKey={'components.Report.TranslatedReport.title'}
              project={project}
              company={company}
              locale={targetLocale}
            >
              <CompanyGlossaryLink project={project} />
              <ReportReload locale={targetLocale} loadReport={loadReport} />
              <SearchAndReplace parentsSelector=".report-edit-side-by-side-last" />
              <Toolbar
                locale={targetLocale}
                onFileUpload={onFileUpload}
                {...undoRedoActions}
                showComments={showComments}
                canImportTranslations={!!project.sourceProjectId}
                onImportTranslation={onImportTranslation}
              />
            </ReportNavigation>
          )}
        </div>

        <div className="report-content-wrapper flex-grow-1">
          <ShowIf rule={notNewsUpdateType}>
            <SidebarWithReportChangeListener
              reportStore={reportStore}
              locale={Locale.JA}
              displayMenu={false}
              groupedNews={groupedNews}
            />
          </ShowIf>
          <div className="report-editor-sticky-tools-wrapper report-editor-sticky-right" />
          <div className="report-content flex-grow-1">
            <DeletionToggles showEnglishDiffToggle={!!project?.translateFromRevisionId} />

            <div className="report-comments-visible report-comments-visible-side-by-side-editor">
              <div className="container-fluid report-edit report-with-comments report-edit-side-by-side content-visibility-auto">
                <NewsUpdatesHeader />
                {report!.sections.map(section => (
                  <div id={section._id} key={section._id} data-section={true} className="content-visibility-block">
                    {section.blocks.map(block => (
                      <TranslatedReportBlocks
                        section={section}
                        block={block}
                        closeSidebar={closeSidebar}
                        setUntranslatedTerms={setUntranslatedTerms}
                        targetLocale={targetLocale}
                        key={block.id}
                        previousBlock={previousBlocks?.[block.id]}
                        showDiff={!!project?.translateFromRevisionId}
                        groupedNews={groupedNews}
                      />
                    ))}
                  </div>
                ))}
              </div>

              <InlineComments locale={targetLocale} />
            </div>
          </div>
          {showTranslationSidebar && untranslatedTerms.length > 0 && (
            <TranslationSidebar
              untranslatedTerms={untranslatedTerms}
              onTranslationAdded={(untranslatedTermsLeft: string[]) => {
                setUntranslatedTerms(untranslatedTermsLeft)
              }}
            />
          )}
        </div>

        {showCommentsSidebar && (
          <Modal
            component={<CommentsSidebar localeComments={localeComments} onClose={closeComments} />}
            onClose={closeComments}
          />
        )}
      </>
    )
  }
}

export default withProviders([TranslatePageProvider, InlineCommentProvider, DiffDeletionsProvider], TranslateReportPage)
