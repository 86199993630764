import {Locale} from 'domain/Locale'
import {Block, BlockType, CloneBlock} from 'domain/Report'
import deepEqual from 'fast-deep-equal'
import BlocksEditor from '../../../editorjs/converting/BlocksEditor'
import deepCopy from '../../../utils/deepCopy'
import {BlockMergeStatus} from './ReportSectionsMerger'


export class BlockMergeResult {
  public readonly left?: Block
  public readonly right?: Block
  public readonly wasBlockDeleted?: boolean
  public resolved?: Block
  private localeStatus: Record<Locale, BlockMergeStatus> = {
    [Locale.JA]: BlockMergeStatus.EQUAL,
    [Locale.EN]: BlockMergeStatus.EQUAL
  }
  private allBlockMergeResults: BlockMergeResult[] = []

  constructor(init: { left?: Block, right?: Block, wasBlockDeleted?: boolean}) {
    this.left = init.left
    this.right = init.right
    this.wasBlockDeleted = init.wasBlockDeleted

    if (!this.left || !this.right) {
      Object.values(Locale).forEach(locale => this.setStatus(locale, BlockMergeStatus.INSERTION))
    } else {
      const areEqual = deepEqual(this.left, this.right)
      this.resolved = areEqual ? this.left : deepCopy(this.left)
      if (!areEqual) {
        Object.values(Locale).forEach(locale => {
          const leftData = this.left && BlocksEditor.convertToEditor([this.left], locale, false)
          const rightData = this.right && BlocksEditor.convertToEditor([this.right], locale, false)
          if (!deepEqual(leftData, rightData)) {
            this.localeStatus[locale] = BlockMergeStatus.CONFLICT
          }
        })
      }
    }
  }

  isResolutionRequired(locale: Locale)   {
    const status = this.localeStatus[locale]
    return !!status && [BlockMergeStatus.CONFLICT, BlockMergeStatus.INSERTION].includes(status)
  }

  rejectLeft() {
    if (this.status(Locale.JA) === BlockMergeStatus.INSERTION) {
      this.setStatus(Locale.JA, BlockMergeStatus.REJECTED)
      this.setStatus(Locale.EN, BlockMergeStatus.REJECTED)
    }
  }

  rejectRight() {
    if (this.status(Locale.JA) === BlockMergeStatus.INSERTION) {
      this.setStatus(Locale.JA, BlockMergeStatus.REJECTED)
      this.setStatus(Locale.EN, BlockMergeStatus.REJECTED)
    }
  }

  copyLeft() {
    this.resolved = deepCopy(this.left)
    if (this.status(Locale.JA) === BlockMergeStatus.INSERTION) {
      this.setStatus(Locale.JA, BlockMergeStatus.COPIED)
      this.setStatus(Locale.EN, BlockMergeStatus.COPIED)
    }
  }

  copyRight() {
    this.resolved = deepCopy(this.right)
    if (this.status(Locale.JA) === BlockMergeStatus.INSERTION) {
      this.setStatus(Locale.JA, BlockMergeStatus.COPIED)
      this.setStatus(Locale.EN, BlockMergeStatus.COPIED)
    }
  }

  updateResolved(block: Block) {
    this.resolved = block
  }

  isConflict(locale: Locale) {
    return this.status(locale) === BlockMergeStatus.CONFLICT
  }

  isClone() {
    return this.left?.type === BlockType.CLONE || this.right?.type === BlockType.CLONE
  }

  isOriginalBlockInResult() {
    const sourceId = (this.left as CloneBlock)?.sourceId ?? (this.right as CloneBlock)?.sourceId
    return this.allBlockMergeResults.find(merge => merge.resolved?.id === sourceId)
  }

  status(locale: Locale) {
    return this.localeStatus[locale]
  }

  resolve(locale: Locale) {
    this.localeStatus[locale] = BlockMergeStatus.RESOLVED
  }

  private setStatus(locale: Locale, status: BlockMergeStatus) {
    this.localeStatus[locale] = status
  }

  setAllBlockMergeResults(mergeResults: BlockMergeResult[]) {
    this.allBlockMergeResults = mergeResults
  }
}