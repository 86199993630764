export const scrollToElement = (element: Element) => {
  const offset = -80
  const scrollPosition = element.getBoundingClientRect().top + window.pageYOffset + offset
  window.scrollTo({top: scrollPosition, behavior: 'smooth'})
}

export const scrollToElementWithRetry = (elementLookup: () => Element | null, tries = 10) => {
  const tryScroll = () => {
    const element = elementLookup()

    if (element) {
      scrollToElement(element)
    } else if (!element && tries) {
      tries--
      setTimeout(tryScroll, 100)
    }
  }

  setTimeout(tryScroll, 100)
}

export default scrollToElement