import {ProjectStage, ProjectTimeline} from 'domain/Project'
import {ProjectStageRule} from 'domain/ProjectStageFlow'
import {Role, TRANSLATOR_ROLES} from 'domain/User'
import ProjectService from '../../api/src/services/ProjectService'
import {ProjectAggregate} from 'domain/ProjectAggregate'

type StageRuleOverride = Pick<ProjectStageRule, 'milestone' | 'hours'>

export const stages: Record<ProjectStage, (_: StageRuleOverride) => ProjectStageRule> = {
  [ProjectStage.INTERVIEW]: (overrides: StageRuleOverride) => ({
    stage: ProjectStage.INTERVIEW,
    timeline: ProjectTimeline.INTERVIEWS,
    isProjectAnalystStage: true,
    roles: [Role.ANALYST],
    ...overrides
  }),
  [ProjectStage.INTERVIEW_1]: (overrides: StageRuleOverride) => ({
    stage: ProjectStage.INTERVIEW_1,
    timeline: ProjectTimeline.INTERVIEWS,
    isProjectAnalystStage: true,
    roles: [Role.ANALYST],
    ...overrides
  }),
  [ProjectStage.INTERVIEW_2]: (overrides: StageRuleOverride) => ({
    stage: ProjectStage.INTERVIEW_2,
    timeline: ProjectTimeline.INTERVIEWS,
    isProjectAnalystStage: true,
    roles: [Role.ANALYST],
    ...overrides
  }),
  [ProjectStage.ORIGINAL_WRITING]: (overrides: StageRuleOverride) => ({
    stage: ProjectStage.ORIGINAL_WRITING,
    timeline: ProjectTimeline.ORIGINAL_REPORT,
    isProjectAnalystStage: true,
    roles: [Role.ANALYST],
    ...overrides
  }),
  [ProjectStage.ORIGINAL_SELECT_CONTENT]: (overrides: StageRuleOverride) => ({
    stage: ProjectStage.ORIGINAL_SELECT_CONTENT,
    timeline: ProjectTimeline.ORIGINAL_REPORT,
    isProjectAnalystStage: true,
    roles: [Role.ANALYST],
    ...overrides
  }),
  [ProjectStage.ORIGINAL_REVIEW]: (overrides: StageRuleOverride) => ({
    stage: ProjectStage.ORIGINAL_REVIEW,
    timeline: ProjectTimeline.ORIGINAL_REPORT,
    roles: [Role.ANALYST],
    isProjectAnalystStage: true,
    requiresAdditionalAssistants: true,
    ...overrides
  }),
  [ProjectStage.ORIGINAL_REVIEW1]: (overrides: StageRuleOverride) => ({
    stage: ProjectStage.ORIGINAL_REVIEW1,
    timeline: ProjectTimeline.ORIGINAL_REPORT,
    roles: [Role.ANALYST],
    isProjectAnalystStage: true,
    requiresAdditionalAssistants: true,
    ...overrides
  }),
  [ProjectStage.ORIGINAL_POST_REVIEW_WRITING1]: (overrides: StageRuleOverride) => ({
    stage: ProjectStage.ORIGINAL_POST_REVIEW_WRITING1,
    timeline: ProjectTimeline.ORIGINAL_REPORT,
    isProjectAnalystStage: true,
    roles: [Role.ANALYST],
    ...overrides
  }),
  [ProjectStage.ORIGINAL_REVIEW2]: (overrides: StageRuleOverride) => ({
    stage: ProjectStage.ORIGINAL_REVIEW2,
    timeline: ProjectTimeline.ORIGINAL_REPORT,
    roles: [Role.ANALYST],
    isProjectAnalystStage: true,
    requiresAdditionalAssistants: true,
    ...overrides
  }),
  [ProjectStage.ORIGINAL_POST_REVIEW_WRITING2]: (overrides: StageRuleOverride) => ({
    stage: ProjectStage.ORIGINAL_POST_REVIEW_WRITING2,
    timeline: ProjectTimeline.ORIGINAL_REPORT,
    isProjectAnalystStage: true,
    roles: [Role.ANALYST],
    ...overrides
  }),
  [ProjectStage.ORIGINAL_EDITING_FOR_TRANSLATION]: (overrides: StageRuleOverride) => ({
    stage: ProjectStage.ORIGINAL_EDITING_FOR_TRANSLATION,
    timeline: ProjectTimeline.ORIGINAL_REPORT,
    roles: [Role.ANALYST],
    preAssignedUserEmail: process.env.TRANSLATION_FRIENDLINESS_EDITOR,
    ...overrides
  }),
  [ProjectStage.ORIGINAL_HEAD_OF_RESEARCH_AUTH]: (overrides: StageRuleOverride) => ({
    stage: ProjectStage.ORIGINAL_HEAD_OF_RESEARCH_AUTH,
    timeline: ProjectTimeline.ORIGINAL_REPORT,
    isProjectAnalystStage: true,
    roles: [Role.ANALYST],
    ...overrides
  }),
  [ProjectStage.ORIGINAL_PROOFREADING]: (overrides: StageRuleOverride) => ({
    stage: ProjectStage.ORIGINAL_PROOFREADING,
    timeline: ProjectTimeline.ORIGINAL_REPORT,
    isProjectAnalystStage: true,
    roles: [Role.ANALYST],
    ...overrides
  }),
  [ProjectStage.ORIGINAL_COMPANY_CHECK]: (overrides: StageRuleOverride) => ({
    stage: ProjectStage.ORIGINAL_COMPANY_CHECK,
    timeline: ProjectTimeline.ORIGINAL_REPORT,
    isProjectAnalystStage: true,
    roles: [Role.ANALYST],
    ...overrides
  }),
  [ProjectStage.ORIGINAL_POST_COMPANY_REVIEW_WRITING]: (overrides: StageRuleOverride) => ({
    stage: ProjectStage.ORIGINAL_POST_COMPANY_REVIEW_WRITING,
    timeline: ProjectTimeline.ORIGINAL_REPORT,
    isProjectAnalystStage: true,
    roles: [Role.ANALYST],
    ...overrides
  }),
  [ProjectStage.TRANSLATION_PREPARATION]: (overrides: StageRuleOverride) => ({
    stage: ProjectStage.TRANSLATION_PREPARATION,
    timeline: ProjectTimeline.TRANSLATED_REPORT,
    roles: [Role.TRANSLATOR_COORDINATOR],
    validateBeforeEnter: async (project: ProjectAggregate, projectService: ProjectService) => {
      return projectService.onTranslationPreparationValidate(project)
    },
    onEnter: async (project: ProjectAggregate, projectService: ProjectService) => {
      return projectService.onTranslationPreparationEnter(project)
    },
    ...overrides
  }),
  [ProjectStage.TRANSLATION_WRITING]: (overrides: StageRuleOverride) => ({
    stage: ProjectStage.TRANSLATION_WRITING,
    timeline: ProjectTimeline.TRANSLATED_REPORT,
    roles: TRANSLATOR_ROLES,
    validateBeforeEnter: async (project: ProjectAggregate, projectService: ProjectService) => {
      return projectService.onTranslationValidate(project)
    },
    ...overrides
  }),
  [ProjectStage.TRANSLATION_OZ_CHECK]: (overrides: StageRuleOverride) => ({
    stage: ProjectStage.TRANSLATION_OZ_CHECK,
    timeline: ProjectTimeline.TRANSLATED_REPORT,
    isProjectAnalystStage: true,
    roles: [Role.ANALYST],
    ...overrides
  }),
  [ProjectStage.TRANSLATION_REVIEW]: (overrides: StageRuleOverride) => ({
    stage: ProjectStage.TRANSLATION_REVIEW,
    timeline: ProjectTimeline.TRANSLATED_REPORT,
    roles: [Role.EDITOR],
    ...overrides
  }),
  [ProjectStage.TRANSLATION_COMPANY_CHECK]: (overrides: StageRuleOverride) => ({
    stage: ProjectStage.TRANSLATION_COMPANY_CHECK,
    timeline: ProjectTimeline.TRANSLATED_REPORT,
    isProjectAnalystStage: true,
    roles: [Role.ANALYST],
    ...overrides
  }),
  [ProjectStage.PUBLISH]: (overrides: StageRuleOverride) => ({
    stage: ProjectStage.PUBLISH,
    timeline: ProjectTimeline.PUBLISHING,
    isProjectAnalystStage: true,
    roles: [Role.ANALYST],
    onEnter: async (project: ProjectAggregate, projectService: ProjectService) => {
      projectService.onPublishEnter(project)
    },
    ...overrides
  })
}
