import {ProjectStage} from 'domain/Project'
import {Translator} from 'domain/User'
import React, {ReactElement, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {getProjectTasks} from '../../../api-clients/projectTaskClient'
import CompanyButton from '../../companies/CompanyButton'
import UserButton from '../../user/UserButton'
import FormattedDate from '../../utils/FormattedDate'
import Spinner from '../../utils/Spinner'
import TranslatorAssignmentProfile from '../TranslatorAssignmentProfile'
import {ProjectAggregate} from 'domain/ProjectAggregate'

interface Props {
  project: ProjectAggregate
  selectedTranslator: Translator | null
}

const TranslatorAssignmentProjectPane: React.FC<Props> = ({project, selectedTranslator}): ReactElement => {
  const {t} = useTranslation()
  const [translationWritingTaskEndDate, setTranslationWritingTaskEndDate] = useState<Date | null>(null)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    getProjectTasks(project._id)
      .then(tasks => {
        const translationWritingTask = tasks.find(task => task.stage === ProjectStage.TRANSLATION_WRITING)
        if (translationWritingTask) {
          setTranslationWritingTaskEndDate(new Date(translationWritingTask.endDate))
        }
      })
      .finally(() => {
        setLoading(false)
      })
  }, [])

  return (
    <div className="pane flex-grow-0">
      <div className="pane-header">
        <div className="pane-title">{t('components.TranslatorAssignmentForm.project')}</div>
      </div>
      <div className="pane-body">
        {loading ? (
          <div className="d-flex justify-content-center">
            <Spinner />
          </div>
        ) : (
          <>
            <h5>{project.title}</h5>
            <div className="data-list data-list-wrappable">
              <dl>
                <dt>{t('components.TranslatorAssignmentForm.company')}</dt>
                <dd>
                  <CompanyButton company={project.company} buttonClass="btn-outline-secondary me-1" />
                </dd>
              </dl>
              <dl>
                <dt>{t('components.TranslatorAssignmentForm.analyst')}</dt>
                <dd>
                  {project.analyst && <UserButton user={project.analyst} buttonClass="btn-outline-secondary mr-1" />}
                </dd>
              </dl>
              <dl>
                <dt>{t('components.TranslatorAssignmentForm.deadline')}</dt>
                <dd>
                  {translationWritingTaskEndDate && (
                    <strong>
                      <FormattedDate date={translationWritingTaskEndDate} format={t('dateTimeFormat')}/>
                    </strong>
                  )}
                </dd>
              </dl>
            </div>
            {selectedTranslator && <TranslatorAssignmentProfile translator={selectedTranslator} />}
          </>
        )}
      </div>
    </div>
  )
}

export default TranslatorAssignmentProjectPane
