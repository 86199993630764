import {CommentWithUserName} from 'domain/Comment'
import React, {ReactNode, useContext, useMemo, useState} from 'react'
import {CommentContext} from './CommentProvider'


type Coordinates = { x: number, y: number }

type InlineCommentContextType = {
  inlineComment: CommentWithUserName | null
  closeInlineComment: () => void
  openInlineComment: (id: string, coordinates: Coordinates | null) => void
  coordinates: Coordinates | null
}

export const InlineCommentContext = React.createContext({} as InlineCommentContextType)

const InlineCommentProvider = ({children}: { children: ReactNode | ReactNode[] }) => {
  const {comments} = useContext(CommentContext)
  const [inlineCommentId, setInlineCommentId] = useState<string | null>(null)
  const [coordinates, setCoordinates] = useState<Coordinates | null>(null)

  const inlineComment = useMemo(() => {
    return (comments || []).find(comment => comment._id === inlineCommentId) || null
  }, [comments, inlineCommentId])

  const closeInlineComment = () => {
    setInlineCommentId(null)
    setCoordinates(null)
  }

  const openInlineComment = (id: string, coordinates: Coordinates | null) => {
    setInlineCommentId(id)
    setCoordinates(coordinates)
  }

  const state = {
    inlineComment,
    closeInlineComment,
    openInlineComment,
    coordinates
  }

  return (
    <InlineCommentContext.Provider value={state}>
      {children}
    </InlineCommentContext.Provider>
  )
}

export default InlineCommentProvider