import {BookmarkBlock} from 'domain/Report'
import React, {ReactElement} from 'react'


const Bookmark: React.FC<{ block: BookmarkBlock }> = ({block}): ReactElement => {
  return (
    <div className="bookmark">
      <div className="dash" />
      <span>{block.title}</span>
    </div>
  )
}

export default Bookmark
