import {Id} from 'domain/Entity'
import {Holiday} from 'domain/Holiday'
import apiClient from '../ApiClient'


const getHolidays = async (): Promise<Holiday[]> => {
  return await apiClient.get('/api/holidays')
}

const deleteHoliday = async (holidayId: Id): Promise<Holiday[]> => {
  return await apiClient.delete(`/api/holidays/${holidayId}`)
}

const createHoliday = async (date: string): Promise<Holiday> => {
  return await apiClient.post('/api/holidays', {date})
}

export {
  getHolidays,
  deleteHoliday,
  createHoliday
}