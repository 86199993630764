import {Holiday} from 'domain/Holiday'
import {AggregatedTask, TaskStatus} from 'domain/Task'
import {getBusinessDates, isWithinDates} from 'domain/tasks/taskScheduleUtils'
import moment from 'moment-timezone'
import React, {useCallback, useContext, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {getHolidays} from '../../api-clients/holidayClient'
import {getCurrentUserOverdueTasks, getCurrentUserTasks} from '../../api-clients/taskClient'
import EventBus, {EventType} from '../../EventBus'
import {LocaleContext} from '../../providers/LocaleProvider'
import {relativeDate} from '../../utils/momentUtils'
import Pane from '../utils/Pane'
import ProjectTaskCard from './ProjectTaskCard'


const SchedulePage: React.FC = () => {
  const {t} = useTranslation()
  const [tasks, setTasks] = useState<AggregatedTask[]>([])
  const [overdueTasks, setOverdueTasks] = useState<AggregatedTask[]>([])
  const [holidays, setHolidays] = useState<Holiday[]>([])
  const {locale} = useContext(LocaleContext)

  const displayedDays = 5
  const dates = getBusinessDates(new Date(), displayedDays, [])
  const startDate = dates[0]!.start
  const endDate = dates.last()!.end

  useEffect(() => {
    loadTasks()
    getHolidays().then(setHolidays)
    EventBus.on(EventType.TASK_RESCHEDULED, loadTasks)

    return () => {
      EventBus.unsubscribe(EventType.TASK_RESCHEDULED, loadTasks)
    }
  }, [])

  const loadTasks = useCallback(() => {
    getCurrentUserTasks(startDate, endDate).then(setTasks)
    getCurrentUserOverdueTasks(startDate).then(setOverdueTasks)
  }, [])

  return <div className="panes panes-fill-height">
    <div className="panes-horizontal-scoller">
      {dates.map(({start, end}, columnIndex) => {
        const rangeTasks = tasks.filter(t => {
          return isWithinDates(t.startDate, t.endDate, start, end)
        })

        const finishedTasks = rangeTasks.filter(task => task.status === TaskStatus.FINISHED)
        const unfinishedTasks = rangeTasks.filter(task => task.status !== TaskStatus.FINISHED)

        if (columnIndex === 0) unfinishedTasks.unshift(...overdueTasks)

        const isHoliday = holidays.find(h => h.date === moment(start).format('YYYY-MM-DD'))
        const title = relativeDate(start, locale)

        const paneTitle = <>
          {title}
          {isHoliday &&
            <div className="badge bg-success rounded-pill ms-1">{t('components.SchedulePage.holiday')}</div>
          }
        </>
        return <Pane title={paneTitle} key={title}>
          <>
            {finishedTasks.map(task => <ProjectTaskCard task={task} onUpdated={loadTasks}
                                                        key={`${task._id}-${columnIndex}`}
                                                        isContinuing={moment(task.startDate).isBefore(start)}/>)}

            {unfinishedTasks.map(task => <ProjectTaskCard task={task} onUpdated={loadTasks}
                                                          key={`${task._id}-${columnIndex}`}
                                                          isContinuing={moment(task.startDate).isBefore(start)}/>)}
          </>
        </Pane>
      })}
    </div>
  </div>
}

export default SchedulePage