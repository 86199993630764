import React, {ReactNode, useEffect, useState} from 'react'
import EventBus, {EventType} from '../EventBus'


export interface DiffDeletionsState {
  isDeletionVisible: boolean
  toggleDeletionVisibility: () => void,
  onApplyDeletionVisibility: () => void
}

export const DiffDeletionsContext = React.createContext<DiffDeletionsState>({} as DiffDeletionsState)

const DiffDeletionsProvider = ({children}: { children: ReactNode | ReactNode[] }) => {
  const [isDeletionVisible, setDeletionVisible] = useState(true)

  useEffect(() => {
    onApplyDeletionVisibility()
  }, [])

  const toggleDeletionVisibility = () => {
    setDeletionVisible(!isDeletionVisible)
    applyDeletionVisibility(isDeletionVisible)
  }

  const onApplyDeletionVisibility = () => {
    applyDeletionVisibility(!isDeletionVisible)
  }

  const applyDeletionVisibility = (isDeletionVisible: boolean) => {
    Array.from(document.getElementsByTagName('DEL')).forEach(el => {
      if (!isDeletionVisible) el.classList.remove('d-none')
      else el.classList.add('d-none')
    })

    EventBus.emit(EventType.HTML_DIFF_UPDATED, {isDeletionVisible: !isDeletionVisible})
  }

  const providerState = {
    isDeletionVisible,
    toggleDeletionVisibility,
    onApplyDeletionVisibility
  }

  return (
    <DiffDeletionsContext.Provider value={providerState}>
      {children}
    </DiffDeletionsContext.Provider>
  )
}

export default DiffDeletionsProvider