import {ExternalTranslator, hasRoles, Role, Translator} from 'domain/User'
import React, {ReactElement} from 'react'
import {useTranslation} from 'react-i18next'


interface TranslatorAssignmentProfileProps {
  translator: Translator
}

const TranslatorAssignmentProfile: React.FC<TranslatorAssignmentProfileProps> = ({translator}): ReactElement => {
  const {t} = useTranslation()
  return <>
    <h6 className="card-title">{t('components.TranslatorAssignmentProfile.translator')}</h6>
    <div className="card">
      <div className="card-body p-3">
        <div className="d-flex justify-content-between">
          <div>
            <h6>{translator.name}</h6>
            <div className="small"><a href={`mailto:${translator.email}`}>{translator.email}</a></div>
            <div className="small"><a href={`tel:${translator.phone}`}>{translator.phone}</a></div>
            {hasRoles(translator, Role.EXTERNAL_TRANSLATOR) && (translator as ExternalTranslator).ratePerCharacter && (
              <div className="small" dangerouslySetInnerHTML={
                {__html: t('components.TranslatorAssignmentProfile.rate',
                    {rate: (translator as ExternalTranslator).ratePerCharacter})}
              }/>)}
          </div>
          {translator.tier && <div className="d-flex flex-column">
            <div className="badge bg-success p-2 rounded-pill mb-2">
              {t('models.User.tier')} {translator.tier}
            </div>
          </div>}
        </div>
      </div>
      <div className="card-body small p-3 pb-1">
        <div className="mb-2">
          {translator.notes}
        </div>
      </div>
    </div>
  </>
}

export default TranslatorAssignmentProfile