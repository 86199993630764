import {Company} from 'domain/Company'
import {Entity, Id} from 'domain/Entity'
import {Project} from 'domain/Project'


export interface ProjectNotification extends Entity {
  userId: Id
  projectId: Id
  type: ProjectNotificationType
  isRead?: boolean
  isNotifiedByEmail?: boolean
  params?: any
}

export interface AggregatedProjectNotification extends ProjectNotification {
  companyTick: Company['tick']
  projectTitle: Project['title']
}

export enum ProjectNotificationType {
  COMMENT_CREATE = 'COMMENT_CREATE',
  COMMENT_REPLY = 'COMMENT_REPLY',
  TRANSLATION_REQUEST_CREATE = 'TRANSLATION_REQUEST_CREATE',
  TRANSLATION_REQUEST_ACCEPT = 'TRANSLATION_REQUEST_ACCEPT',
  TRANSLATION_REQUEST_REJECT = 'TRANSLATION_REQUEST_REJECT',
  TRANSLATION_REQUEST_CANCEL = 'TRANSLATION_REQUEST_CANCEL',
  PROJECT_ASSIGN = 'PROJECT_ASSIGN',
  PROJECT_UNASSIGN = 'PROJECT_UNASSIGN',
  PROJECT_TASK_START = 'PROJECT_TASK_START',
  PROJECT_TASK_ASSIGN = 'PROJECT_TASK_ASSIGN',
  PROJECT_TASK_UNASSIGN = 'PROJECT_TASK_UNASSIGN',
  PROJECT_TASK_RESCHEDULE = 'PROJECT_TASK_RESCHEDULE',
}