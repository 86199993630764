import {Locale} from 'domain/Locale'
import {Block, BlockType} from 'domain/Report'
import React from 'react'
import ReadonlyBlocks from '../view/ReadonlyBlocks'
import {ToolbarSide} from './BlockMergeToolbar'
import {SectionMergeResult} from './ReportSectionsMerger'


interface ReadonlyBlockProps {
  mergeResults: SectionMergeResult[]
  block: Block
  locale: Locale
  side?: ToolbarSide
}

const ReadonlyBlock: React.FC<ReadonlyBlockProps> = props => {
  const {mergeResults, block, locale, side} = props
  const cloneOrigin = (): Block | undefined => {
    if (!block || block.type !== BlockType.CLONE) {
      return
    }
    return mergeResults.flatMap(s => s.mergedBlocks).map(({left, right, resolved}) => {
      const candidate = side ? side === ToolbarSide.RIGHT ? right : left : resolved

      if (block?.type === BlockType.CLONE && candidate?.id === block.sourceId) {
        return {...candidate, id: block.id}
      }
    }).find(Boolean)
  }
  return block ?
    <ReadonlyBlocks blocks={[cloneOrigin() ?? block]} locale={locale} displayBookmarks={true}/> : <></>
}

export default ReadonlyBlock