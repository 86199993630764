import {EditorListBlock} from 'domain/Editor'
import {Locale} from 'domain/Locale'
import {ListBlock} from 'domain/Report'
import Converter from './Converter'


class ListConverter extends Converter<ListBlock, EditorListBlock> {
  toEditor(block: ListBlock, locale: Locale): EditorListBlock {
    const {id, type, items, ...other} = block
    return {id, type, data: {...other, items: items[locale]?.value || []}}
  }

  fromEditor(editorBlock: EditorListBlock, locale: Locale) {
    const {id, type, data} = editorBlock
    const {items, ...others} = data
    return {
      id, type,
      items: {
        [locale]: {
          value: items
        }
      },
      ...others
    }
  }
}

export default new ListConverter()