import {useEffect} from 'react'

const TIMEOUT_MS = 10000
const ALLOWED_DELAY_MS = 120000

export const usePageReloadAfterSleep = () => {
  useEffect(() => {
    let lastTime = new Date().getTime()

    const intervalId = setInterval(() => {
      const currentTime = new Date().getTime()
      if (currentTime > lastTime + TIMEOUT_MS + ALLOWED_DELAY_MS) {
        window.location.reload()
      }
      lastTime = currentTime
    }, TIMEOUT_MS)

    return () => {
      clearInterval(intervalId)
    }
  }, [])
}
