import {Locale} from 'domain/Locale'
import {PageType} from 'domain/PageType'
import {ReportRevision, ReportState} from 'domain/Report'
import {BlockDiffResult} from 'domain/report-diff/BlockDiffResult'
import {BlocksDiffer} from 'domain/report-diff/BlocksDiffer'
import React, {useContext, useEffect, useMemo, useState} from 'react'
import {getReportAtRevision, getReportRevisions} from '../../api-clients/reportClient'
import EventBus, {EventType} from '../../EventBus'
import useCurrentLocationVisibilityInSidebar from '../../hooks/useCurrentLocationVisibilityInSidebar'
import {useProjectDocumentTitle} from '../../hooks/useProjectDocumentTitle'
import DiffDeletionsProvider from '../../providers/DiffDeletionsProvider'
import {ReportContext} from '../../providers/ReportProvider'
import {withProviders} from '../../providers/utils'
import Spinner from '../utils/Spinner'
import LanguageSwitcher from './LanguageSwitcher'
import Sidebar from './navigation/Sidebar'
import ReportNavigation from './report-navigation/ReportNavigation'
import ReportRevisionDiff from './ReportRevisionDiff'
import RevisionHistorySidebar from './revisions/RevisionHistorySidebar'
import ShowIf, {notNewsUpdateType} from './ShowIf'

const RevisionsPreviewPage: React.FC = () => {
  const {project, company, reportStore} = useContext(ReportContext)
  const [selectedRevisionIdx, setSelectedRevisionIdx] = useState<number>(0)
  const [compareToRevisionIdx, setCompareToRevisionIdx] = useState<number | null>(null)
  const [selectedReport, setSelectedReport] = useState<ReportState | null>(null)
  const [reportLocale, setReportLocale] = useState<Locale>(Locale.JA)
  const [revisions, setRevisions] = useState<ReportRevision[]>([])
  const [loading, setLoading] = useState<boolean>(true)
  const [blocksDiffs, setBlocksDiffs] = useState<BlockDiffResult[]>([])
  const localeRevisions = useMemo(() => {
    return revisions.filter(revision => !revision.locale || revision.locale === reportLocale)
  }, [revisions, reportLocale])
  useCurrentLocationVisibilityInSidebar('[data-section]', [blocksDiffs])
  useProjectDocumentTitle(project, PageType.REVISIONS)

  const calculateDiffBetweenRevisions = (curRevisionIdx: number, prevRevisionIdx: number) => {
    setLoading(true)

    const currentRevision = localeRevisions[curRevisionIdx]
    const previousRevision = localeRevisions[prevRevisionIdx]

    const currentReportPromise = getReportAtRevision(project!._id, currentRevision._id)
    const previousReportPromise: Promise<ReportState | null> = previousRevision
      ? getReportAtRevision(project!._id, previousRevision._id)
      : Promise.resolve(null)

    Promise.all([currentReportPromise, previousReportPromise]).then(([currentReport, previousReport]) => {
      setSelectedReport(previousReport)
      const currentSections = currentReport!.sections
      const previousSections = previousReport?.sections ?? []
      const reportBlocksDiffer = new BlocksDiffer(previousSections, currentSections)
      const blocksDiffs = reportBlocksDiffer.diff()
      setBlocksDiffs(blocksDiffs)
      setLoading(false)
    })
  }

  useEffect(() => {
    setTimeout(() => EventBus.emit(EventType.HTML_DIFF_UPDATED), 0)
  }, [blocksDiffs])

  useEffect(() => {
    getReportRevisions(project._id).then(revisions => {
      revisions.sort(revision => -new Date(revision.createdAt).getTime())
      setRevisions(revisions)
      setSelectedRevisionIdx(0)
    })
  }, [])

  useEffect(() => {
    if (
      selectedRevisionIdx === null ||
      project === null ||
      localeRevisions.length === 0 ||
      !localeRevisions[selectedRevisionIdx]
    ) {
      return
    }

    setTimeout(() => {
      const revisionsToCompare = [selectedRevisionIdx, compareToRevisionIdx ?? selectedRevisionIdx]
      revisionsToCompare.sort()
      calculateDiffBetweenRevisions(revisionsToCompare[0], revisionsToCompare[1] + 1)
    }, 0)
  }, [selectedRevisionIdx, compareToRevisionIdx, project, localeRevisions])

  useEffect(() => {
    setSelectedRevisionIdx(0)
    setCompareToRevisionIdx(null)
  }, [reportLocale])

  const setLocaleAndScrollTop = (locale: Locale) => {
    setReportLocale(locale)
    window.scrollTo(0, 0)
  }

  return (
    <div>
      <div className="navbar navbar-secondary">
        {company && project && (
          <ReportNavigation
            titleKey={'components.Report.RevisionsPreview.title'}
            project={project}
            company={company}
            locale={reportLocale}
          >
            <div className="ms-2">
              <LanguageSwitcher activeLocale={reportLocale} setLocale={setLocaleAndScrollTop} />
            </div>
          </ReportNavigation>
        )}
      </div>

      <div className="report-content-wrapper">
        {selectedReport && (
          <ShowIf rule={notNewsUpdateType}>
            <Sidebar report={selectedReport} locale={reportLocale} displayMenu={false} displayNewsIfEmpty={false} />
          </ShowIf>
        )}

        <div className="report-content report-output flex-grow-1">
          {blocksDiffs.length > 0 && (
            <ReportRevisionDiff
              reportState={reportStore.getReport()}
              blockDiffs={blocksDiffs}
              locale={reportLocale}
              wordCountExcludedBlockIds={project?.wordCountExcludedBlockIds}
            />
          )}
          {loading && <Spinner withDimmer />}
        </div>
        {project && (
          <RevisionHistorySidebar
            locale={reportLocale}
            localeRevisions={localeRevisions}
            revisions={revisions}
            selectedRevisionIdx={selectedRevisionIdx}
            setSelectedRevisionIdx={setSelectedRevisionIdx}
            reportLocale={reportLocale}
            compareToRevisionIdx={compareToRevisionIdx}
            setCompareToRevisionIdx={setCompareToRevisionIdx}
            setRevisions={setRevisions}
            project={project}
          />
        )}
      </div>
    </div>
  )
}

export default withProviders([DiffDeletionsProvider], RevisionsPreviewPage)
