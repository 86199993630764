import {StatusCodes} from 'http-status-codes'


const {CONFLICT} = StatusCodes

const handleApiErrors = (conflictMessage: string, onError: (status: StatusCodes) => void) => {
  return (error: any) => {
    if (error.status === CONFLICT) {
      alert(conflictMessage)
      location.reload()
    } else {
      onError(error.status)
    }
  }
}

export default handleApiErrors