import {hasProjectAdminAccess} from 'domain/access'
import {Permission} from 'domain/Permission'
import {ProjectStage, REVIEW_STAGES} from 'domain/Project'
import {ProjectTaskWithUsers} from 'domain/Task'
import {TranslationRequestStatus} from 'domain/TranslationRequest'
import {isTranslatorCoordinator, User} from 'domain/User'
import {ReactComponent as DeleteIcon} from '../../../assets/icons/delete.svg'
import {ReactComponent as DoneIcon} from '../../../assets/icons/done.svg'
import {ReactComponent as PersonPlusIcon} from '../../../assets/icons/person_plus.svg'
import React, {useContext, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {acceptTranslationRequest} from '../../../api-clients/translationRequestClient'
import {getUser} from '../../../api-clients/userClient'
import EventBus, {EventType} from '../../../EventBus'
import useModal from '../../../hooks/useModal'
import useToast from '../../../hooks/useToast'
import {UserContext} from '../../../providers/UserProvider'
import ShowIf, {notNewsUpdateType} from '../../report/ShowIf'
import AcceptedTranslationRequestRejectModal from '../../translations-dashboard/AcceptedTranslationRequestRejectModal'
import TranslationRequestCancelModal from '../../translations-dashboard/TranslationRequestCancelModal'
import TranslationRequestRejectModal from '../../translations-dashboard/TranslationRequestRejectModal'
import TranslatorAssignmentForm from '../../translations-dashboard/TranslatorAssignmentForm'
import UserButton from '../../user/UserButton'
import Protected from '../../utils/Protected'
import SetProjectAnalysts from '../SetProjectAnalysts'
import SetProjectReviewers from '../SetProjectReviewers'
import ChangeTasksDatesButton from '../tasks/ChangeTasksDatesButton'
import ImportFromProjectButton from '../tasks/ImportFromProjectButton'
import OriginalImportButton from '../tasks/OriginalImportButton'
import TaskAssignButton from '../tasks/TaskAssignButton'
import TranslationImportButton from '../tasks/TranslationImportButton'
import {ProjectAggregate} from 'domain/ProjectAggregate'


interface Props {
  project: ProjectAggregate
  projectTasks: ProjectTaskWithUsers[]
  loadProjectWithTasks: () => void
}

const PreparationTimelinePhase: React.FC<Props> = props => {
  const {project, projectTasks, loadProjectWithTasks} = props
  const {t} = useTranslation()
  const {user} = useContext(UserContext)
  const {showModal} = useModal()
  const {renderSuccessToast} = useToast()
  const lastTranslationRequest = project.translationRequests.last()
  const [translator, setTranslator] = useState<User | null>(null)

  const translationTask = projectTasks.find(task => task.stage === ProjectStage.TRANSLATION_WRITING)
  const editorTask = projectTasks.find(task => task.stage === ProjectStage.TRANSLATION_REVIEW)
  const reviewTasks = projectTasks.filter(task => REVIEW_STAGES.includes(task.stage))

  const reviewers = reviewTasks.flatMap(t => t.assistantUsers || [])
    .subtract(project.assistantAnalysts, user => user._id).uniqueBy(u => u._id)

  const onTranslationRequestChange = () => {
    loadProjectWithTasks()
    EventBus.emit(EventType.REFRESH_PROJECT_LOGS)
  }

  const openTranslationAssignmentForm = (e: React.MouseEvent) => {
    e.preventDefault()
    e.stopPropagation()
    showModal(<TranslatorAssignmentForm project={project} onAssigned={onTranslationRequestChange}/>)
  }

  useEffect(() => {
    if (lastTranslationRequest?.translatorId) {
      getUser(lastTranslationRequest.translatorId, true).then(setTranslator)
    }
  }, [project])

  const onTranslationRequestAccept = async (e: React.MouseEvent) => {
    e.preventDefault()
    e.stopPropagation()

    if (!lastTranslationRequest) return
    acceptTranslationRequest(lastTranslationRequest._id).then(() => {
      onTranslationRequestChange()
      renderSuccessToast(t('components.PendingConfirmationCard.accepted'))
    })
  }

  const onTranslationRequestReject = (e: React.MouseEvent) => {
    e.preventDefault()
    e.stopPropagation()

    if (!lastTranslationRequest) return
    showModal(<TranslationRequestRejectModal translationRequest={lastTranslationRequest}
                                             onRejected={onTranslationRequestChange}/>)
  }

  const onAssignAnalystModalOpen = (e: React.MouseEvent) => {
    e.preventDefault()

    showModal(<SetProjectAnalysts project={project} onUpdate={loadProjectWithTasks}/>)
  }

  const onAssignReviewersModalOpen = (e: React.MouseEvent) => {
    e.preventDefault()
    showModal(<SetProjectReviewers project={project} onUpdate={loadProjectWithTasks} tasks={reviewTasks}/>)
  }

  const onAcceptedTranslationRequestReject = (e: React.MouseEvent) => {
    e.preventDefault()
    e.stopPropagation()

    if (!lastTranslationRequest) return
    showModal(<AcceptedTranslationRequestRejectModal
      translationRequest={lastTranslationRequest} onRejected={onTranslationRequestChange}/>)
  }

  const openTranslationCancelForm = (e: React.MouseEvent) => {
    e.preventDefault()
    e.stopPropagation()
    if (!lastTranslationRequest) return
    showModal(<TranslationRequestCancelModal translationRequest={lastTranslationRequest}
                                             onCanceled={onTranslationRequestChange}/>)
  }

  const isWaitingForTranslatorResponse = lastTranslationRequest?.status === TranslationRequestStatus.PENDING
  const isOwnTranslationRequest = lastTranslationRequest?.translatorId === user._id

  const canCancelTranslationRequest = lastTranslationRequest?.status === TranslationRequestStatus.ACCEPTED
    && project.activeTask && [
      ProjectStage.TRANSLATION_REVIEW, ProjectStage.TRANSLATION_COMPANY_CHECK, ProjectStage.PUBLISH
    ].indexOf(project.activeTask.stage) < 0

  return <div className={`stepper-step stepper-step-success`}>
    <div className="stepper-step-heading">
      <div className="stepper-step-title">
        <span className="stepper-step-title-icon">1</span>
        {t(`components.PreparationTimelinePhase.title`)}
      </div>
    </div>
    <div className="stepper-step-body">
      <div className="stepper-step-body-wrapper">
        {hasProjectAdminAccess(user, project) && <div className="card mb-2">
          <div className="card-body px-2 py-1 d-flex align-items-center justify-content-between">
            <div className="d-flex small">
              <label className="custom-control-label">
                {t('components.PreparationTimelinePhase.schedulePlanning')}
              </label>
            </div>
            <div>
              <ChangeTasksDatesButton project={project} tasks={projectTasks} onUpdated={loadProjectWithTasks}/>
            </div>
          </div>
        </div>}
        <div className="card mb-2">
          <div className="card-body px-2 py-1 d-flex align-items-center justify-content-between">
            <div className="d-flex small">
              <label className="custom-control-label">
                {t(`components.PreparationTimelinePhase.assignAnalyst`)}
              </label>
              {project.analystId && <DoneIcon className="icon icon-sm text-success ms-1"/>}
            </div>
            <div>
              {project.analyst && <UserButton user={project.analyst} buttonClass="btn-outline-success ms-1"/>}
              {project.assistantAnalysts.map(assistant => {
                return <UserButton user={assistant} buttonClass="btn-outline-secondary ms-1"
                                   key={assistant._id as string}/>
              })}
              <Protected permission={Permission.Project.EDIT_SETTINGS}>
                <button className="btn btn-xs btn-outline-primary btn-with-icon ms-1"
                        onClick={onAssignAnalystModalOpen}>
                  <PersonPlusIcon className="icon-xs"/>
                  <span>
                    {project.analystId ?
                      t(`components.PreparationTimelinePhase.reassign`) :
                      t(`components.PreparationTimelinePhase.assign`)}
                  </span>
                </button>
              </Protected>
            </div>
          </div>

          <div className="card-body px-2 py-1 d-flex align-items-center justify-content-between">
            <div className="d-flex small">
              <label className="custom-control-label">
                {t(`components.PreparationTimelinePhase.assignReviewers`)}
              </label>
              {reviewers.length > 0 && <DoneIcon className="icon icon-sm text-success ms-1"/>}
            </div>
            <div>
              {reviewers.map(user => {
                return <UserButton user={user} buttonClass="btn-outline-secondary ms-1" key={user._id as string}/>
              })}
              <Protected permission={Permission.Project.EDIT_SETTINGS}>
                <button className="btn btn-xs btn-outline-primary btn-with-icon ms-1"
                        onClick={onAssignReviewersModalOpen}>
                  <PersonPlusIcon className="icon-xs"/>
                  <span>
                    {reviewers.length > 0 ?
                      t(`components.PreparationTimelinePhase.reassign`) :
                      t(`components.PreparationTimelinePhase.assign`)}
                  </span>
                </button>
              </Protected>
            </div>
          </div>

          <div className="card-body px-2 py-1 d-flex align-items-center justify-content-between">
            <div className="d-flex small">
              <label className="custom-control-label">
                {t(`components.PreparationTimelinePhase.assignTranslator`)}
              </label>
              {translationTask?.user && <DoneIcon className="icon icon-sm text-success ms-1"/>}
            </div>
            <div>
              {isWaitingForTranslatorResponse && !isOwnTranslationRequest && (
                <div className="alert alert-warning mb-0 text-left me-n1 small p-2 mt-0">
                  {translator && t('components.PreparationTimelinePhase.waitingForTranslatorNameConfirmation', {
                    name: translator.name
                  })}
                  {!translator && t(`components.PreparationTimelinePhase.waitingForTranslatorConfirmation`)}
                </div>
              )}

              {canCancelTranslationRequest && isOwnTranslationRequest && (
                <button className="btn btn-xs btn-outline-danger"
                        onClick={onAcceptedTranslationRequestReject}>
                  <span>{t(`components.PreparationTimelinePhase.reject`)}</span>
                </button>
              )}

              <Protected permission={Permission.Project.ASSIGN_TRANSLATOR}>
                {!isWaitingForTranslatorResponse && !translationTask?.userId ? (
                    <button className="btn btn-xs btn-outline-primary btn-with-icon"
                            onClick={openTranslationAssignmentForm}>
                      <PersonPlusIcon className="icon-xs"/>
                      <span>{t(`components.PreparationTimelinePhase.assign`)}</span>
                    </button>) :
                  <></>
                }
              </Protected>

              <Protected permission={Permission.Project.ASSIGN_TRANSLATOR}>
                {isTranslatorCoordinator(user.roles) && (
                  canCancelTranslationRequest || isWaitingForTranslatorResponse
                ) ? (
                  <button className="btn btn-xs btn-outline-danger btn-with-icon"
                          onClick={openTranslationCancelForm}>
                    <DeleteIcon className="icon-xs"/>
                    <span>{t(`components.PreparationTimelinePhase.cancel`)}</span>
                  </button>
                ) : <></>}
              </Protected>

              {isWaitingForTranslatorResponse && isOwnTranslationRequest && (
                <div className="alert alert-warning mb-0 text-left me-n1 mt-0">
                  <div className="small fw-bold mb-2">{t(`components.PreparationTimelinePhase.doYouAccept`)}</div>
                  <div className="d-flex justify-content-end">
                    <button className="btn btn-sm btn-success me-1" onClick={onTranslationRequestAccept}>
                      <span>{t(`components.PreparationTimelinePhase.accept`)}</span>
                    </button>
                    <button className="btn btn-sm btn-danger" onClick={onTranslationRequestReject}>
                      <span>{t(`components.PreparationTimelinePhase.reject`)}</span>
                    </button>
                  </div>
                </div>
              )}

              {translationTask?.user && (
                <UserButton user={translationTask?.user} buttonClass="btn-outline-success ms-1"/>
              )}
            </div>
          </div>
          <div className="card-body px-2 py-1 d-flex align-items-center justify-content-between">
            <div className="d-flex small">
              <label className="custom-control-label">
                {t(`components.PreparationTimelinePhase.assignEditor`)}
              </label>
              {editorTask?.user && <DoneIcon className="icon icon-sm text-success ms-1"/>}
            </div>
            <div>
              {editorTask?.user && <UserButton user={editorTask?.user} buttonClass="btn-outline-success ms-1"/>}
              {editorTask && <TaskAssignButton task={editorTask} onAssigned={loadProjectWithTasks}
                                               projectAssistants={project.assistantAnalysts}/>}
            </div>
          </div>
        </div>

        <Protected permission={Permission.Report.UPDATE}>
          <div className="card mb-2">
            <ShowIf rule={notNewsUpdateType} project={project}>
              <>
                <div className="card-body px-2 py-1 d-flex align-items-center justify-content-between">
                  <div className="small">
                    <label className="custom-control-label">
                      {t(`components.PreparationTimelinePhase.importOriginal`)}
                    </label>
                  </div>
                  <div>
                    <OriginalImportButton project={project}/>
                  </div>
                </div>
                <div className="card-body px-2 py-1 d-flex align-items-center justify-content-between">
                  <div className="small">
                    <label className="custom-control-label">
                      {t(`components.PreparationTimelinePhase.importTranslation`)}
                    </label>
                  </div>
                  <div>
                    <TranslationImportButton project={project}/>
                  </div>
                </div>
              </>
            </ShowIf>
            <div className="card-body px-2 py-1 d-flex align-items-center justify-content-between">
              <div className="small">
                <label className="custom-control-label">
                  {t(`components.PreparationTimelinePhase.importFromAnotherProject`)}
                </label>
              </div>
              <div>
                <ImportFromProjectButton project={project}/>
              </div>
            </div>
          </div>
        </Protected>
      </div>
    </div>
  </div>
}

export default PreparationTimelinePhase