import borderIcon from '../../../assets/inline-svg/border_all.svg'
import equalColumnWidthIcon from '../../../assets/inline-svg/view_column.svg'
import {API} from '@editorjs/editorjs'
import {EditorGridBlockData} from 'domain/Editor'
import {make} from '../dom'


class Tunes {
  buttons: HTMLElement[] = []

  constructor(private api: API,
              private onBorderChange: (value: boolean) => void,
              private onEqualWidthChange: (value: boolean) => void) {
  }

  get CSS() {
    return {
      buttonBase: this.api.styles.settingsButton,
      buttonActive: this.api.styles.settingsButtonActive,
    }
  }

  get tunes() {
    return [
      {
        name: 'border',
        icon: borderIcon,
        title: 'With border',
        action: this.borderSettingClicked.bind(this)
      },
      {
        name: 'equalColumnWidth',
        icon: equalColumnWidthIcon,
        title: 'Equal column width',
        action: this.equalWidthSettingClicked.bind(this)
      }
    ]
  }

  render(data: EditorGridBlockData) {
    const wrapper = make('div')

    this.tunes.forEach(tune => {
      const title = this.api.i18n.t(tune.title)
      const el = make('div', [this.CSS.buttonBase], {
        innerHTML: tune.icon,
        title
      })

      el.addEventListener('click', () => {
        tune.action(tune.name)
      })

      el.dataset.tune = tune.name

      el.classList.toggle(this.CSS.buttonActive, data[tune.name])
      this.buttons.push(el)

      this.api.tooltip.onHover(el, title, {
        placement: 'top'
      })

      wrapper.appendChild(el)
    })

    return wrapper
  }

  borderSettingClicked(tuneName: string) {
    this.tuneClicked(tuneName, this.onBorderChange)
  }

  equalWidthSettingClicked(tuneName: string) {
    this.tuneClicked(tuneName, this.onEqualWidthChange)
  }

  tuneClicked(tuneName: string, callback: (value: any) => void) {
    const button = this.buttons.find(el => el.dataset.tune === tuneName)!
    const value = !button.classList.contains(this.CSS.buttonActive)
    button.classList.toggle(this.CSS.buttonActive, value)
    callback(value)
  }
}

export default Tunes