import {ObjectId} from 'bson'
import {Locale} from 'domain/Locale'
import {Block, BlockType, CloneBlock, ReportState, Section} from 'domain/Report'
import {anyBlockContainsTranslation} from 'domain/utils/translationUtils'
import deepEqual from 'fast-deep-equal'
import EventBus, {EventType} from '../../EventBus'
import ReportSender from '../../hooks/ReportSender'

type Listener = (report: ReportState) => void

class ReportStore {
  private updateListeners: Listener[] = []
  private updateStructureListeners: Listener[] = []
  private report: ReportState | undefined = undefined
  private changedLocalesStore = new ChangedLocalesStore()
  private _reportSender: ReportSender

  constructor(private projectId: string) {
    this._reportSender = new ReportSender(projectId)
    this.onUpdate(report => {
      this._reportSender.recordReportUpdate(report, this.getChangedLocales())
    })
  }

  get reportSender() {
    return this._reportSender
  }

  onUpdate(listener: Listener) {
    this.updateListeners.push(listener)
  }

  onUpdateStructure(listener: Listener) {
    this.updateStructureListeners.push(listener)
  }

  getReport() {
    return this.report
  }

  setReport(report: ReportState) {
    this.report = report
    this.notifyUpdateStructure()
  }

  getChangedLocales() {
    return Array.from(this.changedLocalesStore.changedLocales) as Locale[]
  }

  addSection(section: Section) {
    if (!this.report) return

    this.report.sections.push(section)
    this.changedLocalesStore.addAll()
    this.notifyUpdateStructure()
  }

  deleteSection(id: ObjectId | string) {
    if (!this.report) return

    const remainingSections = this.report.sections.filter(section => section._id !== id)
    this.notifySourceBlockDeletion(id, remainingSections)

    this.report.sections = remainingSections
    this.changedLocalesStore.addAll()
    this.notifyUpdateStructure()
  }

  moveSection(id: ObjectId | string, forward: boolean) {
    if (!this.report) return
    const indexFrom = this.report.sections.findIndex(section => section._id === id)
    if (indexFrom < 0) return

    const indexTo = forward ? indexFrom + 1 : indexFrom - 1
    this.swapSections(indexFrom, indexTo)
    this.changedLocalesStore.addAll()
    this.notifyUpdateStructure()
  }

  private swapSections(indexFrom: number, indexTo: number) {
    if (!this.report ||
      indexFrom >= this.report.sections.length || indexTo >= this.report.sections.length ||
      indexFrom < 0 || indexTo < 0) return

    [this.report.sections[indexFrom], this.report.sections[indexTo]]
      = [this.report.sections[indexTo], this.report.sections[indexFrom]]
  }

  private notifySourceBlockDeletion(id: ObjectId | string, remainingSections: Section[]) {
    const removedBlockIds = this.report!.sections.filter(section => section._id === id)
      .first().blocks.map(block => block.id)

    remainingSections
      .flatMap(section => section.blocks)
      .filter(block => block.type === BlockType.CLONE && removedBlockIds.includes(block.sourceId))
      .forEach(block => {
        EventBus.emit(EventType.BLOCK_DELETED, {blockId: (block as CloneBlock).sourceId})
      })
  }

  updateReportSection(updatedSection: Section, locale: Locale) {
    if (!this.report) return

    this.report.sections = this.report.sections.map(section => {
      return section._id === updatedSection._id ? updatedSection : section
    })

    this.changedLocalesStore.add(locale)
    this.notifyUpdate()
  }

  updateSectionBlocks(sectionId: string, blocks: Block[], locale: Locale) {
    if (!this.report) return

    const section = this.report.sections.find(section => section._id === sectionId)
    if (deepEqual(section?.blocks, blocks)) return

    this.updateChangedLocales(section?.blocks!, blocks, locale)

    this.updateReportSection({...section!, blocks}, locale)
  }

  updateSectionBlocksAndRerender(sectionId: string, blocks: Block[], locale: Locale) {
    this.updateSectionBlocks(sectionId, blocks, locale)
    this.notifyUpdateStructure()
  }

  updateSectionBlock(sectionId: string, updatedBlock: Block, locale: Locale) {
    if (!this.report) return

    const section = this.report.sections.find(section => section._id === sectionId)
    const updatedBlocks = section!.blocks.map(block => block.id === updatedBlock.id ? updatedBlock : block)
    const updatedSection = {...section!, blocks: updatedBlocks}
    this.updateReportSection(updatedSection, locale)
  }

  private notifyUpdate() {
    this.updateListeners.forEach(listener => listener(this.report!))
  }

  private notifyUpdateStructure() {
    this.updateStructureListeners.forEach(listener => listener(this.report!))
    this.notifyUpdate()
  }

  private updateChangedLocales(original: Block[], updated: Block[], locale: Locale) {
    const updatedBlockIds = updated.map(block => block.id)
    const deletedBlocks = original.filter(block => !updatedBlockIds.includes(block.id))
    if (deletedBlocks.length > 0 && anyBlockContainsTranslation(deletedBlocks)) {
      this.changedLocalesStore.addAll()
    } else {
      this.changedLocalesStore.add(locale)
    }
  }
}

class ChangedLocalesStore {
  public changedLocales = new Set()

  public add(locale: Locale) {
    this.changedLocales.add(locale)
  }

  public addAll() {
    Object.values(Locale).forEach(locale => this.changedLocales.add(locale))
  }
}

export default ReportStore