import {Company} from 'domain/Company'
import {Id} from 'domain/Entity'
import {Errors} from 'domain/Errors'
import {isDerivativeProjectType, Project, ProjectType} from 'domain/Project'
import {ReactComponent as DoneIcon} from '../../assets/icons/done.svg'
import React, {FC, ReactElement, useEffect, useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'
import {ProjectCreateRequest} from '../../../../api/src/services/ProjectCreationService'
import {getActiveCompanies} from '../../api-clients/companyClient'
import {createProject, getCloneableProjects} from '../../api-clients/projectClient'
import useModal from '../../hooks/useModal'
import CompanySelect from '../companies/CompanySelect'
import Collapsible from '../utils/Collapsible'
import ButtonWithLoaderAndIcon from '../utils/form/ButtonWithLoaderAndIcon'
import Select from '../utils/form/Select'
import ModalTemplate from '../utils/ModalTemplate'
import ProjectForm from './ProjectForm'

interface NewProjectProps {
  companyId?: string
  sourceProjectId?: Id
}

const NewProject: FC<NewProjectProps> = ({companyId, sourceProjectId}): ReactElement => {
  const {t} = useTranslation()
  const {closeModal} = useModal()
  const history = useHistory()
  const [companyProjects, setCompanyProjects] = useState<Project[]>([])
  const [companies, setCompanies] = useState<Company[]>([])
  const [project, setProject] = useState<Partial<Project>>({
    type: sourceProjectId ? ProjectType.FLASH : ProjectType.FULL,
    notifySubscribers: true,
    deadline: {},
    companyId,
    sourceProjectId
  })
  const [loading, setLoading] = useState(true)
  const [submitting, setSubmitting] = useState(false)
  const [projectFormLoaded, setProjectFormLoaded] = useState(false)
  const [errors, setErrors] = useState<Errors<Project>>({})
  const [resetHighlightedBlocks, setResetHighlightedBlocks] = useState<boolean>(true)
  const [addNewsUpdatesSections, setAddNewsUpdatesSections] = useState<boolean>(true)
  const [startDate, setStartDate] = useState<Date | undefined>()
  const selectedCompany = useMemo(() => companies.find(c => c._id === project.companyId), [project, companies])

  useEffect(() => {
    getActiveCompanies().then(companies => {
      setCompanies(companies)
      setLoading(false)
    })
  }, [])

  useEffect(() => {
    if (!project.companyId) return
    getCloneableProjects(project.companyId).then(setCompanyProjects)
  }, [project.companyId])

  useEffect(() => {
    if (!selectedCompany) return

    setProject(project => {
      return {
        ...project,
        analystId: selectedCompany.seniorAnalystId,
        assistantAnalystIds: selectedCompany.assistantAnalystIds
      }
    })
  }, [selectedCompany])

  const onConfirm = async (event: React.MouseEvent<HTMLButtonElement> | React.FormEvent) => {
    event.preventDefault()
    setSubmitting(true)
    const request = {
      ...project,
      resetHighlightedBlocks,
      addNewsUpdatesSections,
      startDate: startDate as unknown as Date
    } as ProjectCreateRequest

    try {
      const createdProject = await createProject(request)
      closeModal()
      history.push(`/projects/${createdProject._id}`)
    } catch (error: unknown) {
      if (typeof error !== 'object') {
        console.error('Could not create project', error)
      }
      const errors = (error as {errors: unknown}).errors
      if (errors) {
        setErrors(errors as Errors<Project>)
        return
      }
      throw error
    } finally {
      setSubmitting(false)
    }
  }

  const onChange = (field: string, value: unknown) => {
    setProject(project => ({...project, [field]: value}))
  }

  const onProjectTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newProjectType = event.target.value as ProjectType
    const sourceProjectId = isDerivativeProjectType(newProjectType) ? project.sourceProjectId : undefined
    setProject(project => ({...project, type: newProjectType, sourceProjectId}))
  }

  const onProjectSourceChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const {value} = event.target
    const sourceProject = companyProjects.find(project => project._id === value)!
    const sourceProjectId = sourceProject?._id
    const companyId = sourceProject?.companyId
    setProject(project => ({...project, sourceProjectId, companyId}))
  }

  const onCompanyChange = (companyId?: Id) => {
    if (companyId !== project.companyId) setProject(project => ({...project, companyId, sourceProjectId: undefined}))
  }

  return (
    <ModalTemplate size="xl" title={t('components.NewProject.title')}>
      <form onSubmit={onConfirm}>
        <div className="modal-body">
          <ProjectForm
            project={project}
            errors={errors}
            onChange={onChange}
            startDate={startDate}
            setStartDate={setStartDate}
            onFormReady={() => setProjectFormLoaded(true)}
          >
            <div className="mb-3">
              <label className="form-label small">{t('models.Project.type')}</label>
              <div>
                {Object.values(ProjectType).map(projectType => {
                  return (
                    <div className="form-check form-check-inline" key={projectType}>
                      <input
                        type="radio"
                        id={projectType}
                        name="type"
                        className="form-check-input"
                        value={projectType}
                        checked={projectType === project.type}
                        onChange={onProjectTypeChange}
                      />
                      <label className="form-check-label" htmlFor={projectType}>
                        {t(`models.ProjectType.${projectType}`)}
                      </label>
                    </div>
                  )
                })}
              </div>
            </div>

            <div className="mb-3">
              <label htmlFor="project-company" className="form-label small">
                {t('models.Project.company')}
              </label>
              <CompanySelect
                id="project-company"
                name="companyId"
                companies={companies}
                selectedValue={companyId}
                clearable={!!companyId}
                onChange={onCompanyChange}
              />
            </div>

            {isDerivativeProjectType(project.type) && (
              <div className="mb-3">
                <Collapsible
                  title={t('components.NewProject.createBasedOnExistingReport')}
                  initiallyCollapsed={sourceProjectId === undefined}
                >
                  <>
                    <label htmlFor="project-source" className="form-label small">
                      {t('components.NewProject.existingReport')}
                    </label>
                    <Select
                      className="form-select"
                      id="project-source"
                      name="projectSource"
                      onChange={onProjectSourceChange}
                      disabled={!projectFormLoaded || loading}
                      value={(project.sourceProjectId as string) || ''}
                      error={errors.sourceProjectId}
                    >
                      {!project.sourceProjectId && <option>{t('components.NewProject.selectSource')}</option>}
                      {companyProjects.map(project => (
                        <option value={project._id as string} key={project._id as string}>
                          {project.title} - {t('models.ProjectType.' + project.type)}
                        </option>
                      ))}
                    </Select>
                    {!project.companyId && (
                      <small className="text-danger">{t('components.NewProject.selectCompanyFirst')}</small>
                    )}
                    <div className="form-check pt-2">
                      <input
                        type="checkbox"
                        id="resetHighlightedBlocks"
                        name="type"
                        className="form-check-input"
                        checked={resetHighlightedBlocks}
                        onChange={() => setResetHighlightedBlocks(!resetHighlightedBlocks)}
                      />
                      <label className="form-check-label" htmlFor="resetHighlightedBlocks">
                        {t('components.NewProject.resetHighlightedBlocks')}
                      </label>
                    </div>
                    <div className="form-check pt-2">
                      <input
                        type="checkbox"
                        id="addNewsUpdatesSections"
                        name="type"
                        className="form-check-input"
                        checked={addNewsUpdatesSections}
                        onChange={() => setAddNewsUpdatesSections(!addNewsUpdatesSections)}
                      />
                      <label className="form-check-label" htmlFor="addNewsUpdatesSections">
                        {t('components.NewProject.addNewsUpdatesSections')}
                      </label>
                    </div>
                  </>
                </Collapsible>
              </div>
            )}
          </ProjectForm>
        </div>
        <div className="modal-footer">
          <ButtonWithLoaderAndIcon type="submit" className={'btn-success'} loading={submitting} icon={<DoneIcon className="icon" />}>
            {t('components.NewProject.create')}
          </ButtonWithLoaderAndIcon>
        </div>
      </form>
    </ModalTemplate>
  )
}

export default NewProject
