import {Locale} from 'domain/Locale'
import {PublishingProjectTask, TaskStatus} from 'domain/Task'
import React, {FC, useState} from 'react'
import EventBus, {EventType} from '../../../EventBus'
import DefaultProjectTask, {ProjectTaskProps} from './DefaultProjectTask'
import PublishedProjectLinks from './PublishedProjectLinks'
import StageCompletionButton from './StageCompletionButton'


const PublishTask: FC<ProjectTaskProps> = props => {
  const {project, loadProjectWithTasks, task} = props
  const [loading, setLoading] = useState(false)
  const showFinishProjectButton = task.status === TaskStatus.IN_PROGRESS

  const loadProject = () => {
    loadProjectWithTasks()
    EventBus.emit(EventType.REFRESH_PROJECT_LOGS)
  }

  const finishProjectButton = <div>
    <div className="float-end pb-2">
      <div className="pe-2 d-inline">
        <StageCompletionButton project={project} task={task}
                               onStageCompleted={loadProject}
                               disabled={loading} setLoading={setLoading}
                               label={'components.StageCompletionButton.finishProject'}/>
      </div>
    </div>
  </div>

  const content = <>
    <PublishedProjectLinks project={project} loadProject={loadProject}
                           task={task as PublishingProjectTask}/>
  </>

  return <DefaultProjectTask {...props}
                             content={content}
                             bottom={showFinishProjectButton && finishProjectButton}
  />
}

export default PublishTask