import React, {ReactNode, useState} from 'react'


export const ToastContext = React.createContext({} as any)

export enum ToastType {
  SUCCESS = 'SUCCESS',
  DANGER = 'DANGER',
  WARNING = 'WARNING'
}

export interface Toast {
  id: string
  content: string
  type: ToastType
}

const ToastProvider = ({children}: { children: ReactNode }) => {
  const [toastContents, setToastContents] = useState<Toast[]>([])

  return (
    <ToastContext.Provider value={{toastContents, setToastContents}}>
      {children}
    </ToastContext.Provider>
  )
}

export default ToastProvider