import {API, BaseTool} from '@editorjs/editorjs'
import {EditorTableBlockData, EditorTableCellTemplate, EditorTableConfig} from 'domain/Editor'
import {AdditionalStyle, MainTableStyle, TableStyle} from 'domain/Report'
import {removeSpans} from 'domain/utils/textUtils'
import excelIcon from '../../../assets/inline-svg/microsoft-excel.svg'
import tableExcelIcon from '../../../assets/inline-svg/microsoft-excel.svg'
import stretched from '../../../assets/inline-svg/stretched.svg'
import tableCompactIcon from '../../../assets/inline-svg/table-compact.svg'
import tableNormalIcon from '../../../assets/inline-svg/table-normal.svg'
import tablePaddedIcon from '../../../assets/inline-svg/table-padded.svg'
import {containsImage, containsTable, make} from '../dom'
import TableBuilder from './TableBuilder'
import {getTableStyles, isMainTableStyle} from './tableClassNamesHelper'
import {defaultTableStyle, getTableCellType, getTableTemplateFromHTML} from './tableHelper'


interface TableSetting {
  icon: string
  title: string
  style: TableStyle
}

type ExcelTableConstructor = {
  data?: EditorTableBlockData,
  config?: EditorTableConfig,
  api: API,
  readOnly?: boolean
}

class ExcelTable implements BaseTool {
  api: API
  config: EditorTableConfig
  settingsButtons: HTMLElement[]
  data: EditorTableBlockData
  tableBuilder: TableBuilder
  readonly: boolean

  static get sanitize() {
    return {
      style: true,
      content: true,
      title: true,
      description: true
    }
  }

  constructor({data, config, api, readOnly}: ExcelTableConstructor) {
    this.api = api
    this.config = config || {} as EditorTableConfig
    this.settingsButtons = []
    this.readonly = readOnly || false

    if (data?.content) {
      this.data = {...data}
    } else {
      this.data = ExcelTable.defaultData()
    }

    if (!this.data?.style) {
      this.data.style = MainTableStyle.COMPACT
    }

    const tableBuilderConfigs = {
      placeholderCSS: [this.api.styles.button, 'table-placeholder'],
      placeholderText: this.config.placeholderText || 'Insert your table here',
      placeholderIcon: tableExcelIcon,
      titlePlaceholder: this.config.titlePlaceholder || 'Table title',
      descriptionPlaceholder: this.config.descriptionPlaceholder || 'Table description',
      onTablePaste: this.onTablePaste.bind(this)
    }

    this.tableBuilder = new TableBuilder(tableBuilderConfigs, this.data, this.readonly)
  }

  propagateEvent(event: Event) {
    if (event instanceof ClipboardEvent) {
      this.tableBuilder.nodes.container.dispatchEvent(event)
    }
  }

  static get isReadOnlySupported() {
    return true
  }

  static defaultData(): EditorTableBlockData {
    return {
      content: [],
      style: MainTableStyle.COMPACT
    }
  }

  static get settings(): TableSetting[] {
    return [
      {
        title: 'Large',
        style: MainTableStyle.NORMAL,
        icon: tablePaddedIcon
      },
      {
        title: 'Normal',
        style: MainTableStyle.COMPACT,
        icon: tableNormalIcon
      },
      {
        title: 'Compact',
        style: MainTableStyle.EXTRA_COMPACT,
        icon: tableCompactIcon
      },
      {
        title: 'AutoWidth',
        icon: stretched,
        style: AdditionalStyle.WIDTH_AUTO
      }
    ]
  }

  static get toolbox() {
    return {
      title: 'Excel Table',
      icon: excelIcon
    }
  }

  set tableStyle(style: MainTableStyle) {
    this.data.style = style
    this.settingsButtons.forEach(button => {
      button.classList.toggle(this.api.styles.settingsButtonActive, button.dataset.style === style)
    })
  }

  set additionTableStyle(style: AdditionalStyle) {
    if (!this.data.additionalStyles)
      this.data.additionalStyles = []

    if (this.data.additionalStyles.includes(style))
      this.data.additionalStyles = this.data.additionalStyles.filter(existing => existing !== style)
    else
      this.data.additionalStyles.push(style)
  }

  renderSettings() {
    const wrapper = make('div')

    ExcelTable.settings.forEach(setting => {
      const button = make('div', [this.api.styles.settingsButton], {innerHTML: setting.icon})
      button.dataset.style = setting.style
      const isMainStyle = isMainTableStyle(setting.style)
      button.addEventListener('click', () => {
        if (isMainStyle) {
          const style = setting.style as MainTableStyle
          this.tableStyle = style
          this.tableBuilder.changeTableStyle(style)
        } else {
          const style = setting.style as AdditionalStyle
          const toggled = this.data.additionalStyles?.includes(style) || false
          toggled ? button.classList.add(this.api.styles.settingsButtonActive)
            : button.classList.remove(this.api.styles.settingsButtonActive)
          this.tableBuilder.toggleAdditionalStyle(style, toggled)
          this.additionTableStyle = style
        }
      })

      if (this.data.style === setting.style
        || (!isMainStyle && !this.data.additionalStyles?.includes(setting.style as AdditionalStyle))) {
        button.classList.add(this.api.styles.settingsButtonActive)
      }

      const title = this.api.i18n.t(setting.title)
      this.api.tooltip.onHover(button, title, {
        placement: 'top'
      })

      if (isMainStyle)
        this.settingsButtons.push(button)
      wrapper.appendChild(button)
    })

    return wrapper
  }


  render(): HTMLElement {
    return this.tableBuilder.render()
  }

  save(): EditorTableBlockData {
    const table: HTMLTableElement = this.tableBuilder.nodes.table
    const title = this.tableBuilder.nodes.title
    const description = this.tableBuilder.nodes.description

    const content = this.data.content.map((row, rowIndex) => {
      return {
        ...row,
        cells: row.cells.map((cell, cellIdx) => {
          const td = table.rows[rowIndex].cells[cellIdx]

          const cellData = {
            html: this.cellContent(table, rowIndex, cellIdx),
            type: getTableCellType(td.innerHTML),
            backgroundStyle: cell.backgroundStyle,
            fontColorStyle: cell.fontColorStyle,
            verticalAlign: cell.verticalAlign,
            ...this.omitDefaultValues(cell)
          } as EditorTableCellTemplate

          const width = this.relativeWidth(td, table)
          if (width) {
            cellData.width = width
          }

          Object.keys(cellData).forEach(key => cellData[key] === undefined && delete cellData[key])
          return cellData
        })
      }
    })

    const res = {
      title: removeSpans(title?.innerHTML),
      description: removeSpans(description?.innerHTML),
      style: this.data.style,
      additionalStyles: this.data.additionalStyles,
      content
    }
    return res
  }

  private omitDefaultValues(cell: EditorTableCellTemplate) {
    const cellData = {} as Partial<EditorTableCellTemplate>

    if (cell.colSpan !== 1) {
      cellData.colSpan = cell.colSpan !== 1 ? cell.colSpan : undefined
    }

    if (cell.rowSpan !== 1) {
      cellData.rowSpan = cell.rowSpan
    }

    if (cell.textAlign !== 'left' && cell.textAlign !== 'start') {
      cellData.textAlign = cell.textAlign
    }

    if (cell.fontWeight !== 'normal' && cell.fontWeight !== '400') {
      cellData.fontWeight = cell.fontWeight
    }

    if (cell.fontStyle !== 'normal') {
      cellData.fontStyle = cell.fontStyle
    }

    if (!cell.textDecoration?.startsWith('none')) {
      cellData.textDecoration = cell.textDecoration
      cellData.textDecorationStyle = cell.textDecorationStyle
    }

    if (cell.hasBorderLeft) {
      cellData.hasBorderLeft = cell.hasBorderLeft
    }

    if (cell.hasBorderRight) {
      cellData.hasBorderRight = cell.hasBorderRight
    }

    return cellData
  }

  private cellContent(table: HTMLTableElement, rowIndex: number, cellIdx: number) {
    return removeSpans(table.rows[rowIndex].cells[cellIdx].innerHTML)
  }

  private onTablePaste(event: ClipboardEvent) {
    const html: string | undefined = event.clipboardData?.getData('text/html')
    if (!html) return

    if (containsTable(html) || containsImage(html)) {
      event.preventDefault()
      event.stopImmediatePropagation()
    }

    if (containsTable(html)) {
      this.data.content = getTableTemplateFromHTML(html)
      this.data.style = defaultTableStyle(this.data.content)

      this.tableBuilder.replaceTable(this.data.content, getTableStyles(this.data))
    }
  }

  private relativeWidth(td: HTMLTableCellElement, table: HTMLTableElement): string | undefined {
    if (!td.style.width) return
    if (td.style.width.endsWith('px')) {
      const relativeWidth = parseInt(td.style.width, 10) * 100 / table.clientWidth
      return `${relativeWidth}%`
    } else {
      return td.style.width
    }
  }
}

export default ExcelTable