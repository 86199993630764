import {Locale} from 'domain/Locale'
import {GroupedNews} from 'domain/Project'

import React, {useEffect} from 'react'
import useForceRender from '../../../hooks/useForceRender'
import debounce from '../../../utils/debounce'
import memoizeComponent from '../../../utils/memoizeComponent'
import ReportStore from '../ReportStore'
import Sidebar from './Sidebar'


interface SidebarProps {
  reportStore: ReportStore
  locale: Locale
  groupedNews: GroupedNews
  displayMenu?: boolean
  displayNewsIfEmpty?: boolean
}

const SidebarWithReportChangeListener: React.FC<SidebarProps> = props => {
  const {reportStore, locale, groupedNews, displayNewsIfEmpty, displayMenu = true} = props

  const {rerender} = useForceRender()
  const report = reportStore.getReport()

  useEffect(() => {
    const debouncedRender = debounce(rerender, 1000)

    reportStore.onUpdate(() => {
      debouncedRender()
    })
  }, [])

  if (!report) return <></>
  return <Sidebar report={report!} locale={locale} displayMenu={displayMenu} groupedNews={groupedNews}
                  displayNewsIfEmpty={displayNewsIfEmpty} reportStore={reportStore}/>
}

export default memoizeComponent(SidebarWithReportChangeListener)