import {Errors} from 'domain/Errors'
import {Project} from 'domain/Project'
import {ReactComponent as DoneIcon} from '../../assets/icons/done.svg'
import React, {FC, ReactElement, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {updateProject} from '../../api-clients/projectClient'
import useModal from '../../hooks/useModal'
import ModalTemplate from '../utils/ModalTemplate'
import ProjectForm from './ProjectForm'
import {ProjectAggregate} from 'domain/ProjectAggregate'


interface Props {
  project: Project
  onUpdate: (project: ProjectAggregate) => void
}

const EditProject: FC<Props> = ({project: initialProject, onUpdate}): ReactElement => {
  const {t} = useTranslation()
  const {closeModal} = useModal()
  const [project, setProject] = useState<Project>(initialProject)
  const [errors, setErrors] = useState<Errors<Project>>({})

  const onConfirm = (event: React.MouseEvent<HTMLButtonElement> | React.FormEvent) => {
    event.preventDefault()

    updateProject(project).then(project => {
      closeModal()
      onUpdate(project)
    }).catch(({errors}) => setErrors(errors))
  }

  const onChange = (field: string, value: any) => {
    setProject(project => ({...project, [field]: value}))
  }

  return (
    <ModalTemplate size="xl" title={t('components.EditProject.title')}>
      <form onSubmit={onConfirm}>
        <div className="modal-body">
          <ProjectForm project={project} errors={errors} onChange={onChange}/>
        </div>
        <div className="modal-footer">
          <button type="submit" className="btn btn-success btn-with-icon">
            <span>{t('components.EditProject.update')}</span>
            <DoneIcon className="icon" />
          </button>
        </div>
      </form>
    </ModalTemplate>
  )
}

export default EditProject