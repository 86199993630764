import {Locale} from 'domain/Locale'
import {Permission} from 'domain/Permission'
import {Project} from 'domain/Project'
import {ReactComponent as RefreshIcon} from '../../../assets/icons/refresh_black.svg'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {regenerateProjectReport} from '../../../api-clients/projectClient'
import useModal from '../../../hooks/useModal'
import ConfirmationModal from '../../utils/ConfirmationModal'
import Protected from '../../utils/Protected'


interface Props {
  project: Project
  loadProject: () => void
  locale: Locale
  regenerationInProgress: boolean
  disabled: boolean
}

const RegenerateReportButton: React.FC<Props> = props => {
  const {project, loadProject, locale, regenerationInProgress, disabled} = props
  const {showModal} = useModal()
  const {t} = useTranslation()

  const regenerate = (event: React.MouseEvent) => {
    event.preventDefault()

    const onConfirm = async () => {
      regenerateProjectReport(project._id, locale).then(() => loadProject())
    }

    showModal(<ConfirmationModal
      title={t('components.RegenerateReportButton.confirmationTitle')}
      body={t('components.RegenerateReportButton.confirmationMessage', {
        type: t(`components.RegenerateReportButton.type.${locale}`)
      })}
      ConfirmationIcon={RefreshIcon} onConfirm={onConfirm}/>)
  }

  return <Protected permission={Permission.Project.EDIT_SETTINGS}>
    <span className={'ms-2'}>
      {regenerationInProgress ? <div className="spinner-border spinner-border-sm text-muted"/> : (
        <button className="btn btn-xs btn-outline-secondary btn-icon-only" onClick={regenerate} disabled={disabled}
                title={t('components.RegenerateReportButton.buttonTitle')}>
          <RefreshIcon className="icon-xs"/>
        </button>
      )}
    </span>
  </Protected>
}

export default RegenerateReportButton