import {Company} from 'domain/Company'
import {Locale} from 'domain/Locale'
import {ReactComponent as CloseIcon} from '../../assets/icons/close.svg'
import {ReactComponent as DoneIcon} from '../../assets/icons/done.svg'
import React, {ReactElement, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {deleteCompany} from '../../api-clients/companyClient'
import useModal from '../../hooks/useModal'
import ModalTemplate from '../utils/ModalTemplate'


interface CompanyFormProps {
  company: Company,
  onDeleted: () => void
}

const DeleteCompany: React.FC<CompanyFormProps> = ({company, onDeleted}): ReactElement => {
  const {closeModal} = useModal()
  const {t} = useTranslation()
  const [errors, setErrors] = useState<{ message?: string }>({})

  const onDelete = (event: React.MouseEvent) => {
    event.preventDefault()
    deleteCompany(company._id).then(() => {
      closeModal()
      onDeleted()
    }).catch(({errors}) => setErrors(errors))
  }

  return (
    <ModalTemplate>
      <div className="modal-body">
        {t('components.Companies.DeleteCompany.body', {company: company.name[Locale.EN]})}
        {errors.message && (<div className="text-danger">
          {t(`components.Companies.DeleteCompany.${errors.message}`, {defaultValue: errors.message})}
        </div>)}
      </div>
      <div className="modal-footer justify-content-around">
        <button type="button" className="btn btn-danger btn-with-icon" onClick={closeModal}>
          <span>{t('components.Companies.DeleteCompany.cancel')}</span>
          <CloseIcon className="icon" />
        </button>
        <button type="submit" className="btn btn-success btn-with-icon" onClick={onDelete}>
          <span>{t('components.Companies.DeleteCompany.confirm')}</span>
          <DoneIcon className="icon" />
        </button>
      </div>
    </ModalTemplate>
  )
}

export default DeleteCompany
