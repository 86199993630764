import {PermissionType} from 'domain/Permission'
import React, {ReactElement, useContext} from 'react'
import {UserContext} from '../../providers/UserProvider'


interface ProtectedProps {
  children: ReactElement
  else?: ReactElement | string
}

interface ProtectedPermissionProps extends ProtectedProps {
  permission: PermissionType
}

interface ProtectedPermissionsProps extends ProtectedProps {
  permissions: PermissionType[]
}

const Protected: React.FC<ProtectedPermissionProps | ProtectedPermissionsProps> = props => {
  const {children} = props
  const requestedPermissions = (props as ProtectedPermissionsProps).permissions ||
    [(props as ProtectedPermissionProps).permission]

  const {permissions} = useContext(UserContext)

  if (requestedPermissions.some(p => permissions.includes(p))) {
    return children
  } else {
    return props.else ? <>{props.else}</> : <></>
  }
}

export default Protected