import {useEffect, useRef} from 'react'
import debounce from '../utils/debounce'
import {Pagination} from './usePaginatedCollection'


type UsePaginatedViewParams<T> = {
  pagination: Pagination<T>
}

type ScrollableContainers = {
  scrollableBody: Window | HTMLElement
  scrollableWrapper: HTMLElement
}

export const usePaginatedView = <T>({pagination}: UsePaginatedViewParams<T>) => {
  const container = useRef<HTMLDivElement | HTMLTableSectionElement>(null)

  useEffect(() => {
    const {scrollableBody, scrollableWrapper} = getScrollableContainer()
    const onScroll = () => {
      const {scrollHeight, scrollTop, clientHeight} = scrollableWrapper

      if (scrollTop + clientHeight > scrollHeight - 5) {
        if (!pagination.loading) {
          pagination.loadNextPage()
        }
      }
    }

    const debouncedScroll = debounce(onScroll, 200)

    scrollableBody.addEventListener('scroll', debouncedScroll)

    return () => {
      scrollableBody.removeEventListener('scroll', debouncedScroll)
    }
  }, [pagination])

  const getScrollableContainer = (): ScrollableContainers => {
    const parent = container.current!.parentElement!
    const overflow = window.getComputedStyle(parent).overflowY

    if (overflow === 'auto') {
      return {scrollableBody: parent, scrollableWrapper: parent}
    } else {
      return {scrollableBody: window, scrollableWrapper: document.documentElement}
    }
  }

  return {
    container
  }
}