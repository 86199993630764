import {Locale} from 'domain/Locale'
import {GroupedNews} from 'domain/Project'
import {Section, SectionType} from 'domain/Report'
import React from 'react'
import NewsReleasesSection from './NewsReleasesSection'


interface Props {
  section: Section
  render: () => React.ReactNode
  locale: Locale
  groupedNews?: GroupedNews
  displayIfNoNews?: boolean
}

const SectionOrNewsReleases: React.FC<Props> = props => {
  const {section, render, groupedNews, locale, displayIfNoNews} = props

  if (section.type === SectionType.RECENT_UPDATES && groupedNews) {
    return <NewsReleasesSection newsProjects={groupedNews.recentUpdates} section={section} locale={locale}
                                displayIfNoNews={displayIfNoNews}/>
  }

  if (section.type === SectionType.NEWS_AND_TOPICS && groupedNews) {
    return <NewsReleasesSection newsProjects={groupedNews.newsAndTopics} section={section} locale={locale}
                                displayIfNoNews={displayIfNoNews}/>
  }

  return <>{render()}</>

}

export default SectionOrNewsReleases
