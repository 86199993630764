export enum DetectedEnvironment {
  STAGING = 'STAGING',
  DEVELOPMENT = 'DEVELOPMENT',
  PRODUCTION = 'PRODUCTION'
}

export const detectEnvironment = (): DetectedEnvironment => {
  const host = new URL(window.location.href).host
  if (host.includes('localhost')) return DetectedEnvironment.DEVELOPMENT
  if (host.includes('staging')) return DetectedEnvironment.STAGING
  return DetectedEnvironment.PRODUCTION
}