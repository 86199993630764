import {Locale} from 'domain/Locale'
import {Permission} from 'domain/Permission'
import {ReactComponent as UploadIcon} from '../../assets/icons/cloud_upload.svg'
import {ReactComponent as CommentIcon} from '../../assets/icons/comment.svg'
import {ReactComponent as ImportIcon} from '../../assets/icons/compare_arrows.svg'
import {ReactComponent as RedoIcon} from '../../assets/icons/redo.svg'
import {ReactComponent as UndoIcon} from '../../assets/icons/undo.svg'
import React, {ReactElement, useContext, useEffect} from 'react'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'
import {useTranslation} from 'react-i18next'
import useForceRender from '../../hooks/useForceRender'
import {CommentContext} from '../../providers/CommentProvider'
import Protected from '../utils/Protected'

interface ToolbarRightProps {
  locale: Locale
  onFileUpload: () => void
  canUndo: () => boolean
  canRedo: () => boolean
  canImportTranslations?: boolean
  onImportTranslation?: () => void
  undo: () => void
  redo: () => void
  showComments: () => void
  onUndoRedoUpdate: (cb: () => void) => void
}

const Toolbar: React.FC<ToolbarRightProps> = (props): ReactElement => {
  const {
    locale,
    onFileUpload,
    canUndo,
    canRedo,
    undo,
    redo,
    onUndoRedoUpdate,
    showComments,
    canImportTranslations,
    onImportTranslation
  } = props
  const {t} = useTranslation()
  const {getUnresolvedLocaleComments} = useContext(CommentContext)
  const {rerender} = useForceRender()

  useEffect(() => {
    onUndoRedoUpdate(() => rerender())
  }, [])

  const unresolvedLocaleComments = getUnresolvedLocaleComments(locale)

  const uploadFile = (event: React.MouseEvent) => {
    event.preventDefault()
    onFileUpload()
  }

  const importTranslation = (event: React.MouseEvent) => {
    event.preventDefault()
    if (onImportTranslation) onImportTranslation()
  }

  const onShowCommentsClick = (e: React.MouseEvent) => {
    e.preventDefault()
    showComments()
  }

  const onUndo = (e: React.MouseEvent) => {
    e.preventDefault()
    undo()
  }

  const onRedo = (e: React.MouseEvent) => {
    e.preventDefault()
    redo()
  }

  return (
    <div className="navbar-nav">
      <Protected permission={Permission.Comment.READ}>
        <div className="nav-item">
          <a className="nav-link position-relative" href="#" onClick={onShowCommentsClick}>
            <CommentIcon className="icon" />
            {unresolvedLocaleComments.length > 0 && (
              <span className="badge bg-warning text-body badge-circle position-absolute top-0 end-0">
                {unresolvedLocaleComments.length}
              </span>
            )}
          </a>
        </div>
      </Protected>

      <Protected permission={Permission.Report.UPDATE}>
        <div className="nav-item">
          <a className="nav-link" href="#" onClick={uploadFile}>
            <UploadIcon className="icon" />
          </a>
        </div>
      </Protected>

      {canImportTranslations && (
        <Protected permission={Permission.Report.UPDATE}>
          <OverlayTrigger
            placement="bottom"
            overlay={
              <Tooltip id="sticky-tooltip">
                <div className="small">{t('components.Toolbar.importHover')}</div>
              </Tooltip>
            }
            trigger={['hover', 'focus']}
          >
            <div className="nav-item">
              <a className="nav-link" href="#" onClick={importTranslation}>
                <ImportIcon className="icon" />
              </a>
            </div>
          </OverlayTrigger>
        </Protected>
      )}

      <div className="nav-item">
        <a className={`nav-link ${canUndo() ? 'text-muted' : 'text-muted-more'}`} href="#" onClick={onUndo}>
          <UndoIcon className="icon" />
        </a>
      </div>

      <div className="nav-item">
        <a className={`nav-link ${canRedo() ? 'text-muted' : 'text-muted-more'}`} href="#" onClick={onRedo}>
          <RedoIcon className="icon" />
        </a>
      </div>
    </div>
  )
}

export default Toolbar
