import {ReactComponent as AddAboveIcon} from '../../../../assets/icons/add_above.svg'
import {ReactComponent as AddBottomIcon} from '../../../../assets/icons/add_bottom.svg'
import {ReactComponent as AddLeftIcon} from '../../../../assets/icons/add_left.svg'
import {ReactComponent as AddRightIcon} from '../../../../assets/icons/add_right.svg'
import {Direction} from 'domain/Report'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {MENU_ICON_STYLE, MENU_ITEM_TITLE_STYLE, MENU_SECONDARY_ITEM_STYLE, MENU_SECTION_STYLE} from './styles'

interface Props {
  onAddRow: (direction: Direction) => void
  onAddColumn: (direction: Direction) => void
}

export const RowColumnButtonsSection: React.FC<Props> = ({onAddRow, onAddColumn}) => {
  const {t} = useTranslation()

  const buttons = [
    {icon: <AddAboveIcon className={MENU_ICON_STYLE} />, handler: () => onAddRow(Direction.ABOVE)},
    {icon: <AddRightIcon className={MENU_ICON_STYLE} />, handler: () => onAddColumn(Direction.RIGHT)},
    {icon: <AddBottomIcon className={MENU_ICON_STYLE} />, handler: () => onAddRow(Direction.BELOW)},
    {icon: <AddLeftIcon className={MENU_ICON_STYLE} />, handler: () => onAddColumn(Direction.LEFT)}
  ]

  return (
    <div>
      <div className={MENU_ITEM_TITLE_STYLE}>{t('Add Rows Columns')}</div>
      <div className={MENU_SECTION_STYLE}>
        {buttons.map((button, index) => (
          <div className={MENU_SECONDARY_ITEM_STYLE} onClick={button.handler} key={index}>
            {button.icon}
          </div>
        ))}
      </div>
    </div>
  )
}
