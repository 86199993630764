import React, {Suspense} from 'react'
import {createRoot} from 'react-dom/client'
import {BrowserRouter as Router} from 'react-router-dom'
import '../../domain/Array.ext'
import '../../domain/String.ext'
import {postClientError} from './api-clients/clientErrors'
import App from './App'

window.addEventListener('error', (event: ErrorEvent) => {
  if (event.error) {
    postClientError({
      message: event.error.message,
      stack: event.error.stack
    })
  }
})

const appContainer = document.getElementById('root')!
const root = createRoot(appContainer)
root.render(
  <Suspense fallback={<div>Loading</div>}>
    <Router>
      <App />
    </Router>
  </Suspense>
)
