export interface ProjectProblem {
  message: string
  link: string
  problemType: ProblemType
}

export enum ProblemType {
  TECHNICAL = 'technical',
  CONTENT = 'content',
  OTHER = 'other'
}
