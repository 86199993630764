import {CommentStatus, CommentWithUserName} from 'domain/Comment'

export const filterComments = (
  comments: CommentWithUserName[],
  commentFilters: {userIds: any[]; isVisible: boolean; isSticky: boolean; status: CommentStatus}
) =>
  comments
    .filter(comment => {
      if (!commentFilters.isSticky) {
        return true
      }
      return comment.sticky
    })
    .filter(comment => {
      if (!commentFilters.isVisible) {
        return true
      }
      return comment.isVisibleForCompany
    })
    .filter(comment => {
      if (commentFilters.status === CommentStatus.ALL) {
        return true
      }
      return comment.status === commentFilters.status
    })
    .filter(comment => {
      if (commentFilters.userIds.length === 0) {
        return true
      }
      return commentFilters.userIds.includes(comment.userId)
    })
