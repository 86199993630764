import React, {CSSProperties} from 'react'


type Props = {
  buttonLink: string
  buttonText: string
}

const EmailButton: React.FC<Props> = ({buttonLink, buttonText}) => {
  const css = {
    background: '#3971a8',
    fontWeight: 'bold',
    textDecoration: 'none',
    textAlign: 'center',
    padding: '16px 32px',
    color: '#fff',
    borderRadius: '4px',
    display: 'block'
  } as CSSProperties

  return <a href={buttonLink} style={css}>
    {buttonText}
  </a>
}

export default EmailButton