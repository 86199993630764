import {make} from '../dom'


class TableResizer {
  cursorX?: number
  selectedColumn?: HTMLElement
  nextColumn?: HTMLElement
  selectedColumnWidth?: number
  nextColumnWidth?: number

  constructor(private table: HTMLTableElement) {
  }

  get CSS() {
    return {
      resizeHandle: 'resize-handle'
    }
  }

  enable() {
    this.createResizeHandles()
    this.observeTableStructureChange()
  }

  private observeTableStructureChange() {
    const mutationObserver = new MutationObserver(mutations => {
      const isNewCellAdded = mutations.some(mutation => Array.from(mutation.addedNodes).some(node => {
        return ['TR', 'TD'].includes(node.nodeName)
      }))

      if (isNewCellAdded) {
        this.clean()
        this.createResizeHandles()
      }
    })

    mutationObserver.observe(this.table, {childList: true, subtree: true})
  }

  private clean() {
    for (const resizeHandle of Array.from(this.table.querySelectorAll('.' + this.CSS.resizeHandle))) {
      resizeHandle.remove()
    }
  }

  private createResizeHandles() {
    const cells = Array.from(this.table.getElementsByTagName('td')) as HTMLElement[]

    for (const cell of cells) {
      const resizeHandle = this.buildResizeHandle()
      cell.appendChild(resizeHandle)
      resizeHandle.addEventListener('mousedown', this.onMouseDown)
    }
  }

  private onMouseDown = (e: MouseEvent) => {
    e.stopPropagation()

    this.selectedColumn = (e.target as any).parentElement
    this.nextColumn = this.selectedColumn!.nextElementSibling as HTMLElement
    this.cursorX = e.pageX

    this.selectedColumnWidth = this.selectedColumn!.offsetWidth

    if (this.nextColumn) {
      this.nextColumnWidth = this.nextColumn.offsetWidth
    }

    document.addEventListener('mousemove', this.onMouseMove)
    document.addEventListener('mouseup', this.onMouseUp)
  }

  private onMouseMove = (e: MouseEvent) => {
    if (this.selectedColumn) {
      const diffX = e.pageX - this.cursorX!

      if (this.nextColumn && this.nextColumn.style.width) {
        this.nextColumn.style.width = this.percentageOfTableWidth(this.nextColumnWidth! - diffX) + '%'
      }

      this.selectedColumn.style.width = this.percentageOfTableWidth(this.selectedColumnWidth! + diffX) + '%'
    }
  }

  private onMouseUp = () => {
    this.reset()
    document.removeEventListener('mousemove', this.onMouseMove)
    document.removeEventListener('mouseup', this.onMouseUp)
  }

  private reset() {
    this.selectedColumn = undefined
    this.selectedColumnWidth = undefined
    this.nextColumn = undefined
    this.nextColumnWidth = undefined
    this.cursorX = undefined
  }

  private percentageOfTableWidth(num: number) {
    return num * 100 / this.table.offsetWidth
  }

  private buildResizeHandle() {
    return make('div', [this.CSS.resizeHandle])
  }
}

export default TableResizer
