class TableParser {
  html: string
  iframe?: HTMLIFrameElement

  constructor(html: string) {
    this.html = this.keepNotStandardMsoStyles(html)
  }

  withParsedTable<T>(fn: (table: HTMLTableElement | null | undefined) => T): T {
    this.iframe = this.embedIntoIframe(this.html)
    const table: HTMLTableElement | null | undefined = this.iframe.contentDocument?.querySelector('table')
    const result = fn(table)
    this.iframe.remove()
    return result
  }

  styles(node: HTMLElement): CSSStyleDeclaration {
    if (!this.iframe) {
      throw Error('Cannot fetch styles outside of "withParsedTable" function')
    }
    return this.iframe.contentWindow!.getComputedStyle(node) as CSSStyleDeclaration & {textOnly: boolean}
  }

  private embedIntoIframe(html: string): HTMLIFrameElement {
    const iframe: HTMLIFrameElement = document.createElement('iframe')
    iframe.style.display = 'none'
    document.body.appendChild(iframe)
    iframe.contentWindow?.document.open()
    iframe.contentWindow?.document.write(html)
    iframe.contentWindow?.document.close()
    return iframe
  }

  private keepNotStandardMsoStyles(html: string): string {
    return html.replace(/mso-([^:]*)/g, '--mso-$1')
  }
}

export default TableParser
