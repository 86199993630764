import {Id} from 'domain/Entity'
import {ProjectTask, ProjectTaskWithUsers, TaskDates, TaskUserAssignmentPayload} from 'domain/Task'
import apiClient from '../ApiClient'
import {ProjectAggregate} from 'domain/ProjectAggregate'


const getProjectTasks = async (projectId: Id): Promise<ProjectTaskWithUsers[]> => {
  return await apiClient.get(`/api/project-tasks?projectId=${projectId}`)
}

const updateTaskInterviewDate = async (taskId: Id, interviewDate: string): Promise<ProjectAggregate> => {
  return await apiClient.patch(`/api/project-tasks/${taskId}/interview-date`, {interviewDate})
}

const finishProjectTask = async (taskId: Id): Promise<ProjectTask> => {
  return await apiClient.patch(`/api/project-tasks/${taskId}/finish`)
}

const restartProjectTask = async (taskId: Id): Promise<ProjectTask> => {
  return await apiClient.patch(`/api/project-tasks/${taskId}/restart`)
}

const rescheduleProjectTask = async (taskId: Id, newEndDate: Date, reason: string): Promise<ProjectTask> => {
  return await apiClient.patch(`/api/project-tasks/${taskId}/reschedule`, {newEndDate, reason})
}

const assignUsersToProjectTask = async (taskId: Id, payload: TaskUserAssignmentPayload): Promise<ProjectTask> => {
  return await apiClient.patch(`/api/project-tasks/${taskId}/assign`, payload)
}

const assignAnalystsToProjectTasks = async (projectId: Id,
                                            analystId: Id | null,
                                            assistantAnalystIds: Id[]): Promise<void> => {
  const payload = {analystId, assistantAnalystIds}
  return await apiClient.patch(`/api/project-tasks/assign-analysts?projectId=${projectId}`, payload)
}

const updateProjectTasksDates = async (projectId: Id, tasks: TaskDates[]): Promise<void> => {
  return await apiClient.patch(`/api/project-tasks/dates?projectId=${projectId}`, {tasks})
}

export {
  getProjectTasks,
  updateTaskInterviewDate,
  finishProjectTask,
  restartProjectTask,
  rescheduleProjectTask,
  assignAnalystsToProjectTasks,
  assignUsersToProjectTask,
  updateProjectTasksDates
}