import {Id} from 'domain/Entity'
import stringHash from 'string-hash'
import {createLargePaste} from '../../../api-clients/largePasteClient'
import {containsImage, containsTable} from '../../../editorjs/plugins/dom'
import EventBus, {EventType} from '../../../EventBus'
import LocalStorage from '../../../LocalStorage'


type PasteText = {
  text: string
  html: string
}

export const LARGE_PASTE_MINIMUM_LENGTH = 300
export const LARGE_PASTE_MINIMUM_NEW_LINES = 5

export const addCopyTextListeners = (node: HTMLElement) => {
  node.addEventListener('copy', _ => EventBus.emit(EventType.TEXT_COPIED, {text: document.getSelection()?.toString()}))
  node.addEventListener('cut', _ => EventBus.emit(EventType.TEXT_COPIED, {text: document.getSelection()?.toString()}))
}

export const onTextCopy = (text: string) => {
  const hash = stringHash(text).toString()
  LocalStorage.setCopyTextHash(hash)
}

export const onTextPaste = (pasteText: PasteText, projectId: Id) => {
  if (pasteText.html) {
    if (containsTable(pasteText.html) || containsImage(pasteText.html)) {
      return
    }
  }

  if (pasteText.text) {
    if (isPastedFromEditor(pasteText.text)) {
      return
    }

    const textLength = pasteText.text.length
    const textNewLinesCount = (pasteText.text.match(/\n/g) || []).length

    if (textLength > LARGE_PASTE_MINIMUM_LENGTH || textNewLinesCount > LARGE_PASTE_MINIMUM_NEW_LINES) {
      setTimeout(() => {
        createLargePaste(projectId, textLength, textNewLinesCount)
      }, 0)
    }
  }
}

const isPastedFromEditor = (text: string) => {
  if (LocalStorage.getCopyTextHash()) {
    const hash = stringHash(text).toString()
    if (LocalStorage.getCopyTextHash() === hash) {
      return true
    }
  }

  return false
}