import {hasCommentWriteAccess} from 'domain/access'
import {Comment} from 'domain/Comment'
import {Errors} from 'domain/Errors'
import {Locale} from 'domain/Locale'
import React, {ReactElement, useContext, useEffect, useMemo, useRef, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {createComment} from '../../../api-clients/commentClient'
import {COMMENT_ID_PREFIX_CLASS} from '../../../editorjs/plugins/comment/CommentTool'
import {CommentContext} from '../../../providers/CommentProvider'
import {ReportContext} from '../../../providers/ReportProvider'
import {UserContext} from '../../../providers/UserProvider'
import CommentContentForm from './CommentContentForm'
import {getElementTopPosition, removeCommentHighlighting} from './commentUtils'

interface CommentProps {
  locale: Locale
  elements: HTMLElement[]
  classNames?: string
}

const NewComment: React.FC<CommentProps> = ({locale, elements, classNames}): ReactElement => {
  const {user} = useContext(UserContext)
  const {project} = useContext(ReportContext)
  const {closeNewComment, setComments} = useContext(CommentContext)
  const {t} = useTranslation()
  const [content, setContent] = useState('')
  const [errors, setErrors] = useState<Errors<Comment>>({})
  const [loading, setLoading] = useState(false)

  const commentedElementY = useMemo(() => {
    if (!elements[0]) {
      return window.innerHeight / 2
    }
    return getElementTopPosition(elements[0])
  }, [elements])

  const newCommentRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    setTimeout(() => {
      document.addEventListener('mousedown', onOutsideNewCommentClickListener)
    }, 0)

    return () => {
      document.removeEventListener('mousedown', onOutsideNewCommentClickListener)
    }
  }, [])

  const onOutsideNewCommentClickListener = (event: MouseEvent) => {
    const commentDiv = newCommentRef.current!
    const clickedInside = commentDiv.contains(event.target as Node)
    if (!clickedInside) {
      elements.forEach(element => removeCommentHighlighting(element))
      closeNewComment()
    }
  }

  const onCancel = (event: React.MouseEvent) => {
    event.preventDefault()
    event.stopPropagation()

    cancelNewComment()
  }

  const cancelNewComment = () => {
    elements.forEach(element => removeCommentHighlighting(element))
    closeNewComment()
  }

  const onCreate = () => {
    if (loading) {
      return
    }

    setLoading(true)
    const commentedText = elements.map(element => element.textContent).join('\n')
    const blockId = elements[0]?.closest('.ce-block')?.getAttribute('id')
    const commentPayload = {
      content,
      locale,
      reportId: project!.reportId,
      projectId: project!._id,
      commentedText,
      blockId
    }

    createComment(commentPayload).then(comment => {
      elements.forEach(element => {
        element.classList.add(COMMENT_ID_PREFIX_CLASS + comment._id)
        delete element.dataset.highlighted
      })

      setComments(comments => ([...(comments || []), comment]))
      closeNewComment()
    }).catch(({errors}) => {
      setErrors(errors)
    }).finally(() => {
      setLoading(false)
    })
  }

  if (!hasCommentWriteAccess(user, project!))
    return <></>

  const style = {
    top: commentedElementY + 'px'
  }

  return (
    <div className={`comment-container ${classNames ? classNames : ''}`} style={style} ref={newCommentRef}>
      <div className="card mb-2 elevate comment activated">
        <div className="card-body px-3 py-2">
          <div className="mb-2">
            <div className="d-flex justify-content-between align-items-center mb-2">
              <div className="small text-muted">{user.name}</div>
            </div>

            <CommentContentForm projectId={project?._id!}
                                content={content}
                                onContentChange={setContent}
                                onSubmit={onCreate}
                                autofocus={true} error={errors.content}/>
          </div>
          <div className="mb-2">
            <div>
              <button type="submit" className="btn btn-sm btn-success me-2" onClick={onCreate}>
                <span>{t('components.Report.comments.NewComment.save')}</span>
              </button>

              <button type="button" className="btn btn-sm btn-outline-secondary" onClick={onCancel} disabled={loading}>
                <span>{t('components.Report.comments.NewComment.cancel')}</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default NewComment