import {ObjectId} from 'bson'
import {GlossaryType, TermTranslation} from 'domain/Glossary'
import {Locale} from 'domain/Locale'
import {Permission} from 'domain/Permission'
import {StatusCodes} from 'http-status-codes'
import {ReactComponent as CloseIcon} from '../../../assets/icons/close.svg'
import {ReactComponent as GlossaryIcon} from '../../../assets/icons/glossary.svg'
import React, {ChangeEvent, FormEvent, useContext, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {Link} from 'react-router-dom'
import {addOrUpdateGlossaryTerm} from '../../../api-clients/glossaryTermClient'
import EventBus, {EventType} from '../../../EventBus'
import useToast from '../../../hooks/useToast'
import {ReportContext} from '../../../providers/ReportProvider'
import {TranslatePageContext} from '../../../providers/TranslatePageProvider'
import handleApiErrors from '../../../utils/clientErrorHandlingUtils'
import memoizeComponent from '../../../utils/memoizeComponent'
import Input from '../../utils/form/Input'
import Protected from '../../utils/Protected'


const {UNPROCESSABLE_ENTITY} = StatusCodes

interface Props {
  untranslatedTerms: string[]
  onTranslationAdded: (untranslatedTermsLeft: string[]) => void
}

const TranslationSidebar: React.FC<Props> = props => {
  const {untranslatedTerms, onTranslationAdded} = props
  const [translations, setTranslations] = useState(untranslatedTerms.map(term => ({term, translation: ''})))
  const {t} = useTranslation()
  const {renderSuccessToast} = useToast()
  const [dupTranslationIdx, setDupTranslationIdx] = useState<number | null>(null)
  const {company} = useContext(ReportContext)
  const {setShowTranslationSidebar} = useContext(TranslatePageContext)
  const {renderErrorToast} = useToast()

  const onAddedTranslation = (
    event: FormEvent<HTMLFormElement>, untranslatedIdx: number, term: string, translation: string) => {
    event.preventDefault()

    setDupTranslationIdx(null)

    const newTerm: TermTranslation = {
      _id: new ObjectId(),
      [Locale.JA]: term,
      [Locale.EN]: translation,
      isPrimary: false
    }

    addOrUpdateGlossaryTerm(GlossaryType.TABLE, newTerm, company?._id!)
      .then(() => {
        renderSuccessToast(t('glossary.term.addSuccess', {value: term}))
        setTranslations(translations.filter((t, idx) => idx !== untranslatedIdx))
        onTranslationAdded(untranslatedTerms.filter((t, idx) => idx !== untranslatedIdx))
      })
      .catch(handleApiErrors(t('glossary.errors.updatedByOther'), status => {
        if (status === UNPROCESSABLE_ENTITY) {
          setDupTranslationIdx(untranslatedIdx)
        } else {
          renderErrorToast(t('glossary.errors.notSaved'))
        }
      }))
  }

  useEffect(() => {
    document.body.classList.add('sidebar-right-open')

    return () => {
      document.body.classList.remove('sidebar-right-open')
    }
  }, [])

  const onSidebarClose = () => {
    setShowTranslationSidebar(false)
  }

  const updateTranslation = (e: ChangeEvent<HTMLInputElement>, idx: number) => {
    translations[idx].translation = e.target.value
    setTranslations([...translations])
  }

  const onTermHoverStart = (e: React.MouseEvent, term: string) => {
    EventBus.emit(EventType.HOVER, {term})
  }

  const onTermHoverEnd = () => {
    EventBus.emit(EventType.HOVER, {term: null})
  }

  return (
    <aside className="translation-sidebar report-sidebar report-sidebar-right">
      <div className="d-flex justify-content-between">
        <div className="text-muted fw-bold p-3">{t('components.TranslationSidebar.untranslatedTerms')}</div>
        <button type="button" className="btn btn-with-icon btn-sm btn-link text-muted ms-1 my-2" data-dismiss="modal"
                aria-label="Close" onClick={onSidebarClose}>
          <CloseIcon className="icon" />
        </button>
      </div>
      <div className="report-sidebar-body px-3">

        <div className="mb-3 ms-n1">
          <Link to={`/glossary/${company?._id}`} target="_blank" className="btn btn-with-icon btn-sm btn-link">
            <GlossaryIcon className="icon-sm"/>
            <span>{t('glossary.companyGlossary')}</span>
          </Link>
        </div>

        {translations.map(({term, translation}, idx) => {
          return (
            <article key={`${idx}-${term}`} className="card mb-2"
                     onMouseEnter={event => onTermHoverStart(event, term)}
                     onMouseLeave={onTermHoverEnd}>
              <div className="card-body p-2">
                <div className="mb-1">{term}</div>
                <Protected permission={Permission.Glossary.UPDATE}>
                  <form
                    onSubmit={(event: FormEvent<HTMLFormElement>) => onAddedTranslation(event, idx, term, translation)}>
                    <div className="input-group input-group-sm">
                      <Input className="form-control form-control-sm"
                             value={translations[idx].translation}
                             required
                             onChange={(e: ChangeEvent<HTMLInputElement>) => updateTranslation(e, idx)}
                             error={dupTranslationIdx === idx ?
                               t('glossary.errors.duplicateTranslation') : ''}
                      />
                      <div className="input-group-append">
                        <button type="submit" className="btn btn-outline-primary btn-sm">
                          <span>{t('glossary.term.addTerm')}</span>
                        </button>
                      </div>
                    </div>
                  </form>
                </Protected>
              </div>
            </article>
          )
        })}
      </div>
    </aside>
  )
}

export default memoizeComponent(TranslationSidebar)