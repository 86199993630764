import {API, BlockAPI} from '@editorjs/editorjs'
import moveToOriginalIcon from '../../../assets/inline-svg/open_in_browser.svg'
import scrollToElement from '../../../utils/scrollToElement'
import {make} from '../dom'
import {CloneData, clonedBlockClass} from './Clone'


interface Tune {
  title: string
  icon: string
  action: () => void
}

class Tunes {
  private readonly scrollToSourceBlockAction: () => void

  constructor(private api: API) {
    this.scrollToSourceBlockAction = () => this.scrollToSourceBlock()
  }

  get CSS() {
    return {
      buttonBase: this.api.styles.settingsButton,
      buttonActive: this.api.styles.settingsButtonActive
    }
  }

  get tunes(): Tune[] {
    return [
      {
        title: 'Move to original',
        icon: moveToOriginalIcon,
        action: this.scrollToSourceBlockAction
      }
    ]
  }

  render(data: CloneData) {
    const wrapper = make('div')

    this.tunes.forEach(tune => {
      const title = this.api.i18n.t(tune.title)
      const el = make('div', [this.CSS.buttonBase], {
        innerHTML: tune.icon,
        title
      })

      el.addEventListener('click', () => {
        tune.action()
      })

      this.api.tooltip.onHover(el, title, {
        placement: 'top'
      })

      wrapper.appendChild(el)
    })

    return wrapper
  }

  private scrollToSourceBlock() {
    const block = (this.api.blocks.getBlockByIndex(this.api.blocks.getCurrentBlockIndex()) as BlockAPI)
    const sourceId = (block.holder.querySelector(`.${clonedBlockClass}`) as HTMLElement).dataset.sourceId!
    const sourceElement = document.getElementById(sourceId)
    if (sourceElement) scrollToElement(sourceElement)
  }
}

export default Tunes