const getNavigableRevisionDiffs = (isDeletionVisible: boolean): HTMLElement[] => {
  const allDiffEls = Array.from(document.querySelectorAll(
    [
      '.report-content-block ins',
      isDeletionVisible && '.report-content-block del'
    ].filter(v => !!v).join(', ')
  ))

  return Array.from(new Set(allDiffEls.map(el => {
    return el.closest('.report-content-block')
  }))) as HTMLElement[]
}

export default getNavigableRevisionDiffs