import {LargePasteAggregate} from 'domain/report-statistics/LargePaste'
import {ReactComponent as DownloadIcon} from '../../assets/icons/cloud_download.svg'
import {ReactComponent as RefreshIcon} from '../../assets/icons/refresh_black.svg'
import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {downloadCsv, getLargePastes} from '../../api-clients/largePasteClient'
import useToast from '../../hooks/useToast'
import {subtractDays} from '../../utils/dateUtils'
import {formatDate} from '../../utils/momentUtils'
import {LARGE_PASTE_MINIMUM_LENGTH, LARGE_PASTE_MINIMUM_NEW_LINES} from '../report/edit/largePasteDetector'
import {downloadBlob} from '../report/navigation/navigationUtils'
import Input from '../utils/form/Input'


const LargePastesPage: React.FC = () => {
  const {t} = useTranslation()
  const {renderErrorToast} = useToast()
  const [startDate, setStartDate] = useState<string>(formatDate(subtractDays(new Date(), 6), 'YYYY-MM-DD'))
  const [endDate, setEndDate] = useState<string>(formatDate(new Date(), 'YYYY-MM-DD'))

  const [largePastes, setLargePastes] = useState<LargePasteAggregate[]>([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)
    getLargePastes(startDate, endDate).then(setLargePastes).finally(() => setLoading(false))
  }, [])

  const onStartDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {value} = e.target
    setStartDate(value)
  }

  const onEndDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {value} = e.target
    setEndDate(value)
  }

  const refreshData = () => {
    setLoading(true)
    getLargePastes(startDate, endDate).then(setLargePastes).finally(() => setLoading(false))
  }

  const onDownloadCsv = (event: React.MouseEvent) => {
    event.preventDefault()
    downloadCsv(startDate, endDate).then(blob => {
      downloadBlob(blob, `large-pastes-${startDate}-${endDate}.csv`)
    }).catch(_ => {
      renderErrorToast(t('components.LargePastePage.downloadError'))
    })
  }

  return (
    <div className="container py-5">
      <div className="align-items-center my-5">
        <h1 className="display-3 fw-bolder">
          {t('components.LargePastePage.title')}
        </h1>
        <div>
          {t('components.LargePastePage.description', {
            minimumNewLines: LARGE_PASTE_MINIMUM_NEW_LINES,
            minimumCharacters: LARGE_PASTE_MINIMUM_LENGTH
          })}
        </div>
      </div>

      <div className="card elevate">
        <div className="card-body">
          <div className="d-flex justify-content-between">
            <div className="d-flex justify-content-start align-items-end">
              <div className="pe-3">
                <label htmlFor="start-date"
                       className="form-label small">{t('components.LargePastePage.startDate')}</label>
                <Input type="date" className="form-control" id="start-date" name="start-date" required={true}
                       value={startDate} onChange={onStartDateChange}/>
              </div>
              <div className="pe-3">
                <label htmlFor="end-date"
                       className="form-label small">{t('components.LargePastePage.endDate')}</label>
                <div className="d-flex align-items-center">
                  <Input type="date" className="form-control me-3" id="end-date" name="end-date" required={true}
                         value={endDate} onChange={onEndDateChange}/>
                  <button type="button" className="btn btn-sm btn-outline-secondary btn-icon-only" onClick={refreshData}
                          disabled={loading}>
                    <RefreshIcon className="icon icon-sm"/>
                  </button>
                </div>
              </div>
            </div>
            <div>
              <button onClick={onDownloadCsv}
                      title={t('components.LargePastePage.downloadCsv')}
                      className="btn btn-outline-secondary btn-sm btn-icon-only px-2 ms-2">
                <DownloadIcon className="icon icon-sm"/>
                <span>{t('components.LargePastePage.downloadCsv')}</span>
              </button>
            </div>
          </div>
        </div>

        <table className="table table-sm small table-hover table-clickable-rows">
          <thead>
          <tr>
            <th>{t('models.User.name')}</th>
            <th>{t('models.User.email')}</th>
            <th>{t('models.LargePasteAggregate.count')}</th>
          </tr>
          </thead>
          <tbody>
          {loading
            ?
            <tr>
              <td>
                <div className="d-flex justify-content-center">
                  <span className="spinner-border spinner-border-lg me-1" role="status" aria-hidden="true"/>
                </div>
              </td>
            </tr>
            :
            <>
              {largePastes.map(largePasteAggregate => (
                <tr key={largePasteAggregate.user._id as string}>
                  <td>{largePasteAggregate.user.name}</td>
                  <td className="text-break">{largePasteAggregate.user.email}</td>
                  <td>{largePasteAggregate.count}</td>
                </tr>))
              }
            </>}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default LargePastesPage
