import {Id} from 'domain/Entity'
import {TranslationRequest} from 'domain/TranslationRequest'
import apiClient from '../ApiClient'
import {ProjectAggregate} from 'domain/ProjectAggregate'


const requestTranslation = async (projectId: Id, translatorId: Id, message: string): Promise<TranslationRequest> => {
  return await apiClient.post(`/api/projects/translation-requests`, {projectId, translatorId, message})
}

const acceptTranslationRequest = async (translationRequestId: Id): Promise<void> => {
  return await apiClient.patch(`/api/projects/translation-requests/${translationRequestId}/accept`, {})
}

const rejectTranslationRequest = async (translationRequestId: Id, reply: string): Promise<ProjectAggregate> => {
  return await apiClient.patch(`/api/projects/translation-requests/${translationRequestId}/reject`, {reply})
}

const rejectAcceptedTranslationRequest = async (translationRequestId: Id, reply: string): Promise<ProjectAggregate> => {
  return await apiClient.patch(`/api/projects/translation-requests/${translationRequestId}/reject-accepted`, {reply})
}

const cancelTranslationRequest = async (translationRequestId: Id, cancelReply: string): Promise<ProjectAggregate> => {
  return await apiClient.patch(`/api/projects/translation-requests/${translationRequestId}/cancel`, {cancelReply})
}

export {
  requestTranslation,
  acceptTranslationRequest,
  rejectTranslationRequest,
  rejectAcceptedTranslationRequest,
  cancelTranslationRequest
}