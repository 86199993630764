import {stages} from 'domain/project-stages/stages'
import {ProjectStageRule} from 'domain/ProjectStageFlow'
import {ProjectMilestone} from 'domain/Report'


const flashReportStages: ProjectStageRule[] = [
  stages.ORIGINAL_WRITING({hours: 2, milestone: ProjectMilestone.ORIGINAL_REPORT_WRITING_FINISHED}),
  stages.ORIGINAL_SELECT_CONTENT({hours: 1}),
  stages.ORIGINAL_REVIEW({hours: 1}),
  stages.ORIGINAL_POST_REVIEW_WRITING1({hours: 1, milestone: ProjectMilestone.ORIGINAL_REPORT_FINISHED}),
  stages.TRANSLATION_PREPARATION({hours: 1}),
  stages.TRANSLATION_WRITING({hours: 2, milestone: ProjectMilestone.TRANSLATED_REPORT_WRITING_FINISHED}),
  stages.TRANSLATION_REVIEW({hours: 1, milestone: ProjectMilestone.TRANSLATED_REPORT_FINISHED}),
  stages.PUBLISH({hours: 1, milestone: ProjectMilestone.PROJECT_FINISHED})
]

export default flashReportStages