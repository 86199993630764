import {Id} from 'domain/Entity'
import {GlossaryType} from 'domain/Glossary'
import {Permission} from 'domain/Permission'
import {ReactComponent as ArrowDownIcon} from '../../assets/icons/arrow_down.svg'
import {ReactComponent as ArrowRightIcon} from '../../assets/icons/arrow_right.svg'
import {ReactComponent as DoneIcon} from '../../assets/icons/done.svg'
import React, {FC, ReactElement, useState} from 'react'
import {Collapse} from 'react-collapse'
import {useTranslation} from 'react-i18next'
import {uploadGlossaryFile} from '../../api-clients/glossaryTermClient'
import useModal from '../../hooks/useModal'
import ButtonWithLoader from '../utils/form/ButtonWithLoader'
import Input from '../utils/form/Input'
import ModalTemplate from '../utils/ModalTemplate'
import Protected from '../utils/Protected'
import RawHtml from '../utils/RawHtml'

interface UploadGlossaryProps {
  companyId?: Id
  onUploaded: () => void
  glossaryType: GlossaryType
}

const UploadGlossary: FC<UploadGlossaryProps> = ({companyId, onUploaded, glossaryType}): ReactElement => {
  const {t} = useTranslation()
  const {closeModal} = useModal()
  const [file, setFile] = useState<File | null>(null)
  const [loading, setLoading] = useState(false)
  const [collapsedInstructions, setCollapsedInstructions] = useState(true)
  const [errors, setErrors] = useState<{filetype?: string; filecontent?: string; terms?: string; term?: string}>({})
  const [clearOnUploading, setClearOnUploading] = useState(false)

  const onFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]
    setFile(file || null)
  }

  const onToggleCollapseInstructions = (event: React.MouseEvent) => {
    event.preventDefault()
    setCollapsedInstructions(collapsed => !collapsed)
  }

  const onConfirm = (event: React.MouseEvent<HTMLButtonElement> | React.FormEvent) => {
    event.preventDefault()

    if (!file) {
      return
    }

    setLoading(true)
    uploadGlossaryFile(glossaryType, companyId, file, clearOnUploading)
      .then(() => {
        setLoading(false)
        closeModal()
        onUploaded()
      })
      .catch(({errors}) => {
        setLoading(false)
        setErrors(errors)
        console.error('error on uploading file', errors)
      })
  }

  return (
    <ModalTemplate size="lg" title={t('components.UploadGlossary.title', {type: t(`glossary.types.${glossaryType}`)})}>
      <form onSubmit={onConfirm}>
        <div className="modal-body">
          <div className="mb-3">
            <div className="custom-file">
              <Input
                type="file"
                accept=".csv,text/csv"
                className="form-control"
                id="uploadFile"
                onChange={onFileChange}
              />

              {errors.filetype && (
                <div className="invalid-feedback d-block">
                  {t('components.UploadGlossary.errors.' + errors.filetype)}
                </div>
              )}

              {errors.filecontent && (
                <div className="invalid-feedback d-block">
                  {t('components.UploadGlossary.errors.' + errors.filecontent)}
                </div>
              )}

              {errors.terms && errors.term && (
                <div className="invalid-feedback d-block">
                  {t('components.UploadGlossary.errors.invalidJaTerm', {term: errors.term})}
                </div>
              )}
            </div>
          </div>
          <Protected permission={Permission.Glossary.DELETE_ALL}>
            <div className="form-check pt-2 mb-3">
              <input
                type="checkbox"
                id="clearOnUploading"
                name="type"
                className="form-check-input"
                checked={clearOnUploading}
                onChange={_ => setClearOnUploading(!clearOnUploading)}
              />
              <label className="form-check-label" htmlFor="clearOnUploading">
                {t('components.UploadGlossary.clearOnUploading')}
              </label>
            </div>
          </Protected>

          <a href="" onClick={onToggleCollapseInstructions}>
            {t('components.UploadGlossary.instructions.title')}
            {collapsedInstructions ? <ArrowRightIcon className="icon" /> : <ArrowDownIcon className="icon" />}
          </a>

          <Collapse
            isOpened={!collapsedInstructions}
            theme={{collapse: 'transition-slide-up', content: 'transition-slide-down'}}
          >
            <div className="border p-2 mt-2">
              <h6>{t('components.UploadGlossary.instructions.body.excelTitle')}</h6>
              <ol>
                <li>
                  <RawHtml>{t('components.UploadGlossary.instructions.body.openFile')}</RawHtml>
                </li>
                <li>
                  <RawHtml>{t('components.UploadGlossary.instructions.body.noHeaders')}</RawHtml>
                </li>
                <li>
                  <RawHtml>{t('components.UploadGlossary.instructions.body.newRow')}</RawHtml>
                </li>
                <li>
                  <RawHtml>{t('components.UploadGlossary.instructions.body.columns')}</RawHtml>
                </li>
                <li>
                  <RawHtml>{t('components.UploadGlossary.instructions.body.saveAsCsv')}</RawHtml>
                </li>
                <li>
                  <RawHtml>{t('components.UploadGlossary.instructions.body.save')}</RawHtml>
                </li>
                <li>
                  <RawHtml>{t('components.UploadGlossary.instructions.body.extension')}</RawHtml>
                </li>
                <li>
                  <RawHtml>{t('components.UploadGlossary.instructions.body.checkContent')}</RawHtml>
                  <div>
                    <code>
                      "四半期業績推移","Quarterly","Only for tables"
                      <br />
                      "収益性","Profit margins"
                      <br />
                      "EBITDA マージン","EBITDA margin"
                      <br />
                      "利益率（マージン）","Net margin"
                      <br />
                    </code>
                  </div>
                </li>
                <li>
                  <RawHtml>{t('components.UploadGlossary.instructions.body.import')}</RawHtml>
                </li>
              </ol>
            </div>
          </Collapse>
        </div>

        <div className="modal-footer">
          <ButtonWithLoader
            loading={loading}
            type="submit"
            className="btn btn-success btn-with-icon"
            onClick={event => onConfirm(event)}
            disabled={!file}
          >
            <span>{t('components.UploadGlossary.uploadButton')}</span>
            <DoneIcon className="icon" />
          </ButtonWithLoader>
        </div>
      </form>
    </ModalTemplate>
  )
}

export default UploadGlossary
