import {Locale} from 'domain/Locale'
import {ReportUpdateType} from 'domain/Websockets'
import React, {useContext, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {ReportContext} from '../../../providers/ReportProvider'
import websocket from '../../../websocket'
import ButtonWithLoader from '../../utils/form/ButtonWithLoader'

type Props = {
  locale: Locale
  loadReport: () => Promise<void>
}

const ReportReload: React.FC<Props> = ({locale, loadReport}) => {
  const {t} = useTranslation()
  const {project} = useContext(ReportContext)
  const [reportUpdated, setReportUpdated] = useState(false)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    websocket.onReportUpdated(project!._id, data => {
      const showNotification = locale === Locale.EN || data.type === ReportUpdateType.SECTION || data.locale === locale
      if (showNotification) setReportUpdated(true)
    })

    return () => {
      websocket.stopListeningReportUpdated(project!._id)
    }
  }, [])

  const reloadReport = (e: React.MouseEvent) => {
    e.preventDefault()
    setLoading(true)
    loadReport()
      .then(() => {
        setReportUpdated(false)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return reportUpdated ? (
    <ButtonWithLoader className="btn btn-success btn-sm" onClick={reloadReport} loading={loading}>
      {t('components.ReportReload.pullChanges')}
    </ButtonWithLoader>
  ) : (
    <></>
  )
}

export default ReportReload
