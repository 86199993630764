import {BlockType, ReportState} from 'domain/Report'
import deepCopy from '../../utils/deepCopy'


export const toggleBlockSection = (
  report: ReportState, blockId: string, newState: boolean, halfSelect = false) => {

  const skipSource =  newState && halfSelect
  const skipChildren = !newState && halfSelect

  return report.sections.map(section => {
    const newBlocks = []
    for (let i = 0; i < section.blocks.length; i++) {
      const block = section.blocks[i]
      if (block.id === blockId) {
        const newBlock = deepCopy(block)
        if (!skipSource) {
          newBlock.checked = newState
        }
        newBlocks.push(newBlock)

        if (!skipChildren && block.type === BlockType.HEADING) {
          const level = block.level
          while ((++i) < section.blocks.length) {
            const blockInside = section.blocks[i]
            if (blockInside.type === BlockType.HEADING && blockInside.level <= level) {
              newBlocks.push(blockInside)
              break
            } else {
              const newBlockInside = deepCopy(blockInside)
              newBlockInside.checked = newState
              newBlocks.push(newBlockInside)
            }
          }
        }
      } else {
        newBlocks.push(block)
      }
    }
    return {...section, blocks: newBlocks}
  })
}