import {isOnlyCompanyRepresentative} from 'domain/User'
import {StatusCodes} from 'http-status-codes'
import React, {PropsWithChildren, useContext, useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {postClientError} from './api-clients/clientErrors'
import useToast from './hooks/useToast'
import {UserContext} from './providers/UserProvider'

const ErrorHandler: React.FC<PropsWithChildren> = ({children}) => {
  const {user} = useContext(UserContext)
  const {renderErrorToast} = useToast()
  const {t} = useTranslation()

  useEffect(() => {
    window.addEventListener('unhandledrejection', unhandledRejectionHandler)

    return () => {
      window.removeEventListener('unhandledrejection', unhandledRejectionHandler)
    }
  }, [])

  const unhandledRejectionHandler = (rejectionEvent: PromiseRejectionEvent) => {
    const {reason} = rejectionEvent
    // tslint:disable-next-line:quotemark
    if (reason.message && reason.message.includes("Pass correct holder's ID")) {
      return
    }

    switch (reason.message) {
      case 'errors.apiVersionMismatch': {
        window.confirm(t('errors.apiVersionMismatch'))
        window.location.reload()
        break
      }
      case 'errors.networkUnavailable': {
        renderErrorToast(t('errors.networkUnavailable'))
        break
      }
      case 'errors.requestTimedOut': {
        renderErrorToast(t('errors.requestTimedOut'))
        postClientError({reason} || rejectionEvent)
        break
      }
      case 'errors.reportSendError': {
        if (isOnlyCompanyRepresentative(user.roles) && reason.status === StatusCodes.BAD_REQUEST) {
          postClientError({reason} || rejectionEvent)
          break
        }
        renderErrorToast(t('errors.unexpected'))
        break
      }
      case 'errors.reportLoadError': {
        renderErrorToast(t('errors.unexpected'))
        break
      }
      case 'errors.payloadTooLarge': {
        renderErrorToast(t('errors.payloadTooLarge'))
        break
      }
      case 'errors.reportUpdateError': {
        renderErrorToast(t('errors.reportUpdateFailed'))
        postClientError({reason} || rejectionEvent)
        break
      }
      default: {
        renderErrorToast(t('errors.unexpected'))
        postClientError({reason} || rejectionEvent)
      }
    }
  }

  return <>{children}</>
}

export default ErrorHandler
