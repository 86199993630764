interface String {
  trimHTMLSpaces(): string
  removeHTMLTags(): string
  replaceHtmlSpaces(): string
}

String.prototype.trimHTMLSpaces = function () {
  return this.replace(/^(&nbsp;)+/g, '').replace(/(&nbsp;)+$/g, '')
}

String.prototype.removeHTMLTags = function () {
  return this.replace(/(<([^>]+)>)/ig, '')
    .replace(/&amp;/g, '&')
    .replace(/&lt;/g, '<')
    .replace(/&gt;/g, '>')
}

String.prototype.replaceHtmlSpaces = function() {
  return this.replace(/&nbsp;/g, ' ')
}