import {Id} from 'domain/Entity'
import {Locale} from 'domain/Locale'
import {ReportState} from 'domain/Report'
import {ReactComponent as ArrowDownIcon} from '../../assets/icons/arrow_down.svg'
import {ReactComponent as ArrowRightIcon} from '../../assets/icons/arrow_right.svg'
import {ReactComponent as DoneIcon} from '../../assets/icons/done.svg'
import React, {FC, ReactElement, useState} from 'react'
import {Collapse} from 'react-collapse'
import {useTranslation} from 'react-i18next'
import {uploadReportFile} from '../../api-clients/reportClient'
import useModal from '../../hooks/useModal'
import useToast from '../../hooks/useToast'
import reportLockManager from '../report/ReportLockManager'
import ButtonWithLoader from '../utils/form/ButtonWithLoader'
import Input from '../utils/form/Input'
import ModalTemplate from '../utils/ModalTemplate'
import RawHtml from '../utils/RawHtml'

interface UploadFileProps {
  projectId: Id
  locale: Locale
  onUploaded: (report: ReportState) => void
}

const UploadFile: FC<UploadFileProps> = ({projectId, locale, onUploaded}): ReactElement => {
  const {t} = useTranslation()
  const {closeModal} = useModal()
  const [file, setFile] = useState<File | null>(null)
  const [loading, setLoading] = useState(false)
  const [collapsedInstructions, setCollapsedInstructions] = useState(true)
  const {renderErrorToast} = useToast()
  const [addNewsUpdatesSections, setAddNewsUpdatesSections] = useState<boolean>(true)

  const onToggleCollapseInstructions = (event: React.MouseEvent) => {
    event.preventDefault()
    setCollapsedInstructions(collapsed => !collapsed)
  }

  const onFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFile(event.target.files?.[0] || null)
  }

  const onConfirm = (event: React.MouseEvent<HTMLButtonElement> | React.FormEvent) => {
    event.preventDefault()

    if (!file) {
      return
    }

    setLoading(true)
    uploadReportFile(projectId, file, locale, addNewsUpdatesSections)
      .then(report => {
        setLoading(false)
        closeModal()
        reportLockManager.notifyReportMigrated(projectId)
        onUploaded(report)
      })
      .catch(({errors}) => {
        setLoading(false)
        if (errors.diff === 'blank') {
          renderErrorToast(t('components.Project.UploadFile.blankDiffError'))
        }
      })
  }

  return (
    <ModalTemplate size="lg" title={t(`components.Project.UploadFile.title.${locale}`)}>
      <form onSubmit={onConfirm}>
        <div className="modal-body">
          <div className="mb-3">
            <div className="custom-file">
              <Input type="file" className="form-control" id="uploadFile" onChange={onFileChange} />
            </div>
          </div>

          <div className="form-check pb-2">
            <input
              type="checkbox"
              id="addNewsUpdatesSections"
              name="type"
              className="form-check-input"
              checked={addNewsUpdatesSections}
              onChange={() => setAddNewsUpdatesSections(!addNewsUpdatesSections)}
            />
            <label className="form-check-label" htmlFor="addNewsUpdatesSections">
              {t('components.Project.UploadFile.addNewsUpdatesSections')}
            </label>
          </div>

          <a href="" onClick={onToggleCollapseInstructions}>
            {t('components.Project.UploadFile.instructions.title')}
            {collapsedInstructions ? <ArrowRightIcon className="icon" /> : <ArrowDownIcon className="icon" />}
          </a>

          <Collapse
            isOpened={!collapsedInstructions}
            theme={{collapse: 'transition-slide-up', content: 'transition-slide-down'}}
          >
            <div className="border p-2 mt-2">
              <h6>{t('components.Project.UploadFile.instructions.body.ja_title')}</h6>
              <ol>
                <li>
                  <RawHtml>{t('components.Project.UploadFile.instructions.body.disable_tracking_changes')}</RawHtml>
                </li>
                <li>
                  <RawHtml>{t('components.Project.UploadFile.instructions.body.run_macro')}</RawHtml>
                </li>
                <li>
                  <RawHtml>{t('components.Project.UploadFile.instructions.body.remove_redundant_pages')}</RawHtml>
                </li>
                <li>
                  {t('components.Project.UploadFile.instructions.body.review_formatting.body')}
                  <ul>
                    <li>
                      <RawHtml>
                        {t('components.Project.UploadFile.instructions.body.review_formatting.chart_title')}
                      </RawHtml>
                    </li>
                    <li>
                      <RawHtml>
                        {t('components.Project.UploadFile.instructions.body.review_formatting.chart_source')}
                      </RawHtml>
                    </li>
                    <li>
                      <RawHtml>{t('components.Project.UploadFile.instructions.body.review_formatting.alert')}</RawHtml>
                    </li>
                    <li>
                      <RawHtml>
                        {t('components.Project.UploadFile.instructions.body.review_formatting.bulleted_paragraphs')}
                      </RawHtml>
                    </li>
                  </ul>
                </li>
                <li>{t('components.Project.UploadFile.instructions.body.save')}</li>
                <li>{t('components.Project.UploadFile.instructions.body.upload')}</li>
                <li>{t('components.Project.UploadFile.instructions.body.review')}</li>
              </ol>

              <h6>{t('components.Project.UploadFile.instructions.body.en_title')}</h6>
              <ol>
                <li>{t('components.Project.UploadFile.instructions.body.repeat_steps_above')}</li>
                <li>{t('components.Project.UploadFile.instructions.body.review_unmatched_translation')}</li>
                <li>{t('components.Project.UploadFile.instructions.body.re_upload')}</li>
              </ol>
            </div>
          </Collapse>
        </div>

        <div className="modal-footer">
          <ButtonWithLoader
            loading={loading}
            type="submit"
            className="btn btn-success btn-with-icon"
            onClick={event => onConfirm(event)}
          >
            <span>{t('components.Project.UploadFile.upload')}</span>
            <DoneIcon className="icon" />
          </ButtonWithLoader>
        </div>
      </form>
    </ModalTemplate>
  )
}

export default UploadFile
