import {API, BaseTool} from '@editorjs/editorjs'
import {
  EditorCommonTableBlockCellData,
  EditorCommonTableBlockData,
  EditorCommonTableBlockRowData,
  EditorTableConfig
} from 'domain/Editor'
import {BackgroundStyle, MainTableStyle, TextAlign} from 'domain/Report'
import tableNormalIcon from '../../../assets/inline-svg/table-normal.svg'
import CommonTableBuilder from './CommonTableBuilder'
import Tunes from './Tunes'


type CommonTableConfig = {
  titlePlaceholder?: string,
  descriptionPlaceholder?: string
  allowChangeLayout?: boolean
}

interface CommonTableConstructorParam {
  data?: EditorCommonTableBlockData
  config?: CommonTableConfig
  api: API
  readOnly?: boolean
}

class CommonTable implements BaseTool {
  api: API
  config: CommonTableConfig
  settingsButtons: HTMLElement[]
  data: EditorCommonTableBlockData
  builder: CommonTableBuilder
  tunes: Tunes
  readonly: boolean

  constructor({data, config, api, readOnly}: CommonTableConstructorParam) {
    this.api = api
    this.config = config || {} as EditorTableConfig
    this.settingsButtons = []
    this.readonly = readOnly || false

    if (data && Object.keys(data).length > 0) {
      this.data = data
    } else {
      this.data = {
        title: '',
        description: '',
        style: MainTableStyle.COMPACT,
        additionalStyles: [],
        ...CommonTable.defaultTableData()
      }
    }

    this.data = {
      ...this.data,
      titlePlaceholder: config?.titlePlaceholder,
      descriptionPlaceholder: config?.descriptionPlaceholder
    }

    const allowChangeLayout = this.config.allowChangeLayout ?? true
    this.builder = new CommonTableBuilder(
      this.api,
      this.data,
      this.readonly,
      allowChangeLayout,
      CommonTable.defaultCellData
    )

    this.tunes = new Tunes(
      this.api,
      tableStyle => {
        this.builder.setTableStyle(tableStyle)
      },
      (additionalStyle, button) => {
        this.builder.setAdditionalStyle(additionalStyle, button)
      }
    )
  }

  static get isReadOnlySupported() {
    return true
  }

  static defaultTableData(): { rows: EditorCommonTableBlockRowData[] } {
    return {
      rows: [
        {cells: [this.defaultHeaderCellData(), this.defaultHeaderCellData()]},
        {cells: [this.defaultCellData(), this.defaultCellData()]}
      ]
    }
  }

  static defaultHeaderCellData(): EditorCommonTableBlockCellData {
    return {value: '', style: BackgroundStyle.HEADER}
  }

  static defaultCellData(): EditorCommonTableBlockCellData {
    return {value: ''}
  }

  static get toolbox() {
    return {
      title: 'Table',
      icon: tableNormalIcon
    }
  }

  render(): HTMLElement {
    return this.builder.ui()
  }

  rendered() {
    this.builder.enableTableResizing()
    this.builder.enableSelection()
  }

  renderSettings(): HTMLElement {
    return this.tunes.render(this.data)
  }

  async save() {
    const table = this.builder.nodes.table as HTMLTableElement
    return {
      style: table.dataset.style,
      additionalStyles: this.data.additionalStyles,
      title: this.builder.nodes.title.innerHTML,
      description: this.builder.nodes.description.innerHTML,
      rows: Array.from(table.rows).map((row, rowIdx) => (
        {
          cells: Array.from(table.rows[rowIdx].cells).map(cell => {
            const cellData: EditorCommonTableBlockCellData = {
              value: cell.querySelector('.' + CommonTableBuilder.CSS.cellContentWrapper)!.innerHTML,
              rowSpan: cell.rowSpan,
              colSpan: cell.colSpan,
              style: cell.dataset.style as BackgroundStyle
            }

            if (cell.style.width) {
              cellData.width = cell.style.width
            }

            if (cell.dataset.align) {
              cellData.align = cell.dataset.align as TextAlign
            }

            return cellData
          })
        }
      ))
    }
  }

  destroy() {
    this.builder.destroy()
  }
}

export default CommonTable
