import {ReactComponent as CloseIcon} from '../../assets/icons/close.svg'
import {ReactComponent as DoneIcon} from '../../assets/icons/done.svg'
import React, {ReactElement, useState} from 'react'
import {useTranslation} from 'react-i18next'
import useModal from '../../hooks/useModal'
import ModalTemplate from '../utils/ModalTemplate'
import ButtonWithLoaderAndIcon from './form/ButtonWithLoaderAndIcon'
import RawHtml from './RawHtml'


interface Props {
  title?: string,
  body?: string,
  ConfirmationIcon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>,
  onConfirm: () => Promise<any>
  btnClassName?: string,
  closeOnlyLast?: boolean
}

const ConfirmationModal: React.FC<Props> = (props): ReactElement => {
  const {
    ConfirmationIcon = DoneIcon,
    title,
    body,
    onConfirm,
    btnClassName = 'btn-success',
    closeOnlyLast = false
  } = props
  const {closeModal, closeLastModal} = useModal()
  const {t} = useTranslation()
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)


  const onClickConfirm = (event: React.MouseEvent) => {
    event.preventDefault()
    setLoading(true)
    onConfirm().finally(() => {
      setLoading(false)
      closeModal()
    }).catch(error => setErrorMessage(error?.errors?.message ?? 'Unknown Error'))
  }

  const onClickClose = (_: React.MouseEvent) => {
    closeOnlyLast ? closeLastModal() : closeModal()
  }

  return (
    <ModalTemplate title={title}>
      {errorMessage && (<div className="modal-body">
        <div className="text-danger">{errorMessage}</div>
      </div>)}
      {body && (<div className="modal-body">
        <RawHtml>{body}</RawHtml>
      </div>)}
      <div className="modal-footer justify-content-between">
        <button type="button" className="btn btn-danger btn-with-icon" onClick={onClickClose}>
          <span>{t('components.ConfirmationModal.cancel')}</span>
          <CloseIcon className="icon" />
        </button>
        <ButtonWithLoaderAndIcon type="submit" className={`btn ${btnClassName} btn-with-icon`} onClick={onClickConfirm}
                                 loading={loading} icon={<ConfirmationIcon className="icon" />} id={'confirmModal'}>
          <span>{t('components.ConfirmationModal.confirm')}</span>
        </ButtonWithLoaderAndIcon>
      </div>
    </ModalTemplate>
  )
}

export default ConfirmationModal
