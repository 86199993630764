import React, {useEffect, useState} from 'react'
import useToast from '../../hooks/useToast'
import {Toast, ToastType} from '../../providers/ToastProvider'


interface ToastProps {
  toast: Toast
}

const Toast: React.FC<ToastProps> = ({toast}) => {
  const {removeToast} = useToast()
  const [isClosing, setIsClosing] = useState(false)
  const [duration] = useState(4000)

  useEffect(() => {
    setTimeout(() => {
      registerToastClosing()
    }, duration - 100)
  }, [])

  const registerToastClosing = () => {
    setIsClosing(true)

    setTimeout(() => {
      removeToast(toast)
    }, 100)
  }

  const textBrightness = toast.type === ToastType.WARNING ? 'dark' : 'white'

  return (
    <div
      className={`toast d-flex align-items-center text-${textBrightness} border-0 show bg-${toast.type.toLowerCase()} transition-slide-in-down ${isClosing ? 'slide-out-blurred-top' : ''}`}
      role="alert" aria-live="assertive" aria-atomic="true">
      <div className="toast-body">
        {toast.content}
      </div>
      <button type="button" className={`btn-close btn-close-${textBrightness} ms-auto me-2`}
              data-dismiss="toast" aria-label="Close"
              onClick={() => registerToastClosing()}/>
    </div>
  )
}

export default Toast