import {EditorTableBlockData} from 'domain/Editor'
import {CellTemplate, MainTableStyle, TableStyle} from 'domain/Report'


const isMainTableStyle = (style: TableStyle) => {
  return Object.keys(MainTableStyle).includes(style)
}

const getTableClassNames = (styles: TableStyle[]): string[] => {
  const classNames = ['table', 'table-hover', 'table-report', 'excel-table']
  for (const style of styles){
    isMainTableStyle(style) ? classNames.push(getStyleClass(style as MainTableStyle)) : classNames.push(style)
  }
  return classNames
}

const getStyleClass = (style: MainTableStyle): string => {
  return `table-report-${style.toLowerCase().replace('_', '-')}`
}

const getTableStyles = (data: EditorTableBlockData): TableStyle[] => {
  const styles = [data.style]
  if (data.additionalStyles && data.additionalStyles.length)
    return [...styles, ...data.additionalStyles]
  return styles
}

const getCellClassNames = (template: CellTemplate): string[] => {
  const classNames = []

  classNames.push(`table-cell-text-${template.fontColorStyle?.toLowerCase()}`)
  classNames.push(`table-cell-${template.type?.toLowerCase()}`)
  classNames.push(`table-cell-${template.backgroundStyle?.toLowerCase().replace('_', '-')}`)

  if (template.hasBorderLeft) {
    classNames.push('table-cell-left-border')
  }

  if (template.hasBorderRight) {
    classNames.push('table-cell-right-border')
  }

  return classNames
}

export {getCellClassNames, getTableClassNames, getStyleClass, isMainTableStyle, getTableStyles}