import {Locale} from 'domain/Locale'
import {GridBlock} from 'domain/Report'
import {ReactComponent as ArrowRightIcon} from '../../../assets/icons/arrow_forward.svg'
import React, {useContext} from 'react'
import {useTranslation} from 'react-i18next'
import useToast from '../../../hooks/useToast'
import {ReportContext} from '../../../providers/ReportProvider'
import {applyGridStructureFromSourceLocale} from './gridCloneUtils'
import {isSourceBlockLocked} from './sourceBlockLockedUtils'

interface TranslationGridToolbarProps {
  sectionId: string
  block: GridBlock
  locale: Locale
}

const TranslationGridToolbar: React.FC<TranslationGridToolbarProps> = ({sectionId, block, locale}) => {
  const {t} = useTranslation()
  const {reportStore} = useContext(ReportContext)
  const {renderSuccessToast} = useToast()
  const updateFromOriginal = (e: React.MouseEvent) => {
    e.preventDefault()
    if (isSourceBlockLocked(block.id)) {
      return
    }
    if (isEmptyContent(block, Locale.EN)) {
      const newBlock = applyGridStructureFromSourceLocale(block, Locale.JA, Locale.EN)
      reportStore.updateSectionBlock(sectionId, newBlock, locale)
      renderSuccessToast(t('components.TranslationGridToolbar.copyingSuccessful'))
    }
  }

  const isEmptyContent = (block: GridBlock, locale: Locale) => {
    return !block.grid[locale] || !block.grid[locale]?.length
  }

  return isEmptyContent(block, Locale.EN) ? (
    <div className="context-menu-anchor d-flex justify-content-end">
      <div className="context-menu context-menu-upwards d-flex w-auto">
        <div className="context-menu-item" onClick={updateFromOriginal}>
          <ArrowRightIcon className="icon icon-sm ms-n1 me-1 text-info" />
          <span className="context-menu-item-text">
            {t('components.TranslationGridToolbar.applyStructureFromOriginal')}
          </span>
        </div>
      </div>
    </div>
  ) : (
    <></>
  )
}

export default TranslationGridToolbar
