import {Permission} from 'domain/Permission'
import {ReportRevision} from 'domain/Report'
import {ReactComponent as TourIcon} from '../../../assets/icons/tour.svg'
import React, {useState} from 'react'
import {renderToString} from 'react-dom/server'
import {useTranslation} from 'react-i18next'
import {diffUnitsOfTimeBetweenDates} from '../../../utils/momentUtils'
import InlineEditable from '../../utils/form/InlineEditable'
import FormattedDate from '../../utils/FormattedDate'
import Protected from '../../utils/Protected'

interface RevisionLinkProps {
  revision: ReportRevision
  isSelected: boolean
  onRevisionNameChange: (rev: ReportRevision, name: string) => void
  onRevisionClick: (e: React.MouseEvent) => void
  isSelectedForTranslation: boolean
}

const RevisionLink: React.FC<RevisionLinkProps> = props => {
  const {t} = useTranslation()
  const {revision, isSelectedForTranslation, isSelected, onRevisionClick, onRevisionNameChange} = props

  const [updatedRevision, setUpdatedRevision] = useState<ReportRevision>(revision)
  const formattedDate = <FormattedDate date={updatedRevision.createdAt} format={'LLL'}/>
  const minutesSpent = diffUnitsOfTimeBetweenDates(updatedRevision.createdAt, updatedRevision.updatedAt)
  const name = updatedRevision.name ? updatedRevision.name : renderToString(formattedDate)
  const changeRevisionName = (name: string) => {
    setUpdatedRevision({...updatedRevision, name})
    onRevisionNameChange(revision, name)
  }

  return (
    <div onClick={onRevisionClick} className="sidebar-item-link">
      {isSelected ?
        <Protected permission={Permission.Revision.UPDATE} else={<a>{name}</a>}>
          <InlineEditable value={name} onChange={changeRevisionName}/>
        </Protected>
        : <a>{name}</a>
      }

      {updatedRevision.name && <span className="subscript">{formattedDate}</span>}
      <span className="subscript">
        {updatedRevision.createdBy && <span>&bull; {updatedRevision.createdBy.name}, </span>}
        <span>{minutesSpent}</span>
      </span>

      {isSelectedForTranslation && <span className="subscript">
        <span className="badge bg-brand-blue">{t('components.RevisionLink.translateFromHere')}</span>
      </span>}
      {updatedRevision.milestones?.map(milestone => {

        return <span className="subscript">
          <span className="badge bg-info">
            <TourIcon className="icon-xs me-1"/>
            {t(`components.RevisionLink.milestone.${milestone}`)}
          </span>
        </span>
      })}
    </div>
  )
}

export default RevisionLink