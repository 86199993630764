import {Block} from 'domain/Report'

const selectedClass = 'ce-block--selected'

export const removeSelectionFromBlocks = () => {
  document.querySelectorAll('.' + selectedClass).forEach(node => {
    node.classList.remove(selectedClass)
  })
}

export const addSelectionToBlocks = (blocks: Block[]) => {
  blocks.forEach(block => {
    const el = document.getElementById(block.id)
    if (el) el.classList.add(selectedClass)
  })
}