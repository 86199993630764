import {Id} from 'domain/Entity'
import {Locale} from 'domain/Locale'
import {Permission} from 'domain/Permission'
import {ReportRevision} from 'domain/Report'
import {ReactComponent as DeleteIcon} from '../../../assets/icons/delete.svg'
import React, {useContext} from 'react'
import {Dropdown} from 'react-bootstrap'
import {useTranslation} from 'react-i18next'
import {assignTranslationRevision} from '../../../api-clients/projectClient'
import {deleteRevision, updateRevisionName} from '../../../api-clients/reportClient'
import useModal from '../../../hooks/useModal'
import useToast from '../../../hooks/useToast'
import ConfirmationModal from '../../utils/ConfirmationModal'
import DropdownMenu from '../../utils/DropdownMenu'
import Protected from '../../utils/Protected'
import DiffDeletionsToggle from './DiffDeletionsToggle'
import RevisionLink from './RevisionLink'
import WordCountBadge from './WordCountBadge'
import {ProjectAggregate} from 'domain/ProjectAggregate'
import {ReportContext} from '../../../providers/ReportProvider'

interface RevisionSidebarProps {
  locale: Locale
  localeRevisions: ReportRevision[]
  revisions: ReportRevision[]
  selectedRevisionIdx: number | null
  setSelectedRevisionIdx: (idx: number) => void
  reportLocale: Locale
  compareToRevisionIdx: number | null
  setCompareToRevisionIdx: (idx: number | null) => void
  setRevisions: (revisions: ReportRevision[]) => void
  project: ProjectAggregate
}

const RevisionHistorySidebar: React.FC<RevisionSidebarProps> = props => {
  const {
    locale,
    localeRevisions,
    revisions,
    selectedRevisionIdx,
    setSelectedRevisionIdx,
    compareToRevisionIdx,
    setCompareToRevisionIdx,
    setRevisions,
    project
  } = props

  const {t} = useTranslation()
  const {renderSuccessToast, renderErrorToast} = useToast()
  const {showModal} = useModal()
  const {setTranslateFromRevisionId} = useContext(ReportContext)

  const onRevisionClick = (e: React.MouseEvent, revisionIdx: number) => {
    e.preventDefault()
    setSelectedRevisionIdx(revisionIdx)
    setCompareToRevisionIdx(null)
  }

  const isSelected = (revisionIdx: number) => {
    if (compareToRevisionIdx === null || selectedRevisionIdx === null) return revisionIdx === selectedRevisionIdx

    const min = Math.min(selectedRevisionIdx, compareToRevisionIdx)
    const max = Math.max(selectedRevisionIdx, compareToRevisionIdx)
    return revisionIdx >= min && revisionIdx <= max
  }

  const onRevisionNameChange = (revision: ReportRevision, name: string) => {
    updateRevisionName(revision._id, name)
  }

  const assignTranslation = (revisionIdx: number) => {
    const nextRevisionId = localeRevisions[revisionIdx + 1]._id
    assignTranslationRevision(project._id, nextRevisionId)
      .then(() => {
        setTranslateFromRevisionId(nextRevisionId)
        renderSuccessToast(t('components.Report.revisions.RevisionHistorySidebar.assignTranslationRevisionSuccess'))
      })
      .catch(() => {
        renderErrorToast(t('components.Report.revisions.RevisionHistorySidebar.assignTranslationRevisionFailure'))
      })
  }

  const onConfirmDeleteRevision = (revisionId: Id) => {
    return deleteRevision(project._id, revisionId)
      .then(() => {
        setRevisions(revisions.filter(revision => revision._id !== revisionId))
        renderSuccessToast(t('components.Report.revisions.RevisionHistorySidebar.deleteRevisionSuccess'))
      })
      .catch(() => {
        renderErrorToast(t('components.Report.revisions.RevisionHistorySidebar.deleteRevisionFailure'))
      })
  }

  const onDeleteRevision = (e: React.MouseEvent, revisionId: Id) => {
    e.preventDefault()
    showModal(
      <ConfirmationModal
        title={t('components.Report.revisions.RevisionHistorySidebar.deleteConfirmationTitle')}
        ConfirmationIcon={DeleteIcon}
        body={t('components.Report.revisions.RevisionHistorySidebar.deleteConfirmation')}
        onConfirm={() => onConfirmDeleteRevision(revisionId)}
      />
    )
  }

  const translateFromRevisionIdx = project.translateFromRevisionId
    ? localeRevisions.map(l => l._id).indexOf(project.translateFromRevisionId)
    : null

  return project ? (
    <div className="report-sidebar report-sidebar-right">
      <div className="report-sidebar-body">
        <div className="text-muted fw-bold p-3">{t('components.Report.revisions.RevisionHistorySidebar.title')}</div>
        <div className="mb-2 ms-3">
          <DiffDeletionsToggle />
        </div>
        <div className="mb-3 mx-3">
          <WordCountBadge />
        </div>
        <div data-testid="list-of-revisions">
          {localeRevisions.map((revision, revisionIdx) => {
            const isSelectedForTranslation = !!translateFromRevisionIdx && revisionIdx === translateFromRevisionIdx - 1

            return (
              <div key={revision._id as string}>
                <div className={`sidebar-item ${isSelected(revisionIdx) ? 'sidebar-item-selected' : ''}`}>
                  <RevisionLink
                    revision={revision}
                    isSelected={isSelected(revisionIdx)}
                    onRevisionNameChange={onRevisionNameChange}
                    isSelectedForTranslation={isSelectedForTranslation}
                    onRevisionClick={e => onRevisionClick(e, revisionIdx)}
                  />
                  <DropdownMenu id={`revision-menu-${revision._id}`}>
                    <Dropdown.Item onClick={() => setCompareToRevisionIdx(revisionIdx)}>
                      <span>{t('components.Report.revisions.RevisionHistorySidebar.compareWithSelected')}</span>
                    </Dropdown.Item>
                    <Protected permission={Permission.Project.EDIT_SETTINGS}>
                      {locale === Locale.JA ? (
                        <Dropdown.Item onClick={() => assignTranslation(revisionIdx)}>
                          <span>
                            {t('components.Report.revisions.RevisionHistorySidebar.assignTranslationRevision')}
                          </span>
                        </Dropdown.Item>
                      ) : (
                        <></>
                      )}
                    </Protected>
                    {revisionIdx === 0 && (
                      <Protected permission={Permission.Revision.DELETE}>
                        <Dropdown.Item onClick={event => onDeleteRevision(event as React.MouseEvent, revision._id)}>
                          <span>{t('components.Report.revisions.RevisionHistorySidebar.deleteRevision')}</span>
                        </Dropdown.Item>
                      </Protected>
                    )}
                  </DropdownMenu>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  ) : null
}

export default RevisionHistorySidebar
