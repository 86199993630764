import {Block, BlockType, BookmarkBlock, HeadingBlock, ReportState, Section} from 'domain/Report'
import {isNewsUpdatesSection} from 'domain/reportUtils'

export const getBookmarks = (report?: ReportState) => {
  if (!report) {
    return {}
  }

  return report.sections.reduce((res, section) => {
    const bookmarks = section.blocks.filter(b => b.type === BlockType.BOOKMARK) as BookmarkBlock[]
    if (bookmarks.length) res[section._id] = bookmarks
    return res
  }, {})
}

export const getSubSectionBlocks = (section: Section, startHeading: HeadingBlock) => {
  const headingBlockIdx = section.blocks.indexOf(startHeading)
  const blocksAfterIndex = section.blocks.slice(headingBlockIdx + 1)
  const subSectionBlocks: Block[] = [startHeading]
  const startHeadingLevel = startHeading.level

  for (const block of blocksAfterIndex) {
    if (block.type === BlockType.HEADING && block.level <= startHeadingLevel) break
    subSectionBlocks.push(block)
  }

  return subSectionBlocks
}

export const clonedBlockStyle = (block?: Block) => {
  return block && block.type === BlockType.CLONE ? 'pe-none report-block-clone' : ''
}

export const readonlySectionBlockStyle = (section: Section) => {
  return isNewsUpdatesSection(section.type) ? 'report-block-readonly-section' : ''
}

export const teaserReport = (report: ReportState, sectionIdx = 0) => {
  const sectionBlocks = (report.sections[sectionIdx] || []).blocks || []
  const sectionBlocksCount = sectionBlocks.length
  const blocksToDisplay = Math.min(4, sectionBlocksCount / 2)
  const visibleBlocks = sectionBlocks.slice(0, blocksToDisplay)
  const visibleSection = {...report.sections[sectionIdx], blocks: visibleBlocks}

  return {...report, sections: [visibleSection]}
}

export const onlyStarredReportContent = (report: ReportState) => {
  const sections = report.sections
    .map(section => {
      return {
        ...section,
        blocks: section.blocks.filter(b => b.checked)
      }
    })
    .filter(section => section.blocks.length)

  return {...report, sections}
}
