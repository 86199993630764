import {Section} from 'domain/Report'
import EventBus, {EventType} from '../../../EventBus'
import {isInViewport} from '../../../utils/isInViewport'


export const keepScrollPositionInDiffView = (id: string) => {
  const topPosition = document.querySelector(`[id="${id}"]`)!.getBoundingClientRect().top

  setTimeout(() => {
    const elementAfterRerender = document.getElementById(id)
    if (!elementAfterRerender) return
    window.scrollBy({top: elementAfterRerender.getBoundingClientRect().top - topPosition, behavior: 'auto'})
  }, 0)
}

export const keepScrollPositionInEditor = (id: string) => {
  const topPosition = document.querySelector(`[id="${id}"]`)!.getBoundingClientRect().top

  const scrollToElement = () => {
    const elementAfterRerender = document.getElementById(id)!
    const top = elementAfterRerender.getBoundingClientRect().top - topPosition
    window.scrollBy({top})
    EventBus.unsubscribe(EventType.EDITOR_READY, scrollToElement)
  }
  EventBus.on(EventType.EDITOR_READY, scrollToElement)
}

export const preserveHeight = (container: HTMLDivElement) => {
  const sectionHeight = container.getBoundingClientRect().height as number
  container.style.minHeight = `${sectionHeight}px`
}

export const removeMinHeightOnEditorRender = (container: HTMLDivElement) => {
  const removeMinHeight = () => {
    container.style.removeProperty('min-height')
    EventBus.unsubscribe(EventType.EDITOR_READY, removeMinHeight)
  }

  EventBus.on(EventType.EDITOR_READY, removeMinHeight)
}

export const firstBlockInViewport = (section: Section) => section.blocks.find(block => {
  const div = document.getElementById(block.id)
  return div ? isInViewport(div) : false
})