import {Id, Paginated} from 'domain/Entity'
import React, {useEffect, useRef, useState} from 'react'
import EventBus, {EventType} from '../../EventBus'
import {attachOnScrollHook} from '../../utils/paginationUtils'

type Props<T> = {
  fetchCollection: (page: number) => Promise<Paginated<T>>
  render: (item: T, index: number) => React.ReactElement
  id?: string
  dependencies?: ReadonlyArray<any>
}

const defaultPagination = {collection: [], page: 1, perPage: 0, hasNext: true}

function PaginatedView<T extends {_id: Id}>({fetchCollection, render, id, dependencies = []}: Props<T>) {
  const [pagination, setPagination] = useState<Paginated<T>>(defaultPagination)
  const [loading, setLoading] = useState(false)
  const container = useRef<HTMLDivElement>(null)

  useEffect(() => {
    return attachOnScrollHook(
      container.current!.parentElement as HTMLElement,
      pagination.page,
      loading,
      pagination.hasNext,
      (page: number) => {
        loadCollection({...pagination, page})
      }
    )
  }, [pagination, loading])

  useEffect(() => {
    resetPagination()
  }, dependencies)

  useEffect(() => {
    if (!id) return

    EventBus.on(EventType.REFRESH_PAGINATED_COLLECTION, onRefresh)

    return () => {
      EventBus.unsubscribe(EventType.REFRESH_PAGINATED_COLLECTION, onRefresh)
    }
  }, [fetchCollection])

  const onRefresh = (event: Event) => {
    const collectionId = (event as CustomEvent).detail.id
    if (id === collectionId) {
      resetPagination()
    }
  }

  const resetPagination = () => {
    loadCollection(defaultPagination)
  }

  const loadCollection = (updatedPagination: Paginated<T>) => {
    setLoading(true)
    fetchCollection(updatedPagination.page)
      .then(pagination => {
        setPagination({...updatedPagination, collection: [...updatedPagination.collection, ...pagination.collection]})
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <div ref={container}>
      {pagination &&
        pagination.collection.map((item, i) => {
          return <React.Fragment key={item._id as string}>{render(item, i)}</React.Fragment>
        })}
      {loading && (
        <div className="text-center">
          <div className="spinner-border text-primary" />
        </div>
      )}
    </div>
  )
}

export default PaginatedView
