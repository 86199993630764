import {AggregatedProjectNotification} from 'domain/ProjectNotification'
import {projectNotificationLink, projectNotificationText} from 'domain/utils/projectNotificationUtils'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {makeLink} from '../../../../api/src/utils/linkUtils'
import EmailTitle from './EmailTitle'


type Props = {
  notifications: AggregatedProjectNotification[]
}

const BatchUnreadProjectNotification: React.FC<Props> = ({notifications}) => {
  const {t} = useTranslation()

  return <>
    <style dangerouslySetInnerHTML={{
      __html: `
    .notification-content div span {
      color: #6c757d;
      margin-top: 5px;
      font-size: 0.85rem;
    }
    `
    }}/>

    <EmailTitle titleText={t('emails.batchUnreadProjectNotification.bodyTitle')}/>

    {notifications.map((notification, i) => {
      return <div style={{...(i > 0 ? {marginTop: '20px'} : {}), paddingTop: '20px', borderTop: '1px solid #dee2e6'}}>
        <a href={makeLink(projectNotificationLink(notification))} target="_blank">
          <h3 style={{marginTop: 0, marginBottom: '6px'}}>
            {t(`models.ProjectNotification.${notification.type}.title`)}
          </h3>
        </a>

        <div className="notification-content">
          {projectNotificationText(notification, t)}
        </div>
      </div>
    })}
  </>
}

export default BatchUnreadProjectNotification