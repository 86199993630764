import {Id} from 'domain/Entity'
import {Translator, User, UserWithFormPasswords} from 'domain/User'
import apiClient from '../ApiClient'
import {UserAggregate} from 'domain/UserAggregate'


const getUsers = async (): Promise<UserAggregate[]> => {
  return await apiClient.get('/api/users') as UserAggregate[]
}

const getUser = async (id: Id, suppressError?: boolean): Promise<User> => {
  return await apiClient.get(`/api/users/${id}`, suppressError) as User
}

const getAnalysts = async (): Promise<User[]> => {
  return await apiClient.get('/api/users/analysts') as User[]
}

const getTranslators = async (): Promise<Translator[]> => {
  return await apiClient.get('/api/users/translators')
}

const getEditors = async (): Promise<User[]> => {
  return await apiClient.get('/api/users/editors') as User[]
}

const createUser = async (user: Partial<User>): Promise<User> => {
  return await apiClient.post('/api/users', user) as User
}

const activateUser = async (token: string, user: Partial<UserWithFormPasswords>): Promise<User> => {
  return await apiClient.patch(`/api/users/activate`, {...user, activationToken: token}) as User
}

const getUserByActivationToken = async (token: string): Promise<User> => {
  return await apiClient.get(`/api/users/activation/${token}`)
}

const getMentionableUsersForProject = async (projectId: Id): Promise<User[]> => {
  return await apiClient.get(`/api/users/mentionable?projectId=${projectId}`)
}

const requestPasswordReset = async(email: string) => {
  return await apiClient.post('/api/users/request-reset-password', {email})
}

const resetPassword = async(payload: {password: string, passwordConfirmation: string, resetPasswordToken: string}) => {
  return await apiClient.post('/api/users/reset-password', payload)
}

const updateUserFull = async(user: User): Promise<User> => {
  return await apiClient.patch(`/api/users/${user._id}`, user) as User
}

const updateUserProfile = async(user: User): Promise<User> => {
  return await apiClient.patch(`/api/users/${user._id}/profile`, user) as User
}

const archiveUser = async(user: User): Promise<User> => {
  return await apiClient.patch(`/api/users/${user._id}/archive`, user) as User
}

const unarchiveUser = async(user: User): Promise<User> => {
  return await apiClient.patch(`/api/users/${user._id}/unarchive`, user) as User
}

export {
  getUsers,
  getUser,
  getTranslators,
  getAnalysts,
  getEditors,
  createUser,
  updateUserFull,
  updateUserProfile,
  activateUser,
  getUserByActivationToken,
  getMentionableUsersForProject,
  requestPasswordReset,
  resetPassword,
  archiveUser,
  unarchiveUser
}