import {User} from 'domain/User'
import {Entity, Id} from './Entity'
import {Locale} from './Locale'


export interface MicroTranslationProject extends Entity {
  chatId: Id
  translatorId?: Id
  companyName: string
  companyId: Id
  investorName: string
  investorOrganizationName: string
  status: MicroTranslationStatus
}

export interface MicroTranslation extends Entity {
  microTranslationProjectId: Id
  user: {
    name: string,
    companyName: string
  },
  originalContent: string
  translation?: string
  correction?: string
  editorId?: Id
  translatorId?: Id
  messageId: Id
  locale: Locale
  sentAt: Date
  wordCount: number
  status: MicroTranslationStatus
  reference?: ReferencedBlock
}

export enum MicroTranslationStatus {
  PENDING = 'PENDING',
  PUBLISHED = 'PUBLISHED'
}

export interface CreateMicroTranslationPayload {
  messageId: Id
  chatId: Id
  locale: Locale
  originalContent: string
  user: {
    name: string,
    companyName: string
  },
  investorName: string
  investorOrganizationName: string
  companyId: Id
  companyName: string
  sentAt: Date
  reference?: ReferencedBlock
}

export interface BreadcrumbPart {
  title: string
  id: string
}

export interface ReferencedBlock {
  blockId: Id
  projectId: Id
  html: string
  breadcrumb: BreadcrumbPart[]
}

export interface PublishMicroTranslationPayload {
  messageId: Id
  translation: string
}

export interface PublishMicroTranslationCorrectionPayload {
  messageId: Id
  correction: string
}

export interface AggregatedMicroTranslationProject extends MicroTranslationProject {
  translator?: User
  pendingTranslations: Omit<MicroTranslation, 'user' | 'originalContent' | 'translation'>[]
}

export interface AggregatedMicroTranslation extends MicroTranslation {
  translator?: User
  editor?: User
}

export type CreateMicroTranslationProject = Omit<MicroTranslationProject, '_id' | 'status' | 'createdAt' | 'updatedAt'>
export type CreateMicroTranslation = Omit<MicroTranslation, '_id' | 'status' | 'createdAt' | 'updatedAt'>