import {StatusCodes} from 'http-status-codes'
import React, {useContext, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {Link, useHistory} from 'react-router-dom'
import {login} from '../../api-clients/authClient'
import LocalStorage from '../../LocalStorage'
import {UserContext} from '../../providers/UserProvider'
import PublicPageTemplate from '../public/PublicPageTemplate'
import EnvironmentIndicationBadge from '../topnav/EnvironmentIndicationBadge'
import Input from '../utils/form/Input'


const {CONFLICT} = StatusCodes

interface Credentials {
  email: string
  password: string
}

const LoginPage = () => {
  const {t} = useTranslation()
  const [credentials, setCredentials] = useState<Partial<Credentials>>({})
  const [errors, setErrors] = useState<{ error?: string } | null>(null)
  const {setAccessToken} = useContext(UserContext)
  const history = useHistory()

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault()
    const {name, value} = event.target
    setCredentials(c => ({...c, [name]: value}))
  }

  const onSubmit = (event: React.FormEvent) => {
    event.preventDefault()
    setErrors(null)

    login(credentials).then(tokens => {
      setAccessToken(tokens.accessToken)

      const redirectTo = LocalStorage.getRedirectTo()
      history.push(redirectTo || '/')
      LocalStorage.removeRedirectTo()
    }).catch(e => {
      if (e.status === CONFLICT) {
        alert(t('errors.apiVersionMismatch'))
        location.reload()
      }
      setErrors(e.errors)
    })
  }

  return (
    <div className="container-fluid">

      <PublicPageTemplate>

        <div className="container-narrow">
          <form className="login-form" onSubmit={onSubmit}>
            <div className="d-flex justify-content-between align-items-center mb-3">
              <h4 className="mb-0">
                {t('components.Login.title')}
              </h4>
              <EnvironmentIndicationBadge/>
            </div>

            {errors?.error && (
              <div className="alert alert-danger shake-horizontal">
                {t(`components.Login.errors.${errors.error}`)}
              </div>
            )}

            <div className="form-floating mb-3">
              <Input className="form-control"
                     name="email"
                     id="loginInput"
                     value={credentials.email || ''}
                     onChange={onChange} placeholder={t('components.Login.email')}/>
              <label htmlFor="loginInput">{t('components.Login.email')}</label>
            </div>

            <div className="form-floating mb-3">
              <Input className="form-control"
                     name="password"
                     id="loginPassword"
                     value={credentials.password || ''}
                     type="password"
                     onChange={onChange} placeholder={t('components.Login.password')}/>
              <label htmlFor="loginPassword">{t('components.Login.password')}</label>
            </div>

            <button className="btn btn-primary w-100 btn-lg">
              <span>{t('components.Login.signIn')}</span>
            </button>

            <small className="d-block text-center mt-3">
              <Link to={'/request-reset-password'}>{t('components.Login.resetPassword')}</Link>
            </small>
          </form>
        </div>
      </PublicPageTemplate>
    </div>
  )
}

export default LoginPage