import {Locale} from 'domain/Locale'
import React, {FC, ReactElement} from 'react'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'
import {useTranslation} from 'react-i18next'
import {formatDate} from '../../utils/momentUtils'
import {expectedDaysOverdue} from '../../utils/projectUtils'
import {ProjectAggregate} from 'domain/ProjectAggregate'


interface Props {
  project: ProjectAggregate
  locale: Locale
  displayLocale?: boolean
  formatKey?: string
}

const DeadlineOverdueBadge: FC<Props> = ({project, locale, formatKey, displayLocale = true}): ReactElement => {
  const {t} = useTranslation()
  if (!project.deadline?.[locale]) {
    return <></>
  }

  if (project.published?.[locale]) {
    return <></>
  }

  const daysUntilDeadline = expectedDaysOverdue(project, locale)

  if (daysUntilDeadline >= 0) {
    return <></>
  }

  const label = () => {
    return `${displayLocale ? (locale === Locale.JA ? 'JP ' : 'EN ') : ''}` +
      t(formatKey || 'components.DeadlineOverdueBadge.defaultFormat', {days: -daysUntilDeadline})
  }

  const tooltipDiv = <Tooltip id="deadline-tooltip">
    {t('components.DeadlineOverdueBadge.deadlineTooltip', {
      date: formatDate(project.expectedDeadline[locale], t('dateFormat'))
    })
    }
  </Tooltip>

  return (
    <span className={`badge rounded-pill me-1 bg-danger`}>
      <OverlayTrigger overlay={tooltipDiv}>
        <span>{label()}</span>
      </OverlayTrigger>
    </span>
  )
}

export default DeadlineOverdueBadge