import {AggregatedProjectLog} from 'domain/ProjectLog'
import React from 'react'
import {useTranslation} from 'react-i18next'
import RawHtml from '../../../utils/RawHtml'

interface LogProps {
  log: AggregatedProjectLog
}

const DefaultLogLabel: React.FC<LogProps> = ({log}) => {
  const {t} = useTranslation()
  const username = log.user ? log.user.name : log.userId
  const logEntry = t(`components.ProjectLogs.${log.type}`, {username, ...log.params}).toString()

  return <RawHtml>{logEntry}</RawHtml>
}

export default DefaultLogLabel
