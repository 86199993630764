import {Locale} from 'domain/Locale'
import {ProjectWithReport} from 'domain/Project'
import {ReportState, Section} from 'domain/Report'
import React from 'react'
import {useTranslation} from 'react-i18next'
import ReadonlyBlocks from '../view/ReadonlyBlocks'
import NewsReleaseTitle from './NewsReleaseTitle'

type Props = {
  newsProjects: ProjectWithReport[]
  section: Section
  locale: Locale
  reportState?: ReportState
  displayIfNoNews?: boolean
}

const NewsReleasesSection: React.FC<Props> = ({newsProjects, section, locale, reportState, displayIfNoNews = true}) => {
  const {t} = useTranslation()
  if (newsProjects.length === 0 && !displayIfNoNews) return <></>

  return (
    <div className="report-section report-section-output section" data-section={true} id={section._id}>
      <div className="container">
        <div className="report-view">
          {section.blocks[0] && <ReadonlyBlocks blocks={section.blocks.slice(0, 1)} locale={locale} />}

          {newsProjects.length === 0 && <p className="mt-5">{t('components.NewsReleasesSection.noNews')}</p>}

          {newsProjects.map(newsReport => {
            const blocks = newsReport.report.sections.flatMap(s => s.blocks)
            return (
              <React.Fragment key={newsReport._id as string}>
                <NewsReleaseTitle newsReport={newsReport} locale={locale} />
                <ReadonlyBlocks blocks={blocks} locale={locale} reportState={reportState} />
              </React.Fragment>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default NewsReleasesSection
