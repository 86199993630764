import {ReactComponent as TableChartIcon} from '../../../../assets/icons/table_chart.svg'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {MENU_ICON_STYLE, MENU_ITEM_STYLE, MENU_ITEM_TEXT_STYLE} from './styles'

interface Props {
  onMerge: () => void
}

export const MergeButton: React.FC<Props> = ({onMerge}) => {
  const {t} = useTranslation()
  console.log('table_chart', TableChartIcon)
  return (
    <div className={MENU_ITEM_STYLE} onClick={onMerge}>
      <TableChartIcon className={MENU_ICON_STYLE} />
      <span className={MENU_ITEM_TEXT_STYLE}>{t('Merge cells')}</span>
    </div>
  )
}
