import React, {FC, MouseEvent, ReactElement, useContext, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {ReportContext} from '../../../providers/ReportProvider'
import CommentContentForm from './CommentContentForm'

interface Props {
  originalContent: string
  onSubmit: (content: string) => void
  onCancel: (event: MouseEvent) => void
}

const EditComment: FC<Props> = ({originalContent, onSubmit, onCancel}): ReactElement => {
  const {t} = useTranslation()
  const [content, setContent] = useState(originalContent)
  const {project} = useContext(ReportContext)
  const validContent = content && content !== originalContent

  const onSave = (event: MouseEvent) => {
    event.preventDefault()
    onSubmit(content)
  }

  return (
    <>
      <div className="mb-2">
        <CommentContentForm
          projectId={project?._id!}
          content={content}
          onContentChange={setContent}
          onSubmit={onSave}
          autofocus={true}
        />
      </div>
      <div className="mb-1">
        <div>
          <button
            type="submit"
            className={`btn btn-sm btn-success me-2 ${validContent ? '' : 'disabled'}`}
            onClick={onSave}
          >
            <span>{t('components.Report.comments.EditComment.save')}</span>
          </button>

          <button type="button" className="btn btn-sm btn-outline-secondary" onClick={onCancel}>
            <span>{t('components.Report.comments.EditComment.cancel')}</span>
          </button>
        </div>
      </div>
    </>
  )
}

export default EditComment
