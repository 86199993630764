import {Locale} from 'domain/Locale'
import React, {ReactElement, useContext} from 'react'
import {useComments} from '../../../hooks/useComments'
import {CommentContext} from '../../../providers/CommentProvider'
import NewComment from '../comments/NewComment'
import InlineComment from './InlineComment'


interface Props {
  locale: Locale
}

const InlineComments: React.FC<Props> = ({locale}): ReactElement => {
  const {newComment} = useContext(CommentContext)
  useComments()

  return (<>
      <InlineComment/>
      {newComment && <NewComment locale={locale} elements={newComment.elements} classNames="inline-comment"/>}
    </>
  )
}

export default InlineComments