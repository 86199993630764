import React, {CSSProperties, PropsWithChildren} from 'react'
import {useTranslation} from 'react-i18next'


type Props = {
  host: string
}

const EmailLayout: React.FC<PropsWithChildren<Props>> = ({host, children}) => {
  const {t} = useTranslation()

  const head = `
  <!--[if mso]>
    <style>
      table {border-collapse:collapse;border-spacing:0;border:none;margin:0;}
      div, td {padding:0;}
      div {margin:0 !important;}
    </style>
    <noscript>
      <xml>
        <o:OfficeDocumentSettings>
          <o:PixelsPerInch>96</o:PixelsPerInch>
        </o:OfficeDocumentSettings>
      </xml>
    </noscript>
  <![endif]-->
  <meta charSet="utf-8"/>
  <meta name="viewport" content="width=device-width,initial-scale=1"/>
  <meta name="x-apple-disable-message-reformatting"/>
  <style>
    table, td, div, h1, p {
      font-family: Avenir, 'Segoe UI', Arial, sans-serif;
    }

    blockquote {
      white-space: pre-wrap;
      background: #f9f9f9;
      border-left: 10px solid #ccc;
      padding: 10px;
      margin-left: 20px;
    }
  </style>`

  const containerCss = {
    textSizeAdjust: '100%',
    WebkitTextSizeAdjust: '100%',
    msTextSizeAdjust: '100%',
    backgroundColor: '#f8f9fa'
  } as CSSProperties

  const headerCss = {
    width: '94%',
    maxWidth: '480px',
    border: 'none',
    borderSpacing: 0,
    textAlign: 'left',
    fontFamily: 'Arial, sans-serif',
    fontSize: '16px',
    lineHeight: '22px',
    color: '#363636'
  } as CSSProperties


  return <html lang="en">
  <head dangerouslySetInnerHTML={{__html: head}} />
  <body style={{margin:0, padding:0, wordSpacing: 'normal', backgroundColor: '#f8f9fa'}}>
  <div role="article" aria-roledescription="email" lang="en" style={containerCss}>
    <table role="presentation" style={{width: '100%', border: 'none', borderSpacing: 0}}>
      <tr>
        <td align="center" style={{padding: '16px 0 16px 0'}}>
          <table role="presentation" style={headerCss}>
            <tr>
              <td style={{padding: 0}}>
                <table style={{width: '100%', border: 'none', borderSpacing: 0}}>
                  <tr>
                    <td style={{padding: '3px', backgroundColor: '#7aa4cf'}}/>
                    <td style={{padding: '3px', backgroundColor: '#ffbb00'}}/>
                    <td style={{padding: '3px', backgroundColor: '#7aa4cf'}}/>
                    <td style={{padding: '3px', backgroundColor: '#3971a8'}}/>
                  </tr>
                </table>
              </td>
            </tr>
            <tr>
              <td style={{padding: '16px 32px 0', backgroundColor: '#ffffff'}}>
                <img width="125" height="48" alt="Shared Research logo"
                     src={`${host}/images/sr-logo-email.png`}/>
              </td>
            </tr>
            <tr>
              <td style={{padding: '16px 32px 32px 32px', backgroundColor: '#ffffff'}}>
                {children}
              </td>
            </tr>
          </table>

          <p style={{marginTop: '16px', marginBottom: '32px', fontSize: '11px', color: '#A0A8B0', maxWidth: '480px'}}
             dangerouslySetInnerHTML={{__html: t('emails.general.footerCopy')}} />
        </td>
      </tr>
    </table>
  </div>
  </body>
  </html>
}

export default EmailLayout