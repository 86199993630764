import {ReactComponent as BusinessIcon} from '../../assets/icons/business.svg'
import React, {ReactElement, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'
import {getCurrentUserProjects} from '../../api-clients/projectClient'
import Pane from '../utils/Pane'
import {ProjectAggregate} from 'domain/ProjectAggregate'


const CompanyProjectsPage: React.FC = (): ReactElement => {
  const {t} = useTranslation()
  const history = useHistory()

  const [projects, setProjects] = useState<ProjectAggregate[]>([])

  useEffect(() => {
    getCurrentUserProjects().then(setProjects)
  }, [])

  const openProjectPage = (e: React.MouseEvent, project: ProjectAggregate) => {
    e.preventDefault()
    history.push(`/projects/${project._id}`)
  }

  return (
    <div className="panes panes-fill-height">
      <div className="pane-sidebar">
        <div className="pane-body pt-3">
          <div className="nav flex-column nav-pills nav-pills-secondary">
            <a className={`nav-link mb-1 active`} href="">
              {t(`components.Projects.Projects.columnTitles.my_projects`)}
            </a>
          </div>
        </div>
      </div>

      <div className="panes-horizontal-scoller">
        <Pane title={t(`components.Projects.Projects.columnTitles.my_projects`)}>
          {projects.map(project => {
            return <div className="card card-clickable mb-2" onClick={e => openProjectPage(e, project)}
                        key={project._id as string}>
              <div className="card-body p-2">
                <div className="d-flex justify-content-between">
                  <div className="small fw-semibold mb-1">{project.title}</div>
                </div>

                <div className="d-flex align-items-center">
                  <button className="btn btn-xs btn-outline-secondary btn-with-icon">
                    <BusinessIcon className="icon-xs"/>
                    <span> {t('models.ProjectType.' + project.type)}</span>
                  </button>
                </div>
              </div>
            </div>
          })}
        </Pane>
      </div>
    </div>
  )
}

export default CompanyProjectsPage