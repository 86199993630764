import {Block, BlockType, Section} from 'domain/Report'
import {BlockDiffResult, BlockDiffStatus} from './BlockDiffResult'


export type BlockWithSectionId = Block & {
  sectionId: string
}

export class BlocksDiffer {
  constructor(
    private readonly baseSections: Section[],
    private readonly currentSections: Section[]) {
  }

  diff(): BlockDiffResult[] {
    const baseReportBlocks = this.baseSections.map(s => {
      return s.blocks.map(b => ({...b, sectionId: s._id}))
    }).flat().filter(b => b.type !== BlockType.BOOKMARK) as BlockWithSectionId[]

    const currentReportBlocks = this.currentSections.map(s => {
      return s.blocks.map(b => ({...b, sectionId: s._id}))
    }).flat().filter(b => b.type !== BlockType.BOOKMARK) as BlockWithSectionId[]

    return this.diffBlocks(baseReportBlocks, currentReportBlocks)
  }

  private diffBlocks(baseBlocks: BlockWithSectionId[], currentBlocks: BlockWithSectionId[]): BlockDiffResult[] {
    let baseBlockIdx = 0
    let currentBlockIdx = 0

    const currentBlocksById: Record<string, Block> = currentBlocks.reduce((acc, block) => {
      acc[block.id] = block
      return acc
    }, {} as Record<string, Block>)

    const baseBlocksById: Record<string, Block> = baseBlocks.reduce((acc, block) => {
      acc[block.id] = block
      return acc
    }, {} as Record<string, Block>)

    const diffedBlocks: BlockDiffResult[] = []
    const movedBlockIds = [] as string[]

    while (baseBlockIdx < baseBlocks.length && currentBlockIdx < currentBlocks.length) {
      const firstBlock = baseBlocks[baseBlockIdx]
      const secondBlock = currentBlocks[currentBlockIdx]

      if (firstBlock.id === secondBlock.id) {
        diffedBlocks.push(this.makeEqual(firstBlock, secondBlock))
        baseBlockIdx++
        currentBlockIdx++
      } else if (!currentBlocksById[firstBlock.id]) {
        diffedBlocks.push(this.makeDeletion(firstBlock))
        baseBlockIdx++
      } else if (!baseBlocksById[secondBlock.id]) {
        diffedBlocks.push(this.makeAddition(secondBlock))
        currentBlockIdx++
      } else if (currentBlocksById[firstBlock.id] && !movedBlockIds.includes(secondBlock.id)) {
        diffedBlocks.push(this.makeMovedAway(firstBlock))
        movedBlockIds.push(firstBlock.id)
        baseBlockIdx++
      } else if (baseBlocksById[secondBlock.id]) {
        diffedBlocks.push(this.makeMovedTo(baseBlocksById[secondBlock.id], secondBlock))
        currentBlockIdx++
      }
    }

    while (baseBlockIdx < baseBlocks.length) {
      const block = baseBlocks[baseBlockIdx++]
      diffedBlocks.push(this.makeDeletion(block))
    }

    while (currentBlockIdx < currentBlocks.length) {
      const currentBlock = currentBlocks[currentBlockIdx++]
      const baseBlock = baseBlocksById[currentBlock.id]

      if (baseBlock && movedBlockIds.includes(currentBlock.id)) {
        diffedBlocks.push(this.makeMovedTo(baseBlock, currentBlock))
      } else {
        diffedBlocks.push(this.makeAddition(currentBlock))
      }
    }

    return diffedBlocks
  }

  private makeEqual(firstBlock: Block, secondBlock: BlockWithSectionId) {
    return new BlockDiffResult({
      left: firstBlock,
      right: secondBlock,
      status: BlockDiffStatus.EQUAL,
      sectionId: secondBlock.sectionId
    })
  }

  private makeMovedAway(baseBlock: BlockWithSectionId) {
    return new BlockDiffResult({
      left: baseBlock,
      right: baseBlock,
      status: BlockDiffStatus.MOVED_AWAY,
      sectionId: baseBlock.sectionId
    })
  }

  private makeMovedTo(baseBlock: Block, currentBlock: BlockWithSectionId) {
    return new BlockDiffResult({
      left: baseBlock,
      right: currentBlock,
      status: BlockDiffStatus.MOVED_TO,
      sectionId: currentBlock.sectionId
    })
  }

  private makeDeletion(block: BlockWithSectionId) {
    return new BlockDiffResult({left: block, status: BlockDiffStatus.DELETION, sectionId: block.sectionId})
  }

  private makeAddition(block: BlockWithSectionId) {
    return new BlockDiffResult({right: block, status: BlockDiffStatus.INSERTION, sectionId: block.sectionId})
  }
}