import {ReactComponent as ArrowLeftIcon} from '../../../assets/icons/arrow_back.svg'
import {ReactComponent as ArrowRightIcon} from '../../../assets/icons/arrow_forward.svg'
import {ReactComponent as CloseIcon} from '../../../assets/icons/close.svg'
import React from 'react'


interface MergeToolbarProps {
  copyBlock: () => void
  rejectBlock: () => void
  canReject: boolean
  side: ToolbarSide
}

export enum ToolbarSide {
  LEFT = 'left',
  RIGHT = 'right'
}

const BlockMergeToolbar: React.FC<MergeToolbarProps> = ({copyBlock, canReject, rejectBlock, side}) => {
  const onCopy = (e: React.MouseEvent) => {
    e.preventDefault()
    copyBlock()
  }

  const onReject = (e: React.MouseEvent) => {
    e.preventDefault()
    rejectBlock()
  }

  return (
    <div className={`position-absolute p-1 top-0 ${side === ToolbarSide.LEFT ? 'end-0 me-n3' : 'start-0 ms-n3'}`}>
      <div className="btn-group-vertical border rounded bg-container" style={{zIndex:1}}>
        <a className="btn btn-sm btn-icon-only btn-secondary" href="#" onClick={onCopy}>
          {side === ToolbarSide.LEFT ?
            <ArrowRightIcon className="icon icon-sm"/> :
            <ArrowLeftIcon className="icon icon-sm"/>}
        </a>
        { canReject &&
          <a className="btn btn-sm btn-icon-only  btn-secondary" href="#" onClick={onReject}>
            <CloseIcon className="icon icon-sm"/>
          </a>
        }
      </div>
    </div>
  )
}

export default BlockMergeToolbar