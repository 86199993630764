import {ReplyWithUserName} from 'domain/Comment'
import {Id} from 'domain/Entity'
import React, {ReactElement, useContext, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {deleteReply, updateReply} from '../../../api-clients/commentClient'
import {CommentContext} from '../../../providers/CommentProvider'
import {LocaleContext} from '../../../providers/LocaleProvider'
import {UserContext} from '../../../providers/UserProvider'
import {relativeDateTime} from '../../../utils/momentUtils'
import Content from './Content'
import EditComment from './EditComment'


interface ReplyProps {
  commentId: Id,
  reply: ReplyWithUserName
}

const Reply: React.FC<ReplyProps> = ({reply, commentId}): ReactElement => {
  const [editing, setEditing] = useState(false)
  const {setComments} = useContext(CommentContext)
  const {user} = useContext(UserContext)
  const {t} = useTranslation()
  const {locale} = useContext(LocaleContext)

  const canManage = (): boolean => {
    return user._id === reply.userId
  }

  const onEditReply = (content: string) => {
    updateReply(commentId, reply._id, content).then(updatedReply => {
      setEditing(false)
      setComments(comments => (comments || []).map(comment => {
        if (comment._id === commentId) {
          return {
            ...comment, replies: comment.replies.map(reply => {
              return reply._id === updatedReply._id ? updatedReply : reply
            })
          }
        } else {
          return comment
        }
      }))
    })
  }

  const enableEditing = (e: React.MouseEvent) => {
    e.preventDefault()
    e.stopPropagation()
    setEditing(true)
  }

  const disableEditing = (e: React.MouseEvent) => {
    e.stopPropagation()
    setEditing(false)
  }

  const onDelete = (e: React.MouseEvent) => {
    e.preventDefault()
    e.stopPropagation()

    deleteReply(commentId, reply._id).then(() => {
      setComments(comments => (comments || []).map(comment => {
        if (comment._id === commentId) {
          return {...comment, replies: comment.replies.filter(r => r._id !== reply._id)}
        } else {
          return comment
        }
      }))
    })
  }

  return (
    <div className="mx-n1">
      <div className="xsmall text-muted">{reply.username}</div>
      <div className="xxsmall text-muted-more mb-1">{relativeDateTime(reply.createdAt, locale)}</div>
      {editing ? (
        <EditComment originalContent={reply.content} onSubmit={onEditReply} onCancel={disableEditing}/>
      ) : (
        <>
          <Content content={reply.content} data-reply-content="" />

          {canManage() && (
            <div className="small">
              <a href="#" onClick={enableEditing}>
                <span>{t('components.Report.comments.Reply.edit')}</span>
              </a>
              <span className="text-muted-more">&nbsp;•&nbsp;</span>
              <a href="#" onClick={onDelete}>
                <span>{t('components.Report.comments.Reply.delete')}</span>
              </a>
            </div>
          )}
        </>
      )}
    </div>
  )
}

export default Reply