import {Locale} from 'domain/Locale'
import {Project} from 'domain/Project'
import {ReactComponent as UploadIcon} from '../../../assets/icons/cloud_upload.svg'
import React, {FC, ReactElement} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'
import useModal from '../../../hooks/useModal'
import UploadFile from '../UploadFile'


export interface Props {
  project: Project
}

const TranslationImportButton: FC<Props> = ({project}): ReactElement => {
  const {t} = useTranslation()
  const history = useHistory()
  const {showModal} = useModal()

  const uploadTranslationFile = (event: React.MouseEvent) => {
    event.preventDefault()
    showModal(<UploadFile locale={Locale.EN} projectId={project._id}
                          onUploaded={() => history.push(`/projects/${project._id}/report/translate`)}/>)
  }

  return (
    <button className="btn btn-xs btn-outline-secondary btn-with-icon ms-1"
            onClick={uploadTranslationFile}>
      <UploadIcon className="icon icon-xs"/>
      <span className="text-nowrap">{t('components.TranslationImportButton.action')}</span>
    </button>
  )
}

export default TranslationImportButton