import {Locale} from 'domain/Locale'
import moment from 'moment'
import relativeDateFormat from './relativeDateFormat.json'
import relativeDateTimeFormat from './relativeDateTimeFormat.json'


export const formattedTimezone = () => {
  return moment().format('Z')
}

export const formattedMonth = (month: number, locale: Locale, format = 'MMM') => {
  return moment({months: month}, undefined, locale).format(format)
}

export const relativeDate = (date: Date, locale: Locale) => {
  return moment(date, undefined, locale).calendar(relativeDateFormat[locale])
}

export const relativeDateTime = (date: Date, locale: Locale) => {
  return moment(date, undefined, locale).calendar(relativeDateTimeFormat[locale])
}

export const relativeDateFromNow = (date: Date, locale: Locale, fallbackFormat: string) => {
  const dayInMs = 1000 * 60 * 60 * 24
  if (moment().diff(date) > dayInMs) {
    return moment(date).format(fallbackFormat)
  } else {
    return moment(date, undefined, locale).fromNow()
  }
}

export const fromNow = (date: Date, locale: Locale) => {
  return moment(date, undefined, locale).fromNow()
}

export const formatDate = (date: Date, format = 'MMM') => {
  return moment(date).format(format)
}

export const diffDaysBetweenDates = (start: Date, end: Date) => {
  return moment(end).startOf('day').diff(moment(start).startOf('day'), 'days')
}

export const diffUnitsOfTimeBetweenDates = (start: Date, end: Date) => {
  const duration = moment.duration(new Date(end).getTime() - new Date(start).getTime())
  const month = duration.months()
  const days = duration.days()
  const hours = duration.hours()
  const minutes = duration.minutes()

  const formattedMonth = month > 0 ? `${month}month` : ''
  const formattedDays = days > 0 ? `${days}d` : ''
  const formattedHours = hours > 0 ? `${hours}h` : ''
  const formattedMinutes = minutes > 0 ? `${minutes}m` : ''
  const formattedDiff = [formattedMonth, formattedDays, formattedHours, formattedMinutes].filter(i => !!i).join(' ')
  return formattedDiff ? formattedDiff : '0m'
}