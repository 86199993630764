import {PermissionType} from 'domain/Permission'
import React, {useContext} from 'react'
import {RouteProps} from 'react-router'
import {Route} from 'react-router-dom'
import {UserContext} from './providers/UserProvider'

interface AuthRouteProps extends RouteProps {
  permission?: PermissionType
}

const AuthRoute: React.FC<AuthRouteProps> = props => {
  const {permissions, user} = useContext(UserContext)
  const {permission, ...routeProps} = props

  if (user && (!permission || permissions.includes(permission))) {
    return <Route {...routeProps} />
  } else {
    return <div>404</div>
  }
}

export default AuthRoute
