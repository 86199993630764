import {Id} from 'domain/Entity'
import {Project} from 'domain/Project'
import {User} from 'domain/User'
import {ReactComponent as DoneIcon} from '../../assets/icons/done.svg'
import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {assignAnalystsToProjectTasks} from '../../api-clients/projectTaskClient'
import {getAnalysts} from '../../api-clients/userClient'
import useModal from '../../hooks/useModal'
import MultipleSelect from '../utils/form/MultipleSelect'
import Select from '../utils/form/Select'
import ModalTemplate from '../utils/ModalTemplate'

type Props = {
  project: Project
  onUpdate: () => void
}

const SetProjectAnalysts: React.FC<Props> = ({project, onUpdate}) => {
  const [analystId, setAnalystId] = useState<Id | null>(project.analystId || null)
  const [analysts, setAnalysts] = useState<User[]>([])
  const [assistantAnalystIds, setAssistantAnalystIds] = useState<Id[]>([])
  const [loading, setLoading] = useState(true)
  const {t} = useTranslation()
  const {closeModal} = useModal()

  useEffect(() => {
    getAnalysts().then(setAnalysts).then(() => setLoading(false))
  }, [])

  const onAnalystChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setAnalystId(event.target.value || null)
  }

  const onAssistantAnalystsChange = (assistantAnalystIds: string[]) => {
    setAssistantAnalystIds(assistantAnalystIds)
  }

  const onConfirm = (event: React.MouseEvent<HTMLButtonElement> | React.FormEvent) => {
    event.preventDefault()

    assignAnalystsToProjectTasks(project._id, analystId, assistantAnalystIds).then(() => {
      closeModal()
      onUpdate()
    })
  }

  return <ModalTemplate size="md" title={t('components.SetProjectAnalysts.title')}>
    <form onSubmit={onConfirm}>
      <div className="modal-body">
        <div className="row">
          <div className="col-12 mb-2">
            <label htmlFor="user" className="form-label small">
              {t('components.SetProjectAnalysts.label')}
            </label>
            <Select id="user" className="form-select" value={analystId as string || ''}
                    onChange={onAnalystChange} name="userId" disabled={loading}>
              <option value="">{t('components.SetProjectAnalysts.selectUser')}</option>
              {analysts.map(user =>
                <option key={user._id as string} value={user._id as string}>{user.name}</option>
              )}
            </Select>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <label className="form-label small">
              {t('models.Project.assistantAnalysts')}
            </label>
            <MultipleSelect htmlId="assistantAnalysts" name="assistantAnalysts"
                            placeholder={t('components.ProjectForm.selectAssistantAnalysts')}
                            label={(user: User) => user.name!} collection={analysts}
                            selectedValues={project.assistantAnalystIds as string[]}
                            onChange={onAssistantAnalystsChange}
            />
          </div>
        </div>
      </div>
      <div className="modal-footer">
        <button type="submit" className="btn btn-success btn-with-icon">
          <span>{t('components.SetProjectAnalysts.update')}</span>
          <DoneIcon className="icon" />
        </button>
      </div>
    </form>
  </ModalTemplate>
}

export default SetProjectAnalysts