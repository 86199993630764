import {Block} from 'domain/Report'
import React, {ReactNode, useState} from 'react'


export const SelectedBlocksContext = React.createContext({} as any)

const SelectedBlocksProvider = ({children}: { children: ReactNode }) => {
  const [selectedBlocks, setSelectedBlocks] = useState<Block[]>([])

  return (
    <SelectedBlocksContext.Provider value={{selectedBlocks, setSelectedBlocks}}>
      {children}
    </SelectedBlocksContext.Provider>
  )
}

export default SelectedBlocksProvider