import {TaskWithUser} from 'domain/Task'
import {isOnlyTranslator} from 'domain/User'
import i18next from 'i18next'


export const getTaskAssignee = (task: TaskWithUser) => {
  const {user, roles} = task
  if (user) return user
  if (isOnlyTranslator(roles)) return i18next.t('components.TaskAssignee.translator')
  return roles.map(role => i18next.t(`models.Role.${role}`)).join(', ')
}
