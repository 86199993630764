import {Company} from 'domain/Company'
import {Locale} from 'domain/Locale'
import React from 'react'
import {useTranslation} from 'react-i18next'
import FormattedDate from '../utils/FormattedDate'


interface IRContactsProps {
  locale: Locale
  company: Company
}

const IRContacts: React.FC<IRContactsProps> = ({locale, company}) => {
  const {t} = useTranslation()
  return (
    <>
      <div className="data-list data-list-small data-list-2-columns mb-n2">
        <dl>
          <dt>{t('models.Company.irContact')}</dt>
          <dd>{company.irContact?.[locale] ?? '-'}</dd>
        </dl>
        <dl>
          <dt>{t('models.Company.irPhone')}</dt>
          <dd>{company.irPhone?.[locale] ?? '-'}</dd>
        </dl>
        <dl>
          <dt>{t('models.Company.irEmail')}</dt>
          <dd>{company.irEmail?.[locale] ?? '-'}</dd>
        </dl>
        <dl>
          <dt>{t('models.Company.irWebsite')}</dt>
          <dd><a href={company.irWebsite?.[locale] || ''} target="_blank">{company.irWebsite?.[locale] || '-'}</a></dd>
        </dl>
      </div>
    </>)
}

export default IRContacts