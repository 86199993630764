import {AggregatedCompany, Company} from 'domain/Company'
import {Permission} from 'domain/Permission'
import {ReactComponent as AddIcon} from '../../assets/icons/add.svg'
import {ReactComponent as DeleteIcon} from '../../assets/icons/delete.svg'
import {ReactComponent as EditIcon} from '../../assets/icons/edit.svg'
import React, {ReactElement, useContext, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory, useParams} from 'react-router-dom'
import {getCompany, updateCompany} from '../../api-clients/companyClient'
import {getCompanyActiveProjects, getCompanyPublishedProjects} from '../../api-clients/projectClient'
import useModal from '../../hooks/useModal'
import {UserContext} from '../../providers/UserProvider'
import CompanyCard from '../companies/CompanyCard'
import CompanyForm from '../companies/CompanyForm'
import DeleteCompany from '../companies/DeleteCompany'
import NewProject from '../projects/NewProject'
import ProjectCard from '../projects/ProjectCard'
import PaginatedView from '../utils/PaginatedView'
import Pane from '../utils/Pane'
import Protected from '../utils/Protected'
import CompanyAnalysts from './CompanyAnalysts'
import CompanyDetails from './CompanyDetails'
import CompanyImages from './CompanyImages'
import ToggleCompanyStatusButton from './ToggleCompanyStatusButton'
import {ProjectAggregate} from 'domain/ProjectAggregate'


interface RouteParams {
  id: string
}

const CompanyPage: React.FC = (): ReactElement => {
  const {t} = useTranslation()
  const {id} = useParams<RouteParams>()
  const {hasPermission} = useContext(UserContext)
  const [company, setCompany] = useState<AggregatedCompany | null>(null)
  const [projects, setProjects] = useState<ProjectAggregate[]>([])
  const {showModal} = useModal()
  const history = useHistory()

  const onCompanyUpdate = (company: Company) => {
    return updateCompany(company).then(setCompany)
  }

  const onCompanyDelete = () => {
    history.push('/companies')
  }

  const onCompanyStatusChange = () => {
    if (!company) return
    getCompany(company?._id).then(setCompany)
  }

  const editCompany = (event: React.MouseEvent) => {
    event.preventDefault()
    showModal(<CompanyForm company={company!} onConfirm={onCompanyUpdate}/>)
  }

  const deleteCompany = () => {
    showModal(<DeleteCompany company={company!} onDeleted={onCompanyDelete}/>)
  }

  const addNewProject = (event: React.MouseEvent) => {
    event.preventDefault()
    showModal(<NewProject companyId={company?._id.toString()} />)
  }

  useEffect(() => {
    getCompany(id).then(setCompany)

    if (hasPermission(Permission.Project.READ_ALL)) {
      getCompanyActiveProjects(id).then(setProjects)
    }
  }, [id])

  return (
    <div className="panes panes-fill-height">
      <div className="pane">
        <div className="pane-header">
          <h4 className="pane-title">
            {t('components.Company.Company.title')}
          </h4>
          <div>
            <Protected permission={Permission.Project.CREATE}>
              <button className="btn btn-outline-primary btn-sm btn-with-icon btn-block" onClick={addNewProject}>
                <span>{t('components.Projects.Projects.newProject')}</span>
                <AddIcon className="icon icon-sm"/>
              </button>
            </Protected>

            <Protected permission={Permission.Company.UPDATE}>
              <button title={t('components.Companies.Companies.edit')} onClick={editCompany}
                      className="btn btn-outline-secondary btn-sm btn-icon-only ms-2">
                <EditIcon className="icon icon-sm"/>
              </button>
            </Protected>
            <Protected permission={Permission.Company.DELETE}>
              <>
                {company &&
                  <ToggleCompanyStatusButton company={company} onCompanyStatusChange={onCompanyStatusChange}/>}
                <button title={t('components.Companies.Companies.delete')} onClick={deleteCompany}
                        className="btn btn-outline-secondary btn-sm btn-icon-only ms-2">
                  <DeleteIcon className="icon icon-sm"/>
                </button>
              </>
            </Protected>
          </div>
        </div>

        <div className="pane-body">
          {company && <CompanyCard company={company}/>}
          {company && <CompanyAnalysts company={company}/>}
          {company && <CompanyImages company={company} onCompanyUpdates={setCompany}/>}
          {company && <CompanyDetails company={company}/>}
        </div>
      </div>

      <Protected permission={Permission.Project.READ_ALL} else={<div className="pane"/>}>
        <>
          <Pane title={t('components.Company.Company.ongoing_projects')}>
            {company && projects.map(project => {
              return <ProjectCard project={project} key={project._id.toString()}/>
            })}
          </Pane>

          <Pane title={t('components.Company.Company.published_reports')}>
            <PaginatedView fetchCollection={page => getCompanyPublishedProjects(id, page)}
                           render={(project: ProjectAggregate) => {
                             return <ProjectCard project={project} key={project._id as string}/>
                           }}/>
          </Pane>
        </>
      </Protected>
    </div>
  )
}

export default CompanyPage