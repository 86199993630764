import {PermissionType} from 'domain/Permission'
import apiClient from '../ApiClient'


const getPermissions = async () => {
  return await apiClient.get('/api/permissions') as PermissionType[]
}

export {
  getPermissions
}