import Papa from 'papaparse'


export const parseCsvFile = async (file: File,
                                   allowedHeaders: string[] | readonly string[] = [],
                                   headersMap: any = {}): Promise<any[]> => {
  const {errors, data, meta} = Papa.parse(await readFile(file), {
    header: true,
    skipEmptyLines: true,
    transform: v => {
      return v.trim() === '' ? null : v.trim()
    },
    transformHeader(header: string): string {
      return headersMap ? headersMap[header] : header
    }
  })

  if (errors.length) {
    throw {error: 'invalidFormat'}
  }

  if (allowedHeaders.length) {
    const headers = meta.fields || []
    const valid = allowedHeaders.every(header => headers.includes(header))
    if (!valid) {
      throw {error: 'invalidFormat'}
    }
  }
  return data
}

const readFile = (file: File): Promise<string> => {
  return new Promise(resolve => {
    const reader = new FileReader()
    reader.onload = event => {
      resolve(event.target?.result as string || '')
    }
    reader.readAsText(file, 'utf-8')
  })
}
