import React from 'react'
import {BackgroundStyle, Direction, TextAlign} from 'domain/Report'
import {BackgroundColorButtonsSection} from './menu/BackgroundColorButtonsSection'
import {MergeButton} from './menu/MergeButton'
import {RemoveButton} from './menu/RemoveButton'
import {RowColumnButtonsSection} from './menu/RowColumnButtonsSection'
import {TextAlignButtonsSection} from './menu/TextAlignButtonsSection'
import {createRoot} from 'react-dom/client'

class Menu {
  menu: HTMLElement | null = null

  constructor(
    private coordinates: {x: number; y: number},
    private onRemove: () => void,
    private canRemove: () => boolean,
    private onMerge: () => void,
    private onBackgroundColorPicked: (style: BackgroundStyle) => void,
    private onTextAlignChange: (align: TextAlign) => void,
    private onAddRow: (direction: Direction) => void,
    private onAddColumn: (direction: Direction) => void,
    private selectedCellCount: number
  ) {
    this.onClick = this.onClick.bind(this)
    this.onKeyDown = this.onKeyDown.bind(this)
  }

  callAndCloseMenu<T>(action: (arg: T) => void) {
    return (arg: T) => {
      action(arg)
      this.destroy()
    }
  }

  render() {
    const menu = document.createElement('div')
    menu.classList.add('context-menu', 'common-table-context-menu')
    menu.style.top = `${this.coordinates.y}px`
    menu.style.left = `${this.coordinates.x}px`
    const menuContent = (
      <>
        {this.selectedCellCount > 1 && <MergeButton onMerge={this.callAndCloseMenu<void>(this.onMerge)} />}
        <BackgroundColorButtonsSection onBackgroundColorPicked={this.callAndCloseMenu(this.onBackgroundColorPicked)} />
        <TextAlignButtonsSection onTextAlignChange={this.callAndCloseMenu(this.onTextAlignChange)} />
        {this.selectedCellCount > 1 && (
          <RowColumnButtonsSection
            onAddRow={this.callAndCloseMenu(this.onAddRow)}
            onAddColumn={this.callAndCloseMenu(this.onAddColumn)}
          />
        )}
        {this.canRemove() && <RemoveButton onRemove={this.callAndCloseMenu<void>(this.onRemove)} />}
      </>
    )

    createRoot(menu).render(menuContent)
    document.body.append(menu)

    this.menu = menu
    setTimeout(() => {
      document.addEventListener('click', this.onClick, false)
      document.addEventListener('contextmenu', this.onClick, false)
      document.addEventListener('keydown', this.onKeyDown, false)
    }, 0)
  }

  onClick(event: MouseEvent) {
    const clickedInsideMenu = this.menu && this.menu.contains(event.target as HTMLElement)

    if (!clickedInsideMenu) {
      this.destroy()
    }
  }

  onKeyDown(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      this.destroy()
    }
  }

  destroy() {
    if (!this.menu || !this.menu.parentElement) {
      return
    }

    this.menu.parentElement!.removeChild(this.menu)
    document.removeEventListener('click', this.onClick)
  }
}

export default Menu
