import {hasProjectAdminAccess, hasTaskAccess} from 'domain/access'
import {Permission} from 'domain/Permission'
import {ProjectStage} from 'domain/Project'
import {getStageRule} from 'domain/project-stages/projectStageHelpers'
import {ProjectTaskWithUsers, TaskStatus} from 'domain/Task'
import {hasRoles, Role} from 'domain/User'
import {ReactComponent as EditIcon} from '../../../assets/icons/edit.svg'
import React, {FC, PropsWithChildren, ReactNode, useContext, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {restartProjectTask} from '../../../api-clients/projectTaskClient'
import EventBus, {EventType} from '../../../EventBus'
import useToast from '../../../hooks/useToast'
import {UserContext} from '../../../providers/UserProvider'
import UserButton from '../../user/UserButton'
import FormattedDate from '../../utils/FormattedDate'
import Protected from '../../utils/Protected'
import TaskAssignee from '../TaskAssignee'
import DeleteProjectButton from './DeleteProjectButton'
import StageCompletionButton from './StageCompletionButton'
import TaskAssignButton from './TaskAssignButton'
import TaskRescheduleButton from './TaskRescheduleButton'
import {ProjectAggregate} from 'domain/ProjectAggregate'

export interface ProjectTaskProps {
  project: ProjectAggregate
  loadProjectWithTasks: () => void
  isFirst: boolean
  canBeReverted: boolean
  content?: ReactNode
  bottom?: ReactNode
  task: ProjectTaskWithUsers
}

const DefaultProjectTask: FC<PropsWithChildren<ProjectTaskProps>> = props => {
  const {t} = useTranslation()
  const {user} = useContext(UserContext)
  const {project, loadProjectWithTasks, isFirst, canBeReverted, children, content, bottom, task} = props
  const {renderWarningToast} = useToast()
  const [loading, setLoading] = useState(false)
  const showMarkAsDoneButton = task.status === TaskStatus.IN_PROGRESS && task.stage !== ProjectStage.PUBLISH
  const stageRule = getStageRule(project, task.stage)

  const markAsNotDone = () => {
    setLoading(true)

    restartProjectTask(task._id)
      .then(_ => {
        onTaskChanged()
      })
      .catch(e => {
        if (e.errors && e.errors.stage) {
          renderWarningToast(t(`errors.canNotChangeStage`))
        }
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const requiresAdditionalAssistants = !!stageRule?.requiresAdditionalAssistants
  const requiresUser =
    !hasRoles(task.roles, Role.TRANSLATOR_COORDINATOR) &&
    !getStageRule(project, task.stage)?.isProjectAnalystStage &&
    task.status !== TaskStatus.FINISHED
  const requiresUserAssignment = task.status === TaskStatus.IN_PROGRESS && requiresUser && !task.userId
  const additionalTaskAssistants = (task.assistantUsers || []).subtract(project.assistantAnalysts, u => u._id)
  const requiresAdditionalAssistantsAssignment =
    task.status === TaskStatus.IN_PROGRESS && requiresAdditionalAssistants && additionalTaskAssistants.length === 0
  const requiredAssignment = requiresUserAssignment || requiresAdditionalAssistantsAssignment

  const onTaskChanged = () => {
    loadProjectWithTasks()
    EventBus.emit(EventType.REFRESH_PROJECT_LOGS)
  }

  const taskRescheduledToLater =
    task.reschedules?.length && new Date(task.reschedules[0].previousEndDate) < new Date(task.endDate)
  const taskIsDelayed =
    (new Date(task.endDate) < new Date() && task.status === TaskStatus.IN_PROGRESS) || taskRescheduledToLater
  const showDelayedBadge = !hasRoles(user, Role.COMPANY_REPRESENTATIVE)

  return (
    <div>
      <div className={`card mb-2 ${task.status === TaskStatus.SCHEDULED ? 'stepper-step-future' : ''}`}>
        <div className="card-body p-2">
          {task.status === TaskStatus.IN_PROGRESS && (
            <div className="progress mt-n2 mx-n2 mb-2" style={{height: 5 + 'px'}}>
              <div className="progress-bar progress-bar-striped progress-bar-animated w-100" />
            </div>
          )}
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <h5 className="card-title mb-0">
                {t(`models.ProjectStage.${task.stage}.title`)}
                {taskIsDelayed && showDelayedBadge && (
                  <span className="badge rounded-pill ms-1 bg-danger">
                    {t('components.DefaultProjectTask.delayed')}
                  </span>
                )}
              </h5>
              <div className="small text-muted-more">{t(`models.ProjectStage.${task.stage}.description`)}</div>
            </div>
            <div>
              {hasTaskAccess(user, project, task) ? (
                <>
                  {task.status === TaskStatus.FINISHED && canBeReverted && (
                    <button className="btn btn-xs btn-outline-secondary" disabled={loading} onClick={markAsNotDone}>
                      {t('components.DefaultProjectTask.markAsNotDone')}
                    </button>
                  )}
                  {showMarkAsDoneButton && (
                    <StageCompletionButton
                      project={project}
                      task={task}
                      onStageCompleted={onTaskChanged}
                      disabled={loading}
                      setLoading={setLoading}
                      label={'components.StageCompletionButton.markAsDone'}
                    />
                  )}
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>

        {content}

        <div>
          <div className="px-2 pb-2 d-flex justify-content-between align-items-end">
            <div className="data-list data-list-row">
              <dl>
                <dt>{t('models.Task.endDate')}</dt>
                <dd className="small">
                  <FormattedDate date={task.endDate} format={t('dateTimeFormat')} />
                  <Protected permission={Permission.ProjectTask.RESCHEDULE}>
                    <TaskRescheduleButton
                      task={task}
                      project={project}
                      onRescheduled={onTaskChanged}
                      classNames="btn btn-outline-secondary btn-xs btn-icon-only ms-2"
                    >
                      <EditIcon className="icon icon-xs" />
                    </TaskRescheduleButton>
                  </Protected>
                </dd>
              </dl>
              <dl>
                <dt>{t('components.DefaultProjectTask.responsible')}</dt>
                <dd className="d-flex gap-1">
                  <TaskAssignee task={task} />

                  {requiresAdditionalAssistants &&
                    additionalTaskAssistants.map(assistant => {
                      return <UserButton user={assistant} key={assistant._id as string} />
                    })}

                  {stageRule?.isProjectAnalystStage && (
                    <span className="small text-muted">{t('components.DefaultProjectTask.andAssistants')}</span>
                  )}

                  {hasProjectAdminAccess(user, project) && (
                    <>
                      {requiresAdditionalAssistants && additionalTaskAssistants.length === 0 && (
                        <span className="text-danger small">{t('components.DefaultProjectTask.assignOtherUsers')}</span>
                      )}

                      {(requiresUser || requiresAdditionalAssistants) && (
                        <TaskAssignButton
                          task={task}
                          onAssigned={loadProjectWithTasks}
                          canAssignAssistants={requiresAdditionalAssistants}
                          canAssignUser={requiresUser}
                          projectAssistants={project.assistantAnalysts}
                          requiresAssignment={requiredAssignment}
                        />
                      )}
                      {requiresUserAssignment && (
                        <span className="text-danger small">{t('components.DefaultProjectTask.noUserAssigned')}</span>
                      )}
                    </>
                  )}
                </dd>
              </dl>
              {children}
            </div>
          </div>
        </div>

        {bottom}

        {isFirst && hasProjectAdminAccess(user, project) && (
          <div>
            <div className="float-end pb-2">
              <DeleteProjectButton project={project} isDisabled={task.status === TaskStatus.FINISHED} />
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default DefaultProjectTask
