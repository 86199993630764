import {AggregatedTask, TaskStatus} from 'domain/Task'
import {getBusinessDates, isWithinDates} from 'domain/tasks/taskScheduleUtils'
import {User} from 'domain/User'
import moment from 'moment-timezone'
import React, {ReactElement, useContext, useEffect, useMemo, useRef, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {getUserOverdueTasks, getUserTasks} from '../../api-clients/taskClient'
import useModal from '../../hooks/useModal'
import {LocaleContext} from '../../providers/LocaleProvider'
import {relativeDate} from '../../utils/momentUtils'
import {attachOnScrollHook} from '../../utils/paginationUtils'
import ProjectTaskCard from '../schedule/ProjectTaskCard'
import UserTaskCalendarModal from './UserTaskCalendarModal'


interface Props {
  user: User
}

const UserSchedulePane: React.FC<Props> = ({user}): ReactElement => {
  const {t} = useTranslation()
  const {locale} = useContext(LocaleContext)
  const {showModal} = useModal()
  const [tasks, setTasks] = useState<AggregatedTask[]>([])
  const [overdueTasks, setOverdueTasks] = useState<AggregatedTask[]>([])
  const [page, setPage] = useState(1)
  const [loading, setLoading] = useState(false)
  const [hasNext, setHasNext] = useState(true)
  const container = useRef<HTMLDivElement>(null)

  const displayedDays = 7
  const dates = useMemo(() => getBusinessDates(new Date(), displayedDays * page, []), [page])

  useEffect(() => {
    loadTasks()
  }, [user, page])

  useEffect(() => {
    if (tasks.length < 20 && hasNext) setPage(page => page + 1)
  }, [tasks, loading, hasNext])

  useEffect(() => {
    return attachOnScrollHook(container.current! as HTMLElement, page, loading, hasNext, setPage)
  }, [hasNext, page, loading])

  const loadTasks = () => {
    if (loading || !hasNext) return
    const requestDates = dates.slice((page - 1) * displayedDays)

    const from = requestDates[0].start
    const to = requestDates.last()!.end

    loadTasksForDates(from, to, page === 1)
  }

  const reloadTasks = () => {
    const from = dates[0].start
    const to = dates.last()!.end
    loadTasksForDates(from, to, true, true)
  }

  const loadTasksForDates = (from: Date, to: Date, loadOverdue: boolean, reset = false) => {
    setLoading(true)

    getUserTasks(user._id, from, to).then(userTasks => {
      const activeTasks = userTasks.filter(task => task.status !== TaskStatus.FINISHED)
      setTasks(tasks => reset ? activeTasks : [...tasks, ...activeTasks].uniqueBy(t => t._id))
      setHasNext(userTasks.length > 0)
    }).finally(() => {
      setLoading(false)
    })

    if (loadOverdue) getUserOverdueTasks(user._id, from).then(setOverdueTasks)
  }

  const tasksByDates = useMemo(() => {
    return dates.map(date => {
      return {date, tasks: tasks.filter(t => isWithinDates(t.startDate, t.endDate, date.start, date.end))}
    })
  }, [tasks])

  const onCalendarShow = (e: React.MouseEvent) => {
    e.preventDefault()
    showModal(<UserTaskCalendarModal user={user}/>)
  }

  return <>
    <div className="pane">
      <div className="pane-header">
        <h4 className="pane-title">{t('components.UserSchedulePane.title')}</h4>
        <button className="btn btn-primary btn-sm" onClick={onCalendarShow}>
          {t('components.UserSchedulePane.calendar')}
        </button>
      </div>
      <div className="pane-body" ref={container}>
        {tasksByDates.map(({date, tasks}, dayIndex) => {
          const {start} = date
          const dayTasks = dayIndex === 0 ? [...overdueTasks, ...tasks] : tasks

          return dayTasks.length > 0 && <React.Fragment key={`day-${dayIndex}`}>
            <h6>{relativeDate(start, locale)}</h6>
            {dayTasks.map(task => {
              return <ProjectTaskCard task={task} onUpdated={reloadTasks}
                                      key={`${task._id}-${dayIndex}`}
                                      isContinuing={moment(task.startDate).isBefore(start)}/>
            })}
          </React.Fragment>
        })}

        {loading && <div className="text-center">
          <div className="spinner-border text-primary"/>
        </div>}
      </div>
    </div>
  </>
}

export default UserSchedulePane