import {Company} from 'domain/Company'
import {Errors} from 'domain/Errors'
import {Role, roleRequiresCompanyId, User} from 'domain/User'
import {ReactComponent as DoneIcon} from '../../assets/icons/done.svg'
import React, {useContext, useEffect, useState} from 'react'
import SelectSearch from 'react-dropdown-select'
import {useTranslation} from 'react-i18next'
import {getActiveCompanies} from '../../api-clients/companyClient'
import {createUser} from '../../api-clients/userClient'
import useModal from '../../hooks/useModal'
import useToast from '../../hooks/useToast'
import {LocaleContext} from '../../providers/LocaleProvider'
import Input from '../utils/form/Input'
import {localizedRoles, onHandleKeyDownFn, rolesFromOptions, SelectOption} from '../utils/form/SearchSelectHelper'
import Select from '../utils/form/Select'
import ModalTemplate from '../utils/ModalTemplate'
import {UserAggregate} from 'domain/UserAggregate'

interface UserFormParams {
  onUserCreated: (user: UserAggregate) => void
}

const UserForm: React.FC<UserFormParams> = ({onUserCreated}) => {
  const {closeModal} = useModal()
  const {renderSuccessToast} = useToast()
  const {t} = useTranslation()
  const {locale} = useContext(LocaleContext)
  const [user, setUser] = useState<Partial<User>>({})
  const [errors, setErrors] = useState<Errors<User>>()
  const [companies, setCompanies] = useState<Company[]>([])

  const onConfirm = (event: React.FormEvent) => {
    event.preventDefault()

    createUser(user).then(user => {
      closeModal()
      renderSuccessToast(t('components.UserForm.registrationSuccessMessage'))

      const company = companies.find(company => user.companyId === company._id)
      onUserCreated({...user, company})
    }).catch(({errors}) => setErrors(errors))
  }

  const onChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    event.preventDefault()
    const {name, value} = event.target
    setUser(user => ({...user, [name]: value}))
  }

  useEffect(() => {
    getActiveCompanies().then(setCompanies)
  }, [])

  const availableRoles = localizedRoles(Object.values(Role), t)
  const selectedUserRoles = () => localizedRoles((user?.roles || []), t)

  const onChangeRole = (options: SelectOption[]) => {
    setUser(user => ({...user, roles: rolesFromOptions(options)}))
  }

  return (
    <ModalTemplate title={t('components.UserForm.title')}>
      <form onSubmit={onConfirm}>
        <div className="modal-body">
          <div className="row">
            <div className="mb-3 col-lg-6">
              <label htmlFor="email" className="form-label small">
                {t('models.User.email')}
              </label>

              <Input className="form-control" name="email" value={user.email || ''}
                     onChange={onChange} error={errors?.email}/>
            </div>

            <div className="mb-3 col-lg-6">
              <label htmlFor="email" className="form-label small">
                {t('models.User.roles')}
              </label>

              <SelectSearch options={availableRoles} onChange={onChangeRole}
                            handleKeyDownFn={onHandleKeyDownFn} multi={true}
                            values={selectedUserRoles()} additionalProps={{className: 'userRoleSelect'}}/>

            </div>

            {roleRequiresCompanyId(user.roles!) ? (
              <>
                <div className="mb-3 col-lg-6"/>
                <div className="mb-3 col-lg-6">
                  <label htmlFor="company-id" className="form-label small">
                    {t('models.User.companyId')}
                  </label>

                  <Select value={(user.companyId || '') as string} id="company-id" onChange={onChange}
                          name="companyId"
                          className="form-select"
                          error={errors?.companyId}>
                    <option>{t('components.UserForm.selectCompany')}</option>
                    {companies.map(company => {
                      return <option key={company._id as string} value={company._id as string}>
                        {company.tick} - {company.name[locale]}
                      </option>
                    })}
                  </Select>
                </div>
              </>
            ) : <></>}
          </div>
        </div>
        <div className="modal-footer">
          <button className="btn btn-success btn-with-icon">
            <span>{t('components.UserForm.title')}</span>
            <DoneIcon className="icon" />
          </button>
        </div>
      </form>
    </ModalTemplate>
  )
}

export default UserForm