const placeCaretAtTheEndOfText = (element: HTMLDivElement) => {
  element.focus()
  const range = document.createRange()
  range.selectNodeContents(element)
  range.collapse(false)
  const sel = window.getSelection()
  sel!.removeAllRanges()
  sel!.addRange(range)
}

export default placeCaretAtTheEndOfText