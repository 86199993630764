import {Project} from 'domain/Project'
import {User} from 'domain/User'
import moment from 'moment'
import React from 'react'
import i18n from '../../../../api/src/i18n'
import {makeLink} from '../../../../api/src/utils/linkUtils'
import EmailButton from './EmailButton'
import EmailTitle from './EmailTitle'

type Props = {
  translator: User
  project: Project
  message: string
  translationWritingDeadline: Date
}

const TranslationAssignmentNotification: React.FC<Props> = ({
  translator,
  project,
  message,
  translationWritingDeadline
}) => {
  return (
    <>
      <EmailTitle titleText={i18n.t('emails.translationAssignmentNotification.bodyTitle')} />

      <p>
        {i18n.t('emails.translationAssignmentNotification.greeting')} {translator.name},
      </p>

      <p>{i18n.t('emails.translationAssignmentNotification.intro')}</p>

      <p>
        <table>
          <tbody>
            <tr>
              <td colSpan={2}>
                <b>{project.title}</b>
              </td>
            </tr>
            <tr>
              <td>{i18n.t('emails.translationAssignmentNotification.projectType')}</td>
              <td>{i18n.t('models.ProjectType.' + project.type)}</td>
            </tr>
            <tr>
              <td>{i18n.t('emails.translationAssignmentNotification.deadlineInJapaneseTimezone')}</td>
              <td>{moment(translationWritingDeadline).tz('Asia/Tokyo').format('YYYY-MM-DD HH:mm')}</td>
            </tr>
            <tr>
              <td>{i18n.t('emails.translationAssignmentNotification.wordCount')}</td>
              <td>{project.wordCount ?? i18n.t('emails.translationAssignmentNotification.wordCountUnknown')}</td>
            </tr>
          </tbody>
        </table>
      </p>

      {message && (
        <>
          <div>{i18n.t('emails.translationAssignmentNotification.additionalInformation')}</div>
          <blockquote>{message}</blockquote>
        </>
      )}

      <p style={{margin: '25px 0 16px'}}>
        <EmailButton
          buttonLink={makeLink(`/projects/${project._id}`)}
          buttonText={i18n.t('emails.translationAssignmentNotification.goToProject')}
        />
      </p>
    </>
  )
}

export default TranslationAssignmentNotification
