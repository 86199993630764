import {hasProjectAccess, hasProjectReadAccess} from 'domain/access'
import {Locale} from 'domain/Locale'
import {PageType} from 'domain/PageType'
import {Permission} from 'domain/Permission'
import {ProjectUpdateType} from 'domain/Websockets'
import {ReactComponent as DownloadIcon} from '../../assets/icons/cloud_download.svg'
import {ReactComponent as CopyIcon} from '../../assets/icons/content_copy.svg'
import {ReactComponent as EditIcon} from '../../assets/icons/edit.svg'
import {ReactComponent as PreviewIcon} from '../../assets/icons/preview.svg'
import {ReactComponent as SettingsIcon} from '../../assets/icons/settings.svg'
import {ReactComponent as SnippetIcon} from '../../assets/icons/snippet_folder.svg'
import {ReactComponent as StickyIcon} from '../../assets/icons/sticky_note.svg'
import {ReactComponent as TranslateIcon} from '../../assets/icons/translate.svg'
import React, {FC, ReactElement, useContext, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {Link, useParams} from 'react-router-dom'
import {isCloneable} from '../../../../api/src/utils/projectCloningUtils'
import {getProject} from '../../api-clients/projectClient'
import useModal from '../../hooks/useModal'
import {useProjectDocumentTitle} from '../../hooks/useProjectDocumentTitle'
import useToast from '../../hooks/useToast'
import {UserContext} from '../../providers/UserProvider'
import {ignore} from '../../utils/promiseUtils'
import websocket from '../../websocket'
import CompanyCard from '../companies/CompanyCard'
import DeadlineOverdueBadge from '../projects/DeadlineOverdueBadge'
import EditProject from '../projects/EditProject'
import NewProject from '../projects/NewProject'
import FormattedDate from '../utils/FormattedDate'
import Pane from '../utils/Pane'
import Protected from '../utils/Protected'
import DownloadPdf from './DownloadPdf'
import ProjectLogs from './ProjectLogs'
import ProjectTimeline from './ProjectTimeline'
import {ProjectAggregate} from 'domain/ProjectAggregate'

interface RouteParams {
  projectId: string
}

const ProjectPage: FC = (): ReactElement => {
  const {showModal} = useModal()
  const {t} = useTranslation()
  const {projectId} = useParams<RouteParams>()
  const {user} = useContext(UserContext)
  const {renderSuccessToast, renderErrorToast} = useToast()
  const [project, setProject] = useState<ProjectAggregate | null>(null)
  const [sourceProject, setSourceProject] = useState<ProjectAggregate | null>(null)
  useProjectDocumentTitle(project, PageType.PROJECT)

  useEffect(() => {
    ignore(loadProject())
  }, [projectId])

  useEffect(() => {
    if (!project) return

    websocket.onProjectWordCountUpdated(project._id, ({wordCount}) => {
      setProject(project => ({...project!, wordCount}))
    })

    websocket.onProjectUpdated(project._id, ({type, errors}) => {
      switch (type) {
        case ProjectUpdateType.REPORT_GENERATION_START:
          renderSuccessToast(t('components.ProjectPage.reportGenerationStarted'))
          break
        case ProjectUpdateType.REPORT_GENERATION_SUCCEEDED:
          renderSuccessToast(t('components.ProjectPage.reportGenerated'))
          break
        case ProjectUpdateType.REPORT_GENERATION_FAILED:
          if (errors?.error) {
            renderErrorToast(t('components.ProjectPage.' + errors.error))
          } else {
            renderErrorToast(t('components.ProjectPage.reportGeneratingFailed'))
          }
          break
      }
      ignore(loadProject())
    })

    return () => {
      websocket.stopListeningProjectUpdated(project._id)
      websocket.stopListeningWordCountUpdated(project._id)
    }
  }, [project?._id])

  async function loadProject(callback?: () => void) {
    try {
      const project = await getProject(projectId)
      setProject(project)
      await loadSourceProject(project)
      callback?.()
    } catch (error) {
      console.error(`Failed to load project: ${projectId}`, error)
    }
  }

  const loadSourceProject = async (project: ProjectAggregate) => {
    if (project.sourceProjectId) {
      try {
        const sourceProject = await getProject(project.sourceProjectId as string, true)
        setSourceProject(sourceProject)
      } catch (error) {
        console.error(`Failed to load source project: ${project.sourceProjectId}`, error)
      }
    } else {
      setSourceProject(null)
    }
  }

  const openPrintSettings = (event: React.MouseEvent) => {
    event.preventDefault()
    showModal(<DownloadPdf project={project!} company={project!.company!} />)
  }

  const openProjectSettings = (event: React.MouseEvent) => {
    event.preventDefault()
    showModal(<EditProject project={project!} onUpdate={setProject} />)
  }

  const cloneProject = (event: React.MouseEvent) => {
    event.preventDefault()
    showModal(<NewProject sourceProjectId={project?._id} companyId={project?.companyId.toString()} />)
  }

  if (!project) return <></>

  return (
    <div className="panes panes-fill-height">
      <Pane title={t('components.Project.Project.project')}>
        {project && (
          <div className="card mb-3">
            <div className="card-header">
              <div className="icon text-muted me-2">
                <SnippetIcon className="icon-sm" />
              </div>
              <div className="flex-grow-1">
                <h6 className="mb-0">{project.title}</h6>
                {sourceProject && (
                  <p className="small text-muted mb-0">
                    {t('components.Project.basedOn')}{' '}
                    <Link to={`/projects/${sourceProject._id}`}>{sourceProject.title}</Link>
                  </p>
                )}
              </div>
              <div className="card-header-actions">
                {hasProjectAccess(user, project) && (
                  <Protected permission={Permission.Report.UPDATE}>
                    <Link to={`/projects/${projectId}/report/edit`} className="btn btn-success btn-sm btn-with-icon">
                      <EditIcon className="icon icon-sm" />
                      <span>{t('components.Project.Project.edit')}</span>
                    </Link>
                  </Protected>
                )}

                {hasProjectAccess(user, project) && (
                  <Protected permission={Permission.Report.UPDATE}>
                    <Link
                      to={`/projects/${projectId}/report/translate`}
                      title={t('components.ReportNavigation.translate')}
                      className="btn btn-outline-success btn-sm btn-icon-only px-2 ms-2"
                    >
                      <TranslateIcon className="icon-sm" />
                      <span>{t('components.Project.Project.translate')}</span>
                    </Link>
                  </Protected>
                )}

                {hasProjectAccess(user, project) && isCloneable(project) && (
                  <Protected permission={Permission.Project.CREATE}>
                    <button
                      onClick={cloneProject}
                      title={t('components.Project.Project.clone')}
                      className="btn btn-outline-secondary btn-sm btn-icon-only px-2 ms-2"
                    >
                      <CopyIcon className="icon icon-sm" />
                    </button>
                  </Protected>
                )}

                {hasProjectReadAccess(user, project) && (
                  <Protected permission={Permission.Report.READ}>
                    <Link
                      to={`/projects/${projectId}/report`}
                      title={t('components.Project.Project.view')}
                      className="btn btn-outline-secondary btn-sm btn-icon-only px-2 ms-2"
                    >
                      <PreviewIcon className="icon icon-sm" />
                    </Link>
                  </Protected>
                )}

                {hasProjectAccess(user, project) && (
                  <Protected permission={Permission.Report.DOWNLOAD_PDF}>
                    <button
                      onClick={openPrintSettings}
                      title={t('components.Project.DownloadPdf.title')}
                      className="btn btn-outline-secondary btn-sm btn-icon-only px-2 ms-2"
                    >
                      <DownloadIcon className="icon icon-sm" />
                    </button>
                  </Protected>
                )}

                {hasProjectAccess(user, project) && (
                  <Protected permission={Permission.Project.EDIT_SETTINGS}>
                    <button
                      className="btn btn-outline-secondary btn-sm btn-icon-only px-2 ms-2"
                      title={t('components.Project.settings')}
                      onClick={openProjectSettings}
                    >
                      <SettingsIcon className="icon icon-sm" />
                    </button>
                  </Protected>
                )}
              </div>
            </div>
            <div className="card-body">
              {project.notes && (
                <div className="alert alert-warning alert-with-icon">
                  <StickyIcon className="me-2 icon" />
                  <div>{project.notes}</div>
                </div>
              )}

              <div className="data-list data-list-row">
                <dl>
                  <dt>{t('models.Project.type')}</dt>
                  <dd>{t(`models.ProjectType.${project.type}`)}</dd>
                </dl>
                <dl>
                  <dt>{t('models.Project.deadline.ja')}</dt>
                  <dd className="">
                    <div className="me-1">{project.deadline?.ja && <FormattedDate date={project.deadline.ja} />}</div>
                    <DeadlineOverdueBadge
                      project={project}
                      locale={Locale.JA}
                      displayLocale={false}
                      formatKey={'components.ProjectPage.format'}
                    />
                  </dd>
                </dl>
                <dl>
                  <dt>{t('models.Project.deadline.en')}</dt>
                  <dd className="">
                    <div className="me-1">
                      {project.deadline?.en ? <FormattedDate date={project.deadline.en} /> : '-'}
                    </div>
                    <DeadlineOverdueBadge
                      project={project}
                      locale={Locale.EN}
                      displayLocale={false}
                      formatKey={'components.ProjectPage.format'}
                    />
                  </dd>
                </dl>

                {project.publishedAt?.ja && (
                  <dl>
                    <dt>{t('models.Project.publishedAt.ja')}</dt>
                    <dd className="d-flex align-items-center">
                      <div className="me-1">
                        <FormattedDate date={project.publishedAt.ja} />
                      </div>
                    </dd>
                  </dl>
                )}
                {project.publishedAt?.en && (
                  <dl>
                    <dt>{t('models.Project.publishedAt.en')}</dt>
                    <dd className="d-flex align-items-center">
                      <div className="me-1">
                        <FormattedDate date={project.publishedAt.en} />
                      </div>
                    </dd>
                  </dl>
                )}
              </div>
            </div>
            <div className="card-body">
              <div className="data-list data-list-row">
                <dl>
                  <dt>{t('models.Project.reportTitle.ja')}</dt>
                  <dd>{project.reportTitle?.[Locale.JA] || '-'}</dd>
                </dl>
                <dl>
                  <dt>{t('models.Project.reportTitle.en')}</dt>
                  <dl>{project.reportTitle?.[Locale.EN] || '-'}</dl>
                </dl>
              </div>
            </div>
          </div>
        )}
        {project.company && <CompanyCard company={project.company} showDetailsLink />}
        {project && (
          <Protected permission={Permission.Project.VIEW_LOGS}>
            <ProjectLogs projectId={project._id} />
          </Protected>
        )}
      </Pane>

      <Pane title={t('components.Project.Project.project_timeline')}>
        {project && <ProjectTimeline project={project} loadProject={loadProject} />}
      </Pane>
    </div>
  )
}

export default ProjectPage
