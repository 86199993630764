import {hasProjectAccess} from 'domain/access'
import {CommentStatus, CommentWithUserName} from 'domain/Comment'
import {Locale} from 'domain/Locale'
import {Permission} from 'domain/Permission'
import React, {PropsWithChildren, useContext, useEffect, useState} from 'react'
import {getComments} from '../api-clients/commentClient'
import {ignore} from '../utils/promiseUtils'
import {ReportContext} from './ReportProvider'
import {UserContext} from './UserProvider'

type Comments = CommentWithUserName[]

export class CommentState {
  static empty(): CommentState {
    return new CommentState(
      [],
      () => {},
      () => {},
      null,
      () => {}
    )
  }

  constructor(
    public comments: CommentWithUserName[],
    public setComments: (comments: Comments | ((comments: Comments) => Comments)) => void,
    public addComment: (elements: HTMLElement[]) => void,
    public newComment: NewComment | null,
    public closeNewComment: () => void
  ) {}

  getLocaleComments = (locale: Locale): CommentWithUserName[] => {
    return this.comments.filter(comment => comment.locale === locale)
  }

  getUnresolvedLocaleComments = (locale: Locale): CommentWithUserName[] => {
    return this.getLocaleComments(locale).filter(comment => comment.status === CommentStatus.ACTIVE)
  }

  unresolvedComments: (locale: Locale) => CommentWithUserName[] = (locale: Locale) => {
    return this.comments
      .filter(comment => comment.locale === locale)
      .filter(comment => comment.status === CommentStatus.ACTIVE)
  }
}

export const CommentContext = React.createContext<CommentState>(CommentState.empty())

export interface NewComment {
  elements: HTMLElement[]
}

const CommentProvider: React.FC<PropsWithChildren> = ({children}) => {
  const {project} = useContext(ReportContext)
  const [comments, setComments] = useState<CommentWithUserName[]>([])
  const [newComment, setNewComment] = useState<NewComment | null>(null)
  const {user, hasPermission} = useContext(UserContext)

  useEffect(() => {
    ignore(loadComments())
  }, [])

  const loadComments = async () => {
    if (hasPermission(Permission.Comment.READ) && hasProjectAccess(user, project)) {
      const comments = await getComments(project._id)
      setComments(comments)
    }
  }

  const commentState = new CommentState(
    comments,
    setComments,
    (elements: HTMLElement[]) => {
      setNewComment({elements})
    },
    newComment,
    () => {
      setNewComment(null)
    }
  )

  return <CommentContext.Provider value={commentState}>{children}</CommentContext.Provider>
}

export default CommentProvider
