import {hasTaskAccess} from 'domain/access'
import {ProjectTask} from 'domain/Task'
import React, {useContext} from 'react'
import {useTranslation} from 'react-i18next'
import {finishProjectTask} from '../../../api-clients/projectTaskClient'
import useToast from '../../../hooks/useToast'
import {UserContext} from '../../../providers/UserProvider'
import {ProjectAggregate} from 'domain/ProjectAggregate'

interface Props {
  project: ProjectAggregate
  task: ProjectTask
  onStageCompleted: () => void
  setLoading: (loading: boolean) => void
  disabled: boolean
  label: string
}

const StageCompletionButton: React.FC<Props> = props => {
  const {t} = useTranslation()
  const {user} = useContext(UserContext)
  const {renderWarningToast} = useToast()
  const {project, task, onStageCompleted, setLoading, disabled, label} = props

  const completeStage = () => {
    setLoading(true)

    finishProjectTask(task._id)
      .then(_ => {
        onStageCompleted()
      })
      .catch(e => {
        if (e.errors && e.errors.invalidState) {
          renderWarningToast(t(`errors.${e.errors.invalidState}`))
        }
        if (e.errors && e.errors.task) {
          renderWarningToast(t(`errors.canNotChangeStage`))
        }
      })
      .finally(() => {
        setLoading(false)
      })
  }

  if (hasTaskAccess(user, project, task)) {
    return (
      <button className="btn btn-sm btn-success" onClick={completeStage} disabled={disabled}>
        {t(label)}
      </button>
    )
  }

  return <></>
}

export default StageCompletionButton
