import {Entity, Id} from 'domain/Entity'
import {Locale} from 'domain/Locale'
import {ReportData} from 'domain/Report'

export enum ProjectReportSize {
  REGULAR = 'REGULAR',
  FULL = 'FULL',
  TEASER = 'TEASER'
}

export interface ProjectLink {
  locale: Locale
  filepath: string
  filename: string
  fileId: Id
  createdAt: Date
  format: ProjectLinkFormat
  size: ProjectReportSize
}

export enum ProjectLinkFormat {
  HTML = 'HTML',
  PDF = 'PDF'
}

export type ReportTitle = {
  [key in Locale]?: string
}

export interface Project extends Entity {
  type: ProjectType
  title: string
  reportTitle?: ReportTitle
  companyId: Id
  reportId: Id
  notes?: string
  deadline: DeadlineValue,
  analystId?: Id
  assistantAnalystIds?: Id[]
  translateFromRevisionId?: Id
  sourceProjectId?: Id
  publishedAt?: PublishedDates
  pushedToPublicAt?: PublishedDates
  published?: { [key in Locale]?: boolean }
  links?: ProjectLink[]
  wordCountExcludedBlockIds?: string[]
  wordCount?: number
  notifySubscribers: boolean
  isFinished?: boolean
}

export type PublishedDates = {
  [key in Locale]?: Date
}

export type DeadlineValue = {
  [key in Locale]?: Date
}

export enum ProjectType {
  FULL = 'FULL',
  ESPRESSO = 'ESPRESSO',
  FLASH = 'FLASH',
  NEWS_UPDATE = 'NEWS_UPDATE',
  POST_INTERVIEW_UPDATE = 'POST_INTERVIEW_UPDATE'
}

export const FULL_SIZE_REPORT_TYPES = [ProjectType.FULL, ProjectType.POST_INTERVIEW_UPDATE, ProjectType.FLASH]
export const REPORTS_WITH_NEWS_UPDATES = [ProjectType.FULL, ProjectType.POST_INTERVIEW_UPDATE, ProjectType.FLASH]

export const containsNewsUpdates = (type?: ProjectType) =>
  type && REPORTS_WITH_NEWS_UPDATES.includes(type)

export const isDerivativeProjectType = (type?: ProjectType) =>
  type && [ProjectType.FLASH, ProjectType.POST_INTERVIEW_UPDATE].includes(type)

export const isOriginalReportInProgress = (timeline: ProjectTimeline) => originalReportTimelines.includes(timeline)

export const REQUIRED_REPORTS_SIZES_BY_TYPE: { [key in ProjectType]: ProjectReportSize[] } = {
  [ProjectType.FULL]: [ProjectReportSize.FULL, ProjectReportSize.TEASER],
  [ProjectType.ESPRESSO]: [ProjectReportSize.FULL, ProjectReportSize.TEASER],
  [ProjectType.POST_INTERVIEW_UPDATE]: [ProjectReportSize.FULL, ProjectReportSize.TEASER],
  [ProjectType.NEWS_UPDATE]: [ProjectReportSize.FULL, ProjectReportSize.REGULAR, ProjectReportSize.TEASER],
  [ProjectType.FLASH]: [ProjectReportSize.FULL, ProjectReportSize.REGULAR, ProjectReportSize.TEASER]
}

export enum ProjectStage {
  INTERVIEW = 'INTERVIEW',
  INTERVIEW_1 = 'INTERVIEW_1',
  INTERVIEW_2 = 'INTERVIEW_2',
  ORIGINAL_WRITING = 'ORIGINAL_WRITING',
  ORIGINAL_REVIEW = 'ORIGINAL_REVIEW',
  ORIGINAL_REVIEW1 = 'ORIGINAL_REVIEW1',
  ORIGINAL_POST_REVIEW_WRITING1 = 'ORIGINAL_POST_REVIEW_WRITING1',
  ORIGINAL_REVIEW2 = 'ORIGINAL_REVIEW2',
  ORIGINAL_POST_REVIEW_WRITING2 = 'ORIGINAL_POST_REVIEW_WRITING2',
  ORIGINAL_EDITING_FOR_TRANSLATION = 'ORIGINAL_EDITING_FOR_TRANSLATION',
  ORIGINAL_HEAD_OF_RESEARCH_AUTH = 'ORIGINAL_HEAD_OF_RESEARCH_AUTH',
  ORIGINAL_PROOFREADING = 'ORIGINAL_PROOFREADING',
  ORIGINAL_COMPANY_CHECK = 'ORIGINAL_COMPANY_CHECK',
  ORIGINAL_POST_COMPANY_REVIEW_WRITING = 'ORIGINAL_POST_COMPANY_REVIEW_WRITING',
  ORIGINAL_SELECT_CONTENT = 'ORIGINAL_SELECT_CONTENT',
  TRANSLATION_PREPARATION = 'TRANSLATION_PREPARATION',
  TRANSLATION_WRITING = 'TRANSLATION_WRITING',
  TRANSLATION_OZ_CHECK = 'TRANSLATION_OZ_CHECK',
  TRANSLATION_REVIEW = 'TRANSLATION_REVIEW',
  TRANSLATION_COMPANY_CHECK = 'TRANSLATION_COMPANY_CHECK',
  PUBLISH = 'PUBLISH'
}

export const INTERVIEW_STAGES = [ProjectStage.INTERVIEW, ProjectStage.INTERVIEW_1, ProjectStage.INTERVIEW_2]
export const REVIEW_STAGES = [
  ProjectStage.ORIGINAL_REVIEW, ProjectStage.ORIGINAL_REVIEW1, ProjectStage.ORIGINAL_REVIEW2
]

export enum ProjectTimeline {
  INTERVIEWS = 'INTERVIEWS',
  ORIGINAL_REPORT = 'ORIGINAL_REPORT',
  TRANSLATED_REPORT = 'TRANSLATED_REPORT',
  PUBLISHING = 'PUBLISHING'
}

export const originalReportTimelines = [ProjectTimeline.INTERVIEWS, ProjectTimeline.ORIGINAL_REPORT]

export type ProjectCreate = Pick<Project, 'type' | 'title' | 'reportTitle' | 'companyId' | 'notes' | 'deadline'
  | 'analystId' | 'assistantAnalystIds' | 'sourceProjectId' | 'publishedAt' | 'notifySubscribers'>

export type ProjectUpdate = Pick<Project, 'title' | 'notes' | 'deadline' | 'analystId'
  | 'publishedAt' | 'reportTitle' | 'notifySubscribers'>

export type PublicProject = Pick<Project, '_id' | 'type' | 'reportTitle' | 'companyId' | 'publishedAt' | 'links' |
  'notifySubscribers' | 'pushedToPublicAt'> & {
  published: { [key in Locale]?: boolean }
}

export const serializeProjectPayload = (project: Project): PublicProject => {
  return {
    _id: project._id,
    type: project.type,
    reportTitle: project.reportTitle,
    companyId: project.companyId,
    publishedAt: project.publishedAt,
    links: project.links,
    notifySubscribers: project.notifySubscribers,
    published: {
      ja: project.published?.ja,
      en: project.published?.en
    },
    pushedToPublicAt: {
      ja: project.pushedToPublicAt?.ja,
      en: project.pushedToPublicAt?.en
    }
  }
}

export type ProjectWithReport = Project & {
  report: ReportData
}

export interface GroupedNews {
  recentUpdates: ProjectWithReport[]
  newsAndTopics: ProjectWithReport[]
}

export const emptyGroupedNews = (): GroupedNews => {
  return {
    recentUpdates: [],
    newsAndTopics: []
  }
}
