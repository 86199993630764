import {Block, Section} from 'domain/Report'
import {BlockMergeResult} from './BlockMergeResult'


export enum BlockMergeStatus {
  EQUAL = 'EQUAL',
  INSERTION = 'INSERTION',
  CONFLICT = 'CONFLICT',
  REJECTED = 'REJECTED',
  COPIED = 'COPIED',
  RESOLVED = 'RESOLVED',
}

export class SectionMergeResult {
  constructor(
    public _id: string,
    public mergedBlocks: BlockMergeResult[]
  ) {
  }

  toSection(): Section {
    return {
      _id: this._id,
      blocks: this.mergedBlocks.filter(_ => _.resolved).map(_ => _.resolved as Block)
    }
  }
}

export class ReportSectionsMerger {
  private readonly baseBlocksById: Record<string, Block>
  private readonly secondSectionsById: Record<string, Section>

  constructor(
    commonBaseSections: Section[],
    private readonly first: Section[],
    private readonly second: Section[]) {

    this.baseBlocksById = commonBaseSections.flatMap(s => s.blocks).groupByFirst(b => b.id)

    this.secondSectionsById = second.groupByFirst(s => s._id)
  }

  merge(): SectionMergeResult[] {
    let firstIdx = 0
    let secondIdx = 0
    const result: SectionMergeResult[] = []

    while (firstIdx < this.first.length && secondIdx < this.second.length) {
      const firstSection = this.first[firstIdx]
      const secondSection = this.second[secondIdx]

      if (firstSection._id === secondSection._id) {
        result.push(
          new SectionMergeResult(firstSection._id, this.mergeBlocks(firstSection.blocks, secondSection.blocks))
        )
        firstIdx++
        secondIdx++
      } else if (this.secondSectionsById[firstSection._id]) {
        result.push(this.mergeRight(secondSection))
        secondIdx++
      } else {
        result.push(this.mergeLeft(firstSection))
        firstIdx++
      }
    }

    while (firstIdx < this.first.length) {
      result.push(this.mergeLeft(this.first[firstIdx++]))
    }

    while (secondIdx < this.second.length) {
      result.push(this.mergeRight(this.second[secondIdx++]))
    }

    const allMergeResults = result.flatMap(section => section.mergedBlocks)
    result.forEach(section => section.mergedBlocks.forEach(block => block.setAllBlockMergeResults(allMergeResults)))

    return result
  }

  private mergeBlocks(first: Block[], second: Block[]): BlockMergeResult[] {
    let firstIdx = 0
    let secondIdx = 0

    const secondSectionsByIds: Record<string, Block> = second.reduce((acc, s) => {
      acc[s.id] = s
      return acc
    }, {} as Record<string, Block>)

    const mergedBlocks: BlockMergeResult[] = []

    while (firstIdx < first.length && secondIdx < second.length) {
      const firstBlock = first[firstIdx]
      const secondBlock = second[secondIdx]

      if (firstBlock.id === secondBlock.id) {
        mergedBlocks.push(new BlockMergeResult({left: firstBlock, right: secondBlock}))
        firstIdx++
        secondIdx++
      } else if (secondSectionsByIds[firstBlock.id]) {
        mergedBlocks.push(this.makeRight(secondBlock))
        secondIdx++
      } else {
        mergedBlocks.push(this.makeLeft(firstBlock))
        firstIdx++
      }
    }

    while (firstIdx < first.length) {
      const block = first[firstIdx++]
      mergedBlocks.push(this.makeLeft(block))
    }

    while (secondIdx < second.length) {
      const block = second[secondIdx++]
      mergedBlocks.push(this.makeRight(block))
    }

    return mergedBlocks
  }

  private mergeRight(section: Section): SectionMergeResult {
    const mergedBlocks = section.blocks.map(block => this.makeRight(block))
    return new SectionMergeResult(section._id, mergedBlocks)
  }

  private mergeLeft(section: Section): SectionMergeResult {
    const mergedBlocks = section.blocks.map(block => this.makeLeft(block))
    return new SectionMergeResult(section._id, mergedBlocks)
  }

  private makeLeft(block: Block) {
    return new BlockMergeResult({left: block, wasBlockDeleted: this.wasBlockDeleted(block)})
  }

  private makeRight(block: Block) {
    return new BlockMergeResult({right: block, wasBlockDeleted: this.wasBlockDeleted(block)})
  }

  private wasBlockDeleted(block: Block) {
    return !!this.baseBlocksById[block.id]
  }
}