import {Locale} from 'domain/Locale'
import {Project} from 'domain/Project'
import {ReactComponent as UnpublishIcon} from '../../../assets/icons/arrow_downward.svg'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {unpublishProjectReport} from '../../../api-clients/projectClient'
import useModal from '../../../hooks/useModal'
import ConfirmationModal from '../../utils/ConfirmationModal'

interface Props {
  project: Project
  locale: Locale
  loadProject: () => void
  disabled: boolean
}

const UnpublishReportButton: React.FC<Props> = props => {
  const {project, locale, loadProject, disabled} = props
  const {t} = useTranslation()
  const {showModal} = useModal()

  const onConfirmUnpublishing = async () => {
    unpublishProjectReport(project._id, locale).then(() => loadProject())
  }

  const unpublish = (event: React.MouseEvent) => {
    event.preventDefault()

    showModal(
      <ConfirmationModal
        title={t('components.UnpublishReportButton.title')}
        body={t('components.UnpublishReportButton.message', {
          type: t('components.UnpublishReportButton.type.' + locale)
        })}
        ConfirmationIcon={UnpublishIcon}
        onConfirm={onConfirmUnpublishing}
      />
    )
  }

  return (
    <button className="btn btn-xs btn-with-icon btn-outline-danger text-nowrap" onClick={unpublish} disabled={disabled}>
      {t('components.UnpublishReportButton.action')}
      <UnpublishIcon className="icon-xs" />
    </button>
  )
}

export default UnpublishReportButton
