import {CompanyStatus} from 'domain/Company'
import React from 'react'
import {useTranslation} from 'react-i18next'


type Props = {
  status: CompanyStatus
}

const CompanyStatusBadge: React.FC<Props> = props => {
  const {status} = props
  const {t} = useTranslation()

  const statusColor = {
    [CompanyStatus.INACTIVE]: 'bg-warning',
    [CompanyStatus.DRAFT]: 'bg-info',
    [CompanyStatus.ACTIVE]: 'bg-success'
  }

  return <div className="small">
    <div className={`badge rounded-pill ms-2 ${statusColor[status]}`}>
      {t(`models.CompanyStatus.${status}`)}
    </div>
  </div>
}

export default CompanyStatusBadge