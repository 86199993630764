import {EditorBlock} from 'domain/Editor'
import {Id} from 'domain/Entity'
import {Locale} from 'domain/Locale'
import {User} from 'domain/User'
import {BlockOperation, ReportBlockUpdate, ReportSectionDelete, ReportUpdateType} from 'domain/Websockets'
import i18next from 'i18next'
import * as jsondiffpatch from 'jsondiffpatch'
import {useEffect} from 'react'
import websocket from '../../websocket'


type LockedBlock = Pick<ReportBlockUpdate, 'blockId' | 'operation' | 'locale' | 'username'>

class ReportLockManager {
  private lockedItems = new Map<string, ReportBlockUpdate | ReportSectionDelete>()
  private projectId?: Id
  private locale?: Locale
  private user?: User
  private started: boolean = false

  public startListening(projectId: Id, locale: Locale, user: User) {
    this.projectId = projectId
    this.locale = locale
    this.user = user
    this.lockedItems = new Map()
    this.started = true

    websocket.onReportUpdated(this.projectId, data => {
      this.lockedItems.set(JSON.stringify(data), data)
      this.processUpdate(data)
    })

    websocket.onReportMigrated(this.projectId, () => {
      alert(i18next.t('errors.reportMigrated'))
      window.location.reload()
    })
  }

  public resetLockedItems() {
    this.lockedItems = new Map()
    this.unlockAllItems()
  }

  public stopListening() {
    this.started = false

    if (this.projectId) {
      websocket.stopListeningReportUpdated(this.projectId)
      websocket.stopListeningReportMigrated(this.projectId)
    }
  }

  public notifyReportMigrated(projectId: Id) {
    websocket.notifyReportMigrated(projectId)
  }

  public notifySectionDeleted(sectionId: string) {
    if (!this.started) {
      return
    }

    websocket.notifySectionDeleted({
      projectId: this.projectId as string,
      sectionId,
      username: this.user!.name!,
      userId: this.user!._id as string,
      type: ReportUpdateType.SECTION
    })
  }

  public notifyBlocksChange(oldBlocks: EditorBlock[], updatedBlocks: EditorBlock[], locale: Locale) {
    if (!this.started) {
      return
    }

    oldBlocks.forEach(oldBlock => {
      const updatedBlock = updatedBlocks.find(block => block.id === oldBlock.id)
      const operation = updatedBlock ? BlockOperation.UPDATE : BlockOperation.DELETE
      const delta = jsondiffpatch.diff(oldBlock, updatedBlock)

      if (!!delta) {
        websocket.notifyBlockUpdated({
          projectId: this.projectId as string,
          locale,
          blockId: oldBlock.id,
          operation,
          username: this.user!.name!,
          userId: this.user!._id as string,
          type: ReportUpdateType.BLOCK
        })
      }
    })
  }

  public notifyBlocksMoved(movedBlocksIds: string[]) {
    if (!this.started) {
      return
    }

    movedBlocksIds.forEach(blockId => {
      websocket.notifyBlockUpdated({
        projectId: this.projectId as string,
        blockId,
        operation: BlockOperation.MOVE,
        username: this.user!.name!,
        userId: this.user!._id as string,
        type: ReportUpdateType.BLOCK
      })
    })
  }

  public withLockedContent(cb: () => Promise<void>) {
    return cb().then(() => {
      this.lockedItems.forEach(data => {
        this.processUpdate(data)
      })
    })
  }

  public validateBlocksOrderChange(previousBlockIds: string[], newBlockIds: string[]) {
    const lockedItems = Array.from(this.lockedItems.values())

    const movedBlocksIds = previousBlockIds.filter((prevBlockId, i) => prevBlockId !== newBlockIds[i])
    this.notifyBlocksMoved(movedBlocksIds)

    for (const movedBlockId of movedBlocksIds) {
      const lock = lockedItems.find(d => d.type === ReportUpdateType.BLOCK && d.blockId === movedBlockId)
      if (lock) {
        alert(i18next.t('errors.reportContentChanged', {username: lock.username}))
        window.location.reload()
      }
    }
  }

  private processUpdate(data: ReportBlockUpdate | ReportSectionDelete) {
    if (data.type === ReportUpdateType.BLOCK) {
      this.processLockedBlock(data)
    } else {
      this.processLockedSection(data)
    }
  }

  private processLockedBlock(data: ReportBlockUpdate) {
    const {blockId, operation, locale, username} = data
    const affectedNodes = Array.from(this.findAffectedNodes({blockId, operation, locale}))

    affectedNodes.forEach(node => {
      this.lockNode(node, username)
    })
  }

  private processLockedSection({sectionId, username}: ReportSectionDelete) {
    const node = document.getElementById(sectionId)
    if (node) {
      this.lockNode(node, username)
    }

    const sectionSidebarTitle = document.getElementById(`report-toc-item-${sectionId}`)
    if (sectionSidebarTitle) {
      this.lockSidebarSectionTitle(sectionSidebarTitle)
    }
  }

  private findAffectedNodes({blockId, operation, locale}: Omit<LockedBlock, 'username'>): NodeListOf<HTMLElement> {
    if ([BlockOperation.DELETE, BlockOperation.MOVE].includes(operation)) {
      return document.querySelectorAll(`[id="${blockId}"]`)
    } else {
      return document.querySelectorAll(`[data-locale="${locale}"] [id="${blockId}"]`)
    }
  }

  private lockNode(node: HTMLElement, username: string) {
    node.dataset.edited = username

    Array.from(node.querySelectorAll('[contenteditable="true"]')).forEach(node => {
      const nodeElement = node as HTMLElement
      nodeElement.dataset.contentEditable = nodeElement.contentEditable
      nodeElement.contentEditable = 'false'
    })

    node.dataset.contentEditable = node.contentEditable
    node.contentEditable = 'false'
  }

  private lockSidebarSectionTitle(node: HTMLElement) {
    node.classList.add('locked')
  }

  private unlockAllItems() {
    const lockedNodes = Array.from(document.querySelectorAll('[data-edited]')) as HTMLElement[]

    lockedNodes.forEach(node => {
      delete node.dataset.edited

      Array.from(node.querySelectorAll('[contenteditable="false"]')).forEach(node => {
        const nodeElement = node as HTMLElement
        nodeElement.contentEditable = nodeElement.dataset.contentEditable || 'true'
      })

      node.contentEditable = node.dataset.contentEditable || 'true'
    })
  }
}

export const useReportLock = (projectId: Id, locale: Locale, user: User) => {
  useEffect(() => {
    reportLockManager.startListening(projectId, locale, user)
    return () => reportLockManager.stopListening()
  }, [user, projectId, locale])
}

const reportLockManager = new ReportLockManager()
export default reportLockManager