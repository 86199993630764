import {Locale} from 'domain/Locale'
import {Permission} from 'domain/Permission'
import React from 'react'
import {useTranslation} from 'react-i18next'
import InlineEditable from '../utils/form/InlineEditable'
import Protected from '../utils/Protected'


interface Props {
  isEditable: boolean
  locale: Locale
  term: string
  onChange: (value: string) => void
  errorMessage?: string
}

const GlossaryTermRecord: React.FC<Props> = props => {
  const {isEditable, term, onChange, locale, errorMessage} = props
  const {t} = useTranslation()

  return (<Protected permission={Permission.Glossary.UPDATE} else={term}>
    {
      isEditable ?
        <InlineEditable value={term} initiallyEditable={!term}
                        placeholder={t(`glossary.term.${locale}.placeholder`)}
                        onChange={onChange}
                        errorMessage={errorMessage}
        /> : <span>{term}</span>
    }
  </Protected>)
}

export default GlossaryTermRecord