import {Holiday} from 'domain/Holiday'
import {Locale} from 'domain/Locale'
import moment, {Moment} from 'moment-timezone'


type TaskDates = {
  startDate: Date
  endDate: Date
}

export const roundHours = (date: Date) => {
  const dateWithHourPrecision = new Date(date)
  dateWithHourPrecision.setHours(date.getHours(), 0, 0, 0)

  if (date.getTime() > dateWithHourPrecision.getTime()) {
    dateWithHourPrecision.setHours(dateWithHourPrecision.getHours() + 1)
  }

  return dateWithHourPrecision
}

export const calculateTaskDates = (start: Date, hours: number, holidays: Holiday[]): TaskDates => {
  let startWithTZ = moment(start).utc()

  const endOfBusiness = endOfBusinessDate(startWithTZ)
  if (startWithTZ.isSameOrAfter(endOfBusiness)) {
    startWithTZ = findNextBusinessDateStart(startWithTZ, holidays)
  }

  const endDate = addBusinessHours(startWithTZ, hours, holidays)
  return {startDate: startWithTZ.toDate(), endDate: endDate.toDate()}
}

export const getBusinessDates = (startDate: Date, days: number, holidays: Holiday[]): { start: Date, end: Date }[] => {
  const result = []
  let current = moment(startDate).set({hours: 0, minutes: 0, seconds: 0, milliseconds: 0})

  if (!(isWeekend(current) || isHoliday(current, holidays))) {
    result.push({start: current.toDate(), end: current.endOf('date').toDate()})
  }

  const iterations = days - result.length
  for (let i = 0; i < iterations; i++) {
    const nextBusinessDate = findNextBusinessDate(current, holidays)
    result.push({start: nextBusinessDate.toDate(), end: nextBusinessDate.endOf('date').toDate()})
    current = nextBusinessDate
  }

  return result
}

export const isWithinDates = (startDate: Date, endDate: Date, rangeStart: Date, rangeEnd: Date) => {
  return moment(startDate).isSameOrBefore(rangeEnd) && moment(endDate).isSameOrAfter(rangeStart)
}

export const formattedBusinessDuration = (hours: number, locale: Locale) => {
  return moment.duration({hours}).locale(locale).humanize()
}

const addBusinessHours = (date: Moment, hours: number, holidays: Holiday[]): Moment => {
  const hoursForDate = millisecondsToHours(endOfBusinessDate(date).diff(date))
  const exceededHours = hours - hoursForDate

  if (exceededHours > 0) {
    return addBusinessHours(findNextBusinessDateStart(date, holidays), exceededHours, holidays)
  } else {
    return addHours(date, hours)
  }
}

const findNextBusinessDateStart = (previousDate: Moment, holidays: Holiday[]): Moment => {
  const nextBusinessDate = findNextBusinessDate(previousDate, holidays)
  nextBusinessDate.set({hours: 0, minutes: 0, seconds: 0, milliseconds: 0})
  return nextBusinessDate
}

const findNextBusinessDate = (previousDate: Moment, holidays: Holiday[]): Moment => {
  const nextBusinessDate = moment(previousDate)
  nextBusinessDate.add({day: 1})

  if (isWeekend(nextBusinessDate) || isHoliday(nextBusinessDate, holidays)) {
    return findNextBusinessDate(nextBusinessDate, holidays)
  }
  nextBusinessDate.set({hours: 0, minutes: 0, seconds: 0, milliseconds: 0})
  return nextBusinessDate
}

const addHours = (date: Moment, hours: number) => {
  const newDate = moment(date)
  newDate.add({hours})
  return newDate
}

const endOfBusinessDate = (date: Moment) => {
  const result = moment(date)
  result.set({hours: 8})
  return result
}

const millisecondsToHours = (milliseconds: number) => {
  return Math.ceil(milliseconds / (3600 * 1000))
}

const isWeekend = (date: Moment) => {
  const weekday = date.weekday()
  const sunday = 0
  const saturday = 6
  return weekday === saturday || weekday === sunday
}

const isHoliday = (date: Moment, holidays: Holiday[]) => {
  return holidays.some(holiday => date.format('YYYY-MM-DD') === holiday.date)
}