import {Job, JobStatus} from 'domain/Job'
import {getJob} from '../api-clients/jobClient'


const waitJobCompletion = (job: Job, timeout = 1000) => {
  return new Promise((resolve, reject) => {
    checkJob(job._id as string, timeout, resolve, reject)
  })
}

const checkJob = (jobId: string, timeout: number, resolve: any, reject: any) => {
  setTimeout(async () => {
    const job = await getJob(jobId).catch(() => {
      reject(job)
    })

    if (!job) return

    if (job.status === JobStatus.SUCCEEDED) {
      resolve(job)
    } else if (job.status === JobStatus.FAILED) {
      reject(job)
    } else {
      checkJob(jobId, timeout, resolve, reject)
    }
  }, timeout)
}

export default waitJobCompletion