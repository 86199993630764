import {Id} from 'domain/Entity'
import {BreadcrumbPart} from 'domain/MicroTranslation'
import React from 'react'


interface Props {
  breadcrumb:BreadcrumbPart[]
  projectId: Id
}

const Breadcrumb: React.FC<Props> = props => {
  const {breadcrumb, projectId} = props

  return <ol className="breadcrumb">
    {breadcrumb.map((breadcrumbPart, i) => {
      const className = `small breadcrumb-item ${i === 0 ? 'active fw-bold' : ''}`

      return <li className={className} key={`${i}-${breadcrumbPart.id}`}>
        {i === 0 ?
          <a href={`/projects/${projectId}`} target="_blank">{breadcrumbPart.title}</a> :
          breadcrumbPart.title
        }
      </li>
    })}
  </ol>
}

export default Breadcrumb