import {useState} from 'react'


interface UseForceRenderState {
  rerender: () => void
}

const useForceRender = (): UseForceRenderState => {
  const [value, setValue] = useState(true)

  const rerender = () => {
    setValue(v => !v)
  }

  return {rerender}
}

export default useForceRender