import classnames from 'classnames'
import {Locale} from 'domain/Locale'
import {ExcelTableBlock, MainTableStyle} from 'domain/Report'
import React, {ReactElement} from 'react'
import {getCellClassNames, getTableClassNames} from '../../../editorjs/plugins/excel-table/tableClassNamesHelper'
import memoizeComponent from '../../../utils/memoizeComponent'
import RawHtml from '../../utils/RawHtml'
import {cellStyles} from './ViewHelper'


interface TableProps {
  block: ExcelTableBlock,
  locale: Locale
}

const APPROXIMATE_TABLE_ROWS_PER_PAGE = 50

const Table: React.FC<TableProps> = ({block, locale}): ReactElement => {
  const title = block.title[locale]?.value
  const description = block.description[locale]?.value
  const tableTemplate = block.layout[locale]?.template || []
  const style = block.layout[locale]?.style || MainTableStyle.NORMAL
  const additionalStyles = block.layout[locale]?.additionalStyles || []

  const isLargeTable = (block.content[locale]?.value || []).length > APPROXIMATE_TABLE_ROWS_PER_PAGE

  return (
    <div className={isLargeTable ? '' : 'print-break-inside-avoid'}>
      {title && (<div className="entity-title"><RawHtml>{title}</RawHtml></div>)}
      <div className="table-responsive">
        <table className={classnames(getTableClassNames([...additionalStyles, style]))} data-excel-table={true}>
          <tbody>
          {(block.content[locale]?.value || []).map((row, rowIndex) => {
            const rowTemplate = tableTemplate[rowIndex] || {}
            return (
              <tr key={rowIndex}>
                {
                  row.map((cellContent, cellIndex) => {
                    const template = rowTemplate.cells?.[cellIndex] || {}
                    const styles = cellStyles(template)

                    return (
                      <React.Fragment key={`${rowIndex}-${cellIndex}`}>
                        {rowIndex === 0 && (<th className={classnames(getCellClassNames(template))}
                                                style={styles}
                                                colSpan={template.colSpan || 1}
                                                rowSpan={template.rowSpan || 1}>
                          <RawHtml>{cellContent}</RawHtml>
                        </th>)}

                        {rowIndex !== 0 && (<td className={classnames(getCellClassNames(template))}
                                                style={styles}
                                                colSpan={template.colSpan || 1}
                                                rowSpan={template.rowSpan || 1}>
                          <RawHtml>{cellContent}</RawHtml>
                        </td>)}
                      </React.Fragment>
                    )
                  })}
              </tr>)
          })}
          </tbody>
        </table>
      </div>
      {description && (<div className="entity-description"><RawHtml>{description}</RawHtml></div>)}
    </div>
  )
}

export default memoizeComponent(Table)
