import {BackgroundStyle} from 'domain/Report'
import React, {FC} from 'react'
import {useTranslation} from 'react-i18next'
import {
  MENU_COLOR_BUTTON_STYLE,
  MENU_ICON_STYLE,
  MENU_ITEM_TITLE_STYLE,
  MENU_SECONDARY_ITEM_STYLE,
  MENU_SECTION_STYLE
} from './styles'

interface Props {
  onBackgroundColorPicked: (style: BackgroundStyle) => void
}

export const BackgroundColorButtonsSection: FC<Props> = ({onBackgroundColorPicked}) => {
  const {t} = useTranslation()

  const className = (backgroundStyle: BackgroundStyle) =>
    `table-cell-${backgroundStyle.toLowerCase()}  ${MENU_COLOR_BUTTON_STYLE} ${MENU_SECONDARY_ITEM_STYLE}`

  return (
    <div>
      <div className={MENU_ITEM_TITLE_STYLE}>{t('Background color')}</div>
      <div className={MENU_SECTION_STYLE}>
        <div
          className={className(BackgroundStyle.HEADER)}
          onClick={() => onBackgroundColorPicked(BackgroundStyle.HEADER)}
        />
        <div
          className={className(BackgroundStyle.HIGHLIGHTED)}
          onClick={() => onBackgroundColorPicked(BackgroundStyle.HIGHLIGHTED)}
        />
        <div
          className={className(BackgroundStyle.NORMAL)}
          onClick={() => onBackgroundColorPicked(BackgroundStyle.NORMAL)}
        />
      </div>
    </div>
  )
}
