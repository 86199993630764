import React from 'react'
import Error from './Error'


type TextareaProps = React.DetailedHTMLProps<React.TextareaHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement> & {
  error?: string | null
}

const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(({error, ...props}, ref) => {
  return (
    <>
      <textarea {...props} ref={ref} className={`${props.className} ${error ? 'is-invalid' : ''}`}/>
      {error && <Error error={error}/>}
    </>
  )
})

export default Textarea
