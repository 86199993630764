import {AggregatedProjectLog} from 'domain/ProjectLog'
import React from 'react'
import {useTranslation} from 'react-i18next'
import RawHtml from '../../../utils/RawHtml'


interface LogProps {
  log: AggregatedProjectLog
}

const ReportEditLogLabel: React.FC<LogProps> = ({log}) => {
  const {t} = useTranslation()
  const username = (log.user ? log.user.name : log.userId)

  return <RawHtml>
    {log.params.locale ?
      t(`components.ProjectLogs.${log.type}.${log.params.locale}`, {username}) :
      t(`components.ProjectLogs.${log.type}.withoutLocale`, {username})}
  </RawHtml>
}

export default ReportEditLogLabel