import React from 'react'
import Error from './Error'

type InputProps = React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> & {
  error?: string | null
}

const Input: React.FunctionComponent<InputProps> = ({error, ...props}) => {
  return (
    <>
      <input {...props} className={`${props.className} ${error ? 'is-invalid' : ''}`}/>
      {error && <Error error={error}/>}
    </>
  )
}

export default Input
