import React, {ReactNode, useEffect} from 'react'


interface Props {
  onClose: (e: React.MouseEvent | Event) => void
}

interface SingleComponentProps extends Props {
  component: ReactNode
}

interface MultipleComponentProps extends Props {
  components: ReactNode[],
}

const Modal: React.FC<SingleComponentProps | MultipleComponentProps> = props => {
  const {onClose} = props

  useEffect(() => {
    document.body.classList.add('overflow-hidden', 'modal-open')
    document.addEventListener('keydown', onKeyDown)

    return () => {
      document.body.classList.remove('overflow-hidden', 'modal-open')
      document.removeEventListener('keydown', onKeyDown)
    }
  }, [])

  const onKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      onClose(event)
    }
  }

  const {components} = props as MultipleComponentProps
  const {component} = props as SingleComponentProps

  const modalComponents = [...(components ? components : []), component].filter(c => !!c)

  return (
    <div id="modals-container">
      {modalComponents.map((component, i) => (
        <React.Fragment key={`modal-${i}`}>
          <div className="modal show" style={{zIndex: 1041 + i}}>
            {component}
          </div>
          <div className="modal-backdrop fade show" style={{zIndex: 1040 + i}}/>
        </React.Fragment>
      ))}
    </div>
  )
}

export default Modal