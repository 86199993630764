import {Id} from 'domain/Entity'
import {User} from 'domain/User'
import React, {FC, KeyboardEvent, MouseEvent, ReactElement, useContext, useEffect, useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {postClientError} from '../../../api-clients/clientErrors'
import {getMentionableUsersForProject} from '../../../api-clients/userClient'
import {ReportContext} from '../../../providers/ReportProvider'
import ContentEditable from '../../utils/form/ContentEditable'
import {findMentionedEntry} from './commentUtils'

export const MENTION_REGEXP = /(^|\s|<br>|&nbsp;)@([^@]*)$/

interface Props {
  projectId: Id
  content: string
  onContentChange: (content: string) => void
  onSubmit: (props?: any) => void
  error?: string
  autofocus?: boolean
}

const CommentContentForm: FC<Props> = (props): ReactElement => {
  const {projectId, content, onContentChange, onSubmit, error, autofocus} = props
  const {t} = useTranslation()
  const [mentionableUsers, setMentionableUsers] = useState<User[]>([])
  const [userSearch, setUserSearch] = useState<string | null>(null)
  const reportContext = useContext(ReportContext)

  useEffect(() => {
    if (projectId === undefined) {
      postClientError({
        ...reportContext,
        message: 'projectId for getMentionableUsersForProject is missing',
        projectId: reportContext.project?._id
      })
    }

    getMentionableUsersForProject(projectId).then(setMentionableUsers)
  }, [])

  useEffect(() => {
    setUserSearch(findMentionedEntry(content))
  }, [content])

  const handleSubmitShortcut = (e: KeyboardEvent<HTMLDivElement>): void => {
    if (e.key === 'Enter' && (e.ctrlKey || e.metaKey)) {
      onSubmit(e)
    }
  }

  const addUserMention = (event: MouseEvent, user: User) => {
    event.preventDefault()
    event.stopPropagation()
    onContentChange(
      content.replace(
        MENTION_REGEXP,
        `$1@<a href="/users/${user._id}" data-user-id="${user._id}" target="_blank">${user.name}</a>`
      )
    )
    setUserSearch(null)
  }

  const filteredMentionableUsers = useMemo(() => {
    if (!userSearch) return mentionableUsers
    return mentionableUsers.filter(
      user =>
        user.name?.toLocaleLowerCase().includes(userSearch.toLowerCase()) ||
        user.email.toLowerCase().includes(userSearch.toLowerCase())
    )
  }, [userSearch, mentionableUsers])

  return (
    <div className="position-relative">
      <ContentEditable
        value={content}
        onContentChange={onContentChange}
        onKeyDown={handleSubmitShortcut}
        data-placeholder={t('components.Report.comments.CommentContentForm.contentPlaceholder')}
        className="form-control form-control-sm"
        error={error}
        autofocus={autofocus}
      />
      {userSearch !== null && filteredMentionableUsers.length > 0 && (
        <ul className="dropdown-menu show w-100">
          {filteredMentionableUsers.map(user => (
            <li key={user._id as string}>
              <a
                className="dropdown-item text-pre-wrap d-block"
                href="#"
                onClick={event => addUserMention(event, user)}
              >
                <span className="d-block">{user.name}</span>
                <small className="text-muted">{user.email}</small>
              </a>
            </li>
          ))}
        </ul>
      )}
    </div>
  )
}

export default CommentContentForm
