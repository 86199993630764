import {Project} from 'domain/Project'
import {ReactComponent as ArrowDown} from '../../../assets/icons/arrow_downward.svg'
import {ReactComponent as ArrowUp} from '../../../assets/icons/arrow_upward.svg'
import React, {PropsWithChildren, ReactElement, useEffect} from 'react'
import EventBus, {EventType} from '../../../EventBus'
import useTagNavigator from '../../../hooks/useTagNavigator'
import memoizeComponent from '../../../utils/memoizeComponent'
import ProjectButton from '../../project/ProjectButton'


interface ReportNavigationProps {
  project: Project
}

const MergeNavigation: React.FC<PropsWithChildren<ReportNavigationProps>> = ({project, children}): ReactElement => {
  const navigator = useTagNavigator()

  const updatedNavigator = () => {
    navigator.updateTags(Array.from(document.querySelectorAll('.merge-requires-resolution')))
  }

  useEffect(() => {
    EventBus.on(EventType.MERGE_CONFLICT_RENDERED, updatedNavigator)
    return () => EventBus.unsubscribe(EventType.MERGE_CONFLICT_RENDERED, updatedNavigator)
  }, [])

  const navigate = (event: React.MouseEvent, step: number) => {
    event.preventDefault()
    if (step > 0) navigator.navigateForward()
    else navigator.navigateBack()
  }

  return (
    <div className="container-fluid">
      <div className="row flex-grow-1">
        <div className="col-4">
          <div className="h6 mt-1 mb-0 d-block text-truncate">
            {project.title}
          </div>
          <div>
            <ProjectButton project={project} useGenericTitle buttonClass="me-2"/>
          </div>
        </div>
        <div className="col-8 d-flex align-items-center justify-content-end">

          <div className="navbar navbar-expand ms-3">
            <div className="navbar-nav">
              <div className="nav-item">
                <a className={`nav-link ${navigator.canNavigateBack() ? 'text-muted' : 'text-muted-more'}`}
                   href="#" onClick={e => navigate(e, -1)}>
                  <ArrowUp className="icon" />
                </a>
              </div>

              <div className="nav-item">
                <a className={`nav-link ${navigator.canNavigateForward() ? 'text-muted' : 'text-muted-more'}`} href="#"
                   onClick={e => navigate(e, +1)}>
                  <ArrowDown className="icon" />
                </a>
              </div>
            </div>
          </div>

          {children}
        </div>
      </div>
    </div>)
}

export default memoizeComponent(MergeNavigation)