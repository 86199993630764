import {Locale} from 'domain/Locale'


export enum WebsocketChannel {
  REPORT_UPDATE = 'report-update',
  REPORT_MIGRATED = 'report-migrated',
  PROJECT_UPDATED = 'project-updated',
  PROJECT_WORD_COUNT_UPDATED = 'project-word-count-updated',
  PENDING_MICRO_TRANSLATION_COUNT_CHANGED = 'pending-micro-translation-count-changed',
  PROJECT_NOTIFICATION_CREATED = 'project-notification-created'
}

export enum BlockOperation {
  UPDATE = 'UPDATE',
  DELETE = 'DELETE',
  MOVE = 'MOVE'
}

export enum ReportUpdateType {
  BLOCK = 'BLOCK',
  SECTION = 'SECTION'
}

export enum ProjectUpdateType {
  REPORT_GENERATION_START = 'REPORT_GENERATION_START',
  REPORT_GENERATION_SUCCEEDED = 'REPORT_GENERATION_SUCCEEDED',
  REPORT_GENERATION_FAILED = 'REPORT_GENERATION_FAILED',
  REPORT_GENERATION_FINISHED = 'REPORT_GENERATION_FINISHED',
}

export interface ReportBlockUpdate {
  type: ReportUpdateType.BLOCK
  blockId: string
  userId: string
  username: string
  projectId: string
  locale?: Locale
  operation: BlockOperation
}

export interface ReportSectionDelete {
  type: ReportUpdateType.SECTION
  sectionId: string
  userId: string
  username: string
  projectId: string
}