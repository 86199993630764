import {Comment, CommentWithUserName, ReplyWithUserName} from 'domain/Comment'
import {Id} from 'domain/Entity'
import apiClient from '../ApiClient'

const createComment = async (comment: Partial<Comment>): Promise<CommentWithUserName> => {
  return (await apiClient.post('/api/comments', comment)) as CommentWithUserName
}

const getComments = async (projectId: Id, suppressError = false): Promise<CommentWithUserName[]> => {
  return (await apiClient.get(`/api/comments?projectId=${projectId}`, suppressError)) as CommentWithUserName[]
}

const stickComment = async (commentId: Id): Promise<CommentWithUserName> => {
  return (await apiClient.patch(`/api/comments/${commentId}/stick`, {})) as CommentWithUserName
}

const unstickComment = async (commentId: Id): Promise<CommentWithUserName> => {
  return (await apiClient.patch(`/api/comments/${commentId}/unstick`, {})) as CommentWithUserName
}

const addReply = async (commentId: Id, content: string, projectId: Id): Promise<ReplyWithUserName> => {
  return (await apiClient.post(`/api/comments/${commentId}/replies`, {content, projectId})) as ReplyWithUserName
}

const updateComment = async (commentId: Id, content: string, projectId: Id): Promise<CommentWithUserName> => {
  return (await apiClient.patch(`/api/comments/${commentId}`, {content, projectId})) as CommentWithUserName
}

const updateCommentVisibility = async (commentId: Id, isVisibleForCompany: boolean): Promise<CommentWithUserName> => {
  return await apiClient.patch(`/api/comments/${commentId}/visibility`, {isVisibleForCompany})
}

const resolveComment = async (commentId: Id, projectId: Id): Promise<CommentWithUserName> => {
  return (await apiClient.patch(`/api/comments/${commentId}/resolve`, {projectId})) as CommentWithUserName
}

const unresolveComment = async (commentId: Id, projectId: Id): Promise<CommentWithUserName> => {
  return (await apiClient.patch(`/api/comments/${commentId}/unresolve`, {projectId})) as CommentWithUserName
}

const deleteComment = async (commentId: Id, projectId: Id): Promise<CommentWithUserName> => {
  return await apiClient.delete(`/api/comments/${commentId}?projectId=${projectId}`)
}

const updateReply = async (commentId: Id, replyId: Id, content: string): Promise<ReplyWithUserName> => {
  return (await apiClient.patch(`/api/comments/${commentId}/replies/${replyId}`, {content})) as ReplyWithUserName
}

const deleteReply = async (commentId: Id, replyId: Id): Promise<ReplyWithUserName> => {
  return await apiClient.delete(`/api/comments/${commentId}/replies/${replyId}`)
}

export {
  unresolveComment as activateComment,
  createComment,
  getComments,
  addReply,
  updateComment,
  updateCommentVisibility,
  stickComment,
  unstickComment,
  resolveComment,
  updateReply,
  deleteComment,
  deleteReply,
}
