import {hasProjectAccess} from 'domain/access'
import {ReactComponent as GlossaryIcon} from '../../../assets/icons/glossary.svg'
import React, {useContext} from 'react'
import {Link} from 'react-router-dom'
import {UserContext} from '../../../providers/UserProvider'
import {ProjectAggregate} from 'domain/ProjectAggregate'

type Props = {
  project: ProjectAggregate
}

const CompanyGlossaryLink: React.FC<Props> = props => {
  const {project} = props
  const {user} = useContext(UserContext)

  return (
    <div className="navbar-nav">
      {hasProjectAccess(user, project) && (
        <div className="nav-item">
          <Link to={`/glossary/${project.companyId}`} target="_blank" className="nav-link">
            <GlossaryIcon className="icon" />
          </Link>
        </div>
      )}
    </div>
  )
}

export default CompanyGlossaryLink
