import apiClient from '../ApiClient'


const login = async (credentials: { email?: string, password?: string }): Promise<{ accessToken: string }> => {
  return await apiClient.post('/api/tokens/authenticate', credentials)
}

const renewAccessToken = async (): Promise<{ accessToken: string }> => {
  return await apiClient.post('/api/tokens/refresh', {})
}

const logout = async (): Promise<void> => {
  return await apiClient.delete('/api/tokens/clear')
}

export {
  login,
  renewAccessToken,
  logout
}