import {Locale} from 'domain/Locale'
import {BlockType, HeadingBlock, Section} from 'domain/Report'
import {isNewsUpdatesSection} from 'domain/reportUtils'
import {ReactComponent as AddIcon} from '../../../assets/icons/add.svg'
import {ReactComponent as ArrowDownIcon} from '../../../assets/icons/arrow_down.svg'
import {ReactComponent as ArrowDown} from '../../../assets/icons/arrow_downward.svg'
import {ReactComponent as ArrowRightIcon} from '../../../assets/icons/arrow_right.svg'
import {ReactComponent as ArrowUp} from '../../../assets/icons/arrow_upward.svg'
import {ReactComponent as DeleteIcon} from '../../../assets/icons/delete.svg'
import React, {ReactElement, useContext, useState} from 'react'
import {Dropdown} from 'react-bootstrap'
import {Collapse} from 'react-collapse'
import {useTranslation} from 'react-i18next'
import useModal from '../../../hooks/useModal'
import {ReportContext} from '../../../providers/ReportProvider'
import memoizeComponent from '../../../utils/memoizeComponent'
import {getSubSectionBlocks} from '../../../utils/reportHelpers'
import DropdownMenu from '../../utils/DropdownMenu'
import {SelectedBlocksContext} from '../bookmarks/SelectedBlocksProvider'
import DeleteSectionModal from '../edit/DeleteSectionModal'
import reportLockManager from '../ReportLockManager'
import HeadingLink, {HeadingType} from './HeadingLink'
import {addSelectionToBlocks, removeSelectionFromBlocks} from './sectionUtils'

interface SectionProps {
  section: Section
  locale: Locale
  displayMenu: boolean
}

const Section: React.FC<SectionProps> = ({section, locale, displayMenu}): ReactElement => {
  const {t} = useTranslation()
  const headings = section.blocks.filter(block => block.type === BlockType.HEADING) as HeadingBlock[]
  const [mainHeading, ...subHeadings] = headings
  const [collapsed, setCollapsed] = useState(true)
  const {reportStore, visibleSectionId} = useContext(ReportContext)
  const {setSelectedBlocks} = useContext(SelectedBlocksContext)

  const onToggleCollapse = (event: React.MouseEvent) => {
    event.preventDefault()
    setCollapsed(collapsed => !collapsed)
  }

  const deleteSectionAndCloseMenu = () => {
    reportLockManager.notifySectionDeleted(section._id)
    reportStore.deleteSection(section._id)
  }

  const displayHeading = (block: HeadingBlock) => {
    return ['1', '2', '3'].indexOf(block.level.toString()) !== -1
  }

  const missingTitleHeading = (section: Section) => {
    return {
      id: section._id,
      level: '1',
      text: {
        [Locale.EN]: {value: t('components.Report.navigation.Section.title_missing')},
        [Locale.JA]: {value: t('components.Report.navigation.Section.title_missing')}
      }
    }
  }

  const selectSubSection = (startHeading: HeadingBlock) => {
    removeSelectionFromBlocks()

    const subSectionBlocks = getSubSectionBlocks(section, startHeading)
    addSelectionToBlocks(subSectionBlocks)
    setSelectedBlocks(subSectionBlocks)
  }

  const deleteSubSection = (startHeading: HeadingBlock) => {
    const subSectionBlocks = getSubSectionBlocks(section, startHeading)
    const updatedBlocks = section.blocks.filter(block => !subSectionBlocks.includes(block))
    reportStore.updateSectionBlocksAndRerender(section._id, updatedBlocks, locale)
  }

  const displayedMainHeading = mainHeading || missingTitleHeading(section)

  const {showModal} = useModal()

  const moveSectionForward = (id: string) => {
    reportStore.moveSection(id, true)
  }

  const moveSectionBackward = (id: string) => {
    reportStore.moveSection(id, false)
  }

  return (
    <div className={`report-toc-section ${visibleSectionId === section._id ? 'report-toc-section-in-viewport' : ''}`}>
      <div
        className={`report-toc-item report-toc-item-h${displayedMainHeading.level}`}
        id={`report-toc-item-${section._id}`}
      >
        <a className="report-toc-section-toggle" onClick={onToggleCollapse}>
          {collapsed ? <ArrowRightIcon className="icon" /> : <ArrowDownIcon className="icon" />}
        </a>

        <HeadingLink
          heading={displayedMainHeading}
          locale={locale}
          headingType={mainHeading ? HeadingType.NORMAL : HeadingType.WARNING}
        />

        {displayMenu && (
          <DropdownMenu id={`section-menu-${section._id}`}>
            {!isNewsUpdatesSection(section.type) && (
              <Dropdown.Item onClick={() => showModal(<DeleteSectionModal onDelete={deleteSectionAndCloseMenu} />)}>
                <DeleteIcon className="icon icon-sm ms-n1 me-1 text-danger" />
                <span>{t('components.Report.navigation.Section.deleteSection')}</span>
              </Dropdown.Item>
            )}
            <Dropdown.Item onClick={() => moveSectionBackward(section._id)}>
              <ArrowUp className="icon icon-sm ms-n1 me-1" />
              <span>{t('components.Report.navigation.Section.moveUp')}</span>
            </Dropdown.Item>
            <Dropdown.Item onClick={() => moveSectionForward(section._id)}>
              <ArrowDown className="icon icon-sm ms-n1 me-1" />
              <span>{t('components.Report.navigation.Section.moveDown')}</span>
            </Dropdown.Item>
          </DropdownMenu>
        )}
      </div>

      <Collapse isOpened={!collapsed} theme={{collapse: 'transition-slide-up', content: 'transition-slide-down'}}>
        {subHeadings.map(heading => {
          return (
            displayHeading(heading) && (
              <div className={`report-toc-item report-toc-item-h${heading.level}`} key={heading.id}>
                <HeadingLink heading={heading} locale={locale} />
                {displayMenu && (
                  <DropdownMenu id={`subsection-menu-${heading.id}`}>
                    <Dropdown.Item onClick={() => selectSubSection(heading)}>
                      <AddIcon className="icon icon-sm ms-n1 me-1 text-info" />
                      <span>{t('components.Report.navigation.Section.selectSection')}</span>
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => deleteSubSection(heading)}>
                      <DeleteIcon className="icon icon-sm ms-n1 me-1 text-danger" />
                      <span>{t('components.Report.navigation.Section.deleteSection')}</span>
                    </Dropdown.Item>
                  </DropdownMenu>
                )}
              </div>
            )
          )
        })}
      </Collapse>
    </div>
  )
}

export default memoizeComponent(Section)
