import {Locale} from 'domain/Locale'
import {AggregatedMicroTranslationProject} from 'domain/MicroTranslation'
import {ReactComponent as MailIcon} from '../../assets/icons/mail.svg'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'
import UserButton from '../user/UserButton'
import TranslationDeadlineBadge from './TranslationDeadlineBadge'

type Props = {
  microTranslation: AggregatedMicroTranslationProject
}

const MicroTranslationCard: React.FC<Props> = ({microTranslation}) => {
  const history = useHistory()
  const {t} = useTranslation()

  const onClick = (event: React.MouseEvent) => {
    event.preventDefault()
    history.push(`/micro-translations/${microTranslation._id}`)
  }

  return (
    <div className="card card-clickable mb-2" onClick={onClick}>
      <div className="card-body p-2">
        <div className="d-flex justify-content-between mb-1 small align-items-center">
          <div>
            <div className="fw-bold">{microTranslation.investorOrganizationName}</div>
            <div>{microTranslation.investorName}</div>
          </div>
          <div className="text-muted-more mx-1">vs</div>
          <div className="text-right fw-bold">
            <div>{microTranslation.companyName}</div>
          </div>
        </div>

        {microTranslation.translator && (
          <div className="d-flex align-items-center text-muted mb-2">
            <div className="small me-1">{t('components.MicroTranslationCard.translator')}</div>
            <UserButton user={microTranslation.translator} buttonClass="btn-outline-secondary me-1" />
          </div>
        )}

        {microTranslation.pendingTranslations.length > 0 && (
          <div className="mb-1">
            {microTranslation.pendingTranslations.map(translation => {
              return (
                <div
                  className="d-flex justify-content-between align-items-center border-top"
                  key={translation._id.toString()}
                >
                  <div className="me-1">
                    <MailIcon className="icon-xs text-muted-more" />
                  </div>
                  <div className="flex-grow-1">
                    <div className="small text-muted">
                      <span>{translation.locale === Locale.JA ? 'JA › EN' : 'EN › JA'}</span> ·{' '}
                      <span>{t('components.MicroTranslationCard.words', {count: translation.wordCount})}</span>
                    </div>
                  </div>
                  <div className="flex-grow-0 text-right d-flex flex-column text-center ms-2">
                    <div className="small text-right small text-nowrap">
                      <TranslationDeadlineBadge sentAt={translation.sentAt} />
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        )}
      </div>
    </div>
  )
}

export default MicroTranslationCard
