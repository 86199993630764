import {Id} from 'domain/Entity'
import {GlossaryTerm, GlossaryTermWithAlternatives, GlossaryType, TermTranslation} from 'domain/Glossary'
import {Locale} from 'domain/Locale'
import {Permission} from 'domain/Permission'
import {ReactComponent as DeleteIcon} from '../../assets/icons/delete.svg'
import React, {ChangeEvent, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {addOrUpdateGlossaryTerm} from '../../api-clients/glossaryTermClient'
import useModal from '../../hooks/useModal'
import {Pagination} from '../../hooks/usePaginatedCollection'
import useToast from '../../hooks/useToast'
import PaginatedTable from '../utils/PaginatedTable'
import Protected from '../utils/Protected'
import AlternativeTranslations from './AlternativeTranslations'
import DeleteGlossaryTerm from './DeleteGlossaryTerm'
import GlossaryComment from './GlossaryComment'
import GlossaryTermRecord from './GlossaryTermRecord'

interface Props {
  pagination: Pagination<GlossaryTermWithAlternatives>
  isEditable: boolean
  glossaryType: GlossaryType
  companyTicks: any
  companyId?: Id
}

interface FailedTranslation {
  id: Id
  message: string
}

const GlossaryTerms: React.FC<Props> = props => {
  const {pagination, isEditable, glossaryType, companyTicks, companyId} = props
  const {t} = useTranslation()
  const {showModal} = useModal()
  const {renderErrorToast} = useToast()
  const [failedTranslation, setFailedTranslation] = useState<FailedTranslation>()

  const updateTerm = (updatedTerm: GlossaryTerm) => {
    setFailedTranslation(undefined)

    addOrUpdateGlossaryTerm(glossaryType, updatedTerm, companyId)
      .then(() => {
        pagination.reload()
      })
      .catch((error: any) => {
        const fieldError = error.errors?.en || error.errors?.ja
        if (fieldError) {
          const errorMessage = t(`errors.${fieldError}`)
          setFailedTranslation({id: updatedTerm._id, message: errorMessage})
          renderErrorToast(errorMessage)
        } else {
          renderErrorToast(t('glossary.errors.notSaved'))
        }
      })
  }

  const inlineErrorMessage = (term: TermTranslation) => {
    return failedTranslation && failedTranslation.id === term._id ? failedTranslation.message : undefined
  }

  const confirmDeletion = (term: TermTranslation) => {
    showModal(<DeleteGlossaryTerm term={term} onDeleted={onDelete} />)
  }

  const onDelete = () => {
    pagination.reload()
  }

  const onSelectAsPrimary = (event: ChangeEvent<HTMLInputElement>, term: GlossaryTermWithAlternatives) => {
    const isPrimary = event.target.checked
    updateTerm({...term, isPrimary})
  }

  const tableHeader = (
    <thead>
      <tr className="border-bottom">
        <th />
        <th className="w-25">
          <h6>{t('glossary.term.ja.title')}</h6>
        </th>
        <th className="w-25">
          <h6>{t('glossary.term.en.title')}</h6>
        </th>
        <th className="w-50" colSpan={3}>
          <h6 className="mb-0">{t('glossary.term.comment.title')}</h6>
        </th>
      </tr>
    </thead>
  )

  return (
    <>
      <PaginatedTable
        pagination={pagination}
        thead={tableHeader}
        className={`table table-sm table-striped small mt-0 table-hover ${!isEditable && 'opacity-75'}`}
        render={(term: GlossaryTermWithAlternatives) => {
          return (
            <tr key={term._id as string}>
              <td>
                <AlternativeTranslations alternatives={term.alternatives} companyTicks={companyTicks} />
              </td>
              <td className="w-25">
                <GlossaryTermRecord
                  isEditable={isEditable}
                  locale={Locale.JA}
                  term={term[Locale.JA]}
                  onChange={value => updateTerm({...term, [Locale.JA]: value.trim()})}
                />
              </td>
              <td className="w-25">
                <GlossaryTermRecord
                  isEditable={isEditable}
                  locale={Locale.EN}
                  term={term[Locale.EN]}
                  onChange={value => updateTerm({...term, [Locale.EN]: value.trim()})}
                  errorMessage={inlineErrorMessage(term)}
                />
              </td>
              <td className="w-50">
                <GlossaryComment
                  isEditable={isEditable}
                  comment={term.comment ?? ''}
                  onChange={value => updateTerm({...term, comment: value})}
                />
              </td>
              <td>
                <Protected permission={Permission.Glossary.DELETE}>
                  <button
                    title={t('glossary.term.delete.title')}
                    className="btn btn-sm btn-outline-secondary btn-with-icon"
                    onClick={() => confirmDeletion(term)}
                    disabled={!isEditable}
                  >
                    <DeleteIcon className="icon icon-sm" />
                  </button>
                </Protected>
              </td>
              <td>
                {term.companyId && (
                  <Protected permission={Permission.Glossary.DELETE}>
                    <div className="is-primary-toggle form-check d-flex flex-column justify-content-center">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        checked={term.isPrimary}
                        onChange={e => onSelectAsPrimary(e, term)}
                        title={t('glossary.term.selectAsPrimary')}
                      />
                    </div>
                  </Protected>
                )}
              </td>
            </tr>
          )
        }}
      />
      <a id="endOfTable" />
    </>
  )
}

export default GlossaryTerms
