import classNames from 'classnames'
import {Permission} from 'domain/Permission'
import {TaskWithUser} from 'domain/Task'
import {User} from 'domain/User'
import {ReactComponent as PersonPlusIcon} from '../../../assets/icons/person_plus.svg'
import React from 'react'
import {useTranslation} from 'react-i18next'
import useModal from '../../../hooks/useModal'
import Protected from '../../utils/Protected'
import SetTaskAssignee from '../SetTaskAssignee'


type Props = {
  projectAssistants: User[]
  onAssigned: () => void
  task: TaskWithUser
  requiresAssignment?: boolean
  canAssignUser?: boolean
  canAssignAssistants?: boolean
}

const TaskAssignButton: React.FC<Props> = props => {
  const {
    onAssigned, task, requiresAssignment, projectAssistants, canAssignUser = true, canAssignAssistants = false
  } = props
  const {showModal} = useModal()
  const {t} = useTranslation()

  const onAssigneeModalOpen = (e: React.MouseEvent) => {
    e.preventDefault()
    showModal(<SetTaskAssignee task={task} onAssigned={onAssigned} canAssignUser={canAssignUser}
                               canAssignAssistants={canAssignAssistants} projectAssistants={projectAssistants}/>)
  }

  const buttonClassNames = {
    'btn btn-xs btn-with-icon ms-1': true,
    'btn-outline-primary': !requiresAssignment,
    'btn-outline-danger': requiresAssignment
  }

  return <Protected permission={Permission.Project.EDIT_SETTINGS}>
    <button className={classNames(buttonClassNames)} onClick={onAssigneeModalOpen}>
      <PersonPlusIcon className="icon-xs"/>
      <span>
        {(task?.user && !requiresAssignment) ?
          t(`components.TaskAssignButton.reassign`) :
          t(`components.TaskAssignButton.assign`)}
      </span>
    </button>
  </Protected>
}

export default TaskAssignButton