import {hasCommentWriteAccess} from 'domain/access'
import {Locale} from 'domain/Locale'
import {Permission} from 'domain/Permission'
import {Block, ReportState} from 'domain/Report'
import React, {useContext, useEffect, useMemo, useRef, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {CommentContext} from '../../../providers/CommentProvider'
import {UserContext} from '../../../providers/UserProvider'
import LazyGradually from '../../utils/LazyGradually'
import RawHtml from '../../utils/RawHtml'
import reportLockManager from '../ReportLockManager'
import ReportStore from '../ReportStore'
import {calculateDiffHTML} from './diffUtils'
import {
  firstBlockInViewport,
  keepScrollPositionInDiffView,
  keepScrollPositionInEditor,
  preserveHeight,
  removeMinHeightOnEditorRender
} from './editableReportSectionUtils'
import Editor, {EditorMode} from './Editor'
import SectionDiffToggle from './SectionDiffToggle'
import {ProjectAggregate} from 'domain/ProjectAggregate'

interface Props {
  sectionId: string
  project: ProjectAggregate
  reportStore: ReportStore
  autofocus: boolean
  baseReport: ReportState | null
  locale: Locale
  readonly: boolean
}

const EditableReportSection: React.FC<Props> = props => {
  const {sectionId, project, reportStore, baseReport, autofocus, locale, readonly} = props
  const {t, i18n} = useTranslation()
  const {addComment} = useContext(CommentContext)
  const {user, hasPermission} = useContext(UserContext)
  const [diffVisible, setDiffVisible] = useState(false)
  const [diffHTML, setDiffHTML] = useState('')
  const editorContainer = useRef<HTMLDivElement>(null)
  const showDiff = diffVisible && !!diffHTML
  const loadingDiff = diffVisible && !diffHTML
  const reportState = reportStore.getReport()
  const section = reportState?.sections.find(section => section._id === sectionId)!

  const baseReportSection = useMemo(() => {
    if (!baseReport) return
    return baseReport.sections.find(section => section._id === sectionId)
  }, [baseReport])

  useEffect(() => {
    if (!editorContainer.current) return

    const firstVisibleBlock = firstBlockInViewport(section)!
    preserveHeight(editorContainer.current!)
    if (diffVisible) {
      setTimeout(() => {
        keepScrollPositionInDiffView(firstVisibleBlock.id)
        setDiffHTML(calculateDiffHTML(baseReportSection?.blocks || [], section.blocks, locale, reportState))
      })
    } else {
      keepScrollPositionInEditor(firstVisibleBlock.id)
      removeMinHeightOnEditorRender(editorContainer.current!)
      setDiffHTML('')
    }
  }, [diffVisible])

  const onToggleDiff = (e: React.MouseEvent) => {
    e.preventDefault()
    setDiffVisible(visible => !visible)
  }

  const onEdit = (blocks: Block[]) => {
    if (readonly) {
      return
    }

    const previousBlocks = reportStore.getReport()!.sections.find(s => s._id === sectionId)?.blocks || []
    const previousBlocksIds = previousBlocks.map(b => b.id)
    const newBlocksIds = blocks.map(b => b.id)
    reportLockManager.validateBlocksOrderChange(previousBlocksIds, newBlocksIds)
    reportStore.updateSectionBlocks(sectionId, blocks, locale)
  }

  return (
    <div className="report-section" id={sectionId as string} data-id={sectionId} data-section={true}>
      <LazyGradually>
        {baseReport && <SectionDiffToggle loading={loadingDiff} active={showDiff} onClick={onToggleDiff} />}
        <div className={`report-editor ${showDiff ? '' : 'report-editor-activated'}`} ref={editorContainer}>
          {!showDiff && (
            <Editor
              blocks={section.blocks}
              autofocus={autofocus}
              t={t}
              i18n={i18n}
              projectType={project.type}
              projectId={project._id}
              onEdit={onEdit}
              locale={locale}
              mode={EditorMode.EDIT}
              id={`editorjs-${sectionId}`}
              onAddComment={addComment}
              readonly={readonly}
              canComment={hasPermission(Permission.Comment.MANAGE) && hasCommentWriteAccess(user, project!)}
            />
          )}
          {diffHTML && (
            <div>
              <RawHtml>{diffHTML}</RawHtml>
            </div>
          )}
        </div>
      </LazyGradually>
    </div>
  )
}

export default EditableReportSection
