import {Id} from 'domain/Entity'
import {Errors} from 'domain/Errors'
import {GlossaryTerm, GlossaryType, TermTranslation} from 'domain/Glossary'
import {ReactComponent as DoneIcon} from '../../assets/icons/done.svg'
import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'
import {addOrUpdateGlossaryTerm} from '../../api-clients/glossaryTermClient'
import useModal from '../../hooks/useModal'
import useToast from '../../hooks/useToast'
import ButtonWithLoaderAndIcon from '../utils/form/ButtonWithLoaderAndIcon'
import Input from '../utils/form/Input'
import ModalTemplate from '../utils/ModalTemplate'


interface Props {
  type: GlossaryType
  onAdd: () => void
  companyId?: Id
}

const AddGlossaryTerm: React.FC<Props> = ({type, onAdd, companyId}) => {
  const {closeModal} = useModal()
  const {renderSuccessToast} = useToast()
  const {t} = useTranslation()
  const [term, setTerm] = useState<TermTranslation>({ja: '', en: '', comment: '', isPrimary: false})
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState<Errors<GlossaryTerm>>({})

  const onAddNewTerm = () => {
    setLoading(true)
    setErrors({})

    addOrUpdateGlossaryTerm(type, term, companyId)
      .then(() => {
        renderSuccessToast('Term successfully added')
        closeModal()
        onAdd()
      })
      .catch(({errors}) => setErrors(errors))
      .finally(() => setLoading(false))
  }

  const onChange = (field: string, event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault()
    const value = event.target.value
    setTerm({...term, [field]: value})
  }

  return <ModalTemplate title={t('glossary.term.newTerm')}>
    <div className="modal-body">
      <div className="row">
        <div className="mb-3 col-lg-6">
          <label htmlFor="ja" className="form-label small">
            {t('models.GlossaryTerm.ja')}
          </label>

          <Input className="form-control" name="ja" value={term.ja}
                 onChange={e => onChange('ja', e)} error={errors?.ja}/>
        </div>
        <div className="mb-3 col-lg-6">
          <label htmlFor="en" className="form-label small">
            {t('models.GlossaryTerm.en')}
          </label>

          <Input className="form-control" name="en" value={term.en}
                 onChange={e => onChange('en', e)} error={errors?.en}/>
        </div>
      </div>
      <div className="row">
        <div className="mb-3 col-lg-12">
          <label htmlFor="comment" className="form-label small">
            {t('models.GlossaryTerm.comment')}
          </label>

          <Input className="form-control" name="comment" value={term.comment}
                 onChange={e => onChange('comment', e)} error={errors?.comment}/>
        </div>
      </div>
      {companyId &&
        <div className="row">
          <div className="mb-3 col-lg-12">
            <input type="checkbox" id="isPrimary" name="type" className="form-check-input me-2"
                   checked={term.isPrimary}
                   onChange={() => setTerm({...term, isPrimary: !term.isPrimary})}/>
            <label htmlFor="isPrimary" className="form-label small">
              {t('models.GlossaryTerm.isPrimary')}
            </label>
          </div>
        </div>}
    </div>
    <div className="modal-footer justify-content-end">
      <ButtonWithLoaderAndIcon loading={loading} icon={<DoneIcon className="icon" />} onClick={onAddNewTerm} className="btn btn-success">
        <span>{t('glossary.term.addTerm')}</span>
      </ButtonWithLoaderAndIcon>
    </div>
  </ModalTemplate>
}

export default AddGlossaryTerm