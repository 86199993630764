import {Id} from 'domain/Entity'
import {Locale} from 'domain/Locale'
import {TranslationRequestStatus} from 'domain/TranslationRequest'
import {ExternalTranslator, hasRoles, Role, Translator} from 'domain/User'
import {ReactComponent as ArrowBackIcon} from '../../../assets/icons/arrow_back.svg'
import {ReactComponent as ArrowForwardIcon} from '../../../assets/icons/arrow_forward.svg'
import moment from 'moment'
import React, {ReactElement, useEffect, useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {getActiveProjects} from '../../../api-clients/projectClient'
import {getTranslators} from '../../../api-clients/userClient'
import {datesForWeek, weekNumber} from '../../../utils/dateUtils'
import FormattedDate from '../../utils/FormattedDate'
import {groupedProjectsByTranslatorIdAndDeadline} from './projectsByTranslator'
import {ProjectAggregate} from 'domain/ProjectAggregate'

interface TranslatorAssignmentCalendarProps {
  project: ProjectAggregate
  setSelectedTranslator: (translator: Translator) => void
}

const TranslatorAssignmentCalendar: React.FC<TranslatorAssignmentCalendarProps> = (
  {project, setSelectedTranslator}): ReactElement => {

  const [translators, setTranslators] = useState<Translator[]>([])
  const [projects, setProjects] = useState<ProjectAggregate[]>([])
  const [dateFromSelectedWeek, setDateFromSelectedWeek] = useState<Date>(project.expectedDeadline.en)
  const {t} = useTranslation()
  const projectsByTranslator = useMemo(() => groupedProjectsByTranslatorIdAndDeadline(projects), [projects])
  const lastTranslationRequest = project.translationRequests.last()

  const isDeadline = (date: Date) => {
    return date.getTime() === new Date(project.expectedDeadline[Locale.EN]).getTime()
  }

  useEffect(() => {
    getTranslators().then(translators => {
      setTranslators(translators.sortBy(translator => translator.tier))
    })
    getActiveProjects().then(setProjects)
  }, [])

  const showPreviousWeek = (e: React.MouseEvent) => {
    e.preventDefault()
    setDateFromSelectedWeek(date => moment.utc(date).add(-1, 'week').toDate())
  }

  const showNextWeek = (e: React.MouseEvent) => {
    e.preventDefault()
    setDateFromSelectedWeek(date => moment.utc(date).add(1, 'week').toDate())
  }

  const isLastRejected = (translatorId: Id): boolean => {
    if (!lastTranslationRequest) {
      return false
    }
    return lastTranslationRequest.status === TranslationRequestStatus.REJECTED
      && translatorId === lastTranslationRequest.translatorId
  }

  const displayableDates = useMemo(() => datesForWeek(dateFromSelectedWeek), [dateFromSelectedWeek])
  return <div className="pane flex-grow-1">
    <div className="pane-header">
      <div className="pane-title">{t('components.TranslatorAssignmentCalendar.title')}</div>
      <div className="pane-header-actions mx-auto">
        <button className="btn btn-xs" onClick={showPreviousWeek}>
          <ArrowBackIcon className="icon-sm"/>
        </button>
        <span className="mx-3 small font-weight-bold">
          {t('components.TranslatorAssignmentCalendar.week')} {weekNumber(dateFromSelectedWeek)}
        </span>
        <button className="btn btn-xs" onClick={showNextWeek}>
          <ArrowForwardIcon className="icon-sm"/>
        </button>
      </div>
    </div>
    <div className="pane-body">
      <table className="table table-sm table-hover mb-0 border-bottom small">
        <thead>
        <tr>
          <th className="text-muted"/>
          <th className="text-muted-more small align-bottom">
            {t('components.TranslatorAssignmentCalendar.tier')}
          </th>
          <th className="text-muted-more small align-bottom">
            {t('components.TranslatorAssignmentCalendar.rates')}
          </th>
          {displayableDates.map(dateOfWeek =>
            <th key={dateOfWeek.getTime()}
                className={`align-bottom text-center small font-weight-bold position-relative ${isDeadline(dateOfWeek) ? 'cell-deadline' : ''}`}>
              {isDeadline(dateOfWeek) && (
                <div className="badge bg-danger position-absolute top-0 start-100 translate-middle">
                  {t('components.TranslatorAssignmentCalendar.deadline')}
                </div>)
              }
              <FormattedDate date={dateOfWeek} format={'dd D'}/>
            </th>)}
        </tr>
        </thead>
        <tbody>
        {translators.map(translator => (
          <tr key={translator._id as string}>
            <th className="w-25">
              <div className="form-check form-check-inline mb-0">
                <input type="radio" id={`translator-${translator._id}`} name="assignedTranslator"
                       className="form-check-input"
                       value={translator._id as string} onChange={() => {
                  setSelectedTranslator(translator)
                }}/>
                <label className="form-check-label" htmlFor={`translator-${translator._id}`}>
                  {translator.name} {hasRoles(translator, Role.EXTERNAL_TRANSLATOR) && (
                  <span className="badge rounded-pill bg-info ms-1"
                        title={t('models.Role.EXTERNAL_TRANSLATOR')}
                  >ext</span>
                )}
                </label>
                {isLastRejected(translator._id) && (
                  <span className="badge rounded-pill bg-warning ms-1"
                        title={t('components.TranslatorAssignmentCalendar.lastRejectedNote')}
                  >{t('components.TranslatorAssignmentCalendar.lastRejected')}</span>
                )}
              </div>
            </th>
            <td className="small text-center" style={{width: '1%'}}>
              {translator.tier || '-'}
            </td>
            <td className="small text-center" style={{width: '1%'}}>
              {hasRoles(translator, Role.EXTERNAL_TRANSLATOR) && (translator as ExternalTranslator).ratePerCharacter}
            </td>
            {displayableDates.map(dateOfWeek => {
              const groupedProjects = projectsByTranslator[translator._id as string]?.[dateOfWeek.toISOString()] || []

              return <td key={`${translator._id}-${dateOfWeek.valueOf()}`}
                         className={`border-left text-center align-middle ${isDeadline(dateOfWeek) ? 'cell-deadline' : ''}`}
                         style={{width: '75px'}}>
                {groupedProjects.map(({type, size}) => (
                  <div className="badge bg-warning text-dark mx-1">
                    {t(`models.ProjectType.${type}`)} {size > 1 ? `X ${size}` : ''}
                  </div>
                ))}
              </td>
            })}
          </tr>
        ))}
        </tbody>
      </table>
    </div>
  </div>
}
export default TranslatorAssignmentCalendar
