import {Holiday} from 'domain/Holiday'
import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'
import {createHoliday} from '../../api-clients/holidayClient'
import useModal from '../../hooks/useModal'
import Input from '../utils/form/Input'
import ModalTemplate from '../utils/ModalTemplate'


type Props = {
  onAdded: (holiday: Holiday) => void
}

const AddHoliday: React.FC<Props> = ({onAdded}) => {
  const [date, setDate] = useState('')
  const {closeLastModal} = useModal()
  const {t} = useTranslation()
  const [loading, setLoading] = useState(false)

  const onSubmit = () => {
    setLoading(true)
    createHoliday(date).then(holiday => {
      onAdded(holiday)
      closeLastModal()
    }).finally(() => {
      setLoading(false)
    })
  }

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {value} = e.target
    setDate(value)
  }

  return <ModalTemplate title={t('components.AddHoliday.title')}>
    <form>
      <div className="modal-body">
        <label htmlFor="holiday-date" className="form-label small">{t('models.Holiday.date')}</label>
        <Input type="date" className="form-control me-3" id="holiday-date" name="holiday-date"
               required={true} value={date} onChange={onChange}/>
      </div>
      <div className="modal-footer justify-content-end">
        <button className="btn btn-primary" type="submit" onClick={onSubmit}
                disabled={!date || loading}>
          {t('components.AddHoliday.add')}
        </button>
      </div>
    </form>
  </ModalTemplate>
}

export default AddHoliday