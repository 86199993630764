import {Locale} from 'domain/Locale'
import React, {useEffect, useRef, useState} from 'react'
import {useTranslation} from 'react-i18next'
import EventBus, {EventType} from '../../EventBus'
import {SectionMergeResult} from './merge/ReportSectionsMerger'


interface MergeConflictsCountProps {
  mergeResult: SectionMergeResult[]
  locale: Locale
}
const MergeConflictsCount: React.FC<MergeConflictsCountProps> = props => {
  const {mergeResult, locale} = props

  const {t} = useTranslation()
  const localeRef = useRef(locale)

  const conflictingBlocksCount = () => {
    const locale = localeRef.current
    return mergeResult.flatMap(s => s.mergedBlocks.filter(b => b.isConflict(locale))).length
  }
  const [conflictCount, setConflictCount] = useState<number>(conflictingBlocksCount())

  const countConflicts = () => setConflictCount(conflictingBlocksCount())

  useEffect(() => {
    EventBus.on(EventType.MERGE_CONFLICT_RESOLVED, countConflicts)
    return () => EventBus.unsubscribe(EventType.MERGE_CONFLICT_RESOLVED, countConflicts)
  }, [])

  useEffect(() => {
    localeRef.current = locale
    countConflicts()
  }, [locale])

  return conflictCount ?
    <div className="d-flex align-items-center bg-soft-warning rounded py-1 px-3">
      <div className="h5 mb-0 me-2">{conflictCount}</div>
      <div className="text-muted text-pre-wrap">
        {t('components.MergeConflictsCount.count', {count: conflictCount})}
      </div>
    </div> : <></>
}

export default MergeConflictsCount