import {Locale} from 'domain/Locale'
import {Block, GridBlock} from 'domain/Report'
import {initBlock} from 'domain/utils/transformBlocks'
import {v4} from 'uuid'


export const applyGridStructureFromSourceLocale = (block: GridBlock, sourceLocale: Locale, targetLocale: Locale) => {
  return {
    ...block,
    grid: {
      ...block.grid,
      [targetLocale]: block.grid[sourceLocale]?.map(gridRowContent => {
        return gridRowContent.map(cell => {
          return {
            ...cell,
            id: v4(),
            blocks: initBlocks(cell.blocks, sourceLocale, targetLocale)
          }
        })
      })
    }
  }
}

const initBlocks = (blocks: Block[], sourceLocale: Locale, targetLocale: Locale) => {
  return blocks.map(block => initBlock(block, sourceLocale, targetLocale))
}
