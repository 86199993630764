import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import EventBus, {EventType} from '../../../EventBus'
import DiffDeletionsToggle from './DiffDeletionsToggle'


type Props = {
  showEnglishDiffToggle: boolean
}

const DeletionToggles: React.FC<Props> = props => {
  const {showEnglishDiffToggle} = props
  const {t} = useTranslation()
  const [showEnglish, setShowEnglish] = useState(false)
  const [showToggles, setShowToggles] = useState(false)

  useEffect(() => {
    EventBus.on(EventType.HTML_DIFF_UPDATED, onHtmlDiffComputed)

    return () => EventBus.unsubscribe(EventType.HTML_DIFF_UPDATED, onHtmlDiffComputed)
  }, [])

  const onHtmlDiffComputed = () => {
    setShowToggles(true)
  }

  const onDiffToggle = () => {
    const newState = !showEnglish
    setShowEnglish(newState)
    EventBus.emit(EventType.TOGGLE_SHOW_JA_EN_DIFF, {newState})
  }

  if (!showToggles) return <></>

  return <div className="report-view-toolbar mb-3">
    <DiffDeletionsToggle/>

    {showEnglishDiffToggle && <div className="form-check form-switch mb-0 ms-2">
      <input id="deletionVisibility" type="checkbox" className="form-check-input"
             checked={showEnglish} onChange={onDiffToggle}/>
      <label className="form-check-label text-muted text-nowrap" htmlFor="deletionVisibility">
        {t('components.TranslateReportPage.showTranslationsToggle')}
      </label>
    </div>}
  </div>
}

export default DeletionToggles