import {Locale} from 'domain/Locale'
import {Block, BlockType, CloneBlock, ReportState} from 'domain/Report'
import React from 'react'
import memoizeComponent from '../../../utils/memoizeComponent'
import Alert from './Alert'
import Bookmark from './Bookmark'
import CommonTable from './CommonTable'
import Grid from './Grid'
import Heading from './Heading'
import Image from './Image'
import List from './List'
import Paragraph from './Paragraph'
import Table from './Table'

interface ViewProps {
  blocks: Block[]
  reportState?: ReportState
  locale: Locale
  displayBookmarks?: boolean
}

const ReadonlyBlocks: React.FC<ViewProps> = props => {
  const {blocks, locale, displayBookmarks = false, reportState} = props

  const blockComponent = (blockToRender: Block) => {
    let block = blockToRender
    if (block.type === BlockType.CLONE) {
      if (!reportState) return <></>
      const sourceBlock = reportState.sections
        .flatMap(section => section.blocks)
        .find(b => b.id === (block as CloneBlock).sourceId)
      if (!sourceBlock) return <></>
      block = {...sourceBlock, id: block.id}
    }

    switch (block.type) {
      case BlockType.HEADING:
        return <Heading block={block} locale={locale} />
      case BlockType.LIST:
        return <List block={block} locale={locale} />
      case BlockType.EXCEL_TABLE:
        return <Table block={block} locale={locale} />
      case BlockType.IMAGE:
        return <Image block={block} locale={locale} />
      case BlockType.ALERT:
        return <Alert block={block} locale={locale} />
      case BlockType.BOOKMARK:
        return displayBookmarks ? <Bookmark block={block} /> : <></>
      case BlockType.CLONE:
        return <></>
      case BlockType.GRID:
        return <Grid block={block} locale={locale} />
      case BlockType.COMMON_TABLE:
        return <CommonTable block={block} locale={locale} />
      default:
        return <Paragraph block={block} locale={locale} />
    }
  }

  return (
    <>
      {blocks.map(block => {
        return (
          <div id={block.id} key={block.id} className="report-content-block" data-block={block.type}>
            {blockComponent(block)}
          </div>
        )
      })}
    </>
  )
}

export default memoizeComponent(ReadonlyBlocks)
