import {Locale} from 'domain/Locale'
import {HeadingBlock} from 'domain/Report'
import React, {ReactElement} from 'react'
import RawHtml from '../../utils/RawHtml'


interface HeadingProps {
  block: HeadingBlock,
  locale: Locale
}

const Heading: React.FC<HeadingProps> = ({block, locale}): ReactElement => {
  return React.createElement(`h${block.level}`, {id: `title-${block.id}`},
    <RawHtml>{block.text[locale]?.value || ''}</RawHtml>)
}

export default Heading
