import React, {ReactElement, useEffect, useRef, useState} from 'react'
import {useTranslation} from 'react-i18next'
import RawHtml from '../../utils/RawHtml'

type ContentProps = React.DetailedHTMLProps<React.BaseHTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
  content: string
}

const Content: React.FC<ContentProps> = ({content, ...divProps}): ReactElement => {
  const [collapsed, setCollapsed] = useState(false)
  const [collapsable, setCollapsable] = useState(false)
  const contentRef = useRef<HTMLDivElement>(null)
  const {t} = useTranslation()

  useEffect(() => {
    if (!(contentRef.current && contentRef.current.clientHeight > 150)) {
      return
    }

    setCollapsed(true)
    setCollapsable(true)
  }, [])

  const toggleCollapsed = (event: React.MouseEvent) => {
    event.preventDefault()
    event.stopPropagation()
    setCollapsed(collapsed => !collapsed)
  }

  return (
    <>
      <div {...divProps} ref={contentRef} className={`comment-content small ${collapsed ? 'comment-collapsed' : ''}`}>
        <RawHtml>{content}</RawHtml>
      </div>
      {collapsable && (
        <div className="small">
          <a href="" className="text-muted" onClick={toggleCollapsed}>
            <span>
              {collapsed ? t('components.Report.comments.Content.more') : t('components.Report.comments.Content.less')}
            </span>
          </a>
        </div>
      )}
    </>
  )
}

export default Content
