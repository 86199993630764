import {Entity} from 'domain/Entity'


export enum JobStatus {
  PENDING = 'PENDING',
  SUCCEEDED = 'SUCCEEDED',
  FAILED = 'FAILED'
}

export interface Job extends Entity {
  status: JobStatus
  error?: string
  data?: any
}

export interface PDFFileJob extends Job {
  data: {
    filepath: string
  }
}