import {Block} from 'domain/Report'

export const sanitizeMessage = (html: string): string => {
  return html.replace(/(<\/?(?:b|u|i|ul|ol|li|div)[\s]*>)|<[^>]+>/gi, '$1')
}

export const sanitizeCommentsAndMarkers = (block: Block): Block => {
  let result = removeCommentsFromText(JSON.stringify(block))
  result = removeMarksFromText(result)
  return JSON.parse(result)
}

export const sanitizeAllCommentsFromHTML = (reportHTML: string) => {
  return removeCommentsFromText(removeStickyCommentsFromText(reportHTML))
}

export const sanitizeAllCommentsAndMarks = (content: string) => {
  let result = removeStickyCommentsFromText(content)
  result = removeCommentsFromText(result)
  result = removeMarksFromText(result)
  return result
}

const removeCommentsFromText = (text: string) => {
  return text.replace(/<comment[^>]*>/gi, '').replace(/<\/comment[^>]*>/gi, '')
}

const removeStickyCommentsFromText = (text: string) => {
  return text.replace(/<sticky-comment[^>]*>/gi, '').replace(/<\/sticky-comment[^>]*>/gi, '')
}

const removeMarksFromText = (text: string) => {
  return text.replace(/<mark[^>]*>/gi, '').replace(/<\/mark[^>]*>/gi, '')
}