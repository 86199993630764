import {Company} from 'domain/Company'
import {Permission} from 'domain/Permission'
import React, {PropsWithChildren, useContext, useEffect, useState} from 'react'
import {getCompany} from '../api-clients/companyClient'
import {getProject} from '../api-clients/projectClient'
import ReportStore from '../components/report/ReportStore'
import {UserContext} from './UserProvider'
import {ProjectAggregate} from 'domain/ProjectAggregate'
import {Locale} from 'domain/Locale'
import {Id} from 'domain/Entity'

export interface ReportProviderState {
  company: Company | null
  project: ProjectAggregate
  setEnglishReportTitle: (title: string) => void
  setWordCountExcludedBlockIds: (blockIds: string[]) => void
  setTranslateFromRevisionId: (id: Id) => void
  reportStore: ReportStore
  visibleSectionId: string | null
  setVisibleSectionId: (id: string | null) => void
}

export const ReportContext = React.createContext<ReportProviderState>({} as ReportProviderState)

interface Props {
  projectId: string
}

const ReportProvider: React.FC<PropsWithChildren<Props>> = ({children, projectId}) => {
  const [reportStore, setReportStore] = useState<ReportStore>()
  const [company, setCompany] = useState<Company | null>(null)
  const [project, setProject] = useState<ProjectAggregate>()
  const [visibleSectionId, setVisibleSectionId] = useState<string | null>(null)
  const {hasPermission} = useContext(UserContext)

  useEffect(() => {
    initContext()
  }, [projectId])

  const initContext = async () => {
    const project = await getProject(projectId)
    setProject(project)
    setReportStore(new ReportStore(project._id.toString()))
    setVisibleSectionId(null)

    if (hasPermission(Permission.Company.READ)) {
      const company = await getCompany(project.companyId)
      setCompany(company)
    }
  }

  if (!project) {
    return null
  }

  if (!reportStore) {
    return null
  }

  const setEnglishReportTitle = (title: string) =>
    setProject({...project, reportTitle: {...project.reportTitle, [Locale.EN]: title}})

  const setWordCountExcludedBlockIds = (blockIds: string[]) =>
    setProject({...project, wordCountExcludedBlockIds: blockIds})

  const setTranslateFromRevisionId = (id: Id) => setProject({...project, translateFromRevisionId: id})

  const providerState = {
    company,
    project,
    setEnglishReportTitle,
    setWordCountExcludedBlockIds,
    setTranslateFromRevisionId,
    reportStore,
    visibleSectionId,
    setVisibleSectionId
  }

  return <ReportContext.Provider value={providerState}>{children}</ReportContext.Provider>
}

export default ReportProvider
