import {GlossaryAlternativeTerm} from 'domain/Glossary'
import {Locale} from 'domain/Locale'
import {ReactComponent as GlobalIcon} from '../../assets/icons/globe.svg'
import React from 'react'
import {OverlayTrigger, Popover} from 'react-bootstrap'
import {useTranslation} from 'react-i18next'

interface GlobalTranslationsProps {
  alternatives: GlossaryAlternativeTerm[]
  companyTicks: {}
}

const AlternativeTranslations: React.FC<GlobalTranslationsProps> = ({alternatives, companyTicks}) => {
  const {t} = useTranslation()

  const translationPopover = (
    <Popover id="translation-popover">
      <Popover.Header as="div">{t('glossary.alternativeTranslations.title')}</Popover.Header>

      <Popover.Body>
        <ul>
          {alternatives.map((term, idx) => (
            <li key={idx}>
              {term[Locale.EN]}
              {term.companyId && companyTicks[term.companyId] ? (
                <div className="ms-2 badge bg-primary">{companyTicks[term.companyId]}</div>
              ) : (
                <div className="ms-2 badge bg-info">{t('glossary.alternativeTranslations.global')}</div>
              )}
            </li>
          ))}
        </ul>
      </Popover.Body>
    </Popover>
  )

  return alternatives.length > 0 ? (
    <OverlayTrigger overlay={translationPopover} rootClose placement="right" trigger="click">
      <span>
        <GlobalIcon className="icon" />
      </span>
    </OverlayTrigger>
  ) : (
    <></>
  )
}

export default AlternativeTranslations
