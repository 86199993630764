import {API, BaseTool} from '@editorjs/editorjs'
import Tunes from './Tunes'


export interface CloneData {
  sourceId: string
}

export const clonedBlockClass = 'report-block-clone'

export default class Clone implements BaseTool {
  private readonly api: API
  private readonly wrapper: HTMLDivElement
  private readonly data: CloneData
  private source?: HTMLElement
  tunes: Tunes

  static get isReadOnlySupported() {
    return true
  }

  get CSS() {
    return {
      wrapper: clonedBlockClass
    }
  }

  constructor({data, api}: { data?: CloneData, config?: {}, api: API }) {
    this.api = api
    this.data = data!

    this.source = this.findSourceElement()

    this.wrapper = document.createElement('div')
    this.wrapper.className = this.CSS.wrapper

    this.tunes = new Tunes(
      this.api
    )
  }

  renderSettings(): HTMLElement {
    return this.tunes.render(this.data)
  }

  render() {
    this.copyChildrenFromSource()

    setTimeout(() => this.rebuildChildren(), 1000)

    this.wrapper.dataset.sourceId = this.data.sourceId
    return this.wrapper
  }

  save(): CloneData {
    return {
      sourceId: this.data.sourceId
    }
  }

  private rebuildChildren(retryCount = 1) {
    const source = this.findSourceElement()

    if (!source) {
      if (retryCount < 20) {
        setTimeout(() => this.rebuildChildren(retryCount + 1), 1000)
      }
    } else {
      this.source = source
      this.copyChildrenFromSource()
    }
  }

  private sourceChildren(): Node[] {
    if (!this.source) return []
    const sourceContent = this.source.querySelector('.ce-block__content')!.cloneNode(true)
    Array.from((sourceContent as HTMLElement).querySelectorAll('[contenteditable="true"]'))
      .forEach(e => (e as HTMLElement).contentEditable = 'false')
    return Array.from(sourceContent.childNodes)
  }

  private findSourceElement() {
    const sourceElement = document.getElementById(`${this.data.sourceId}`)
    if (sourceElement) return sourceElement
  }

  private copyChildrenFromSource() {
    while (this.wrapper.childNodes.length) {
      this.wrapper.removeChild(this.wrapper.childNodes[0])
    }
    this.sourceChildren().forEach(c => this.wrapper.appendChild(c))
  }
}
