import {Company} from 'domain/Company'
import {Id} from 'domain/Entity'
import {GlossaryType, TermTranslation} from 'domain/Glossary'
import React, {ReactNode, useContext, useState} from 'react'
import {addOrUpdateGlossaryTerm} from '../api-clients/glossaryTermClient'
import {ReportContext} from './ReportProvider'


interface TranslatePageState {
  companies: Company[]
  setCompanies: (companies: Company[]) => void
  showTranslationSidebar: boolean
  setShowTranslationSidebar: (value: boolean) => void
  addGlossaryTerm: (term: TermTranslation, alternativeTermCompanyId?: Id) => void
}

export const TranslatePageContext = React.createContext({} as TranslatePageState)

const TranslatePageProvider = ({children}: { children: ReactNode }) => {
  const {company} = useContext(ReportContext)
  const [companies, setCompanies] = useState<Company[]>([])
  const [showTranslationSidebar, setShowTranslationSidebar] = useState<boolean>(true)

  const addGlossaryTerm = async (term: TermTranslation, alternativeTermCompanyId?: Id) => {
    if (alternativeTermCompanyId && alternativeTermCompanyId !== company?._id) {
      await addOrUpdateGlossaryTerm(GlossaryType.TABLE, term, company?._id)
    }
  }

  const state = {
    companies, setCompanies,
    showTranslationSidebar,
    setShowTranslationSidebar,
    addGlossaryTerm
  }

  return (
    <TranslatePageContext.Provider value={state}>
      {children}
    </TranslatePageContext.Provider>
  )
}

export default TranslatePageProvider