import {originalReportTimelines, ProjectStage} from 'domain/Project'
import {TranslationRequestStatus} from 'domain/TranslationRequest'
import {ProjectAggregate} from 'domain/ProjectAggregate'


export const unassignedProjects = (projects: ProjectAggregate[]): ProjectAggregate[] => {
  return projects.filter(project => {
    const lastTranslationRequest = project.translationRequests.last()
    return !project.translatorId && (
      !lastTranslationRequest || lastTranslationRequest.status === TranslationRequestStatus.REJECTED
    )
  })
}

export const pendingTranslationConfirmationProjects = (projects: ProjectAggregate[]): ProjectAggregate[] => {
  return projects.filter(project => {
    const lastTranslationRequest = project.translationRequests.last()

    return !project.translatorId && (
      lastTranslationRequest && lastTranslationRequest.status === TranslationRequestStatus.PENDING
    )
  })
}

export const waitingOriginalProjects = (projects: ProjectAggregate[]): ProjectAggregate[] => {
  return projects.filter(project => {
    if (!project.translatorId) return false
    return project.activeTask && originalReportTimelines.includes(project.activeTask.timeline)
  })
}

export const inTranslationProjects = (projects: ProjectAggregate[]): ProjectAggregate[] => {
  return projects.filter(project => {
    return project.activeTask?.stage === ProjectStage.TRANSLATION_WRITING
  })
}

export const inTranslationReviewProjects = (projects: ProjectAggregate[]): ProjectAggregate[] => {
  return projects.filter(project => {
    return project.activeTask?.stage === ProjectStage.TRANSLATION_REVIEW
  })
}

export const inTranslationCompanyReviewProjects = (projects: ProjectAggregate[]): ProjectAggregate[] => {
  return projects.filter(project => {
    return project.activeTask?.stage === ProjectStage.TRANSLATION_COMPANY_CHECK
  })
}