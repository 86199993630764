import {Project, ProjectTimeline} from 'domain/Project'
import {getStageRule} from 'domain/project-stages/projectStageHelpers'
import {ProjectStageRule} from 'domain/ProjectStageFlow'
import {ProjectTask} from 'domain/Task'
import {TranslationRequestStatus} from 'domain/TranslationRequest'
import {hasRoles, Role, RequestUser} from 'domain/User'
import {ProjectAggregate} from 'domain/ProjectAggregate'

export const hasProjectReadAccess = (user: RequestUser, project: ProjectAggregate): boolean => {
  const {companyId, roles, _id: userId} = user

  if (companyId && (companyId !== project.companyId.toString() || !project.activeTask)) {
    return false
  }

  const lastTranslationRequest = project.translationRequests.last()
  const isAssignedTranslator = project.translatorId && userId === project.translatorId.toString()

  const isAssignedToLastTranslationRequest = lastTranslationRequest &&
    lastTranslationRequest.status === TranslationRequestStatus.PENDING &&
    userId === lastTranslationRequest.translatorId.toString()

  if (hasRoles(roles, Role.EXTERNAL_TRANSLATOR)) {
    if (!isAssignedTranslator && !isAssignedToLastTranslationRequest) {
      return false
    }
  }

  return true
}

export const hasProjectAccess = (user: RequestUser, project: ProjectAggregate): boolean => {
  const {companyId, roles, _id: userId} = user

  if (companyId && companyId !== project.companyId.toString()) {
    return false
  }

  return roles.includes(Role.EXTERNAL_TRANSLATOR) ?
    (!!project.translatorId && userId === project.translatorId.toString()) :
    true
}

export const hasProjectAdminAccess = (user: RequestUser, project: Project): boolean => {
  return hasRoles(user.roles, Role.ADMIN) || (!!project.analystId && user._id === project.analystId.toString()) ||
    (project.assistantAnalystIds || []).some(id => user._id === id.toString())
}

export const hasTaskAccess = (user: RequestUser,
                              project: Pick<Project, 'type' | 'analystId' | 'assistantAnalystIds'>,
                              task: ProjectTask): boolean => {
  if (user._id === task.userId?.toString()) return true
  if (hasRoles(user, Role.ADMIN)) return true
  if (hasRoles(user, Role.ANALYST) && user._id === project.analystId?.toString()) return true
  if (hasRoles(user, Role.ANALYST) && (project.assistantAnalystIds || []).map(id => id.toString()).includes(user._id)) {
    return true
  }
  if (task.timeline === ProjectTimeline.TRANSLATED_REPORT && hasRoles(user, Role.TRANSLATOR_COORDINATOR)) return true

  const currentStageRule = getStageRule(project, task.stage)!
  const {isProjectAnalystStage} = currentStageRule

  return !isProjectAnalystStage &&
    hasRoles(user.roles, Role.ANALYST) &&
    userMatchesStageRole(user, currentStageRule)
}

export const hasCommentWriteAccess = (user: RequestUser, project: ProjectAggregate): boolean => {
  const srEmployeeRoles = [Role.ADMIN, Role.ANALYST, Role.EDITOR, Role.TRANSLATOR_COORDINATOR, Role.INTERNAL_TRANSLATOR]
  return hasRoles(user, srEmployeeRoles) || !!project.activeTask
}

const userMatchesStageRole = (user: RequestUser, stageRule: ProjectStageRule): boolean => {
  const {roles} = stageRule
  if (hasRoles(roles, Role.TRANSLATOR_COORDINATOR)) return hasRoles(user, Role.TRANSLATOR_COORDINATOR)
  if (hasRoles(roles, Role.ANALYST)) return hasRoles(user, Role.ANALYST)

  return true
}