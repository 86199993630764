import React, {CSSProperties} from 'react'


type Props = {
  titleText: string
}

const EmailTitle: React.FC<Props> = ({titleText}) => {
  const css = {
    color: '#3a71a8',
    marginTop: 0,
    marginBottom: '16px',
    fontSize: '26px',
    lineHeight: '32px',
    fontWeight: 'normal'
  } as CSSProperties

  return <h1 style={css}>
    {titleText}
  </h1>
}

export default EmailTitle