import {ProblemType, ProjectProblem} from 'domain/ProjectProblem'
import {ReactComponent as DoneIcon} from '../../../assets/icons/done.svg'
import React, {useContext, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {createProjectProblem} from '../../../api-clients/projectClient'
import useModal from '../../../hooks/useModal'
import useToast from '../../../hooks/useToast'
import {LocaleContext} from '../../../providers/LocaleProvider'
import Textarea from '../../utils/form/Textarea'
import ModalTemplate from '../../utils/ModalTemplate'
import {ProjectAggregate} from 'domain/ProjectAggregate'

type Props = {
  project: ProjectAggregate
}

const ProjectProblemModal: React.FC<Props> = ({project}) => {
  const {closeModal} = useModal()
  const {t} = useTranslation()
  const [message, setMessage] = useState('')
  const [defaultMessage, setDefaultMessage] = useState('')
  const [loading, setLoading] = useState(false)
  const {renderSuccessToast, renderErrorToast} = useToast()
  const [problemType, setProblemType] = useState<ProblemType>(ProblemType.TECHNICAL)
  const {locale} = useContext(LocaleContext)

  useEffect(() => {
    const companyTitle = project.company.name?.[locale] || ''
    const message = t('components.ProjectProblemModal.messageIntro', {project: project.title, company: companyTitle})
    setDefaultMessage(message)
    setMessage(message)
  }, [locale])

  const onSendProblem = () => {
    const problem: ProjectProblem = {
      link: window.location.href,
      problemType,
      message
    }

    setLoading(true)
    createProjectProblem(project._id, problem)
      .then(() => {
        renderSuccessToast(t('components.ProjectProblemModal.success'))
      })
      .catch(() => {
        renderErrorToast(t('components.ProjectProblemModal.error'))
      }).finally(() => {
      closeModal()
      setLoading(false)
    })
  }

  const onProblemTypeChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    setProblemType(e.target.value as ProblemType)
  }

  const isValidMessage = () => {
    return message !== defaultMessage && message.length > 0
  }

  return <ModalTemplate size="lg" title={t('components.ProjectProblemModal.title')}>
    <form onSubmit={onSendProblem}>
      <div className="modal-body">
        <div className="mb-2">{t('components.ProjectProblemModal.typeDescription')}</div>
        {Object.values(ProblemType).map(problemOption => {
          return <div className="form-check">
            <input type="radio" id={problemOption} name="type" className="form-check-input" value={problemOption}
                   checked={problemOption === problemType} onChange={onProblemTypeChanged}/>
            <label htmlFor={problemOption}>{t(`components.ReportProblemModal.problems.${problemOption}`)}</label>
          </div>
        })}


        <div className="mt-3">{t('components.ProjectProblemModal.description')}</div>
        <Textarea rows={6} className="form-control" value={message} onChange={e => setMessage(e.target.value)}/>
        <div className="text-muted small">{t('components.ProjectProblemModal.linkHelper')}</div>
      </div>
      <div className="modal-footer">
        <button className="btn btn-success btn-with-icon" type="submit"
                disabled={loading || !isValidMessage()}>
          {loading && <div className="spinner-border text-muted-more spinner-border-sm me-2"/>}
          <span>{t('components.ProjectProblemModal.sendEmail')}</span>
          <DoneIcon className="icon" />
        </button>
      </div>
    </form>
  </ModalTemplate>
}

export default ProjectProblemModal