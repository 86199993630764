import {Locale} from 'domain/Locale'
import {TranslationRequest} from 'domain/TranslationRequest'
import {User} from 'domain/User'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {makeLink} from '../../../../api/src/utils/linkUtils'
import { formatDate } from '../../utils/momentUtils'
import EmailButton from './EmailButton'
import EmailTitle from './EmailTitle'
import {ProjectAggregate} from 'domain/ProjectAggregate'


type Props = {
  coordinator: User
  project: ProjectAggregate
  translator: User
  translationRequest: TranslationRequest
}

const TranslationRequestCancelledNotification: React.FC<Props> = props => {
  const {coordinator, project, translator, translationRequest} = props
  const {t} = useTranslation()
  const deadline = project.deadline[Locale.EN]

  return <>
    <EmailTitle titleText={t('emails.translationRequestCancelledNotification.bodyTitle')}/>

    <div>
            <span dangerouslySetInnerHTML={{
              __html: t('emails.translationRequestCancelledNotification.message', {
                translator: translator.name,
                coordinator: coordinator.name,
                title: project.title,
                type: t('models.ProjectType.' + project.type),
                deadline: deadline ? formatDate(deadline, t('dateFormat')) : 'N/A',
              })
            }}/>

      <blockquote>{translationRequest.cancelReply}</blockquote>

      <p style={{margin: '25px 0 16px'}}>
        <EmailButton buttonLink={makeLink(`/projects/${project._id}`)}
                     buttonText={t('emails.translationRequestCancelledNotification.goToProject')}/>
      </p>
    </div>
  </>
}

export default TranslationRequestCancelledNotification