import {AggregatedCompany, COMPANY_FILE_HEADERS, CompanyRow} from 'domain/Company'
import {Locale} from 'domain/Locale'
import {ReactComponent as InsertDriveFile} from '../../assets/icons/insert_drive_file.svg'
import {ReactComponent as UploadFileIcon} from '../../assets/icons/upload_file.svg'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {createCompanies} from '../../api-clients/companyClient'
import useModal from '../../hooks/useModal'
import useToast from '../../hooks/useToast'
import ConfirmationModal from '../utils/ConfirmationModal'
import ButtonWithLoaderAndIcon from '../utils/form/ButtonWithLoaderAndIcon'
import ModalTemplate from '../utils/ModalTemplate'


type Props = {
  companies: CompanyRow[],
  onPickAnotherFile: () => void,
  onUploaded: () => void
}

const MAX_PREVIEW_ROWS = 20
const UploadCompaniesPreview: React.FC<Props> = ({companies, onPickAnotherFile, onUploaded}) => {
  const {t} = useTranslation()
  const uploadFileIcon = <UploadFileIcon className="icon icon-sm"/>
  const insertDriveIcon = <InsertDriveFile className="icon icon-sm"/>
  const {showModal} = useModal()
  const {renderSuccessToast, renderErrorToast} = useToast()
  const onSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    showModal(<ConfirmationModal title={t('components.UploadCompaniesPreview.confirmation.title')}
                                 body={t('components.UploadCompaniesPreview.confirmation.body')}
                                 onConfirm={uploadCompanies}/>)
  }

  const uploadCompanies = (): Promise<AggregatedCompany[] | void> => {
    return createCompanies(companies)
      .then(() => {
        renderSuccessToast(t('components.UploadCompaniesPreview.success'))
        onUploaded()
      })
      .catch(({errors}) => {
        const errorMessage = !!errors.duplicatedTick
          ? t('components.UploadCompaniesPreview.errors.duplicate', {tick: errors.duplicatedTick})
          : t('components.UploadCompaniesPreview.errors.main')
        renderErrorToast(errorMessage)
      })
  }

  const getHeaderTranslation = (column: string) => {
    const lastTwoCharacters = column.slice(-2)
    if (Object.keys(Locale).includes(lastTwoCharacters)) {
      const columnWithoutLocale = column.slice(0, -2)
      return `${t(`models.Company.${columnWithoutLocale}`)} (${t(`models.Company.languagePlaceholder.${Locale[lastTwoCharacters]}`)})`
    } else {
      return t(`models.Company.${column}`)
    }
  }

  return <ModalTemplate title={t('components.UploadCompaniesPreview.title')} size="xxl">
    <form onSubmit={onSubmit}>
      <div className="modal-body vh-70 overflow-scroll">
        <table className="table table-sm">
          <thead>
          <tr>{COMPANY_FILE_HEADERS.map(column => {
            return <th key={column}>{getHeaderTranslation(column)}</th>
          })}</tr>
          </thead>
          <tbody>
          {companies.slice(-MAX_PREVIEW_ROWS).map(company => {
            return <tr key={company.tick}>{Object.keys(company).map(objectKey => {
              return <td key={objectKey}>{company[objectKey]}</td>
            })}</tr>
          })}
          </tbody>
        </table>
      </div>
      <div className="modal-footer justify-content-between">
        <ButtonWithLoaderAndIcon icon={insertDriveIcon} loading={false} className="btn-outline-success"
                                 onClick={onPickAnotherFile}>
          {t('components.UploadCompaniesPreview.pickAnotherFile')}
        </ButtonWithLoaderAndIcon>

        <ButtonWithLoaderAndIcon icon={uploadFileIcon} loading={false}
                                 className="btn-success" type="submit"
                                 disabled={false}>
          <span>{t('components.UploadCompaniesPreview.upload')}</span>
        </ButtonWithLoaderAndIcon>
      </div>
    </form>
  </ModalTemplate>
}

export default UploadCompaniesPreview