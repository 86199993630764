import {TranslationRequest} from 'domain/TranslationRequest'
import {User} from 'domain/User'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {makeLink} from '../../../../api/src/utils/linkUtils'
import EmailButton from './EmailButton'
import EmailTitle from './EmailTitle'
import {ProjectAggregate} from 'domain/ProjectAggregate'

type Props = {
  project: ProjectAggregate
  translator: User
  assigner: User
  translationRequest: TranslationRequest
}

const TranslationRequestRejectedNotification: React.FC<Props> = props => {
  const {project, translator, assigner, translationRequest} = props
  const {t} = useTranslation()

  return (
    <>
      <EmailTitle titleText={t('emails.translationRequestRejected.bodyTitle')} />

      <div>
        <span
          dangerouslySetInnerHTML={{
            __html: t('emails.translationRequestRejected.message', {
              name: assigner.name,
              translator: translator.name,
              title: project.title,
              type: t('models.ProjectType.' + project.type)
            })
          }}
        />

        <blockquote>{translationRequest.reply}</blockquote>

        <p style={{margin: '25px 0 16px'}}>
          <EmailButton
            buttonLink={makeLink(`/projects/${project._id}`)}
            buttonText={t('emails.translationRequestRejected.goToProject')}
          />
        </p>
      </div>
    </>
  )
}

export default TranslationRequestRejectedNotification
