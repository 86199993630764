import {Locale} from 'domain/Locale'
import {AlertBlock} from 'domain/Report'
import React, {ReactElement} from 'react'


interface AlertProps {
  block: AlertBlock,
  locale: Locale
}

const Alert: React.FC<AlertProps> = ({block, locale}): ReactElement => {
  const value = block.message[locale]?.value

  return (value && value.removeHTMLTags() !== '') ? (
    <div className={`report-block-${block.alertType} print-page-break-inside-avoid`}>
      <div className={`cdx-alert cdx-alert-${block.alertType}`}>
        <div className="cdx-alert__message">
          <p className="mb-0" dangerouslySetInnerHTML={{__html: block.message[locale]?.value || ''}}/>
        </div>
      </div>
    </div>
  ) : <></>
}

export default Alert
