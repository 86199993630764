import {Holiday} from 'domain/Holiday'
import {Project} from 'domain/Project'
import {getStageRules} from 'domain/project-stages/projectStageHelpers'
import {ProjectTask} from 'domain/Task'
import {calculateTaskDates} from 'domain/tasks/taskScheduleUtils'
import {ReactComponent as DoneIcon} from '../../../assets/icons/done.svg'
import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {getHolidays} from '../../../api-clients/holidayClient'
import {updateProjectTasksDates} from '../../../api-clients/projectTaskClient'
import useModal from '../../../hooks/useModal'
import useToast from '../../../hooks/useToast'
import TaskDatePicker from '../../tasks/TaskDatePicker'
import ModalTemplate from '../../utils/ModalTemplate'


type Props = {
  project: Project
  onUpdated: () => void
  tasks: ProjectTask[]
}

const ChangeTasksDatesModal: React.FC<Props> = ({project, onUpdated, tasks}) => {
  const [updatedTasks, setUpdatedTasks] = useState([...tasks])
  const {renderSuccessToast} = useToast()
  const [errors, setErrors] = useState<{ tasks?: any }>({})
  const [loading, setLoading] = useState(false)
  const {closeModal} = useModal()
  const [holidays, setHolidays] = useState<Holiday[]>([])
  const {t} = useTranslation()

  useEffect(() => {
    getHolidays().then(setHolidays)
  }, [])

  const onTaskDateChange = (taskIdx: number, date: Date, field: 'startDate' | 'endDate') => {
    updatedTasks[taskIdx][field] = date
    const stageRules = getStageRules(project.type)

    let start = date
    const taskIdxToAdjustDatesFrom = field === 'startDate' ? taskIdx : taskIdx + 1

    for (let i = taskIdxToAdjustDatesFrom; i < updatedTasks.length; i++) {
      const stageRule = stageRules[i]
      const {startDate, endDate} = calculateTaskDates(start, stageRule.hours, holidays)
      updatedTasks[i].startDate = startDate
      updatedTasks[i].endDate = endDate
      start = endDate
    }

    setUpdatedTasks([...updatedTasks])
  }

  const onSubmit = (e: React.FormEvent) => {
    e.preventDefault()

    setLoading(true)
    updateProjectTasksDates(project._id, updatedTasks).then(() => {
      onUpdated()
      closeModal()
      renderSuccessToast(t('components.ChangeTasksDatesModal.successfullyUpdated'))
    }).catch(({errors}) => {
      setErrors(errors)
    }).finally(() => {
      setLoading(false)
    })
  }

  const fieldErrors = (taskIdx: number, field: 'startDate' | 'endDate') => {
    const fieldError = errors[`tasks.${taskIdx}.${field}`]
    if (!fieldError) return

    return <div className="invalid-feedback d-block">
      {t(`components.ChangeTasksDatesModal.errors.${fieldError}`)}
    </div>
  }

  return <ModalTemplate title={t('components.ChangeTasksDatesModal.title')} size="xl">
    <form onSubmit={onSubmit}>
      <div className="modal-body">
        <table className="table table-sm">
          <thead>
          <tr>
            <th>{t('models.Task.stage')}</th>
            <th>{t('models.Task.startDate')}</th>
            <th>{t('models.Task.endDate')}</th>
          </tr>
          </thead>
          <tbody>
          {updatedTasks.map((task, i) => {
            return <tr key={task._id as string}>
              <td><strong>{t(`models.ProjectStage.${task.stage}.title`)}</strong></td>
              <td className="align-top">
                <TaskDatePicker initial={task.startDate} onChange={date => onTaskDateChange(i, date, 'startDate')}/>
                {fieldErrors(i, 'startDate')}
              </td>
              <td className="align-top">
                <TaskDatePicker initial={task.endDate} onChange={date => onTaskDateChange(i, date, 'endDate')}/>
                {fieldErrors(i, 'endDate')}
              </td>
            </tr>
          })}
          </tbody>
        </table>

        <div className="alert bg-warning mt-2">
          {t('components.ChangeTasksDatesModal.alert')}
        </div>
      </div>
      <div className="modal-footer">
        <button type="submit" className="btn btn-success btn-with-icon" disabled={loading}>
          <DoneIcon className="me-2 icon"/>
          <span>{t('components.ChangeTasksDatesModal.save')}</span>
        </button>
      </div>
    </form>
  </ModalTemplate>
}

export default ChangeTasksDatesModal