import React from 'react'
import {ReactComponent as AlignLeftIcon} from '../../../../assets/icons/format_align_left.svg'
import {ReactComponent as AlignRightIcon} from '../../../../assets/icons/format_align_right.svg'
import {ReactComponent as AlignCenterIcon} from '../../../../assets/icons/format_align_center.svg'
import {useTranslation} from 'react-i18next'
import {TextAlign} from 'domain/Report'
import {MENU_ICON_STYLE, MENU_ITEM_TITLE_STYLE, MENU_SECONDARY_ITEM_STYLE, MENU_SECTION_STYLE} from './styles'

interface Props {
  onTextAlignChange: (align: TextAlign) => void
}

export const TextAlignButtonsSection: React.FC<Props> = ({onTextAlignChange}) => {
  const {t} = useTranslation()

  const buttons = [
    {icon: <AlignLeftIcon className={MENU_ICON_STYLE} />, textAlign: TextAlign.LEFT},
    {icon: <AlignRightIcon className={MENU_ICON_STYLE} />, textAlign: TextAlign.RIGHT},
    {icon: <AlignCenterIcon className={MENU_ICON_STYLE} />, textAlign: TextAlign.CENTER}
  ]

  return (
    <div>
      <div className={MENU_ITEM_TITLE_STYLE}>{t('Align text')}</div>
      <div className={MENU_SECTION_STYLE}>
        {buttons.map(button => (
          <div
            className={MENU_SECONDARY_ITEM_STYLE}
            onClick={() => onTextAlignChange(button.textAlign)}
            key={button.textAlign}
          >
            {button.icon}
          </div>
        ))}
      </div>
    </div>
  )
}
