import {Entity, Id} from 'domain/Entity'
import {User} from 'domain/User'


export interface TranslationRequest extends Entity {
  projectId: Id
  assignerId: Id
  translatorId: Id
  status: TranslationRequestStatus
  message: string
  reply?: string
  cancelUserId?: Id
  cancelReply?: string
}

export enum TranslationRequestStatus {
  PENDING = 'PENDING',
  ACCEPTED = 'ACCEPTED',
  REJECTED = 'REJECTED'
}

export type TranslationRequestWithTranslator = TranslationRequest & {
  translator: User
}