import {Locale} from 'domain/Locale'
import {
  AlertBlock,
  Block,
  BlockType,
  CommonTableBlock,
  CommonTableContent,
  CommonTableText,
  ExcelTableBlock,
  GridBlock,
  GridData,
  HeadingBlock,
  ImageBlock,
  ListBlock,
  ListContent,
  ListItem,
  ParagraphBlock,
  TableContent,
  TextContent
} from 'domain/Report'
import {sanitizeAllCommentsAndMarks} from 'domain/utils/sanitizeUtils'
import escapeStringRegexp from 'escape-string-regexp'
import objectAssignDeep from 'object-assign-deep'
import {v4} from 'uuid'
import deepCopy from '../../ui/src/utils/deepCopy'


type ContentProcessor<T> = {
  textContent: (textContent: TextContent, skipTransformIfHasContent?: boolean) => T
  listContent: (listContent: ListContent) => T
  tableContent: (tableContent: TableContent) => T
  commonTableText: (commonTableText: CommonTableText) => T
  commonTableContent: (commonTableContent: CommonTableContent) => T
  gridData: (gridData: GridData) => T
}

export const initBlock = (block: Block, source: Locale, target: Locale): Block => {
  const newBlock = blockContentTransformers[block.type](block, contentInitializers(source, target))
  newBlock.id = v4()
  return newBlock
}

export const copyBlockTextContent = (block: Block,
                                     source: Locale,
                                     target: Locale,
                                     reset = false,
                                     copyTableContent = true): Block => {
  return blockContentTransformers[block.type](block, contentCopiers(source, target, reset, copyTableContent), true)
}

export const applyGlossaryToTextContent = (
  block: Block, target: Locale, glossary: { ja: string, en: string }[]
): Block => {
  return blockContentTransformers[block.type](block, contentGlossaryAppliers(target, glossary))
}

const blockContentTransformers: {
  [key in BlockType]: (block: Block,
                       contentTransformer: ContentProcessor<void>,
                       skipTextTransformIfHasContent?: boolean) => Block
} = {
  [BlockType.HEADING]: (block, contentTransformer) => {
    const headingBlock = deepCopy(block) as HeadingBlock
    contentTransformer.textContent(headingBlock.text)
    return headingBlock
  },
  [BlockType.PARAGRAPH]: (block, contentTransformer) => {
    const paragraphBlock = deepCopy(block) as ParagraphBlock
    contentTransformer.textContent(paragraphBlock.text)
    return paragraphBlock
  },
  [BlockType.LIST]: (block, contentTransformer) => {
    const listBlock = deepCopy(block) as ListBlock
    contentTransformer.listContent(listBlock.items)
    return listBlock
  },
  [BlockType.EXCEL_TABLE]: (block, contentTransformer, skipTextTransformIfHasContent?: boolean) => {
    const excelTableBlock = deepCopy(block) as ExcelTableBlock
    contentTransformer.textContent(excelTableBlock.title, skipTextTransformIfHasContent)
    contentTransformer.textContent(excelTableBlock.description, skipTextTransformIfHasContent)
    contentTransformer.tableContent(excelTableBlock.content)
    return excelTableBlock
  },
  [BlockType.COMMON_TABLE]: (block, contentTransformer) => {
    const commonTableBlock = deepCopy(block) as CommonTableBlock
    contentTransformer.commonTableText(commonTableBlock.title)
    contentTransformer.commonTableText(commonTableBlock.description)
    contentTransformer.commonTableContent(commonTableBlock.content)
    return commonTableBlock
  },
  [BlockType.IMAGE]: (block, contentTransformer) => {
    const imageBlock = deepCopy(block) as ImageBlock
    contentTransformer.textContent(imageBlock.title)
    contentTransformer.textContent(imageBlock.description)
    return imageBlock
  },
  [BlockType.ALERT]: (block, contentTransformer) => {
    const alertBlock = deepCopy(block) as AlertBlock
    contentTransformer.textContent(alertBlock.message)
    return alertBlock
  },
  [BlockType.BOOKMARK]: block => {
    return deepCopy(block)
  },
  [BlockType.GRID]: (block, contentTransformer) => {
    const gridBlock = deepCopy(block) as GridBlock
    contentTransformer.textContent(gridBlock.title)
    contentTransformer.textContent(gridBlock.description)
    contentTransformer.gridData(gridBlock.grid)
    return gridBlock
  },
  [BlockType.CLONE]: (block: Block) => {
    return deepCopy(block)
  }
}

const contentInitializers = (source: Locale, target: Locale): ContentProcessor<void> => {
  return {
    textContent: (textContent: TextContent) => {
      delete textContent[source]
      textContent[target] = {value: ''}
    },
    listContent: (listContent: ListContent) => {
      const convertList = (item: ListItem): ListItem => {
        if (typeof item === 'string') {
          return ''
        }

        const list: ListItem = []
        for (const listItem of item) {
          list.push(convertList(listItem))
        }
        return list
      }

      const convertLocaleContent = (localeContentValue: ListItem[]) => {
        const list: ListItem[] = []
        for (const item of localeContentValue) {
          list.push(convertList(item))
        }
        return list
      }
      if (listContent[source]) {
        listContent[target] = {value: convertLocaleContent(listContent[source]!.value)}
        delete listContent[source]
      } else {
        listContent[target] = {value: []}
      }
    },
    tableContent: (tableContent: TableContent) => {
      const convertTableValue = (tableText: string[][]) => {
        return tableText.map(strings => {
          return strings.map(_ => '')
        })
      }
      if (tableContent[source]) {
        tableContent[target] = {value: convertTableValue(tableContent[source]!.value)}
        delete tableContent[source]
      } else {
        tableContent[target] = {value: []}
      }
    },
    commonTableText: (_: CommonTableText) => {
      throw new Error('not implemented')
    },
    commonTableContent: (_: CommonTableContent) => {
      throw new Error('not implemented')
    },
    gridData: (_: GridData) => {
      throw new Error('not implemented')
    }
  }
}

const contentGlossaryAppliers = (target: Locale, glossary: { ja: string, en: string }[]): ContentProcessor<void> => {
  const applyGlossary = (text?: string) => {
    let result = text || ''
    glossary.forEach(term => {
      result = result.replace(new RegExp(escapeStringRegexp(term.ja), 'g'), term.en)
    })
    return result
  }

  return {
    textContent: (textContent: TextContent) => {
      if (!textContent[target]?.value) return
      textContent[target]!.value = applyGlossary(textContent[target]?.value)
    },
    listContent: (listContent: ListContent) => {
      if (!listContent[target]?.value) return

      const applyGlossaryToItem = (item: ListItem): ListItem => {
        if (Array.isArray(item)) {
          return item.map(applyGlossaryToItem)
        } else {
          return applyGlossary(item)
        }
      }

      listContent[target]!.value = listContent[target]!.value.map(applyGlossaryToItem)
    },
    tableContent: (_: TableContent) => {
      return
    },
    commonTableText: (commonTableText: CommonTableText) => {
      if (!commonTableText[target]) return
      commonTableText[target] = applyGlossary(commonTableText[target])
    },
    commonTableContent: (commonTableContent: CommonTableContent) => {
      if (!commonTableContent[target]) return
      commonTableContent[target] = commonTableContent[target]!.map(row => {
        return row.map(cell => {
          return {...cell, value: applyGlossary(cell.value)}
        })
      })
    },
    gridData: (gridData: GridData) => {
      if (!gridData[target]) return
      gridData[target] = gridData[target]!.map(row => {
        return row.map(cell => {
          return {...cell, blocks: cell.blocks.map(block => {
            return applyGlossaryToTextContent(block, target, glossary)
          })}
        })
      })
    }
  }
}

const contentCopiers = (source: Locale, target: Locale,
                        reset: boolean, copyTableContent: boolean): ContentProcessor<void> => {
  const targetContentPresenceCheckers = contentPresenceCheckers(target)

  const copyContent = (content: any) => {
    const copiedContent = deepCopy(content)
    return JSON.parse(sanitizeAllCommentsAndMarks(JSON.stringify(copiedContent)))
  }

  return {
    textContent: (textContent: TextContent, skipTransformIfHasContent?: boolean) => {
      const hasContent = targetContentPresenceCheckers.textContent(textContent)
      if (hasContent && !reset) return
      if (hasContent && skipTransformIfHasContent) return

      textContent[target] = copyContent(textContent[source])
    },
    listContent: (listContent: ListContent) => {
      const hasContent = targetContentPresenceCheckers.listContent(listContent)
      if (!reset && hasContent) return

      listContent[target] = copyContent(listContent[source])
    },
    tableContent: (tableContent: TableContent) => {
      if (!copyTableContent) return
      const hasContent = targetContentPresenceCheckers.tableContent(tableContent)
      if (!reset && hasContent) return

      tableContent[target] = copyContent(tableContent[source])
    },
    commonTableText: (commonTableText: CommonTableText) => {
      const hasContent = targetContentPresenceCheckers.commonTableText(commonTableText)
      if (!reset && hasContent) return

      commonTableText[target] = copyContent(commonTableText[source])
    },
    commonTableContent: (commonTableContent: CommonTableContent) => {
      if (!copyTableContent) return
      const hasContent = targetContentPresenceCheckers.commonTableContent(commonTableContent)
      if (!reset && hasContent) return

      commonTableContent[target] = copyContent(commonTableContent[source])
    },
    gridData: (gridData: GridData) => {
      gridData[target] = (gridData[source] || []).map((row, rowIdx) => {
        return row.map((cell, cellIdx) => {
          return {
            id: cell.id,
            blocks: cell.blocks.map((sourceBlock, blockId) => {
              const targetBlock = gridData[target]?.[rowIdx]?.[cellIdx]?.blocks?.[blockId]

              const blockWithBothLocales = targetBlock?.type === sourceBlock.type ?
                objectAssignDeep({}, targetBlock, sourceBlock) :
                sourceBlock

              return copyBlockTextContent(blockWithBothLocales, source, target)
            })
          }
        })
      })
    }
  }
}

const contentPresenceCheckers = (locale: Locale): ContentProcessor<boolean> => {
  return {
    textContent: (textContent: TextContent) => {
      return !!textContent[locale]?.value
    },
    listContent: (listContent: ListContent) => {
      return !!listContent[locale]?.value && listContent[locale]!.value.length > 0
    },
    tableContent: (tableContent: TableContent) => {
      return !!tableContent[locale]?.value && tableContent[locale]!.value.length > 0
    },
    commonTableText: (commonTableText: CommonTableText) => {
      return !!commonTableText[locale]
    },
    commonTableContent: (commonTableContent: CommonTableContent) => {
      return !!commonTableContent[locale] && commonTableContent[locale]!.length > 0
    },
    gridData: (gridData: GridData) => {
      throw new Error('Not implemented')
    }
  }
}