import {hasTaskAccess} from 'domain/access'
import {Comment} from 'domain/Comment'
import {Locale} from 'domain/Locale'
import {ReactComponent as CommentIcon} from '../../../assets/icons/comment.svg'
import React, {ReactElement, useContext, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {getComments} from '../../../api-clients/commentClient'
import {getProject} from '../../../api-clients/projectClient'
import {finishProjectTask} from '../../../api-clients/projectTaskClient'
import useToast from '../../../hooks/useToast'
import {UserContext} from '../../../providers/UserProvider'
import {commentsWithRepliesSize} from '../../../utils/commentsUtils'
import ProjectCard from './ProjectCard'
import {ProjectAggregate} from 'domain/ProjectAggregate'

interface CardProps {
  project: ProjectAggregate
  onUpdated: (project: ProjectAggregate) => void
}

const InReviewCard: React.FC<CardProps> = ({project, onUpdated}): ReactElement => {
  const {t} = useTranslation()
  const {user} = useContext(UserContext)
  const {renderSuccessToast} = useToast()

  const [comments, setComments] = useState<Comment[]>([])

  useEffect(() => {
    getComments(project._id).then(comments => setComments(comments.filter(comment => comment.locale === Locale.EN)))
  }, [project])

  const commentIcon = () => {
    return comments.length > 0 ? <span className="btn btn-xs btn-secondary btn-with-icon">
      <CommentIcon className="icon-xs"/><span>{commentsWithRepliesSize(comments)}</span>
    </span> : undefined
  }

  const onDone = async (e: React.MouseEvent) => {
    e.preventDefault()
    e.stopPropagation()

    if (!project.activeTask) return

    finishProjectTask(project.activeTask._id).then(_ => {
      getProject(project._id).then(project => {
        onUpdated(project)
        renderSuccessToast(t('components.InReviewCard.completed'))
      })
    })
  }

  return <ProjectCard project={project} badges={commentIcon()}>
    {project.activeTask && hasTaskAccess(user, project, project.activeTask) && <div className="d-flex mt-2">
      <button className="btn btn-sm btn-outline-success me-1 flex-grow-1" onClick={onDone}>
        <span>{t('components.InReviewCard.done')}</span>
      </button>
    </div>}
  </ProjectCard>
}

export default InReviewCard