import {Id, Paginated} from 'domain/Entity'
import {
  AggregatedMicroTranslation,
  AggregatedMicroTranslationProject,
  MicroTranslation,
  MicroTranslationProject
} from 'domain/MicroTranslation'
import apiClient from '../ApiClient'


const getPublishedTranslationProjects = async (page = 1): Promise<Paginated<AggregatedMicroTranslationProject>> => {
  return await apiClient.get(`/api/micro-translations/published?page=${page}`)
}

const getAssignedTranslationProjects = async (page = 1): Promise<Paginated<AggregatedMicroTranslationProject>> => {
  return await apiClient.get(`/api/micro-translations/assigned?page=${page}`)
}

const getUnassignedTranslationProjects = async (page = 1): Promise<Paginated<AggregatedMicroTranslationProject>> => {
  return await apiClient.get(`/api/micro-translations/unassigned?page=${page}`)
}

const searchMicroTranslationProjects = async (search: string,
                                              page = 1): Promise<Paginated<AggregatedMicroTranslationProject>> => {
  return await apiClient.get(`/api/micro-translations/search?search=${search}&page=${page}`)
}

const getMicroTranslationProject = async (projectId: Id): Promise<MicroTranslationProject> => {
  return await apiClient.get(`/api/micro-translations/${projectId}`)
}

const getMicroTranslationProjectTranslations = async (projectId: Id): Promise<AggregatedMicroTranslation[]> => {
  return await apiClient.get(`/api/micro-translations/${projectId}/translations`)
}

const assignMicroTranslationToMe = async (projectId: Id): Promise<MicroTranslationProject> => {
  return await apiClient.patch(`/api/micro-translations/${projectId}/assign-me`)
}

const unassignMeFromMicroTranslation = async (projectId: Id): Promise<MicroTranslationProject> => {
  return await apiClient.patch(`/api/micro-translations/${projectId}/unassign-me`)
}

const saveMicroTranslation = async (
  projectId: Id, translationId: Id, translation: string): Promise<MicroTranslation> => {
  return await apiClient.patch(`/api/micro-translations/${projectId}/translations/${translationId}`, {translation})
}

const saveAndPublishMicroTranslation = async (projectId: Id, translationId: Id,
                                              translation: string): Promise<MicroTranslation> => {
  return await apiClient.patch(
    `/api/micro-translations/${projectId}/translations/${translationId}/publish`, {translation}
  )
}

const getPendingMicroTranslationProjectsCount = async (): Promise<{count: number}> => {
  return await apiClient.get(`/api/micro-translations/pending-count`)
}

const publishMicroTranslationCorrection = async (projectId: Id, translationId: Id,
                                                 correction: string): Promise<MicroTranslation> => {
  return await apiClient.patch(`/api/micro-translations/${projectId}/translations/${translationId}/publish-correction`,
    {correction})
}

export {
  getPublishedTranslationProjects,
  getAssignedTranslationProjects,
  getUnassignedTranslationProjects,
  getMicroTranslationProject,
  getMicroTranslationProjectTranslations,
  assignMicroTranslationToMe,
  unassignMeFromMicroTranslation,
  saveMicroTranslation,
  saveAndPublishMicroTranslation,
  searchMicroTranslationProjects,
  getPendingMicroTranslationProjectsCount,
  publishMicroTranslationCorrection
}