import {Paginated} from 'domain/Entity'
import {Permission} from 'domain/Permission'
import {Project, ProjectType} from 'domain/Project'
import {ReactComponent as AddIcon} from '../../assets/icons/add.svg'
import React, {ReactElement, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {
  getActiveProjectsByType,
  getCurrentUserProjects,
  getPublishedProjects,
  searchProjects
} from '../../api-clients/projectClient'
import EventBus, {EventType} from '../../EventBus'
import useModal from '../../hooks/useModal'
import LocalStorage from '../../LocalStorage'
import PaginatedView from '../utils/PaginatedView'
import Pane from '../utils/Pane'
import Protected from '../utils/Protected'
import NewProject from './NewProject'
import PaginatedProjectsColumn from './panes/PaginatedProjectsColumn'
import ProjectHistoryPane from './panes/ProjectHistoryPane'
import ProjectsColumn from './panes/ProjectsColumn'
import ProjectCard from './ProjectCard'
import {ProjectAggregate} from 'domain/ProjectAggregate'


enum DashboardColumn {
  MY_PROJECTS = 'MY_PROJECTS',
  FULL_REPORT = 'FULL_REPORT',
  ESPRESSO = 'ESPRESSO',
  FLASH = 'FLASH',
  NEWS_UPDATE = 'NEWS_UPDATE',
  POST_INTERVIEW_UPDATE = 'POST_INTERVIEW_UPDATE',
  PUBLISHED = 'PUBLISHED'
}

enum CustomColumn {
  HISTORY = 'HISTORY'
}

const DEFAULT_VISIBLE_COLUMNS = [
  DashboardColumn.MY_PROJECTS,
  DashboardColumn.FULL_REPORT,
  DashboardColumn.ESPRESSO
]

type ProjectLoadFunction = (() => Promise<Project[]>) | ((page: number) => Promise<Paginated<Project>>)

const PROJECTS_LOAD_FUNCTIONS: { [key in DashboardColumn]: ProjectLoadFunction } = {
  [DashboardColumn.MY_PROJECTS]: () => getCurrentUserProjects(),
  [DashboardColumn.FULL_REPORT]: () => getActiveProjectsByType(ProjectType.FULL),
  [DashboardColumn.ESPRESSO]: () => getActiveProjectsByType(ProjectType.ESPRESSO),
  [DashboardColumn.FLASH]: () => getActiveProjectsByType(ProjectType.FLASH),
  [DashboardColumn.NEWS_UPDATE]: () => getActiveProjectsByType(ProjectType.NEWS_UPDATE),
  [DashboardColumn.POST_INTERVIEW_UPDATE]: () => getActiveProjectsByType(ProjectType.POST_INTERVIEW_UPDATE),
  [DashboardColumn.PUBLISHED]: (page: number) => getPublishedProjects(page)
}

const ProjectsPage: React.FC = (): ReactElement => {
  const {t} = useTranslation()
  const {showModal} = useModal()
  const [visibleColumns, setVisibleColumns] = useState<(DashboardColumn | CustomColumn)[]>(
    LocalStorage.getProjectsDashboardColumns() || DEFAULT_VISIBLE_COLUMNS
  )
  const [search, setSearch] = useState<string>('')
  const [isSearchExecuted, setIsSearchExecuted] = useState(false)

  useEffect(() => {
    LocalStorage.setProjectsDashboardColumns(visibleColumns)
  }, [visibleColumns])

  const isVisible = (column: DashboardColumn | CustomColumn) => {
    return visibleColumns.includes(column)
  }

  const toggleColumn = (event: React.MouseEvent, column: DashboardColumn | CustomColumn) => {
    event.preventDefault()

    if (isVisible(column)) {
      setVisibleColumns(columns => columns.filter(c => c !== column))
    } else {
      setVisibleColumns(columns => ([...columns, column]))
    }
  }

  const closeColumn = (event: React.MouseEvent, column: DashboardColumn | CustomColumn) => {
    event.preventDefault()
    setVisibleColumns(columns => columns.filter(c => c !== column))
  }

  const addNewProject = (event: React.MouseEvent) => {
    event.preventDefault()
    showModal(<NewProject/>)
  }

  const onSearchConfirm = (event: React.KeyboardEvent<HTMLInputElement>) => {
    event.preventDefault()
    if (!search) return

    if (event.key === 'Enter') {
      setIsSearchExecuted(true)
      EventBus.emit(EventType.REFRESH_PAGINATED_COLLECTION, {id: 'search'})
    }
  }

  const onSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault()
    setSearch(event.target.value)
  }

  const columnComponent = (type: DashboardColumn) =>
    type === DashboardColumn.PUBLISHED ? PaginatedProjectsColumn : ProjectsColumn

  return (
    <div className="panes panes-fill-height">
      <div className="pane-sidebar">
        <div className="pane-body pt-3">
          <input className="form-control mb-3" type="search"
                 placeholder={t('components.Companies.Companies.search_placeholder')}
                 aria-label="Search" onChange={onSearch} value={search} onKeyUp={onSearchConfirm}/>
          <div className="nav flex-column nav-pills nav-pills-secondary">
            {Object.keys(DashboardColumn).map(column => {
              return (
                <a className={`nav-link mb-1 ${isVisible(column as DashboardColumn) ? 'active' : ''}`}
                   key={column} href="" onClick={e => toggleColumn(e, column as DashboardColumn)}>
                  {t(`components.Projects.Projects.columnTitles.${column.toLowerCase()}`)}
                </a>)
            })}

            <Protected permission={Permission.Project.SEE_GLOBAL_HISTORY}>
              <a className={`nav-link mb-1 ${isVisible(CustomColumn.HISTORY) ? 'active' : ''}`}
                 href="" onClick={e => toggleColumn(e, CustomColumn.HISTORY)}>
                {t('components.Projects.Projects.columnTitles.history')}
              </a>
            </Protected>
          </div>
            <Protected permission={Permission.Project.CREATE}>
              <button className="btn btn-outline-primary btn-sm btn-with-icon btn-block mt-3" onClick={addNewProject}>
                <span>{t('components.Projects.Projects.newProject')}</span>
                <AddIcon className="icon icon-sm"/>
              </button>
            </Protected>
        </div>
      </div>

      <div className="panes-horizontal-scoller">
        {isSearchExecuted && (
          <Pane title={t(`components.Projects.Projects.columnTitles.search`)}
                onClose={() => setIsSearchExecuted(false)}>
            <PaginatedView fetchCollection={page => searchProjects(search, page)} id={'search'}
                           render={(project: ProjectAggregate) => {
                             return <ProjectCard project={project} showProjectType={true}/>
                           }}/>
          </Pane>)}

        {isVisible(CustomColumn.HISTORY) && <ProjectHistoryPane onClose={e => closeColumn(e, CustomColumn.HISTORY)} />}

        {Object.keys(DashboardColumn).map(column => {
          const ColumnComponent = columnComponent(column as DashboardColumn)

          return isVisible(column as DashboardColumn) && (
            <Pane title={t(`components.Projects.Projects.columnTitles.${column.toLowerCase()}`)}
                  key={column} onClose={e => closeColumn(e, column as DashboardColumn)}>
              <ColumnComponent loadProjects={PROJECTS_LOAD_FUNCTIONS[column]}/>
            </Pane>)
        })}
      </div>
    </div>
  )
}

export default ProjectsPage