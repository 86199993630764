import {Job} from 'domain/Job'
import apiClient from '../ApiClient'


const getJob = async (id: string): Promise<Job> => {
  return await apiClient.get(`/api/jobs/${id}`) as Job
}

export {
  getJob
}