import debounce from './debounce'

export const attachOnScrollHook = (
  container: HTMLElement,
  page: number,
  loading: boolean,
  hasNext: boolean,
  setPage: (page: number) => void
) => {
  const scrollableContainer = container

  const onScroll = () => {
    const {scrollHeight, scrollTop, clientHeight} = scrollableContainer

    if (scrollTop + clientHeight > scrollHeight - 5) {
      if (!hasNext) {
        scrollableContainer.removeEventListener('scroll', debouncedScroll)
        return
      }

      if (!loading) {
        setPage(page + 1)
      }
    }
  }

  const debouncedScroll = debounce(onScroll, 200)

  scrollableContainer.addEventListener('scroll', debouncedScroll)

  return () => {
    scrollableContainer.removeEventListener('scroll', debouncedScroll)
  }
}