import {Locale} from 'domain/Locale'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {fromNow} from '../../../utils/momentUtils'


interface Props {
  userName: string,
  companyName: string,
  sentAt: Date,
  locale: Locale
}

const MicroTranslationTranslatorLabel: React.FC<Props> = props => {
  const {userName, companyName, sentAt, locale} = props
  const {t} = useTranslation()

  return <div className="d-flex align-items-center justify-content-between mb-2">
    <div className="small text-muted"
         dangerouslySetInnerHTML={{
           __html: t('components.MicroTranslationColumn.sentBy', {user: userName, company: companyName})
         }}>
    </div>
    <div className="small text-muted-more text-right">
      {fromNow(sentAt, locale)}
    </div>
  </div>

}

export default MicroTranslationTranslatorLabel