import {Locale} from 'domain/Locale'
import {Project} from 'domain/Project'
import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {getLastPublishedProject, getProjectLastRevisionDate} from '../../../api-clients/projectClient'


type Props = {
  project: Project
  locale: Locale
  reportFileCreatedAt: Date
}

const OutdatedReportFilesWarning: React.FC<Props> = ({project, locale, reportFileCreatedAt}) => {
  const {t} = useTranslation()
  const [lastRevisionDate, setLastRevisionDate] = useState<Date | null>(null)
  const [lastPublishedProject, setLastPublishedProject] = useState<Project | null>(null)

  useEffect(() => {
    getProjectLastRevisionDate(project._id, locale).then(({date}) => setLastRevisionDate(date))

    getLastPublishedProject(project.companyId, locale).then(project => {
      setLastPublishedProject(project)
    }).catch(() => {
      setLastPublishedProject(null)
    })
  }, [project._id])

  if (lastRevisionDate && new Date(lastRevisionDate) > reportFileCreatedAt) {
    return <div className="text-danger">
      {t('components.OutdatedReportFilesWarning.newRevision')}
    </div>
  }

  const isLastProjectPublishedAfterFilesGenerated = () => {
    if (!lastPublishedProject) return false
    if (project.published?.[locale]) return false
    const lastPublishedProjectPushedToPublicAt = new Date(lastPublishedProject.pushedToPublicAt?.[locale]!)

    return project._id !== lastPublishedProject._id && reportFileCreatedAt <= lastPublishedProjectPushedToPublicAt
  }

  if (isLastProjectPublishedAfterFilesGenerated()) {
    return <div className="text-danger">
      {t('components.OutdatedReportFilesWarning.newProjectPublished')}
    </div>
  }

  return <></>
}

export default OutdatedReportFilesWarning