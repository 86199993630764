import {EditorCommonTableBlock, EditorCommonTableBlockCellData, EditorCommonTableBlockData} from 'domain/Editor'
import {Locale} from 'domain/Locale'
import {CommonTableBlock} from 'domain/Report'
import Converter from './Converter'


class CommonTableConverter extends Converter<CommonTableBlock, EditorCommonTableBlock> {
  toEditor(block: CommonTableBlock, locale: Locale): EditorCommonTableBlock {
    const {id, type, title, description, content, layout, style, additionalStyles, checked} = block
    const rowsContent = layout.rows.map((row, rowIndex) => {
      return {
        cells: row.cells.map((cell, cellIndex) => {
          const cellContent = content[locale]?.[rowIndex]?.[cellIndex]
          const updatedCellContent: EditorCommonTableBlockCellData = {
            ...cell,
            value: cellContent?.value || ''
          }

          if (!!cellContent?.width) {
            updatedCellContent.width = cellContent?.width
          }

          return updatedCellContent
        })
      }
    })

    const editorBlockData: EditorCommonTableBlockData = {
      style,
      additionalStyles: additionalStyles || [],
      title: title[locale] || '',
      description: description[locale] || '',
      rows: rowsContent
    }

    if (checked) {
      editorBlockData.checked = checked
    }

    return {id, type, data: editorBlockData}
  }

  fromEditor(editorBlock: EditorCommonTableBlock, locale: Locale): CommonTableBlock {
    const {id, type, data} = editorBlock

    const {title, description, rows, style, additionalStyles, checked} = data

    const cellContent = rows.map(row => row.cells.map(cell => ({value: cell.value, width: cell.width})))

    const rowsWithoutData = rows.map(row => {
      return {
        cells: row.cells.map(cell => {
          const {value, width, ...other} = cell
          return other
        })
      }
    })

    const block: CommonTableBlock = {
      id, type, style, additionalStyles,
      layout: {
        rows: rowsWithoutData
      },
      title: {
        [locale]: title
      },
      description: {
        [locale]: description
      },
      content: {
        [locale]: cellContent
      }
    }

    if (checked) {
      block.checked = checked
    }

    return block
  }
}

const commonTableConverter = new CommonTableConverter()
export default commonTableConverter