import {useEffect} from 'react'

const DATA_SECTION_SELECTOR = '[data-section=true]'

export const useResizeDataSections = (deps: unknown[]) => {
  useEffect(() => {
    const targets = Array.from(document.querySelectorAll(DATA_SECTION_SELECTOR)) as HTMLElement[]
    if (targets.length === 0) return

    const resizeObserver = initResizeObserver(targets)
    const intersectionObserver = initIntersectionObserver(targets)

    return () => {
      resizeObserver.disconnect()
      intersectionObserver.disconnect()
    }
  }, deps)
}

const initResizeObserver = (targets: HTMLElement[]): ResizeObserver => {
  const resizeObserver = new ResizeObserver(entries => {
    for (const entry of entries) {
      const target = entry.target as HTMLElement
      target.style['contain-intrinsic-size'] = `0 ${target.clientHeight}px`
    }
  })

  for (const target of targets) {
    resizeObserver.observe(target)
  }

  return resizeObserver
}

const initIntersectionObserver = (targets: HTMLElement[]): IntersectionObserver => {
  const options = {
    rootMargin: '0px',
    threshold: 0.1
  }

  const intersectionObserver = new IntersectionObserver(entries => {
    for (const entry of entries) {
      const target = entry.target as HTMLElement

      if (entry.isIntersecting) {
        target.classList.add('content-visibility-visible')
      } else {
        target.classList.remove('content-visibility-visible')
      }
    }
  }, options)

  for (const target of targets) {
    intersectionObserver.observe(target)
  }

  return intersectionObserver
}
