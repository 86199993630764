import {Id} from 'domain/Entity'
import {TaskUserAssignmentPayload, TaskWithUser} from 'domain/Task'
import {hasRoles, isTranslator, Role, User} from 'domain/User'
import {ReactComponent as DoneIcon} from '../../assets/icons/done.svg'
import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {assignUsersToProjectTask} from '../../api-clients/projectTaskClient'
import {getAnalysts, getEditors, getTranslators} from '../../api-clients/userClient'
import useModal from '../../hooks/useModal'
import MultipleSelect from '../utils/form/MultipleSelect'
import Select from '../utils/form/Select'
import ModalTemplate from '../utils/ModalTemplate'

type Props = {
  projectAssistants: User[]
  task: TaskWithUser
  onAssigned: () => void
  canAssignUser: boolean
  canAssignAssistants: boolean
}

const SetTaskAssignee: React.FC<Props> = props => {
  const {task, projectAssistants, onAssigned, canAssignUser, canAssignAssistants} = props
  const projectAssistantsIds = projectAssistants.map(a => a._id)
  const otherTaskAssistantsIds = (task.assistantUsersIds || []).subtract(projectAssistantsIds)

  const [userId, setUserId] = useState<Id | null>(task.userId || null)
  const [assistantUsersIds, setAssistantUsersIds] = useState<Id[]>(otherTaskAssistantsIds)
  const [users, setUsers] = useState<User[]>([])
  const [loading, setLoading] = useState(true)
  const {t} = useTranslation()
  const {closeModal} = useModal()

  const getUsers = () => {
    if (isTranslator(task.roles)) return getTranslators()
    if (hasRoles(task.roles, Role.EDITOR)) return getEditors()
    return getAnalysts()
  }

  useEffect(() => {
    getUsers().then(setUsers).then(() => setLoading(false))
  }, [])

  const onChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setUserId(event.target.value || null)
  }

  const onAssistantsChange = (assistantsIds: string[]) => {
    setAssistantUsersIds(assistantsIds)
  }

  const onConfirm = (event: React.MouseEvent<HTMLButtonElement> | React.FormEvent) => {
    event.preventDefault()

    const payload = {} as TaskUserAssignmentPayload

    if (canAssignUser) payload.userId = userId
    if (canAssignAssistants) payload.assistantUsersIds = [
      ...projectAssistantsIds,
      ...assistantUsersIds
    ].uniqueBy(id => id)

    assignUsersToProjectTask(task._id, payload).then(() => {
      closeModal()
      onAssigned()
    })
  }

  const stage = t(`models.ProjectStage.${task.stage}.title`)
  return <ModalTemplate size="md" title={t('components.SetTaskAssignee.title', {stage})}>
    <form onSubmit={onConfirm}>
      <div className="modal-body">
        <div className="row">
          <div className="col-12">
            <label htmlFor="user" className="form-label small">
              {t('components.SetTaskAssignee.label')}
            </label>
            <Select id="user" className="form-select" value={userId as string || ''}
                    onChange={onChange} name="userId" disabled={loading || !canAssignUser}>
              <option value="">{t('components.SetTaskAssignee.selectUser')}</option>
              {users.map(user =>
                <option key={user._id as string} value={user._id as string}>{user.name}</option>
              )}
            </Select>
          </div>
        </div>

        {canAssignAssistants && <div className="row mt-2">
          <div className="col-12">
            <label className="form-label small">
              {t('components.SetTaskAssignee.otherUsers')}
            </label>
            <MultipleSelect htmlId="assistants" name="assistants"
                            placeholder={t('components.SetTaskAssignee.selectOtherUsers')}
                            label={(user: User) => user.name!} collection={users}
                            selectedValues={assistantUsersIds as string[]}
                            onChange={onAssistantsChange}
            />
          </div>
        </div>}
      </div>
      <div className="modal-footer">
        <button type="submit" className="btn btn-success btn-with-icon">
          <span>{t('components.SetTaskAssignee.update')}</span>
          <DoneIcon className="icon" />
        </button>
      </div>
    </form>
  </ModalTemplate>
}

export default SetTaskAssignee