import React, {useEffect, useState} from 'react'
import {getPendingMicroTranslationProjectsCount} from '../../api-clients/microTranslationClient'
import websocket from '../../websocket'


const MicroTranslationsBadge: React.FC = () => {
  const [count, setCount] = useState(0)

  useEffect(() => {
    setTimeout(() => {
      getPendingMicroTranslationProjectsCount().then(({count}) => setCount(count))
    }, 0)

    websocket.onPendingMicroTranslationCountChanged(() => {
      getPendingMicroTranslationProjectsCount().then(({count}) => setCount(count))
    })

    return () => {
      websocket.stopListeningPendingMicroTranslationCountChanged()
    }
  }, [])

  if (count === 0) return <></>
  return <span className="badge bg-danger badge-circle-sm ml-n1 ms-1">{count}</span>
}

export default MicroTranslationsBadge