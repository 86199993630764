import {ReactComponent as ArrowDownward} from '../../../assets/icons/arrow_downward.svg'
import {ReactComponent as ArrowUpward} from '../../../assets/icons/arrow_upward.svg'
import {ReactComponent as Close} from '../../../assets/icons/close.svg'
import {ReactComponent as FindReplace} from '../../../assets/icons/find_replace.svg'
import React, {ChangeEvent, useEffect, useRef, useState} from 'react'
import {useTranslation} from 'react-i18next'
import EventBus, {EventType} from '../../../EventBus'


interface SearchAndReplaceProps {
  classes?: string
  parentsSelector: string
}

const SearchAndReplace: React.FC<SearchAndReplaceProps> = ({classes, parentsSelector}) => {
  const {t} = useTranslation()
  const [isActive, setActive] = useState(false)
  const [searchWord, setSearchWord] = useState('')
  const [replaceWord, setReplaceWord] = useState('')
  const [canChange, setCanChange] = useState(false)
  const [currentMatch, setCurrentMatch] = useState(0)
  const [matches, setMatches] = useState(0)
  const theWindow = window as any

  const onReadyCb = useRef<(() => void) | null>(null)

  const onEditorReady = () => {
    setTimeout(() => {
      if (!onReadyCb.current) {
        return
      }
      onReadyCb.current()
      onReadyCb.current = null
    }, 150)
  }

  const searchCodes = ['F3', 'ShiftF3']

  const onDocumentKeyDown = (e: KeyboardEvent) => {
    const code = `${e.shiftKey ? 'Shift' : ''}${e.code}`

    if (searchCodes.includes(code)) {
      e.preventDefault()
      e.stopPropagation()
    }

    if (code === 'F3') {
      if (!isActive) setActive(true)
      else search()
    } else if (code === 'ShiftF3') {
      search(true)
    }
  }

  useEffect(() => {
    document.addEventListener('keydown', onDocumentKeyDown)
    return () => document.removeEventListener('keydown', onDocumentKeyDown)
  }, [isActive, searchWord, currentMatch])

  useEffect(() => {
    EventBus.on(EventType.EDITOR_READY, onEditorReady)

    return () => EventBus.unsubscribe(EventType.EDITOR_READY, onEditorReady)
  }, [])

  useEffect(() => {
    if (!isActive) {
      setSearchWord('')
      setMatches(0)
      setReplaceWord('')
      setCurrentMatch(0)
    }
  }, [isActive])

  const toggleActive = (e: React.MouseEvent) => {
    e.preventDefault()
    setActive(!isActive)
  }

  const onPressSearchInput = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.code === 'Enter') {
      search()
    }
  }

  const updateSearchWord = (e: ChangeEvent<HTMLInputElement>) => {
    const word = e.target.value
    setSearchWord(word)
    const matches = word ? document.body.innerText.match(new RegExp(`${word}`, 'g'))?.length ?? 0 : 0
    setMatches(matches)
  }

  const updateReplaceWord = (e: ChangeEvent<HTMLInputElement>) => {
    setReplaceWord(e.target.value)
  }

  const search = (backward = false) => {
    if (!theWindow.find) return
    theWindow.find(searchWord, true, backward, true)

    updateCurrentMatch(currentMatch + (backward ? -1 : 1))

    const textNode = theWindow.getSelection().anchorNode

    const parentContainers = Array.from(document.querySelectorAll(parentsSelector))
    for (const parent of parentContainers) {
      if (parent.contains(textNode)) {
        setCanChange(true)
        return
      }
    }
    setCanChange(false)
  }

  const updateCurrentMatch = (value: number) => {
    let newValue = value
    if (newValue < 0) newValue = matches
    else if (newValue > matches) newValue = 0
    setCurrentMatch(newValue)
  }

  const onSearch = (e: React.MouseEvent, backward = false) => {
    e.preventDefault()
    search(backward)
  }

  const replaceTextInEditor = () => {
    const textNode = theWindow.getSelection().anchorNode
    textNode.textContent = textNode.textContent.replace(searchWord, replaceWord)
    theWindow.find(replaceWord, true, false, true)
    setMatches(matches - 1)
    updateCurrentMatch(currentMatch - 1)
  }

  const onReplace = (e: React.MouseEvent) => {
    e.preventDefault()

    if (!canChange || !replaceWord) return

    const textNode = theWindow.getSelection().anchorNode
    if (hasParentWithClass(textNode, 'codex-editor-wrapper')) {
      setTimeout(replaceTextInEditor)
    } else {
      onReadyCb.current = () => {
        search()
        setTimeout(replaceTextInEditor)
      }
      textNode.parentElement?.click()
    }
  }

  const hasParentWithClass = (el: HTMLElement, parentClass: string) => {
    let parent = el.parentElement
    while (parent) {
      if (parent.classList.contains(parentClass))
        return true
      parent = parent.parentElement
    }
    return false
  }

  const searchBackward = (e: React.MouseEvent) => onSearch(e, true)

  return isActive ?
    <div className={`navbar-contextual bg-softer-warning slide-in-top ${classes}`}>
      <div className="col-2 d-flex align-items-center">
        <span className="text-muted">{t('components.SearchAndReplace.title')}</span>
      </div>
      <div className="col-8 d-flex align-items-center">
        <div className="container">
          <div className="row g-1">
            <div className="col">
              <input className="form-control form-control-sm" value={searchWord} onKeyPress={onPressSearchInput}
                     onChange={updateSearchWord}
                     placeholder={t('components.SearchAndReplace.searchPlaceholder')}
                     autoFocus={true}/>
            </div>
            <div className="col">
              <input className="form-control form-control-sm" value={replaceWord} onChange={updateReplaceWord}
                     placeholder={t('components.SearchAndReplace.replacePlaceholder')}/>
            </div>
            <div className="col-auto">
              <button className="btn btn-with-icon btn-sm" disabled={!searchWord} onClick={searchBackward}>
                <ArrowUpward className="icon-sm"/>
              </button>
              <button className="btn btn-with-icon btn-sm" disabled={!searchWord} onClick={onSearch}>
                <ArrowDownward className="icon-sm"/>
              </button>
            </div>
            <div className="col-auto">
              <button className="btn btn-with-icon btn-success btn-sm"
                      disabled={!replaceWord || !canChange} onClick={onReplace}>
                {t('components.SearchAndReplace.replace')}
              </button>
            </div>
            <div className="col-2 d-flex justify-content-center align-items-center">
              <div className="text-muted small">
                {currentMatch} / {t('components.SearchAndReplace.countResults', {count: matches})}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-2 text-end">
        <button className="btn btn-with-icon btn-sm" onClick={toggleActive}>
          <Close className="icon-sm"/>
        </button>
      </div>
    </div> :
    <div className="navbar-nav">
      <div className="nav-item">
        <a className="nav-link" href="#" onClick={toggleActive} title={t('components.SearchAndReplace.title')}>
          <FindReplace className="icon" />
        </a>
      </div>
    </div>
}

export default SearchAndReplace