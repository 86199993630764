import {Id} from 'domain/Entity'
import React from 'react'
import {useTranslation} from 'react-i18next'
import EventBus, {EventType} from '../../../EventBus'


interface TranslationDiffToggleProps {
  blockId?: Id
  showEnglish: boolean,
}

const TranslationDiffToggle: React.FC<TranslationDiffToggleProps> = props => {
  const {blockId, showEnglish} = props
  const {t} = useTranslation()

  const onClickToggle = () => {
    const payload = {newState: !showEnglish}
    EventBus.emit(EventType.TOGGLE_SHOW_JA_EN_DIFF, blockId ? {...payload, blockId} : payload)
  }

  return <div className="context-menu-anchor d-flex justify-content-end">
    <div className={`context-menu context-menu-upwards w-auto`}>
      <div className="context-menu-item" onClick={onClickToggle}>
        <span className={`context-menu-item-text`}>
          {showEnglish ?
            t('components.Report.TranslatedReportBlocks.showJA') :
            t('components.Report.TranslatedReportBlocks.showEN')
          }
        </span>
      </div>
    </div>
  </div>

}

export default TranslationDiffToggle
