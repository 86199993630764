import {User} from 'domain/User'
import React, {ReactElement} from 'react'
import {useTranslation} from 'react-i18next'


interface UserDetailsProps {
  user: User
}

const UserDetails: React.FC<UserDetailsProps> = ({user}): ReactElement => {
  const {t} = useTranslation()

  return (<div className="data-list data-list-row data-list-wrappable">
    <dl>
      <dt>{t('models.User.email')}</dt>
      <dd><a href={`mailto:${user.email}`}>{user.email}</a></dd>
    </dl>
    <dl>
      <dt>{t('models.User.phone')}</dt>
      <dd><a href={`tel:${user.phone}`}>{user.phone}</a></dd>
    </dl>
    <dl>
      <dt>{t('models.User.roles')}</dt>
      <dd>{user.roles.map(role => t('models.Role.' + role)).join(', ')}</dd>
    </dl>
  </div>)
}

export default UserDetails