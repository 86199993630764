import {CommentStatus} from 'domain/Comment'
import {isOnlyCompanyRepresentative} from 'domain/User'
import {ReactComponent as PinIcon} from '../../../assets/icons/push_pin.svg'
import {ReactComponent as VisibilityIcon} from '../../../assets/icons/visibility.svg'
import {ReactComponent as VisibilityOffIcon} from '../../../assets/icons/visibility_off.svg'
import React, {FC, useContext, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {UserContext} from '../../../providers/UserProvider'
import MultipleSelect from '../../utils/form/MultipleSelect'
import Select from '../../utils/form/Select'

interface FilterUser {
  _id: string
  name: string
}

interface Filters {
  userIds: string[]
  status: CommentStatus
  isSticky: boolean
  isVisible: boolean
}

interface Props {
  users: FilterUser[]
  userIds: string[]
  onChange: (filters: Filters | ((filters: Filters) => Filters)) => void
}

const CommentFilters: FC<Props> = ({users, onChange, userIds}) => {
  const {t} = useTranslation()
  const [_, setSelectedUserIds] = useState(userIds)
  const [status, setStatus] = useState(CommentStatus.ACTIVE)
  const [isSticky, setIsSticky] = useState(false)
  const [isVisible, setIsVisible] = useState(false)
  const {user} = useContext(UserContext)

  const onChangeFilter = (key: any, value: any) => {
    onChange(prev => ({...prev, [key]: value}))
  }

  return (
    <div className="mb-4 d-flex flex-column gap-2">
      <div className="row">
        <div className="col">
          <MultipleSelect
            htmlId="filterCommentUser"
            name="filterCommentUser"
            placeholder={t('components.Report.comments.CommentsSidebar.placeholder')}
            label={(user: FilterUser) => user.name!}
            collection={users}
            selectedValues={userIds}
            onChange={ids => {
              setSelectedUserIds(ids)
              onChangeFilter('userIds', ids)
            }}
          />
        </div>
      </div>
      <div className="row">
        <div className="d-flex flex-grow-1 gap-1">
          <Select
            id="commentStatus"
            className="form-select cursor-pointer"
            name="commentStatus"
            aria-describedby="commentStatus"
            value={status}
            onChange={status => {
              setStatus(status.target.value as CommentStatus)
              onChangeFilter('status', status.target.value)
            }}
          >
            {Object.values(CommentStatus).map(status => (
              <option key={status} value={status}>
                {t(`models.Comment.status.${status}`)}
              </option>
            ))}
          </Select>
          {!isOnlyCompanyRepresentative(user.roles) && (
            <>
              <button
                className={`filter-comment-btn btn-with-icon ms-1 ${
                  isSticky ? 'btn-purple' : 'btn-outline-purple border-purple'
                }`}
                onClick={() => {
                  onChangeFilter('isSticky', !isSticky)
                  setIsSticky(prev => !prev)
                }}
              >
                <PinIcon className="icon icon-sm m-0" />
              </button>
              <button
                className={`filter-comment-btn btn-with-icon ms-1 ${
                  isVisible ? 'btn-info' : 'btn-outline-info border-info'
                }`}
                onClick={() => {
                  onChangeFilter('isVisible', !isVisible)
                  setIsVisible(prev => !prev)
                }}
              >
                {isVisible ? (
                  <VisibilityIcon className="icon icon-sm m-0" />
                ) : (
                  <VisibilityOffIcon className="icon icon-sm m-0" />
                )}
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default CommentFilters
