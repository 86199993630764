import {UserData} from './providers/UserProvider'

class MSClarityUser {
  login(user: UserData) {
    if (!this.MSClarity) return
    this.MSClarity('identify', user._id)
    this.MSClarity('set', 'userEmail', user.email)
  }

  logout() {
    if (!this.MSClarity) return
    this.MSClarity('identify', null)
    this.MSClarity('set', 'userEmail', 'GUEST')
  }

  private get MSClarity() {
    return (window as any).clarity
  }
}

export const msClarityUser = new MSClarityUser()
