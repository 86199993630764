import {Id} from 'domain/Entity'
import {GlossaryAlternativeTerm, GlossaryTermsForTranslation, GlossaryType} from 'domain/Glossary'
import {Locale} from 'domain/Locale'
import {Block, BlockType, CellType, ListItem, RowTemplate} from 'domain/Report'
import {isOnlyPunctuation, japaneseLanguageRegExp, tokenizeJapaneseTextByDelimiters} from 'domain/utils/textUtils'
import {v4} from 'uuid'
import {fetchGlossary} from '../../../api-clients/glossaryTermClient'
import BlockTermsCollector from './BlockTermsCollector'
import {escapeHtmlTagSymbols} from './translationUtils'
import {applyAllTranslationRules, applyCleanupRules, getTranslationCandidates} from './TranslatorRules'


export interface GlossaryAlternativeTermWithTick extends GlossaryAlternativeTerm {
  tick?: string
}

const TEXT_NODE = 3

class BlockTranslator {
  private readonly untranslated = new Set<string>()
  private tableGlossary: { [key in string]: GlossaryAlternativeTerm[] } = {}
  private textGlossary: { [key in string]: GlossaryAlternativeTerm[] } = {}

  constructor(
    private readonly companyId: Id,
    private readonly targetLocale = Locale.EN) {
  }

  async translate<T extends Block>(block: T): Promise<T> {
    this.untranslated.clear()

    await this.initGlossary(block)
    return this.applyGlossaryTerms(block) as T
  }

  private async initGlossary(block: Block) {
    const {textTerms, tableTerms} = new BlockTermsCollector(block, this.targetLocale).terms()

    const whitelistedTerms = (terms: string[]) => {
      return terms.filter(term => !term.match(/^-?\d+$/)).filter(term => !isOnlyPunctuation(term))
    }

    const [textGlossaryTerms, tableGlossaryTerms] = await Promise.all([
      fetchGlossary(whitelistedTerms(textTerms), this.companyId, GlossaryType.BLOCK),
      fetchGlossary(whitelistedTerms(tableTerms), this.companyId, GlossaryType.TABLE)
    ])

    const mapGlossary = (glossaryTerms: GlossaryTermsForTranslation[]) => {
      return glossaryTerms.reduce((acc, term) => {
        acc[term[Locale.JA]] = term.alternatives
        return acc
      }, {})
    }

    this.textGlossary = mapGlossary(textGlossaryTerms)
    this.tableGlossary = mapGlossary(tableGlossaryTerms)
  }

  private translateHTML(originalHtml: string,
                        preserveBrTag = false,
                        isTableCell = false,
                        glossaryType = GlossaryType.BLOCK) {

    const node = new DOMParser().parseFromString(originalHtml, 'text/html').body as HTMLDivElement
    let nodeHTML = (node.innerHTML ?? '').replaceHtmlSpaces().replace(/ {2,}/g, ' ')

    if (isTableCell) {
      nodeHTML = nodeHTML.replace(/\n/g, '').removeHTMLTags().trim()
    }

    if (preserveBrTag) {
      nodeHTML = nodeHTML.replace(/<br\s*\/?>/gi, '\n')
    }

    nodeHTML = this.applyGlossaryToText(nodeHTML, isTableCell, glossaryType)

    nodeHTML = this.applyRulesToRawText(nodeHTML)

    if (isTableCell) {
      nodeHTML = this.collectUntranslatedTermsFromRawText(nodeHTML)
    }

    if (preserveBrTag) {
      nodeHTML = nodeHTML.replace(/\n/g, '<br>')
    }

    node.innerHTML = nodeHTML
    return node.innerHTML
  }

  isJapanese(term: string) {
    return japaneseLanguageRegExp().exec(term)
  }

  get translationResult(): TranslationResult {
    return {
      untranslatedTerms: Array.from(this.untranslated)
    }
  }

  private applyGlossaryToText(nodeHTML: string, isTableCell: boolean, glossaryType: GlossaryType) {
    const replacements = {}
    const glossary = glossaryType === GlossaryType.BLOCK ? this.textGlossary : this.tableGlossary

    for (const jaTerm of Object.keys(glossary)) {
      const jaTermCandidates = getTranslationCandidates(jaTerm)
      const alternatives = glossary[jaTerm]

      let matchFound = false
      for (const candidate of Array.from(jaTermCandidates)) {
        if (isTableCell ? nodeHTML === candidate : nodeHTML.includes(candidate)) {
          __replace(candidate, alternatives)
          matchFound = true
        }
      }
      if (!matchFound && isTableCell) {
        tokenizeJapaneseTextByDelimiters(nodeHTML).map(term => {
          for (const candidate of Array.from(jaTermCandidates)) {
            if (term === candidate) {
              __replace(candidate, alternatives)
              matchFound = true
              break
            }
          }
        })
      }
    }

    function __replace(jaTerm: string, alternatives: GlossaryAlternativeTerm[]) {
      const escapedJaTerm = jaTerm.replace(/[|\\{}()[\]^$+*?.]/g, '\\$&').replace(/-/g, '\\x2d')

      const replacementId = v4().replace(new RegExp('-', 'g'), '').toString()
      replacements[replacementId] = `<span data-source-term="${escapeHtmlTagSymbols(jaTerm)}" data-candidates="${escapeHtmlTagSymbols(JSON.stringify(alternatives))}" class="translated-term">` +
        alternatives[0][Locale.EN] +
        `</span> `

      nodeHTML = nodeHTML.replace(
        new RegExp(escapedJaTerm, 'g'),
        `${replacementId}`
      )
    }

    for (const replacementId of Object.keys(replacements)) {
      nodeHTML = nodeHTML.replace(
        new RegExp(replacementId, 'g'),
        replacements[replacementId]
      )
    }

    return nodeHTML
  }

  private applyRulesToRawText(nodeHTML: string) {
    const node = new DOMParser().parseFromString(nodeHTML, 'text/html').body
    node.childNodes.forEach(child => {
      if (child.nodeType === TEXT_NODE) {
        child.textContent = applyAllTranslationRules(applyCleanupRules(child.textContent || ''))
      }
    })
    return node.innerHTML
  }

  private collectUntranslatedTermsFromRawText(nodeHTML: string) {
    const node = new DOMParser().parseFromString(nodeHTML, 'text/html').body

    node.childNodes.forEach(child => {
      if (child.nodeType === TEXT_NODE) {
        const fragment = document.createDocumentFragment()
        const newChildContent = tokenizeJapaneseTextByDelimiters(child.textContent || '').map(term => {
          const hasJapaneseWords = this.isJapanese(term)

          if (hasJapaneseWords) {
            this.untranslated.add(term)
            return `<span class="untranslated-term">${term}</span>`
          } else {
            return term
          }
        }).join('')

        const newNodes = new DOMParser().parseFromString(newChildContent, 'text/html').body.childNodes
        for (const node of Array.from(newNodes)) fragment.append(node)
        child.replaceWith(fragment)
      }
    })

    return node.innerHTML
  }

  private applyGlossaryTerms(block: Block): Block {
    const locale = this.targetLocale

    switch (block.type) {
      case BlockType.PARAGRAPH:
      case BlockType.HEADING:
        block.text[locale] = {value: this.translateHTML(block.text[locale]?.value || '')}
        return block
      case BlockType.ALERT:
        block.message[locale] = {value: this.translateHTML(block.message[locale]?.value || '')}
        return block
      case BlockType.LIST:
        const translateListItem = (item: ListItem): ListItem => {
          if (Array.isArray(item)) {
            return item.map(translateListItem)
          } else return this.translateHTML(item)
        }
        block.items[locale] = {value: (block.items[locale]?.value || []).map(translateListItem)}
        return block
      case BlockType.IMAGE:
        block.title[locale] = {value: this.translateHTML(block.title[locale]?.value || '')}
        block.description[locale] = {value: this.translateHTML(block.description[locale]?.value || '')}
        return block
      case BlockType.EXCEL_TABLE:
        block.title[locale] = {value: this.translateHTML(block.title[locale]?.value || '')}
        block.description[locale] = {value: this.translateHTML(block.description[locale]?.value || '')}

        block.content[locale]?.value.forEach((row, rowIdx) => {
          row.forEach((originalCellHtml, colIdx) => {
            if (this.isTextCell(originalCellHtml, rowIdx, colIdx, block.layout[locale]?.template)) {
              block.content[locale]!.value![rowIdx][colIdx] = this.translateHTML(
                block.content[locale]?.value![rowIdx][colIdx] || '', true, true, GlossaryType.TABLE
              )
            }
          })
        })
        return block
      case BlockType.COMMON_TABLE:
        block.title[locale] = this.translateHTML(block.title[locale] || '')
        block.description[locale] = this.translateHTML(block.description[locale] || '')
        block.content[locale]?.forEach(row => {
          row.forEach(cell => {
            cell.value = this.translateHTML(cell.value, true, true, GlossaryType.TABLE)
          })
        })
        return block
      case BlockType.GRID:
        block.grid[locale]?.forEach(row => row.forEach(cell => cell.blocks.forEach(block => {
          this.applyGlossaryTerms(block)
        })))
        return block
      default:
        return block
    }
  }

  private isTextCell(originalCellHtml: string | null, rowIdx: number, colIdx: number, rowTemplates?: RowTemplate[]) {
    const originalText = (originalCellHtml || '').removeHTMLTags()
    const isNumber = !isNaN(+originalText)

    if (!originalText || isNumber) return false

    return rowTemplates ? CellType.TEXT === rowTemplates[rowIdx]?.cells[colIdx]?.type : false
  }
}

interface TranslationResult {
  untranslatedTerms: string[]
}

export default BlockTranslator