import {ObjectId} from 'bson'
import {useContext} from 'react'
import {Toast, ToastContext, ToastType} from '../providers/ToastProvider'


interface ToastState {
  toastContents: Toast[]
  renderSuccessToast: (content: string) => void
  renderErrorToast: (content: string) => void
  renderWarningToast: (content: string) => void
  removeToast: (toast: Toast) => void
}

const useToast = (): ToastState => {
  const {toastContents, setToastContents} = useContext(ToastContext)

  const renderSuccessToast = (content: string) => {
    const toast = {content, type: ToastType.SUCCESS, id: new ObjectId().toString()}
    setToastContents((toasts: Toast[]) => [...toasts, toast])
  }

  const renderErrorToast = (content: string) => {
    const toast = {content, type: ToastType.DANGER, id: new ObjectId().toString()}
    setToastContents((toasts: Toast[]) => [...toasts, toast])
  }

  const renderWarningToast = (content: string) => {
    const toast = {content, type: ToastType.WARNING, id: new ObjectId().toString()}
    setToastContents((toasts: Toast[]) => [...toasts, toast])
  }

  const removeToast = (toast: Toast) => {
    setToastContents((toasts: Toast[]) => toasts.filter(t => t.id !== toast.id))
  }

  return {toastContents, renderSuccessToast, renderErrorToast, renderWarningToast, removeToast}
}

export default useToast