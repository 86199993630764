import {API} from '@editorjs/editorjs'
import {EditorCommonTableBlockCellData, EditorCommonTableBlockData, EditorCommonTableBlockRowData} from 'domain/Editor'
import {AdditionalStyle, BackgroundStyle, Direction, MainTableStyle, TextAlign} from 'domain/Report'
import plusIcon from '../../../assets/inline-svg/add.svg'
import {containsTable, make, onPasteInContentEditable} from '../dom'
import {getStyleClass} from '../excel-table/tableClassNamesHelper'
import {getTableContentFromHTML} from './commonTableHelpers'
import Menu from './Menu'
import Selection from './Selection'
import TableResizer from './TableResizer'

class CommonTableBuilder {
  nodes: {[key in string]: HTMLElement}
  additionalStyles: AdditionalStyle[] = []
  selection: Selection

  constructor(
    private api: API,
    private data: EditorCommonTableBlockData,
    private readonly: boolean,
    private allowChangeLayout: boolean,
    private generateCellData: () => EditorCommonTableBlockCellData
  ) {
    this.nodes = {
      wrapper: make('div'),
      tableWrapper: make('div', [this.CSS.tableWrapper]),
      wrapperWithRowButton: make('div', [this.CSS.wrapperWithRowButton]),
      title: this.buildTextField(data.title, [this.CSS.title], data.titlePlaceholder, 'h5'),
      description: this.buildTextField(data.description, [this.CSS.description], data.descriptionPlaceholder),
      table: this.buildTable(),
      newRowButton: this.newRowButton(),
      newColumnButton: this.newColumnButton()
    }

    this.nodes.wrapperWithRowButton.append(this.nodes.table)
    this.nodes.tableWrapper.append(this.nodes.wrapperWithRowButton)

    if (!this.readonly && this.allowChangeLayout) {
      this.nodes.wrapperWithRowButton.append(this.nodes.newRowButton)
      this.nodes.tableWrapper.append(this.nodes.newColumnButton)
    } else {
      this.nodes.tableWrapper.classList.add(this.CSS.readonly)
    }
    this.nodes.wrapper.append(this.nodes.title, this.nodes.tableWrapper, this.nodes.description)

    this.setTableStyle(this.data.style)
    if (this.data.additionalStyles.length) {
      const uniqueAdditionalStyles = this.data.additionalStyles.uniqueBy(additionalStyle => additionalStyle.toString())
      this.nodes.table.classList.add(uniqueAdditionalStyles.join(' '))
    }
    this.selection = new Selection(this.nodes.table as HTMLTableElement)
  }

  get CSS() {
    return CommonTableBuilder.CSS
  }

  enableSelection() {
    if (!this.readonly && this.allowChangeLayout) {
      this.selection.enable()
    }
  }

  static get CSS() {
    return {
      wrapper: 'editor-grid-wrapper',
      tableWrapper: 'editor-grid-table-wrapper',
      wrapperWithRowButton: 'common-table-wrapper-with-button',
      table: ['table', 'table-report', 'table-bordered', 'common-table', 'm-0'],
      gridButton: 'editor-grid-button',
      verticalGridButton: 'editor-grid-button-vertical',
      horizontalGridButton: 'editor-grid-button-horizontal',
      cell: 'editor-common-table-cell',
      cellContentWrapper: 'common-table-cell-content-wrapper',
      selected: 'selected',
      icon: 'icon',
      disabled: 'disabled',
      input: 'cdx-input',
      title: 'entity-title',
      description: 'entity-description',
      readonly: 'readonly'
    }
  }

  ui(): HTMLElement {
    return this.nodes.wrapper
  }

  get colsNumber(): number {
    return Array.from(this.table.rows[0]?.cells || [])
      .map(cell => cell.colSpan)
      .reduce((res, colSpan) => res + colSpan)
  }

  get rowsNumber(): number {
    return this.table.rows.length
  }

  get table(): HTMLTableElement {
    return this.nodes.table as HTMLTableElement
  }

  get tbody(): HTMLTableSectionElement {
    return this.nodes.table.querySelector('tbody') as HTMLTableSectionElement
  }

  get selectedCells(): HTMLTableCellElement[] {
    return Array.from(this.table.querySelectorAll('.' + this.CSS.selected)) as HTMLTableCellElement[]
  }

  public enableTableResizing() {
    if (!this.readonly) {
      new TableResizer(this.nodes.table as HTMLTableElement).enable()
    }
  }

  private buildTextField(text: string, css: string[], placeholder?: string, readonlyTag = 'div') {
    if (this.readonly) return this.buildReadonlyTextField(text, css, placeholder, readonlyTag)
    const div = make('div', [this.CSS.input, ...css], {
      innerHTML: text,
      contentEditable: !this.readonly,
      tabIndex: 0
    })

    div.addEventListener('paste', onPasteInContentEditable)

    if (placeholder) div.dataset.placeholder = placeholder
    return div
  }

  private buildReadonlyTextField(text: string, css: string[], _?: string, readonlyTag = 'div') {
    return make(this.readonly ? readonlyTag : 'div', css, {
      innerHTML: text,
      contentEditable: false
    })
  }

  public setTableStyle(tableStyle: MainTableStyle) {
    Object.values(MainTableStyle).forEach(value => {
      if (value !== tableStyle) this.nodes.table.classList.remove(getStyleClass(value))
    })
    this.nodes.table.classList.add(getStyleClass(tableStyle))
    this.table.dataset.style = tableStyle
  }

  public setAdditionalStyle(additionalStyle: AdditionalStyle, toggleButton: HTMLElement) {
    if (!this.data.additionalStyles.includes(additionalStyle)) {
      this.data.additionalStyles.push(additionalStyle)
      this.nodes.table.classList.add(additionalStyle)
      toggleButton.classList.remove(this.api.styles.settingsButtonActive)
    } else {
      this.data.additionalStyles = this.data.additionalStyles.filter(existing => existing !== additionalStyle)
      this.nodes.table.classList.remove(additionalStyle)
      toggleButton.classList.add(this.api.styles.settingsButtonActive)
    }
  }

  public destroy() {
    this.selection.destroy()
  }

  private buildTable(): HTMLTableElement {
    const table = make('table', this.CSS.table) as HTMLTableElement
    this.buildTableContent(table)
    return table
  }

  private buildTableContent(table: HTMLTableElement) {
    const tbody = make('tbody')
    table.append(tbody)

    this.data.rows.forEach(row => {
      const tr = this.buildRow(row)
      tbody.append(tr)
    })
  }

  private buildRow(row: EditorCommonTableBlockRowData) {
    const tr = make('tr')

    row.cells.forEach(cell => {
      tr.append(this.buildCell(cell))
    })

    return tr
  }

  private buildCell(cell: EditorCommonTableBlockCellData): HTMLTableCellElement {
    const cellClassNames = [this.CSS.cell]
    if (cell.style) cellClassNames.push(`table-cell-${cell.style.toLowerCase()}`)
    if (cell.align) cellClassNames.push(`text-${cell.align.toLowerCase()}`)

    const td = make('td', cellClassNames, {
      rowSpan: cell.rowSpan || 1,
      colSpan: cell.colSpan || 1
    }) as HTMLTableCellElement

    if (cell.width) {
      td.style.width = cell.width
    }

    const div = make('div', [this.CSS.cellContentWrapper], {
      innerHTML: cell.value,
      contentEditable: !this.readonly
    })

    div.addEventListener('paste', e => this.onCellPaste(e))

    td.append(div)
    if (cell.style) td.dataset.style = cell.style
    if (cell.align) td.dataset.align = cell.align

    td.addEventListener('click', () => {
      if (this.selectedCells.length > 1) {
        this.unselect()
      }
    })

    if (this.allowChangeLayout) td.addEventListener('contextmenu', this.onContextMenu)

    return td
  }

  private addRow(direction?: Direction) {
    const row: EditorCommonTableBlockRowData = {cells: []}

    for (let i = 0; i < (this.colsNumber || 1); i++) {
      row.cells.push({value: ''})
    }

    const tr = this.buildRow(row)
    const selectedCell = this.selectedCells[0]?.parentElement as HTMLTableRowElement

    switch (direction) {
      case Direction.ABOVE:
        selectedCell.before(tr)
        break
      case Direction.BELOW:
        selectedCell.after(tr)
        break
      default:
        this.tbody.append(tr)
    }

    this.selection.reset()
  }

  private addColumn(direction?: Direction) {
    const columnData: EditorCommonTableBlockCellData[] = []

    for (let i = 0; i < this.rowsNumber; i++) {
      columnData.push(this.generateCellData())
    }

    if (this.table.rows.length === 0) {
      this.tbody.insertRow()
    }

    const cellIndex = this.selectedCells[0]?.cellIndex
    Array.from(this.table.rows).forEach((row, rowIdx) => {
      const td = this.buildCell(columnData[rowIdx])

      switch (direction) {
        case Direction.LEFT:
          row.cells[cellIndex].before(td)
          break
        case Direction.RIGHT:
          row.cells[cellIndex].after(td)
          break
        default:
          row.append(td)
      }
    })

    this.selection.reset()
  }

  private unselect() {
    this.selectedCells.forEach(cellNode => {
      cellNode.classList.remove(this.CSS.selected)
    })
  }

  private onContextMenu = (event: Event) => {
    const mouseEvent = event as MouseEvent
    const td = (event.target as HTMLElement).closest('td')!

    if (!td.classList.contains(this.CSS.selected)) {
      return
    }

    event.preventDefault()

    new Menu(
      {x: mouseEvent.clientX + window.scrollX, y: mouseEvent.clientY + window.scrollY},
      this.removeCells.bind(this),
      this.canRemoveSelectedCells.bind(this),
      this.mergeCells.bind(this),
      this.setBackgroundColor.bind(this),
      this.setTextAlign.bind(this),
      this.addRow.bind(this),
      this.addColumn.bind(this),
      this.selectedCells.length
    ).render()
  }

  private canRemoveSelectedCells() {
    const cellsCount = this.table.querySelectorAll('td').length
    return (
      this.selectedCells.length < cellsCount &&
      (this.isFullRowSelected(this.selectedCells) || this.isFullColumnSelected(this.selectedCells))
    )
  }

  private setBackgroundColor(style: BackgroundStyle) {
    for (const cell of this.selectedCells) {
      if (cell.dataset.style) cell.classList.remove(`table-cell-${cell.dataset.style.toLowerCase()}`)
      cell.classList.add(`table-cell-${style.toLowerCase()}`)
      cell.dataset.style = style
    }

    this.unselect()
  }

  private setTextAlign(align: TextAlign) {
    for (const cell of this.selectedCells) {
      if (cell.dataset.align) cell.classList.remove(`text-${cell.dataset.align.toLowerCase()}`)
      cell.classList.add(`text-${align.toLowerCase()}`)
      cell.dataset.align = align
    }

    this.unselect()
  }

  private isFullRowSelected = (cells: HTMLTableCellElement[]) => {
    const rows = Array.from(new Set(cells.map(cell => cell.parentElement))) as HTMLTableRowElement[]

    for (const row of rows) {
      const rowCells = cells.filter(cell => cell.parentElement === row)

      if (rowCells.length === row.cells.length) {
        return true
      }
    }

    return false
  }

  private isFullColumnSelected = (cells: HTMLTableCellElement[]) => {
    const table = this.nodes.table as HTMLTableElement

    const minRowIdx = Math.min(...cells.map(c => (c.parentElement as HTMLTableRowElement).rowIndex))
    const maxRowIdx = Math.max(...cells.map(c => (c.parentElement as HTMLTableRowElement).rowIndex + c.rowSpan - 1))

    return minRowIdx === 0 && maxRowIdx >= table.rows.length - 1
  }

  private onCellPaste(event: ClipboardEvent) {
    const html: string | undefined = event.clipboardData?.getData('text/html')
    if (!html) return

    const res = containsTable(html)
    if (res) {
      event.preventDefault()
      event.stopImmediatePropagation()
      this.onTablePaste(html)
    } else {
      onPasteInContentEditable(event)
    }
  }

  private onTablePaste(html: string) {
    this.data.rows = getTableContentFromHTML(html)
    this.table.innerHTML = ''
    this.buildTableContent(this.table)
  }

  private removeCells() {
    for (const cell of this.selectedCells) {
      cell.remove()
    }

    for (const row of Array.from(this.table.rows)) {
      if (row.cells.length === 0) row.remove()
    }
  }

  private mergeCells() {
    const resultCell = this.selectedCells[0] as HTMLTableCellElement

    if (!resultCell) {
      return
    }

    const resultCellRowIdx = (resultCell.parentElement as HTMLTableRowElement).rowIndex
    const resultInnerHTML = this.selectedCells
      .map(cell => {
        return cell.querySelector('.' + this.CSS.cellContentWrapper)!.innerHTML
      })
      .join(' ')

    for (const cell of this.selectedCells) {
      if (cell === resultCell) continue
      const rowIdx = (cell.parentElement as HTMLTableRowElement).rowIndex

      if (rowIdx === resultCellRowIdx) {
        resultCell.colSpan += cell.colSpan
      } else {
        resultCell.rowSpan = rowIdx + cell.rowSpan - resultCellRowIdx
      }

      cell.remove()
    }

    resultCell.querySelector('.' + this.CSS.cellContentWrapper)!.innerHTML = resultInnerHTML
    this.unselect()
    this.selection.reset()
  }

  private newRowButton(): HTMLElement {
    const button = make('div', [this.CSS.gridButton, this.CSS.horizontalGridButton])
    button.appendChild(this.plusIcon())

    button.addEventListener('click', () => {
      this.addRow()
    })

    return button
  }

  private newColumnButton(): HTMLElement {
    const button = make('div', [this.CSS.gridButton, this.CSS.verticalGridButton])
    button.appendChild(this.plusIcon())

    button.addEventListener('click', () => {
      this.addColumn()
    })

    return button
  }

  private plusIcon(): HTMLElement {
    const icon = make('span', [this.CSS.icon])
    icon.innerHTML = plusIcon
    return icon
  }
}

export default CommonTableBuilder
