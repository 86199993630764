import classnames from 'classnames'
import React, {ReactElement} from 'react'
import {useTranslation} from 'react-i18next'
import {Link} from 'react-router-dom'
import {calculateProgress, expectedDaysOverdue, taskLocale} from '../../utils/projectUtils'
import CompanyButton from '../companies/CompanyButton'
import TaskAssignee from '../project/TaskAssignee'
import DeadlineOverdueBadge from './DeadlineOverdueBadge'
import {ProjectAggregate} from 'domain/ProjectAggregate'

interface Props {
  project: ProjectAggregate
  showProjectType?: boolean
}

const ProjectCard: React.FC<Props> = ({project, showProjectType}): ReactElement => {
  const {t} = useTranslation()
  const daysUntilDeadline = project.activeTask ? expectedDaysOverdue(project, taskLocale(project.activeTask)) : 0

  const progressClassNames = {
    'progress-bar progress-bar-animated': true,
    'bg-danger': daysUntilDeadline < 0,
    'bg-success': project.isFinished
  }

  return (
    <Link className="card card-clickable mb-2" to={`/projects/${project._id}`}>
      <div className="card-body p-2">
        <div className="d-flex justify-content-between">
          <div className="small fw-semibold mb-1">{project.title}</div>
          <div className="small text-right small text-nowrap">
            {project.activeTask && <DeadlineOverdueBadge locale={taskLocale(project.activeTask)} project={project} />}
          </div>
        </div>

        <div className="d-flex align-items-center flex-wrap gap-1">
          <CompanyButton company={project.company} buttonClass="btn-outline-secondary" />

          {showProjectType && (
            <div className="btn btn-xs btn-outline-secondary btn-with-icon">
              <span>{t(`models.ProjectType.${project.type}`)}</span>
            </div>
          )}

          {project.activeTask && <TaskAssignee task={project.activeTask} buttonClass="" />}
        </div>

        <div className="progress mb-n2 mx-n2 mt-2">
          <div
            role="progressbar"
            className={classnames(progressClassNames)}
            style={{width: `${calculateProgress(project)}%`}}
          />
        </div>
      </div>
    </Link>
  )
}

export default ProjectCard
