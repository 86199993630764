import {Id} from 'domain/Entity'
import React, {useMemo} from 'react'
import SelectSearch from 'react-dropdown-select'
import {useTranslation} from 'react-i18next'

type Item = any & {_id: Id}

type Props = {
  collection: Item[]
  selectedValues?: string[]
  onChange: (ids: string[]) => void
  htmlId: string
  name: string
  label: (item: any) => string
  placeholder: string
}

export type SelectOption = {
  label: string
  value: string
}

const MultipleSelect: React.FC<Props> = props => {
  const {collection, onChange, htmlId, name, placeholder, label} = props
  const selectedValues = useMemo(() => props.selectedValues || [], [props.selectedValues])
  const onSelectChange = (options: SelectOption[]) => onChange(options.map(option => option.value))
  const {t} = useTranslation()

  const selectOptions = collection.map(item => ({
    label: label(item),
    value: item._id as string
  }))

  const selectedValuesWithLabels = selectOptions.filter(option => selectedValues.includes(option.value))

  return <SelectSearch options={selectOptions} multi={true} required={false}
                       additionalProps={{id: htmlId}} name={name}
                       onChange={onSelectChange} searchable={true}
                       values={selectedValuesWithLabels} noDataLabel={t('components.MultipleSelect.noMatches')}
                       placeholder={placeholder}/>
}

export default MultipleSelect