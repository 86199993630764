import {Project, ProjectType} from 'domain/Project'
import React, {ReactElement, useContext} from 'react'
import {ReportContext} from '../../providers/ReportProvider'


interface ShowIfProps {
  children: ReactElement
  rule: (project: Project) => boolean
  project?: Project
}

export const notNewsUpdateType = (project: Project) => {
  return project.type !== ProjectType.NEWS_UPDATE
}

const ShowIf: React.FC<ShowIfProps> = props => {
  const {children, rule, project: projectProps} = props
  const {project} = useContext(ReportContext)

  const currentProject = project ?? projectProps
  if (currentProject && rule(currentProject)) {
    return children
  } else {
    return <></>
  }
}

export default ShowIf