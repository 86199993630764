import {API} from '@editorjs/editorjs'
import {EditorGridBlockData, EditorGridCellData, EditorGridRowData} from 'domain/Editor'
import plusIcon from '../../../assets/inline-svg/add.svg'
import dotsIcon from '../../../assets/inline-svg/dots.svg'
import {make, onPasteInContentEditable} from '../dom'
import Menu from './Menu'

const MAX_COLUMNS = 4

class GridBuilder {
  selectedRow: number | null = null
  selectedColumn: number | null = null
  grid: EditorGridRowData[]
  nodes: {[key in string]: HTMLElement}

  constructor(
    private api: API,
    private data: EditorGridBlockData,
    private readonly: boolean,
    private generateCellData: () => EditorGridCellData,
    private _onRowAdded: (idx: number, data: EditorGridRowData) => void,
    private _onColumnAdded: (idx: number, data: EditorGridCellData[]) => void,
    private _onRowDelete: (ids: number) => void,
    private _onColumnDelete: (ids: number) => void
  ) {
    this.grid = data.grid

    this.nodes = {
      wrapper: make('div'),
      tableWrapper: make('div', [this.CSS.tableWrapper, this.data.border ? this.CSS.tableBordered : null]),
      wrapperWithRowButton: make('div', [this.CSS.wrapperWithRowButton]),
      title: this.buildTextField(data.title, [this.CSS.title], data.titlePlaceholder, 'h5'),
      description: this.buildTextField(data.description, [this.CSS.description], data.descriptionPlaceholder),
      table: this.buildTable(),
      newRowButton: this.newRowButton(),
      newColumnButton: this.newColumnButton()
    }

    this.nodes.wrapperWithRowButton.append(this.nodes.table)
    this.nodes.tableWrapper.append(this.nodes.wrapperWithRowButton)

    if (!this.readonly) {
      this.nodes.wrapperWithRowButton.append(this.nodes.newRowButton)
      this.nodes.tableWrapper.append(this.nodes.newColumnButton)
    } else {
      this.nodes.tableWrapper.classList.add(this.CSS.readonly)
    }

    this.nodes.wrapper.append(this.nodes.title, this.nodes.tableWrapper, this.nodes.description)

    this.nodes.wrapper.addEventListener('keydown', this.preventEventPropagationOutsideOfGrid)
    this.updateAddColumnButtonState()
  }

  private preventEventPropagationOutsideOfGrid(event: KeyboardEvent) {
    const isBlockCreationShortcut = event.altKey && event.shiftKey
    if (isBlockCreationShortcut) {
      event.stopPropagation()
    }
  }

  get CSS() {
    return GridBuilder.CSS
  }

  static get CSS() {
    return {
      wrapper: 'editor-grid-wrapper',
      tableWrapper: 'editor-grid-table-wrapper',
      wrapperWithRowButton: 'editor-grid-wrapper-with-button',
      table: 'editor-grid',
      tableFixed: 'editor-grid-fixed',
      tableBordered: 'editor-grid-bordered',
      gridButton: 'editor-grid-button',
      verticalGridButton: 'editor-grid-button-vertical',
      horizontalGridButton: 'editor-grid-button-horizontal',
      cell: 'editor-grid-cell',
      selected: 'selected',
      columnHandle: 'editor-grid-column-handle',
      rowHandle: 'editor-grid-row-handle',
      cellContentWrapper: 'cell-content-wrapper',
      icon: 'icon',
      disabled: 'disabled',
      input: 'cdx-input',
      title: 'entity-title',
      description: 'entity-description',
      readonly: 'readonly'
    }
  }

  ui(): HTMLElement {
    return this.nodes.wrapper
  }

  get colsNumber(): number {
    return this.grid[0]?.length || 0
  }

  get rowsNumber(): number {
    return this.grid.length
  }

  get table(): HTMLTableElement {
    return this.nodes.table as HTMLTableElement
  }

  private buildTextField(text: string, css: string[], placeholder?: string, readonlyTag = 'div') {
    if (this.readonly) return this.buildReadonlyTextField(text, css, placeholder, readonlyTag)

    const div = make('div', [this.CSS.input, ...css], {
      innerHTML: text,
      contentEditable: true,
      tabIndex: 0
    })

    div.addEventListener('paste', onPasteInContentEditable)

    if (placeholder) div.dataset.placeholder = placeholder
    return div
  }

  private buildReadonlyTextField(text: string, css: string[], _?: string, readonlyTag = 'div') {
    return make(this.readonly ? readonlyTag : 'div', css, {
      innerHTML: text,
      contentEditable: false
    })
  }

  private buildTable(): HTMLTableElement {
    const table = make('table', [this.CSS.table, this.data.equalColumnWidth ? this.CSS.tableFixed : null])

    this.grid.forEach(row => {
      const tr = make('tr') as HTMLTableRowElement
      table.append(tr)
      this.buildRow(tr, row)
    })

    return table as HTMLTableElement
  }

  private buildRow(tr: HTMLTableRowElement, row: EditorGridRowData) {
    row.forEach(cell => {
      const td = make('td', [this.CSS.cell]) as HTMLTableCellElement
      tr.appendChild(td)
      this.buildCell(tr, td, cell)
    })
  }

  private buildCell(tr: HTMLTableRowElement, td: HTMLTableCellElement, cell: EditorGridCellData) {
    td.append(make('div', [this.CSS.cellContentWrapper], {id: cell.id}))
    this.ensureRowColumnHandlesInCell(tr, td)
  }

  private ensureRowColumnHandlesInCell(tr: HTMLTableRowElement, td: HTMLTableCellElement): void {
    if (this.readonly) {
      return
    }

    const firstRow = tr.rowIndex === 0
    const firstColumn = td.cellIndex === 0

    if (firstRow) {
      td.prepend(this.columnsSettings(td))
    }

    if (firstColumn) {
      td.prepend(this.rowSettings(tr))
    }
  }

  private ensureRowColumnHandles() {
    Array.from(this.table.rows).forEach(tr => {
      Array.from(tr.cells).forEach(td => {
        this.ensureRowColumnHandlesInCell(tr, td)
      })
    })
  }

  private addRow() {
    const rowIdx = this.rowsNumber
    const rowData: EditorGridCellData[] = []

    for (let i = 0; i < (this.colsNumber || 1); i++) {
      rowData.push(this.generateCellData())
    }

    const tr = make('tr') as HTMLTableRowElement
    this.table.append(tr)
    this.buildRow(tr, rowData)

    this.grid.splice(rowIdx, 0, rowData)
    this._onRowAdded(rowIdx, rowData)
  }

  private addColumn() {
    const columnIdx = this.colsNumber
    const columnData: EditorGridCellData[] = []

    if (this.grid.length === 0) {
      this.grid.push([])
    }

    for (let i = 0; i < this.rowsNumber; i++) {
      columnData.push(this.generateCellData())
    }

    if (this.table.rows.length === 0) {
      this.table.insertRow()
    }

    Array.from(this.table.rows).forEach((tr, rowIdx) => {
      const td = make('td', [this.CSS.cell]) as HTMLTableCellElement
      tr.appendChild(td)
      this.buildCell(tr, td, columnData[rowIdx])
    })

    this.grid.forEach((row, i) => {
      row.splice(columnIdx, 0, columnData[i])
    })

    this.updateAddColumnButtonState()
    this._onColumnAdded(columnIdx, columnData)
  }

  private deleteRow(idx: number): void {
    this.table.deleteRow(idx)
    this.ensureRowColumnHandles()

    this.grid.splice(idx, 1)
    this._onRowDelete(idx)
  }

  private deleteColumn(idx: number): void {
    Array.from(this.table.rows).forEach(row => {
      row.deleteCell(idx)
    })

    for (let i = this.table.rows.length - 1; i >= 0; i--) {
      if (this.table.rows[i].cells.length === 0) {
        this.table.deleteRow(i)
      }
    }

    this.ensureRowColumnHandles()

    this.grid.forEach(row => {
      row.splice(idx, 1)
    })

    for (let i = this.grid.length - 1; i >= 0; i--) {
      if (this.grid[i].length === 0) {
        this.grid.splice(i, 1)
      }
    }

    this.updateAddColumnButtonState()
    this._onColumnDelete(idx)
  }

  private updateAddColumnButtonState() {
    const totalColumns = this.grid[0]?.length || 0
    this.nodes.newColumnButton.classList.toggle(this.CSS.disabled, totalColumns >= MAX_COLUMNS)
  }

  private selectRow(index: number) {
    this.unselect()
    this.selectedRow = index

    this.table.querySelectorAll(`tr:nth-child(${index + 1}) td`).forEach(cell => {
      cell.classList.add(this.CSS.selected)
    })
  }

  private selectColumn(index: number) {
    this.unselect()
    this.selectedColumn = index

    this.table.querySelectorAll(`td:nth-child(${index + 1})`).forEach(cell => {
      cell.classList.add(this.CSS.selected)
    })
  }

  unselect() {
    this.selectedColumn = null
    this.selectedRow = null
    this.table.querySelectorAll('.' + this.CSS.selected).forEach(elem => elem.classList.remove(this.CSS.selected))
  }

  private rowSettings(tr: HTMLTableRowElement) {
    const wrapper = make('div', [this.CSS.rowHandle])
    const settings = make('div', [this.CSS.gridButton, this.CSS.verticalGridButton])
    settings.appendChild(this.settingsIcon())

    const menu = new Menu(
      this.api,
      settings,
      () => {
        this.deleteRow(tr.rowIndex)
      },
      () => this.grid.length > 1
    )

    settings.addEventListener('click', () => {
      this.toggleRowSelection(tr.rowIndex)
      this.toggleSettingsMenu(menu)
    })

    wrapper.append(settings)
    return wrapper
  }

  private columnsSettings(td: HTMLTableCellElement) {
    const wrapper = make('div', [this.CSS.columnHandle])
    const settings = make('div', [this.CSS.gridButton, this.CSS.horizontalGridButton])
    settings.appendChild(this.settingsIcon())

    const menu = new Menu(
      this.api,
      settings,
      () => {
        this.deleteColumn(td.cellIndex)
      },
      () => this.grid[0].length > 1
    )

    settings.addEventListener('click', () => {
      this.toggleColumnSelection(td.cellIndex)
      this.toggleSettingsMenu(menu)
    })

    wrapper.append(settings)
    return wrapper
  }

  private toggleSettingsMenu(menu: Menu) {
    if (menu.isRendered()) {
      menu.destroy()
    } else {
      menu.render()
    }
  }

  private toggleColumnSelection(column: number) {
    if (this.selectedColumn === column) {
      this.unselect()
    } else {
      this.selectColumn(column)
    }
  }

  private toggleRowSelection(row: number) {
    if (this.selectedRow === row) {
      this.unselect()
    } else {
      this.selectRow(row)
    }
  }

  private newRowButton(): HTMLElement {
    const button = make('div', [this.CSS.gridButton, this.CSS.horizontalGridButton])
    button.appendChild(this.plusIcon())

    button.addEventListener('click', () => {
      this.addRow()
    })

    return button
  }

  private newColumnButton(): HTMLElement {
    const button = make('div', [this.CSS.gridButton, this.CSS.verticalGridButton])
    button.appendChild(this.plusIcon())

    button.addEventListener('click', () => {
      this.addColumn()
    })

    return button
  }

  private settingsIcon(): HTMLElement {
    const icon = make('span', [this.CSS.icon])
    icon.innerHTML = dotsIcon
    return icon
  }

  private plusIcon(): HTMLElement {
    const icon = make('span', [this.CSS.icon])
    icon.innerHTML = plusIcon
    return icon
  }
}

export default GridBuilder