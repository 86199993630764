import {Id} from 'domain/Entity'
import {ReactComponent as DoneIcon} from '../../assets/icons/done.svg'
import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'
import {copyJaToEn} from '../../api-clients/reportClient'
import useModal from '../../hooks/useModal'
import useToast from '../../hooks/useToast'
import ButtonWithLoaderAndIcon from '../utils/form/ButtonWithLoaderAndIcon'
import ModalTemplate from '../utils/ModalTemplate'


type Props = {
  projectId: Id
}

const ConfirmCopyJaToEn: React.FC<Props> = props => {
  const {projectId} = props
  const {t} = useTranslation()
  const {renderErrorToast, renderSuccessToast} = useToast()
  const {closeModal} = useModal()
  const [loading, setLoading] = useState(false)

  const onInitiate = (event: React.MouseEvent) => {
    event.preventDefault()
    setLoading(true)
    copyJaToEn(projectId)
      .then(() => {
        closeModal()
        renderSuccessToast(t('components.ConfirmCopyJaToEn.successful'))
      })
      .catch(() => {
        renderErrorToast(t('components.ConfirmCopyJaToEn.error'))
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (<ModalTemplate title={t('components.ConfirmCopyJaToEn.title')}>
    <div className="modal-body">
      <div>
        {t('components.ConfirmCopyJaToEn.description')}
      </div>
    </div>

    <div className="modal-footer">
      <ButtonWithLoaderAndIcon loading={loading} className="btn btn-success" onClick={onInitiate}
                               icon={<DoneIcon className="icon" />}>
        <span>{t('components.ConfirmCopyJaToEn.copy')}</span>
      </ButtonWithLoaderAndIcon>
    </div>
  </ModalTemplate>)
}

export default ConfirmCopyJaToEn