import React, {ReactElement} from 'react'

type Props = React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> & {
  loading: boolean
  icon: ReactElement
}

const ButtonWithLoaderAndIcon: React.FC<Props> = props => {
  const {loading, icon, children, className, ...rest} = props
  return (
    <button className={`btn btn-with-icon ${className} ${loading ? 'disabled' : ''}`} {...rest}>
      <>
        {children}
        {loading ? <span className="spinner-border spinner-border-sm ms-2" role="status" aria-hidden="true" /> : icon}
      </>
    </button>
  )
}

export default ButtonWithLoaderAndIcon
