import {COMPANY_FILE_HEADER_MAP, COMPANY_FILE_HEADERS, CompanyRow} from 'domain/Company'
import React, {useEffect, useRef, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {getCompaniesBulkTemplateBlob} from '../../api-clients/companyClient'
import {ReactComponent as DownloadIcon} from '../../assets/icons/cloud_download.svg'
import {ReactComponent as UploadFileIcon} from '../../assets/icons/upload_file.svg'
import {parseCsvFile} from '../../utils/fileUtils'
import ButtonWithLoaderAndIcon from '../utils/form/ButtonWithLoaderAndIcon'
import ModalTemplate from '../utils/ModalTemplate'

type Props = {
  onUploaded: (companies: CompanyRow[]) => void
}

const UploadCompaniesForm: React.FC<Props> = ({onUploaded}) => {
  const {t} = useTranslation()
  const [file, setFile] = useState<File | null>(null)
  const [loading, setLoading] = useState<boolean>(false)
  const [errors, setErrors] = useState<{error?: string}>({})
  const templateFileName = 'companies_bulk_upload_template.csv'
  const [blobUrl, setBlobUrl] = useState<string | null>(null)
  const downloadTemplateRef = useRef<HTMLAnchorElement>(null)

  const onFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return
    setFile(e.target.files![0])
  }

  const onUpload = (e: React.FormEvent) => {
    e.preventDefault()
    if (!file) return
    setLoading(true)
    setErrors({})

    parseCsvFile(file, COMPANY_FILE_HEADERS, COMPANY_FILE_HEADER_MAP)
      .then((companies: CompanyRow[]) => {
        onUploaded(companies)
      })
      .catch(errors => {
        setErrors(errors)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const onDownload = async (e: React.FormEvent) => {
    if (blobUrl) {
      return
    }

    e.preventDefault()

    getCompaniesBulkTemplateBlob().then(blob => {
      const url = URL.createObjectURL(blob)
      setBlobUrl(url)
    })
  }

  useEffect(() => {
    if (blobUrl) {
      downloadTemplateRef.current!.click()
    }
  }, [blobUrl])

  return (
    <ModalTemplate title={t('components.Companies.Companies.uploadCompanies')} size="md">
      <form onSubmit={onUpload}>
        <div className="modal-body">
          <ol>
            <li>
              <strong>{t('components.UploadCompaniesForm.description.upload')}</strong>
            </li>
            <li>{t('components.UploadCompaniesForm.description.check')}</li>
            <li>{t('components.UploadCompaniesForm.description.confirm')}</li>
          </ol>
          <input type="file" accept=".csv" className="form-control" onChange={onFileChange} required={true} />
          {errors.error && (
            <div className="invalid-feedback d-block">{t(`components.UploadCompaniesForm.errors.${errors.error}`)}</div>
          )}
        </div>
        <div className="modal-footer">
          <div className="d-flex w-100 flex-row justify-content-between">
            <a
              className={'btn btn-with-icon btn-outline-success'}
              onClick={onDownload}
              title={t('components.UploadCompaniesForm.downloadTemplate')}
              download={templateFileName}
              href={blobUrl || ''}
              ref={downloadTemplateRef}
            >
              <span>{t('components.UploadCompaniesForm.downloadTemplate')}</span>
              <DownloadIcon className="icon icon-sm" />
            </a>

            <ButtonWithLoaderAndIcon
              icon={<UploadFileIcon className="icon icon-sm" />}
              loading={loading}
              className="btn btn-success"
              type="submit"
            >
              {t('components.UploadCompaniesForm.upload')}
            </ButtonWithLoaderAndIcon>
          </div>
        </div>
      </form>
    </ModalTemplate>
  )
}

export default UploadCompaniesForm
