import {Locale} from 'domain/Locale'
import {ProjectType} from 'domain/Project'
import {BlockType, HeadingBlock} from 'domain/Report'
import React, {useContext, useEffect, useRef, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {v4} from 'uuid'
import {updateProjectReportTitle} from '../../../api-clients/projectClient'
import useToast from '../../../hooks/useToast'
import {ReportContext} from '../../../providers/ReportProvider'
import Heading from '../view/Heading'

const NewsUpdatesHeader: React.FC = () => {
  const {project, setEnglishReportTitle} = useContext(ReportContext)
  const {renderErrorToast} = useToast()
  const {t} = useTranslation()
  const [englishHeading, setEnglishHeading] = useState<string | undefined>(project?.reportTitle?.en)
  const [originalHeadingId] = useState(v4())
  const englishHeadingRef = useRef<HTMLHeadingElement>(null)

  useEffect(() => {
    const heading = englishHeadingRef.current
    if (!heading) {
      return
    }

    heading.innerText = block.text.en?.value || ''
  }, [englishHeadingRef])

  const block: HeadingBlock = {
    id: originalHeadingId,
    type: BlockType.HEADING,
    level: 1,
    text: {ja: {value: project?.reportTitle?.ja ?? ''}, en: {value: project?.reportTitle?.en ?? ''}}
  }

  const onKeyDown = (e: React.KeyboardEvent) => {
    switch (e.code) {
      case 'Enter':
        e.preventDefault()
        break
    }
    setTimeout(async () => {
      const newContent = (e.target as HTMLElement).textContent ?? ''
      if (englishHeading !== newContent) {
        setEnglishHeading(newContent)
        try {
          await updateProjectReportTitle(project?._id, newContent)
          setEnglishReportTitle(newContent)
        } catch (_error) {
          renderErrorToast(t('errors.unexpected'))
        }
      }
    }, 0)
  }

  return project?.type === ProjectType.NEWS_UPDATE ? (
    <div className="report-blocks-row">
      <div className="report-edit report-edit-side-by-side">
        <div className="row flex-nowrap">
          <div className="col report-edit-side-by-side-first context-menu-hover-show-trigger">
            <Heading block={block} locale={Locale.JA} />
          </div>
          <div className="col report-edit-side-by-side-last">
            <div className="report-block report-editor-activate">
              <div className="ce-block ce-block--focused">
                <div className="ce-block__content">
                  <h1 className="ce-header" contentEditable="true" onKeyDown={onKeyDown} ref={englishHeadingRef} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <></>
  )
}

export default NewsUpdatesHeader
