import {ObjectId} from 'bson'
import {Entity, Id} from 'domain/Entity'
import {Locale} from 'domain/Locale'
import {Project, ProjectStage, ProjectTimeline} from 'domain/Project'
import {ProjectMilestone} from 'domain/Report'
import {Role, User} from 'domain/User'
import {Company} from 'domain/Company'

interface BaseTask extends Entity {
  status: TaskStatus
  userId?: Id | null
}

export interface ProjectTask extends BaseTask {
  stage: ProjectStage
  projectId: Id
  timeline: ProjectTimeline
  roles: Role[]
  milestone?: ProjectMilestone
  startDate: Date
  endDate: Date
  reschedules?: ProjectTaskReschedule[]
  assistantUsersIds: Id[]
}

export interface InterviewProjectTask extends ProjectTask {
  interviewDate: string
}

export interface PublishingProjectTask extends ProjectTask {
  isGeneratingFiles?: {[key in Locale]?: boolean}
}

export interface ProjectTaskReschedule extends Entity {
  previousEndDate: Date
  newEndDate: Date
  reason?: string
  userId: Id
}

export enum TaskStatus {
  SCHEDULED = 'SCHEDULED',
  IN_PROGRESS = 'IN_PROGRESS',
  FINISHED = 'FINISHED'
}

export type Task = ProjectTask | InterviewProjectTask | PublishingProjectTask

export type TaskWithUser = Task & {
  user?: User
}

export type ProjectTaskWithUsers = ProjectTask & {
  user?: User
  assistantUsers?: User[]
}

export type AggregatedTask = Task & {
  project: Pick<Project, '_id' | 'title' | 'type' | 'companyId' | 'analystId' | 'assistantAnalystIds'>
  company: Pick<Company, '_id' | 'shortName' | 'name' | 'tick' | 'phone' | 'website' | 'financialYearEnd'>
}

export type RescheduledTask = {
  _id: ObjectId
  stage: ProjectStage
  users: User[]
  endDate: Date
  startDate: Date
  previousStartDate: Date
  previousEndDate: Date
}

export type TaskUserAssignmentPayload = {
  userId?: Id | null
  assistantUsersIds?: Id[]
}

export type TaskDates = Pick<ProjectTask, '_id' | 'startDate' | 'endDate'>