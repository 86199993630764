import {CellTemplate} from 'domain/Report'
import {CSSProperties} from 'react'


const cellStyles = (template: CellTemplate): CSSProperties => {

  const styles = {
    textAlign: template.textAlign,
    fontWeight: template.fontWeight,
    fontStyle: template.fontStyle,
    textDecoration: template.textDecoration,
    textDecorationStyle: template.textDecorationStyle,
    verticalAlign: template.verticalAlign,
    width: template.width || 'auto'
  }

  return Object.fromEntries(Object.entries(styles).filter(([_, value]) => value))
}

export {cellStyles}
