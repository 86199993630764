const throttle = (func: (...args: any[]) => void, limit: number): (() => void) => {
  let inThrottle: boolean

  return (...args: any[]): any => {
    const context = this

    if (!inThrottle) {
      inThrottle = true
      func.apply(context, args)
      setTimeout(() => {
        inThrottle = false
        func.apply(context, args)
      }, limit)
    }
  }
}

export default throttle