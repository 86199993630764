import {EditorCloneBlock} from 'domain/Editor'
import {Locale} from 'domain/Locale'
import {CloneBlock} from 'domain/Report'
import Converter from './Converter'


class CloneConverter extends Converter<CloneBlock, EditorCloneBlock> {
  toEditor(block: CloneBlock, locale: Locale = Locale.JA): EditorCloneBlock {
    const {id, type, sourceId, ...other} = block
    return {id, type, data: {sourceId, ...other}}
  }

  fromEditor(editorBlock: EditorCloneBlock, locale: Locale = Locale.JA) {
    const {id, type, data} = editorBlock
    const {sourceId, ...other} = data

    return {id, type, sourceId, ...other}
  }
}

export default new CloneConverter()