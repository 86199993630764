import {Permission} from 'domain/Permission'
import {TranslationRequest, TranslationRequestStatus} from 'domain/TranslationRequest'
import {User} from 'domain/User'
import React, {ReactElement, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {getUser} from '../../../api-clients/userClient'
import useModal from '../../../hooks/useModal'
import {calculateProgress} from '../../../utils/projectUtils'
import Protected from '../../utils/Protected'
import TranslatorAssignmentForm from '../TranslatorAssignmentForm'
import ProjectCard from './ProjectCard'
import {ProjectAggregate} from 'domain/ProjectAggregate'

interface CardProps {
  project: ProjectAggregate
}

const UnassignedTranslationCard: React.FC<CardProps> = ({project}): ReactElement => {
  const {t} = useTranslation()
  const {showModal} = useModal()
  const lastTranslationRequest = project.translationRequests.last()
  const [rejectTranslator, setRejectTranslator] = useState<User | null>()
  const [cancelUser, setCancelUser] = useState<User | null>()

  useEffect(() => {
    if (lastTranslationRequest?.translatorId) {
      getUser(lastTranslationRequest.translatorId, true).then(setRejectTranslator)
    }
  }, [lastTranslationRequest?.translatorId])

  useEffect(() => {
    if (lastTranslationRequest?.cancelUserId) {
      getUser(lastTranslationRequest.cancelUserId, true).then(setCancelUser)
    }
  }, [lastTranslationRequest?.cancelUserId])

  const onAssigned = (request: TranslationRequest) => {
    project.translationRequests.push(request)
  }

  const openTranslationAssignmentForm = (e: React.MouseEvent) => {
    e.preventDefault()
    e.stopPropagation()
    showModal(<TranslatorAssignmentForm project={project} onAssigned={onAssigned} />)
  }

  return (
    <ProjectCard project={project}>
      <Protected permission={Permission.Project.ASSIGN_TRANSLATOR}>
        <>
          {lastTranslationRequest?.status === TranslationRequestStatus.REJECTED && (
            <>
              {cancelUser ? (
                <div className="alert alert-warning mt-2 p-2 mb-0 small">
                  <div className="fw-semibold">
                    {t('components.UnassignedTranslationCard.canceledBy')}&nbsp;
                    {cancelUser.name}
                  </div>
                  <div>{lastTranslationRequest.cancelReply}</div>
                </div>
              ) : (
                <div className="alert alert-danger mt-2 p-2 mb-0 small">
                  <div className="fw-semibold">
                    {t('components.UnassignedTranslationCard.rejectedBy')}&nbsp;
                    {rejectTranslator?.name}
                  </div>
                  <div>{lastTranslationRequest.reply}</div>
                </div>
              )}
            </>
          )}
          <div className="d-flex mt-2">
            <button className="btn btn-sm btn-outline-primary flex-grow-1" onClick={openTranslationAssignmentForm}>
              <span>{t('components.UnassignedTranslationCard.assign')}</span>
            </button>
          </div>
        </>
      </Protected>
      <div className="progress mb-n2 mx-n2 mt-2">
        <div
          role="progressbar"
          className="progress-bar progress-bar-animated"
          style={{width: `${calculateProgress(project)}%`}}
        />
      </div>
    </ProjectCard>
  )
}

export default UnassignedTranslationCard
