import {Id} from 'domain/Entity'
import {Project} from 'domain/Project'
import {ProjectTaskWithUsers} from 'domain/Task'
import {User} from 'domain/User'
import {ReactComponent as DoneIcon} from '../../assets/icons/done.svg'
import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {assignUsersToProjectTask} from '../../api-clients/projectTaskClient'
import {getAnalysts} from '../../api-clients/userClient'
import useModal from '../../hooks/useModal'
import MultipleSelect from '../utils/form/MultipleSelect'
import ModalTemplate from '../utils/ModalTemplate'

type Props = {
  project: Project
  tasks: ProjectTaskWithUsers[]
  onUpdate: () => void
}

const SetProjectReviewers: React.FC<Props> = ({project, tasks, onUpdate}) => {
  const initialReviewersIds = tasks.flatMap(t => t.assistantUsersIds || []).subtract(project.assistantAnalystIds || [])
  const [userIds, setUserIds] = useState<Id[]>(initialReviewersIds)
  const [analysts, setAnalysts] = useState<User[]>([])
  const {t} = useTranslation()
  const {closeModal} = useModal()

  useEffect(() => {
    getAnalysts().then(setAnalysts)
  }, [])

  const onChange = (userIds: string[]) => {
    setUserIds(userIds)
  }

  const onConfirm = (event: React.MouseEvent<HTMLButtonElement> | React.FormEvent) => {
    event.preventDefault()

    const allAssistantsIds = [...userIds, ...(project.assistantAnalystIds || [])]
    Promise.all(tasks.map(task => {
      return assignUsersToProjectTask(task._id, {assistantUsersIds: allAssistantsIds})
    })).then(() => {
      onUpdate()
      closeModal()
    })
  }

  return <ModalTemplate size="md" title={t('components.SetProjectReviewers.title')}>
    <form onSubmit={onConfirm}>
      <div className="modal-body">
        <div className="row">
          <div className="col-12">
            <label className="form-label small">
              {t('components.SetProjectReviewers.label')}
            </label>
            <MultipleSelect htmlId="reviewers" name="reviewers"
                            placeholder={t('components.SetProjectReviewers.selectReviewers')}
                            label={(user: User) => user.name!}
                            collection={analysts}
                            selectedValues={userIds as string[]}
                            onChange={onChange}
            />
          </div>
        </div>

        {tasks.length > 1 && <div className="row">
          <div className="col-12 mt-2">
            <div className="alert alert-info mb-0">
              {t('components.SetProjectReviewers.alert')}
              <ul className="mb-0">
                {tasks.map(task => {
                  return <li key={`review-${task._id}`}>
                    <strong>{t(`models.ProjectStage.${task.stage}.title`)}</strong>
                  </li>
                })}
              </ul>
            </div>
          </div>
        </div>}
      </div>
      <div className="modal-footer">
        <button type="submit" className="btn btn-success btn-with-icon">
          <span>{t('components.SetProjectReviewers.update')}</span>
          <DoneIcon className="icon" />
        </button>
      </div>
    </form>
  </ModalTemplate>
}

export default SetProjectReviewers