enum Company {
  READ_ALL = 'COMPANY_READ_ALL',
  READ = 'COMPANY_READ',
  CREATE = 'COMPANY_CREATE',
  DELETE = 'COMPANY_DELETE',
  UPDATE = 'COMPANY_UPDATE'
}

enum Glossary {
  READ = 'GLOSSARY_READ',
  DELETE = 'GLOSSARY_DELETE',
  DELETE_ALL = 'GLOSSARY_DELETE_ALL',
  UPDATE = 'GLOSSARY_UPDATE',
  UPLOAD_FROM_FILE = 'UPLOAD_FROM_FILE',
  UPLOAD_GLOBAL_FROM_FILE = 'UPLOAD_GLOBAL_FROM_FILE'
}

enum Project {
  READ_ALL = 'PROJECT_READ_ALL',
  READ = 'PROJECT_READ',
  CREATE = 'PROJECT_CREATE',
  DELETE = 'PROJECT_DELETE',
  EDIT_SETTINGS = 'PROJECT_EDIT_SETTINGS',
  VIEW_LOGS = 'VIEW_LOGS',
  ASSIGN_TRANSLATOR = 'ASSIGN_TRANSLATOR',
  CANCEL_TRANSLATION_REQUEST = 'CANCEL_TRANSLATION_REQUEST',
  SEE_GLOBAL_HISTORY = 'SEE_GLOBAL_HISTORY'
}

enum Report {
  READ = 'REPORT_READ',
  UPDATE = 'REPORT_UPDATE',
  DOWNLOAD_PDF = 'REPORT_DOWNLOAD_PDF',
  AUTO_TRANSLATE = 'AUTO_TRANSLATE'
}

enum Revision {
  READ_ALL = 'REVISION_READ_ALL',
  UPDATE = 'REVISION_UPDATE',
  DELETE = 'REVISION_DELETE'
}

enum User {
  READ_ALL = 'USER_READ_ALL',
  READ = 'USER_READ',
  READ_SCHEDULE = 'USER_READ_SCHEDULE',
  CREATE = 'USER_CREATE',
  UPDATE_PROFILE = 'UPDATE_PROFILE',
  UPDATE_FULL = 'UPDATE_FULL',
  ACTIVATE = 'USER_ACTIVATE',
  READ_PROFILE = 'READ_PROFILE'
}

enum Comment {
  READ = 'COMMENT_READ',
  MANAGE = 'COMMENT_MANAGE',
  STICK = 'COMMENT_STICK',
  MANAGE_VISIBILITY = 'COMMENT_MANAGE_VISIBILITY'
}

enum MicroTranslation {
  READ = 'MICRO_TRANSLATION_READ',
  MANAGE = 'MICRO_TRANSLATION_MANAGE',
  CORRECT = 'MICRO_TRANSLATION_CORRECT'
}

enum Manual {
  COMPANY_REP_READ = 'COMPANY_REP_MANUAL_READ',
  EXTERNAL_TRANSLATOR_READ = 'EXTERNAL_TRANSLATOR_READ'
}

enum System {
  LARGE_PASTE_MANAGE = 'LARGE_PASTE_MANAGE',
  HOLIDAYS = 'HOLIDAYS'
}

enum ProjectTask {
  RESCHEDULE = 'TASK_RESCHEDULE'
}

export const Permission = {
  Company,
  Glossary,
  Project,
  Report,
  Revision,
  User,
  Comment,
  MicroTranslation,
  ProjectTask,
  Manual,
  System
}

export type PermissionType = Company | Glossary | Project | Report | Revision | User | Comment | MicroTranslation |
  ProjectTask | Manual | System