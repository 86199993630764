import {EditorTableBlock} from 'domain/Editor'
import {Locale} from 'domain/Locale'
import {ExcelTableBlock, MainTableStyle} from 'domain/Report'
import Converter from './Converter'


class TableConverter extends Converter<ExcelTableBlock, EditorTableBlock> {
  toEditor(block: ExcelTableBlock, locale: Locale): EditorTableBlock {
    const {id, type, title, description, content, layout, ...other} = block

    const contentWithTemplate = (layout[locale]?.template || []).map((row, rowIndex) => {
      return {
        ...row,
        cells: row.cells.map((cell, cellIndex) => {
          return {
            ...cell,
            html: content[locale]?.value?.[rowIndex]?.[cellIndex] || ''
          }
        })
      }
    })

    const style = layout[locale]?.style || MainTableStyle.NORMAL
    const additionalStyles = layout[locale]?.additionalStyles || []

    return {
      id, type,
      data: {
        ...other,
        title: title[locale]?.value,
        description: description[locale]?.value,
        content: contentWithTemplate,
        style,
        additionalStyles
      }
    }
  }

  fromEditor(editorBlock: EditorTableBlock, locale: Locale) {
    const {id, type, data} = editorBlock

    const {title, description, content, style, additionalStyles, ...others} = data

    const values = content.map(row => row.cells.map(cell => cell.html))
    const template = content.map(row => {
      return {
        ...row,
        cells: row.cells.map(cell => {
          const {html, ...other} = cell
          return other
        })
      }
    })

    return {
      id, type,
      layout: {
        [locale]: {
          template,
          style,
          additionalStyles
        }
      },
      title: {
        [locale]: {
          value: title
        }
      },
      description: {
        [locale]: {
          value: description
        }
      },
      content: {
        [locale]: {
          value: values
        }
      },
      ...others
    }
  }
}

export default new TableConverter()