import {Errors} from 'domain/Errors'
import {UserWithFormPasswords} from 'domain/User'
import {ReactComponent as DoneIcon} from '../../assets/icons/done.svg'
import React, {ReactElement, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory, useParams} from 'react-router-dom'
import {activateUser, getUserByActivationToken} from '../../api-clients/userClient'
import useToast from '../../hooks/useToast'
import PublicPageTemplate from '../public/PublicPageTemplate'
import Input from '../utils/form/Input'

const UserActivationPage: React.FC = (): ReactElement => {
  const history = useHistory()
  const {t} = useTranslation()
  const {renderSuccessToast} = useToast()

  const {activationToken} = useParams<{activationToken: string}>()

  const [user, setUser] = useState<Partial<UserWithFormPasswords> | null>(null)
  const [errors, setErrors] = useState<Errors<UserWithFormPasswords> & {baseError: string}>()
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setLoading(true)
    getUserByActivationToken(activationToken)
      .then(user => {
        setUser(user)
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
  }, [])

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault()

    const {name, value} = event.target
    setUser(user => ({...user, [name]: value}))
  }

  const onSubmit = (event: React.FormEvent) => {
    event.preventDefault()

    activateUser(activationToken, user!)
      .then(user => {
        renderSuccessToast(t('components.Users.UserActivation.activationSuccessMessage'))
        history.push('/login')
      })
      .catch(({errors}) => setErrors(errors))
  }

  if (loading) {
    return <></>
  }

  return (
    <PublicPageTemplate>
      <div className="container-narrow">
        <h4 className="mb-3">{t('components.Users.UserActivation.title')}</h4>

        {errors?.baseError && (
          <div className="alert alert-danger">{t('components.Users.UserActivation.cannotActivate')}</div>
        )}

        {user ? (
          <form className="my-2 my-lg-0" onSubmit={onSubmit}>
            <div className="form-floating mb-3">
              <Input className="form-control" name="email" value={user.email || ''} disabled={true} />
              <label htmlFor="email" className="form-label">
                {t('models.User.email')}
              </label>
            </div>

            <div className="form-floating mb-3 required">
              <Input
                className="form-control"
                name="name"
                value={user.name || ''}
                onChange={onChange}
                error={errors?.name}
              />
              <label htmlFor="name" className="form-label">
                {t('models.User.name')}
              </label>
            </div>
            <div className="form-floating mb-3">
              <Input
                className="form-control"
                name="phone"
                value={user.phone || ''}
                onChange={onChange}
                error={errors?.phone}
              />
              <label htmlFor="phone" className="form-label">
                {t('models.User.phone')}
              </label>
            </div>
            <div className="form-floating mb-3 required">
              <Input
                className="form-control"
                name="password"
                type="password"
                value={user.password}
                onChange={onChange}
                error={errors?.password}
              />
              <label htmlFor="password" className="form-label">
                {t('models.User.password')}
              </label>
            </div>
            <div className="form-floating mb-3 required">
              <Input
                className="form-control"
                name="passwordConfirmation"
                type="password"
                value={user.passwordConfirmation}
                onChange={onChange}
                error={errors?.passwordConfirmation}
              />
              <label htmlFor="passwordConfirmation" className="form-label">
                {t('models.User.passwordConfirmation')}
              </label>
              <div className="form-text small text-muted">{t('errors.password_pattern')}</div>
            </div>
            <div>
              <button className="btn btn-lg btn-success btn-with-icon w-100">
                <span>{t('components.Users.UserActivation.activate')}</span>
                <DoneIcon className="icon" />
              </button>
            </div>
          </form>
        ) : (
          <div className="alert alert-danger">{t('components.Users.UserActivation.userNotFound')}</div>
        )}
      </div>
    </PublicPageTemplate>
  )
}

export default UserActivationPage