import {EditorTableBlockData, EditorTableRowTemplate} from 'domain/Editor'
import {AdditionalStyle, MainTableStyle, TableStyle} from 'domain/Report'
import {make, onPasteInContentEditable} from '../dom'
import {getCellClassNames, getStyleClass, getTableClassNames, getTableStyles} from './tableClassNamesHelper'


interface TableBuilderConfigs {
  placeholderCSS: string | string[],
  placeholderText: string,
  placeholderIcon: string,
  titlePlaceholder: string,
  descriptionPlaceholder: string
  onTablePaste: (event: ClipboardEvent) => void
}

class TableBuilder {
  config: TableBuilderConfigs
  data: EditorTableBlockData
  nodes: any
  readonly: boolean

  constructor(config: TableBuilderConfigs, data: EditorTableBlockData, readonly: boolean) {
    this.config = config
    this.data = data
    this.readonly = readonly

    this.nodes = {
      container: make('div'),
      placeholder: this.buildPlaceholder(),
      title: this.buildTextField(
        this.data.title || '', [this.CSS.title], this.config.titlePlaceholder, 'h5'
      ),
      description: this.buildTextField(
        this.data.description || '', [this.CSS.description], this.config.descriptionPlaceholder
      ),
      table: this.buildTable(this.data.content, getTableStyles(this.data))
    }

    this.nodes.container.addEventListener('paste', this.config.onTablePaste)
  }

  render() {
    this.nodes.container.innerHTML = ''
    const content = this.isContentEmpty() ? (this.readonly ? '' : this.nodes.placeholder) : this.nodes.table
    this.nodes.container.append(
      this.nodes.title,
      content,
      this.nodes.description
    )

    return this.nodes.container
  }

  get CSS() {
    return {
      input: 'cdx-input',
      title: 'entity-title',
      description: 'entity-description',
      placeholder: this.config.placeholderCSS
    }
  }

  replaceTable(data: EditorTableRowTemplate[], styles: TableStyle[]) {
    this.nodes.table = this.buildTable(data, styles)
    return this.render()
  }

  changeTableStyle(style: MainTableStyle) {
    Object.values(MainTableStyle).forEach(value => {
      if (value !== style)
        this.nodes.table.classList.remove(getStyleClass(value))
    })
    this.nodes.table.classList.add(getStyleClass(style))
  }

  toggleAdditionalStyle(style: AdditionalStyle, toggled: boolean) {
    toggled ? this.nodes.table.classList.remove(style) : this.nodes.table.classList.add(style)
  }

  private buildTextField(text: string, css: string[], placeholder?: string, readonlyTag = 'div') {
    if (this.readonly) return this.buildReadonlyTextField(text, css, readonlyTag)
    const div = make('div', [this.CSS.input, ...css], {
      innerHTML: text,
      contentEditable: true,
      tabIndex: 0
    })

    div.addEventListener('paste', onPasteInContentEditable)

    if (placeholder) div.dataset.placeholder = placeholder
    return div
  }

  private buildReadonlyTextField(text: string, css: string[], readonlyTag = 'div') {
    return make(this.readonly ? readonlyTag : 'div', css, {
      innerHTML: text,
      contentEditable: false
    })
  }

  private buildPlaceholder() {
    const placeholder = make('div', this.CSS.placeholder, {
      innerHTML: `${this.config.placeholderIcon} ${this.config.placeholderText}`,
      tabIndex: 0
    })

    placeholder.addEventListener('paste', this.config.onTablePaste)

    return placeholder
  }

  private buildTable(data: EditorTableRowTemplate[], styles: TableStyle[]): HTMLTableElement {
    const table: HTMLTableElement = make('table', getTableClassNames(styles)) as HTMLTableElement
    const tbody: HTMLTableSectionElement = make('tbody') as HTMLTableSectionElement
    table.append(tbody)

    data.forEach(rowTemplate => {
      const tr: HTMLTableRowElement = table.insertRow()

      rowTemplate.cells.forEach((cellTemplate, cellIdx) => {
        const td = tr.insertCell()
        td.classList.add(...getCellClassNames(cellTemplate))
        td.style.textAlign = cellTemplate.textAlign || ''
        td.style.fontWeight = cellTemplate.fontWeight || ''
        td.style.fontStyle = cellTemplate.fontStyle || ''
        td.style.textDecoration = cellTemplate.textDecoration || ''
        td.style.textDecorationStyle = cellTemplate.textDecorationStyle || ''
        td.style.verticalAlign = cellTemplate.verticalAlign || ''
        td.colSpan = cellTemplate.colSpan || 1
        td.rowSpan = cellTemplate.rowSpan || 1
        td.contentEditable = this.readonly ? 'false' : 'true'
        td.innerHTML = cellTemplate.html
        td.style.width = cellTemplate.width || ''
      })
    })

    table.addEventListener('keydown', TableBuilder.onKeyDown)
    table.addEventListener('paste', this.config.onTablePaste)

    return table
  }

  private static onKeyDown(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      event.preventDefault()
      event.stopPropagation()
    }
  }

  private isContentEmpty(): boolean {
    return this.data.content.length === 0
  }
}

export default TableBuilder