import {CaretPositions} from '@editorjs/editorjs'
import diffPatch from 'domain/diffPatch'
import {ReportState} from 'domain/Report'
import EventBus, {EventType} from '../../../EventBus'
import deepCopy from '../../../utils/deepCopy'
import {calculateDiffInWorker} from '../../../workers/workerApi'

enum Direction {
  PREVIOUS = -1,
  NEXT = 1
}

interface State {
  diff: object
  caret?: CaretPositions
}

type Listener = () => void

export default class UndoRedo {
  private position = -1
  private stack: State[] = []
  private lastState!: ReportState
  listeners: Listener[] = []
  private shouldStoreChange = true

  constructor(readonly maxStackLen = 30) {
  }

  initialize(report: ReportState) {
    this.setLastState(report)
    this.position = -1
    this.stack = []
  }

  onUpdate(listener: Listener) {
    this.listeners.push(listener)
  }

  async onReportUpdated(report: ReportState, caretPositions: CaretPositions) {
    if (!this.shouldStoreChange) {
      return
    }

    const diff = await calculateDiffInWorker(this.lastState || {sections: []}, report)

    if (diff) {
      this.storeChange({diff, caret: caretPositions})
      this.setLastState(report)
      this.notifyUpdate()
    }
  }

  canUndo() {
    return this.position > -1
  }

  canRedo() {
    return this.position < this.stack.length - 1
  }

  undo() {
    if (!this.canUndo()) {
      return
    }

    return this.restoreState(Direction.PREVIOUS)
  }

  redo() {
    if (!this.canRedo()) {
      return
    }

    return this.restoreState(Direction.NEXT)
  }

  private restoreState(direction: Direction) {
    if (!this.lastState) return
    this.shouldStoreChange = false

    const restoredPosition = direction === Direction.NEXT ? this.position + 1 : this.position
    const restoredState = this.stack[restoredPosition]

    const {sections} = direction === Direction.NEXT ?
      diffPatch.patch(this.lastState, restoredState.diff) :
      diffPatch.unpatch(this.lastState, restoredState.diff)

    const clonedSections = deepCopy(sections)

    this.position = this.position + direction
    this.setLastState({...this.lastState, sections: clonedSections})

    if (restoredState.caret) {
      const caret = direction === Direction.NEXT ? restoredState.caret.end : restoredState.caret.start
      setTimeout(() => this.restoreCaret(caret), 150)
    }

    this.notifyUpdate()
    EventBus.emit(EventType.ON_UNDO_REDO)
    this.shouldStoreChange = true

    return clonedSections
  }

  private restoreCaret(caret: CaretPositions['start'] | CaretPositions['end'] | undefined) {
    if (!caret) return

    const {selector, offset} = caret
    const el = document.querySelector(selector) as HTMLElement
    if (!el) return

    el.focus()

    const range = document.createRange()
    try {
      range.setStart(el.childNodes[0], offset)
      range.setEnd(el.childNodes[0], offset)

      window.getSelection()!.removeAllRanges()
      window.getSelection()!.addRange(range)
    } catch (e) {
      console.warn(e)
    }
  }

  private storeChange(state: State) {
    this.stack = this.stack.slice(0, this.position + 1)
    this.stack.push(state)
    this.position++

    if (this.stack.length > this.maxStackLen) {
      this.stack.shift()
      this.position--
    }
  }

  private setLastState(report: ReportState) {
    this.lastState = deepCopy(report)
  }

  private notifyUpdate() {
    this.listeners.forEach(listener => listener())
  }
}