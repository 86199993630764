import {Company} from 'domain/Company'
import React, {ReactElement, useContext} from 'react'
import {useTranslation} from 'react-i18next'
import {LocaleContext} from '../../providers/LocaleProvider'
import {formattedMonth} from '../../utils/momentUtils'

interface DescriptionProps {
  company: Pick<Company, 'phone' | 'website' | 'financialYearEnd'>
}

const Description: React.FC<DescriptionProps> = ({company}): ReactElement => {
  const {t} = useTranslation()
  const {locale} = useContext(LocaleContext)

  return (
    <div>
      <div className="data-list data-list-small data-list-wrappable data-list-row mb-n2">
        <dl>
          <dt>{t('models.Company.phone')}</dt>
          <dd>{company.phone || '-'}</dd>
        </dl>
        <dl>
          <dt>{t('models.Company.website')}</dt>
          <dd>
            <a href={company.website || ''}>{company.website || '-'}</a>
          </dd>
        </dl>
        <dl>
          <dt>{t('models.Company.financialYearEnd')}</dt>
          <dd>{company.financialYearEnd !== undefined ? formattedMonth(company.financialYearEnd, locale) : '-'}</dd>
        </dl>
      </div>
    </div>
  )
}

export default Description
