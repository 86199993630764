import {TaskStatus} from 'domain/Task'
import React, {FC} from 'react'
import {useTranslation} from 'react-i18next'
import ReportTranslationInitialization from '../../report/ReportTranslationInitialization'
import DefaultProjectTask, {ProjectTaskProps} from './DefaultProjectTask'

const TranslationWritingTask: FC<ProjectTaskProps> = props => {
  const {project, task} = props
  const {t} = useTranslation()

  return (
    <DefaultProjectTask {...props}>
      <dl>
        <dt>{t('models.Project.wordCount')}</dt>
        <dd>{project.wordCount ?? '-'}</dd>
      </dl>
      {task.status === TaskStatus.IN_PROGRESS && (
        <dl>
          <dt>{t('components.TranslationWritingTask.prepareTranslation')}</dt>
          <dd>
            <ReportTranslationInitialization project={project} />
          </dd>
        </dl>
      )}
    </DefaultProjectTask>
  )
}

export default TranslationWritingTask
