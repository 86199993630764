class TranslatorRule {
  constructor(private searchPattern: string, private replacePattern: string) {
  }

  apply(word: string): string {
    let previousWord = word
    word = word.replace(new RegExp(this.searchPattern, 'g'), this.replacePattern)

    while (previousWord !== word) {
      previousWord = word
      word = word.replace(new RegExp(this.searchPattern, 'g'), this.replacePattern)
    }

    return word
  }
}

const separatorTranslationRules: TranslatorRule[] = [
  new TranslatorRule('。', '. '),
  new TranslatorRule('、', ', '),
  new TranslatorRule('，', ', '),
  new TranslatorRule('（', ' ('),
  new TranslatorRule('）', ') '),
  new TranslatorRule('：', ': '),
  new TranslatorRule('；', '; '),
  new TranslatorRule('～', ' - '),
  new TranslatorRule('【', ' ['),
  new TranslatorRule('】', '] '),
  new TranslatorRule('｛', ' {'),
  new TranslatorRule('｝', '} '),
  new TranslatorRule('＜', ' <'),
  new TranslatorRule('＞', '> ')
]

const timeTranslationRules: TranslatorRule[] = [
  new TranslatorRule('^([1-4])Q$', 'Q$1'),
  new TranslatorRule('^1Q-([2-4])Q$', 'Q1-Q$1'),
  new TranslatorRule('^1-([2-4])Q$', 'Q1-Q$1'),
  new TranslatorRule('([^\\d]|^)(20)?([0-9]{2})年([0-9]{1})月期', '$1FY0$4/$3'),
  new TranslatorRule('([^\\d]|^)(20)?([0-9]{2})年([0-9]{2})月期', '$1FY$4/$3')
]

const cleanupRules: TranslatorRule[] = [
  new TranslatorRule('^（人）$', ''),
  new TranslatorRule('^人$', ''),
  new TranslatorRule('^（％）$', ''),
  new TranslatorRule('^社$', ''),
  new TranslatorRule('^（社）$', '')
]

export const applyCleanupRules = (word: string) => {
  return applyRules(word, cleanupRules)
}

export const applyAllTranslationRules = (word: string) => {
  const allTranslationRules = separatorTranslationRules.concat(timeTranslationRules)
  return applyRules(word, allTranslationRules)
}

export const getTranslationCandidates = (word: string) => {
  const candidates = new Set<string>()
  candidates.add(word)
  candidates.add(applySeparatorTranslationRules(word))
  candidates.add(applySeparatorTranslationRules(word, true))
  return candidates
}

const applySeparatorTranslationRules = (word: string, withoutSpace: boolean = false) => {
  const result = applyRules(word, separatorTranslationRules)
  return withoutSpace ? result.replace(new RegExp(' ', 'g'), '') : result
}

const applyRules = (word: string, rules: TranslatorRule[]) => {
  let wordResult = word
  for (const rule of rules) {
    wordResult = rule.apply(wordResult)
  }

  return wordResult
}