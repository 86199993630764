import {Locale} from 'domain/Locale'
import {CommonTableBlock, CommonTableLayout} from 'domain/Report'
import React, {ReactElement} from 'react'
import {getStyleClass} from '../../../editorjs/plugins/excel-table/tableClassNamesHelper'
import memoizeComponent from '../../../utils/memoizeComponent'
import RawHtml from '../../utils/RawHtml'


interface CommonTableProps {
  block: CommonTableBlock,
  locale: Locale
}

const APPROXIMATE_TABLE_ROWS_PER_PAGE = 50

const CommonTable: React.FC<CommonTableProps> = ({block, locale}): ReactElement => {
  const title = block.title[locale]
  const description = block.description[locale]
  const layout: CommonTableLayout = block.layout
  const localeContent = block.content[locale]
  const tableDensityClass = getStyleClass(block.style)
  const isLargeTable = (layout.rows || []).length > APPROXIMATE_TABLE_ROWS_PER_PAGE
  const additionalTableStyles = block.additionalStyles ? block.additionalStyles.join(' ') : ''
  const tableClasses = `table table-report table-bordered common-table ${tableDensityClass} ${additionalTableStyles}`
  return (
    <div className={isLargeTable ? '' : 'print-break-inside-avoid'}>
      {title && (<div className="entity-title"><RawHtml>{title}</RawHtml></div>)}

      {block.content[locale] ? <div className="table-responsive">
        <table className={tableClasses}>
          <tbody>
          {layout.rows.map((row, rowIndex) => (
            <tr key={`${block.id}-${rowIndex}`}>
              {row.cells.map((cell, cellIndex) => {
                const cellData = block.content[locale]?.[rowIndex]?.[cellIndex]
                const cellClassNames = []
                if (cell.style) cellClassNames.push(`table-cell-${cell.style.toLowerCase()}`)
                if (cell.align) cellClassNames.push(`text-${cell.align.toLowerCase()}`)

                return <td key={`${block.id}-${rowIndex}-${cellIndex}`}
                           colSpan={cell.colSpan || 1}
                           rowSpan={cell.rowSpan || 1}
                           style={{width: cellData?.width || 'auto'}}
                           className={cellClassNames.join(' ')}>
                  <RawHtml>{localeContent?.[rowIndex]?.[cellIndex]?.value || ''}</RawHtml>
                </td>
              })}
            </tr>
          ))}
          </tbody>
        </table>
      </div> : <></>}

      {description && (<div className="entity-description"><RawHtml>{description}</RawHtml></div>)}
    </div>
  )
}

export default memoizeComponent(CommonTable)
