import {CompanyRow} from 'domain/Company'
import React, {useState} from 'react'
import UploadCompaniesForm from './UploadCompaniesForm'
import UploadCompaniesPreview from './UploadCompaniesPreview'


type Props = {
  onUploaded: () => void
}

enum State {
  UPLOAD_FILE_FORM = 'UPLOAD_COMPANIES_FORM',
  UPLOAD_USERS = 'UPLOAD_USERS'
}


const UploadCompanies: React.FC<Props> = ({onUploaded}) => {
  const [companies, setCompanies] = useState<CompanyRow[]>([])
  const [state, setState] = useState(State.UPLOAD_FILE_FORM)


  const onCompaniesUpload = (companies: CompanyRow[]) => {
    setCompanies(companies)
    setState(State.UPLOAD_USERS)
  }

  return <>
    {state === State.UPLOAD_FILE_FORM ?
      <UploadCompaniesForm onUploaded={onCompaniesUpload}/>
      : <UploadCompaniesPreview onUploaded={onUploaded} companies={companies}
                                onPickAnotherFile={() => setState(State.UPLOAD_FILE_FORM)}/>}
  </>
}

export default UploadCompanies