import {EditorBookmarkBlock} from 'domain/Editor'
import {Locale} from 'domain/Locale'
import {BookmarkBlock} from 'domain/Report'
import Converter from './Converter'


class BookmarkConverter extends Converter<BookmarkBlock, EditorBookmarkBlock> {
  toEditor(block: BookmarkBlock, locale: Locale = Locale.JA): EditorBookmarkBlock {
    const {id, type, title} = block
    return {id, type, data: {title}}
  }

  fromEditor(editorBlock: EditorBookmarkBlock, locale: Locale = Locale.JA) {
    const {id, type, data} = editorBlock
    const {title} = data

    return {id, type, title}
  }
}

export default new BookmarkConverter()