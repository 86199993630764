import {Permission} from 'domain/Permission'
import React, {useContext, useEffect, useRef} from 'react'
import {hasPermission} from '../../../api/src/server/authorize/permissions'
import {getCurrentUserOverdueTasks} from '../api-clients/taskClient'
import OverdueTasksReminderModal from '../components/tasks/OverdueTasksReminderModal'
import LocalStorage from '../LocalStorage'
import {UserContext} from '../providers/UserProvider'
import {getNextDisplayAt} from '../utils/overdueTasksReminderHelpers'
import useModal from './useModal'
import Timeout = NodeJS.Timeout


const OVERDUE_MODAL_KEY = 'OVERDUE_MODAL_KEY'

const useOverdueTasksReminder = () => {
  const {user} = useContext(UserContext)
  const {showModal, components} = useModal()
  const timeoutRef = useRef<Timeout>()

  useEffect(() => {
    if (!user) return
    if (!hasPermission(user.roles, Permission.ProjectTask.RESCHEDULE)) return

    timeoutRef.current = scheduleOverdueTasksModalDisplay()

    return () => {
      if (timeoutRef.current) clearTimeout(timeoutRef.current)
    }
  }, [user])

  const scheduleOverdueTasksModalDisplay = () => {
    const nextDisplayAt = getNextDisplayAt()
    const diff = Math.max(nextDisplayAt!.getTime() - new Date().getTime(), 0)
    return setTimeout(displayOverdueTasksModal, diff)
  }

  const displayOverdueTasksModal = async () => {
    const overdueTasks = await getCurrentUserOverdueTasks(new Date())

    LocalStorage.setOverdueModalLastDisplayedAt(new Date())
    timeoutRef.current = scheduleOverdueTasksModalDisplay()

    if (overdueTasks.length === 0) return

    const isReminderRendered = components.find(c => c.key === OVERDUE_MODAL_KEY)
    if (isReminderRendered) return

    showModal(<OverdueTasksReminderModal key={OVERDUE_MODAL_KEY} tasks={overdueTasks}/>)
  }
}

export default useOverdueTasksReminder