import classNames from 'classnames'
import {Locale} from 'domain/Locale'
import {GridBlock} from 'domain/Report'
import React, {ReactElement} from 'react'
import RawHtml from '../../utils/RawHtml'
import ReadonlyBlocks from './ReadonlyBlocks'


interface GridProps {
  block: GridBlock,
  locale: Locale
}

const Grid: React.FC<GridProps> = ({block, locale}): ReactElement => {
  const title = block.title[locale]?.value
  const description = block.description[locale]?.value
  const grid = block.grid[locale] || []

  const classes = {
    'report-grid': true,
    'report-grid-bordered': block.border,
    'report-grid-fixed': block.equalColumnWidth
  }

  return block.grid[locale] ? (
    <div className="print-break-inside-avoid">
      {title && (<div className="entity-title"><RawHtml>{title}</RawHtml></div>)}
      <table className={classNames(classes)}>
        <tbody>
        {grid.map((row, i) => (
          <tr key={'report-grid-' + i}>
            {row.map(cell => (
              <td key={cell.id}>
                <ReadonlyBlocks blocks={cell.blocks} locale={locale}/>
              </td>
            ))}
          </tr>
        ))}
        </tbody>
      </table>
      {description && (<div className="entity-description"><RawHtml>{description}</RawHtml></div>)}
    </div>
  ) : <></>
}

export default Grid
