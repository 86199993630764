import {Company} from 'domain/Company'
import {Locale} from 'domain/Locale'
import {Project, ProjectType} from 'domain/Project'
import {BlockType, HeadingBlock, ReportState} from 'domain/Report'
import {ReportType, reportTypeByProjectType} from 'domain/ReportTemplate'
import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {getReport} from '../../api-clients/reportClient'
import Select from '../utils/form/Select'
import ModalTemplate from '../utils/ModalTemplate'
import PdfButton from './PdfButton'


interface PrintSettingsProps {
  project: Project
  company: Company
}

const DownloadPdf: React.FC<PrintSettingsProps> = ({project, company}) => {
  const {t} = useTranslation()
  const [locale, setLocale] = useState(Locale.JA)
  const [report, setReport] = useState<ReportState | null>(null)
  const [sectionId, setSectionId] = useState<string | null>(null)
  const [protectWithPassword, setProtectWithPassword] = useState<boolean>(false)
  const [password, setPassword] = useState<string>('')
  const [isDownloading, setIsDownloading] = useState(false)

  useEffect(() => {
    getReport(project._id).then(report => {
      setReport(report)
      setSectionId(reportTypeByProjectType(project.type))
    })
  }, [])

  const sectionHeadings = (): [string, HeadingBlock][] => {
    if (!report) return []

    const headings: [string, HeadingBlock][] = report.sections.map(section => {
      const heading = section.blocks.find(block => block.type === BlockType.HEADING)
      return [section._id, heading as HeadingBlock]
    })

    return headings.filter(([_, heading]) => !!heading)
  }

  const onTypingPassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault()
    setPassword(event.target.value)
  }

  const reportType = (selectedOption: string | null): ReportType => {
    if (!selectedOption) return ReportType.FULL_REPORT
    return Object.values(ReportType).map(v => v.toString()).includes(selectedOption)
      ? selectedOption as ReportType
      : ReportType.SECTION_REPORT
  }

  const reportSectionId = (selectedOption: string | null) => {
    return selectedOption && Object.values(ReportType).map(v => v.toString()).includes(selectedOption)
      ? null
      : selectedOption
  }

  const onChangeReportSection = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSectionId(e.target.value)
  }

  const fullReportOptionKey = project.type === ProjectType.NEWS_UPDATE
    ? ReportType.NEWS_REPORT
    : ReportType.FULL_REPORT

  return (
    <ModalTemplate title={t('components.Project.DownloadPdf.title')}>
      <div className="modal-body">
        <div className="mb-3">
          {Object.values(Locale).map(loc => (
            <div className="form-check form-check-inline" key={loc}>
              <input type="radio" id={loc} name="locale" className="form-check-input" disabled={isDownloading}
                     value={loc} checked={loc === locale} onChange={() => setLocale(loc)}/>
              <label className="form-check-label" htmlFor={loc}>
                {loc.toUpperCase()}
              </label>
            </div>
          ))}
        </div>

        {report ? (
          <div>
            <label htmlFor="report-section" className="form-label small">
              {t('components.Project.DownloadPdf.reportContent')}
            </label>

            <Select id="report-section" className="form-select" value={sectionId || ''}
                    onChange={onChangeReportSection} disabled={isDownloading}>

              <option value={`${fullReportOptionKey}`}>{t('components.Project.DownloadPdf.fullReport')}</option>
              <option value={`${ReportType.SELECTED_PARTS}`}>
                {t('components.Project.DownloadPdf.selectedParts')}</option>
              {sectionHeadings().map(([sectionId, heading]) => {
                return <option key={sectionId}
                               value={sectionId}>{heading.text[locale]?.value?.removeHTMLTags()}</option>
              })}
            </Select>
            <div className="row g-3 align-items-center mt-0">
              <div className="col-6">
                <div className="form-check me-4">
                  <input className="form-check-input" type="checkbox" id="protectWithPassword"
                         onChange={() => setProtectWithPassword(!protectWithPassword)} disabled={isDownloading}/>
                  <label className="form-check-label" htmlFor="protectWithPassword">
                    {t('components.Project.DownloadPdf.protectWithPassword')}
                  </label>
                </div>
              </div>
              <div className={`col-6 ${protectWithPassword ? '' : 'invisible'}`}>
                <input className="form-control" aria-label="Password"
                       placeholder={t('components.Project.DownloadPdf.enterPassword')}
                       value={password} onChange={onTypingPassword} disabled={isDownloading}/>
              </div>
            </div>
          </div>
        ) : (
          <div className="spinner-border"/>
        )}
      </div>

      <div className="modal-footer">
        <PdfButton reportType={reportType(sectionId)} tick={company.tick} project={project}
                   locale={locale} sectionId={reportSectionId(sectionId)}
                   password={protectWithPassword ? password : undefined}
                   onStartDownload={() => setIsDownloading(true)}
                   onEndDownload={() => setIsDownloading(false)}/>
      </div>
    </ModalTemplate>
  )
}

export default DownloadPdf