import {Project} from 'domain/Project'
import {ReactComponent as ImportIcon} from '../../../assets/icons/compare_arrows.svg'
import React, {FC, ReactElement} from 'react'
import {useTranslation} from 'react-i18next'
import useModal from '../../../hooks/useModal'
import SelectProjectToMerge from '../SelectProjectToMerge'


export interface Props {
  project: Project
}

const ImportFromProjectButton: FC<Props> = ({project}): ReactElement => {
  const {t} = useTranslation()
  const {showModal} = useModal()

  const openProjectMerge = (event: React.MouseEvent) => {
    event.preventDefault()
    showModal(<SelectProjectToMerge project={project}/>)
  }

  return (
    <button className="btn btn-xs btn-outline-secondary btn-with-icon ms-1"
            onClick={openProjectMerge}>
      <ImportIcon className="icon icon-xs"/>
      <span className="text-nowrap">{t('components.ImportFromProjectButton.action')}</span>
    </button>
  )
}

export default ImportFromProjectButton