import {Permission} from 'domain/Permission'
import {User} from 'domain/User'
import {ReactComponent as AddIcon} from '../../assets/icons/add.svg'
import {ReactComponent as ArchiveIcon} from '../../assets/icons/archive.svg'
import {ReactComponent as UnarchiveIcon} from '../../assets/icons/unarchive.svg'
import React, {useContext, useEffect, useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'
import {archiveUser, getUsers, unarchiveUser} from '../../api-clients/userClient'
import useModal from '../../hooks/useModal'
import useToast from '../../hooks/useToast'
import {LocaleContext} from '../../providers/LocaleProvider'
import ConfirmationModal from '../utils/ConfirmationModal'
import Protected from '../utils/Protected'
import UserForm from './UserForm'
import {UserAggregate} from 'domain/UserAggregate'


enum UserMode {
  ACTIVE = 'ACTIVE',
  ARCHIVED = 'ARCHIVED'
}

const UsersPage = () => {
  const {t} = useTranslation()
  const {showModal} = useModal()
  const {renderSuccessToast} = useToast()
  const [users, setUsers] = useState<UserAggregate[]>([])
  const {locale} = useContext(LocaleContext)
  const history = useHistory()
  const [mode, setMode] = useState(UserMode.ACTIVE)

  useEffect(() => {
    getUsers().then(setUsers)
  }, [])

  const archivedUsers = useMemo(() => {
    return users.filter(user => user.archived)
  }, [users])

  const activeUsers = useMemo(() => {
    return users.filter(user => !user.archived)
  }, [users])

  const displayedUsers = useMemo(() => {
    return mode === UserMode.ACTIVE ? activeUsers : archivedUsers
  }, [users, mode])

  const addNewUser = (event: React.MouseEvent) => {
    event.preventDefault()
    showModal(<UserForm onUserCreated={user => setUsers([...users, user])}/>)
  }

  const openUserPage = (event: React.MouseEvent, user: User) => {
    if ((event.target as HTMLElement).tagName === 'A') {
      return
    }

    event.preventDefault()
    history.push(`/users/${user._id}`)
  }

  const onToggleMode = (e: React.ChangeEvent<HTMLInputElement>) => {
    setMode(e.target.value as UserMode)
  }

  const onUserArchive = (e: React.MouseEvent, user: User) => {
    e.preventDefault()
    e.stopPropagation()
    const onConfirm = () => {
      return archiveUser(user).then(updatedUser => {
        renderSuccessToast(t('components.UsersPage.archivedSuccessfully'))
        setUsers(users => users.map(u => u._id === updatedUser._id ? updatedUser : u))
      })
    }

    showModal(<ConfirmationModal title={t('components.UsersPage.confirmArchive', {name: user.name})}
                                 ConfirmationIcon={ArchiveIcon} onConfirm={onConfirm}/>)
  }

  const onUserUnarchive = (e: React.MouseEvent, user: User) => {
    e.preventDefault()
    e.stopPropagation()
    const onConfirm = () => {
      return unarchiveUser(user).then(updatedUser => {
        renderSuccessToast(t('components.UsersPage.unarchivedSuccessfully'))
        setUsers(users => users.map(u => u._id === updatedUser._id ? updatedUser : u))
      })
    }

    showModal(<ConfirmationModal title={t('components.UsersPage.confirmUnarchive', {name: user.name})}
                                 ConfirmationIcon={UnarchiveIcon} onConfirm={onConfirm}/>)
  }

  return (
    <div className="container py-5">
      <div className="d-flex justify-content-between align-items-center my-5">
        <h1 className="display-3 fw-bolder">
          {t('components.UsersPage.title')}
        </h1>

        <Protected permission={Permission.User.CREATE}>
          <button type="button"
                  onClick={addNewUser}
                  className="btn btn-outline-primary btn-sm btn-with-icon">
            <span>{t('components.UsersPage.addNewUser')}</span>
            <AddIcon className="icon icon-sm"/>
          </button>
        </Protected>
      </div>

      <div className="card elevate">
        <Protected permission={Permission.User.UPDATE_FULL}>
          <div className="card-body d-flex justify-content-end">
            {[UserMode.ACTIVE, UserMode.ARCHIVED].map(userMode => {
              return <div className="ms-1" key={userMode}>
                <input type="radio" id={userMode} name="type" className="btn-check"
                       value={userMode} checked={userMode === mode} onChange={onToggleMode}/>
                <label className="btn btn-secondary btn-sm" htmlFor={userMode}>
                  {t(`components.UsersPage.mode.${userMode}`)}
                </label>
              </div>
            })}
          </div>
        </Protected>

        <table className="table table-sm small table-hover table-clickable-rows">
          <thead>
          <tr>
            <th>{t('models.User.name')}</th>
            <th>{t('models.User.email')}</th>
            <th>{t('models.User.phone')}</th>
            <th>{t('models.User.companyId')}</th>
            <th>{t('models.User.roles')}</th>
            <Protected permission={Permission.User.ACTIVATE}>
              <th>{t('components.UsersPage.activationLink')}</th>
            </Protected>
            <Protected permission={Permission.User.UPDATE_FULL}>
              <th/>
            </Protected>
          </tr>
          </thead>
          <tbody>
          {displayedUsers.map(user => (
            <tr key={user._id as string} onClick={e => openUserPage(e, user)}>
              <td>{user.name}</td>
              <td className="text-break">{user.email}</td>
              <td>{user.phone}</td>
              <td>{user.company ? user.company.name[locale] : ''}</td>
              <td>{user.roles.map(role => t(`models.Role.${role}`)).join(', ')}</td>
              <Protected permission={Permission.User.ACTIVATE}>
                <td>
                  {user.activationToken ?
                    <a href={`/user/activate/${user.activationToken}`}>{t('components.UsersPage.linkTitle')}</a> :
                    <span className="badge bg-success">{t('components.UsersPage.registered')}</span>
                  }
                </td>
              </Protected>
              <Protected permission={Permission.User.UPDATE_FULL}>
                <td>
                  {user.archived ? <button className="btn btn-xs btn-outline-success"
                                           onClick={e => onUserUnarchive(e, user)}>
                    {t('components.UsersPage.unarchive')}
                  </button> : <button className="btn btn-xs btn-outline-danger" onClick={e => onUserArchive(e, user)}>
                    {t('components.UsersPage.archive')}
                  </button>}
                </td>
              </Protected>
            </tr>))
          }
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default UsersPage