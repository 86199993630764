import {Locale} from 'domain/Locale'
import {HeadingBlock} from 'domain/Report'
import React, {ReactElement} from 'react'
import scrollToElement from '../../../utils/scrollToElement'


interface HeadingLinkProps {
  heading: HeadingBlock
  locale: Locale
  headingType?: HeadingType
}

export enum HeadingType {
  NORMAL = 'NORMAL',
  WARNING = 'WARNING'
}

const HeadingLink: React.FC<HeadingLinkProps> = ({heading, locale, headingType = HeadingType.NORMAL}): ReactElement => {

  const scrollTo = (id: string) => {
    const element = document.getElementById(id)

    if (element) {
      scrollToElement(element)
    }
  }

  return (
    <a className={`report-toc-link ${headingType === HeadingType.WARNING ? 'text-danger' : ''}`}
       onClick={() => scrollTo(heading.id)}>
      {heading.text[locale]?.value?.removeHTMLTags()}
    </a>
  )
}

export default HeadingLink