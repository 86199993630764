import {Locale} from 'domain/Locale'
import {User} from 'domain/User'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {makeLink} from '../../../../api/src/utils/linkUtils'
import EmailButton from './EmailButton'
import EmailTitle from './EmailTitle'
import {ProjectAggregate} from 'domain/ProjectAggregate'


type Props = {
  project: ProjectAggregate
  translator: User
}

const TranslatorFinishedNotification: React.FC<Props> = props => {
  const {project, translator} = props
  const {t} = useTranslation()

  return <>
    <EmailTitle titleText={t('emails.translatorFinishedNotification.bodyTitle')}/>

    <div>
      <span dangerouslySetInnerHTML={{__html: t('emails.translatorFinishedNotification.message', {
        translator: translator.name,
        project: project.title,
        company: project.company.name[Locale.JA],
        tick: project.company.tick
      })}}/>

      <p style={{margin: '25px 0 16px'}}>
        <EmailButton buttonLink={makeLink(`/projects/${project._id}`)}
                     buttonText={t('emails.translatorFinishedNotification.goToProject')}/>
      </p>
    </div>
  </>
}

export default TranslatorFinishedNotification