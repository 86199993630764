import React, {ReactElement} from 'react'

interface SpinnerProps {
  withDimmer?: boolean
  size?: 'sm' | 'xs'
}

const Spinner: React.FC<SpinnerProps> = ({withDimmer, size}): ReactElement => {

  return (<div className={`loading-spinner
  ${withDimmer ? 'loading-spinner-with-dimming' : ''}
  ${size ? `loading-spinner-${size}` : ''}`}>
    <svg className="spinner-branded" viewBox="0 0 250 250" version="1.1"
         xmlns="http://www.w3.org/2000/svg">
      <title>Loading...</title>
      <g id="sr-logo" transform="translate(27.000000, 27.000000)">
        <g id="logoelement">
          <path
d="M161.640646,81.7130032 C161.640646,78.2667157 158.029275,75.4735248 153.572402,75.4735248 C149.114779,75.4735248 145.501909,78.2667157 145.501909,81.7130032 L145.50041,125.235785 C145.50041,128.681493 149.113281,131.473525 153.570153,131.473525 C158.027776,131.473525 161.639147,128.681493 161.639147,125.235785 L161.640646,81.7130032 Z"
id="Fill-4" fill="#80A2C4"/>
          <path
d="M111.621591,49.1781 C114.73564,49.1781 117.259977,45.5631 117.259977,41.10285 C117.259977,36.64335 114.734592,33.02985 111.621591,33.02985 L71.8967916,33.02835 C68.7827435,33.02835 66.2599771,36.64185 66.2599771,41.10135 C66.2599771,45.5616 68.7827435,49.1766 71.8967916,49.1766 L111.621591,49.1781 Z"
id="Fill-6" fill="#FFCD05"/>
          <path
d="M32.7766798,112.733538 C32.7766798,115.933124 36.3880511,118.525725 40.8456735,118.525725 C45.3025464,118.525725 48.9154168,115.933124 48.9154168,112.733538 L48.916916,72.3195264 C48.916916,69.1194022 45.3040456,66.525725 40.8471727,66.525725 C36.3895502,66.525725 32.778179,69.1204784 32.778179,72.3195264 L32.7766798,112.733538 Z"
id="Fill-10" fill="#80A2C4"/>
          <path
d="M81.0177813,145.823025 C77.7811027,145.823025 75.1577983,149.435775 75.1577983,153.896775 C75.1577983,158.356275 77.7811027,161.970525 81.0177813,161.970525 L122.299448,161.972775 C125.535583,161.972775 128.157798,158.358525 128.157798,153.898275 C128.157798,149.438025 125.535583,145.824525 122.299448,145.824525 L81.0177813,145.823025 Z"
id="Fill-12" fill="#4F7EAC"/>
          <path
d="M47.2379056,177.73095 C40.9236276,179.42295 34.1491208,178.55445 28.4832106,175.28295 C22.8015596,172.00545 18.7194658,166.6227 17.0239653,160.29195 C15.3337116,153.97395 16.2016999,147.1962 19.4720223,141.52695 C22.7498402,135.8412 28.1256713,131.75595 34.4526919,130.06095 C40.7677196,128.37045 47.5429758,129.23895 53.2073869,132.5097 C58.889038,135.7902 62.9726309,141.16995 64.6666323,147.49995 C66.356886,153.8187 65.4911463,160.59795 62.2200745,166.2672 C58.9437556,171.95145 53.5641767,176.0367 47.2379056,177.73095 M20.4516948,189.27945 C29.827918,194.69295 40.9528604,196.18395 51.4129447,193.38045 C61.9210007,190.56495 70.7717837,183.72345 76.2075795,174.30195 C81.61864,164.92095 83.1072625,153.7872 80.3069132,143.32245 C77.4923223,132.8082 70.6548526,123.9522 61.2389028,118.5132 C51.8626796,113.09895 40.7362381,111.6087 30.2784024,114.41295 C19.7703464,117.22845 10.9188139,124.06845 5.48301804,133.4907 C0.0727071429,142.8717 -1.41666495,154.00545 1.38593306,164.4702 C4.19902489,174.98145 11.0372441,183.84195 20.4516948,189.27945"
id="Fill-14" className="logo-circle-4" fill="#80A2C4"/>
          <path
d="M54.7437563,37.36965 C55.7309244,41.0574 55.234717,45.02415 53.3240932,48.33465 C51.4127198,51.6504 48.2630764,54.0234 44.5767493,55.01115 C40.8904222,55.9989 36.9275081,55.5009 33.6182087,53.5899 C30.3059113,51.67665 27.9343092,48.5259 26.9471411,44.83815 C25.9592234,41.14965 26.4561804,37.18515 28.3668042,33.87315 C30.2781776,30.5574 33.4263219,28.18665 37.1133986,27.19665 C40.8004752,26.20815 44.7633893,26.7069 48.0726886,28.61865 C51.3864853,30.53115 53.7565882,33.68115 54.7437563,37.36965 M67.3130973,56.3709 C71.3607115,49.35315 72.4790523,41.02065 70.3840372,33.1914 C68.2777787,25.3209 63.1508008,18.6924 56.1034549,14.62065 C49.0898391,10.5699 40.7629973,9.45315 32.9391091,11.54865 C25.0709969,13.65465 18.4486505,18.7854 14.3792991,25.83765 C10.331685,32.8554 9.21259464,41.18715 11.3083593,49.01565 C13.4146177,56.88615 18.5400966,63.51465 25.5889416,67.5864 C32.6018079,71.6394 40.926401,72.75615 48.7532874,70.65915 C56.6213995,68.55315 63.2444956,63.42315 67.3130973,56.3709"
id="Fill-16" className="logo-circle-1" fill="#FFCD05"/>
          <path
d="M167.455269,20.696025 C172.331145,24.017775 175.854068,28.964025 177.392911,34.710525 C179.081665,41.014275 178.218924,47.777775 174.964343,53.441775 L174.046883,55.037775 L173.96743,54.995775 C170.649136,59.875275 165.7058,63.399525 159.962685,64.940025 C153.651405,66.631275 146.875399,65.761275 141.212487,62.490525 C135.527838,59.209275 131.447244,53.829525 129.750993,47.495775 C128.063738,41.192025 128.932476,34.430775 132.195303,28.775025 C135.478368,23.087025 140.840707,18.968775 147.18122,17.268525 C153.483505,15.579525 160.24302,16.442025 165.902934,19.697775 L167.498743,20.616525 L167.455269,20.696025 Z M188.682007,61.979025 L188.682007,61.946775 L188.933858,61.510275 C194.346418,52.128525 195.834291,40.997025 193.031692,30.530775 C190.263575,20.191275 183.623239,11.428275 174.435904,5.972025 L174.402173,5.972025 L173.965931,5.720025 C164.590457,0.305775 153.464765,-1.184475 143.00618,1.619775 C132.511616,4.433775 123.647341,11.243025 118.213044,20.658525 C112.792989,30.049275 111.305117,41.195025 114.110712,51.674025 C116.924554,62.186775 123.758276,71.042025 133.174225,76.481025 C142.54895,81.899025 153.67764,83.390775 164.137724,80.587275 C174.473382,77.819025 183.228971,71.172525 188.682007,61.979025 L188.682007,61.979025 Z"
id="Fill-18" className="logo-circle-2" fill="#80A2C4"/>
          <path
d="M157.303628,167.802225 C153.617301,168.790725 149.653637,168.293475 146.344337,166.382475 C143.03204,164.470725 140.661937,161.319225 139.67327,157.629975 C138.686102,153.942225 139.182309,149.975475 141.092933,146.664225 C143.006555,143.347725 146.1547,140.977725 149.840277,139.989225 C153.526604,139.001475 157.491017,139.498725 160.799567,141.409725 C164.111864,143.322225 166.481218,146.473725 167.470634,150.162225 C168.457803,153.850725 167.961595,157.817475 166.050972,161.127975 C164.139598,164.442225 160.989205,166.814475 157.303628,167.802225 M138.313571,180.380475 C145.327187,184.431225 153.653279,185.548725 161.478666,183.451725 C169.346029,181.344975 175.969125,176.216475 180.039226,169.163475 C184.086091,162.145725 185.205181,153.815475 183.108667,145.984725 C181.003158,138.112725 175.879178,131.485725 168.829583,127.413975 C161.815218,123.362475 153.489875,122.244975 145.663738,124.341975 C137.796376,126.449475 131.17328,131.577975 127.103929,138.630225 C123.057064,145.646475 121.937974,153.979725 124.032989,161.808975 C126.139997,169.679475 131.265476,176.307975 138.313571,180.380475"
id="Fill-20" className="logo-circle-3" fill="#4F7EAC"/>
          <g className="logo-plus-wrapper">
            <g className="logo-plus">
              <path
              d="M97.2087004,66.931275 L97.20945,66.931275 C101.763766,66.931275 105.48907,70.658775 105.48907,75.215025 L105.48907,119.784525 C105.48907,124.340025 101.763766,128.068275 97.20945,128.068275 L97.2087004,128.068275 C92.6551345,128.068275 88.9298304,124.340025 88.9298304,119.784525 L88.9298304,75.215025 C88.9298304,70.658775 92.6551345,66.931275 97.2087004,66.931275"
              id="Fill-22" fill="#FFCD05"/>
                        <path
              d="M127.760167,97.49925 L127.760167,97.5007498 C127.760167,102.057 124.034113,105.7845 119.480547,105.7845 L74.9373033,105.7845 C70.3837374,105.7845 66.6584333,102.057 66.6584333,97.5007498 L66.6584333,97.49925 C66.6584333,92.943 70.3837374,89.2155 74.9373033,89.2155 L119.480547,89.2155 C124.034113,89.2155 127.760167,92.943 127.760167,97.49925"
              id="Fill-24" fill="#80A2C4"/>
            </g>
          </g>
          <polygon id="Fill-26" fill="#4F7EAC"
points="88.9298304 105.7845 105.48907 105.7845 105.48907 89.2155 88.9298304 89.2155"/>
        </g>
      </g>
    </svg>
  </div>)
}

export default Spinner