import React, {useEffect, useState} from 'react'
import {range} from '../../../../api/src/utils/range'
import {formatDate} from '../../utils/momentUtils'


type Props = {
  initial: Date
  onChange: (date: Date) => void
}

const TaskDatePicker: React.FC<Props> = ({initial, onChange}) => {
  const [newDate, setNewDate] = useState(formatDate(initial, 'YYYY-MM-DD'))
  const [newTime, setNewTime] = useState(`${formatDate(initial, 'HH')}:00`)
  const [changed, setChanged] = useState(false)

  useEffect(() => {
    if (!changed) return
    const finalDate = new Date(`${newDate}T${newTime}`)
    onChange(finalDate)
  }, [newDate, newTime, changed])

  useEffect(() => {
    setChanged(false)
    setNewDate(formatDate(initial, 'YYYY-MM-DD'))
    setNewTime(`${formatDate(initial, 'HH')}:00`)
  }, [initial])

  const onDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    setChanged(true)
    setNewDate(e.target.value)
  }

  const onTimeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    e.preventDefault()
    setChanged(true)
    setNewTime(e.target.value)
  }

  return <div className="d-flex">
    <input type="date" className="form-control" value={newDate}
           onChange={onDateChange} required={true}/>

    <select value={newTime} onChange={onTimeChange} className="form-control ms-2">
      {range(0, 23).map(hour => {
        const formattedHour = `${hour.toString().padStart(2, '0')}:00`
        return <option key={formattedHour} value={formattedHour}>{formattedHour}</option>
      })}
    </select>
  </div>
}

export default TaskDatePicker