import {SendingStatus} from 'domain/SendingStatus'
import moment from 'moment'
import React, {useContext, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'
import EventBus, {EventType} from '../../../EventBus'

import {LocaleContext} from '../../../providers/LocaleProvider'

interface SendingStatusChangedEvent extends CustomEvent {
  detail: {
    status: SendingStatus
    timestamp?: Date
  }
}

const SendingStatusButton: React.FC = () => {
  const {t} = useTranslation()
  const {locale} = useContext(LocaleContext)
  const [sendingStatus, setSendingStatus] = useState<SendingStatus>(SendingStatus.IDLE)
  const [timestamp, setTimestamp] = useState<Date>()
  const history = useHistory()

  const onSendingStatusChange = (event: Event) => {
    const change = event as unknown as SendingStatusChangedEvent
    const status = change.detail.status
    const changedAt = change.detail.timestamp
    if (status === SendingStatus.SENDING) {
      setSendingStatus(SendingStatus.SENDING)
    } else {
      setTimeout(() => {
        setSendingStatus(status)
        if (changedAt) {
          setTimestamp(changedAt)
        }
      }, 500)
    }
  }

  useEffect(() => {
    EventBus.on(EventType.REPORT_SENDING_STATUS_CHANGE, onSendingStatusChange)

    return () => {
      EventBus.unsubscribe(EventType.REPORT_SENDING_STATUS_CHANGE, onSendingStatusChange)
    }
  }, [])

  useEffect(() => {
    if ([SendingStatus.IDLE, SendingStatus.SUCCEEDED].includes(sendingStatus)) return

    const showUnsavedChangesPrompt = (event: BeforeUnloadEvent) => {
      event.preventDefault()
      event.returnValue = ''
    }

    window.addEventListener('beforeunload', showUnsavedChangesPrompt)
    const removeHistoryBlock = history.block(t('warnings.notAllChangesSaved') as string)

    return () => {
      removeHistoryBlock()
      window.removeEventListener('beforeunload', showUnsavedChangesPrompt)
    }
  }, [sendingStatus])

  const saveTimestamp = timestamp && moment(timestamp, undefined, locale).format('hh:mm:ss')

  if (sendingStatus === SendingStatus.IDLE) {
    return null
  }

  const saveStatus = () => {
    switch (sendingStatus) {
      case SendingStatus.SENDING:
        return (
          <div className="small text-success text-nowrap">
            {t('components.Report.report-navigation.SendingStatusButton.saving')}
          </div>
        )
      case SendingStatus.SUCCEEDED:
        return (
          <div className="small text-success text-nowrap">
            {t('components.Report.report-navigation.SendingStatusButton.saved', {
              savedAt: saveTimestamp
            })}
          </div>
        )
      case SendingStatus.FAILED:
        return (
          <div className="small text-danger text-nowrap">
            {t('components.Report.report-navigation.SendingStatusButton.failed')}
            {saveTimestamp &&
              t('components.Report.report-navigation.SendingStatusButton.lastSavedAt', {lastSavedAt: saveTimestamp})}
          </div>
        )
    }
  }

  return (
    <div className="d-flex align-items-baseline justify-content-between gap-2" data-sending-status={sendingStatus}>
      {saveStatus()}
    </div>
  )
}

export default SendingStatusButton
