import {Entity, Id} from 'domain/Entity'
import {Locale} from 'domain/Locale'


export interface TermTranslation {
  _id?: Id
  [Locale.JA]: string
  [Locale.EN]: string
  isPrimary: boolean
  comment?: string
}

export enum GlossaryType {
  TABLE = 'TABLE',
  BLOCK = 'BLOCK'
}

export interface GlossaryTerm extends Entity {
  companyId?: Id
  type: GlossaryType
  [Locale.JA]: string
  [Locale.EN]: string
  isPrimary: boolean
  comment?: string
}

export type GlossaryTermWithAlternatives = GlossaryTerm & {
  alternatives: GlossaryAlternativeTerm[]
}

export interface GlossaryTermsForTranslation {
  [Locale.JA]: string,
  alternatives: GlossaryAlternativeTerm[]
}

export interface GlossaryAlternativeTerm {
  _id: Id,
  [Locale.EN]: string,
  type: GlossaryAlternativeType,
  companyId?: string
}

export enum GlossaryAlternativeType {
  VERIFIED = 0,
  COMPANY = 1,
  GLOBAL = 2,
  OTHER_COMPANY = 3
}
