import {Company} from 'domain/Company'
import {Locale} from 'domain/Locale'
import React, {useContext} from 'react'
import {useTranslation} from 'react-i18next'
import {LocaleContext} from '../../providers/LocaleProvider'
import {formattedMonth} from '../../utils/momentUtils'
import FormattedDate from '../utils/FormattedDate'
import IRContacts from './IRContacts'


interface CompanyDetailsProps {
  company: Company
}

const CompanyDetails: React.FC<CompanyDetailsProps> = ({company}) => {
  const {t} = useTranslation()
  const {locale} = useContext(LocaleContext)

  return (
    <div className="card mb-3">
      <div className="card-header">
        <h5 className="card-title mb-0">
          {t('components.Company.CompanyDetails.contacts')}
        </h5>
      </div>
      <div className="card-body">
        <h6 className="card-title mb-0">
          {t('components.Company.CompanyDetails.details')}
        </h6>

        <div className="data-list data-list-small">
          <dl>
            <dt>{t('models.Company.headOfficeAddress')}</dt>
            <dd>
              <div className="text-pre-wrap">{company.headOfficeAddress?.[Locale.JA]}</div>
              <div className="text-pre-wrap">{company.headOfficeAddress?.[Locale.EN]}</div>
            </dd>
          </dl>
        </div>

        <div className="data-list data-list-small data-list-2-columns">
          <dl>
            <dt>{t('models.Company.japaneseSpellingName')}</dt>
            <dd>{company.japaneseSpellingName ?? '-'}</dd>
          </dl>
          <dl>
            <dt>{t('models.Company.shortName')}</dt>
            <dd>
              <div>{company.shortName[Locale.JA]}</div>
              <div>{company.shortName[Locale.EN]}</div>
            </dd>
          </dl>
        </div>
        <div className="data-list data-list-small data-list-2-columns">
          <dl>
            <dt>{t('models.Company.establishedAt')}</dt>
            <dd>{company.establishedAt ? <FormattedDate date={company.establishedAt}/> : '-'}</dd>
          </dl>
          <dl>
            <dt>{t('models.Company.exchangeName')}</dt>
            <dd>
              <div>{company.exchangeName?.[Locale.JA] ?? '-'}</div>
              <div>{company.exchangeName?.[Locale.EN] ?? '-'}</div>
            </dd>
          </dl>
        </div>

        <div className="data-list data-list-small data-list-2-columns">
          <dl>
            <dt>{t('models.Company.listedAt')}</dt>
            <dd>{company.listedAt ? <FormattedDate date={company.listedAt}/> : '-'}</dd>
          </dl>
          <dl>
            <dt>{t('models.Company.coverageInitiatedAt')}</dt>
            <dd>{company.coverageInitiatedAt ? <FormattedDate date={company.coverageInitiatedAt}/> : '-'}</dd>
          </dl>
          <dl>
            <dt>{t('models.Company.financialYearEnd')}</dt>
            <dd>{company.financialYearEnd !== undefined ? formattedMonth(company.financialYearEnd, locale) : '-'}</dd>
          </dl>
        </div>

        <div className="data-list data-list-small">
          <dl>
            <dt>{t('models.Company.summary')}</dt>
            <dd>
              <div className="text-pre-wrap mt-1">{company.summary?.[Locale.JA]}</div>
              <div className="text-pre-wrap mt-1">{company.summary?.[Locale.EN]}</div>
            </dd>
          </dl>
        </div>

        <div className="data-list data-list-small">
          <dl>
            <dt>{t('models.Company.industries')}</dt>
            <dd>
              <div className="text-pre-wrap mt-1">
                {(company.classification?.gics || []).map(gics => {
                  return <span className="badge bg-secondary me-" key={gics}>{t(`gics.${gics}`)}</span>
                })}
              </div>
            </dd>
          </dl>
        </div>

      </div>
     <div className="card-body pb-1">
        <h5 className="card-title">
          {t('components.Company.CompanyDetails.irContactsJP')}
        </h5>
        <IRContacts locale={Locale.JA} company={company}/>
      </div>
      <div className="card-body pb-1">
        <h5 className="card-title">
          {t('components.Company.CompanyDetails.irContactsEN')}
        </h5>
        <IRContacts locale={Locale.EN} company={company}/>
      </div>
    </div>
  )
}

export default CompanyDetails