import {Company} from 'domain/Company'
import {Locale} from 'domain/Locale'
import {ReactComponent as BusinessIcon} from '../../assets/icons/business.svg'
import React, {ReactElement} from 'react'
import {useTranslation} from 'react-i18next'
import {Link} from 'react-router-dom'
import CompanyStatusBadge from './CompanyStatusBadge'
import Description from './Description'

interface CardProps {
  company: Company
  showDetailsLink?: boolean
}

const CompanyCard: React.FC<CardProps> = ({company, showDetailsLink}): ReactElement => {
  const {t} = useTranslation()

  return (
    <div className="card mb-3">
      <div className="card-header">
        <div className="d-flex align-items-start">
          <BusinessIcon className="text-muted mt-1 me-2 icon-sm" />
          <div className="flex-grow-1">
            <h6 className="mb-0 d-flex align-content-between">
              {company.name[Locale.EN]} <span className="text-muted ms-1">{company.tick}</span>
              <CompanyStatusBadge status={company.status} />
              {company.isZeroYen && (
                <div className="small">
                  <div className={`badge rounded-pill ms-2 bg-danger`}>{t('models.Company.isZeroYen')}</div>
                </div>
              )}
            </h6>
            <h6 className="mb-0">{company.name[Locale.JA]}</h6>
          </div>
        </div>

        {showDetailsLink ? (
          <div className="card-header-actions">
            <Link to={`/companies/${company._id}`} className="btn btn-sm btn-outline-secondary">
              <span>{t('components.Company.CompanyDetails.details')}</span>
            </Link>
          </div>
        ) : (
          ''
        )}
      </div>
      <div className="card-body">
        <Description company={company} />
      </div>
    </div>
  )
}

export default CompanyCard
