import {Errors} from 'domain/Errors'
import {Permission} from 'domain/Permission'
import {ExternalTranslator, hasRoles, InternalTranslator, Role, User} from 'domain/User'
import {ReactComponent as DoneIcon} from '../../assets/icons/done.svg'
import React, {useContext, useState} from 'react'
import SelectSearch from 'react-dropdown-select'
import {useTranslation} from 'react-i18next'
import {updateUserFull, updateUserProfile} from '../../api-clients/userClient'
import useModal from '../../hooks/useModal'
import {UserContext} from '../../providers/UserProvider'
import Error from '../utils/form/Error'
import {localizedRoles, onHandleKeyDownFn, rolesFromOptions, SelectOption} from '../utils/form/SearchSelectHelper'
import Textarea from '../utils/form/Textarea'
import ModalTemplate from '../utils/ModalTemplate'
import Protected from '../utils/Protected'
import EditExternalTranslatorForm from './edit-user/EditExternalTranslatorForm'
import EditInternalTranslatorForm from './edit-user/EditInternalTranslatorForm'


interface EditUserProps {
  user: User
  onUpdated: (user: User) => void
}

const EditUser: React.FC<EditUserProps> = props => {
  const {onUpdated} = props
  const {closeModal} = useModal()
  const {t} = useTranslation()
  const [user, setUser] = useState(props.user)
  const [errors, setErrors] = useState<Errors<User>>({})
  const {hasPermission} = useContext(UserContext)

  const onConfirm = (e: React.FormEvent) => {
    e.preventDefault()
    let userUpdate
    if (hasPermission(Permission.User.UPDATE_FULL)) {
      userUpdate = updateUserFull(user)
    } else if (hasPermission(Permission.User.UPDATE_PROFILE)) {
      userUpdate = updateUserProfile(user)
    }

    if (userUpdate) {
      userUpdate.then(user => {
        closeModal()
        onUpdated(user)
      }).catch(({errors}) => setErrors(errors))
    }
  }

  const onChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLSelectElement | HTMLInputElement>) => {
    e.preventDefault()
    const {name, value} = e.target
    setUser(user => ({...user, [name]: value ? value : null}))
  }

  const availableRoles = localizedRoles(Object.values(Role), t)
  const selectedUserRoles = () => localizedRoles((user?.roles || []), t)

  const onChangeRole = (options: SelectOption[]) => {
    setUser(user => ({...user, roles: rolesFromOptions(options)}))
  }

  return <ModalTemplate size="md" title={t('components.EditUser.title')}>
    <form onSubmit={onConfirm}>
      <div className="modal-body">
        <Protected permission={Permission.User.UPDATE_FULL}>
          <div className="row">
            <div className="mb-3 col-lg-12">
              <label className={`form-label small ${errors.roles ? 'is-invalid' : ''}`}>
                {t('models.User.roles')}
              </label>
              <SelectSearch required={true}
                            options={availableRoles}
                            onChange={onChangeRole}
                            handleKeyDownFn={onHandleKeyDownFn} multi={true}
                            values={selectedUserRoles()}/>
              {errors.roles && <Error error={errors.roles}/>}
            </div>
          </div>
        </Protected>
        {user && hasRoles(user, Role.EXTERNAL_TRANSLATOR) &&
        <EditExternalTranslatorForm user={user as ExternalTranslator}
                                    errors={errors}
                                    onChange={onChange}/>}
        {user && hasRoles(user, Role.INTERNAL_TRANSLATOR) &&
        <EditInternalTranslatorForm user={user as InternalTranslator}
                                    errors={errors}
                                    onChange={onChange}/>}
        <div className="row">
          <div className="mb-3 col-lg-12">
            <label className="form-label small">
              {t('models.User.notes')}
            </label>
            <Textarea className="form-control" id="notes" name="notes" onChange={onChange}
                      error={errors.notes} value={user.notes}/>
          </div>
        </div>
      </div>

      <div className="modal-footer">
        <button className="btn btn-success btn-with-icon">
          <span>{t('components.EditUser.save')}</span>
          <DoneIcon className="icon" />
        </button>
      </div>
    </form>
  </ModalTemplate>
}

export default EditUser