import moment from 'moment-timezone'
import LocalStorage from '../LocalStorage'


export const getNextDisplayAt = () => {
  const possibleNextDateTimes = getPossibleNextDateTimes()
  const previousDisplayedAt = LocalStorage.getOverdueModalLastDisplayedAt()

  if (!previousDisplayedAt) return possibleNextDateTimes[0].toDate()

  for (const date of possibleNextDateTimes) {
    if (moment(previousDisplayedAt).isBefore(date)) {
      return date.toDate()
    }
  }
}

const getPossibleNextDateTimes = () => {
  const today = moment(new Date()).format(`YYYY-MM-DD`)
  const tomorrow = moment(new Date()).add({day: 1}).format(`YYYY-MM-DD`)

  return [
    moment(`${today} 09:00`),
    moment(`${today} 18:00`),
    moment(`${tomorrow} 09:00`)
  ]
}