import {AggregatedProjectNotification} from 'domain/ProjectNotification'
import {ReactComponent as NotificationIcon} from '../../assets/icons/notifications.svg'
import React, {useContext, useEffect, useState} from 'react'
import {OverlayTrigger, Popover} from 'react-bootstrap'
import {getProjectNotifications, readAllProjectNotifications} from '../../api-clients/projectNotificationClient'
import {UserContext} from '../../providers/UserProvider'
import websocket from '../../websocket'
import PaginatedView from '../utils/PaginatedView'
import ProjectNotificationCard from './ProjectNotificationCard'

const NotificationBadge: React.FC = () => {
  const {user} = useContext(UserContext)
  const [hasUnread, setHasUnread] = useState(false)

  useEffect(() => {
    getProjectNotifications().then(notifications => {
      setHasUnread(!!notifications.collection.find(n => !n.isRead))
    })

    websocket.onProjectNotificationCreated(user._id, () => {
      setHasUnread(true)
    })

    return () => {
      websocket.stopListeningProjectNotificationCreated(user._id)
    }
  }, [user])

  const projectNotificationComponent = (notification: AggregatedProjectNotification, i: number) => {
    return (
      <div className={i === 0 ? '' : 'mt-1'}>
        <ProjectNotificationCard projectNotification={notification} />
      </div>
    )
  }

  const dropdown = (
    <Popover
      id="notifications-dropdown"
      className="nav-settings-tooltip nav-settings-notifications-container"
      onClick={() => document.body.click()}
    >
      <Popover.Body className="p-0">
        <div className="nav-settings-dropdown nav-settings-notifications">
          <PaginatedView fetchCollection={getProjectNotifications} render={projectNotificationComponent} />
        </div>
      </Popover.Body>
    </Popover>
  )

  const onOpened = () => {
    if (!hasUnread) return
    readAllProjectNotifications().then(() => {
      setHasUnread(false)
    })
  }

  return (
    <OverlayTrigger overlay={dropdown} rootClose placement="bottom" trigger="click" delay={0} onEntered={onOpened}>
      <button className="btn btn-link nav-link btn-sm d-flex align-items-center position-relative">
        <div className="d-inline-flex">
          <NotificationIcon className="icon" />
          {hasUnread && (
            <div className="badge badge-circle-sm bg-danger position-absolute badge-top-overlap">&nbsp;</div>
          )}
        </div>
      </button>
    </OverlayTrigger>
  )
}

export default NotificationBadge
