import {ReactElement, ReactNode, useContext} from 'react'
import {ModalContext} from '../providers/ModalProvider'


interface ModalState {
  showModal: (modal: ReactNode) => void
  closeModal: () => void
  closeLastModal: () => void
  components: ReactElement[]
}

const useModal = (): ModalState => {
  const {addComponent, setComponents, components} = useContext(ModalContext)

  const showModal = (modal: ReactNode) => {
    addComponent(modal)
  }

  const closeModal = () => {
    setComponents([])
  }

  const closeLastModal = () => {
    setComponents((components: ReactNode[]) => components.slice(0, components.length - 1))
  }

  return {showModal, closeModal, closeLastModal, components}
}

export default useModal