import {EditorGridBlock, EditorGridBlockData} from 'domain/Editor'
import {Locale} from 'domain/Locale'
import {GridBlock} from 'domain/Report'
import BlocksEditor from './BlocksEditor'
import Converter from './Converter'


class GridConverter extends Converter<GridBlock, EditorGridBlock> {
  toEditor(block: GridBlock, locale: Locale = Locale.JA): EditorGridBlock {
    const {id, type, border, grid, title, description, equalColumnWidth, checked} = block

    const convertedBlocksInGrid = (grid[locale] || []).map(row => (
      row.map(cell => ({
        id: cell.id,
        blocks: new BlocksEditor(cell.blocks).convertToEditor(locale)
      }))
    ))

    const data: EditorGridBlockData = {
      border,
      equalColumnWidth,
      title: title?.[locale]?.value || '',
      description: description?.[locale]?.value || '',
      grid: convertedBlocksInGrid
    }

    if (checked) {
      data.checked = checked
    }

    return {id, type, data}
  }

  fromEditor(editorBlock: EditorGridBlock, locale: Locale = Locale.JA) : GridBlock {
    const {id, type, data} = editorBlock
    const {grid, border, title, description, equalColumnWidth, checked} = data

    const convertedBlocksInGrid = grid.map(row => (
      row.map(cell => ({
          id: cell.id,
          blocks: BlocksEditor.convertFromEditor(cell.blocks, locale)
        }
      ))
    ))

    const blockData: GridBlock = {
      id,
      type,
      border,
      equalColumnWidth,
      title: {[locale]: {value: title}},
      description: {[locale]: {value: description}},
      grid: {[locale]: convertedBlocksInGrid}
    }

    if (checked) {
      blockData.checked = checked
    }

    return blockData
  }
}

const gridConverter = new GridConverter()
export default gridConverter