import {Permission} from 'domain/Permission'
import {isTranslator, Translator, User} from 'domain/User'
import {ReactComponent as EditIcon} from '../../assets/icons/edit.svg'
import {ReactComponent as PersonIcon} from '../../assets/icons/person.svg'
import React, {ReactElement, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useParams} from 'react-router-dom'
import {getUser} from '../../api-clients/userClient'
import useModal from '../../hooks/useModal'
import Protected from '../utils/Protected'
import EditUser from './EditUser'
import TranslatorProfile from './TranslatorProfile'
import UserDetails from './UserDetails'
import UserPageProjects from './UserPageProjects'
import UserSchedulePane from './UserSchedulePane'

interface RouteParams {
  id: string
}

const UserPage: React.FC = (): ReactElement => {
  const {t} = useTranslation()
  const {id} = useParams<RouteParams>()
  const {showModal} = useModal()
  const [user, setUser] = useState<User | null>(null)

  useEffect(() => {
    getUser(id).then(setUser)
  }, [])

  const editUser = (e: React.MouseEvent) => {
    e.preventDefault()
    showModal(<EditUser user={user!} onUpdated={setUser} />)
  }

  return (
    <>
      <div className="panes panes-fill-height">
        <div className="pane">
          <div className="pane-header">
            <h4 className="pane-title">{t('components.UserPage.title')}</h4>
          </div>
          <div className="pane-body">
            <div className="card mb-3">
              <div className="card-header d-flex align-items-center">
                <PersonIcon className="icon text-muted me-2" />
                <div className="flex-grow-1">
                  <h5 className="mb-0">{user?.name}</h5>
                </div>
                <div className="d-flex"></div>
              </div>
              <div className="card-body">{user && <UserDetails user={user} />}</div>
            </div>

            <div className="card mb-3">
              <div className="card-header d-flex align-items-center">
                <h5 className="card-title d-flex justify-content-between mb-0">{t('components.UserPage.profile')}</h5>

                <Protected permissions={[Permission.User.UPDATE_FULL, Permission.User.UPDATE_PROFILE]}>
                  <div className="card-header-actions">
                    <button
                      title={t('components.UserPage.edit')}
                      onClick={editUser}
                      className="btn btn-outline-secondary btn-sm btn-icon-only"
                    >
                      <EditIcon className="icon icon-sm" />
                    </button>
                  </div>
                </Protected>
              </div>

              <div className="card-body">
                <div>
                  <Protected permission={Permission.User.READ_PROFILE}>
                    {user?.notes ? (
                      <div className="alert alert-warning">
                        <div className="small">{user?.notes}</div>
                      </div>
                    ) : (
                      <></>
                    )}
                  </Protected>
                  <div className="data-list data-list-row data-list-wrappable">
                    <Protected permission={Permission.User.READ_PROFILE}>
                      <>{user && isTranslator(user.roles) && <TranslatorProfile user={user as Translator} />}</>
                    </Protected>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {user && <UserPageProjects user={user} />}

        {user && (
          <Protected permission={Permission.User.READ_SCHEDULE}>
            <UserSchedulePane user={user} />
          </Protected>
        )}
      </div>
    </>
  )
}

export default UserPage
