import {Comment} from 'domain/Comment'
import {ProjectStage, ProjectTimeline} from 'domain/Project'
import {ProjectTask, ProjectTaskWithUsers, TaskStatus} from 'domain/Task'
import {ReactComponent as CommentIcon} from '../../../assets/icons/comment.svg'
import React, {FC, ReactElement, useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {Link} from 'react-router-dom'
import {commentsWithRepliesSize} from '../../../utils/commentsUtils'
import DefaultProjectTask, {ProjectTaskProps} from '../tasks/DefaultProjectTask'
import InterviewTask from '../tasks/InterviewTask'
import PublishTask from '../tasks/PublishTask'
import TranslationWritingTask from '../tasks/TranslationWritingTask'
import {ProjectAggregate} from 'domain/ProjectAggregate'

interface Props {
  project: ProjectAggregate
  projectTasks: ProjectTaskWithUsers[]
  previousTask: ProjectTask | null
  loadProjectWithTasks: () => void
  timelinePhaseIndex: number
  comments?: Comment[]
}

const ProjectTimelinePhase: FC<Props> = (props): ReactElement => {
  const {project, timelinePhaseIndex, comments, loadProjectWithTasks, projectTasks, previousTask} = props
  const {t} = useTranslation()
  const [showCompletedTasks, setShowCompletedTasks] = useState(false)
  const timeline = projectTasks[0]?.timeline

  const completedTasks = useMemo(() => {
    return projectTasks.filter(task => task.status === TaskStatus.FINISHED)
  }, [projectTasks])

  const phaseProgressClass = useMemo(() => {
    if (projectTasks.find(task => task.status === TaskStatus.IN_PROGRESS)) return 'stepper-step-active'
    else if (projectTasks.find(task => task.status === TaskStatus.SCHEDULED)) return 'stepper-step-future'
    return 'stepper-step-success'
  }, [projectTasks])

  const stageComponent = (task: ProjectTask): React.FC<ProjectTaskProps> => {
    const stageComponentMap = {
      [ProjectStage.TRANSLATION_WRITING]: TranslationWritingTask,
      [ProjectStage.PUBLISH]: PublishTask,
      [ProjectStage.INTERVIEW]: InterviewTask,
      [ProjectStage.INTERVIEW_1]: InterviewTask,
      [ProjectStage.INTERVIEW_2]: InterviewTask
    }

    return stageComponentMap[task.stage] || DefaultProjectTask
  }

  const urlToPageWithCommentsSidebar = () => {
    if (timeline === ProjectTimeline.ORIGINAL_REPORT) {
      return `/projects/${project._id}/report/edit`
    } else {
      return `/projects/${project._id}/report/translate`
    }
  }

  const onToggleCompletedTasks = (e: React.MouseEvent) => {
    e.preventDefault()
    setShowCompletedTasks(!showCompletedTasks)
  }

  return (
    <div className={`stepper-step ${phaseProgressClass}`}>
      <div className="stepper-step-heading">
        <div className="stepper-step-title">
          <span className="stepper-step-title-icon">{timelinePhaseIndex + 2}</span>
          {timeline && t(`models.ProjectTimelinePhase.${timeline}`)}
        </div>

        {comments && comments.length > 0 && (
          <span className="btn btn-xs btn-secondary btn-with-icon">
            <Link to={{pathname: urlToPageWithCommentsSidebar(), state: {showCommentsSidebar: true}}}>
              <CommentIcon className="icon-xs" />
              <span>{commentsWithRepliesSize(comments)}</span>
            </Link>
          </span>
        )}
      </div>

      <div className="stepper-step-body">
        <div className="stepper-step-body-wrapper">
          {completedTasks.length > 0 && timeline !== ProjectTimeline.PUBLISHING && (
            <div className="text-center mb-2">
              <button className="btn btn-xs w-100 btn-outline-secondary" onClick={onToggleCompletedTasks}>
                {t(
                  showCompletedTasks
                    ? 'components.ProjectTimelinePhase.hideCompletedSteps'
                    : 'components.ProjectTimelinePhase.showCompletedSteps'
                )}
              </button>
            </div>
          )}

          {projectTasks.map((task, taskIndex) => {
            const StageComponent = stageComponent(task)
            const showTask =
              showCompletedTasks || task.status !== TaskStatus.FINISHED || task.stage === ProjectStage.PUBLISH

            return (
              showTask && (
                <StageComponent
                  project={project}
                  task={task}
                  isFirst={timelinePhaseIndex === 0 && taskIndex === 0}
                  loadProjectWithTasks={loadProjectWithTasks}
                  canBeReverted={task._id === previousTask?._id}
                  key={task._id as string}
                />
              )
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default ProjectTimelinePhase
