import {Company, CompanyStatus} from 'domain/Company'
import React, {ReactElement, useContext} from 'react'
import {useTranslation} from 'react-i18next'
import {changeCompanyStatus} from '../../api-clients/companyClient'
import {LocaleContext} from '../../providers/LocaleProvider'
import ConfirmationModal from '../utils/ConfirmationModal'


interface Props {
  company: Company,
  onChange: () => void
}

const ToggleCompanyStatus: React.FC<Props> = ({company, onChange}): ReactElement => {
  const {t} = useTranslation()
  const {locale} = useContext(LocaleContext)

  const isCompanyActive = company.status === CompanyStatus.ACTIVE
  const confirmationMessage = t(
    isCompanyActive ? 'components.ToggleCompanyStatus.deactivate' : 'components.ToggleCompanyStatus.activate',
    {company: company.name[locale]}
  )
  const newStatus = isCompanyActive ? CompanyStatus.INACTIVE : CompanyStatus.ACTIVE
  const onConfirm = () => changeCompanyStatus(company._id, newStatus).then(onChange)

  return <ConfirmationModal title={t('components.ToggleCompanyStatus.title')} body={confirmationMessage}
                            onConfirm={onConfirm}/>
}

export default ToggleCompanyStatus
