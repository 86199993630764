import {renewAccessToken} from './api-clients/authClient'


const REFRESH_TOKEN_INTERVAL = 30 * 60 * 1000 // 30 min

class TokenStorage {

  private timerId: NodeJS.Timeout | null = null
  private token: string | null = null

  setToken(token: string | null) {
    this.token = token

    if (token) {
      this.initiateTokenRefreshing()
    } else {
      this.stopTokenRefreshing()
    }
  }

  getToken() {
    return this.token || ''
  }

  private initiateTokenRefreshing() {
    if (!this.timerId) {
      this.timerId = setInterval(() => {
        renewAccessToken().then(tokens => {
          this.token = tokens.accessToken
        }).catch(console.warn)
      }, REFRESH_TOKEN_INTERVAL) as unknown as NodeJS.Timeout
    }
  }

  private stopTokenRefreshing() {
    if (this.timerId) {
      clearInterval(this.timerId)
      this.timerId = null
    }
  }
}

const tokenStorage = new TokenStorage()
export default tokenStorage