import {CommentWithUserName} from 'domain/Comment'
import {Locale} from 'domain/Locale'
import {PageType} from 'domain/PageType'
import {Permission} from 'domain/Permission'
import {emptyGroupedNews, GroupedNews} from 'domain/Project'
import {ReactComponent as CommentIcon} from '../../assets/icons/comment.svg'
import React, {ReactElement, useContext, useEffect, useState} from 'react'
import useForceRender from '../../hooks/useForceRender'
import {useProjectDocumentTitle} from '../../hooks/useProjectDocumentTitle'
import useReportPage from '../../hooks/useReportPage'
import {CommentContext} from '../../providers/CommentProvider'
import {ReportContext} from '../../providers/ReportProvider'
import Modal from '../utils/Modal'
import Protected from '../utils/Protected'
import Spinner from '../utils/Spinner'
import Comments from './comments/Comments'
import CommentsSidebar from './comments/CommentsSidebar'
import EditableReportSection from './edit/EditableReportSection'
import SectionOrNewsReleases from './edit/SectionOrNewsReleases'
import LanguageSwitcher from './LanguageSwitcher'
import Sidebar from './navigation/Sidebar'
import ReportNavigation from './report-navigation/ReportNavigation'
import ShowIf, {notNewsUpdateType} from './ShowIf'

const ProofreadingPage: React.FC = (): ReactElement => {
  const {getGroupedNews} = useReportPage()
  const {getLocaleComments, comments} = useContext(CommentContext)
  const {project, company, reportStore} = useContext(ReportContext)
  const [localeComments, setLocaleComments] = useState<CommentWithUserName[]>([])
  const [showCommentsSidebar, setShowCommentsSidebar] = useState(false)
  const [groupedNews, setGroupedNews] = useState<GroupedNews>(emptyGroupedNews)
  const [locale, setLocale] = useState<Locale>(Locale.JA)
  const {rerender} = useForceRender()
  useProjectDocumentTitle(project, PageType.PROOFREADING)

  useEffect(() => {
    reportStore.onUpdateStructure(() => {
      rerender()
    })
  }, [])

  useEffect(() => {
    getGroupedNews(locale).then(setGroupedNews)
  }, [locale])

  useEffect(() => {
    setLocaleComments(getLocaleComments(locale))
  }, [comments, locale])

  const report = reportStore.getReport()

  const showComments = (e: React.MouseEvent) => {
    e.preventDefault()
    setShowCommentsSidebar(true)
  }

  const closeComments = () => {
    setShowCommentsSidebar(false)
  }

  if (!report) {
    return <Spinner withDimmer />
  } else {
    return (
      <>
        <div className="navbar navbar-secondary">
          {project && company && (
            <ReportNavigation
              titleKey={'components.Report.EditReport.title'}
              project={project}
              company={company}
              locale={locale}
            >
              <div className="btn-group ms-2" role="group">
                <Protected permission={Permission.Comment.READ}>
                  <div className="nav-item">
                    <a className="nav-link" href="#" onClick={showComments}>
                      <CommentIcon className="icon" />
                    </a>
                  </div>
                </Protected>
                <LanguageSwitcher activeLocale={locale} setLocale={setLocale} />
              </div>
            </ReportNavigation>
          )}
        </div>

        <div className="report-content-wrapper">
          {report && (
            <ShowIf rule={notNewsUpdateType}>
              <Sidebar report={report} locale={locale} displayMenu={false} groupedNews={groupedNews} />
            </ShowIf>
          )}

          <div className="report-content report-edit report-edit-original flex-grow-1 report-proofreading">
            {project && report && company && (
              <>
                <div className="report-comments-visible report-comments-visible-normal-editor">
                  <div className="container container-editor show-comments report-with-comments">
                    {report.sections.map((section, i) => {
                      return (
                        <SectionOrNewsReleases
                          key={`section-${section._id}`}
                          section={section}
                          locale={locale}
                          groupedNews={groupedNews}
                          render={() => (
                            <EditableReportSection
                              sectionId={section._id}
                              key={`section-${section._id}`}
                              reportStore={reportStore}
                              baseReport={null}
                              autofocus={i === 0}
                              project={project}
                              locale={locale}
                              readonly={true}
                            />
                          )}
                        />
                      )
                    })}
                  </div>
                  <Comments locale={locale} />
                </div>
              </>
            )}
          </div>
        </div>
        {showCommentsSidebar && (
          <Modal
            component={<CommentsSidebar localeComments={localeComments} onClose={closeComments} />}
            onClose={closeComments}
          />
        )}
      </>
    )
  }
}

export default ProofreadingPage
