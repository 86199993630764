const debounce = <F extends (...args: any[]) => any>(func: F, waitFor: number) => {
  let timeout: ReturnType<typeof setTimeout> | null = null

  const debounced = (...args: any[]) => {
    if (timeout !== null) {
      clearTimeout(timeout)
      timeout = null
    }

    timeout = setTimeout(() => func(...args), waitFor)
  }

  return debounced as (...args: any[]) => ReturnType<F>
}

export default debounce