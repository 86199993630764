import {PermissionType} from 'domain/Permission'
import jwt from 'jsonwebtoken'
import React, {ReactNode, useState} from 'react'
import {getPermissions} from '../api-clients/permissionClient'
import {msClarityUser} from '../MSClarityUser'
import tokenStorage from '../TokenStorage'

export const UserContext = React.createContext({} as any)

export interface UserData {
  _id: string
  name: string
  email: string
  role: string
  companyId: string
}

const UserProvider = ({children}: { children: ReactNode }) => {
  const [user, setUser] = useState<UserData | null>(null)
  const [permissions, setPermissions] = useState<PermissionType[]>([])

  const setAccessToken = (token: string) => {
    const payload = jwt.decode(token)
    tokenStorage.setToken(token)
    setUser(payload as any)
    msClarityUser.login(payload as UserData)
    getPermissions().then(permissions => {
      setPermissions(permissions)
    })
  }

  const logoutUser = () => {
    setUser(null)
    tokenStorage.setToken(null)
    msClarityUser.logout()
  }

  const hasPermission = (permission: PermissionType): boolean => {
    return permissions.includes(permission)
  }

  const state = {
    user,
    logoutUser,
    setAccessToken,
    permissions,
    hasPermission
  }

  return (
    <UserContext.Provider value={state}>
      {children}
    </UserContext.Provider>
  )
}

export default UserProvider
