import {Locale} from 'domain/Locale'
import {AggregatedMicroTranslation, MicroTranslation, MicroTranslationProject} from 'domain/MicroTranslation'
import React, {useContext} from 'react'
import {LocaleContext} from '../../../providers/LocaleProvider'
import MicroTranslationColumn from './MicroTranslationColumn'
import MicroTranslationTranslatorLabel from './MicroTranslationTranslatorLabel'


type Props = {
  microTranslation: AggregatedMicroTranslation
  project: MicroTranslationProject
  onPublished: (microTranslation: MicroTranslation) => void
}

const MicroTranslationRow: React.FC<Props> = ({microTranslation, project, onPublished}) => {
  const {locale} = useContext(LocaleContext)

  return <div>
    <MicroTranslationTranslatorLabel
      userName={microTranslation.user.name} companyName={microTranslation.user.companyName}
      sentAt={microTranslation.sentAt} locale={locale}/>

    <div className="card-group">
      <MicroTranslationColumn locale={Locale.JA}
                              microTranslation={microTranslation} project={project} onPublished={onPublished}/>
      <MicroTranslationColumn locale={Locale.EN}
                              microTranslation={microTranslation} project={project} onPublished={onPublished}/>
    </div>
  </div>
}

export default MicroTranslationRow