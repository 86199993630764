import {Company} from 'domain/Company'
import React, {FC, ReactElement, useContext} from 'react'
import SelectSearch from 'react-dropdown-select'
import {useTranslation} from 'react-i18next'
import {LocaleContext} from '../../providers/LocaleProvider'
import sortByTick from './sortByTick'

interface CompanySelectProps {
  id: string
  companies: Company[]
  selectedValue?: string
  onChange: (selectedValue: string) => void
  defaultValue?: SelectOption
  clearable?: boolean
  name?: string
}

export type SelectOption = {
  label: string
  value: string
}

const CompanySelect: FC<CompanySelectProps> = (props): ReactElement => {
  const {companies, selectedValue, onChange, id, defaultValue, clearable, name} = props
  const {locale} = useContext(LocaleContext)
  const onCompanyChange = (options: SelectOption[]) => onChange(options[0]?.value || '')
  const {t} = useTranslation()

  const selectOptions = sortByTick(companies)
    .map(company => ({
      label: `${company.tick} - ${company.name[locale]}`,
      value: company._id as string
    }))
    .prependIfPresent(defaultValue)

  const selectedOption = () => selectOptions.filter(option => option.value === selectedValue)

  return (
    <SelectSearch
      options={selectOptions}
      multi={false}
      required={true}
      additionalProps={{id}}
      name={name}
      style={{minWidth: '200px'}}
      clearable={clearable}
      onChange={onCompanyChange}
      searchable={true}
      values={selectedOption()}
      noDataLabel={t('components.CompanySelect.noMatches')}
      placeholder={t('components.CompanySelect.placeholder')}
    />
  )
}

export default CompanySelect
