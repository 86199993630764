import {ProjectStage} from 'domain/Project'
import {TranslationRequestStatus} from 'domain/TranslationRequest'
import React, {ReactElement, useContext, useEffect, useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {getCurrentUserProjects, getPendingTranslatorConfirmationProjects} from '../../api-clients/projectClient'
import {UserContext} from '../../providers/UserProvider'
import InTranslationCard from '../translations-dashboard/cards/InTranslationCard'
import PendingConfirmationCard from '../translations-dashboard/cards/PendingConfirmationCard'
import Pane from '../utils/Pane'
import ProjectCard from './ProjectCard'
import {ProjectAggregate} from 'domain/ProjectAggregate'


const ExternalTranslatorProjectsPage: React.FC = (): ReactElement => {
  const {t} = useTranslation()
  const {user} = useContext(UserContext)

  const [projects, setProjects] = useState<ProjectAggregate[]>([])

  const userProjects = useMemo(() => projects.filter(p => p.translatorId === user._id), [projects])
  const pendingProjects = useMemo(() => {
    return projects.filter(project => {
      const lastRequest = project.translationRequests.last()
      return lastRequest?.status === TranslationRequestStatus.PENDING && lastRequest?.translatorId === user._id
    })
  }, [projects])

  useEffect(() => {
    Promise.all([
      getCurrentUserProjects(),
      getPendingTranslatorConfirmationProjects()
    ]).then(([userProjects, pendingProjects]) => {
      setProjects([...userProjects, ...pendingProjects])
    })
  }, [])

  const onProjectUpdate = (updatedProject: ProjectAggregate) => {
    setProjects(projects => projects.map(project => project._id === updatedProject._id ? updatedProject : project))
  }

  const projectCard = (project: ProjectAggregate) => {
    if (project.activeTask?.stage === ProjectStage.TRANSLATION_WRITING) {
      return <InTranslationCard project={project} onUpdated={onProjectUpdate}/>
    } else {
      return <ProjectCard project={project}/>
    }
  }

  return (
    <div className="panes panes-fill-height">
      <div className="pane-sidebar">
        <div className="pane-body pt-3">
          <div className="nav flex-column nav-pills nav-pills-secondary">
            <a className={`nav-link mb-1 active`} href="">
              {t(`components.ExternalTranslatorProjectsPage.myProjects`)}
            </a>
            <a className={`nav-link mb-1 active`} href="">
              {t(`components.ExternalTranslatorProjectsPage.pendingConfirmation`)}
            </a>
          </div>
        </div>
      </div>

      <div className="panes-horizontal-scoller">
        <Pane title={t(`components.ExternalTranslatorProjectsPage.myProjects`)}>
          {userProjects.map(project => {
            return <React.Fragment key={project._id as string}>{projectCard(project)}</React.Fragment>
          })}
        </Pane>

        <Pane title={t(`components.ExternalTranslatorProjectsPage.pendingConfirmation`)}>
          {pendingProjects.map(project => {
            return <PendingConfirmationCard key={project._id as string} project={project} onUpdated={onProjectUpdate}/>
          })}
        </Pane>
      </div>
    </div>
  )
}

export default ExternalTranslatorProjectsPage