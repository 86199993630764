import {Locale} from 'domain/Locale'
import {ParagraphBlock} from 'domain/Report'
import React, {ReactElement} from 'react'
import RawHtml from '../../utils/RawHtml'


interface ParagraphProps {
  block: ParagraphBlock,
  locale: Locale
}

const Paragraph: React.FC<ParagraphProps> = ({block, locale}): ReactElement => {
  return block.text[locale]?.value ?
    <p className={`paragraph paragraph-${block?.style}`}><RawHtml>{block.text[locale]?.value || ''}</RawHtml></p> :
    <></>
}

export default Paragraph
