import {Locale} from 'domain/Locale'
import {GroupedNews, ProjectTimeline} from 'domain/Project'
import {ReportData, SectionType} from 'domain/Report'
import {ProjectTask} from 'domain/Task'
import {diffDaysBetweenDates} from './momentUtils'
import {ProjectAggregate} from 'domain/ProjectAggregate'


export const buildReportWithNewsReleases = (report: ReportData, groupedNews?: GroupedNews,
                                            displayNewsIfEmpty = true) => {
  const reportWithFilteredSections = {
    ...report,
    sections: report.sections.filter(section => {
      if (displayNewsIfEmpty) return true
      if (section.type === SectionType.NEWS_AND_TOPICS) return groupedNews && groupedNews.newsAndTopics.length > 0
      if (section.type === SectionType.RECENT_UPDATES) return groupedNews && groupedNews.recentUpdates.length > 0
      return true
    })
  }

  if (!groupedNews) return reportWithFilteredSections

  return {
    ...reportWithFilteredSections,
    sections: reportWithFilteredSections.sections.map(section => {
      if (section.type === SectionType.NEWS_AND_TOPICS) {
        const blocks = groupedNews.newsAndTopics.flatMap(project => project.report.sections.flatMap(s => s.blocks))
        return {...section, blocks: [section.blocks[0], ...blocks]}
      }
      if (section.type === SectionType.RECENT_UPDATES) {
        const blocks = groupedNews.recentUpdates.flatMap(project => project.report.sections.flatMap(s => s.blocks))
        return {...section, blocks: [section.blocks[0], ...blocks]}
      }
      return section
    })
  }
}

export const calculateProgress = (project: ProjectAggregate) => {
  return project.isFinished ? 100 : project.currentTaskIndex / project.tasksCount * 100
}

export const expectedDaysOverdue = (project: ProjectAggregate, locale: Locale) => {
  if (!project.deadline?.[locale]) return 0
  return diffDaysBetweenDates(new Date(project.expectedDeadline[locale]), new Date(project.deadline[locale]!))
}

export const taskLocale = (task: ProjectTask) => {
  switch (task.timeline) {
    case ProjectTimeline.INTERVIEWS:
    case ProjectTimeline.ORIGINAL_REPORT:
      return Locale.JA
    default:
      return Locale.EN
  }
}