import {ObjectId} from 'bson'
import {hasCommentWriteAccess} from 'domain/access'
import {Id} from 'domain/Entity'
import {GlossaryAlternativeTerm, TermTranslation} from 'domain/Glossary'
import {Locale} from 'domain/Locale'
import {Permission} from 'domain/Permission'
import {Block, BlockType} from 'domain/Report'
import React, {ReactElement, useContext, useEffect, useRef, useState} from 'react'
import ReactDOM from 'react-dom'
import {useTranslation} from 'react-i18next'
import EventBus, {EventType} from '../../../EventBus'
import useToast from '../../../hooks/useToast'
import {CommentContext} from '../../../providers/CommentProvider'
import {DiffDeletionsContext} from '../../../providers/DiffDeletionsProvider'
import {ReportContext} from '../../../providers/ReportProvider'
import {TranslatePageContext} from '../../../providers/TranslatePageProvider'
import {UserContext} from '../../../providers/UserProvider'
import memoizeComponent from '../../../utils/memoizeComponent'
import {clonedBlockStyle} from '../../../utils/reportHelpers'
import Protected from '../../utils/Protected'
import Editor, {EditorMode} from '../edit/Editor'
import ReadonlyBlocks from '../view/ReadonlyBlocks'
import {GlossaryAlternativeTermWithTick} from './BlockTranslator'
import InlineTranslation from './InlineTranslation'
import TranslationGridToolbar from './TranslationGridToolbar'
import {autoTranslateBlock, highlightUntranslatedWord, unescapeHtmlTagSymbols} from './translationUtils'

interface TranslatedBlockProps {
  sectionId: string
  block: Block
  locale: Locale
  onClick: () => void
  setUntranslatedTerms?: (terms: string[]) => void
}

const TranslatedBlock: React.FC<TranslatedBlockProps> = (props): ReactElement => {
  const {sectionId, block, locale, onClick, setUntranslatedTerms} = props

  const {t, i18n} = useTranslation()
  const {company, reportStore, project} = useContext(ReportContext)
  const {addComment} = useContext(CommentContext)
  const {onApplyDeletionVisibility} = useContext(DiffDeletionsContext)
  const {renderSuccessToast, renderErrorToast} = useToast()

  const {companies, addGlossaryTerm, setShowTranslationSidebar} = useContext(TranslatePageContext)
  const [highlightedTerm, setHighlightedTerm] = useState<string | null>()
  const editorRef = useRef<HTMLDivElement>(null)
  const [editable, setEditable] = useState(false)
  const {user, hasPermission} = useContext(UserContext)

  const onActiveBlockChange = async (blocks: Block[]) => {
    reportStore.updateSectionBlock(sectionId, blocks[0], locale)
  }

  const isSource = locale === Locale.JA
  const sourceClass = isSource ? 'report-block-source' : 'report-editor-activate'
  const contentEmptyClass = locale !== Locale.JA && isContentEmpty(block, locale) ? 'report-block-empty' : ''
  const canComment = hasPermission(Permission.Comment.MANAGE) && hasCommentWriteAccess(user, project!)

  useEffect(() => {
    const listener = (event: Event) => {
      const {term} = (event as Event & {detail: {term: string | null}}).detail
      setHighlightedTerm(term)
    }

    EventBus.on(EventType.HOVER, listener)
    return () => EventBus.unsubscribe(EventType.HOVER, listener)
  }, [])

  useEffect(() => {
    const onActivate = (event: Event) => {
      const blockId = (event as any).detail.blockId
      if (block.id === blockId) setEditable(true)
    }

    EventBus.on(EventType.ACTIVATE_TRANSLATED_BLOCK, onActivate)
    return () => EventBus.unsubscribe(EventType.ACTIVATE_TRANSLATED_BLOCK, onActivate)
  }, [])

  useEffect(() => {
    if (!editable || !editorRef.current) return
    highlightUntranslatedWord(editorRef.current, highlightedTerm)
  }, [highlightedTerm])

  useEffect(() => {
    onApplyDeletionVisibility()
  }, [block])

  const findParent = (el: Element) => {
    const selector = ['td', '.entity-title', '.entity-description'].find(selector => !!el.closest(selector))
    return selector && el.closest(selector)!
  }

  const parseCollection = (json?: string) => (json ? JSON.parse(unescapeHtmlTagSymbols(json)) : [])

  const candidatesWithTicks = (span: HTMLElement): GlossaryAlternativeTermWithTick[] => {
    const candidates = parseCollection(span.dataset.candidates) as GlossaryAlternativeTerm[]
    return candidates.map(candidate => ({...candidate, tick: companyTick(candidate.companyId)}))
  }

  const companyTick = (id?: Id): string | undefined => {
    return companies.find(company => company._id === id)?.tick
  }

  const onReadonlyBlockClick = (_: React.MouseEvent) => {
    onClick()
    setEditable(true)
  }

  if (block.type === BlockType.BOOKMARK) {
    return <></>
  }

  let translatedBlockHeight
  if (editable) {
    const translatedBlockParent = document
      .querySelector(`.report-edit-side-by-side-last div[id="${block.id}"]`)
      ?.closest('.report-content-block') // only for readonly block
      ?.closest('.report-edit-side-by-side-last') as HTMLElement

    if (translatedBlockParent) {
      translatedBlockHeight = translatedBlockParent.offsetHeight
    }
  }

  const resetMinHeightOnTranslatedBlock = () => {
    const translatedBlock = document
      .querySelector(`.report-edit-side-by-side-last div[id="${block.id}"]`)
      ?.closest('.report-editor-activate') as HTMLElement
    if (translatedBlock) {
      translatedBlock.style.minHeight = ''
    }
  }

  const onEditorReady = () => {
    resetMinHeightOnTranslatedBlock()
  }

  const onTranslate = async (block: Block) => {
    const {translatedBlock, untranslatedTerms} = await autoTranslateBlock(block, company!._id)
    reportStore.updateSectionBlock(sectionId, translatedBlock, locale)
    setShowTranslationSidebar(true)

    if (!untranslatedTerms.length) {
      renderSuccessToast(t('components.TranslationToolbar.translationSuccessful'))
    }
    if (setUntranslatedTerms) setUntranslatedTerms(untranslatedTerms)
    onTranslated()
  }

  const onTranslated = () => {
    setTimeout(() => {
      if (!editorRef.current) return

      editorRef.current.querySelectorAll('.translated-term').forEach(el => {
        const parent = findParent(el)
        if (parent) {
          parent.classList.add('flash-changed-cell')
          parent.addEventListener('animationend', () => el.classList.remove('flash-changed-cell'))
        }

        const span = el as HTMLSpanElement
        const sourceTerm = unescapeHtmlTagSymbols(span.dataset.sourceTerm!)
        ReactDOM.render(
          <InlineTranslation
            companyId={company?._id}
            translation={span.textContent!}
            sourceTerm={sourceTerm}
            candidates={candidatesWithTicks(span)}
            onTranslationChange={(candidate: GlossaryAlternativeTerm) => {
              const term: TermTranslation = {
                _id: new ObjectId(),
                [Locale.JA]: sourceTerm,
                [Locale.EN]: candidate[Locale.EN],
                isPrimary: false
              }
              addGlossaryTerm(term, candidate.companyId)
              span.textContent = term[Locale.EN]
            }}
          />,
          el
        )
      })
    }, 200)
  }

  return editable ? (
    <>
      <div
        className="report-block report-editor-activate"
        ref={editorRef}
        style={translatedBlockHeight ? {minHeight: translatedBlockHeight + 'px'} : {}}
      >
        {!isSource && block.type === BlockType.GRID && (
          <Protected permission={Permission.Report.UPDATE}>
            <TranslationGridToolbar sectionId={sectionId} block={block} locale={locale} />
          </Protected>
        )}
        <Editor
          blocks={[block]}
          onEdit={onActiveBlockChange}
          locale={locale}
          t={t}
          renderErrorToast={renderErrorToast}
          mode={EditorMode.TRANSLATE}
          autofocus={true}
          id={`${block.id}-${locale}`}
          onAddComment={addComment}
          canComment={canComment}
          onReady={onEditorReady}
          canAutoTranslate={hasPermission(Permission.Report.AUTO_TRANSLATE)}
          onTranslate={onTranslate}
          i18n={i18n}
          projectType={project?.type}
        />
      </div>
    </>
  ) : (
    <div
      data-locale={locale}
      className={`report-block ${sourceClass} ${contentEmptyClass} ${clonedBlockStyle(block)}`}
      onClick={onReadonlyBlockClick}
    >
      <ReadonlyBlocks blocks={[block]} locale={locale} reportState={reportStore.getReport()} />
    </div>
  )
}

const isContentEmpty = (block: Block, locale: Locale): boolean => {
  switch (block.type) {
    case BlockType.PARAGRAPH:
    case BlockType.HEADING:
      return isEmpty(block.text[locale]?.value)
    case BlockType.LIST:
      return isEmpty(block.items[locale]?.value)
    case BlockType.EXCEL_TABLE:
      return isEmpty(block.content[locale]?.value)
    case BlockType.COMMON_TABLE:
      return isEmpty(block.content[locale])
    case BlockType.ALERT:
      return isEmpty(block.message[locale]?.value)
    case BlockType.IMAGE:
      return isEmpty(block.file[locale]?.url)
    case BlockType.GRID:
      const firstRow = block.grid[locale]?.[0]
      const firstCell = firstRow?.[0]

      return (
        isEmpty(block.grid[locale]) ||
        isEmpty(firstRow) ||
        !!firstCell?.blocks.every(block => isContentEmpty(block, locale))
      )
    default:
      return false
  }
}

const isEmpty = (value: string | any[] | undefined | null) => {
  return value instanceof Array ? value.length === 0 : !value || value.trimHTMLSpaces().trim() === ''
}

export default memoizeComponent(TranslatedBlock)
