import React, {useEffect, useRef, useState} from 'react'


interface InputProps {
  value: string
  placeholder?: string
  onChange: (value: string) => void
  initiallyEditable?: boolean
  errorMessage?: string
}

const InlineEditable: React.FunctionComponent<InputProps> = (
  {value, onChange, initiallyEditable, placeholder, errorMessage}) => {

  const [editEnabled, setEditEnabled] = useState(!!initiallyEditable)
  const [newValue, setNewValue] = useState(value)
  const inputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (editEnabled) inputRef.current?.select()
  }, [editEnabled])

  const onKeyDown = (e: React.KeyboardEvent) => {
    switch (e.code) {
      case 'Enter':
        if (value !== newValue) {
          onChange(newValue)
        }

        setEditEnabled(false)
        break
      case 'Escape':
        setEditEnabled(false)
        break
      default:
        break
    }
  }

  const onBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    e.preventDefault()

    if (newValue !== value) {
      onChange(newValue)
    }

    setEditEnabled(false)
  }

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewValue(e.target.value)
  }

  return (<div className="inline-editable">
    {editEnabled ?
      <input ref={inputRef} value={newValue} placeholder={placeholder ?? ''}
             onKeyDown={onKeyDown} onChange={onInputChange} onBlur={onBlur}/> :
      <span className={`inline-editable-mock-input ${errorMessage && 'border-danger'}`}
            onClick={() => setEditEnabled(true)}>{newValue}</span>
    }
    {errorMessage && <span className="text-danger">{errorMessage}</span>}
  </div>)
}

export default InlineEditable
