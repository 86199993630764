import {ObjectId} from 'bson'
import {Company, CompanyCreate, CompanyRow, CompanyStatus} from 'domain/Company'
import {Id} from 'domain/Entity'
import {Locale} from 'domain/Locale'
import apiClient from '../ApiClient'
import {AggregatedCompany} from 'domain/Company'


const getActiveCompanies = async (): Promise<Company[]> => {
  return await apiClient.get(`/api/companies?status=${CompanyStatus.ACTIVE}`) as Company[]
}

const getAllCompanies = async (): Promise<Company[]> => {
  return await apiClient.get('/api/companies') as Company[]
}

const getCompany = async (id: string | ObjectId): Promise<AggregatedCompany> => {
  return await apiClient.get(`/api/companies/${id}`)
}

const createCompany = async (company: Partial<Company>): Promise<AggregatedCompany> => {
  return await apiClient.post('/api/companies', company)
}

const createCompanies = async (companies: CompanyRow[]): Promise<AggregatedCompany[]> => {
  return await apiClient.post('/api/companies/bulk', {companies: mapCompanies(companies)})
}

const getCompaniesBulkTemplateBlob = async (): Promise<Blob> => {
  return await apiClient.getBlob('/api/companies/bulk-template')
}

const updateCompany = async (company: Company): Promise<AggregatedCompany> => {
  return await apiClient.patch(`/api/companies/${company._id}`, company)
}

type CompanyImages = { logoImage: File | null, bgImage: File | null }

const updateCompanyImages = async (id: Id, images: CompanyImages): Promise<AggregatedCompany> => {
  const existingImages = Object.keys(images).reduce((res, key) => {
    if (images[key]) res[key] = images[key]
    return res
  }, {})

  return await apiClient.patchFile(`/api/companies/${id}/images`, {}, existingImages)
}

const deleteCompany = async (id: Id): Promise<{}> => {
  return await apiClient.delete(`/api/companies/${id}`)
}

const changeCompanyStatus = async (id: Id, status: CompanyStatus): Promise<void> => {
  await apiClient.patch(`/api/companies/${id}/status`, {status})
}

const getCompanyClassificationGICS = async (): Promise<string[]> => {
  return await apiClient.get('/api/companies/classification/gics')
}


const mapCompanies = (companies: CompanyRow[]): CompanyCreate[] => {
  return companies.map(company => {
    const mapped = {}
    Object.keys(company).forEach(key => {
      const locale = key.slice(-2)
      const property = key.slice(0, -2)
      if (Object.keys(Locale).includes(locale)) {
        mapped[property] = {...mapped[property], [locale.toLocaleLowerCase()]: company[key]}
      } else {
        const value = company[key]
        mapped[key] = key === 'financialYearEnd' && value ? value - 1 : company[key]
      }
    })
    return {...mapped, status: CompanyStatus.DRAFT} as CompanyCreate
  })
}

export {
  getActiveCompanies,
  getAllCompanies,
  getCompany,
  createCompany,
  createCompanies,
  getCompaniesBulkTemplateBlob,
  updateCompany,
  updateCompanyImages,
  deleteCompany,
  getCompanyClassificationGICS,
  changeCompanyStatus
}