import commentIcon from '../../../assets/inline-svg/insert-comment.svg'
import {API, InlineTool, SanitizerConfig} from 'vendor/editorjs/types'
import {surroundEachTextNodeInRange, surroundOneTextNode} from './selectionUtils'


type CommentToolConfig = {
  onAddComment: (elements: HTMLElement[]) => void
}

export const COMMENT_HIGHLIGHT_CLASS = 'comment-highlight'
export const COMMENT_ID_PREFIX_CLASS = 'comment-id-'
export const COMMENT_STICKY_HIGHLIGHT_CLASS = 'comment-highlight-sticky'
export const COMMENT_TAG = 'COMMENT'

class CommentTool implements InlineTool {
  private readonly api: API
  private readonly onAddComment?: CommentToolConfig['onAddComment']

  constructor({api, config}: { api: API, config?: CommentToolConfig }) {
    this.api = api
    this.onAddComment = config?.onAddComment
  }

  public static get sanitize(): SanitizerConfig {
    return {
      comment: (_: HTMLElement): any => {
        return {class: true}
      },
      'sticky-comment': (_: HTMLElement): any => {
        return {class: true}
      }
    } as any
  }

  static get isInline() {
    return true
  }

  static get availableInReadOnly() {
    return true
  }

  render() {
    const button = document.createElement('button')
    button.type = 'button'
    button.innerHTML = commentIcon
    button.classList.add(this.api.styles.inlineToolButton)

    return button
  }

  surround(range: Range) {
    const {startContainer, endContainer} = range

    const wrappedTextNodes = []

    const isOneTextNode = startContainer.nodeName === '#text' && startContainer === endContainer

    if (isOneTextNode) {
      wrappedTextNodes.push(surroundOneTextNode(range))
    } else {
      wrappedTextNodes.push(...surroundEachTextNodeInRange(range))
    }

    if (this.onAddComment) {
      this.onAddComment(wrappedTextNodes)
    }
  }

  checkState(selection: Selection): boolean {
    return false
  }
}

export default CommentTool