import {EditorAlertBlock} from 'domain/Editor'
import {Locale} from 'domain/Locale'
import {AlertBlock} from 'domain/Report'
import Converter from './Converter'

class AlertConverter extends Converter<AlertBlock, EditorAlertBlock> {
  toEditor(block: AlertBlock, locale: Locale): EditorAlertBlock {
    const {id, type, message, alertType, ...other} = block
    return {id, type, data: {...other, message: message[locale]?.value, type: alertType}}
  }

  fromEditor(editorBlock: EditorAlertBlock, locale: Locale) {
    const {id, type, data} = editorBlock
    const {message, type: alertType, ...others} = data

    return {
      id, type,
      message: {
        [locale]: {
          value: message
        }
      },
      alertType,
      ...others
    }
  }
}

export default new AlertConverter()