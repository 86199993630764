import React, {ReactNode, useState} from 'react'


export const ModalContext = React.createContext({} as any)

const ModalProvider = ({children}: { children: ReactNode }) => {
  const [components, setComponents] = useState<ReactNode[]>([])

  const addComponent = (component: ReactNode) => {
    setComponents(components => ([...components, component]))
  }

  return (
    <ModalContext.Provider value={{components, setComponents, addComponent}}>
      {children}
    </ModalContext.Provider>
  )
}

export default ModalProvider