import {Id} from 'domain/Entity'
import {GlossaryAlternativeTerm} from 'domain/Glossary'
import {Locale} from 'domain/Locale'
import React, {useState} from 'react'
import {OverlayTrigger, Popover} from 'react-bootstrap'
import {GlossaryAlternativeTermWithTick} from './BlockTranslator'
import InlineTranslationCandidate from './InlineTranslationCandidate'


interface InlineTranslationProps {
  companyId?: Id
  translation: string
  sourceTerm: string
  candidates: GlossaryAlternativeTermWithTick[]
  onTranslationChange: (translationCandidate: GlossaryAlternativeTerm) => void
}

const InlineTranslation: React.FC<InlineTranslationProps> = props => {
  const {sourceTerm, translation, candidates, onTranslationChange, companyId} = props
  const [show, setShow] = useState<boolean | undefined>()

  const chooseTranslation = (e: React.MouseEvent, translationCandidate: GlossaryAlternativeTerm) => {
    e.preventDefault()
    onTranslationChange(translationCandidate)
    setShow(false)
  }

  const hasVerifiedCompanyTranslation = candidates[0]?.type === 0

  const translationPopover = (
    <Popover id="translation-popover">
      <Popover.Header as="div">
        <div className="fw-bolder">{sourceTerm}</div>
      </Popover.Header>

      <Popover.Body>
        <div className="popover-menu">
          {candidates.map((candidate, i) => (
            <InlineTranslationCandidate candidate={candidate} translation={translation} companyId={companyId}
                                        onTranslationChosen={chooseTranslation} key={`${i}-${candidate[Locale.EN]}`}
                                        hasVerifiedCompanyTranslation={hasVerifiedCompanyTranslation}/>
          ))
          }
        </div>
      </Popover.Body>
    </Popover>
  )

  return (
    <OverlayTrigger overlay={translationPopover} placement="bottom"
                    rootClose trigger="click" show={show}>
      <span className="text-info">{translation}</span>
    </OverlayTrigger>
  )
}

export default InlineTranslation