import {Locale} from 'domain/Locale'
import {ProjectTimeline as ProjectTimelineEnum} from 'domain/Project'
import {ProjectTaskWithUsers, TaskStatus} from 'domain/Task'
import React, {FC, useContext, useEffect, useMemo, useState} from 'react'
import {getProjectTasks} from '../../api-clients/projectTaskClient'
import {CommentContext} from '../../providers/CommentProvider'
import websocket from '../../websocket'
import PreparationTimelinePhase from './phases/PreparationTimelinePhase'
import ProjectTimelinePhase from './phases/ProjectTimelinePhase'
import {ProjectAggregate} from 'domain/ProjectAggregate'

interface Props {
  project: ProjectAggregate
  loadProject: () => void
}

const ProjectTimeline: FC<Props> = props => {
  const {project, loadProject} = props
  const [projectTasks, setProjectTasks] = useState<ProjectTaskWithUsers[]>([])
  const {unresolvedComments} = useContext(CommentContext)
  const [timelines, setTimelines] = useState<ProjectTimelineEnum[]>([])

  useEffect(() => {
    loadProjectTasks()
  }, [project._id])

  useEffect(() => {
    websocket.onProjectUpdated(project._id, loadProjectTasks)

    return () => {
      websocket.stopListeningProjectUpdated(project._id)
    }
  }, [project._id])

  const taskTimelines = (tasks: ProjectTaskWithUsers[]) => {
      return tasks.reduce((timelines, task) => {
      const taskTimeline = task.timeline
      if (!timelines.includes(taskTimeline)) {
        timelines.push(taskTimeline)
      }
      return timelines
    }, [] as ProjectTimelineEnum[])
  }

  const loadProjectTasks = () => {
    getProjectTasks(project._id).then(tasks => {
      setTimelines(taskTimelines(tasks))
      setProjectTasks(tasks)
    })
  }

  const loadProjectWithTasks = () => {
    loadProjectTasks()
    loadProject()
  }

  const previousTask = useMemo(() => {
    return projectTasks.filter(task => task.status === TaskStatus.FINISHED).last()
  }, [projectTasks])

  const timelinePhaseComments = (timeline: ProjectTimelineEnum) => {
    if (timeline === ProjectTimelineEnum.ORIGINAL_REPORT) {
      return unresolvedComments(Locale.JA)
    }
    if (timeline === ProjectTimelineEnum.TRANSLATED_REPORT) {
      return unresolvedComments(Locale.EN)
    }
    return []
  }

  return (
    <div className="stepper stepper-vertical">
      <PreparationTimelinePhase project={project} projectTasks={projectTasks}
                                loadProjectWithTasks={loadProjectWithTasks}/>

      {projectTasks.length > 0 && timelines.map((timeline, i) => {
        return <ProjectTimelinePhase key={timeline}
                                     project={project}
                                     comments={timelinePhaseComments(timeline)}
                                     loadProjectWithTasks={loadProjectWithTasks}
                                     timelinePhaseIndex={i}
                                     previousTask={previousTask}
                                     projectTasks={projectTasks.filter(task => task.timeline === timeline)}/>
      })}
    </div>
  )
}

export default ProjectTimeline