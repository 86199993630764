import {Locale} from 'domain/Locale'
import {Permission} from 'domain/Permission'
import {hasRoles, isOnlyTranslator, Role} from 'domain/User'
import {ReactComponent as SettingsIcon} from '../../assets/icons/settings.svg'
import React, {ReactElement, useContext} from 'react'
import {Dropdown, OverlayTrigger, Popover} from 'react-bootstrap'
import DropdownItem from 'react-bootstrap/DropdownItem'
import {useTranslation} from 'react-i18next'
import {Link, NavLink, useHistory} from 'react-router-dom'
import {logout} from '../../api-clients/authClient'
import useToast from '../../hooks/useToast'
import {LocaleContext} from '../../providers/LocaleProvider'
import {UserContext} from '../../providers/UserProvider'
import Protected from '../utils/Protected'
import EnvironmentIndicationBadge from './EnvironmentIndicationBadge'
import {ReactComponent as LogoIcon} from '../../assets/images/sr-logo.svg'
import MicroTranslationsBadge from './MicroTranslationsBadge'
import NotificationBadge from './NotificationBadge'

const TopNav: React.FC = (): ReactElement => {
  const {t} = useTranslation()
  const {setLocale} = useContext(LocaleContext)
  const {logoutUser, user} = useContext(UserContext)
  const history = useHistory()
  const {renderSuccessToast} = useToast()
  const isCompanyUser = hasRoles(user?.roles, Role.COMPANY_REPRESENTATIVE)

  const onLogout = () => {
    logout().then(() => {
      logoutUser()
      history.push('/login')
      renderSuccessToast(t('components.TopNav.loggedOut'))
    })
  }

  const onLocaleChange = (locale: Locale) => {
    setLocale(locale)
    window.location.reload()
  }

  const settingsDropdown = (
    <Popover
      id="settings-dropdown"
      className="nav-settings-tooltip dropdown-menu"
      onClick={() => document.body.click()}
    >
      <Popover.Body className="p-0">
        <div className="nav-settings-dropdown">
          <Dropdown.Item onClick={() => onLocaleChange(Locale.JA)}>日本語</Dropdown.Item>
          <Dropdown.Item onClick={() => onLocaleChange(Locale.EN)}>In English</Dropdown.Item>
          <Protected permissions={[Permission.System.LARGE_PASTE_MANAGE, Permission.System.HOLIDAYS]}>
            <>
              <Dropdown.Divider />
              <Protected permission={Permission.System.LARGE_PASTE_MANAGE}>
                <Dropdown.Item href="/admin/large-pastes">{t('components.TopNav.largePastes')}</Dropdown.Item>
              </Protected>
              <Protected permission={Permission.System.HOLIDAYS}>
                <Dropdown.Item href="/admin/holidays">{t('components.TopNav.holidays')}</Dropdown.Item>
              </Protected>
            </>
          </Protected>
          <Dropdown.Divider />
          <DropdownItem onClick={() => onLogout()}>{t('components.TopNav.logOut')}</DropdownItem>
        </div>
      </Popover.Body>
    </Popover>
  )

  return (
    <nav className="navbar navbar-primary navbar-expand-lg">
      <div className="container-fluid">
        <Link to="/" className="navbar-brand">
          <LogoIcon className="logo-sm" />
        </Link>
        <EnvironmentIndicationBadge />
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav me-auto">
            {!isCompanyUser && (
              <li className="nav-item">
                <NavLink to="/schedule" className="nav-link">
                  {t('components.TopNav.schedule')}
                </NavLink>
              </li>
            )}

            <li className="nav-item">
              <NavLink to="/projects" className="nav-link">
                {t('components.TopNav.projects')}
              </NavLink>
            </li>

            <Protected permission={Permission.Project.READ_ALL}>
              <li className="nav-item">
                <NavLink to="/translations" className="nav-link">
                  {isOnlyTranslator(user.roles)
                    ? t('components.TopNav.myTranslations')
                    : t('components.TopNav.translations')}
                </NavLink>
              </li>
            </Protected>

            <Protected permission={Permission.MicroTranslation.READ}>
              <li className="nav-item">
                <NavLink to="/micro-translations" className="nav-link">
                  {t('components.TopNav.microTranslations')}
                  <MicroTranslationsBadge />
                </NavLink>
              </li>
            </Protected>

            <Protected permission={Permission.Company.READ_ALL}>
              <li className="nav-item">
                <NavLink to="/companies" className="nav-link">
                  {t('components.TopNav.companies')}
                </NavLink>
              </li>
            </Protected>

            <Protected permission={Permission.User.READ_ALL}>
              <li className="nav-item">
                <NavLink to="/users" className="nav-link">
                  {t('components.TopNav.users')}
                </NavLink>
              </li>
            </Protected>

            <Protected permission={Permission.Glossary.READ}>
              <li className="nav-item">
                <NavLink to="/glossary" className="nav-link">
                  {t('components.TopNav.glossary')}
                </NavLink>
              </li>
            </Protected>

            <Protected permission={Permission.Manual.COMPANY_REP_READ}>
              <li className="nav-item">
                <a
                  href="https://d2nyjryheal9w7.cloudfront.net/manuals/SR_Writer_Manual.20220308.pdf"
                  className="nav-link"
                  target="_blank"
                  rel="noreferrer"
                >
                  {hasRoles(user, Role.ADMIN) ? t('components.TopNav.companyManual') : t('components.TopNav.manual')}
                </a>
              </li>
            </Protected>

            <Protected permission={Permission.Manual.EXTERNAL_TRANSLATOR_READ}>
              <li className="nav-item">
                <a
                  href="https://docs.google.com/document/d/1mBOZd0UYMbhKzl1XQ88i4SJFXISfRsqc5y7N5rHYE80/edit#heading=h.v8zsfzq9euyp"
                  className="nav-link"
                  target="_blank"
                  rel="noreferrer"
                >
                  {hasRoles(user, Role.ADMIN)
                    ? t('components.TopNav.externalTranslatorManual')
                    : t('components.TopNav.manual')}
                </a>
              </li>
            </Protected>
          </ul>

          <ul className="navbar-nav">
            <li className="nav-item">
              <NotificationBadge />
            </li>
            <li className="nav-item">
              <span className="small text-muted">{user.name}</span>

              <OverlayTrigger overlay={settingsDropdown} rootClose placement="bottom" trigger="click" delay={0}>
                <button
                  aria-haspopup="true"
                  aria-expanded="false"
                  type="button"
                  className="btn btn-link nav-link btn-sm d-flex align-items-center"
                  data-testid="settings-menu"
                >
                  <SettingsIcon className="icon" />
                </button>
              </OverlayTrigger>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  )
}

export default TopNav
