import {AggregatedMicroTranslation, MicroTranslation, MicroTranslationProject} from 'domain/MicroTranslation'
import React, {useEffect, useRef, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useParams} from 'react-router-dom'
import {
  getMicroTranslationProject,
  getMicroTranslationProjectTranslations
} from '../../api-clients/microTranslationClient'
import PaneBody from '../utils/PaneBody'
import MicroTranslationDetails from './micro-translation/MicroTranslationDetails'
import MicroTranslationRow from './micro-translation/MicroTranslationRow'


const MicroTranslationPage: React.FC = () => {
  const {id} = useParams<{ id: string }>()
  const {t} = useTranslation()
  const [project, setProject] = useState<MicroTranslationProject | null>(null)
  const [translations, setTranslations] = useState<AggregatedMicroTranslation[]>([])
  const container = useRef<HTMLDivElement>(null)

  useEffect(() => {
    getMicroTranslationProject(id).then(setProject)
    getMicroTranslationProjectTranslations(id).then(setTranslations).then(() => {
      container.current!.scrollTo({top: container.current!.scrollHeight, behavior: 'auto'})
    })
  }, [])

  const onMicroTranslationPublished = (translation: MicroTranslation) => {
    setTranslations(translations => translations.map(t => t._id === translation._id ? {...t, ...translation} : t))
    getMicroTranslationProject(id).then(setProject)
  }

  if (!project) return <></>

  return <div className="panes panes-fill-height">
    <div className="pane flex-grow-0">
      <MicroTranslationDetails project={project} onProjectUpdated={setProject}/>
    </div>
    <div className="pane flex-grow-1">
      <div className="pane-header">
        <div className="pane-title">
          {t('components.MicroTranslationPage.messageBetween', {
            investor: project.investorOrganizationName,
            company: project.companyName
          })}
        </div>
      </div>
      <PaneBody ref={container}>
        {translations.map(microTranslation => {
          return <MicroTranslationRow microTranslation={microTranslation} key={microTranslation._id as string}
                                      project={project} onPublished={onMicroTranslationPublished}/>
        })}
      </PaneBody>
  </div>
</div>
}

export default MicroTranslationPage