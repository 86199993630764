import {Locale} from 'domain/Locale'
import {BlockDiffResult} from 'domain/report-diff/BlockDiffResult'
import {getWordCount} from 'domain/wordCount'
import React from 'react'
import {renderToString} from 'react-dom/server'
import {useTranslation} from 'react-i18next'
import {HtmlDiff} from '../../../htmldiff/Diff'
import memoizeComponent from '../../../utils/memoizeComponent'
import {clonedBlockStyle} from '../../../utils/reportHelpers'
import RawHtml from '../../utils/RawHtml'
import ReadonlyBlocks from '../view/ReadonlyBlocks'
import {ReportState} from 'domain/Report'

const EXCLUDED_WORDS_DATA_TAG = 'data-word-count-excluded'

interface BlockDiffProps {
  blockDiff: BlockDiffResult
  locale?: Locale
  excludedFromWordCount?: boolean
  reportState?: ReportState
}

const BlockRevisionDiff: React.FC<BlockDiffProps> = props => {
  const {reportState, blockDiff, excludedFromWordCount, locale = Locale.JA} = props
  const {t} = useTranslation()
  const prevBlocks = blockDiff.left ? [blockDiff.left] : []
  const currentBlocks = blockDiff.right ? [blockDiff.right] : []
  const previousReportHTML = renderToString(
    <ReadonlyBlocks blocks={prevBlocks} locale={locale} reportState={reportState} />
  )
  const selectedReportHTML = renderToString(
    <ReadonlyBlocks blocks={currentBlocks} locale={locale} reportState={reportState} />
  )

  let wordCount = 0

  let htmlDiff = ''

  if (!htmlDiff) htmlDiff = HtmlDiff.execute(previousReportHTML, selectedReportHTML)
  if (blockDiff.isMovedAway()) htmlDiff = `<div class="block-moved-away">${htmlDiff}</div>`
  if (blockDiff.isMovedTo()) htmlDiff = `<div class="block-moved-to">${htmlDiff}</div>`

  if (!excludedFromWordCount && /<(ins|del)/.test(htmlDiff)) {
    const blockType = blockDiff.right?.type || blockDiff.left?.type
    wordCount = getWordCount(htmlDiff, blockType!)
  }
  const excludeFromWordCountLabel = t('components.BlocksContextMenu.excludedFromWordCount')

  return (
    <div
      data-word-count={wordCount}
      className={clonedBlockStyle(blockDiff.right)}
      {...(excludedFromWordCount ? {[EXCLUDED_WORDS_DATA_TAG]: excludeFromWordCountLabel} : {})}
    >
      <RawHtml>{htmlDiff}</RawHtml>
    </div>
  )
}

export default memoizeComponent(BlockRevisionDiff)
