import {Locale} from 'domain/Locale'
import {GroupedNews} from 'domain/Project'
import {Block, BlockType, Section} from 'domain/Report'
import {isNewsUpdatesSection} from 'domain/reportUtils'
import React, {useEffect, useState} from 'react'
import EventBus, {EventType} from '../../../EventBus'
import memoizeComponent from '../../../utils/memoizeComponent'
import {clonedBlockStyle, readonlySectionBlockStyle} from '../../../utils/reportHelpers'
import SectionOrNewsReleases from '../edit/SectionOrNewsReleases'
import BlockDiff from '../merge/BlockDiff'
import ReadonlyBlocks from '../view/ReadonlyBlocks'
import TranslatedBlock from './TranslatedBlock'
import TranslationDiffToggle from './TranslationDiffToggle'

interface TranslatedReportBlocksProps {
  section: Section
  block: Block
  closeSidebar: () => void
  setUntranslatedTerms: (xs: string[]) => void
  targetLocale: Locale
  previousBlock?: Block
  showDiff: boolean
  groupedNews: GroupedNews
}

const TranslatedReportBlocks: React.FC<TranslatedReportBlocksProps> = props => {
  const {section, block, closeSidebar, setUntranslatedTerms, targetLocale, previousBlock, showDiff, groupedNews} = props
  const isOriginEditable = (block: Block) => {
    return [BlockType.EXCEL_TABLE, BlockType.IMAGE, BlockType.GRID].includes(block.type)
  }
  const [showEnglish, setShowEnglish] = useState(false)

  const setBlockSelection = (event: Event) => {
    const blockId = (event as CustomEvent).detail.blockId
    const newState = (event as CustomEvent).detail.newState
    if (!blockId || block.id === blockId) {
      setShowEnglish(newState)
    }
  }

  useEffect(() => {
    EventBus.on(EventType.TOGGLE_SHOW_JA_EN_DIFF, setBlockSelection)

    return () => {
      EventBus.unsubscribe(EventType.TOGGLE_SHOW_JA_EN_DIFF, setBlockSelection)
    }
  }, [])

  const firstBlockContent = () => {
    if (!isNewsUpdatesSection(section.type)) {
      if (showDiff) {
        return (
          <>
            <TranslationDiffToggle blockId={block.id} showEnglish={showEnglish} />
            <BlockDiff
              previousBlock={previousBlock}
              currentBlock={block}
              locale={showEnglish ? Locale.EN : Locale.JA}
            />
          </>
        )
      } else if (isOriginEditable(block)) {
        return <TranslatedBlock sectionId={section._id} block={block} locale={Locale.JA} onClick={closeSidebar} />
      } else {
        return <ReadonlyBlocks locale={Locale.JA} blocks={[block]} />
      }
    }

    return (
      <div className={`report-block ${clonedBlockStyle(block)}`}>
        {<SectionOrNewsReleases section={section} groupedNews={groupedNews} locale={Locale.JA} render={() => <></>} />}
      </div>
    )
  }

  return (
    <div className={`report-blocks-row ${readonlySectionBlockStyle(section)}`} key={`translated-block-${block.id}`}>
      <div className="report-edit report-edit-side-by-side">
        <div className="row flex-nowrap">
          <div className="col report-edit-side-by-side-first context-menu-hover-show-trigger show-comments show-inline-comments">
            {firstBlockContent()}
          </div>
          <div className="col report-edit-side-by-side-last show-comments show-inline-comments">
            {!isNewsUpdatesSection(section.type) ? (
              <TranslatedBlock
                sectionId={section._id}
                block={block}
                locale={targetLocale}
                onClick={closeSidebar}
                setUntranslatedTerms={setUntranslatedTerms}
              />
            ) : (
              <div className={`report-block ${clonedBlockStyle(block)}`}>
                {
                  <SectionOrNewsReleases
                    section={section}
                    groupedNews={groupedNews}
                    locale={Locale.EN}
                    render={() => <></>}
                  />
                }
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default memoizeComponent(TranslatedReportBlocks)
