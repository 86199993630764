import {Locale} from 'domain/Locale'
import {ProjectType} from 'domain/Project'
import {ReportType} from 'domain/ReportTemplate'


const displayLocale: Record<Locale, string> = Object.values(Locale).reduce((res, locale) => {
  res[locale] = locale === Locale.JA ? 'JP' : locale.toUpperCase()
  return res
}, {} as Record<Locale, string>)

export const formatDate = (date: Date): string => {
  return '' + date.getFullYear() + `${date.getMonth() + 1}`.padStart(2, '0') + `${date.getDate()}`.padStart(2, '0')
}

export const customReportTitle = (tick: string, locale: Locale) => {
  return `${tick}_${displayLocale[locale]}_${formatDate(new Date())}_CustomReport`
}

export const fullReportTitle = (tick: string, locale: Locale) => {
  return `${tick}_${displayLocale[locale]}_${formatDate(new Date())}`
}

export const newsReportTitle = (tick: string, locale: Locale) => {
  return `${tick}_${displayLocale[locale]}_${formatDate(new Date())}_News`
}

export const flashReportTitle = (tick: string, locale: Locale) => {
  return `${tick}_${displayLocale[locale]}_${formatDate(new Date())}_Flash`
}

export const selectedPartsTitle = (tick: string, locale: Locale) => {
  return `${tick}_${displayLocale[locale]}_${formatDate(new Date())}_Flash`
}

export const teaserReportTitle = (projectType: ProjectType, tick: string, locale: Locale) => {
  switch (projectType) {
    case ProjectType.FLASH:
      return flashReportTitle(tick, locale)
    case ProjectType.NEWS_UPDATE:
      return newsReportTitle(tick, locale)
    default:
      return fullReportTitle(tick, locale)
  }
}

export const reportTitle = (reportType: ReportType, projectType: ProjectType, locale: Locale, tick: string) => {
  const titleMap: Record<ReportType, string> = {
    [ReportType.FULL_REPORT]: fullReportTitle(tick, locale),
    [ReportType.SECTION_REPORT]: flashReportTitle(tick, locale),
    [ReportType.SELECTED_PARTS]: selectedPartsTitle(tick, locale),
    [ReportType.NEWS_REPORT]: newsReportTitle(tick, locale),
    [ReportType.TEASER]: teaserReportTitle(projectType, tick, locale)
  }

  return titleMap[reportType]
}