import {Locale} from 'domain/Locale'
import {Block} from 'domain/Report'
import React, {useContext, useEffect} from 'react'
import EventBus, {EventType} from '../../../EventBus'
import {DiffDeletionsContext} from '../../../providers/DiffDeletionsProvider'
import {ReportContext} from '../../../providers/ReportProvider'
import memoizeComponent from '../../../utils/memoizeComponent'
import {clonedBlockStyle} from '../../../utils/reportHelpers'
import RawHtml from '../../utils/RawHtml'
import {calculateDiffHTML} from '../edit/diffUtils'

interface BlockDiffProps {
  previousBlock?: Block
  currentBlock?: Block
  locale?: Locale
}

const BlockDiff: React.FC<BlockDiffProps> = props => {
  const {previousBlock, currentBlock, locale = Locale.JA} = props
  const {reportStore} = useContext(ReportContext)
  const prevBlocks = previousBlock ? [previousBlock] : []
  const currentBlocks = currentBlock ? [currentBlock] : []
  const {onApplyDeletionVisibility} = useContext(DiffDeletionsContext)

  useEffect(() => {
    EventBus.emit(EventType.HTML_DIFF_UPDATED)
    onApplyDeletionVisibility()
  }, [previousBlock, currentBlock, locale])

  return (
    <div className={clonedBlockStyle(currentBlock)}>
      <RawHtml>{calculateDiffHTML(prevBlocks, currentBlocks, locale, reportStore.getReport())}</RawHtml>
    </div>
  )
}

export default memoizeComponent(BlockDiff)
