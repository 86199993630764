import {AggregatedProjectLog} from 'domain/ProjectLog'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {formatDate} from '../../../../utils/momentUtils'
import RawHtml from '../../../utils/RawHtml'


interface Props {
  log: AggregatedProjectLog
}

const RescheduleLogLabel: React.FC<Props> = ({log}) => {
  const {t} = useTranslation()
  const username = (log.user ? log.user.name : log.userId)
  const stageTitle = t(`models.ProjectStage.${log.params.stage}.title`)

  return <RawHtml>
    {t(`components.ProjectLogs.${log.type}`, {
      username,
      stage: stageTitle,
      oldEndDate: log.params.oldEndDate ? formatDate(log.params.oldEndDate, t('dateTimeFormat')) : 'N/A',
      newEndDate: formatDate(log.params.newEndDate, t('dateTimeFormat'))
    })}
  </RawHtml>
}

export default RescheduleLogLabel