import {EditorImageBlock} from 'domain/Editor'
import {Locale} from 'domain/Locale'
import {ImageBlock} from 'domain/Report'
import Converter from './Converter'


class ImageConverter extends Converter<ImageBlock, EditorImageBlock> {
  toEditor(block: ImageBlock, locale: Locale): EditorImageBlock {
    const {id, type, title, description, file, ...other} = block

    return {
      id, type,
      data: {
        ...other,
        title: title[locale]?.value,
        description: description[locale]?.value,
        file: {
          url: file[locale]?.url,
          width: file[locale]?.width,
          height: file[locale]?.height
        }
      }
    }
  }

  fromEditor(editorBlock: EditorImageBlock, locale: Locale) {
    const {id, type, data} = editorBlock
    const {title, description, file, ...others} = data

    return {
      id, type,
      title: {
        [locale]: {
          value: title
        }
      },
      description: {
        [locale]: {
          value: description
        }
      },
      file: {
        [locale]: file
      },
      ...others
    }
  }
}

export default new ImageConverter()