import {Id} from 'domain/Entity'
import {Locale} from 'domain/Locale'
import {Block, BlockType} from 'domain/Report'
import {copyBlockTextContent} from 'domain/utils/transformBlocks'
import {ReactComponent as OtherCompanyIcon} from '../../../assets/icons/call_made.svg'
import {ReactComponent as CompanyIcon} from '../../../assets/icons/company.svg'
import {ReactComponent as GlobalIcon} from '../../../assets/icons/globe.svg'
import {ReactComponent as VerifiedIcon} from '../../../assets/icons/verified.svg'
import deepCopy from '../../../utils/deepCopy'
import BlockTranslator from './BlockTranslator'
import {isSourceBlockLocked} from './sourceBlockLockedUtils'


export const icons: Record<number, React.FunctionComponent<React.SVGProps<SVGSVGElement>>> = {
  0: VerifiedIcon,
  1: CompanyIcon,
  2: GlobalIcon,
  3: OtherCompanyIcon
}

export const escapeHtmlTagSymbols = (str: string) => {
  return str.replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/"/g, '&quot;')
    .replace(/'/g, '&#039;')
}

export const unescapeHtmlTagSymbols = (str: string) => {
  return str.replace(/&lt;/g, '<').replace(/&gt;/g, '>')
}

export const highlightUntranslatedWord = (cellContainer: HTMLElement, cellText?: string | null) => {
  const className = 'highlighted-word'

  Array.from(cellContainer.querySelectorAll(`.${className}`))
    .forEach(el => el.classList.remove(className))

  if (cellText) {
    Array.from(cellContainer.querySelectorAll('.untranslated-term'))
      .filter(el => el?.textContent === cellText)
      .forEach(el => el.classList.add(className))
  }
}

export const autoTranslateBlock = async (block: Block, companyId: Id) => {
  if (isSourceBlockLocked(block.id)) {
    return {translatedBlock: block, untranslatedTerms: []}
  }

  copyBlockContentAndLayout(block, Locale.JA, Locale.EN)
  const blockTranslator = new BlockTranslator(companyId)
  const translatedBlock = await blockTranslator.translate(block)
  return {translatedBlock, untranslatedTerms: blockTranslator.translationResult.untranslatedTerms}
}


const copyBlockContentAndLayout = (block: Block, sourceLocale: Locale, targetLocale: Locale) => {
  const copiedBlock = copyBlockTextContent(block, sourceLocale, targetLocale, true)
  Object.assign(block, copiedBlock)
  if (block.type === BlockType.COMMON_TABLE || block.type === BlockType.EXCEL_TABLE) {
    block.layout[targetLocale] = deepCopy(block.layout[sourceLocale])
  }
}