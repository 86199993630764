import {Role} from 'domain/User'
import {TFunction} from 'i18next'
import React from 'react'
import {SelectKeyDown} from 'react-dropdown-select'


export type SelectOption = {
  label: string
  value: string
}

export const onHandleKeyDownFn = ({event}: SelectKeyDown<SelectOption>) => {
  if (event.key === 'Enter') {
    event.preventDefault()
  }
}

export const localizedRoles = (roles: Role[], t: TFunction): SelectOption[] => {
  return roles.map(role => ({label: t(`models.Role.${role}`), value: role.toString()}))
}

export const rolesFromOptions = (options: SelectOption[]): Role[] => {
  return options.map(option => Role[option.value])
}