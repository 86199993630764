import deepEqual from 'fast-deep-equal'
import React from 'react'


const memoizeComponent = <T>(component: React.FC<T>) => {
  return React.memo(component, (prevProps, currentProps) => {
    return deepEqual(withoutFn(prevProps), withoutFn(currentProps))
  })
}

const withoutFn = (object: any) => {
  const res: any = {}

  Object.keys(object).forEach(key => {
    if (!(object[key] instanceof Function)) {
      res[key] = object[key]
    }
  })
  return res
}

export default memoizeComponent