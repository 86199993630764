import {isOnlyTranslator} from 'domain/User'
import React, {ReactElement, useContext, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {
  getActiveProjects,
  getPendingTranslatorConfirmationProjects,
  getUserActiveProjects,
  searchProjects
} from '../../api-clients/projectClient'
import EventBus, {EventType} from '../../EventBus'
import LocalStorage from '../../LocalStorage'
import {UserContext} from '../../providers/UserProvider'
import {
  inTranslationCompanyReviewProjects,
  inTranslationProjects,
  inTranslationReviewProjects,
  pendingTranslationConfirmationProjects,
  unassignedProjects,
  waitingOriginalProjects
} from '../projects/filterProjects'
import PaginatedView from '../utils/PaginatedView'
import Pane from '../utils/Pane'
import InReviewCard from './cards/InReviewCard'
import InTranslationCard from './cards/InTranslationCard'
import PendingConfirmationCard from './cards/PendingConfirmationCard'
import ProjectCard from './cards/ProjectCard'
import UnassignedTranslationCard from './cards/UnassignedTranslationCard'
import {ProjectAggregate} from 'domain/ProjectAggregate'


enum DashboardColumn {
  UNASSIGNED = 'UNASSIGNED',
  PENDING = 'PENDING',
  WAITING_ORIGINAL = 'WAITING_ORIGINAL',
  IN_TRANSLATION = 'IN_TRANSLATION',
  IN_REVIEW = 'IN_REVIEW',
  IN_COMPANY_REVIEW = 'IN_COMPANY_REVIEW'
}

const DEFAULT_VISIBLE_COLUMNS = [
  DashboardColumn.UNASSIGNED,
  DashboardColumn.PENDING,
  DashboardColumn.WAITING_ORIGINAL,
  DashboardColumn.IN_TRANSLATION,
  DashboardColumn.IN_REVIEW,
  DashboardColumn.IN_COMPANY_REVIEW
]

const PROJECTS_FILTER_FUNCTIONS: { [key in DashboardColumn]: (projects: ProjectAggregate[]) => ProjectAggregate[] } = {
  [DashboardColumn.UNASSIGNED]: unassignedProjects,
  [DashboardColumn.PENDING]: pendingTranslationConfirmationProjects,
  [DashboardColumn.WAITING_ORIGINAL]: waitingOriginalProjects,
  [DashboardColumn.IN_TRANSLATION]: inTranslationProjects,
  [DashboardColumn.IN_REVIEW]: inTranslationReviewProjects,
  [DashboardColumn.IN_COMPANY_REVIEW]: inTranslationCompanyReviewProjects
}

const PROJECTS_CARD: { [key in DashboardColumn]: React.FC<any> } = {
  [DashboardColumn.UNASSIGNED]: UnassignedTranslationCard,
  [DashboardColumn.PENDING]: PendingConfirmationCard,
  [DashboardColumn.WAITING_ORIGINAL]: ProjectCard,
  [DashboardColumn.IN_TRANSLATION]: InTranslationCard,
  [DashboardColumn.IN_REVIEW]: InReviewCard,
  [DashboardColumn.IN_COMPANY_REVIEW]: ProjectCard
}

const ALL_DASHBOARD_COLUMNS = Object.values(DashboardColumn)
const TRANSLATOR_DASHBOARD_COLUMNS = ALL_DASHBOARD_COLUMNS.filter(c => c !== DashboardColumn.UNASSIGNED)

const TranslationsDashboardPage: React.FC = (): ReactElement => {
  const {t} = useTranslation()
  const {user} = useContext(UserContext)
  const isTranslatorDashboard = isOnlyTranslator(user.roles)

  const [projects, setProjects] = useState<ProjectAggregate[]>([])

  const [visibleColumns, setVisibleColumns] = useState<DashboardColumn[]>(
    LocalStorage.getTranslationDashboardColumns() || DEFAULT_VISIBLE_COLUMNS
  )
  const [search, setSearch] = useState<string>('')
  const [isSearchExecuted, setIsSearchExecuted] = useState(false)

  useEffect(() => {
    (isTranslatorDashboard ?
        fetchCurrentTranslatorProjects() :
        getActiveProjects()
    ).then(setProjects)
  }, [])

  useEffect(() => {
    LocalStorage.setTranslationDashboardColumns(visibleColumns)
  }, [visibleColumns])

  const fetchCurrentTranslatorProjects = () => {
    return Promise.all([
      getUserActiveProjects(user._id),
      getPendingTranslatorConfirmationProjects()
    ]).then(([activeProjects, projectsWithTranslationRequests]) => {
      return [...projectsWithTranslationRequests, ...activeProjects]
    })
  }

  const isVisible = (column: DashboardColumn) => {
    return visibleColumns.includes(column)
  }

  const toggleColumn = (event: React.MouseEvent, column: DashboardColumn) => {
    event.preventDefault()

    if (isVisible(column)) {
      setVisibleColumns(columns => columns.filter(c => c !== column))
    } else {
      setVisibleColumns(columns => ([...columns, column]))
    }
  }

  const closeColumn = (event: React.MouseEvent, column: DashboardColumn) => {
    event.preventDefault()
    setVisibleColumns(columns => columns.filter(c => c !== column))
  }

  const onSearchConfirm = (event: React.KeyboardEvent<HTMLInputElement>) => {
    event.preventDefault()
    if (!search) return

    if (event.key === 'Enter') {
      setIsSearchExecuted(true)
      EventBus.emit(EventType.REFRESH_PAGINATED_COLLECTION, {id: 'search'})
    }
  }

  const onSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault()
    setSearch(event.target.value)
  }

  const onProjectUpdated = (project: ProjectAggregate) => {
    setProjects(projects => projects.map(p => p._id === project._id ? project : p))
  }

  const dashboardColumns = isTranslatorDashboard ? TRANSLATOR_DASHBOARD_COLUMNS : ALL_DASHBOARD_COLUMNS

  return (
    <div className="panes panes-fill-height">
      <div className="pane-sidebar">
        <div className="pane-body pt-3">
          <input className="form-control mb-3" type="search"
                 placeholder={t('components.TranslationsDashboardPage.searchPlaceholder')}
                 aria-label="Search" value={search} onChange={onSearch} onKeyUp={onSearchConfirm}/>
          <div className="nav flex-column nav-pills nav-pills-secondary">
            {dashboardColumns.map(column => {
              const columnProjects = PROJECTS_FILTER_FUNCTIONS[column](projects)
              return (
                <a className={`nav-link mb-1 ${isVisible(column as DashboardColumn) ? 'active' : ''}`}
                   key={column} href="" onClick={e => toggleColumn(e, column as DashboardColumn)}>
                  <div className="d-flex justify-content-between align-items-center">
                    {t(`components.TranslationsDashboardPage.columnTitles.${column.toLowerCase()}`)}
                    {columnProjects.length > 0 && <span className="badge bg-success">{columnProjects.length}</span>}
                  </div>
                </a>
              )
            })}
          </div>
        </div>
      </div>

      <div className="panes-horizontal-scoller">
        {isSearchExecuted && (
          <Pane title={t(`components.Projects.Projects.columnTitles.search`)}
                onClose={() => setIsSearchExecuted(false)}>
            <PaginatedView fetchCollection={page => searchProjects(search, page)} id={'search'}
                           render={(project: ProjectAggregate) => <ProjectCard project={project}/>}/>
          </Pane>)}

        {dashboardColumns.map(column => {
          return isVisible(column as DashboardColumn) && (
            <Pane title={t(`components.TranslationsDashboardPage.columnTitles.${column.toLowerCase()}`)}
                  key={column} onClose={e => closeColumn(e, column as DashboardColumn)}>
              {PROJECTS_FILTER_FUNCTIONS[column](projects).map((project: ProjectAggregate) => {
                const CardComponent = PROJECTS_CARD[column]
                return <CardComponent project={project} key={project._id as string}
                                      onUpdated={onProjectUpdated}/>
              })}
            </Pane>)
        })}
      </div>
    </div>
  )
}

export default TranslationsDashboardPage