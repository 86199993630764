import {Project, ProjectStage, ProjectType} from 'domain/Project'
import espressoStages from 'domain/project-stages/espresso-report'
import flashReportStages from 'domain/project-stages/flash-report'
import initialReportStages from 'domain/project-stages/initial-report'
import newsReleaseStages from 'domain/project-stages/news-release-report'
import postInterviewStages from 'domain/project-stages/post-interview-report'


export const STAGE_FLOW_BY_TYPE = {
  [ProjectType.FULL]: initialReportStages,
  [ProjectType.FLASH]: flashReportStages,
  [ProjectType.NEWS_UPDATE]: newsReleaseStages,
  [ProjectType.POST_INTERVIEW_UPDATE]: postInterviewStages,
  [ProjectType.ESPRESSO]: espressoStages
}

export const getStageRule = (project: Pick<Project, 'type'>, stage: ProjectStage) => {
  const stages = getStageRules(project.type)
  return stages.find(projectStage => projectStage.stage === stage)
}

export const getStageRules = (projectType: ProjectType) => {
  return STAGE_FLOW_BY_TYPE[projectType]
}