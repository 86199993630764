import {Entity, Id} from 'domain/Entity'
import {Delta} from 'jsondiffpatch'
import {Locale} from './Locale'

export interface ReportData {
  sections: Section[]
}

export interface ReportState extends ReportData {
  projectId: Id
  reportId: Id
  updateHash: string
  updatedAt: Date
}

export interface ReportRevisionUser {
  _id: Id,
  name: string
}

export interface ReportRevision extends Entity {
  projectId: Id
  reportId: Id
  diff?: Delta
  locale?: Locale
  name?: string
  createdBy: ReportRevisionUser
  milestones?: ProjectMilestone[]
}

export enum ProjectMilestone {
  ORIGINAL_REPORT_WRITING_FINISHED = 'ORIGINAL_REPORT_WRITING_FINISHED',
  ORIGINAL_REPORT_FINISHED = 'ORIGINAL_REPORT_FINISHED',
  TRANSLATED_REPORT_WRITING_FINISHED = 'TRANSLATED_REPORT_WRITING_FINISHED',
  TRANSLATED_REPORT_FINISHED = 'TRANSLATED_REPORT_FINISHED',
  ORIGINAL_REPORT_PUBLISHED = 'ORIGINAL_REPORT_PUBLISHED',
  TRANSLATED_REPORT_PUBLISHED = 'TRANSLATED_REPORT_PUBLISHED',
  PROJECT_FINISHED = 'PROJECT_FINISHED'
}

export const ORIGINAL_REPORT_MILESTONES = [
  ProjectMilestone.ORIGINAL_REPORT_WRITING_FINISHED,
  ProjectMilestone.ORIGINAL_REPORT_FINISHED,
  ProjectMilestone.ORIGINAL_REPORT_PUBLISHED
]

export const TRANSLATION_REPORT_MILESTONES = [
  ProjectMilestone.TRANSLATED_REPORT_WRITING_FINISHED,
  ProjectMilestone.TRANSLATED_REPORT_FINISHED,
  ProjectMilestone.TRANSLATED_REPORT_PUBLISHED
]

export interface SavedRevisionResponse {
  _id: Id
  updatedAt: Date
  updateHash: string
  isCreated: boolean
}

export enum SectionType {
  RECENT_UPDATES = 'RECENT_UPDATES',
  NEWS_AND_TOPICS = 'NEWS_AND_TOPICS'
}

export interface Section {
  _id: string,
  blocks: Block[],
  type?: SectionType
}

export enum BlockType {
  HEADING = 'heading',
  PARAGRAPH = 'paragraph',
  LIST = 'list',
  EXCEL_TABLE = 'table',
  COMMON_TABLE = 'common-table',
  IMAGE = 'image',
  ALERT = 'alert',
  BOOKMARK = 'bookmark',
  GRID = 'grid',
  CLONE = 'clone'
}

export type Block = HeadingBlock | ParagraphBlock | ListBlock | ExcelTableBlock |
  ImageBlock | AlertBlock | BookmarkBlock | GridBlock | CommonTableBlock | CloneBlock

interface WithBlockId {
  id: string
  checked?: boolean
}

export type TextContent = {
  [key in Locale]?: TextContentValue
}

interface TextContentValue {
  value: string
}

export interface HeadingBlock extends WithBlockId {
  type: BlockType.HEADING
  level: number
  text: TextContent
}

export enum ParagraphStyle {
  NORMAL = 'normal',
  BULLETED_PRIMARY = 'bulleted-primary',
  BULLETED_SECONDARY = 'bulleted-secondary'
}

export interface ParagraphBlock extends WithBlockId {
  type: BlockType.PARAGRAPH
  text: TextContent
  style: ParagraphStyle
}

export enum AlertType {
  INFO = 'info',
  WARNING = 'warning'
}

export interface AlertBlock extends WithBlockId {
  type: BlockType.ALERT
  message: TextContent
  alertType: AlertType
}

export interface ImageBlock extends WithBlockId {
  type: BlockType.IMAGE
  withBorder: boolean
  stretched: boolean
  withBackground: boolean
  file: ImageFile
  title: TextContent
  description: TextContent
}

type ImageFile = {
  [key in Locale]?: ImageFileValue
}

export interface ImageFileValue {
  url?: string
  width?: number
  height?: number
}

export enum ListStyle {
  UNORDERED = 'unordered',
  ORDERED = 'ordered'
}

export interface ListBlock extends WithBlockId {
  type: BlockType.LIST
  style: ListStyle
  items: ListContent
}

export type ListContent = {
  [key in Locale]?: ListLocaleValue
}

export type ListItem = string | ListItem[]

interface ListLocaleValue {
  value: ListItem[]
}

export type TableStyle = MainTableStyle | AdditionalStyle

export enum MainTableStyle {
  NORMAL = 'NORMAL',
  COMPACT = 'COMPACT',
  EXTRA_COMPACT = 'EXTRA_COMPACT'
}

export enum AdditionalStyle {
  WIDTH_AUTO = 'w-auto'
}

export interface ExcelTableBlock extends WithBlockId {
  type: BlockType.EXCEL_TABLE
  layout: TableLayout
  title: TextContent
  description: TextContent
  content: TableContent
}

type TableLayout = {
  [key in Locale]?: {
    template: RowTemplate[],
    style: MainTableStyle,
    additionalStyles?: AdditionalStyle[]
  }
}

export enum BackgroundStyle {
  HEADER = 'HEADER',
  SECONDARY_HEADER = 'SECONDARY_HEADER',
  HIGHLIGHTED = 'HIGHLIGHTED',
  NORMAL = 'NORMAL'
}

export enum FontColorStyle {
  NORMAL = 'NORMAL',
  WARNING = 'WARNING',
}

export interface RowTemplate {
  cells: CellTemplate[]
}

export enum CellType {
  TEXT = 'TEXT',
  NUMBER = 'NUMBER'
}

export interface CellTemplate {
  backgroundStyle?: BackgroundStyle,
  fontColorStyle?: FontColorStyle,
  textAlign?: string,
  fontWeight?: string,
  fontStyle?: string,
  textDecoration?: string,
  textDecorationStyle?: string,
  verticalAlign?: string,
  hasBorderLeft?: boolean,
  hasBorderRight?: boolean,
  colSpan?: number,
  rowSpan?: number,
  width?: string
  type: CellType
}

export type TableContent = {
  [key in Locale]?: TableLocaleValue
}

interface TableLocaleValue {
  value: string[][]
}

export interface BookmarkBlock extends WithBlockId {
  type: BlockType.BOOKMARK
  title: string
}

export interface CloneBlock extends WithBlockId {
  type: BlockType.CLONE
  sourceId: string
}

export interface GridBlock extends WithBlockId {
  type: BlockType.GRID
  border: boolean
  equalColumnWidth: boolean
  grid: GridData
  title: TextContent
  description: TextContent
}

export type GridData = {
  [key in Locale]?: GridRowContent[]
}

export type GridRowContent = GridCellContent[]

export type GridCellContent = {
  id: string
  blocks: Block[]
}

export interface CommonTableBlock extends WithBlockId {
  type: BlockType.COMMON_TABLE
  title: CommonTableText
  style: MainTableStyle,
  additionalStyles?: AdditionalStyle[]
  description: CommonTableText
  content: CommonTableContent
  layout: CommonTableLayout
}

export type CommonTableContent = {
  [key in Locale]?: CommonTableCellValue[][]
}

export enum TextAlign {
  RIGHT = 'RIGHT',
  LEFT = 'LEFT',
  CENTER = 'CENTER'
}

export enum Direction {
  RIGHT = 'RIGHT',
  LEFT = 'LEFT',
  BELOW = 'BELOW',
  ABOVE = 'ABOVE'
}

export interface CommonTableCellValue {
  value: string
  width?: string
}

export interface CommonTableLayout {
  rows: CommonTableRow[]
}

export interface CommonTableRow {
  cells: CommonTableCell[]
}

export interface CommonTableCell {
  rowSpan?: number
  colSpan?: number
  style?: BackgroundStyle
  align?: TextAlign
}

export type CommonTableText = {
  [key in Locale]?: string
}
