import {ObjectId} from 'bson'
import {Entity, Id} from 'domain/Entity'
import {Locale} from 'domain/Locale'

export enum CommentStatus {
  ALL = 'ALL',
  ACTIVE = 'ACTIVE',
  RESOLVED = 'RESOLVED'
}

interface Mentionable {
  mentionedUserIds: Id[]
}

export interface Comment extends Entity, Mentionable {
  userId: Id
  projectId: Id
  reportId: Id
  blockId: string | null
  content: string
  sticky?: boolean
  status: CommentStatus
  resolverId?: Id
  locale: Locale
  replies: Reply[]
  isVisibleForCompany: boolean
  commentedText: string
}

export interface CommentWithUserName extends Comment {
  username: string
  replies: ReplyWithUserName[]
}

export interface Reply extends Entity, Mentionable {
  content: string
  userId: Id
}

export interface ReplyWithUserName extends Reply {
  username: string
}

export type CreateComment = Pick<
  Comment,
  'projectId' | 'reportId' | 'blockId' | 'content' | 'locale' | 'commentedText'
> & {
  projectId: ObjectId
}

export type UpdateComment = Pick<Comment, 'content'> & {
  projectId: ObjectId
}
