import React, {UIEvent, useState} from 'react'


type Props = {
  children?: React.ReactNode | React.ReactNode[] | string
}

const PaneBody = React.forwardRef<HTMLDivElement, Props>(({children}, ref) => {
  const [hasScrolled, setHasScrolled] = useState(false)

  const onScroll = (e: UIEvent) => {
    const target = e.target as HTMLDivElement
    setHasScrolled(target.scrollTop > 0)
  }

  return <div className={`pane-body flex-grow-1 ${hasScrolled ? 'pane-body-scrolled' : ''}`}
              onScroll={onScroll} ref={ref}>
    {children}
  </div>
})

export default PaneBody