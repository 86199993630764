import React, {ReactElement, UIEvent, useState} from 'react'


interface PaneProps {
  title: string | React.ReactNode
  children?: ReactElement | ReactElement[] | null | (ReactElement | null)[]
  onClose?: (e: React.MouseEvent) => void
}

const Pane: React.FC<PaneProps> = ({title, onClose, children}): ReactElement => {

  const [hasScrolled, setHasScrolled] = useState(false)

  const onScroll =(e: UIEvent) => {
    const target = e.target as HTMLDivElement
    setHasScrolled(target.scrollTop > 0)
  }

  return (
    <div className="pane">
      <div className="pane-header">
        <h4 className="pane-title">
          {title}
        </h4>
        {onClose && <button type="button" data-dismiss="pane" aria-label="Close" onClick={onClose}
                            className="btn-xs border-0 btn-close d-flex align-items-center me-n1">
        </button>}
      </div>

      <div className={`pane-body ${hasScrolled ? 'pane-body-scrolled' : ''}`} onScroll={onScroll}>
        {children}
      </div>
    </div>
  )
}

export default Pane