import {ObjectId} from 'bson'
import {CommentWithUserName} from 'domain/Comment'
import {Locale} from 'domain/Locale'
import {PageType} from 'domain/PageType'
import {emptyGroupedNews, GroupedNews, ProjectType} from 'domain/Project'
import {BlockType, ReportState} from 'domain/Report'
import {ReactComponent as AddIcon} from '../../assets/icons/add.svg'
import React, {ReactElement, useContext, useEffect, useRef, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory, useLocation} from 'react-router-dom'
import {getReportAtRevision, getReportRevisions} from '../../api-clients/reportClient'
import {BlockForConversion, blockTypeConverters} from '../../editorjs/converting/BlockTypeConverters'
import EventBus, {EventType} from '../../EventBus'
import useConfirmationBeforeDeletion from '../../hooks/useConfirmationBeforeDeletion'
import useCurrentLocationVisibilityInSidebar from '../../hooks/useCurrentLocationVisibilityInSidebar'
import useForceRender from '../../hooks/useForceRender'
import useModal from '../../hooks/useModal'
import {usePageReloadAfterSleep} from '../../hooks/usePageReloadAfterSleep'
import {useProjectDocumentTitle} from '../../hooks/useProjectDocumentTitle'
import useReportPage from '../../hooks/useReportPage'
import useScrollKey from '../../hooks/useScrollKey'
import {CommentContext} from '../../providers/CommentProvider'
import {ReportContext} from '../../providers/ReportProvider'
import {ReportUpdateContext} from '../../providers/ReportUpdateProvider'
import {UserContext} from '../../providers/UserProvider'
import {withProviders} from '../../providers/utils'
import UploadFile from '../project/UploadFile'
import {toggleBlockSection} from '../utils/editorPageUtils'
import Modal from '../utils/Modal'
import Spinner from '../utils/Spinner'
import BookmarkSelectionMenu from './bookmarks/BookmarkSelectionMenu'
import SelectedBlocksProvider, {SelectedBlocksContext} from './bookmarks/SelectedBlocksProvider'
import Comments from './comments/Comments'
import CommentsSidebar from './comments/CommentsSidebar'
import BlocksContextMenu from './edit/BlocksContextMenu'
import EditableReportSection from './edit/EditableReportSection'
import {onTextCopy, onTextPaste} from './edit/largePasteDetector'
import SectionOrNewsReleases from './edit/SectionOrNewsReleases'
import useExcludedFromWordCountBlocks from './edit/useExcludedFromWordCountBlocks'
import {addSelectionToBlocks, removeSelectionFromBlocks} from './navigation/sectionUtils'
import SidebarWithReportChangeListener from './navigation/SidebarWithReportChangeListener'
import ReportNavigation from './report-navigation/ReportNavigation'
import ReportReload from './report-navigation/ReportReload'
import SearchAndReplace from './report-navigation/SearchAndReplace'
import {useReportLock} from './ReportLockManager'
import ShowIf, {notNewsUpdateType} from './ShowIf'
import Toolbar from './Toolbar'

const EditReportPage: React.FC = (): ReactElement => {
  const {t} = useTranslation()
  useScrollKey()
  const {showModal, closeLastModal} = useModal()
  const [baseReport, setBaseReport] = useState<ReportState | null>(null)
  const {undoRedoActions, getGroupedNews} = useReportPage()
  const {state} = useLocation()
  const history = useHistory()

  const {loadReport, setMigratedReport} = useContext(ReportUpdateContext)
  const {project, company, reportStore} = useContext(ReportContext)
  const {setSelectedBlocks} = useContext(SelectedBlocksContext)
  const {getLocaleComments, comments} = useContext(CommentContext)
  const {user} = useContext(UserContext)
  const [localeComments, setLocaleComments] = useState<CommentWithUserName[]>([])
  const [showCommentsSidebar, setShowCommentsSidebar] = useState(false)
  const [groupedNews, setGroupedNews] = useState<GroupedNews>(emptyGroupedNews)

  const reportContainer = useRef<HTMLDivElement>(null)
  const report = reportStore.getReport()
  const {rerender} = useForceRender()

  const reportLocale = Locale.JA

  useExcludedFromWordCountBlocks()
  useCurrentLocationVisibilityInSidebar('[data-section]', [reportStore.getReport()])
  useConfirmationBeforeDeletion(reportStore)
  usePageReloadAfterSleep()
  useProjectDocumentTitle(project, PageType.EDIT)

  useEffect(() => {
    reportStore.onUpdateStructure(() => {
      rerender()
    })

    getGroupedNews(reportLocale).then(setGroupedNews)

    EventBus.on(EventType.MOVE_TO_BOOKMARK, moveToBookmarkListener)
    EventBus.on(EventType.BLOCK_CONVERSION, blockConversionListener)
    EventBus.on(EventType.SET_BLOCK_SELECTION, setBlockSelection)

    return () => {
      EventBus.unsubscribe(EventType.MOVE_TO_BOOKMARK, moveToBookmarkListener)
      EventBus.unsubscribe(EventType.BLOCK_CONVERSION, blockConversionListener)
      EventBus.unsubscribe(EventType.SET_BLOCK_SELECTION, setBlockSelection)
      closeLastModal()
    }
  }, [])

  useReportLock(project._id, reportLocale, user)

  useEffect(() => {
    if (!project) return
    getReportRevisions(project._id).then(revisions => {
      const firstRevision = revisions.sortBy(r => r.createdAt).first()!
      getReportAtRevision(project._id, firstRevision._id).then(setBaseReport)
    })

    EventBus.on(EventType.TEXT_PASTED, onTextPasted)
    EventBus.on(EventType.TEXT_COPIED, onTextCopied)

    return () => {
      EventBus.unsubscribe(EventType.TEXT_PASTED, onTextPasted)
      EventBus.unsubscribe(EventType.TEXT_COPIED, onTextCopied)
    }
  }, [project])

  const moveToBookmarkListener = (event: Event) => {
    const blockIds = (event as CustomEvent).detail.blockIds
    const report = reportStore.getReport()

    const selectedBlocks = report?.sections.flatMap(s => s.blocks).filter(b => blockIds.includes(b.id)) ?? []

    removeSelectionFromBlocks()
    addSelectionToBlocks(selectedBlocks)

    setSelectedBlocks(selectedBlocks)
  }

  const setBlockSelection = (event: Event) => {
    const report = reportStore.getReport()
    if (!report) return

    const blockId = (event as CustomEvent).detail.blockId
    const newState = (event as CustomEvent).detail.newState
    const halfSelect = (event as CustomEvent).detail.halfSelect

    const newReport = {...report, sections: toggleBlockSection(report, blockId, newState, halfSelect)}
    reportStore.setReport(newReport)
  }

  const blockConversionListener = (event: Event) => {
    const report = reportStore.getReport()
    if (!report) return

    const {blockId, targetType, sourceType, ...options} = (event as any)?.detail!
    if (!Object.keys(blockTypeConverters).includes(sourceType)) return

    const block = report.sections
      .flatMap(s => s.blocks)
      .find(({id, type}) => id === blockId && type === sourceType) as BlockForConversion
    if (block.type !== BlockType.ALERT) {
      if (block.text[Locale.EN] && !window.confirm(t('components.EditReportPage.confirmBlockConversion'))) return
    } else {
      if (block.message[Locale.EN] && !window.confirm(t('components.EditReportPage.confirmBlockConversion'))) return
    }

    const blockTypeConverter = blockTypeConverters[sourceType][targetType]
    reportStore.setReport({
      ...report,
      sections: report.sections.map(s => {
        return {
          ...s,
          blocks: s.blocks.map(b => (b === block ? blockTypeConverter(b, options) : b))
        }
      })
    })
  }

  const onTextPasted = (event: Event) => {
    const {text, html} = (event as any)?.detail!
    onTextPaste({text, html}, project!._id)
  }

  const onTextCopied = (event: Event) => {
    const {text} = (event as any)?.detail!
    onTextCopy(text)
  }

  const addNewSection = () => {
    const sectionId = new ObjectId().toString()

    reportStore.addSection({
      _id: sectionId,
      blocks: []
    })
  }

  const onFileUpload = () => {
    showModal(<UploadFile locale={reportLocale} projectId={project!._id} onUploaded={setMigratedReport} />)
  }

  const showComments = () => {
    setShowCommentsSidebar(true)
  }

  const closeComments = () => {
    setShowCommentsSidebar(false)
  }

  useEffect(() => {
    const localeComments = getLocaleComments(reportLocale)
    if (localeComments && localeComments.length && state && (state as any).showCommentsSidebar) {
      showComments()
      delete (state as any).showCommentsSidebar
      history.replace({...history, state})
    }
    setLocaleComments(localeComments)
  }, [comments])

  if (!report) {
    return <Spinner withDimmer />
  } else {
    return (
      <>
        <div className="navbar navbar-secondary">
          {project && company && (
            <ReportNavigation
              titleKey={'components.EditReportPage.title'}
              project={project}
              company={company}
              locale={reportLocale}
            >
              <ReportReload locale={reportLocale} loadReport={loadReport} />
              <SearchAndReplace parentsSelector=".report-with-comments" />
              <Toolbar
                locale={reportLocale}
                onFileUpload={onFileUpload}
                {...undoRedoActions}
                showComments={showComments}
              />
            </ReportNavigation>
          )}
        </div>
        <div className="report-content-wrapper">
          {report && (
            <ShowIf rule={notNewsUpdateType}>
              <SidebarWithReportChangeListener
                reportStore={reportStore}
                locale={reportLocale}
                groupedNews={groupedNews}
              />
            </ShowIf>
          )}

          <div className="report-content report-edit report-edit-original flex-grow-1">
            {report && company && project && (
              <>
                <div className="report-comments-visible report-comments-visible-normal-editor">
                  <div className="container container-editor show-comments report-with-comments" ref={reportContainer}>
                    {project?.type === ProjectType.NEWS_UPDATE && (
                      <div className="news-report-title">
                        <h1>{project?.reportTitle?.[reportLocale]}</h1>
                      </div>
                    )}
                    {report.sections.map((section, i) => {
                      return (
                        <SectionOrNewsReleases
                          key={`section-${section._id}`}
                          section={section}
                          locale={reportLocale}
                          groupedNews={groupedNews}
                          render={() => (
                            <EditableReportSection
                              sectionId={section._id}
                              key={`section-${section._id}`}
                              reportStore={reportStore}
                              autofocus={i === 0}
                              baseReport={baseReport}
                              project={project}
                              locale={reportLocale}
                              readonly={false}
                            />
                          )}
                        />
                      )
                    })}

                    <BookmarkSelectionMenu />
                    <ShowIf rule={notNewsUpdateType}>
                      <div className="container container-editor">
                        <button
                          type="button"
                          className="btn btn-link btn-with-icon mb-5"
                          onClick={() => addNewSection()}
                        >
                          <AddIcon className="icon" />
                          <span>{t('components.EditReportPage.addSection')}</span>
                        </button>
                      </div>
                    </ShowIf>
                  </div>
                  <Comments locale={reportLocale} />
                </div>
                {reportContainer.current && <BlocksContextMenu container={reportContainer.current} />}
              </>
            )}
          </div>
        </div>

        {showCommentsSidebar && (
          <Modal
            component={<CommentsSidebar localeComments={localeComments} onClose={closeComments} />}
            onClose={closeComments}
          />
        )}
      </>
    )
  }
}

export default withProviders([SelectedBlocksProvider], EditReportPage)
