import {ReactComponent as ReportProblemIcon} from '../../../assets/icons/report_problem.svg'
import React from 'react'
import {useTranslation} from 'react-i18next'
import useModal from '../../../hooks/useModal'
import ProjectProblemModal from './ProjectProblemModal'
import {ProjectAggregate} from 'domain/ProjectAggregate'

interface Props {
  project: ProjectAggregate
}

const ReportProblemButton: React.FC<Props> = ({project}) => {
  const {showModal} = useModal()
  const {t} = useTranslation()
  const onClick = () => {
    showModal(<ProjectProblemModal project={project}/>)
  }

  return <button className="btn btn-xs btn-outline-secondary btn-with-icon me-2" onClick={onClick}>
    <ReportProblemIcon className="icon-xs"/>
    <span>{t('components.ReportProblemButton.title')}</span>
  </button>
}

export default ReportProblemButton