import {ObjectId} from 'bson'
import {Locale} from 'domain/Locale'
import {Block, BlockType, ReportState} from 'domain/Report'
import {BlockDiffResult} from 'domain/report-diff/BlockDiffResult'
import deepEqual from 'fast-deep-equal'
import React from 'react'
import BlockRevisionDiff from './revisions/BlockRevisionDiff'
import ReadonlyBlocks from './view/ReadonlyBlocks'

interface ReportRevisionDiffProps {
  reportState?: ReportState
  blockDiffs: BlockDiffResult[]
  wordCountExcludedBlockIds?: string[]
  locale: Locale
}

const ReportRevisionDiff: React.FC<ReportRevisionDiffProps> = props => {
  const {reportState, blockDiffs, locale} = props
  const wordCountExcludedBlockIds = new Set(props.wordCountExcludedBlockIds)
  const diffId = new ObjectId()

  const blocksById = blockDiffs.filter(diffResult => diffResult.right).groupByFirst(diffResult => diffResult.right!.id)

  const blockDiffsBySection = blockDiffs.groupBy(d => d.sectionId)

  return (
    <div className="container container-editor" key={`diff-${diffId}`}>
      <div className="report-view">
        {Object.keys(blockDiffsBySection).map(sectionId => {
          return (
            <div data-section={true} id={sectionId} key={`revision-section-${sectionId}`} className="report-section">
              {blockDiffsBySection[sectionId].map((blockDiff, i) => {
                const id = blockDiff.left?.id ?? (blockDiff.right?.id as string)

                return (
                  <React.Fragment key={`${diffId}-${id}-${i}`}>
                    {blockDiff.right?.type === BlockType.CLONE ? (
                      <div className="report-block-clone">
                        <ReadonlyBlocks
                          locale={locale}
                          blocks={[blocksById[blockDiff.right.sourceId]?.right as Block].filter(b => !!b)}
                        />
                      </div>
                    ) : (
                      <BlockRevisionDiff
                        reportState={reportState}
                        blockDiff={blockDiff}
                        locale={locale}
                        excludedFromWordCount={wordCountExcludedBlockIds.has(id)}
                      />
                    )}
                  </React.Fragment>
                )
              })}
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default React.memo(ReportRevisionDiff, (prevProps, nextProps) => {
  return deepEqual(prevProps, nextProps)
})
