import {Paginated} from 'domain/Entity'
import React, {FC, ReactElement} from 'react'
import PaginatedView from '../../utils/PaginatedView'
import ProjectCard from '../ProjectCard'
import {ProjectAggregate} from 'domain/ProjectAggregate'

interface ProjectsColumnProps {
  loadProjects: () => Promise<Paginated<ProjectAggregate>>
}

const PaginatedProjectsColumn: FC<ProjectsColumnProps> = ({loadProjects}): ReactElement => {
  return (
    <PaginatedView fetchCollection={loadProjects} render={project => {
      return <ProjectCard project={project} showProjectType={true}/>
    }}/>
  )
}

export default PaginatedProjectsColumn