import {ReactComponent as CloseIcon} from '../../assets/icons/close.svg'
import {ReactComponent as DoneIcon} from '../../assets/icons/done.svg'
import React, {FC, MouseEvent, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {importTranslationsFromSource} from '../../api-clients/reportClient'
import useModal from '../../hooks/useModal'
import useToast from '../../hooks/useToast'
import ButtonWithLoaderAndIcon from '../utils/form/ButtonWithLoaderAndIcon'
import ModalTemplate from '../utils/ModalTemplate'
import {ProjectAggregate} from 'domain/ProjectAggregate'

interface Props {
  project: ProjectAggregate
  loadReport: () => Promise<void>
}

const ImportTranslations: FC<Props> = ({project, loadReport}) => {
  const {t} = useTranslation()
  const {closeModal} = useModal()
  const {renderErrorToast, renderWarningToast} = useToast()
  const [loading, setLoading] = useState(false)

  const onImport = async (event: MouseEvent) => {
    event.preventDefault()
    setLoading(true)
    try {
      await importTranslationsFromSource(project._id)
      await loadReport()
    } catch (error: any) {
      if (error?.errors?.diff === 'blank') {
        renderWarningToast(t('components.ImportTranslations.noChanges'))
      } else {
        renderErrorToast(t('components.ImportTranslations.error'))
      }
    }
    closeModal()
    setLoading(false)
  }

  return (
    <ModalTemplate>
      <div className="modal-body">{t('components.ImportTranslations.message')}</div>
      <div className="modal-footer justify-content-around">
        <button type="button" className="btn btn-danger btn-with-icon" onClick={closeModal}>
          <span>{t('components.ImportTranslations.cancel')}</span>
          <CloseIcon className="icon" />
        </button>
        <ButtonWithLoaderAndIcon
          type="submit"
          className="btn btn-success btn-with-icon"
          onClick={onImport}
          loading={loading}
          icon={<DoneIcon className="icon" />}
        >
          <span>{t('components.ImportTranslations.import')}</span>
        </ButtonWithLoaderAndIcon>
      </div>
    </ModalTemplate>
  )
}

export default ImportTranslations
