import {API} from '@editorjs/editorjs'
import deleteIcon from '../../../assets/inline-svg/delete.svg'
import {make} from '../dom'

class Menu {
  menu: HTMLElement | null = null

  constructor(private api: API,
              private parentNode: HTMLElement,
              private onRemove: () => void,
              private canRemove: () => boolean) {
    this.onClick = this.onClick.bind(this)
  }

  get CSS() {
    return {
      menu: ['context-menu', 'editor-grid-context-menu'],
      menuItem: 'context-menu-item',
      menuItemText: 'context-menu-item-text',
      icon: ['icon', 'icon-sm', 'ms-n1', 'me-1', 'text-info'],
      disabled: 'disabled'
    }
  }

  isRendered(): boolean {
    return this.parentNode.contains(this.menu)
  }

  render() {
    this.menu = make('div', this.CSS.menu)
    this.menu.append(this.removeButton())
    this.parentNode.append(this.menu)

    setTimeout(() => {
      document.addEventListener('click', this.onClick, false)
    }, 0)
  }

  onClick(event: MouseEvent) {
    const clickedInsideMenu = this.menu && this.menu.contains(event.target as HTMLElement)

    if (!clickedInsideMenu) {
      this.destroy()
    }
  }

  destroy() {
    if (!this.menu || !this.menu.parentElement) {
      return
    }

    this.menu.parentElement!.removeChild(this.menu)
    document.removeEventListener('click', this.onClick)
  }

  removeButton() {
    const removeLink = make('a', [this.CSS.menuItem])
    const textSpan = make('span', [this.CSS.menuItemText])
    textSpan.innerText = this.api.i18n.t('Remove')
    removeLink.append(this.removeIcon(), textSpan)
    removeLink.classList.toggle(this.CSS.disabled, !this.canRemove())

    removeLink.addEventListener('click', (e: MouseEvent) => {
      e.preventDefault()

      if (this.canRemove()) {
        this.onRemove()
        this.destroy()
      }
    })

    return removeLink
  }

  removeIcon() {
    const span = make('span', this.CSS.icon)
    span.innerHTML = deleteIcon
    return span
  }
}

export default Menu