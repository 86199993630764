import {User} from 'domain/User'
import React, {ReactElement, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {getUserActiveProjects} from '../../api-clients/projectClient'
import ProjectCard from '../projects/ProjectCard'
import {ProjectAggregate} from 'domain/ProjectAggregate'


interface UserPageParams {
  user: User
}

const UserPageProjects: React.FC<UserPageParams> = ({user}): ReactElement => {
  const {t} = useTranslation()
  const [userProjects, setUserProjects] = useState<ProjectAggregate[]>([])
  useEffect(() => {
    getUserActiveProjects(user._id).then(setUserProjects)
  }, [])

  return <>
    <div className="pane">
      <div className="pane-header">
        <h4 className="pane-title">{t('components.UserPageProjects.title')}</h4>
      </div>
      <div className="pane-body">
        {userProjects.map(project =>
          <ProjectCard project={project} key={project._id.toString()}/>
        )}
      </div>
    </div>
  </>
}

export default UserPageProjects