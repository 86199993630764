import {Locale} from 'domain/Locale'
import {AggregatedProjectNotification, ProjectNotificationType} from 'domain/ProjectNotification'
import {TFunction} from 'i18next'
import moment from 'moment'
import React from 'react'


export const projectNotificationText = (projectNotification: AggregatedProjectNotification, t: TFunction) => {
  const taskStage = projectNotification.params?.taskStage
  const taskStageName = taskStage ? t(`models.ProjectStage.${taskStage}.title`) : undefined
  const oldDate = projectNotification.params?.oldDate
  const newDate = projectNotification.params?.newDate
  const formattedOldDate = oldDate ? moment(oldDate).format(t('dateTimeFormat')) : null
  const formattedNewDate = newDate ? moment(newDate).format(t('dateTimeFormat')) : null

  return <>
     <span className="small" dangerouslySetInnerHTML={{
       __html: t(`models.ProjectNotification.${projectNotification.type}.description`, {
         tick: projectNotification.companyTick,
         projectTitle: projectNotification.projectTitle,
         ...projectNotification.params,
         oldDate: formattedOldDate,
         newDate: formattedNewDate,
         taskStageName
       })
     }}/>

    {projectNotification.params?.comment && <div className="mt-1">
        <span className="text-ellipsis--multiline text-muted mx-1 border-top pt-2 mt-2 small fst-italic"
              dangerouslySetInnerHTML={{__html: projectNotification.params?.comment}}/>
    </div>}
  </>
}

export const projectNotificationLink = (projectNotification: AggregatedProjectNotification) => {
  switch (projectNotification.type) {
    case ProjectNotificationType.COMMENT_CREATE:
    case ProjectNotificationType.COMMENT_REPLY:
      const page = projectNotification.params?.locale === Locale.JA ? 'edit' : 'translate'
      const commentId = projectNotification.params?.commentId
      return `/projects/${projectNotification.projectId}/report/${page}?commentId=${commentId}`
    default:
      return `/projects/${projectNotification.projectId}`
  }
}