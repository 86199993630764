import {Company} from 'domain/Company'
import {Permission} from 'domain/Permission'
import {ReactComponent as AddIcon} from '../../assets/icons/add.svg'
import {ReactComponent as UploadFileIcon} from '../../assets/icons/upload_file.svg'
import React, {ReactElement, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'
import {createCompany, getAllCompanies, updateCompany} from '../../api-clients/companyClient'
import useModal from '../../hooks/useModal'
import Protected from '../utils/Protected'
import Spinner from '../utils/Spinner'
import CompaniesList from './CompaniesList'
import CompanyForm from './CompanyForm'
import UploadCompanies from './UploadCompanies'

const CompaniesPage: React.FC = (): ReactElement => {
  const history = useHistory()
  const {t} = useTranslation()
  const {showModal} = useModal()
  const [loading, setLoading] = useState(true)
  const [companies, setCompanies] = useState<Company[]>([])

  useEffect(() => load(), [])

  const load = () => {
    getAllCompanies()
      .then(setCompanies)
      .finally(() => setLoading(false))
  }

  const addNewCompany = (event: React.MouseEvent) => {
    event.preventDefault()
    showModal(<CompanyForm onConfirm={onCompanyCreate} />)
  }

  const uploadCompanies = (event: React.MouseEvent) => {
    event.preventDefault()
    showModal(<UploadCompanies onUploaded={load} />)
  }

  const onCompanyCreate = (company: Company) => {
    return createCompany(company).then(company => history.push(`/companies/${company._id}`))
  }

  const onCompanyUpdate = (company: Company) => {
    return updateCompany(company).then(() => {
      setCompanies(companies.map(c => (c._id === company._id ? company : c)))
    })
  }

  return (
    <div className="container py-5">
      <div className="d-flex justify-content-between align-items-center my-5">
        <h1 className="display-3 fw-bolder">{t('components.Companies.Companies.title')}</h1>
        <Protected permission={Permission.Company.CREATE}>
          <div>
            <button type="button" onClick={addNewCompany} className="btn btn-outline-primary btn-sm btn-with-icon me-2">
              <span>{t('components.Companies.Companies.newCompany')}</span>
              <AddIcon className="icon icon-sm" />
            </button>

            <button type="button" onClick={uploadCompanies} className="btn btn-outline-primary btn-sm btn-with-icon">
              <span>{t('components.Companies.Companies.uploadCompanies')}</span>
              <UploadFileIcon className="icon icon-sm" />
            </button>
          </div>
        </Protected>
      </div>
      {loading ? (
        <div className="d-flex justify-content-center">
          <Spinner />
        </div>
      ) : (
        <CompaniesList companies={companies} onCompanyUpdate={onCompanyUpdate} />
      )}
    </div>
  )
}

export default CompaniesPage
