import {hasTranslation} from 'domain/utils/translationUtils'
import {useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import ReportStore from '../components/report/ReportStore'
import EventBus, {EventType} from '../EventBus'


const useConfirmationBeforeDeletion = (reportStore: ReportStore) => {
  const {t} = useTranslation()

  useEffect(() => {
    EventBus.on(EventType.IF_DELETE_CONFIRMATION_REQUIRED, checkIfNeedConfirmationBeforeDeletionListener)
    return () => {
      EventBus.unsubscribe(EventType.IF_DELETE_CONFIRMATION_REQUIRED, checkIfNeedConfirmationBeforeDeletionListener)
    }
  }, [])

  const checkIfNeedConfirmationBeforeDeletionListener = (event: Event) => {
    const blockIds = (event as CustomEvent).detail.blockIds
    const report = reportStore.getReport()
    let needConfirmation = true
    let message = ''
    if (report) {
      needConfirmation = report?.sections
        .flatMap(section => section.blocks)
        .some(block => blockIds.includes(block.id) && hasTranslation(block))

      if (needConfirmation) {
        message = t('components.EditReportPage.confirmBlockDeletionWithTranslation')
      }
    }
    EventBus.emit(EventType.IF_DELETE_CONFIRMATION_REQUIRED_RESPONSE, {needConfirmation, message})
  }
}

export default useConfirmationBeforeDeletion