import {Project} from 'domain/Project'
import {ProjectTask} from 'domain/Task'
import React from 'react'
import {useTranslation} from 'react-i18next'
import useModal from '../../../hooks/useModal'
import ChangeTasksDatesModal from './ChangeTasksDatesModal'


interface Props {
  project: Project
  onUpdated: () => void
  tasks: ProjectTask[]
}

const ChangeTasksDatesButton: React.FC<Props> = ({project, onUpdated, tasks}) => {
  const {t} = useTranslation()
  const {showModal} = useModal()

  const onChangeDates = (e: React.MouseEvent) => {
    e.preventDefault()
    showModal(<ChangeTasksDatesModal project={project} tasks={tasks} onUpdated={onUpdated}/>)
  }

  return <div className="d-inline">
    <button className="btn btn-xs btn-primary" onClick={onChangeDates}>
      {t('components.ChangeTasksDatesButton.adjustSchedule')}
    </button>
  </div>
}

export default ChangeTasksDatesButton