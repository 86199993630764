import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'
import {requestPasswordReset} from '../../api-clients/userClient'
import useToast from '../../hooks/useToast'
import PublicPageTemplate from '../public/PublicPageTemplate'
import Input from '../utils/form/Input'


const RequestResetPasswordPage = () => {
  const {t} = useTranslation()
  const {renderSuccessToast} = useToast()
  const [email, setEmail] = useState<string>('')
  const [errors, setErrors] = useState<{email: string, baseError: string} | null>(null)
  const history = useHistory()

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault()
    setEmail(event.target.value)
  }

  const onSubmit = (event: React.FormEvent) => {
    event.preventDefault()
    setErrors(null)

    requestPasswordReset(email).then(() => {
      renderSuccessToast(t('components.RequestResetPasswordPage.checkEmail'))
      history.push('/login')
    }).catch(e => setErrors(e.errors))
  }

  return (
    <div className="container-fluid">
      <PublicPageTemplate>

        <div className="container-narrow">
          <form className="login-form" onSubmit={onSubmit}>
            <h4 className="mb-3">{t('components.RequestResetPasswordPage.title')}</h4>

            {errors?.baseError && (
              <div className="alert alert-danger shake-horizontal">
                {t('components.RequestResetPasswordPage.' + errors.baseError)}
              </div>
            )}

            <div className="form-floating mb-3">
              <Input className="form-control"
                     name="email" id="emailInput" value={email} error={errors?.email}
                     onChange={onChange} placeholder={t('models.User.email')}/>
              <label htmlFor="emailInput">{t('models.User.email')}</label>
            </div>

            <button className="btn btn-primary w-100 btn-lg">
              <span>{t('components.RequestResetPasswordPage.reset')}</span>
            </button>
          </form>
        </div>
      </PublicPageTemplate>
    </div>
  )
}

export default RequestResetPasswordPage