import {Locale} from 'domain/Locale'
import {GroupedNews} from 'domain/Project'
import {ReportState} from 'domain/Report'
import {ReactComponent as CloseIcon} from '../../../assets/icons/close.svg'
import {ReactComponent as ListAlt} from '../../../assets/icons/list_alt.svg'

import React, {useEffect, useState} from 'react'
import {buildReportWithNewsReleases} from '../../../utils/projectUtils'
import ReportStore from '../ReportStore'
import Section from './Section'


interface SidebarProps {
  report: ReportState
  reportStore?: ReportStore
  locale: Locale
  displayMenu?: boolean
  groupedNews?: GroupedNews
  displayNewsIfEmpty?: boolean
}

const Sidebar: React.FC<SidebarProps> = props => {
  const {report, locale, groupedNews, reportStore, displayNewsIfEmpty = true, displayMenu = true} = props

  const [navigationActive, setNavigationActive] = useState<boolean>(false)
  const [reportWithNewsReleases, setReportWithNewsReleases] = useState(() =>
    buildReportWithNewsReleases(report, groupedNews, displayNewsIfEmpty)
  )

  useEffect(() => {
    if (!reportStore) return
    reportStore.onUpdate(() => {
      setReportWithNewsReleases(buildReportWithNewsReleases(report, groupedNews, displayNewsIfEmpty))
    })
  }, [])

  return (
    <>
      <div
        className="report-sidebar transition-slide-in-right report-sidebar-left"
        style={{display: navigationActive ? 'block' : 'none'}}
      >
        <div className="report-sidebar-body">
          <button className="btn btn-with-icon btn-sm btn-link text-muted ms-1 my-2 report-toc-close"
            onClick={() => setNavigationActive(false)}
          >
            <CloseIcon className="icon" />
          </button>

          <div className="report-toc-items">
            {reportWithNewsReleases.sections.map(section => {
              return <Section section={section} locale={locale} key={section._id.toString()}
                              displayMenu={displayMenu}/>
            })}
          </div>
        </div>
      </div>
      <div className="report-editor-sticky-tools-wrapper report-editor-sticky-left"
           style={{display: navigationActive ? 'none' : 'block'}}>
        <button className="btn btn-with-icon btn-sm btn-link text-muted report-toc-open"
                onClick={() => setNavigationActive(true)}>
          <ListAlt className="icon" />
        </button>
      </div>
    </>
  )
}

export default Sidebar