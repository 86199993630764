import {AggregatedProjectLog, ProjectLogType} from 'domain/ProjectLog'
import {ReactComponent as ChangeCircleIcon} from '../../../assets/icons/change_circle.svg'
import {ReactComponent as CheckCircleIcon} from '../../../assets/icons/check_circle.svg'
import {ReactComponent as EditIcon} from '../../../assets/icons/edit.svg'
import {ReactComponent as InfoCircleIcon} from '../../../assets/icons/info_circle.svg'
import {ReactComponent as ScheduleIcon} from '../../../assets/icons/schedule.svg'
import {ReactComponent as TranslateIcon} from '../../../assets/icons/translate.svg'
import React, {useContext} from 'react'
import {LocaleContext} from '../../../providers/LocaleProvider'
import {relativeDateTime} from '../../../utils/momentUtils'
import {logLabel} from '../../../utils/projectLogUtils'


interface LogProps {
  log: AggregatedProjectLog
}

const ProjectLogRow: React.FC<LogProps> = ({log}) => {
  const {locale} = useContext(LocaleContext)

  const LabelComponent = logLabel(log)

  const iconMap = {
    [ProjectLogType.ACCEPT_TRANSLATION_REQUEST]: <CheckCircleIcon className="text-success icon-sm"/>,
    [ProjectLogType.REJECT_TRANSLATION_REQUEST]: <InfoCircleIcon className="text-danger icon-sm"/>,
    [ProjectLogType.REJECT_ACCEPTED_TRANSLATION_REQUEST]: <InfoCircleIcon className="text-danger icon-sm"/>,
    [ProjectLogType.CANCEL_TRANSLATION_REQUEST]: <InfoCircleIcon className="text-danger icon-sm"/>,
    [ProjectLogType.REPORT_EDIT]: log.params?.locale === 'ja' ?
      <EditIcon className="text-muted-more icon-sm"/> :
      <TranslateIcon className="text-muted-more icon-sm"/>,
    [ProjectLogType.STAGE_COMPLETE]: <CheckCircleIcon className="text-success icon-sm"/>,
    [ProjectLogType.STAGE_REVERT]: <ChangeCircleIcon className="text-warning icon-sm"/>,
    [ProjectLogType.STAGE_RESCHEDULE]: <ScheduleIcon className="text-warning icon-sm"/>
  }

  const icon = iconMap[log.type] || <InfoCircleIcon className="text-muted-more icon-sm"/>

  return <tr>
    <td className="py-0">{icon}</td>
    <td className="text-muted-more">{relativeDateTime(log.createdAt, locale)}</td>
    <td><LabelComponent log={log} /></td>
  </tr>
}

export default ProjectLogRow