import {Entity} from 'domain/Entity'
import React from 'react'
import {Pagination} from '../../hooks/usePaginatedCollection'
import {usePaginatedView} from '../../hooks/usePaginatedView'


type Props<T> = {
  pagination: Pagination<T>
  render: (item: T) => React.ReactElement
  thead: React.ReactNode
  className?: string
}

function PaginatedTable<T extends Entity>({pagination, render, className, thead}: Props<T>) {
  const {container} = usePaginatedView({pagination})

  return <table className={className} ref={container as any}>
    {thead}
    <tbody>
    {pagination.pagination && pagination.pagination.collection.map(item => {
      return <React.Fragment key={item._id as string}>{render(item)}</React.Fragment>
    })}
    {pagination.loading && <tr className="text-center">
      <td colSpan={'100%' as any}>
        <div className="spinner-border text-muted-more spinner-border-sm"/>
      </td>
    </tr>}
    </tbody>
  </table>
}

export default PaginatedTable