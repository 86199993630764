import {hasProjectAccess, hasProjectReadAccess} from 'domain/access'
import {Company} from 'domain/Company'
import {Locale} from 'domain/Locale'
import {Permission} from 'domain/Permission'
import {ProjectAggregate} from 'domain/ProjectAggregate'
import {Role} from 'domain/User'
import React, {PropsWithChildren, ReactElement, useContext} from 'react'
import {useTranslation} from 'react-i18next'
import {Link, useLocation} from 'react-router-dom'
import {ReactComponent as DownloadIcon} from '../../../assets/icons/cloud_download.svg'
import {ReactComponent as EditIcon} from '../../../assets/icons/edit.svg'
import {ReactComponent as HistoryIcon} from '../../../assets/icons/history.svg'
import {ReactComponent as PreviewIcon} from '../../../assets/icons/preview.svg'
import {ReactComponent as SaveIcon} from '../../../assets/icons/save.svg'
import {ReactComponent as TranslateIcon} from '../../../assets/icons/translate.svg'
import useModal from '../../../hooks/useModal'
import {ReportUpdateContext} from '../../../providers/ReportUpdateProvider'
import {UserContext} from '../../../providers/UserProvider'
import CompanyButton from '../../companies/CompanyButton'
import DownloadPdf from '../../project/DownloadPdf'
import ProjectButton from '../../project/ProjectButton'
import Protected from '../../utils/Protected'
import RevisionDiffNavigation from '../revisions/RevisionDiffNavigation'
import ReportProblemButton from './ReportProblemButton'
import SendingStatusButton from './SendingStatusButton'

interface ReportNavigationProps {
  project: ProjectAggregate
  company: Company
  titleKey: string
  locale: Locale
}

const ReportNavigation: React.FC<PropsWithChildren<ReportNavigationProps>> = ({
  project,
  company,
  children,
  locale
}): ReactElement => {
  const {t} = useTranslation()
  const {user} = useContext(UserContext)
  const {saveReport} = useContext(ReportUpdateContext)
  const {pathname} = useLocation()

  const showSaveButton = ['edit', 'translate'].some(page => pathname.endsWith(page))

  const navigationLinks = [
    {
      url: `/projects/${project._id}/report/edit`,
      labelKey: 'components.ReportNavigation.edit',
      icon: <EditIcon className="icon-sm" />,
      permission: Permission.Report.UPDATE,
      hasAccess: hasProjectAccess(user, project)
    },
    {
      url: `/projects/${project._id}/report/translate`,
      labelKey: 'components.ReportNavigation.translate',
      icon: <TranslateIcon className="icon-sm" />,
      permission: Permission.Report.UPDATE,
      hasAccess: hasProjectAccess(user, project)
    },
    {
      url: `/projects/${project._id}/report`,
      labelKey: 'components.ReportNavigation.preview',
      icon: <PreviewIcon className="icon-sm" />,
      permission: Permission.Report.READ,
      hasAccess: hasProjectReadAccess(user, project)
    },
    {
      url: `/projects/${project._id}/report/revisions`,
      labelKey: 'components.ReportNavigation.seeHistory',
      icon: <HistoryIcon className="icon-sm" />,
      permission: Permission.Revision.READ_ALL,
      hasAccess: hasProjectAccess(user, project)
    }
  ]

  const {showModal} = useModal()

  const openPrintSettings = (event: React.MouseEvent) => {
    event.preventDefault()
    showModal(<DownloadPdf project={project} company={company} />)
  }

  return (
    <div className="container-fluid" data-testid="report-navigation">
      <div className="row flex-grow-1">
        <div className="col-4 d-flex flex-column gap-1">
          <div className="d-flex align-items-baseline gap-2">
            <div className="fs-6 fw-bold text-truncate max-width-75">{project.title}</div>
            {showSaveButton && (
              <div
                title={t('components.ReportNavigation.saveTooltip')}
                onClick={saveReport}
                className="cursor-pointer align-self-center icon icon-hover"
              >
                <SaveIcon />
              </div>
            )}
            <SendingStatusButton />
          </div>
          <div>
            <ProjectButton project={project} useGenericTitle buttonClass="me-2" />
            <CompanyButton company={company} buttonClass="me-2" />
            {!user?.roles.includes(Role.COMPANY_REPRESENTATIVE) && <ReportProblemButton project={project} />}
          </div>
        </div>
        <div className="col-4 d-flex align-items-center justify-content-center">
          <div className="navbar navbar-expand">
            <div className="navbar-nav">
              {navigationLinks.map(link => {
                return (
                  link.hasAccess && (
                    <Protected permission={link.permission} key={link.url}>
                      <div className="nav-item">
                        <Link
                          to={link.url}
                          className={`nav-link nav-link-large-icon ${pathname.endsWith(link.url) ? 'active' : ''}`}
                          key={link.url}
                        >
                          {link.icon}
                          <span>{t(link.labelKey)}</span>
                        </Link>
                      </div>
                    </Protected>
                  )
                )
              })}
            </div>
          </div>
        </div>
        <div className="col-4 d-flex align-items-center justify-content-end">
          <div className="navbar navbar-expand position-static">
            <div className="navbar-nav">
              {hasProjectAccess(user, project) && (
                <Protected permission={Permission.Report.DOWNLOAD_PDF}>
                  <div className="nav-item">
                    <a onClick={openPrintSettings} className="nav-link" href="#">
                      <DownloadIcon className="icon-sm" />
                      <span>{t('components.ReportNavigation.pdf')}</span>
                    </a>
                  </div>
                </Protected>
              )}
            </div>
            <RevisionDiffNavigation locale={locale} />
            <>{children}</>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ReportNavigation
