import {Project} from 'domain/Project'
import {User} from 'domain/User'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {makeLink} from '../../../../api/src/utils/linkUtils'
import {formatDate} from '../../utils/momentUtils'
import EmailButton from './EmailButton'
import EmailTitle from './EmailTitle'


type Props = {
  assigner: User
  translator: User
  project: Project
}

const TranslationRequestAcceptedNotification: React.FC<Props> = ({assigner, translator, project}) => {
  const {t} = useTranslation()
  return <>
    <EmailTitle titleText={t('emails.translationRequestAcceptedNotification.bodyTitle')}/>

    <p style={{margin: '0 0 16px'}} dangerouslySetInnerHTML={{
      __html: t('emails.translationRequestAcceptedNotification.message', {
        name: assigner.name,
        translator: translator.name,
        title: project.title,
        type: t('models.ProjectType.' + project.type)
      })
    }}/>

    <p style={{margin: '25px 0 16px'}}>
      <EmailButton buttonLink={makeLink(`/projects/${project._id}`)}
                   buttonText={t('emails.translationRequestAcceptedNotification.goToProject')}/>
    </p>
  </>
}

export default TranslationRequestAcceptedNotification