import {ObjectId} from 'bson'
import {Entity} from 'domain/Entity'

export enum Role {
  ANALYST = 'ANALYST',
  INTERNAL_TRANSLATOR = 'INTERNAL_TRANSLATOR',
  EXTERNAL_TRANSLATOR = 'EXTERNAL_TRANSLATOR',
  MICRO_TRANSLATOR = 'MICRO_TRANSLATOR',
  EDITOR = 'EDITOR',
  ADMIN = 'ADMIN',
  COMPANY_REPRESENTATIVE = 'COMPANY_REPRESENTATIVE',
  TRANSLATOR_COORDINATOR = 'TRANSLATOR_COORDINATOR'
}

export enum ResponseSpeed {
  VERY_FAST = 'VERY_FAST',
  FAST = 'FAST',
  AVERAGE = 'AVERAGE',
  SLOW = 'SLOW'
}

export const userProjection = {passwordHash: 0, activationToken: 0, resetPasswordToken: 0, notes: 0}

export interface User extends Entity {
  email: string
  roles: Role[]
  name?: string
  phone?: string
  activationToken?: string
  resetPasswordToken?: string
  companyId?: ObjectId,
  notes?: string
  archived?: boolean
}

export interface RequestUser {
  _id: string
  email: string
  roles: Role[]
  name: string
  companyId?: string
}

export interface InternalTranslator extends User {
  tier?: number
}

export interface ExternalTranslator extends User {
  tier?: number
  ratePerCharacter?: number
  responseSpeed?: ResponseSpeed
}

export type Translator = ExternalTranslator | InternalTranslator

export const isTranslator = (roles: Role[]) => {
  return hasRoles(roles, TRANSLATOR_ROLES)
}

export const isTranslatorCoordinator = (roles: Role[]) => {
  return hasRoles(roles, [Role.TRANSLATOR_COORDINATOR, Role.ADMIN])
}

export const isOnlyTranslator = (roles: Role[]) => {
  return hasRoles(roles, TRANSLATOR_ROLES) && roles.filter(r => !TRANSLATOR_ROLES.includes(r)).length === 0
}

export const isOnlyCompanyRepresentative = (roles: Role[]) => {
  return roles.length === 1 && roles[0] === Role.COMPANY_REPRESENTATIVE
}

export const TRANSLATOR_ROLES = [
  Role.INTERNAL_TRANSLATOR,
  Role.EXTERNAL_TRANSLATOR
]

export type UserWithPassword = (User | Translator) & {
  passwordHash?: string
}

export type UserWithFormPasswords = Omit<User, 'password'> & {
  password: string,
  passwordConfirmation: string
}

export type UpdateUser = {
  notes: User['notes']
  roles?: User['roles']
  tier?: number
  ratePerCharacter?: number
  responseSpeed?: ResponseSpeed
}

export const roleRequiresCompanyId = (roles: Role[]) => {
  return hasRoles(roles, Role.COMPANY_REPRESENTATIVE)
}

export const hasRoles = (arg: User | RequestUser | Role[], lookup: Role | Role[]) : boolean => {
  if (!arg || !lookup) return false
  const sourceRoles = Array.isArray(arg) ? arg : arg.roles
  const lookupRoles = Array.isArray(lookup) ? lookup : [lookup]
  return lookupRoles.some(role => sourceRoles.includes(role))
}