import React, {Component, PropsWithChildren} from 'react'


const lazyComponents = [] as LazyGradually[]
let loading = false


class LazyGradually extends Component<PropsWithChildren> {
  visible: boolean

  constructor(props: {}) {
    super(props)
    this.visible = false
  }

  componentDidMount() {
    lazyComponents.push(this)

    if (loading) {
      return
    }

    loading = true
    loadComponents()
  }

  componentWillUnmount() {
    lazyComponents.splice(lazyComponents.indexOf(this), 1)

    if (lazyComponents.length === 0) {
      loading = false
    }
  }

  render() {
    return this.visible ? this.props.children : <></>
  }
}

const loadComponents = () => {
  if (!loading) {
    return
  }

  const component = lazyComponents.shift()

  if (!component) {
    loading = false
    return
  }

  if (!component.visible) {
    component.visible = true
    component.forceUpdate(() => setTimeout(loadComponents, 1))
  } else {
    loadComponents()
  }
}

export default LazyGradually