import {Permission, PermissionType} from 'domain/Permission'
import {Role} from 'domain/User'


export const RolesPermissions: { [key in Role]: string[] } = {
  [Role.ADMIN]: Object.values(Permission).flatMap(domain => Object.values(domain)),
  [Role.EDITOR]: [
    Permission.Project.READ,
    Permission.Project.READ_ALL,
    Permission.Project.VIEW_LOGS,
    ...Object.values(Permission.Report),
    ...Object.values(Permission.Glossary).filter(permission => permission !== Permission.Glossary.DELETE_ALL),
    Permission.Revision.READ_ALL,
    Permission.Revision.UPDATE,
    ...Object.values(Permission.Comment),
    Permission.Company.READ_ALL,
    Permission.Company.READ,
    Permission.User.READ,
    Permission.MicroTranslation.READ,
    Permission.MicroTranslation.CORRECT,
    Permission.ProjectTask.RESCHEDULE
  ],
  [Role.TRANSLATOR_COORDINATOR]: [
    ...Object.values(Permission.Project),
    ...Object.values(Permission.Report),
    Permission.Revision.READ_ALL,
    Permission.Revision.UPDATE,
    Permission.Comment.READ,
    Permission.Comment.MANAGE,
    Permission.Company.READ_ALL,
    Permission.Company.READ,
    Permission.Company.UPDATE,
    Permission.User.READ,
    Permission.User.READ_ALL,
    Permission.User.READ_PROFILE,
    Permission.User.UPDATE_PROFILE,
    Permission.User.READ_SCHEDULE,
    Permission.MicroTranslation.MANAGE,
    Permission.MicroTranslation.READ,
    Permission.MicroTranslation.CORRECT,
    Permission.ProjectTask.RESCHEDULE
  ],
  [Role.ANALYST]: [
    Permission.Project.READ,
    Permission.Project.READ_ALL,
    Permission.Project.VIEW_LOGS,
    Permission.Project.CREATE,
    Permission.Project.DELETE,
    Permission.Project.EDIT_SETTINGS,
    ...Object.values(Permission.Report),
    Permission.Glossary.READ,
    Permission.Revision.READ_ALL,
    Permission.Revision.UPDATE,
    ...Object.values(Permission.Company),
    ...Object.values(Permission.Comment),
    Permission.User.READ,
    Permission.User.READ_SCHEDULE,
    Permission.ProjectTask.RESCHEDULE
  ],
  [Role.EXTERNAL_TRANSLATOR]: [
    Permission.Project.READ,
    Permission.Project.VIEW_LOGS,
    Permission.Glossary.READ,
    Permission.Glossary.UPDATE,
    Permission.User.READ,
    Permission.Company.READ_ALL,
    Permission.Company.READ,
    Permission.Comment.READ,
    Permission.Comment.MANAGE,
    Permission.Report.AUTO_TRANSLATE,
    Permission.Report.READ,
    Permission.Report.UPDATE,
    Permission.Report.DOWNLOAD_PDF,
    Permission.Revision.READ_ALL,
    Permission.Revision.UPDATE,
    Permission.Manual.EXTERNAL_TRANSLATOR_READ,
  ],
  [Role.INTERNAL_TRANSLATOR]: [
    Permission.Project.READ,
    Permission.Project.READ_ALL,
    Permission.Project.VIEW_LOGS,
    Permission.Glossary.READ,
    Permission.Glossary.UPDATE,
    Permission.User.READ,
    Permission.Company.READ_ALL,
    Permission.Company.READ,
    Permission.Comment.READ,
    Permission.Comment.MANAGE,
    ...Object.values(Permission.Report),
    Permission.Revision.READ_ALL,
    Permission.Revision.UPDATE,
    Permission.ProjectTask.RESCHEDULE
  ],
  [Role.COMPANY_REPRESENTATIVE]: [
    Permission.Project.READ,
    Permission.Company.READ,
    Permission.Report.READ,
    Permission.Revision.READ_ALL,
    Permission.Comment.READ,
    Permission.Comment.MANAGE,
    Permission.Manual.COMPANY_REP_READ
  ],
  [Role.MICRO_TRANSLATOR]: [
    Permission.User.READ,
    Permission.Company.READ_ALL,
    Permission.Company.READ,
    Permission.MicroTranslation.MANAGE,
    Permission.MicroTranslation.READ
  ]
}

export const hasPermission = (roles: Role[], permission: PermissionType) => {
  return roles.some(role => RolesPermissions[role].includes(permission))
}