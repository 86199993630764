import React, {FC, ReactElement, useContext} from 'react'
import {LocaleContext} from '../../providers/LocaleProvider'
import {diffDaysBetweenDates, fromNow} from '../../utils/momentUtils'


interface Props {
  sentAt: Date
}

const TranslationDeadlineBadge: FC<Props> = ({sentAt}): ReactElement => {
  const {locale} = useContext(LocaleContext)
  const maximumBadgeLevel = 9
  const minimumBadgeLevel = 1
  const daysFromSent = diffDaysBetweenDates(sentAt, new Date())

  const calculateBadgeLevel = (daysFromSent: number): number => {
    const badgeDayDifference = maximumBadgeLevel - daysFromSent
    if (badgeDayDifference > maximumBadgeLevel) return maximumBadgeLevel
    if (badgeDayDifference < minimumBadgeLevel) return minimumBadgeLevel
    return badgeDayDifference
  }

  return (
    <span className={`badge rounded-pill me-1 badge-level-${calculateBadgeLevel(daysFromSent)}`}>
      {fromNow(sentAt, locale)}
    </span>
  )
}

export default TranslationDeadlineBadge