import {useContext, useEffect} from 'react'
import {ReportContext} from '../providers/ReportProvider'
import {isInViewport} from '../utils/isInViewport'
import throttle from '../utils/throttle'


const useCurrentLocationVisibilityInSidebar = (selector: string, deps: unknown[] = []) => {
  const {setVisibleSectionId} = useContext(ReportContext)

  useEffect(() => {
    setTimeout(() => highlightSectionInViewport(), 0)

    const throttledHighlightSectionInViewport = throttle(highlightSectionInViewport, 100)

    document.addEventListener('scroll', throttledHighlightSectionInViewport)

    return () => {
      document.removeEventListener('scroll', throttledHighlightSectionInViewport)
    }
  }, deps)

  const highlightSectionInViewport = () => {
    const sections = Array.from(document.querySelectorAll(selector)) as HTMLElement[]

    for (const section of sections) {
      if (isInViewport(section)) {
        setVisibleSectionId(section.id)
        break
      }
    }
  }
}

export default useCurrentLocationVisibilityInSidebar