import {Locale} from 'domain/Locale'
import i18next from 'i18next'
import HttpApi from 'i18next-http-backend'
import React, {ReactNode, useEffect, useState} from 'react'
import {initReactI18next} from 'react-i18next'
import LocalStorage from '../LocalStorage'

export const LocaleContext = React.createContext({} as any)

const LocaleProvider = ({children}: { children: ReactNode }) => {
  const [locale, setLocale] = useState<Locale>(LocalStorage.getLocale() || Locale.JA)

  i18next.use(HttpApi).use(initReactI18next).init({
    debug: true,
    lng: locale,
    fallbackLng: Locale.JA,
    backend: {
      loadPath: '/i18n/{{lng}}.json',
      allowMultiLoading: true
    }
  })

  const rememberLocale = (locale: Locale) => {
    setLocale(locale)
    LocalStorage.setLocale(locale)
    i18next.changeLanguage(locale)
  }

  useEffect(() => {
    document.documentElement.setAttribute('lang', locale.toLowerCase())
  }, [locale])

  return (
    <LocaleContext.Provider value={{locale, setLocale: rememberLocale}}>
      {children}
    </LocaleContext.Provider>
  )
}

export default LocaleProvider