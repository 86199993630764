import React, {FC, ReactElement, useContext} from 'react'
import {useTranslation} from 'react-i18next'
import Moment from 'react-moment'
import {LocaleContext} from '../../providers/LocaleProvider'


interface FormattedDateProps {
  date: Date | string
  format?: string
}

const FormattedDate: FC<FormattedDateProps> = ({date, format}): ReactElement => {
  const {locale} = useContext(LocaleContext)
  const {t} = useTranslation()
  return <Moment element={({children}) => <>{children}</>} format={format || t('dateFormat')}
                 locale={locale}>{date}</Moment>
}

export default FormattedDate
