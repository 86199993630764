import {Permission} from 'domain/Permission'
import {InterviewProjectTask} from 'domain/Task'
import {ReactComponent as DoneIcon, ReactComponent as EditIcon} from '../../../assets/icons/edit.svg'
import React, {FC, useState} from 'react'
import {Collapse} from 'react-collapse'
import {useTranslation} from 'react-i18next'
import {updateTaskInterviewDate} from '../../../api-clients/projectTaskClient'
import useToast from '../../../hooks/useToast'
import FormattedDate from '../../utils/FormattedDate'
import Protected from '../../utils/Protected'
import DefaultProjectTask, {ProjectTaskProps} from './DefaultProjectTask'


const InterviewTask: FC<ProjectTaskProps> = props => {
  const {loadProjectWithTasks} = props
  const task = props.task as InterviewProjectTask
  const [interviewDate, setInterviewDate] = useState(task.interviewDate)
  const {renderSuccessToast} = useToast()
  const {t} = useTranslation()
  const [editing, setEditing] = useState(false)

  const toggleEditing = () => setEditing(!editing)

  const onDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    setInterviewDate(e.target.value)
  }

  const onUpdate = (e: React.MouseEvent) => {
    e.preventDefault()

    updateTaskInterviewDate(task._id, interviewDate!).then(_ => {
      setEditing(false)
      loadProjectWithTasks()
      renderSuccessToast(t('components.DefaultProjectTask.successfullyUpdatedAttributes'))
    })
  }

  const editLayout = <Collapse isOpened={editing}
                               theme={{collapse: 'transition-slide-up', content: 'transition-slide-down'}}>
    <div className="p-3 pt-0 bg-light rounded position-relative">
      <button onClick={toggleEditing} type="button" aria-label="Close"
              className="btn-close btn-sm position-absolute top-0 end-0 mt-3 me-3"/>
      <div className="row stage-attribute">
        <div className="col-lg-6">
          <div className="mb-2">
            <label htmlFor="interviewDate" className="form-label small">
              {t('components.InterviewTask.interviewDate')}
            </label>
            <input type="date" className="form-control" name="interviewDate" onChange={onDateChange}
                   value={interviewDate}/>
          </div>

          <button className="btn btn-success btn-with-icon" onClick={onUpdate}>
            <DoneIcon className="icon icon-sm"/>
            <span>{t('components.InterviewTask.updatedButton')}</span>
          </button>
        </div>
      </div>
    </div>
  </Collapse>

  return <DefaultProjectTask {...props} bottom={editLayout}>
    <dl>
      <dt>
        {t('components.InterviewTask.interviewDate')}
      </dt>
      <dd>
        {task.interviewDate ? <FormattedDate date={task.interviewDate}/> : '-'}
        <Protected permission={Permission.Project.EDIT_SETTINGS}>
          <button title={t('components.InterviewTask.modifyButton')} onClick={toggleEditing}
                  className="btn btn-outline-secondary btn-xs btn-icon-only ms-2">
            <EditIcon className="icon icon-xs"/>
          </button>
        </Protected>
      </dd>
    </dl>
  </DefaultProjectTask>
}

export default InterviewTask