import {TranslationRequestStatus} from 'domain/TranslationRequest'
import {User} from 'domain/User'
import React, {ReactElement, useContext, useEffect, useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {getProject} from '../../../api-clients/projectClient'
import {acceptTranslationRequest} from '../../../api-clients/translationRequestClient'
import {getUser} from '../../../api-clients/userClient'
import useModal from '../../../hooks/useModal'
import useToast from '../../../hooks/useToast'
import {UserContext} from '../../../providers/UserProvider'
import {calculateProgress} from '../../../utils/projectUtils'
import UserButton from '../../user/UserButton'
import TranslationRequestRejectModal from '../TranslationRequestRejectModal'
import ProjectCard from './ProjectCard'
import {ProjectAggregate} from 'domain/ProjectAggregate'

interface CardProps {
  project: ProjectAggregate
  onUpdated: (project: ProjectAggregate) => void
}

const PendingConfirmationCard: React.FC<CardProps> = ({project, onUpdated}): ReactElement => {
  const {t} = useTranslation()
  const {user} = useContext(UserContext)
  const {showModal} = useModal()
  const {renderSuccessToast} = useToast()
  const lastTranslationRequest = project.translationRequests.last()
  const lastRequestBelongsToUser = lastTranslationRequest?.translatorId === user._id
  const translationRequest = useMemo(() => project.translationRequests.last(), [project])
  const [translator, setTranslator] = useState<User | null>(null)

  useEffect(() => {
    if (!lastTranslationRequest?.translatorId) return
    getUser(lastTranslationRequest?.translatorId, false).then(setTranslator)
  }, [])

  const onAccept = async (e: React.MouseEvent) => {
    e.preventDefault()
    e.stopPropagation()

    if (!translationRequest) return
    acceptTranslationRequest(translationRequest._id).then(() => {
      getProject(translationRequest.projectId).then(project => {
        onUpdated(project)
        renderSuccessToast(t('components.PendingConfirmationCard.accepted'))
      })
    })
  }

  const onReject = async (e: React.MouseEvent) => {
    e.preventDefault()
    e.stopPropagation()

    if (!translationRequest) return
    showModal(<TranslationRequestRejectModal translationRequest={translationRequest} onRejected={onUpdated}/>)
  }

  return <ProjectCard project={project} showAssignees={false}>
    {translator && <UserButton user={translator} buttonClass="mt-1 me-1"/>}
    {translationRequest && translationRequest.translatorId === user._id && <div>
      {
        lastTranslationRequest?.status === TranslationRequestStatus.PENDING && lastRequestBelongsToUser
        && lastTranslationRequest.message && (
          <div className="alert alert-info mt-2 p-2 mb-0 small mx-0">
            <div>{lastTranslationRequest.message}</div>
          </div>
        )
      }
      <div className="d-flex mt-2">
        <button className="btn btn-sm btn-outline-success me-1 flex-grow-1" onClick={onAccept}>
          <span>{t('components.PendingConfirmationCard.accept')}</span>
        </button>
        <button className="btn btn-sm btn-outline-danger flex-grow-1" onClick={onReject}>
          <span>{t('components.PendingConfirmationCard.reject')}</span>
        </button>
      </div>
    </div>}
    <div className="progress mb-n2 mx-n2 mt-2">
      <div role="progressbar" className="progress-bar progress-bar-animated"
           style={{width: `${calculateProgress(project)}%`}}/>
    </div>
  </ProjectCard>
}

export default PendingConfirmationCard