import {ReferencedBlock} from 'domain/MicroTranslation'
import {ReactComponent as ArrowDown} from '../../../assets/icons/arrow_down.svg'
import {ReactComponent as ArrowUp} from '../../../assets/icons/arrow_up.svg'
import React, {useEffect, useRef, useState} from 'react'
import Breadcrumb from './Breadcrumb'


type Props = {
  reference: ReferencedBlock
}

const ReferenceBlockCard: React.FC<Props> = props => {
  const {reference} = props
  const [collapsed, setCollapsed] = useState(false)
  const [collapsable, setCollapsable] = useState(false)
  const contentRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (contentRef.current) {
      if (contentRef.current.clientHeight > 90) {
        setCollapsed(true)
        setCollapsable(true)
      }
    }
  }, [])

  const toggleCollapsed = (event: React.MouseEvent) => {
    event.preventDefault()
    event.stopPropagation()
    setCollapsed(collapsed => !collapsed)
  }

  return <div className="card elevate m-3">
    <div className={`card-body ${collapsable ? 'card-body-expandable' : ''} ${collapsed ? '' : 'card-body-expanded'}`}>
      <div className="d-flex">
        <nav aria-label="breadcrumb" className="me-auto">
          <Breadcrumb breadcrumb={reference.breadcrumb} projectId={reference.projectId}/>
        </nav>
      </div>
      <div ref={contentRef} dangerouslySetInnerHTML={{__html: reference.html}}/>

      {collapsable && <a href="#" className="card-body-expander d-print-none bg-fade-to-container"
                         onClick={toggleCollapsed}>
        {collapsed ? <ArrowDown className="icon" /> : <ArrowUp className="icon" />}
      </a>}
    </div>
  </div>
}

export default ReferenceBlockCard