import {Project} from 'domain/Project'
import {ReactComponent as ProjectIcon} from '../../assets/icons/snippet_folder.svg'
import React, {ReactElement} from 'react'
import {useTranslation} from 'react-i18next'
import {Link} from 'react-router-dom'

interface ProjectButtonProps {
  project: Project
  buttonClass?: string,
  useGenericTitle?: boolean
}

const ProjectButton: React.FC<ProjectButtonProps> = ({project, buttonClass, useGenericTitle}): ReactElement => {
  const {t} = useTranslation()
  return (
    <Link to={`/projects/${project._id}`}
          className={`btn btn-xs btn-outline-secondary btn-with-icon ${buttonClass ? buttonClass : ''}`}>
      <ProjectIcon className="icon-xs"/>
      { useGenericTitle ? <span>{t('components.Project.Project.project')}</span> : <span>{project.title}</span>}
    </Link>
  )
}

export default ProjectButton