import {API} from '@editorjs/editorjs'
import {EditorCommonTableBlockData} from 'domain/Editor'
import {AdditionalStyle, MainTableStyle, TableStyle} from 'domain/Report'
import stretched from '../../../assets/inline-svg/stretched.svg'
import tableCompactIcon from '../../../assets/inline-svg/table-compact.svg'
import tableNormalIcon from '../../../assets/inline-svg/table-normal.svg'
import tablePaddedIcon from '../../../assets/inline-svg/table-padded.svg'
import {make} from '../dom'
import {isMainTableStyle} from '../excel-table/tableClassNamesHelper'


interface Tune {
  title: string
  style: TableStyle,
  icon: string
}

class Tunes {
  buttons: HTMLElement[] = []

  constructor(private api: API, private onTableStyleChange: (value: MainTableStyle) => void,
              private onAdditionalStyleChanged: (style: AdditionalStyle, button: HTMLElement) => void) {
  }

  get CSS() {
    return {
      buttonBase: this.api.styles.settingsButton,
      buttonActive: this.api.styles.settingsButtonActive
    }
  }

  get tunes(): Tune[] {
    return [
      {
        title: 'Large',
        style: MainTableStyle.NORMAL,
        icon: tablePaddedIcon
      },
      {
        title: 'Normal',
        style: MainTableStyle.COMPACT,
        icon: tableNormalIcon
      },
      {
        title: 'Compact',
        style: MainTableStyle.EXTRA_COMPACT,
        icon: tableCompactIcon
      },
      {
        title: 'AutoWidth',
        icon: stretched,
        style: AdditionalStyle.WIDTH_AUTO
      }
    ]
  }

  render(data: EditorCommonTableBlockData) {
    const wrapper = make('div')

    this.tunes.forEach(tune => {
      const title = this.api.i18n.t(tune.title)
      const el = make('div', [this.CSS.buttonBase], {
        innerHTML: tune.icon,
        title
      })
      const isMainStyle = isMainTableStyle(tune.style)

      el.addEventListener('click', () => {
        isMainStyle ? this.mainTuneClicked(tune.style as MainTableStyle)
          : this.onAdditionalStyleChanged(tune.style as AdditionalStyle, el)
      })

      el.dataset.tune = tune.style

      if (data.style === tune.style
        || (!isMainStyle && !data.additionalStyles?.includes(tune.style as AdditionalStyle))) {
        el.classList.add(this.CSS.buttonActive)
      }

      this.buttons.push(el)

      this.api.tooltip.onHover(el, title, {
        placement: 'top'
      })

      wrapper.appendChild(el)
    })

    return wrapper
  }

  mainTuneClicked(mainStyle: MainTableStyle) {
    this.buttons.forEach(button => {
      button.classList.toggle(this.CSS.buttonActive, button.dataset.tune === mainStyle)
    })

    this.onTableStyleChange(mainStyle)
  }
}

export default Tunes