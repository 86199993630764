import {ReportState} from 'domain/Report'
import {useCallback, useEffect, useState} from 'react'
import ReportStore from '../ReportStore'
import {caretTracker} from './CaretTracker'
import UndoRedo from './UndoRedo'


export interface EditorUndoRedoState {
  canUndo: () => boolean
  canRedo: () => boolean
  undo: () => void
  redo: () => void
  onUndoRedoUpdate: (cb: () => void) => void
  initialize: (report: ReportState) => void
}

const useEditorUndoRedo = (reportStore: ReportStore): EditorUndoRedoState => {

  const [undoRedo] = useState(new UndoRedo())

  useEffect(() => {
    setEventListeners()
    return removeEventListeners
  }, [])

  const undo = () => {
    const newSections = undoRedo.undo()

    if (newSections) {
      reportStore.setReport({...reportStore.getReport()!, sections: newSections})
    }
  }

  const redo = () => {
    const newSections = undoRedo.redo()

    if (newSections) {
      reportStore.setReport({...reportStore.getReport()!, sections: newSections})
    }
  }

  const buttonKey = /(Mac)/i.test(navigator.platform) ? 'metaKey' : 'ctrlKey'

  const handleUndoRedo = useCallback((e: KeyboardEvent) => {
    if (e[buttonKey] && e.key === 'z') {
      e.preventDefault()
      undo()
    }

    if (e[buttonKey] && e.key === 'y') {
      e.preventDefault()
      redo()
    }
  }, [])

  const setEventListeners = () => {
    document.addEventListener('keydown', handleUndoRedo)
  }

  const removeEventListeners = () => {
    document.removeEventListener('keydown', handleUndoRedo)
  }

  const initialize = (report: ReportState) => {
    undoRedo.initialize(report)

    reportStore.onUpdate(report => {
      setTimeout(() => undoRedo.onReportUpdated(report, caretTracker.caret!), 0)
    })
  }
  return {
    canRedo: () => undoRedo.canRedo(),
    canUndo: () => undoRedo.canUndo(),
    onUndoRedoUpdate: (cb: () => void) => undoRedo.onUpdate(cb),
    initialize,
    undo, redo
  }
}

export default useEditorUndoRedo