import EditorJS, {CaretPositions, I18nDictionary, LogLevels, OutputData} from '@editorjs/editorjs'
import Header from '@editorjs/header'
import Image from '@editorjs/image'
import List from '@editorjs/list'
import Marker from '@editorjs/marker'
import Paragraph from '@editorjs/paragraph'
import {EditorBlock, EditorGridRowData} from 'domain/Editor'
import {Id} from 'domain/Entity'
import {Locale} from 'domain/Locale'
import {ProjectType} from 'domain/Project'
import {AlertType, Block, BlockType} from 'domain/Report'
import Alert from '@editorjs/alert'
import Superscript from 'editorjs-superscript'
import deepEqual from 'fast-deep-equal'
import {i18n} from 'i18next'
import React from 'react'
import apiVersion from '../../../apiVersion'
import BlocksEditor from '../../../editorjs/converting/BlocksEditor'
import Bookmark from '../../../editorjs/plugins/bookmark/Bookmark'
import Clone, {clonedBlockClass} from '../../../editorjs/plugins/clone/Clone'
import CommentTool from '../../../editorjs/plugins/comment/CommentTool'
import CommonTable from '../../../editorjs/plugins/common-table/CommonTable'
import ExcelTable from '../../../editorjs/plugins/excel-table/ExcelTable'
import Grid from '../../../editorjs/plugins/grid/Grid'
import EventBus, {EventType} from '../../../EventBus'
import tokenStorage from '../../../TokenStorage'
import reportLockManager from '../ReportLockManager'
import {caretTracker} from '../undo/CaretTracker'
import {addCopyTextListeners} from './largePasteDetector'
import {postClientError} from '../../../api-clients/clientErrors'


export enum EditorMode {
  EDIT = 'EDIT',
  TRANSLATE = 'TRANSLATE'
}

export enum InlineTools {
  LINK = 'link',
  MARKER = 'marker',
  SUPERSCRIPT = 'superscript',
  COMMENT = 'comment'
}

interface EditorProps {
  blocks: Block[]
  locale: Locale
  mode?: EditorMode
  autofocus?: boolean
  id: string
  onEdit: (blocks: Block[]) => void
  onAddComment?: (elements: HTMLElement[]) => void
  onTranslate?: (block: Block) => void
  onReady?: () => void
  canComment: boolean
  canAutoTranslate?: boolean
  t: (key: string) => string
  renderErrorToast?: (content: string) => void
  i18n: i18n
  readonly?: boolean
  projectType?: ProjectType
  projectId?: Id
}

interface EditorState {
  editor: EditorJS | null
  editorBlocks: EditorBlock[]
  shouldTrackChange: boolean
}

class Editor extends React.Component<EditorProps, EditorState> {
  private readonly onBlockDeletedListener: EventListener

  constructor(props: EditorProps) {
    super(props)
    this.state = {
      editor: null,
      editorBlocks: new BlocksEditor(props.blocks).convertToEditor(props.locale),
      shouldTrackChange: true
    }
    this.onBlockDeletedListener = (event: Event) => this.deleteClonedBlocks(event)
  }

  onChange(caret: CaretPositions) {
    caretTracker.caret = caret
    if (!this.state.shouldTrackChange) {
      this.setState({shouldTrackChange: true})
      return
    }

    if (this.state.editor?.ready) {
      this.saveEditorData()
    } 
  }

  componentDidMount() {
    const {mode} = this.props

    const editor = new EditorJS({
      allowConversion: false,
      autofocus: this.props.autofocus,
      readOnly: !!this.props.readonly,
      allowAutoTranslate: mode === EditorMode.TRANSLATE && this.props.canAutoTranslate,
      onReady: () => {
        this.renderBlocks(this.state.editorBlocks).then(() => {
          if (this.props.autofocus) {
            editor.caret.setToBlock(0)
          }
          EventBus.on(EventType.BLOCK_DELETED, this.onBlockDeletedListener)
          EventBus.emit(EventType.EDITOR_READY, {id: this.props.id})
          if (this.props.onReady) {
            this.props.onReady()
          }
        })

        editor.events.on('content-pasted', () => {
          EventBus.emit(EventType.REPORT_CONTENT_PASTED)
        })

        editor.events.on('translate', editorBlock => {
          const block = this.props.blocks.find(b => b.id === editorBlock.id)
          if (block && this.props.onTranslate) this.props.onTranslate(block)
        })

        if (this.props.renderErrorToast) {
          editor.events.on('editor-error', errorKey => {
            this.props.renderErrorToast!(this.props.t(errorKey))
          })
        }

        if (mode === EditorMode.TRANSLATE) {
          editor.toolbar.open()
        }
      },
      onChange: (_, caret) => this.onChange(caret),
      allowInsert: mode === EditorMode.EDIT,
      allowDelete: mode === EditorMode.EDIT,
      allowClone: mode === EditorMode.EDIT,
      allowCheck: mode === EditorMode.EDIT,
      allowMovingToBookmark: mode === EditorMode.EDIT,
      allowBlockConversion: mode === EditorMode.EDIT,
      holder: this.props.id,
      minHeight: 32,
      logLevel: 'ERROR' as LogLevels,
      tools: this.tools(),
      copyWithFormat: mode === EditorMode.EDIT,
      i18n: {
        messages: this.props.i18n.t('editorjs', {returnObjects: true}) as I18nDictionary
      }
    })
    this.setState({editor})
  }

  shouldComponentUpdate(nextProps: EditorProps, nextState: EditorState) {
    if (nextProps.locale !== this.props.locale) {
      return true
    }

    if (nextProps.blocks === this.props.blocks) {
      return false
    }

    const editorBlocks = new BlocksEditor(nextProps.blocks).convertToEditor(nextProps.locale)
    return !deepEqual(editorBlocks, this.state.editorBlocks)
  }

  componentDidUpdate(prevProps: Readonly<EditorProps>, prevState: Readonly<EditorState>, snapshot?: any) {
    const editorBlocks = new BlocksEditor(this.props.blocks).convertToEditor(this.props.locale)

    if (this.objectsSame(editorBlocks, prevState.editorBlocks)) {
      return false
    }

    this.setState({editorBlocks})

    if (!this.state.editor || !this.state.editor.ready) return

    this.setState({shouldTrackChange: false})
    this.renderBlocks(editorBlocks).then(() => {
      EventBus.emit(EventType.EDITOR_READY, {id: this.props.id})
    })
  }

  componentWillUnmount() {
    if (this.state.editor?.ready) {
      this.saveEditorData(true)
      this.state.editor.destroy()
    }
    EventBus.unsubscribe(EventType.BLOCK_DELETED, this.onBlockDeletedListener)
  }

  private renderBlocks(blocks: EditorBlock[]) {
    if (this.props.mode === EditorMode.EDIT) {
      addCopyTextListeners(document.querySelector('.report-content-wrapper') as HTMLElement)
    }
    return reportLockManager.withLockedContent(() => {
      return this.state.editor!.blocks.render({blocks})
    })
  }

  private objectsSame(obj1?: EditorBlock[], obj2?: EditorBlock[]) {
    const withoutMetadata = (object: EditorBlock) => {
      const {metadata, ...other} = object

      if (other.type === BlockType.GRID) {
        return {
          ...other,
          data: {
            ...other.data,
            grid: object.data.grid.map((row: EditorGridRowData) => {
              return row.map(cell => cell.blocks.map(block => withoutMetadata(block)))
            })
          }
        }
      } else {
        return other
      }
    }

    return deepEqual(
      obj1?.map(obj => withoutMetadata(obj)),
      obj2?.map(obj => withoutMetadata(obj))
    )
  }

  private saveEditorData(checkEquality = false) {
    const {blocks, mode, locale, onEdit} = this.props
    const blocksEditor = new BlocksEditor(blocks)

    this.state
      .editor!.save()
      .then((result: OutputData) => {
        if (checkEquality && this.objectsSame(this.state.editorBlocks, result.blocks as EditorBlock[])) {
          return false
        }

        reportLockManager.notifyBlocksChange(this.state.editorBlocks, result.blocks as EditorBlock[], this.props.locale)
        this.setState({editorBlocks: result.blocks as EditorBlock[]})

        const updatedBlocks =
          mode === EditorMode.EDIT
            ? blocksEditor.reset(result.blocks as EditorBlock[], locale)
            : blocksEditor.merge(result.blocks as EditorBlock[], locale)
        onEdit(updatedBlocks)
      })
      .catch((e: any) => {
        console.warn(e)
        throw {message: 'errors.reportUpdateError', stack: e?.stack || ''}
      })
  }

  render() {
    return (
      <div
        className="codex-editor-wrapper"
        data-locale={this.props.locale}
        translate="no"
        data-is-editor={true}
        id={this.props.id}
      />
    )
  }

  private deleteClonedBlocks(event: Event) {
    const blockId = (event as CustomEvent).detail.blockId

    if (!this.state.editor) return

    const clonedBlocksIndices = []
    const blocks = this.state.editor.blocks
    for (let i = 0; i < blocks.getBlocksCount(); i++) {
      const block = blocks.getBlockByIndex(i)

      if (block && block.name === 'clone') {
        const sourceId = (block.holder.querySelector(`.${clonedBlockClass}`) as HTMLElement).dataset.sourceId

        if (sourceId === blockId) {
          clonedBlocksIndices.push(i)
        }
      }
    }
    clonedBlocksIndices.reverse().forEach(blocks.delete)
  }

  private tools() {
    class UndoableSuperscript extends Superscript {
      constructor(args: any) {
        super(args)
        this.tag = 'SUP'
      }
    }

    const {t} = this.props
    const inlineToolbar = [InlineTools.LINK, InlineTools.MARKER, InlineTools.SUPERSCRIPT]
    if (this.props.canComment) inlineToolbar.push(InlineTools.COMMENT)

    const levels = this.props.projectType === ProjectType.NEWS_UPDATE ? [3, 4, 5, 6] : undefined
    const defaultLevel = this.props.projectType === ProjectType.NEWS_UPDATE ? 3 : undefined
    const config = {
      [BlockType.PARAGRAPH]: {
        class: Paragraph,
        inlineToolbar: true,
        shortcut: 'ALT+SHIFT+P',
        config: {
          preserveBlank: true
        }
      },
      [BlockType.HEADING]: {
        class: Header,
        inlineToolbar,
        shortcut: 'ALT+SHIFT+H',
        config: {
          preserveBlank: true,
          levels,
          defaultLevel
        }
      },
      [BlockType.LIST]: {
        class: List,
        inlineToolbar: true,
        shortcut: 'ALT+SHIFT+L'
      },
      [BlockType.EXCEL_TABLE]: {
        class: ExcelTable,
        shortcut: 'ALT+SHIFT+E',
        config: {
          placeholderText: t('components.Report.edit.Editor.table_placeholder'),
          titlePlaceholder: t('components.Report.edit.Editor.table_title_placeholder'),
          descriptionPlaceholder: t('components.Report.edit.Editor.table_description_placeholder')
        },
        inlineToolbar: true
      },
      [BlockType.COMMON_TABLE]: {
        class: CommonTable,
        shortcut: 'ALT+SHIFT+T',
        config: {
          placeholderText: t('components.Report.edit.Editor.table_placeholder'),
          titlePlaceholder: t('components.Report.edit.Editor.table_title_placeholder'),
          descriptionPlaceholder: t('components.Report.edit.Editor.table_description_placeholder'),
          allowChangeLayout: this.props.mode === EditorMode.EDIT
        },
        inlineToolbar: true
      },
      [BlockType.IMAGE]: {
        class: Image,
        shortcut: 'ALT+SHIFT+I',
        config: {
          additionalRequestHeaders: () => ({
            authorization: `Bearer ${tokenStorage.getToken()}`,
            'X-Api-Version': apiVersion
          }),
          canInsertTable: this.props.mode === EditorMode.EDIT,
          endpoints: {
            byFile: '/api/editorjs/images/file',
            byUrl: '/api/editorjs/images/url'
          },
          titlePlaceholder: t('components.Report.edit.Editor.image.titlePlaceholder'),
          descriptionPlaceholder: t('components.Report.edit.Editor.image.descriptionPlaceholder'),
          buttonContent:
            this.props.mode === EditorMode.EDIT
              ? t('components.Report.edit.Editor.image.uploadText.edit')
              : t('components.Report.edit.Editor.image.uploadText.translate')
        },
        inlineToolbar: true
      },
      [BlockType.ALERT]: {
        class: Alert,
        shortcut: 'ALT+SHIFT+A',
        config: {
          messagePlaceholder: t('components.Report.edit.Editor.alert_message_placeholder'),
          alertTypes: [AlertType.INFO, AlertType.WARNING]
        },
        inlineToolbar: true
      },
      [BlockType.BOOKMARK]: {
        class: Bookmark,
        shortcut: 'ALT+SHIFT+B'
      },
      [BlockType.GRID]: {
        class: Grid,
        shortcut: 'ALT+SHIFT+G',
        config: {
          titlePlaceholder: t('components.Report.edit.Editor.gridTitlePlaceholder'),
          descriptionPlaceholder: t('components.Report.edit.Editor.gridDescriptionPlaceholder')
        },
        inlineToolbar: true
      },
      [BlockType.CLONE]: {
        class: Clone,
        shortcut: 'ALT+SHIFT+C',
        config: {}
      },
      [InlineTools.MARKER]: {
        class: Marker,
        shortcut: 'CMD+M'
      },
      [InlineTools.SUPERSCRIPT]: {
        class: UndoableSuperscript as any
      }
    }

    if (this.props.canComment) {
      config[InlineTools.COMMENT] = {
        class: CommentTool,
        shortcut: 'CTRL+ALT+M',
        config: {
          onAddComment: this.props.onAddComment
        }
      }
    }
    return config
  }
}

export default Editor
