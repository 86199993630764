import {Locale} from 'domain/Locale'
import {Block, ReportState} from 'domain/Report'
import React from 'react'
import {renderToString} from 'react-dom/server'
import {HtmlDiff} from '../../../htmldiff/Diff'
import ReadonlyBlocks from '../view/ReadonlyBlocks'

export const calculateDiffHTML = (
  prevBlocks: Block[],
  currentBlocks: Block[],
  locale: Locale,
  reportState?: ReportState
) => {
  const previousReportHTML = renderToString(
    <ReadonlyBlocks blocks={prevBlocks} locale={locale} reportState={reportState} />
  )
  const selectedReportHTML = renderToString(
    <ReadonlyBlocks blocks={currentBlocks} locale={locale} reportState={reportState} />
  )
  return HtmlDiff.execute(previousReportHTML, selectedReportHTML)
}
