import React, {useContext} from 'react'
import {useTranslation} from 'react-i18next'
import {DiffDeletionsContext} from '../../../providers/DiffDeletionsProvider'


const DiffDeletionsToggle = () => {
  const {isDeletionVisible, toggleDeletionVisibility} = useContext(DiffDeletionsContext)
  const {t} = useTranslation()

  return <div className="form-check form-switch mb-0">
    <input id="deletionVisibility" type="checkbox" className="form-check-input"
           checked={!isDeletionVisible} onChange={toggleDeletionVisibility}/>
    <label className="form-check-label text-muted text-nowrap" htmlFor="deletionVisibility">
      {t('components.Report.revisions.DiffDeletionsToggle.hideDeletions')}
    </label>
  </div>
}

export default DiffDeletionsToggle