import React from 'react'
import {useTranslation} from 'react-i18next'
import UserButton from '../user/UserButton'

import {AggregatedCompany} from 'domain/Company'


interface Props {
  company: AggregatedCompany
}

const CompanyAnalysts: React.FC<Props> = ({company}) => {
  const {t} = useTranslation()

  return (
    <div className="card mb-3">
      <div className="card-header">
        <h5 className="card-title mb-0">
          {t('components.CompanyAnalysts.title')}
        </h5>
      </div>
      <div className="card-body">
        <div className="data-list data-list-small data-list-wrappable data-list-row">
          <dl>
            <dt>{t('models.Company.seniorAnalyst')}</dt>
            <dd>
              {company.seniorAnalyst ? <UserButton user={company.seniorAnalyst} buttonClass="me-1" /> : '-'}
            </dd>
          </dl>
          <dl>
            <dt>{t('models.Company.assistantAnalysts')}</dt>
            <dd>
              {company.assistantAnalysts.length > 0 ? company.assistantAnalysts.map(assistant => {
                return <UserButton user={assistant} buttonClass="me-1"/>
              }) : '-'}
            </dd>
          </dl>
        </div>
      </div>
    </div>
  )
}

export default CompanyAnalysts