import {Permission} from 'domain/Permission'
import {ReactComponent as DeleteIcon} from '../../../assets/icons/delete.svg'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'
import {deleteProject} from '../../../api-clients/projectClient'
import useModal from '../../../hooks/useModal'
import useToast from '../../../hooks/useToast'
import ConfirmationModal from '../../utils/ConfirmationModal'
import Protected from '../../utils/Protected'
import {ProjectAggregate} from 'domain/ProjectAggregate'

interface Props {
  project: ProjectAggregate
  isDisabled: boolean
}

const DeleteProjectButton: React.FC<Props> = ({project, isDisabled}) => {
  const {t} = useTranslation()
  const {renderSuccessToast, renderErrorToast} = useToast()
  const {showModal} = useModal()
  const history = useHistory()

  const onConfirmDeleteProject = () => {
    return deleteProject(project._id as string).then(() => {
      history.push('/projects')
      renderSuccessToast(t('components.DeleteProjectButton.success'))
    }).catch(() => {
      renderErrorToast(t('components.DeleteProjectButton.failure'))
    })
  }

  const onDeleteProject = (e: React.MouseEvent) => {
    e.preventDefault()
    showModal(<ConfirmationModal title={t('components.DeleteProjectButton.confirmationTitle')}
                                 ConfirmationIcon={DeleteIcon}
                                 body={t('components.DeleteProjectButton.confirmation')}
                                 onConfirm={onConfirmDeleteProject}/>)
  }

  return <Protected permission={Permission.Project.DELETE}>
    <div className="pe-2 d-inline">
      <button className="btn btn-xs btn-outline-danger"
              disabled={isDisabled}
              onClick={onDeleteProject}>
        {t('components.DeleteProjectButton.delete')}
      </button>
    </div>
  </Protected>
}

export default DeleteProjectButton