import {MSClarityConfiguration} from 'domain/analytics/MSClarity'
import apiClient from '../ApiClient'


const getMSClarityConfiguration = async (): Promise<MSClarityConfiguration> => {
  return await apiClient.get('/api/ms-clarity')
}

export {
  getMSClarityConfiguration
}
