import {Paginated} from 'domain/Entity'
import {AggregatedMicroTranslationProject} from 'domain/MicroTranslation'
import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {
  getAssignedTranslationProjects,
  getPublishedTranslationProjects,
  getUnassignedTranslationProjects,
  searchMicroTranslationProjects
} from '../../api-clients/microTranslationClient'
import EventBus, {EventType} from '../../EventBus'
import LocalStorage from '../../LocalStorage'
import PaginatedView from '../utils/PaginatedView'
import Pane from '../utils/Pane'
import MicroTranslationCard from './MicroTranslationCard'
import PaginatedColumn from './PaginatedColumn'


enum DashboardColumn {
  UNASSIGNED = 'UNASSIGNED',
  ASSIGNED = 'ASSIGNED',
  COMPLETED = 'COMPLETED'
}

const DEFAULT_VISIBLE_COLUMNS = [
  DashboardColumn.UNASSIGNED,
  DashboardColumn.ASSIGNED,
  DashboardColumn.COMPLETED
]

type ProjectLoadFunction = (page: number) => Promise<Paginated<AggregatedMicroTranslationProject>>

const PROJECTS_LOAD_FUNCTIONS: { [key in DashboardColumn]: ProjectLoadFunction } = {
  [DashboardColumn.UNASSIGNED]: getUnassignedTranslationProjects,
  [DashboardColumn.ASSIGNED]: getAssignedTranslationProjects,
  [DashboardColumn.COMPLETED]: getPublishedTranslationProjects
}

const MicroTranslationsDashboardPage: React.FC = () => {
  const {t} = useTranslation()
  const [visibleColumns, setVisibleColumns] = useState<DashboardColumn[]>(
    LocalStorage.getMicroTranslationsDashboardColumns() || DEFAULT_VISIBLE_COLUMNS
  )
  const [search, setSearch] = useState<string>('')
  const [isSearchExecuted, setIsSearchExecuted] = useState(false)

  useEffect(() => {
    LocalStorage.setMicroTranslationsDashboardColumns(visibleColumns)
  }, [visibleColumns])

  const isVisible = (column: DashboardColumn) => {
    return visibleColumns.includes(column)
  }

  const toggleColumn = (event: React.MouseEvent, column: DashboardColumn) => {
    event.preventDefault()

    if (isVisible(column)) {
      setVisibleColumns(columns => columns.filter(c => c !== column))
    } else {
      setVisibleColumns(columns => ([...columns, column]))
    }
  }

  const closeColumn = (event: React.MouseEvent, column: DashboardColumn) => {
    event.preventDefault()
    setVisibleColumns(columns => columns.filter(c => c !== column))
  }

  const onSearchConfirm = (event: React.KeyboardEvent<HTMLInputElement>) => {
    event.preventDefault()
    if (!search) return

    if (event.key === 'Enter') {
      setIsSearchExecuted(true)
      EventBus.emit(EventType.REFRESH_PAGINATED_COLLECTION, {id: 'search'})
    }
  }

  const onSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault()
    setSearch(event.target.value)
  }
  return (
    <div className="panes panes-fill-height">
      <div className="pane-sidebar">
        <div className="pane-body pt-3">
          <input className="form-control mb-3" type="search"
                 placeholder={t('components.MicroTranslationsDashboardPage.searchPlaceholder')}
                 aria-label="Search" onChange={onSearch} value={search} onKeyUp={onSearchConfirm}/>
          <div className="nav flex-column nav-pills nav-pills-secondary">
            {Object.keys(DashboardColumn).map(column => {
              return (
                <a className={`nav-link mb-1 ${isVisible(column as DashboardColumn) ? 'active' : ''}`}
                   key={column} href="" onClick={e => toggleColumn(e, column as DashboardColumn)}>
                  {t(`components.MicroTranslationsDashboardPage.columnTitles.${column.toLowerCase()}`)}
                </a>)
            })}
          </div>
        </div>
      </div>

      <div className="panes-horizontal-scoller">
        {isSearchExecuted && (
          <Pane title={t(`components.MicroTranslationsDashboardPage.columnTitles.search`)}
                onClose={() => setIsSearchExecuted(false)}>
            <PaginatedView fetchCollection={page => searchMicroTranslationProjects(search, page)} id={'search'}
                           render={(project: AggregatedMicroTranslationProject) => {
                             return <MicroTranslationCard microTranslation={project}/>
                           }}/>
          </Pane>)}
        {Object.keys(DashboardColumn).map(column => {
          return isVisible(column as DashboardColumn) && (
            <Pane title={t(`components.MicroTranslationsDashboardPage.columnTitles.${column.toLowerCase()}`)}
                  key={column} onClose={e => closeColumn(e, column as DashboardColumn)}>
              <PaginatedColumn loadProjects={PROJECTS_LOAD_FUNCTIONS[column]}/>
            </Pane>)
        })}
      </div>
    </div>
  )

}

export default MicroTranslationsDashboardPage