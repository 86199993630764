import {CommentWithUserName} from 'domain/Comment'
import {Locale} from 'domain/Locale'
import React, {ReactElement, useContext, useEffect, useRef, useState} from 'react'
import {useComments} from '../../../hooks/useComments'
import {CommentContext} from '../../../providers/CommentProvider'
import {ReportContext} from '../../../providers/ReportProvider'
import CommentPositionModifier from './CommentPositionModifier'
import NewComment from './NewComment'
import TextAlignedComment from './TextAlignedComment'

interface Props {
  locale: Locale
}

const Comments: React.FC<Props> = ({locale}): ReactElement => {
  const {newComment, getLocaleComments} = useContext(CommentContext)
  const containerRef = useRef<HTMLDivElement>(null)
  const [positionsModifier, setPositionsModifier] = useState<CommentPositionModifier | undefined>()
  const {reportStore} = useContext(ReportContext)
  useComments()

  useEffect(() => {
    const positionsModifier = new CommentPositionModifier(containerRef.current!)
    positionsModifier.observe()
    setPositionsModifier(positionsModifier)
    return () => {
      positionsModifier.close()
    }
  }, [])

  useEffect(() => {
    if (!positionsModifier) return
    positionsModifier.adjustPositions()
  }, [reportStore.getReport()?.sections.map(section => section._id).toString()])

  const notShowResolvedCommentFilter = (comment: CommentWithUserName) => {
    return !comment.resolverId
  }

  return (
    <div className="report-section-comments" ref={containerRef}>
      {getLocaleComments(locale)
        .filter(comment => notShowResolvedCommentFilter(comment))
        .map(comment => (
          <TextAlignedComment comment={comment} key={comment._id as string}/>
        ))
      }

      {newComment && <NewComment locale={locale} elements={newComment.elements}/>}
    </div>
  )
}

export default Comments