import {Locale} from 'domain/Locale'
import {containsNewsUpdates, emptyGroupedNews, GroupedNews} from 'domain/Project'
import {useContext, useEffect} from 'react'
import {getGroupedNewsProjectsWithReports} from '../api-clients/reportClient'
import useEditorUndoRedo, {EditorUndoRedoState} from '../components/report/undo/useEditorUndoRedo'
import {ReportContext} from '../providers/ReportProvider'
import {ReportUpdateContext} from '../providers/ReportUpdateProvider'


interface UseReportPageState {
  undoRedoActions: Omit<EditorUndoRedoState, 'initialize'>
  getGroupedNews: (locale: Locale) => Promise<GroupedNews>
}

const useReportPage = (): UseReportPageState => {
  const {loadReport} = useContext(ReportUpdateContext)
  const {project, reportStore} = useContext(ReportContext)
  const {initialize: initUndoRedo, ...undoRedoActions} = useEditorUndoRedo(reportStore)

  useEffect(() => {
    loadReport()
      .then(() => {
        const reportState = reportStore.getReport()!
        initUndoRedo(reportState)
      })
      .catch(e => {
        console.error('loadReport failed', e)
        throw {message: 'errors.reportLoadError'}
      })
  }, [])

  const getGroupedNews = async (locale: Locale): Promise<GroupedNews> => {
    if (containsNewsUpdates(project.type)) {
      return await getGroupedNewsProjectsWithReports(project._id, locale)
    }

    return emptyGroupedNews()
  }

  return {
    undoRedoActions,
    getGroupedNews
  }
}


export default useReportPage