import classNames from 'classnames'
import {Locale} from 'domain/Locale'
import {
  AggregatedMicroTranslation,
  MicroTranslation,
  MicroTranslationProject,
  MicroTranslationStatus
} from 'domain/MicroTranslation'
import React, {useContext, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {
  publishMicroTranslationCorrection,
  saveAndPublishMicroTranslation,
  saveMicroTranslation
} from '../../../api-clients/microTranslationClient'
import useModal from '../../../hooks/useModal'
import useToast from '../../../hooks/useToast'
import {UserContext} from '../../../providers/UserProvider'
import UserButton from '../../user/UserButton'
import ConfirmationModal from '../../utils/ConfirmationModal'
import ContentEditable from '../../utils/form/ContentEditable'
import MicroTranslationOriginalContent from './MicroTranslationOriginalContent'
import TextEditToolbar from './TextEditToolbar'


interface Props {
  microTranslation: AggregatedMicroTranslation
  project: MicroTranslationProject
  locale: Locale
  onPublished: (microTranslation: MicroTranslation) => void
}

const MicroTranslationColumn: React.FC<Props> = props => {
  const {microTranslation, project, locale, onPublished} = props
  const {user} = useContext(UserContext)
  const {t} = useTranslation()
  const {showModal} = useModal()
  const {renderSuccessToast, renderErrorToast} = useToast()
  const [showCorrectionArea, setShowCorrectionArea] = useState(false)
  const [translation, setTranslation] = useState(microTranslation.translation || '')
  const [correction, setCorrection] = useState(microTranslation.correction || microTranslation.translation || '')

  const originalContent = microTranslation.locale === locale
  const isTranslated = !originalContent && microTranslation.status === MicroTranslationStatus.PUBLISHED
  const isPendingTranslation = !originalContent && microTranslation.status === MicroTranslationStatus.PENDING
  const classes = {'card mb-3': true, 'border-success': isTranslated, 'border-warning': isPendingTranslation}
  const content = originalContent ? microTranslation.originalContent : microTranslation.translation
  const canEdit = isPendingTranslation && project.translatorId === user._id
  const canCorrect = isTranslated && !microTranslation.correction

  const saveTranslation = (e: React.MouseEvent) => {
    e.preventDefault()
    saveMicroTranslation(project._id, microTranslation._id, translation).then(() => {
      setCorrection(translation)
      renderSuccessToast(t('components.MicroTranslationColumn.translationSaved'))
    }).catch(({errors}) => {
      if (errors.error) {
        renderErrorToast(t(`components.MicroTranslationColumn.${errors.error}`))
      } else {
        renderErrorToast(t('components.MicroTranslationColumn.translationNotSaved'))
      }
    })
  }

  const onSaveAndPublishConfirm = () => {
    return saveAndPublishMicroTranslation(project._id, microTranslation._id, translation).then(microTranslation => {
      onPublished(microTranslation)
      setCorrection(translation)
      renderSuccessToast(t('components.MicroTranslationColumn.translationPublished'))
    }).catch(({errors}) => {
      if (errors.error) {
        renderErrorToast(t(`components.MicroTranslationColumn.${errors.error}`))
      } else {
        renderErrorToast(t('components.MicroTranslationColumn.translationNotPublished'))
      }
    })
  }

  const onSaveAndPublishCorrectionConfirm = () => {
    return publishMicroTranslationCorrection(project._id, microTranslation._id, correction).then(microTranslation => {
      onPublished(microTranslation)
      renderSuccessToast(t('components.MicroTranslationColumn.correctionPublished'))
    }).catch(({errors}) => {
      if (errors.error) {
        renderErrorToast(t(`components.MicroTranslationColumn.${errors.error}`))
      } else {
        renderErrorToast(t('components.MicroTranslationColumn.correctionNotPublished'))
      }
    })
  }

  const toggleCorrectionArea = (e: React.MouseEvent) => {
    e.preventDefault()
    setShowCorrectionArea(!showCorrectionArea)
  }

  const saveAndPublishCorrection = async (e: React.MouseEvent) => {
    e.preventDefault()
    showModal(<ConfirmationModal
      title={t('components.MicroTranslationColumn.savePublishCorrectionConfirmation')}
      btnClassName="btn-success" onConfirm={onSaveAndPublishCorrectionConfirm}/>)
  }

  const saveAndPublishTranslation = (e: React.MouseEvent) => {
    e.preventDefault()
    showModal(<ConfirmationModal
      title={t('components.MicroTranslationColumn.savePublishConfirmation')}
      btnClassName="btn-success" onConfirm={onSaveAndPublishConfirm}/>)
  }

  if (originalContent) {
    return <MicroTranslationOriginalContent classes={classes} content={content || ''}
                                            reference={microTranslation.reference}/>
  }

  return <div className={classNames(classes)}>
    <div className="card-body p-3">
      {microTranslation.translator && (
        <div className="d-flex align-items-center mb-1 mt-n1"><UserButton user={microTranslation.translator}/></div>
      )}

      {canEdit ? <ContentEditable value={translation} onContentChange={setTranslation} className="ps-1"
                                  tabIndex={0} style={{minHeight: `100px`}}/> :
        <div dangerouslySetInnerHTML={{__html: content || ''}}/>}

      {canCorrect && <div className="pt-1">
        <div className="d-flex justify-content-end">
          <button className="btn btn-warning btn-sm" onClick={toggleCorrectionArea}>
            {showCorrectionArea
              ? t('components.MicroTranslationColumn.cancel')
              : t('components.MicroTranslationColumn.correct')}
          </button>
        </div>
      </div>}
    </div>

    {microTranslation.correction &&
      <div className="card-body p-3 alert-warning">
        {microTranslation.editor && (
          <div className="d-flex align-items-center mb-1 mt-n1"><UserButton user={microTranslation.editor}/></div>
        )}
        <div>{t('components.MicroTranslationColumn.correctionLabel')}</div>
        <div dangerouslySetInnerHTML={{__html: correction || ''}}/>
      </div>}

    {canCorrect && showCorrectionArea && <>
      <div className="card-body p-3">
        <label className="text-muted small">{t('components.MicroTranslationColumn.correctionTextAreaLabel')}</label>
        <ContentEditable value={correction} onContentChange={setCorrection} className="ps-1"
                         tabIndex={0} style={{minHeight: `100px`}}/>
      </div>
      <div className="card-footer px-3">
        <div className="d-flex justify-content-between">
          <div className="d-flex">
            <TextEditToolbar/>
          </div>
          <button className="btn btn-success btn-sm" onClick={saveAndPublishCorrection} disabled={!correction}>
            {t('components.MicroTranslationColumn.saveSend')}
          </button>
        </div>
      </div>
    </>}

    {canEdit && <div className="card-footer px-3">
      <div className="d-flex justify-content-between">
        <div className="d-flex">
          <button className="btn btn-outline-success btn-sm me-1" onClick={saveTranslation}>
            {t('components.MicroTranslationColumn.save')}
          </button>
          <TextEditToolbar/>
        </div>
        <button className="btn btn-success btn-sm" onClick={saveAndPublishTranslation} disabled={!translation}>
          {t('components.MicroTranslationColumn.saveSend')}
        </button>
      </div>
    </div>}

  </div>
}

export default MicroTranslationColumn