import {ProjectStage} from 'domain/Project'
import {AggregatedProjectLog} from 'domain/ProjectLog'
import React from 'react'
import {useTranslation} from 'react-i18next'
import RawHtml from '../../../utils/RawHtml'


interface LogProps {
  log: AggregatedProjectLog
}

const StageLogLabel: React.FC<LogProps> = ({log}) => {
  const {t} = useTranslation()
  const username = (log.user ? log.user.name : log.userId)
  const isDeprecatedStage = !Object.keys(ProjectStage).includes(log.params.stage)
  const stageTitle = isDeprecatedStage ? log.params.stage : t(`models.ProjectStage.${log.params.stage}.title`)

  return <RawHtml>{t(`components.ProjectLogs.${log.type}`, {username, stage: stageTitle})}</RawHtml>
}

export default StageLogLabel