import TableCellSelector from 'js-table-cell-selector'
import CommonTableBuilder from './CommonTableBuilder'


class Selection {
  private tableCellSelector?: any

  constructor(private table: HTMLTableElement) {
  }

  get selectedClass() {
    return CommonTableBuilder.CSS.selected
  }

  public enable() {
    this.initCellSelector()
  }

  public reset() {
    this.clearBuffer()
    this.initCellSelector()
  }

  public destroy() {
    this.clearBuffer()
  }

  private initCellSelector() {
    const options = {
      deselectOutTableClick: true,
      selectClass: this.selectedClass,
      mouseBlockSelection: false
    }

    this.tableCellSelector = new TableCellSelector(this.table, options, new TableCellSelector.Buffer())
  }

  private clearBuffer() {
    if (this.tableCellSelector) this.tableCellSelector.obBuffer.destroy()
  }
}

export default Selection