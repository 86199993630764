import {Entity, Id} from 'domain/Entity'
import {File} from 'domain/File'
import {Locale} from 'domain/Locale'
import {User} from 'domain/User'

export interface Company extends Entity {
  tick: string
  name: LocalizedString
  shortName: LocalizedString
  japaneseSpellingName: string
  phone?: string
  website?: string
  irWebsite?: LocalizedString
  irPhone?: LocalizedString
  irEmail?: LocalizedString
  irContact?: LocalizedString
  establishedAt?: string
  headOfficeAddress?: LocalizedString
  exchangeName?: LocalizedString
  listedAt?: string
  coverageInitiatedAt?: string
  contractEndsAt?: string
  financialYearEnd?: number
  logoImageId?: Id
  bgImageId?: Id
  summary?: LocalizedString
  classification?: Classification
  seniorAnalystId?: Id
  assistantAnalystIds?: Id[]
  status: CompanyStatus
  isZeroYen: boolean
}

export enum CompanyStatus {
  DRAFT = 'DRAFT',
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE'
}

interface Classification {
  gics?: string[]
}

export type LocalizedString = {
  [key in Locale]: string
}

export interface CompanyWithImages extends Company {
  logoImage?: File | null,
  bgImage?: File | null
}

export interface AggregatedCompany extends CompanyWithImages {
  seniorAnalyst?: User
  assistantAnalysts: User[]
}

export interface AggregatedCompanyWithLastPublished extends CompanyWithImages {
  maxPublishedAt?: Date
}

export type CompanyCreate = Omit<Company, '_id' | 'createdAt' | 'updatedAt'>
export type CompanyUpdate = Omit<CompanyCreate, 'status'>

export const COMPANY_FILE_HEADERS = [
  'tick',
  'nameJA',
  'nameEN',
  'shortNameJA',
  'shortNameEN',
  'japaneseSpellingName',
  'phone',
  'website',
  'irWebsiteJA',
  'irWebsiteEN',
  'irPhoneJA',
  'irPhoneEN',
  'irEmailJA',
  'irEmailEN',
  'irContactJA',
  'irContactEN',
  'establishedAt',
  'headOfficeAddressJA',
  'headOfficeAddressEN',
  'exchangeNameJA',
  'exchangeNameEN',
  'listedAt',
  'coverageInitiatedAt',
  'financialYearEnd',
  'summaryJA',
  'summaryEN',
  'isZeroYen'
] as const

export const COMPANY_FILE_HEADER_MAP = {
  'Tick': 'tick',
  'Name (Japanese)': 'nameJA',
  'Name (English)': 'nameEN',
  'Short Name (Japanese)': 'shortNameJA',
  'Short Name (English)': 'shortNameEN',
  'Japanese Spelling Name': 'japaneseSpellingName',
  'Phone': 'phone',
  'Website': 'website',
  'Ir Website (Japanese)': 'irWebsiteJA',
  'Ir Website (English)': 'irWebsiteEN',
  'Ir Phone (Japanese)': 'irPhoneJA',
  'Ir Phone (English)': 'irPhoneEN',
  'Ir Email (Japanese)': 'irEmailJA',
  'Ir Email (English)': 'irEmailEN',
  'Ir Contact (Japanese)': 'irContactJA',
  'Ir Contact (English)': 'irContactEN',
  'Established At': 'establishedAt',
  'Head Office Address (Japanese)': 'headOfficeAddressJA',
  'Head Office Address (English)': 'headOfficeAddressEN',
  'Exchange Name (Japanese)': 'exchangeNameJA',
  'Exchange Name (English)': 'exchangeNameEN',
  'Listed At': 'listedAt',
  'Coverage Initiated At': 'coverageInitiatedAt',
  'Financial Year End': 'financialYearEnd',
  'Summary (Japanese)': 'summaryJA',
  'Summary (English)': 'summaryEN',
  'Is Zero Yen': 'isZeroYen'
}

export type CompanyRow = { [key in typeof COMPANY_FILE_HEADERS[number]]?: string }
