import {useContext, useEffect, useState} from 'react'
import {SelectedBlocksContext} from '../components/report/bookmarks/SelectedBlocksProvider'


const useTrackSelectedBlocks = (enable: boolean) => {
  const {setSelectedBlocks} = useContext(SelectedBlocksContext)

  const [observer] = useState<MutationObserver>(
    new MutationObserver(() => {
        const selectedBlocks = document.querySelectorAll('.ce-block--selected')
        if (selectedBlocks.length === 0) {
          setSelectedBlocks([])
        }
      }
    )
  )

  useEffect(() => {
    if (!enable) {
      observer.disconnect()
      return
    }

    const observerConfig = {
      attributeFilter: ['class'],
      subtree: true
    }
    observer.observe(document, observerConfig)
  }, [enable])

  useEffect(() => {
    return () => {
      observer.disconnect()
    }
  }, [])

  return observer
}

export default useTrackSelectedBlocks