import {AggregatedProjectNotification} from 'domain/ProjectNotification'
import {projectNotificationLink, projectNotificationText} from 'domain/utils/projectNotificationUtils'
import React, {useContext} from 'react'
import {useTranslation} from 'react-i18next'
import {Link} from 'react-router-dom'
import {LocaleContext} from '../../providers/LocaleProvider'
import {relativeDateFromNow} from '../../utils/momentUtils'


type Props = {
  projectNotification: AggregatedProjectNotification
}

const ProjectNotificationCard: React.FC<Props> = ({projectNotification}) => {
  const {locale} = useContext(LocaleContext)
  const {t} = useTranslation()

  return <Link className={`card card-clickable ${projectNotification.isRead ? '' : 'card-info'}`}
               to={projectNotificationLink(projectNotification)}>
    <div className="card-header p-2 py-1">
      <div className="d-flex justify-content-between align-items-center w-100">
        <strong>
          <small>{t(`models.ProjectNotification.${projectNotification.type}.title`)}</small>
        </strong>
        <small className="text-muted-more">
          {relativeDateFromNow(projectNotification.createdAt, locale, t('dateTimeFormat'))}
        </small>
      </div>
    </div>
    <div className="card-body p-2">
      {projectNotificationText(projectNotification, t)}
    </div>
  </Link>
}

export default ProjectNotificationCard