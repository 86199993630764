import {Comment} from 'domain/Comment'
import {Id} from 'domain/Entity'
import {Errors} from 'domain/Errors'
import React, {ReactElement, useContext, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {addReply} from '../../../api-clients/commentClient'
import {CommentContext} from '../../../providers/CommentProvider'
import {ReportContext} from '../../../providers/ReportProvider'
import CommentContentForm from './CommentContentForm'


interface NewReplyProps {
  commentId: Id
  onClose: (e: React.MouseEvent) => void
}

const NewReply: React.FC<NewReplyProps> = ({commentId, onClose}): ReactElement => {
  const {t} = useTranslation()
  const {project} = useContext(ReportContext)
  const {setComments} = useContext(CommentContext)
  const [content, setContent] = useState('')
  const [errors, setErrors] = useState<Errors<Comment>>({})

  const onCreate = () => {
    addReply(commentId, content, project!._id).then(reply => {
      setComments(comments => (comments || []).map(comment => {
        return comment._id === commentId ? {...comment, replies: [...comment.replies, reply]} : comment
      }))
      setContent('')
    }).catch(({errors}) => setErrors(errors))
  }

  return (
    <div className="mx-n1">
      <div className="mb-2">
        <CommentContentForm projectId={project?._id!} content={content}
                            onContentChange={setContent}
                            onSubmit={onCreate}
                            error={errors.content}/>
      </div>

      <div className="mb-1">
        <div>
          <button type="button" className={`btn btn-sm btn-success me-2 ${content ? '' : 'disabled'}`}
                  onClick={onCreate}>
            <span>{t('components.Report.comments.NewReply.save')}</span>
          </button>

          <button type="button" className="btn btn-sm btn-outline-secondary" onClick={onClose}>
            <span>{t('components.Report.comments.NewReply.cancel')}</span>
          </button>
        </div>
      </div>
    </div>
  )
}

export default NewReply