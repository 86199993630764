import React from 'react'
import {useTranslation} from 'react-i18next'
import {ReactComponent as DeleteIcon} from '../../../../assets/icons/delete.svg'
import {MENU_ICON_STYLE, MENU_ITEM_STYLE, MENU_ITEM_TEXT_STYLE} from './styles'

interface Props {
  onRemove: () => void
}

export const RemoveButton: React.FC<Props> = ({onRemove}) => {
  const {t} = useTranslation()

  return (
    <div className={MENU_ITEM_STYLE} onClick={onRemove}>
      <DeleteIcon className={MENU_ICON_STYLE} />
      <span className={MENU_ITEM_TEXT_STYLE}>{t('Remove')}</span>
    </div>
  )
}
