import React, {ReactElement, useState} from 'react'


interface CollapsibleProps {
  title: string
  children: ReactElement,
  initiallyCollapsed?: boolean
}

const Collapsible: React.FC<CollapsibleProps> = ({title, children, initiallyCollapsed}): ReactElement => {
  const [collapsed, setCollapsed] = useState(initiallyCollapsed === undefined ? true : initiallyCollapsed)

  const onClick = (e: React.MouseEvent) => {
    e.preventDefault()
    setCollapsed(false)
  }

  if (collapsed) {
    return <a href="#" className="small" onClick={onClick}>{title}</a>
  } else {
    return children
  }
}

export default Collapsible
